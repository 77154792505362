import React, { useState, useEffect, useRef } from 'react';
import {
  Table,
  Button,
  Row,
  Col,
  Icon,
  Input,
  Form,
  Drawer,
  Popconfirm
} from 'antd';
import LinkButton from '../../components/general/LinkButton';
import { RouteComponentProps, withRouter } from 'react-router-dom';
import { FormComponentProps } from 'antd/lib/form';
import Util from '../../helpers/Util';
import LawsuitService, {
  LawsuitSearchOption
} from '../../services/LawsuitService';
import { Lawsuit } from '../../models/Lawsuit';
import AButton from '../../components/general/AButton';
import LawsuitEdit from './LawsuitEdit';
import { PaginationConfig } from 'antd/lib/table';
import LawsuitEventList from './LawsuitEventList';

interface Props extends RouteComponentProps, FormComponentProps {}

const LawsuitList: React.FC<Props> = props => {
  const [loading, setLoading] = useState(false);
  const [totalCount, setTotalCount] = useState(0);
  const [currentPage, setCurrentPage] = useState(1);
  const [refreshTrigger, setRefreshTrigger] = useState(1);
  const [searchOption, setSearchOption] = useState<LawsuitSearchOption>({});
  const [items, setItems] = useState<Lawsuit[]>([]);
  const { getFieldDecorator } = props.form;

  const pagination = {
    pageSize: 15,
    current: currentPage,
    total: totalCount,
    showTotal: (total: number, range: [number, number]) =>
      `전체 ${total}건 중 ${range[0]}-${range[1]}`
  };

  useEffect(() => {
    const fetchItems = async () => {
      setLoading(true);
      try {
        const result = await LawsuitService.fetchItems(
          pagination.pageSize,
          currentPage,
          searchOption
        );
        setTotalCount(result.total_count);
        setItems(Lawsuit.fromJsonArray(result.items));
      } catch (error) {
        Util.showError(error);
      } finally {
        setLoading(false);
      }
    };
    fetchItems();
  }, [currentPage, pagination.pageSize, searchOption, refreshTrigger]);

  const columns = [
    {
      title: '상태',
      dataIndex: 'ongoing',
      fixed: 'left' as 'left',
      render: (text: string, record: Lawsuit) =>
        record.ongoing ? '진행' : '완료'
    },
    {
      title: '프로젝트',
      dataIndex: 'project_name',
      fixed: 'left' as 'left',
      width: 300,
      render: (text: string, record: Lawsuit) => (
        <AButton onClick={e => onClickProject(record)}>
          <div className="truncate" style={{ width: '300px' }}>
            {text}
          </div>
        </AButton>
      )
    },
    {
      title: '사업주',
      dataIndex: 'comp_name'
    },
    {
      title: '소송현황',
      children: [
        {
          title: '사건번호',
          key: '소송1',
          dataIndex: 'code'
        },
        {
          title: '사건명',
          key: '소송2',
          dataIndex: 'title',
          render: (text: string, record: Lawsuit) => (
            <AButton onClick={e => onClickTitle(record)}>{text}</AButton>
          )
        },
        {
          title: '원고(채권자)',
          key: '소송3',
          dataIndex: 'accuser'
        },
        {
          title: '피고(채무자)',
          key: '소송4',
          dataIndex: 'accused'
        },
        {
          title: '관할',
          key: '소송5',
          dataIndex: 'court'
        }
      ]
    },
    {
      title: '담당변호사',
      dataIndex: 'lawyer'
    },
    {
      title: '당사담당자',
      dataIndex: 'director'
    },
    {
      title: '소송금액',
      dataIndex: 'f_lawsuit_pay',
      align: 'right' as 'right'
    },
    {
      title: '합의가능금액',
      dataIndex: 'f_settle_pay',
      align: 'right' as 'right'
    },
    {
      width: '80px',
      fixed: 'right' as 'right',
      render: (text: string, record: Lawsuit) => {
        return (
          <>
            <LinkButton onClick={e => onClickUpdateButton(record)}>
              수정
            </LinkButton>{' '}
            |{' '}
            <Popconfirm
              title="정말 삭제하시겠습니까?"
              onConfirm={e => onClickDeleteButton(record)}
            >
              <LinkButton>삭제</LinkButton>
            </Popconfirm>
          </>
        );
      }
    }
  ];

  const onChangeTable = (pagination: PaginationConfig) => {
    setCurrentPage(pagination.current || 1);
  };

  const onSubmitSearchForm = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    props.form.validateFields((err, values) => {
      if (err) return;
      setSearchOption(values);
      setCurrentPage(1);
    });
  };

  const itemRef = useRef<Lawsuit | undefined>(undefined);
  const [editorVisible, setEditorVisible] = useState(false);
  const [detailVisible, setDetailVisible] = useState(false);

  const onClickAddButton = () => {
    itemRef.current = undefined;
    setEditorVisible(true);
  };

  const onClickUpdateButton = async (item: Lawsuit) => {
    itemRef.current = item;
    setEditorVisible(true);
  };

  const onCloseEditor = () => {
    setEditorVisible(false);
  };

  const onSuccessEditing = () => {
    setRefreshTrigger(v => v + 1);
    setEditorVisible(false);
  };

  const onClickDeleteButton = async (item: Lawsuit) => {
    try {
      await LawsuitService.delete(item.id);
      Util.showSuccess('삭제되었습니다.');
      setRefreshTrigger(v => v + 1);
    } catch (err) {
      Util.showError(err);
    }
  };

  const onClickProject = (item: Lawsuit) => {
    props.history.push('/projects/edit?id=' + item.project_id);
  };

  const onClickTitle = (item: Lawsuit) => {
    itemRef.current = item;
    setDetailVisible(true);
  };

  const onCloseDetail = () => {
    setDetailVisible(false);
  };

  return (
    <>
      <h2>법무내역</h2>

      <Form
        className="hl-form"
        layout="inline"
        style={{ marginBottom: 7 }}
        onSubmit={onSubmitSearchForm}
      >
        <Row type="flex" justify="space-between">
          <Col>
            <Form.Item>
              {getFieldDecorator('keyword')(
                <Input.Search
                  placeholder="검색어"
                  allowClear={true}
                  style={{ width: 150 }}
                />
              )}
            </Form.Item>

            <Form.Item>
              <Button type="primary" htmlType="submit">
                검색
              </Button>
            </Form.Item>
          </Col>

          <Col>
            <Button onClick={onClickAddButton}>
              <Icon type="plus" />
              사건 등록
            </Button>
          </Col>
        </Row>
      </Form>

      <Table
        columns={columns}
        dataSource={items}
        pagination={pagination}
        onChange={onChangeTable}
        rowKey="id"
        loading={loading}
        bordered={true}
        size="middle"
        scroll={{ x: 'max-content' }}
      />

      <Drawer
        closable={false}
        placement="right"
        width={1000}
        visible={editorVisible}
        onClose={onCloseEditor}
        destroyOnClose={true}
      >
        <LawsuitEdit
          id={itemRef.current ? itemRef.current.id : undefined}
          onSuccess={onSuccessEditing}
        />
      </Drawer>

      <Drawer
        closable={false}
        placement="right"
        width={800}
        visible={detailVisible}
        onClose={onCloseDetail}
        destroyOnClose={true}
      >
        <LawsuitEventList item={itemRef.current || new Lawsuit()} />
      </Drawer>
    </>
  );
};

const _LawsuitList = Form.create<Props>()(LawsuitList);
export default withRouter(_LawsuitList);
