import React, { useState, useEffect } from 'react';
import Form, { FormComponentProps } from 'antd/lib/form';
import { Button, Input, Table, Icon } from 'antd';
import FCNumberInput from '../../../components/formcomps/FCNumberInput';
import Util from '../../../helpers/Util';
import FIDatePicker from '../../../components/formcomps/FIDatePicker';
import InlineItem from '../../../components/formcomps/InlineItem';
import FICorpSelect from '../../../components/formcomps/FICorpSelect';
import { DepositItem, DailyReportItem } from '../../../models/Finance';
import FinanceService from '../../../services/FinanceService';
import LinkButton from '../../../components/general/LinkButton';
import { Moment } from 'moment';
import _ from 'lodash';

interface Props extends FormComponentProps {
  item?: DailyReportItem;
  corpId?: number;
  onSuccess?: () => void;
}

const DailyDepositEditor: React.FC<Props> = props => {
  const { corpId = 1 } = props;
  const { getFieldDecorator, getFieldValue, setFieldsValue } = props.form;
  const [item] = useState<DailyReportItem>(props.item || new DailyReportItem());
  const [items, setItems] = useState<DepositItem[]>([]);
  const [loading, setLoading] = useState(false);
  const editingMode = props.item !== undefined;

  console.count('DailyDepositEditor');

  // useEffect(() => {
  //   if (editingMode) {
  //     setItems(item.items);
  //   } else {
  //     setTimeout(() => {
  //       fetchPrevItems(corpId, Util.formatDate(moment()));
  //     }, 400);
  //   }
  // }, [editingMode, corpId, item]);

  useEffect(() => {
    if (editingMode) {
      setItems(item.items);
    } else {
      const depositItem = new DepositItem();
      depositItem.type = 1;
      setItems([depositItem]);
    }
  }, [editingMode, item]);

  const onChangeCorp = (corp_id: number) => {
    // NOTE: 자동으로 fetch 하지 않도록 수정
    // fetchPrevItems(corp_id, getFieldValue('target_date'));
  };

  const onChangeTargetDate = (date: Moment | undefined, dateString: string) => {
    // NOTE: 자동으로 fetch 하지 않도록 수정
    // fetchPrevItems(getFieldValue('corp_id'), dateString);
  };

  const onClickFetchPrevData = () => {
    fetchPrevItems(getFieldValue('corp_id'), getFieldValue('target_date'));
  };

  const fetchPrevItems = async (corp_id: number, target_date: string) => {
    // if (editingMode) return;
    setLoading(true);
    try {
      const result = await FinanceService.fetchPrevDailyReport(
        corp_id,
        target_date
      );
      if (result.item) {
        const prevItem = DailyReportItem.fromJson(result.item);
        prevItem.items.forEach(e => {
          e.amount_prev = e.amount_rest;
          e.amount_in = 0;
          e.amount_out = 0;
          e.amount_etc = 0;
        });
        setItems([]);
        setItems(prevItem.items);
        Util.showSuccess(`${prevItem.f_target_date} 데이타를 불러왔습니다.`);
      } else {
        const depositItem = new DepositItem();
        depositItem.type = 1;
        setItems([]);
        setItems([depositItem]);
        Util.showError('집행일 이전 입력된 데이타가 없습니다.');
      }
    } catch (error) {
      Util.showError(error);
    } finally {
      setLoading(false);
    }
  };

  const onEnterItemValue = (
    value: number | string | undefined,
    field: string
  ) => {
    if (value === undefined) return;
    const amount_prev = getFieldValue(`amount_prev${field}`) || 0;
    const amount_in = getFieldValue(`amount_in${field}`) || 0;
    const amount_out = getFieldValue(`amount_out${field}`) || 0;
    setFieldsValue({
      [`amount_rest${field}`]: amount_prev + amount_in - amount_out
    });
  };

  const onClickSubmit = () => {
    props.form.validateFields((err, values) => {
      if (err) return;

      const depositItems = items.map((item: any) => {
        return {
          id: item.id,
          type: item.type,
          title: values['title' + item.id],
          subtitle: values['subtitle' + item.id],
          account_no: values['account_no' + item.id],
          amount_prev: values['amount_prev' + item.id],
          amount_in: values['amount_in' + item.id],
          amount_out: values['amount_out' + item.id],
          amount_rest: values['amount_rest' + item.id],
          amount_etc: values['amount_etc' + item.id]
        };
      });

      const cashItems = depositItems.filter(e => e.type === 1);
      const bankItems = depositItems.filter(e => e.type === 0);
      const amount_cash = _.sumBy(cashItems, e => e.amount_rest);
      const amount_bank = _.sumBy(bankItems, e => e.amount_rest);

      processItem({
        corp_id: values.corp_id,
        target_date: values.target_date,
        create_date: values.create_date,
        items: JSON.stringify(depositItems),
        amount_cash,
        amount_bank
      });
    });
  };

  const [submitting, setSubmitting] = useState(false);

  const processItem = async (values: any) => {
    try {
      if (!values.corp_id) {
        Util.showError('법인을 선택해주세요.');
        return;
      }
      if (!values.target_date || !values.create_date) {
        Util.showError('집행일, 작성일을 입력해주세요.');
        return;
      }

      setSubmitting(true);

      if (editingMode) {
        await FinanceService.updateDailyReports(
          item.corp_id,
          Util.formatDate(item.target_date),
          values
        );
      } else {
        await FinanceService.createDailyReports(values);
      }

      Util.showSuccess('저장되었습니다.');
      if (props.onSuccess) props.onSuccess();
    } catch (err) {
      Util.showError(err);
    } finally {
      setSubmitting(false);
    }
  };

  const columns = [
    {
      title: '은행명',
      dataIndex: 'title',
      align: 'center' as 'center',
      width: 100,
      render: (text: string, record: any) => {
        if (record.type === 1) {
          return { props: { colSpan: 3 }, children: '현 금' };
        }
        return (
          <Form.Item>
            {getFieldDecorator('title' + record.id, {
              initialValue: record.title
            })(<Input />)}
          </Form.Item>
        );
      }
    },
    {
      title: '계좌명',
      dataIndex: 'subtitle',
      width: 100,
      render: (text: string, record: any) => {
        if (record.type === 1) {
          return { props: { colSpan: 0 } };
        }
        return (
          <Form.Item>
            {getFieldDecorator('subtitle' + record.id, {
              initialValue: record.subtitle
            })(<Input />)}
          </Form.Item>
        );
      }
    },
    {
      title: '계좌번호',
      dataIndex: 'account_no',
      width: 200,
      render: (text: string, record: any) => {
        if (record.type === 1) {
          return { props: { colSpan: 0 } };
        }
        return (
          <Form.Item>
            {getFieldDecorator('account_no' + record.id, {
              initialValue: record.account_no
            })(<Input />)}
          </Form.Item>
        );
      }
    },
    {
      title: '전일잔액',
      dataIndex: 'amount_prev',
      render: (text: string, record: any) => {
        return (
          <Form.Item>
            <FCNumberInput
              form={props.form}
              name={`amount_prev${record.id}`}
              options={{ initialValue: record.amount_prev }}
              onEnter={value => onEnterItemValue(value, record.id)}
            />
          </Form.Item>
        );
      }
    },
    {
      title: '입금액',
      dataIndex: 'amount_in',
      render: (text: string, record: any) => {
        return (
          <Form.Item>
            <FCNumberInput
              form={props.form}
              name={`amount_in${record.id}`}
              options={{ initialValue: record.amount_in }}
              onEnter={value => onEnterItemValue(value, record.id)}
            />
          </Form.Item>
        );
      }
    },
    {
      title: '출금액',
      dataIndex: 'amount_out',
      render: (text: string, record: any) => {
        return (
          <Form.Item>
            <FCNumberInput
              form={props.form}
              name={`amount_out${record.id}`}
              options={{ initialValue: record.amount_out }}
              onEnter={value => onEnterItemValue(value, record.id)}
            />
          </Form.Item>
        );
      }
    },
    {
      title: '금일잔액',
      dataIndex: 'amount_rest',
      render: (text: string, record: any) => {
        return (
          <Form.Item>
            <FCNumberInput
              form={props.form}
              name={`amount_rest${record.id}`}
              options={{ initialValue: record.amount_rest }}
              disabled
            />
          </Form.Item>
        );
      }
    },
    {
      title: '비고',
      dataIndex: 'amount_etc',
      render: (text: string, record: any) => {
        return (
          <Form.Item>
            <FCNumberInput
              form={props.form}
              name={`amount_etc${record.id}`}
              options={{ initialValue: record.amount_etc }}
            />
          </Form.Item>
        );
      }
    },
    {
      width: 20,
      render: (text: string, record: any) => {
        if (record.type === 1) return <></>;
        return (
          <LinkButton onClick={e => onClickDeleteItem(record.id)}>
            <Icon type="close" />
          </LinkButton>
        );
      }
    }
  ];

  const onClickAddItem = () => {
    let newItems = items.concat(new DepositItem());
    setItems(newItems);
  };

  const onClickDeleteItem = (id: string) => {
    let newItems = items.filter((item: any) => item.id !== id);
    setItems(newItems);
  };

  return (
    <>
      <h3>일일자금보고 잔액현황</h3>
      <Form layout="inline">
        <div className="hl-form" style={{ marginBottom: '10px' }}>
          <Form.Item label="법인명">
            <InlineItem width="160px">
              <FICorpSelect
                form={props.form}
                width="160px"
                options={{ initialValue: editingMode ? item.corp_id : corpId }}
                onChange={onChangeCorp}
              />
            </InlineItem>
          </Form.Item>

          <Form.Item label="집행일">
            <FIDatePicker
              form={props.form}
              name="target_date"
              initialValue={item && item.target_date && item.f_target_date}
              onChange={onChangeTargetDate}
            />
          </Form.Item>

          <Form.Item label="작성일">
            <FIDatePicker
              form={props.form}
              name="create_date"
              allowClear
              initialValue={item && item.create_date && item.f_create_date}
            />
          </Form.Item>

          <Form.Item>
            <Button onClick={onClickFetchPrevData}>이전 데이타 불러오기</Button>
          </Form.Item>
        </div>

        <Table
          bordered={false}
          size="middle"
          columns={columns}
          dataSource={items}
          pagination={false}
          loading={loading}
          style={{ marginBottom: '10px' }}
          rowKey="id"
        />

        <div style={{ textAlign: 'right' }}>
          <Button onClick={onClickAddItem}>
            <Icon type="plus" />열 추가
          </Button>{' '}
          <Button
            type="primary"
            disabled={submitting || loading}
            onClick={onClickSubmit}
          >
            {editingMode ? '수정' : '등록'}
          </Button>
        </div>
      </Form>
    </>
  );
};

export default Form.create<Props>()(DailyDepositEditor);
