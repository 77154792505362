import React from 'react';
// import OrderService from '../../services/OrderService';
// import { Order } from '../../models/Order';
// import EAService, { EADocSearchOption } from '../../services/EAService';
// import { EADoc } from '../../models/EAS';

interface Props {}

const ProcessUpdate: React.FC<Props> = props => {
  // useEffect(() => {
  //   const fetchItems = async () => {
  //     const result = await OrderService.fetchItems(100, 1);
  //     const orders = Order.fromJsonArray(result.items);
  //     orders.forEach(async order => {
  //       console.log(
  //         `${order.id} | district: ${order.f_district} | area: ${order.f_area}`
  //       );
  //       if (order.districts.length === 0 && order.f_district !== '') {
  //         console.log(`update order.districts: ${order.f_district}`);
  //         // await OrderService.update(order.id, {
  //         //   districts: order.f_district
  //         // });
  //       }
  //       if (
  //         order.areas.length === 0 &&
  //         order.f_area !== '' &&
  //         order.f_area !== null
  //       ) {
  //         console.log(`update order.areas: ${order.f_area}`);
  //         // await OrderService.update(order.id, {
  //         //   areas: order.f_area
  //         // });
  //       }
  //     });
  //   };

  //   fetchItems();
  // }, []);

  // useEffect(() => {
  //   const fetchItems = async () => {
  //     const searchOptions: EADocSearchOption = { ref_target: 'ord' };
  //     const result = await EAService.fetchItems(100, 1, searchOptions);
  //     const docs = EADoc.fromJsonArray(result.items);
  //     docs.forEach(async doc => {
  //       let data = doc.data;
  //       const order = Order.fromJson(doc.data);

  //       console.log(
  //         `data.f_district : ${order.f_district} | data.f_area : ${order.f_area}`
  //       );

  //       if (data.districts === undefined || data.areas === undefined) {
  //         data.districts =
  //           order.f_district === '' ? [] : order.f_district.split(',');
  //         data.areas = order.f_area === '' ? [] : order.f_area.split(',');
  //         console.log(data);
  //         // await EAService.updateEADoc(doc.id, { data2: data });
  //       }
  //     });
  //   };

  //   fetchItems();
  // }, []);

  return (
    <>
      <h2>ProcessUpdate</h2>
    </>
  );
};

export default ProcessUpdate;
