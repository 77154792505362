import React, { useState, useEffect } from 'react';
import { observer, inject } from 'mobx-react';
import { DataStore } from '../../stores/dataStore';
import { TreeSelect } from 'antd';
import { TreeNode } from 'antd/lib/tree-select';
import { Department } from '../../models/Org';
import { FormComponentProps } from 'antd/lib/form';

interface Props extends FormComponentProps {
  values?: number[];
  dataStore?: DataStore;
  name?: string;
  maxDepth?: number; // all: 0, default: 2
  allowClear?: boolean;
  style?: any;
}

const DeptSelect = (props: Props) => {
  const dataStore = props.dataStore!;
  const [deptTreeData, setDeptTreeData] = useState<TreeNode[]>([]);
  const { maxDepth = 2, allowClear = false, style } = props;
  const { getFieldDecorator } = props.form;

  useEffect(() => {
    const convertToTreeData = (
      item: Department,
      currentDepth: number,
      maxDepth: number
    ) => {
      let result = {
        key: item.id,
        value: item.id,
        title: item.name,
        children: [] as any[]
      };

      if (item.children && (currentDepth < maxDepth || maxDepth === 0)) {
        result.children = item.children.map(child => {
          return convertToTreeData(child, ++currentDepth, maxDepth);
        });
      }

      return result;
    };

    let currentDepth = 1;
    if (dataStore.dept.children) {
      const treeData = dataStore.dept.children.map(dept => {
        return convertToTreeData(dept, currentDepth, maxDepth);
      });
      setDeptTreeData(treeData);
    }
  }, [dataStore.dept, maxDepth]);

  return (
    <>
      {getFieldDecorator(props.name || 'dept_ids', {
        initialValue: props.values || undefined
      })(
        <TreeSelect
          placeholder="부서"
          allowClear={allowClear}
          treeData={deptTreeData}
          treeDefaultExpandAll
          multiple
          dropdownStyle={{ maxHeight: 400, overflow: 'auto' }}
          style={style}
        />
      )}
    </>
  );
};

export default inject('dataStore')(observer(DeptSelect));
