import React, { useState } from 'react';
import { Table, Button, Row, Col, Icon } from 'antd';
import { observer, inject } from 'mobx-react';
import { DataStore } from '../../stores/dataStore';
import { Department } from '../../models/Org';
import StringEditor from './StringEditor';
import Util from '../../helpers/Util';
import LinkButton from './LinkButton';

interface Props {
  dataStore?: DataStore;
}

const DepartmentList: React.FC<Props> = props => {
  const [editingItem, setEditingItem] = useState<Department | undefined>(
    undefined
  );
  const [parentItem, setParentItem] = useState<Department | undefined>(
    undefined
  );
  const [nameEditorVisible, setNameEditorVisible] = useState(false);
  const [submitting, setSubmitting] = useState(false);
  const dataStore = props.dataStore!;
  const items = dataStore.dept.children!.slice();

  const columns = [
    {
      title: '부서명',
      dataIndex: 'name'
    },
    {
      title: '순서',
      width: '100px',
      render: (text: string, record: Department) => {
        return (
          <>
            <Button
              size="small"
              onClick={e => processOperation(record, 'up')}
              disabled={submitting}
            >
              <Icon type="up" />
            </Button>{' '}
            <Button
              size="small"
              onClick={e => processOperation(record, 'down')}
              disabled={submitting}
            >
              <Icon type="down" />
            </Button>
          </>
        );
      }
    },
    {
      width: '180px',
      render: (text: string, record: Department) => {
        return (
          <>
            <LinkButton
              onClick={e => handleCreateChildButton(record)}
              disabled={record.isExecutive}
            >
              하위부서등록
            </LinkButton>{' '}
            |{' '}
            <LinkButton
              onClick={e => handleUpdateItemButton(record)}
              disabled={record.isExecutive}
            >
              수정
            </LinkButton>{' '}
            |{' '}
            <LinkButton
              onClick={e => handleDeleteItemButton(record)}
              // disabled={record.isExecutive}
              disabled
            >
              삭제
            </LinkButton>
          </>
        );
      }
    }
  ];

  const handleCreateButtonClick = async () => {
    setNameEditorVisible(true);
  };

  const handleCreateChildButton = async (parent: Department) => {
    setParentItem(parent);
    setNameEditorVisible(true);
  };

  const handleUpdateItemButton = async (item: Department) => {
    setEditingItem(item);
    setNameEditorVisible(true);
  };

  const onCancelNameEditor = () => {
    setNameEditorVisible(false);
    setEditingItem(undefined);
    setParentItem(undefined);
  };

  const onSubmitNameEditor = async (name: string) => {
    try {
      setSubmitting(true);

      console.log(parentItem, editingItem);

      if (parentItem) {
        await dataStore.createChildDept(name, parentItem);
      } else {
        editingItem
          ? await dataStore.updateDept(editingItem.id, { name })
          : await dataStore.createDept(name);
      }

      setNameEditorVisible(false);
      setEditingItem(undefined);
      setParentItem(undefined);
    } catch (err) {
      Util.showError(err);
    } finally {
      setSubmitting(false);
    }
  };

  const handleDeleteItemButton = (item: Department) => {
    // TODO: confirm
    processOperation(item, 'del');
  };

  const processOperation = async (
    item: Department,
    op: 'up' | 'down' | 'del'
  ) => {
    try {
      setSubmitting(true);
      if (op === 'up') await dataStore.moveDeptUp(item.id);
      if (op === 'down') await dataStore.moveDeptDown(item.id);
      if (op === 'del') await dataStore.deleteDept(item.id);
    } catch (err) {
      Util.showError(err);
    } finally {
      setSubmitting(false);
    }
  };

  const editorTitle = parentItem ? `${parentItem.name} 하위부서` : '부서';

  return (
    <>
      <h2>부서관리</h2>
      <Row type="flex" justify="space-between" style={{ marginBottom: 10 }}>
        <Col />
        <Col>
          <Button onClick={handleCreateButtonClick} disabled={submitting}>
            <Icon type="plus" />
            부서 등록
          </Button>
        </Col>
      </Row>

      <Table
        columns={columns}
        dataSource={items}
        pagination={false}
        rowKey="id"
        loading={submitting}
        bordered={true}
        size="middle"
        defaultExpandAllRows={true}
      />

      <StringEditor
        name={editorTitle}
        visible={nameEditorVisible}
        value={editingItem && editingItem.name}
        submitting={submitting}
        onSubmit={onSubmitNameEditor}
        onCancel={onCancelNameEditor}
      />
    </>
  );
};

export default inject('dataStore')(observer(DepartmentList));
