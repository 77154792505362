import React, { useState, useEffect, useRef } from 'react';
import { withRouter, RouteComponentProps } from 'react-router';
import { List, Avatar, Tag, Drawer } from 'antd';
import { Activity } from '../../models/Activity';
import Util from '../../helpers/Util';
import LinkButton from '../../components/general/LinkButton';
import AppService from '../../services/AppService';
import EADocDetail from '../eas/EADocDetail';

interface Props extends RouteComponentProps {
  itemCount?: number;
  onSelectItem?: (activity: Activity) => void;
  onSelectShowAll?: () => void;
}

const NotificationSummary: React.FC<Props> = props => {
  const { itemCount = 10 } = props;
  const [items, setItems] = useState<Activity[]>([]);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    const fetchItems = async () => {
      setLoading(true);
      try {
        const result = await AppService.fetchPrimaryNotifications(itemCount, 1);
        setItems(Activity.fromJsonArray(result.items));
      } catch (error) {
        Util.showError(error);
      } finally {
        setLoading(false);
      }
    };

    fetchItems();
  }, [itemCount]);

  const onClickShowAll = () => {
    props.history.push('/works/board/notifications');
    props.onSelectShowAll && props.onSelectShowAll();
  };

  const itemIdRef = useRef<number>(0);
  const [detailVisible, setDetailVisible] = useState(false);

  const onClickItem = (item: Activity) => {
    // props.history.push('/works/eas/view?id=' + item.targetId);
    // props.onSelectItem && props.onSelectItem(item);
    itemIdRef.current = item.targetId;
    setDetailVisible(true);
  };

  const onCloseDetail = () => {
    setDetailVisible(false);
  };

  return (
    <>
      <List
        loading={loading}
        itemLayout="horizontal"
        dataSource={items}
        renderItem={item => (
          <List.Item
            onClick={e => onClickItem(item)}
            style={{ cursor: 'pointer' }}
          >
            <List.Item.Meta
              avatar={
                <Avatar
                  icon="user"
                  src={item.user.avatar && item.user.avatar.thumbnailFullPath}
                />
              }
              title={
                <>
                  {item.user.name}님{' '}
                  <Tag color={item.typeColor}>{item.typeName}</Tag>
                </>
              }
              description={
                <>
                  {item.content.length > 40
                    ? item.content.substr(0, 40) + '..'
                    : item.content}
                </>
              }
            />
            <div>{Util.formatDateRelatively(item.createdAt)}</div>
          </List.Item>
        )}
      />
      <div style={{ textAlign: 'right', borderTop: '1px solid #e8e8e8' }}>
        <LinkButton onClick={onClickShowAll}>전체보기</LinkButton>
      </div>

      <Drawer
        closable={false}
        placement="right"
        width={'85%'}
        visible={detailVisible}
        onClose={onCloseDetail}
        destroyOnClose={true}
      >
        <EADocDetail id={itemIdRef.current} fetchDelayed />
      </Drawer>
    </>
  );
};

export default withRouter(NotificationSummary);
