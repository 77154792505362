import React, { useState, useEffect, useRef } from 'react';
import { ContractItem, Contract } from '../../../models/Order';
import OrderService from '../../../services/OrderService';
import Util from '../../../helpers/Util';
import { Table, Icon, Tag } from 'antd';
import LinkButton from '../../../components/general/LinkButton';

interface Props {
  orderId: number;
  out?: boolean;
  editable?: boolean;
  terms: string[];
  refreshTrigger: number;
  completed?: boolean;
  onClickItem?: (item: ContractItem, companyName: string) => void;
  onClickDate?: (item: ContractItem, companyName: string) => void;
}

const ContractPaySummary: React.FC<Props> = props => {
  const {
    orderId,
    out = false,
    editable = false,
    completed = false,
    terms,
    refreshTrigger
  } = props;
  const [loading, setLoading] = useState(false);
  const [items, setItems] = useState<ContractItem[]>([]);
  const companies = useRef<{ [id: number]: string }>({});

  useEffect(() => {
    const fetchDetail = async () => {
      setLoading(true);
      try {
        const result = await OrderService.fetchContracts(orderId, out);
        const contracts = Contract.fromJsonArray(result.contracts);

        // console.log(contracts);

        let contractItems: ContractItem[] = [];
        for (const contract of contracts) {
          companies.current[contract.id] = contract.company_name;
          contractItems = [...contractItems, ...contract.items];
        }

        contractItems = contractItems.filter(e => e.completed === completed);
        contractItems.forEach(e => {
          const index = terms.findIndex(term => term === e.term);
          e.index = index + 1;
        });

        setItems(contractItems);
      } catch (error) {
        Util.showError(error);
      } finally {
        setLoading(false);
      }
    };

    fetchDetail();
  }, [orderId, out, completed, terms, refreshTrigger]);

  const columns = [
    {
      title: '업체명',
      dataIndex: 'company_name',
      sorter: (a: ContractItem, b: ContractItem) => {
        return companies.current[a.contract_id] >
          companies.current[b.contract_id]
          ? 1
          : -1;
      },
      render: (text: string, record: ContractItem) => {
        return companies.current[record.contract_id];
      }
    },
    {
      title: '구분',
      dataIndex: 'term',
      sorter: (a: ContractItem, b: ContractItem) => {
        return a.index > b.index ? 1 : -1;
      },
      defaultSortOrder: 'ascend' as 'ascend',
      render: (text: string, record: ContractItem) => {
        if (record.completed) {
          return (
            <span>
              {record.index}. {text} <Icon type="check" />
            </span>
          );
        }
        return (
          <LinkButton onClick={e => onClickItem(record)}>
            {record.index}. {text}
          </LinkButton>
        );
      }
    },
    {
      title: out ? '지급예정일' : '수금예정일',
      dataInex: 'est_date',
      width: '120px',
      sorter: (a: ContractItem, b: ContractItem) =>
        Util.compareMoments(a.est_date, b.est_date),
      render: (text: string, record: ContractItem) => {
        return (
          <LinkButton onClick={e => onClickDate(record)}>
            {Util.formatDate(record.est_date, '등록')}
          </LinkButton>
        );
      }
    },
    {
      title: out ? '지급예정금액 과세' : '수금예정금액 과세',
      dataIndex: 'f_tax_price',
      align: 'right' as 'right'
    },
    {
      title: '면세',
      dataIndex: 'f_notax_price',
      align: 'right' as 'right'
    },
    {
      title: '부가세',
      dataIndex: 'f_vat',
      align: 'right' as 'right'
    },
    {
      title: '합계',
      dataIndex: 'f_total_price',
      align: 'right' as 'right'
    },
    {
      title: '청구일',
      dataIndex: 'req_date',
      width: '140px',
      sorter: (a: ContractItem, b: ContractItem) =>
        Util.compareMoments(a.req_date, b.req_date),
      render: (text: string, record: ContractItem) => {
        return (
          <LinkButton onClick={e => onClickDate(record)}>
            {Util.formatDate(record.req_date, '등록')}
          </LinkButton>
        );
      }
    },
    {
      title: out ? '지급 합계' : '수금 합계',
      dataIndex: 'f_total_pay',
      align: 'right' as 'right',
      render: (text: string, record: ContractItem) => {
        if (record.completed) {
          return <Tag color="green">{text}</Tag>;
        } else {
          return text;
        }
      }
    }
  ];

  const onClickItem = (item: ContractItem) => {
    if (editable) {
      const companyName = companies.current[item.contract_id];
      if (props.onClickItem) props.onClickItem(item, companyName);
    } else {
      Util.showError(
        out
          ? '외주계약이 완료되지 않았습니다.'
          : '수주계약이 완료되지 않았습니다.'
      );
    }
  };

  const onClickDate = (item: ContractItem) => {
    if (editable) {
      const companyName = companies.current[item.contract_id];
      if (props.onClickDate) props.onClickDate(item, companyName);
    } else {
      Util.showError(
        out
          ? '외주계약이 완료되지 않았습니다.'
          : '수주계약이 완료되지 않았습니다.'
      );
    }
  };

  console.count('ContractPaySummary');

  return (
    <Table
      columns={columns}
      dataSource={items}
      rowKey="id"
      pagination={false}
      bordered={true}
      loading={loading}
      size="middle"
      style={{ marginBottom: '25px' }}
    />
  );
};

export default ContractPaySummary;
