import userStore from '../stores/userStore';
import dataStore from '../stores/dataStore';
import { User } from './User';
import { Department, Corp } from './Org';
import _ from 'lodash';
import { File } from './File';

export enum EAFlowItemType {
  submit = 0,
  approve,
  agree,
  refer
}

export enum EAFlowItemAction {
  approve = 1,
  return
}

export class EAFlowItem {
  user: User;
  type: EAFlowItemType;
  action?: EAFlowItemAction;
  updatedAt?: string;

  constructor(
    user: User,
    type: EAFlowItemType,
    action?: EAFlowItemAction,
    updatedAt?: string
  ) {
    this.user = user;
    this.type = type;
    this.action = action;
    this.updatedAt = updatedAt;
  }

  static fromJsonArray(sources: any[]) {
    return sources.map(source => EAFlowItem.fromJson(source));
  }

  static fromJson(source: any) {
    const item = new EAFlowItem(
      userStore.findById(source.user_id),
      source.type,
      source.action,
      source.updated_at
    );
    return item;
  }

  static typeName(item: EAFlowItemType) {
    switch (item) {
      case EAFlowItemType.submit:
        return '기안';
      case EAFlowItemType.approve:
        return '결재';
      case EAFlowItemType.agree:
        return '합의';
      case EAFlowItemType.refer:
        return '참조';
    }
  }

  static actionName(item: EAFlowItemAction) {
    switch (item) {
      case EAFlowItemAction.approve:
        return '승인';
      case EAFlowItemAction.return:
        return '반려';
    }
  }

  get id() {
    return this.type + '-' + this.user.id;
  }

  get typeName() {
    return EAFlowItem.typeName(this.type);
  }

  get actionName() {
    return (this.action && EAFlowItem.actionName(this.action)) || '';
  }
}

// 결재문서와 연동되어 있는 실제 결재선
export class EAFlow {
  items: EAFlowItem[] = [];

  static fromJson(source: any) {
    const item = new EAFlow();
    item.items = source.map((item: any) => {
      return EAFlowItem.fromJson(item);
    });
    return item;
  }
}

// 유저별 결재선 템플릿
export class EAFlowTemplate {
  id = 0;
  // owner: User = new User();
  name = '';
  flow_items: EAFlowItem[] = [];
  cc_users: User[] = [];

  static fromJsonArray(sources: any[]) {
    return sources.map(source => EAFlowTemplate.fromJson(source));
  }

  static fromJson(source: any) {
    console.log(source);
    const item = new EAFlowTemplate();
    item.id = source.id;
    item.name = source.name;
    // item.owner = userStore.findById(source.owner_id);
    if (source.flow_items) {
      item.flow_items = EAFlowItem.fromJsonArray(source.flow_items);
    }
    if (source.cc_items) {
      item.cc_users = source.cc_items.map((userId: number) =>
        userStore.findById(userId)
      );
    }
    return item;
  }
}

export enum EADocListType {
  draft = 'draft',
  sent = 'sent',
  queued = 'queued',
  waiting = 'waiting',
  ongoing = 'ongoing',
  returned = 'returned',
  done = 'done',
  cc = 'cc',
  dreceived = 'dreceived',
  dsent = 'dsent',
  all = 'all'
}

export enum EADocState {
  draft = 0,
  ongoing,
  done,
  returned
}

export class EADoc {
  id = 0;
  corp_id = 0;
  year = 0;
  seq = 1;
  title = '';
  data: any = {};
  state: EADocState = EADocState.draft;
  is_private = false;
  created_at = '';
  updated_at = '';
  comment_count = 0;

  refTarget?: string;
  refId?: number;
  dept?: Department;
  corp?: Corp;
  owner: User = new User();
  assignedTo?: User;
  ccUsers: User[] = [];
  ccDepts: Department[] = [];
  ccDocs: EADoc[] = [];
  files: File[] = [];
  eaFlow: EAFlow = new EAFlow();

  // derived
  [key: string]: any; // for string index (subscript)

  static fromJsonArray(sources: any[]) {
    return sources.map(source => EADoc.fromJson(source));
  }

  static fromJson(source: any) {
    let item = new EADoc();
    for (const key of Object.keys(source)) {
      if (key in item) {
        item[key] = source[key];
      }
    }

    item.owner = userStore.findById(source.owner_id);
    item.assignedTo = userStore.findById(source.assigned_to);
    item.dept = dataStore.findDepartment(source.dept_id);
    item.corp = dataStore.findCorp(source.corp_id);
    item.refTarget = source.ref_target || undefined;
    item.refId = source.ref_id || undefined;

    if (source.cc_users) {
      item.ccUsers = source.cc_users.map((item: any) => {
        return userStore.findById(item.user_id);
      });
    }

    if (source.cc_depts) {
      item.ccDepts = source.cc_depts.map((item: any) => {
        return dataStore.findDepartment(item.dept_id);
      });
    }

    if (source.cc_docs) {
      item.ccDocs = EADoc.fromJsonArray(source.cc_docs);
    }

    if (source.ea_flows) {
      item.eaFlow = EAFlow.fromJson(source.ea_flows);

      if (item.state !== EADocState.draft) {
        const drafter = new EAFlowItem(
          item.owner,
          EAFlowItemType.submit,
          EAFlowItemAction.approve,
          item.created_at
        );
        item.eaFlow.items = [drafter, ...item.eaFlow.items];
      }
    }

    if (source.files) {
      item.files = File.fromJsonArray(source.files);
    }

    return item;
  }

  static listTypeName(item: EADocListType) {
    switch (item) {
      case EADocListType.draft:
        return '임시저장문서';
      case EADocListType.sent:
        return '발신문서';
      case EADocListType.queued:
        return '미결문서';
      case EADocListType.waiting:
        return '대기문서';
      case EADocListType.ongoing:
        return '진행문서';
      case EADocListType.returned:
        return '반려문서';
      case EADocListType.done:
        return '완료문서';
      case EADocListType.cc:
        return '참조문서';
      case EADocListType.dreceived:
        return '부서수신문서';
      case EADocListType.dsent:
        return '부서발신문서';
      case EADocListType.all:
        return '전체문서';
    }
  }

  static stateName(item: EADocState) {
    switch (item) {
      case EADocState.draft:
        return '작성중';
      case EADocState.ongoing:
        return '진행중';
      case EADocState.done:
        return '완료';
      case EADocState.returned:
        return '반려';
    }
  }

  static stateColor(item: EADocState) {
    switch (item) {
      case EADocState.draft:
        return '';
      case EADocState.ongoing:
        return 'blue';
      case EADocState.done:
        return 'green';
      case EADocState.returned:
        return 'red';
    }
  }

  static categoryName(cat_id: number) {
    const names: { [key: number]: string } = {
      // 경영 - 경영지원
      1000: '법무 진행현황 리스트',
      1001: '인원현황 리스트',
      1002: '경영,교육,행사',

      // 경영 - 재무회계
      1100: '기타업무품의',
      1101: '지출결의',
      1102: '외주비 현황보고',
      1103: '매출,수금 현황보고',
      1104: '손익 현황보고',
      1105: '자산부채 현황보고',

      // 설계 - 설계본부
      2000: '정기경영보고',
      2001: '계획도면/검토서',
      2002: '심의도서',
      2003: '허가도서',
      2004: '실시도서',
      2005: '진행프로젝트 보고',

      // 설계 - 도시계획본부
      2100: '정기경영보고',
      2102: '검토서',
      2103: '제안도서',
      2104: '자문도서',
      2105: '입안도서',
      2106: '심의도서',
      2107: '승인도서',

      // 사업 - 사업기획
      3100: '기타업무품의',
      3101: '현장배치 인사명령서',
      3102: '주간 업무보고',
      3103: '진행프로젝트 보고',
      3104: '금융CM팀 수주,매출 현황',
      3105: '현장별 사업현황',

      // 개발
      4000: '기타업무품의',
      4001: '사업수지(정산)',
      4002: '사업계획서',
      4005: '분양계획',
      4006: '광고계획',
      4007: '진행프로젝트 보고',

      // 마케팅
      5000: '기타업무품의',
      5004: '사전원가보고',
      5005: '실행원가보고',
      5007: '정산보고',
      5008: '주간 보고서',

      // 해외
      6001: '주간업무 보고'
    };

    return names[cat_id];
  }

  get code() {
    return `${this.corp ? this.corp.code : '000'}-${this.year}-${this.seq}`;
  }

  get stateName() {
    return EADoc.stateName(this.state);
  }

  get stateColor() {
    return EADoc.stateColor(this.state);
  }

  get hasRightToEdit() {
    return (
      ((this.state === EADocState.draft || this.state === EADocState.ongoing) &&
        this.owner.isMe) ||
      this.hasRightToApprove
    );
  }

  get hasRightToSubmit() {
    return this.state === EADocState.draft && this.owner.isMe;
  }

  // TODO: 임시조치 (완료 문서에 대해서도 수정 가능토록)
  // get hasRightToEdit() {
  //   return this.owner.isMe || this.hasRightToApprove;
  // }

  get hasRightToDelete() {
    const actionedFlowItem = this.eaFlow.items.filter(
      e => e.type !== EAFlowItemType.submit && e.action
    );
    return (
      (this.state === EADocState.draft || this.state === EADocState.ongoing) &&
      this.owner.isMe &&
      actionedFlowItem.length === 0
    );
  }

  get hasRightToApprove() {
    return (
      this.state === EADocState.ongoing &&
      this.assignedTo &&
      this.assignedTo.isMe
    );
  }

  get hasRightToCancelApprove() {
    const lastApprovedItem = this.getLastApprvedItem();
    return (
      this.state === EADocState.ongoing &&
      lastApprovedItem &&
      lastApprovedItem.user.isMe
    );
  }

  // 결재 대기중 항목
  // getCurrentFlowItem() {
  //   if (this.assignedTo === undefined) {
  //     return undefined;
  //   }

  //   const assigendTo = this.assignedTo;
  //   return this.eaFlow.items.find(
  //     flowItem => flowItem.user.id === assigendTo.id
  //   );
  // }

  // 마지막 결재 항목
  getLastApprvedItem() {
    return _.findLast(this.eaFlow.items, flowItem => {
      return (
        flowItem.action === EAFlowItemAction.approve &&
        flowItem.type !== EAFlowItemType.submit
      );
    });
  }
}
