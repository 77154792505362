import React, { useState } from 'react';
import { Comment as AntComment, Avatar, Form, Button } from 'antd';
import TextArea from 'antd/lib/input/TextArea';
import Util from '../../helpers/Util';
import CommentService from '../../services/CommentService';
import { Comment } from '../../models/Comment';
import { inject, observer } from 'mobx-react';
import { AuthStore } from '../../stores/authStore';

interface Props {
  authStore?: AuthStore;
  refTarget: number;
  refId: number;
  parentId?: number;
  onCreateComment?: (comment: Comment) => void;
  onCancel?: () => void;
}

const CommentEditor: React.FC<Props> = props => {
  const authStore = props.authStore!;
  const { refTarget, refId, parentId } = props;
  const [content, setContent] = useState('');
  const [submitting, setSubmitting] = useState(false);
  const [formValidated, setFormValidated] = useState(false);

  const handleTextChange = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
    const value = e.target.value;
    setFormValidated(value.trim().length > 0);
    setContent(value);
  };

  const onClickSubmit = async () => {
    try {
      setSubmitting(true);
      const result = await CommentService.create(
        refTarget,
        refId,
        content,
        parentId
      );
      const comment = Comment.fromJson(result.comment);
      setContent('');
      setFormValidated(false);
      setSubmitting(false);

      if (props.onCreateComment) {
        props.onCreateComment(comment);
      }
    } catch (err) {
      Util.showError(err);
      setSubmitting(false);
    }
  };

  const onClickCancel = () => {
    if (props.onCancel) {
      props.onCancel();
    }
  };

  return (
    <>
      <AntComment
        avatar={
          <Avatar
            icon="user"
            src={
              authStore.user.avatar && authStore.user.avatar.thumbnailFullPath
            }
          />
        }
        content={
          <div>
            <Form.Item>
              <TextArea
                rows={4}
                onChange={handleTextChange}
                value={content}
                autoFocus={!!parentId}
              />
            </Form.Item>
            <Form.Item>
              <Button
                htmlType="submit"
                disabled={!formValidated}
                loading={submitting}
                onClick={onClickSubmit}
                type="primary"
              >
                {parentId === undefined ? '의견 등록' : '답글 등록'}
              </Button>{' '}
              {!!parentId && <Button onClick={onClickCancel}>취소</Button>}
            </Form.Item>
          </div>
        }
      />
    </>
  );
};

export default inject('authStore')(observer(CommentEditor));
