import React, { useState } from 'react';
import { Form, Input, Button } from 'antd';
import { FormComponentProps } from 'antd/lib/form';
import { observer, inject } from 'mobx-react';
import RankSelector from '../general/RankSelect';
import DeptSelect from '../general/DeptSelect';
import { UserStore } from '../../stores/userStore';
import _ from 'lodash';
import Util from '../../helpers/Util';
import FICorpSelect from '../formcomps/FICorpSelect';

interface Props extends FormComponentProps {
  userStore?: UserStore;
  onSuccess: () => void;
}

const _UserCreateForm = (props: Props) => {
  const userStore = props.userStore!;
  const { getFieldDecorator } = props.form;
  const [confirmDirty, setConfirmDirty] = useState(false);

  const handleSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    props.form.validateFields((err, values) => {
      if (err) return;
      createItem(values);
    });
  };

  const createItem = async (values: any) => {
    try {
      values.email += '@hanlimgroup.com';
      userStore.updatingStore = true;
      await userStore.createUser(_.omit(values, 'confirm'));
      props.onSuccess();
    } catch (err) {
      Util.showError(err);
    } finally {
      userStore.updatingStore = false;
    }
  };

  const validateToNextPassword = (rule: any, value: any, callback: any) => {
    const form = props.form;
    if (value && confirmDirty) {
      form.validateFields(['confirm'], { force: true });
    }
    callback();
  };

  const compareToFirstPassword = (rule: any, value: any, callback: any) => {
    const form = props.form;
    if (value && value !== form.getFieldValue('password')) {
      callback('입력하신 두 개의 비밀번호가 일치하지 않습니다.');
    } else {
      callback();
    }
  };

  const handleConfirmBlur = (e: React.FocusEvent<HTMLInputElement>) => {
    const value = e.target.value;
    setConfirmDirty(confirmDirty || !!value);
  };

  return (
    <Form
      labelCol={{ span: 6 }}
      wrapperCol={{ span: 16 }}
      onSubmit={handleSubmit}
    >
      <Form.Item label="사번">
        {getFieldDecorator('id', {
          rules: [
            {
              required: true,
              message: '사번을 입력해주세요.'
            }
          ]
        })(<Input autoFocus />)}
      </Form.Item>
      <Form.Item label="이름">
        {getFieldDecorator('name', {
          rules: [
            {
              required: true,
              message: '이름을 입력해주세요.'
            }
          ]
        })(<Input />)}
      </Form.Item>
      <Form.Item label="이메일">
        {getFieldDecorator('email', {
          rules: [
            // {
            //   type: 'email',
            //   message: '이메일 주소가 올바르지 않습니다.'
            // },
            {
              required: true,
              message: '이메일 주소를 입력해주세요.'
            }
          ]
        })(<Input addonAfter="@hanlimgroup.com" />)}
      </Form.Item>
      <Form.Item label="비밀번호">
        {getFieldDecorator('password', {
          rules: [
            {
              required: true,
              message: '비밀번호를 입력해주세요.'
            },
            {
              min: 4,
              message: '비밀번호는 최소 4자 이상 입력해주세요.'
            },
            {
              max: 32,
              message: '비밀번호는 최대 32자 이내로 입력해주세요.'
            },
            {
              validator: validateToNextPassword
            }
          ],
          initialValue: '12345'
        })(<Input.Password />)}
      </Form.Item>
      <Form.Item label="비밀번호 확인">
        {getFieldDecorator('confirm', {
          rules: [
            {
              required: true,
              message: '확인을 위해 비밀번호를 한번 더 입력해주세요.'
            },
            {
              validator: compareToFirstPassword
            }
          ],
          initialValue: '12345'
        })(<Input.Password onBlur={handleConfirmBlur} />)}
      </Form.Item>

      <FICorpSelect form={props.form} label="소속회사" allowClear />

      <Form.Item label="부서">
        <DeptSelect form={props.form} maxDepth={0} allowClear={true} />
      </Form.Item>
      <Form.Item label="직위">
        <RankSelector form={props.form} />
      </Form.Item>
      <Form.Item wrapperCol={{ span: 12, offset: 6 }}>
        <Button type="primary" htmlType="submit">
          등록
        </Button>
      </Form.Item>
    </Form>
  );
};

const UserCreateForm = Form.create<Props>()(_UserCreateForm);
export default inject('userStore')(observer(UserCreateForm));
