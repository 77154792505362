import React from 'react';
import { Route, Switch, Redirect } from 'react-router-dom';
import UnderConst from '../../components/UnderConst';
import Dashboard from '../projects/common/Dashboard';
import OrderLedger from '../projects/common/OrderLedger';
import OrderList from '../projects/common/OrderList';
import OrderDetail from '../projects/orders/OrderDetail';
import { OrderState } from '../../models/Order';
import DeptCodeList from '../../components/general/DeptCodeList';
import MyEducationList from '../works/educations/MyEducationList';
import EducationSchedules from '../works/educations/EducationSchedules';
import ArchiveGroupBrowser from '../../components/general/ArchiveGroupBrowser';
import CollectPlan from '../management/finance/CollectPlan';
import OrderEADocList from '../projects/orders/OrderEADocList';
import EADocDrafter from '../eas/EADocDrafter';

interface Props {}

const Index: React.FC<Props> = props => {
  const menu = 'business';
  const submenu = 'cm1';
  const deptId = 30;

  return (
    <Switch>
      <Route path="/business/cm1/uc" component={UnderConst} />
      <Route path="/business/cm1/archive" component={ArchiveGroupBrowser} />

      <Route path="/business/cm1" exact>
        <Redirect to="/business/cm1/dashboard" />
      </Route>

      <Route path="/business/cm1/dashboard">
        <Dashboard menu={menu} deptId={deptId} hideDeptSelector />
      </Route>
      <Route path="/business/cm1/ongoing">
        <OrderLedger
          title="진행 프로젝트"
          menu={menu}
          deptId={deptId}
          hideDeptSelector
          state={OrderState.ongoing}
        />
      </Route>

      <Route path="/business/cm1/eadocs/ord">
        <OrderEADocList
          menu={menu}
          submenu={submenu}
          deptId={deptId}
          hideDeptSelector
        />
      </Route>
      <Route path="/business/cm1/draft/ord">
        <EADocDrafter title="수주정보보고서" refTarget="ord" />
      </Route>
      <Route path="/business/cm1/orders/view" component={OrderDetail} />
      <Route path="/business/cm1/orders">
        <OrderList deptId={deptId} hideDeptSelector />
      </Route>
      <Route path="/business/cm1/ordins">
        <OrderLedger
          title="수주견적"
          menu={menu}
          deptId={deptId}
          hideDeptSelector
          target="ordin"
        />
      </Route>
      <Route path="/business/cm1/ctrins">
        <OrderLedger
          title="수주계약"
          menu={menu}
          deptId={deptId}
          hideDeptSelector
          target="ctrin"
        />
      </Route>
      <Route path="/business/cm1/ordouts">
        <OrderLedger
          title="외주실행"
          menu={menu}
          deptId={deptId}
          hideDeptSelector
          target="ordout"
        />
      </Route>
      <Route path="/business/cm1/ctrouts">
        <OrderLedger
          title="외주계약"
          menu={menu}
          deptId={deptId}
          hideDeptSelector
          target="ctrout"
        />
      </Route>

      <Route path="/business/cm1/collect">
        <CollectPlan menu="business/cm1" hideCorpSelect />
      </Route>

      <Route path="/business/cm1/settings/stages">
        <DeptCodeList
          menu={menu}
          deptId={deptId}
          title="부서별 진행단계"
          fieldName="order_stages"
        />
      </Route>
      <Route path="/business/cm1/settings/ctypes">
        <DeptCodeList
          menu={menu}
          deptId={deptId}
          title="부서별 수금구분"
          fieldName="cp_terms"
        />
      </Route>

      <Route path="/business/cm1/myedu" component={MyEducationList} />
      <Route path="/business/cm1/edu" component={EducationSchedules} />
    </Switch>
  );
};

export default Index;
