import React, { useState, useEffect, useRef } from 'react';
import { Button, Table, Progress, InputNumber, Form, Drawer } from 'antd';
import { withRouter, RouteComponentProps } from 'react-router';
import { FormComponentProps } from 'antd/lib/form';
import Util from '../../helpers/Util';
import VacationService, {
  VacationSearchOption
} from '../../services/VacationService';
import { VacationStat } from '../../models/Vacation';
import LinkButton from '../../components/general/LinkButton';
import VacationLogs from '../works/VacationLogs';
import _ from 'lodash';

interface Props extends RouteComponentProps, FormComponentProps {}

const VacationStats: React.FC<Props> = props => {
  const [searchOption, setSearchOption] = useState<VacationSearchOption>({
    year: new Date().getFullYear()
  });
  const [items, setItems] = useState<VacationStat[]>([]);
  const [loading, setLoading] = useState(false);
  const { getFieldDecorator } = props.form;

  useEffect(() => {
    const fetchItems = async () => {
      setLoading(true);
      try {
        const result = await VacationService.fetchRecordStats(searchOption);
        const items = VacationStat.fromJsonArray(result.stats);
        setItems(_.sortBy(items, ['dept.id']));
      } catch (error) {
        Util.showError(error);
      } finally {
        setLoading(false);
      }
    };

    fetchItems();
  }, [searchOption]);

  const onSubmitSearchForm = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    props.form.validateFields((err, values) => {
      if (err) return;
      setSearchOption(values);
    });
  };

  const itemRef = useRef<VacationStat>(new VacationStat());
  const [detailVisible, setDetailVisible] = useState(false);
  const onClickItem = (item: VacationStat) => {
    itemRef.current = item;
    setDetailVisible(true);
  };

  const columns = [
    {
      title: '부서',
      dataIndex: 'dept.name',
      render: (text: string, record: VacationStat) => {
        return (
          <LinkButton onClick={e => onClickItem(record)}>{text}</LinkButton>
        );
      }
    },
    {
      title: '년도',
      dataIndex: 'year'
    },
    {
      title: '발생연차',
      dataIndex: 'given',
      align: 'right' as 'right'
    },
    {
      title: '사용연차',
      dataIndex: 'used',
      align: 'right' as 'right'
    },
    {
      title: '잔여연차',
      dataIndex: 'remains',
      align: 'right' as 'right'
    },
    {
      title: '사용률',
      dataIndex: 'usedRate',
      width: '40%',
      render: (text: string, record: VacationStat) => {
        return <Progress percent={record.usedRate} style={{ width: '90%' }} />;
      }
    }
  ];

  return (
    <>
      <h2>부서별 연차사용 현황</h2>

      <Form
        layout="inline"
        labelCol={{ span: 0 }}
        wrapperCol={{ span: 24 }}
        style={{ marginBottom: 7 }}
        onSubmit={onSubmitSearchForm}
      >
        <Form.Item>
          {getFieldDecorator('year', {
            initialValue: searchOption.year
          })(<InputNumber placeholder="년도" style={{ width: 100 }} />)}
        </Form.Item>

        <Form.Item>
          <Button type="primary" htmlType="submit">
            검색
          </Button>
        </Form.Item>
      </Form>

      <Table
        columns={columns}
        dataSource={items}
        rowKey="id"
        pagination={false}
        bordered={true}
        loading={loading}
        size="middle"
      />

      <Drawer
        closable={false}
        placement="right"
        width="80%"
        visible={detailVisible}
        onClose={e => setDetailVisible(false)}
        destroyOnClose={true}
      >
        <VacationLogs
          searchOption={{
            year: itemRef.current.year,
            dept_id: itemRef.current.dept.id
          }}
        />
      </Drawer>
    </>
  );
};

const _VacationStats = Form.create<Props>()(VacationStats);
export default withRouter(_VacationStats);
