import React, { useState } from 'react';
import Form, { FormComponentProps } from 'antd/lib/form';
import { Button, InputNumber } from 'antd';
import FCNumberInput from '../../../components/formcomps/FCNumberInput';
import Util from '../../../helpers/Util';
import InlineItem from '../../../components/formcomps/InlineItem';
import FICorpSelect from '../../../components/formcomps/FICorpSelect';
import { CarryOver } from '../../../models/Finance';
import FinanceService from '../../../services/FinanceService';

interface Props extends FormComponentProps {
  item?: CarryOver;
  onSuccess?: () => void;
}

const CarryOverEditor: React.FC<Props> = props => {
  const { item } = props;
  const { getFieldDecorator } = props.form;

  console.count('CarryOverEditor');

  const onSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    props.form.validateFields((err, values) => {
      if (err) return;
      processItem(values);
    });
  };

  const [submitting, setSubmitting] = useState(false);

  const processItem = async (values: any) => {
    try {
      if (!values.corp_id) {
        Util.showError('법인을 선택해주세요.');
        return;
      }

      setSubmitting(true);
      if (item) {
        await FinanceService.updateCarryOver(values.year, values.corp_id, {
          amount: values.amount
        });
      } else {
        await FinanceService.createCarryOver(values);
      }

      Util.showSuccess('저장되었습니다.');
      if (props.onSuccess) props.onSuccess();
    } catch (err) {
      Util.showError(err);
    } finally {
      setSubmitting(false);
    }
  };

  const formItemLayout = {
    labelCol: { span: 4 },
    wrapperCol: { span: 19 }
  };

  return (
    <Form {...formItemLayout} onSubmit={onSubmit}>
      <div className="hl-form" style={{ marginBottom: '10px' }}>
        <Form.Item label="년도">
          {getFieldDecorator('year', {
            initialValue: (item && item.year) || new Date().getFullYear(),
            rules: [{ required: true, message: '년도를 입력해주세요' }]
          })(
            <InputNumber
              placeholder="년도"
              style={{ width: 100 }}
              formatter={value => `${value}년`}
              disabled={item !== undefined}
            />
          )}
        </Form.Item>

        <Form.Item label="법인명">
          <InlineItem width="80%">
            <FICorpSelect
              form={props.form}
              options={{ initialValue: (item && item.corp_id) || 1 }}
              disabled={item !== undefined}
            />
          </InlineItem>
        </Form.Item>

        <Form.Item label="이월금액">
          <FCNumberInput
            form={props.form}
            name="amount"
            autoFocus
            options={{ initialValue: (item && item.amount) || 0 }}
          />
        </Form.Item>

        <Form.Item wrapperCol={{ span: 12, offset: 4 }}>
          <Button type="primary" htmlType="submit" disabled={submitting}>
            {item ? '수정' : '등록'}
          </Button>
        </Form.Item>
      </div>
    </Form>
  );
};

export default Form.create<Props>()(CarryOverEditor);
