import React, { useState } from 'react';
import { withRouter, RouteComponentProps } from 'react-router';
import { Tabs, Row, Col } from 'antd';
import EADocListView from './eas/EADocListView';
import { EADocListType } from '../models/EAS';
import LinkButton from '../components/general/LinkButton';
const { TabPane } = Tabs;

interface Props extends RouteComponentProps {}

const HomeEADocWidget: React.FC<Props> = props => {
  const [activeTabKey, setActiveTabKey] = useState(
    localStorage.getItem('homeEADocActiveTabKey') || 'sent'
  );

  const onChangeEADocType = (key: string) => {
    localStorage.setItem('homeEADocActiveTabKey', key);
    setActiveTabKey(key);
  };

  const onClickShowAll = () => {
    props.history.push(`/works/eas/list?type=${activeTabKey}`);
  };

  return (
    <>
      <h3>전자결재</h3>
      <Tabs type="card" onChange={onChangeEADocType} activeKey={activeTabKey}>
        <TabPane tab="발신문서" key="sent" />
        <TabPane tab="대기문서" key="waiting" />
        <TabPane tab="진행문서" key="ongoing" />
      </Tabs>
      <EADocListView
        type={activeTabKey as EADocListType}
        pageSize={5}
        hideTitle
        hideToolbar
        hidePagination
      />
      <Row type="flex" justify="space-between">
        <Col></Col>
        <Col>
          <LinkButton onClick={onClickShowAll}>전체보기</LinkButton>
        </Col>
      </Row>
    </>
  );
};

export default withRouter(HomeEADocWidget);
