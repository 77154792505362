import Service from './Index';

const PlanService = {
  fetchItems: (
    limit: number,
    page: number,
    keyword?: string,
    browseAll?: boolean,
    date?: string
  ) => {
    const offset = (page - 1) * limit;
    return Service.get('/etc/plans', {
      limit,
      offset,
      keyword,
      browseAll,
      date
    });
  },
  fetchItemsForMonth: (year: number, month: number) => {
    return Service.get('/etc/plans_for_month', {
      year,
      month
    });
  },
  fetchItem: (id: number) => {
    return Service.get(`/etc/plans/${id}`);
  },
  create: (data: any) => {
    return Service.post('/etc/plans', data);
  },
  update: (id: number, data: any) => {
    return Service.put(`/etc/plans/${id}`, data);
  },
  delete: (id: number) => {
    return Service.delete(`/etc/plans/${id}`);
  }
};

export default PlanService;
