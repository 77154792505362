import React, { useState, useEffect } from 'react';
import { Table, Button, Row, Col, Icon, Drawer } from 'antd';
import AppService from '../../services/AppService';
import Util from '../../helpers/Util';
import StringCodeListEditor from './StringCodeListEditor';
import { StringCode } from '../../models/Common';
import Form, { FormComponentProps } from 'antd/lib/form';
import FIHQSelect from '../formcomps/FIHQSelect';

interface Props extends FormComponentProps {
  title: string;
  fieldName: string;
  menu?: string;
  deptId?: number;
}

const DeptCodeList: React.FC<Props> = props => {
  const { title, fieldName, menu = 'projects' } = props;
  const [loading, setLoading] = useState(true);
  const [items, setItems] = useState<StringCode[]>([]);
  const [deptId, setDeptId] = useState(props.deptId || 16);

  useEffect(() => {
    const fetchItems = async () => {
      setLoading(true);
      try {
        const result = await AppService.fetchDeptValues(deptId, [fieldName]);
        const codes = result[fieldName] || [];
        const items = codes.map((code: string) => ({ code }));
        setItems(items);
      } catch (error) {
        Util.showError(error);
      } finally {
        setLoading(false);
      }
    };

    fetchItems();
  }, [deptId, fieldName]);

  const onChangeDept = (deptId: number) => {
    setDeptId(deptId);
    localStorage.setItem(`${menu}DefaultDeptId`, String(deptId));
  };

  const [editorVisible, setEditorVisible] = useState(false);

  const onClickEditButton = () => {
    setEditorVisible(true);
  };
  const onCloseEditor = () => {
    setEditorVisible(false);
  };

  const onSubmitChanges = async (changes: StringCode[]) => {
    setLoading(true);
    try {
      const values = changes.map(el => el.code);
      await AppService.updateDeptValues(deptId, fieldName, values);
      setItems(changes);
      setEditorVisible(false);
    } catch (error) {
      Util.showError(error);
    } finally {
      setLoading(false);
    }
  };

  const columns = [
    {
      title: '이름',
      dataIndex: 'code'
    }
  ];

  return (
    <>
      <h2>{title}</h2>
      <Form
        layout="inline"
        labelCol={{ span: 0 }}
        wrapperCol={{ span: 24 }}
        style={{ marginBottom: 7 }}
      >
        <Row type="flex" justify="space-between">
          <Col>
            <FIHQSelect
              form={props.form}
              options={{ initialValue: deptId }}
              width="180px"
              allowClear={false}
              onChange={onChangeDept}
            />
          </Col>
          <Col>
            <Button onClick={onClickEditButton}>
              <Icon type="edit" />
              {title} 관리
            </Button>
          </Col>
        </Row>
      </Form>

      <Table
        columns={columns}
        dataSource={items}
        pagination={false}
        rowKey="code"
        loading={loading}
        bordered={true}
        size="middle"
      />

      <Drawer
        title={`${title} 관리`}
        placement="right"
        width={800}
        visible={editorVisible}
        onClose={onCloseEditor}
        destroyOnClose={true}
      >
        <StringCodeListEditor items={items} onSubmit={onSubmitChanges} />
      </Drawer>
    </>
  );
};

export default Form.create<Props>()(DeptCodeList);
