import React, { useState, useEffect, useRef } from 'react';
import {
  Table,
  Row,
  Col,
  Button,
  Icon,
  Popconfirm,
  Select,
  Drawer
} from 'antd';
import { RouteComponentProps, withRouter } from 'react-router';
import Util from '../../helpers/Util';
import {
  HDescriptions,
  HDRowItem
} from '../../components/general/HDescriptions';
import AttachedFileList from '../../components/general/AttachedFileList';
import { Education, EducationUser } from '../../models/Education';
import EducationService from '../../services/EducationService';
import UserPicker from '../../components/general/UserPicker';
import { User } from '../../models/User';
import LinkButton from '../../components/general/LinkButton';
import AButton from '../../components/general/AButton';
import EADocDetail from '../eas/EADocDetail';

interface Props extends RouteComponentProps {
  id: number;
  onChange?: () => void;
  fetchDelayed?: boolean;
}

const EducationDetail: React.FC<Props> = props => {
  const itemId = props.id || 0;
  const { onChange, fetchDelayed = false } = props;
  const [loading, setLoading] = useState(false);
  const [submitting, setSubmitting] = useState(false);
  const [item, setItem] = useState<Education>(new Education());
  const [items, setItems] = useState<EducationUser[]>([]);
  const [refreshTrigger, setRefreshTrigger] = useState(1);

  useEffect(() => {
    const fetchItem = async (id: number) => {
      setLoading(true);
      try {
        const result = await EducationService.fetchItem(id);
        const education = Education.fromJson(result.education);
        setItem(education);

        const result2 = await EducationService.fetchUsers(id);
        const items = EducationUser.fromJsonArray(result2);
        setItems(items);
      } catch (error) {
        Util.showError(error);
      } finally {
        setLoading(false);
      }
    };

    if (itemId > 0) {
      if (fetchDelayed && refreshTrigger === 1) {
        setTimeout(() => {
          fetchItem(itemId);
        }, 400);
      } else {
        fetchItem(itemId);
      }
    }
  }, [itemId, fetchDelayed, refreshTrigger]);

  useEffect(() => {
    onChange && onChange();
  }, [onChange, refreshTrigger]);

  const columns = [
    {
      title: '사번',
      dataIndex: 'user.id',
      align: 'right' as 'right',
      width: '100px',
      sorter: (a: EducationUser, b: EducationUser) =>
        a.user_id > b.user_id ? 1 : -1
    },
    {
      title: '성명',
      dataIndex: 'user.name',
      defaultSortOrder: 'ascend' as 'ascend',
      sorter: (a: EducationUser, b: EducationUser) =>
        a.user.name > b.user.name ? 1 : -1
    },
    {
      title: '부서',
      dataIndex: 'user.deptName',
      sorter: (a: EducationUser, b: EducationUser) =>
        a.user.deptName > b.user.deptName ? 1 : -1
    },
    {
      title: '직급',
      dataIndex: 'user.rank'
    },
    {
      title: '출결',
      dataIndex: 'state',
      sorter: (a: EducationUser, b: EducationUser) =>
        a.state > b.state ? 1 : -1,
      render: (text: string, record: EducationUser) => {
        return (
          <Select
            disabled={!item.finished}
            style={{ width: '100px' }}
            value={record.state}
            onChange={(value: number) => onChangeState(record, value)}
          >
            <Select.Option value={0}>N/A</Select.Option>
            <Select.Option value={1}>출석</Select.Option>
            <Select.Option value={2}>지각</Select.Option>
            <Select.Option value={3}>결석</Select.Option>
          </Select>
        );
      }
    },
    {
      title: '설문조사',
      dataIndex: 'poll',
      render: (text: string, record: EducationUser) => {
        if (!item.finished) return '';
        return 'N/A';
      }
    },
    {
      title: '비고',
      dataIndex: 'enforced',
      sorter: (a: EducationUser, b: EducationUser) =>
        a.enforced > b.enforced ? 1 : -1,
      render: (text: string, record: EducationUser) => {
        return record.enforced ? '지정' : '';
      }
    },
    {
      dataIndex: 'etc',
      width: 100,
      render: (text: string, record: EducationUser) => {
        if (item.finished) {
          if (record.state === 3) {
            return (
              <LinkButton
                disabled={!Util.isDefined(record.eaid)}
                onClick={e => onClickReason(record)}
              >
                불출석사유서
              </LinkButton>
            );
          }
        } else {
          if (record.enforced) {
            return (
              <>
                <Popconfirm
                  title="정말 취소하시겠습니까?"
                  onConfirm={e => onClickDeleteUser(record.user_id)}
                >
                  <AButton>수강취소</AButton>
                </Popconfirm>
              </>
            );
          }
        }
      }
    }
  ];

  const [selectorVisible, setSelectorVisible] = useState(false);

  const onClickAddUsers = () => {
    setSelectorVisible(true);
  };

  const onCancelUserPicker = () => {
    setSelectorVisible(false);
  };

  const onOkUserPicker = async (selectedItems: User[]) => {
    setSelectorVisible(false);

    if (selectedItems.length > item.available_count) {
      Util.showError('모집인원을 초과하여 등록할 수 없습니다.');
      return;
    }

    try {
      setSubmitting(true);
      await EducationService.addEnforcedUsers(
        itemId,
        selectedItems.map(e => e.id)
      );
      Util.showSuccess('등록되었습니다.');
      setRefreshTrigger(v => v + 1);
    } catch (error) {
      Util.showError(error);
    } finally {
      setSubmitting(false);
    }
  };

  const eadocIdRef = useRef(0);
  const [eadocVisible, setEADocVisible] = useState(false);

  const onClickReason = (record: EducationUser) => {
    if (record.eaid) {
      eadocIdRef.current = record.eaid;
      setEADocVisible(true);
    }
  };

  const onCloseEADoc = () => {
    setEADocVisible(false);
  };

  const onClickDeleteUser = async (userId: number) => {
    try {
      setSubmitting(true);
      await EducationService.deleteUser(itemId, userId);
      Util.showSuccess('취소되었습니다.');
      setRefreshTrigger(v => v + 1);
    } catch (error) {
      Util.showError(error);
    } finally {
      setSubmitting(false);
    }
  };

  const updateUsersState = async (
    itemId: number,
    userIds: number[],
    state: number
  ) => {
    try {
      setSubmitting(true);
      await EducationService.updateUsersState(itemId, userIds, state);
      Util.showSuccess('반영되었습니다.');
      setRefreshTrigger(v => v + 1);
    } catch (error) {
      Util.showError(error);
    } finally {
      setSubmitting(false);
    }
  };

  const onChangeState = (item: EducationUser, value: number) => {
    updateUsersState(itemId, [item.user_id], value);
  };

  const [selectedItems, setSelectedItems] = useState<EducationUser[]>([]);
  const selectedStateRef = useRef(0);
  const rowSelection = {
    onChange: (selectedRowKeys: any, selectedRows: any) => {
      setSelectedItems(selectedRows);
    }
  };

  const onChangeSelectedState = (value: number) => {
    selectedStateRef.current = value;
  };

  const onClickChangeSelected = async () => {
    const userIds = selectedItems.map(e => e.user_id);
    updateUsersState(itemId, userIds, selectedStateRef.current);
  };

  // if (loading) {
  //   return <Spinner />;
  // }

  return (
    <>
      <h2>{item.title}</h2>
      <HDescriptions>
        <HDRowItem label="강사명">{item.instructor}</HDRowItem>
        <HDRowItem label="교육일시">{Util.formatDateTime(item.date)}</HDRowItem>
        <HDRowItem label="교육개요">{item.summary}</HDRowItem>
        <HDRowItem label="신청인원">
          {item.apply_count} / {item.seat_count}
        </HDRowItem>
        <HDRowItem label="상태">{item.finished ? '종료' : '진행'}</HDRowItem>
        {item.files.length > 0 && (
          <HDRowItem label="교육자료">
            <AttachedFileList items={item.files} />
          </HDRowItem>
        )}
      </HDescriptions>

      <Row
        type="flex"
        justify="space-between"
        style={{ marginTop: 10, marginBottom: 10 }}
      >
        <Col>
          <h3>수강신청자</h3>
        </Col>
        <Col>
          <Select
            disabled={!item.finished || selectedItems.length === 0}
            style={{ width: '100px' }}
            defaultValue={0}
            onChange={onChangeSelectedState}
          >
            <Select.Option value={0}>N/A</Select.Option>
            <Select.Option value={1}>출석</Select.Option>
            <Select.Option value={2}>지각</Select.Option>
            <Select.Option value={3}>결석</Select.Option>
          </Select>{' '}
          <Button
            onClick={onClickChangeSelected}
            disabled={
              submitting || !item.finished || selectedItems.length === 0
            }
          >
            선택항목({selectedItems.length}) 출결상태 일괄변경
          </Button>{' '}
          <Button onClick={onClickAddUsers} disabled={submitting}>
            <Icon type="plus" />
            지정 신청자 등록
          </Button>
        </Col>
      </Row>

      <Table
        rowSelection={rowSelection}
        columns={columns}
        dataSource={items}
        pagination={false}
        rowKey="user_id"
        loading={loading || submitting}
        bordered={true}
        size="middle"
      />

      <UserPicker
        visible={selectorVisible}
        onCancel={onCancelUserPicker}
        onOk={onOkUserPicker}
      />

      <Drawer
        closable={false}
        placement="right"
        width="85%"
        visible={eadocVisible}
        onClose={onCloseEADoc}
        destroyOnClose={true}
      >
        <EADocDetail id={eadocIdRef.current} fetchDelayed={true} />
      </Drawer>
    </>
  );
};

export default withRouter(EducationDetail);
