import moment from 'moment';
import Util from '../helpers/Util';
import { User } from './User';
import { File } from './File';
import { EADoc } from './EAS';

export class Asset {
  id = 0;
  code = '';
  name = '';
  category1 = '';
  category2 = '';
  manufacturer = '';
  model = '';
  price = 0;
  purchase_date?: moment.Moment;
  dispose_date?: moment.Moment;

  // derived
  [key: string]: any; // for string index (subscript)
  user?: User;

  static fromJsonArray(sources: any[]) {
    return sources.map(source => Asset.fromJson(source));
  }

  static fromJson(source: any) {
    let item = new Asset();
    for (const key of Object.keys(source)) {
      if (key in item) {
        item[key] = source[key];
      }
    }

    item.purchase_date = Util.stringToMoment(source.purchase_date);
    item.dispose_date = Util.stringToMoment(source.dispose_date);

    if (source.user_id) {
      item.user = User.fromJson({
        id: source.user_id,
        name: source.user_name,
        dept_id: source.dept_id,
        rank: source.rank
      });
    }

    return item;
  }
}

export class AssetUser {
  id = 0;
  asset_id = 0;
  user_id = 0;
  begin_date?: moment.Moment;
  end_date?: moment.Moment;
  note = '';

  // derived
  [key: string]: any; // for string index (subscript)
  user: User = new User();

  static fromJsonArray(sources: any[]) {
    return sources.map(source => AssetUser.fromJson(source));
  }

  static fromJson(source: any) {
    let item = new AssetUser();
    for (const key of Object.keys(source)) {
      if (key in item) {
        item[key] = source[key];
      }
    }

    item.begin_date = Util.stringToMoment(source.begin_date);
    item.end_date = Util.stringToMoment(source.end_date);

    item.user = User.fromJson({
      id: source.user_id,
      name: source.user_name,
      dept_id: source.dept_id,
      rank: source.rank
    });

    return item;
  }
}

export class AssetEADoc {
  id = 0;
  asset_id = 0;
  eadoc_id = 0;
  title = '';
  file_id = 0;
  note = '';

  // derived
  [key: string]: any; // for string index (subscript)
  eadoc: EADoc = new EADoc();
  eadocs: EADoc[] = []; // NOTE: 실제로 관련문서 하나지만 FCEADocInput 파라미터 전달시 배열전달하는 편의를 위해
  file?: File;
  files: File[] = []; // NOTE: 실제로 첨부파일 하나지만 FCFileInput 파라미터 전달시 배열전달하는 편의를 위해

  static fromJsonArray(sources: any[]) {
    return sources.map(source => AssetEADoc.fromJson(source));
  }

  static fromJson(source: any) {
    let item = new AssetEADoc();
    for (const key of Object.keys(source)) {
      if (key in item) {
        item[key] = source[key];
      }
    }

    item.eadoc.id = source.eadoc_id;
    item.eadoc.title = source.title;
    item.eadocs = [item.eadoc];

    if (source.file) {
      item.file = File.fromJson(source.file);
      item.files = [item.file];
    }

    return item;
  }
}

export class AssetReport {
  category1 = '';
  category2 = '';
  count = 0;
  total_price = 0;

  // derived
  [key: string]: any; // for string index (subscript)

  static fromJsonArray(sources: any[]) {
    return sources.map(source => AssetReport.fromJson(source));
  }

  static fromJson(source: any) {
    let item = new AssetReport();
    for (const key of Object.keys(source)) {
      if (key in item) {
        item[key] = source[key];
      }
    }
    return item;
  }

  get id(): string {
    return `${this.category1}-${this.category2}`;
  }
}
