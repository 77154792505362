import React, { useState, useEffect } from 'react';
import { Table, Icon, Drawer, Button, Row, Col } from 'antd';
import Util from '../../helpers/Util';
import { File } from '../../models/File';
import FileService from '../../services/FileService';
import filesize from 'filesize';
import LinkButton from './LinkButton';
import ArchiveFileRevUploader from './ArchiveFileRevUploader';
import AButton from './AButton';

interface Props {
  file: File;
  onChange?: () => void;
}

const ArchiveFileGroup: React.FC<Props> = props => {
  const { file } = props;

  const [items, setItems] = useState<File[]>([]);
  const [loading, setLoading] = useState(false);
  const [refreshTrigger, setRefreshTrigger] = useState(1);

  useEffect(() => {
    const fetchItems = async (group_id: number) => {
      setLoading(true);
      try {
        const result = await FileService.fetchGroupItems(group_id);
        const files = File.fromJsonArray(result.files);
        setItems(files);
      } catch (error) {
        Util.showError(error);
      } finally {
        setLoading(false);
      }
    };

    fetchItems(file.group_id);
  }, [file, refreshTrigger]);

  const onClickDownloadButton = async (item: File) => {
    try {
      setLoading(true);
      await FileService.downloadFile(item.id, item.original_name);
    } catch (error) {
      Util.showError(error);
    } finally {
      setLoading(false);
    }
  };

  const columns = [
    {
      title: '버전',
      dataIndex: 'revision',
      width: 45,
      align: 'right' as 'right'
    },
    {
      title: '이름',
      dataIndex: 'original_name',
      ellipsis: true,
      render: (text: string, record: File) => {
        return (
          <>
            <LinkButton onClick={e => onClickDownloadButton(record)}>
              <Icon type="download" />
            </LinkButton>{' '}
            {record.isPreviewable ? (
              <AButton onClick={e => FileService.previewFile(record)}>
                {text}
              </AButton>
            ) : (
              <>{text}</>
            )}
          </>
        );
      }
    },
    {
      title: '크기',
      dataIndex: 'size',
      width: 90,
      align: 'right' as 'right',
      render: (text: string, record: File) => {
        return filesize(record.size, { round: 0 });
      }
    },
    {
      title: '등록자',
      width: 100,
      dataIndex: 'owner.name'
    },
    {
      title: '등록일',
      dataIndex: 'created_at',
      width: 100,
      render: (text: string, record: File) => Util.formatDate(record.created_at)
    }
    // {
    //   width: '90px',
    //   render: (text: string, record: File) => {
    //     return (
    //       <>
    //         <LinkButton onClick={e => onClickEditButton(record)}>
    //           수정
    //         </LinkButton>{' '}
    //         |{' '}
    //         <Popconfirm
    //           title="정말 삭제하시겠습니까?"
    //           onConfirm={e => onClickDeleteButton(record)}
    //         >
    //           <LinkButton>삭제</LinkButton>
    //         </Popconfirm>
    //       </>
    //     );
    //   }
    // }
  ];

  const [fileEditorVisible, setFileEditorVisible] = useState(false);

  const onClickAddButton = () => {
    setFileEditorVisible(true);
  };

  const onCloseEditor = () => {
    setFileEditorVisible(false);
  };

  const onSuccessEditing = () => {
    setRefreshTrigger(v => v + 1);
    setFileEditorVisible(false);
    props.onChange && props.onChange();
  };

  return (
    <>
      <Row type="flex" justify="space-between" style={{ marginBottom: 10 }}>
        <Col></Col>
        <Col>
          <Button onClick={onClickAddButton}>
            <Icon type="plus" />
            버전 추가
          </Button>
        </Col>
      </Row>

      <Table
        columns={columns}
        dataSource={items}
        rowKey="id"
        pagination={false}
        loading={loading}
        size="middle"
        bordered={true}
      />

      <Drawer
        title="파일 버전 추가"
        width={800}
        visible={fileEditorVisible}
        onClose={onCloseEditor}
        destroyOnClose={true}
      >
        <ArchiveFileRevUploader file={file} onSuccess={onSuccessEditing} />
      </Drawer>
    </>
  );
};

export default ArchiveFileGroup;
