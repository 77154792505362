import React, { useState } from 'react';
import { Row, Col, Button, Icon, Drawer } from 'antd';
import OrderViewer from './OrderViewer';
import OrderEdit from './OrderEdit';
import { Order } from '../../../models/Order';

interface Props {
  title: string;
  id: number;
  allowEditing?: boolean;
  onChangeOrderInfo?: (order: Order) => void;
}

const OrderView: React.FC<Props> = props => {
  console.count('OrderView');

  const { allowEditing = false } = props;
  const [editorVisible, setEditorVisible] = useState(false);
  const [refreshTrigger, setRefreshTrigger] = useState(1);

  const onClickEdit = () => {
    setEditorVisible(true);
  };

  const onCloseEditor = () => {
    setEditorVisible(false);
  };

  const onSaveOrderInfo = (updatedOrder: Order) => {
    setEditorVisible(false);
    setRefreshTrigger(v => v + 1); // TODO: 불필요한 fetch 발생: refresh 하지말고 udpatedOrder를 전달
    props.onChangeOrderInfo && props.onChangeOrderInfo(updatedOrder);
  };

  return (
    <>
      <h2>수주정보 - {props.title}</h2>
      {allowEditing ? (
        <Row gutter={12}>
          <Col className="gutter-row" span={20}>
            <OrderViewer id={props.id} refreshTrigger={refreshTrigger} />
          </Col>
          <Col className="gutter-row" span={4}>
            <Button
              block
              style={{ marginTop: '10px' }}
              onClick={e => onClickEdit()}
            >
              <Icon type="edit" />수 정
            </Button>
          </Col>
        </Row>
      ) : (
        <OrderViewer id={props.id} refreshTrigger={refreshTrigger} />
      )}

      <Drawer
        closable={false}
        placement="right"
        width="85%"
        visible={editorVisible}
        onClose={onCloseEditor}
        destroyOnClose={true}
      >
        <OrderEdit id={props.id} title={props.title} onSave={onSaveOrderInfo} />
      </Drawer>
    </>
  );
};

export default OrderView;
