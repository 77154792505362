import React, { useState } from 'react';
import { Button, Drawer, Tabs } from 'antd';
import EADocListForSelection from './EADocListForSelection';
import { EADoc, EADocListType } from '../../models/EAS';
import EADocDetail from './EADocDetail';
import { inject, observer } from 'mobx-react';
import { AuthStore } from '../../stores/authStore';
import _ from 'lodash';

interface Props {
  visible: boolean;
  onCancel: () => void;
  onOk: (selectedItems: EADoc[]) => void;
  refTarget?: string;
  authStore?: AuthStore;
}

const EADocSelectorDrawer: React.FC<Props> = props => {
  const authStore = props.authStore!;
  const [selectedItems, setSelectedItems] = useState<EADoc[]>([]);
  const [detailVisible, setDetailVisible] = useState(false);
  const [detailItemId, setDetailItemId] = useState(0);

  const handleOK = () => {
    props.onOk(selectedItems);
  };

  const handleClose = () => {
    props.onCancel();
  };

  const handleChangeSelections = (items: EADoc[]) => {
    setSelectedItems(items);
  };

  const handleClickItem = (item: EADoc) => {
    setDetailItemId(item.id);
    setDetailVisible(true);
  };

  const onCloseDetail = () => {
    setDetailVisible(false);
  };

  // TODO: 리팩토링
  // menus/eas.js, SidebarNew.tsx, EASRouter 참고
  let allowAccessAllEADocs = undefined;
  if (authStore.user.dept) {
    const userDeptId = authStore.user.dept.id;
    allowAccessAllEADocs = _.find([1, 2, 11, 12, 13], e => e === userDeptId);
  }

  console.log('EADocSelectorDrawer');

  return (
    <Drawer
      title="문서 선택"
      placement="right"
      width={'85%'}
      visible={props.visible}
      onClose={handleClose}
      destroyOnClose={true}
    >
      <Tabs type="card">
        <Tabs.TabPane tab="완료문서" key="tab1">
          <EADocListForSelection
            type={EADocListType.done}
            refTarget={props.refTarget}
            onChangeSelections={handleChangeSelections}
            onClickItem={handleClickItem}
          />
        </Tabs.TabPane>
        <Tabs.TabPane tab="참조문서" key="tab2">
          <EADocListForSelection
            type={EADocListType.cc}
            refTarget={props.refTarget}
            onChangeSelections={handleChangeSelections}
            onClickItem={handleClickItem}
          />
        </Tabs.TabPane>
        <Tabs.TabPane tab="부서수신문서" key="tab3">
          <EADocListForSelection
            type={EADocListType.dreceived}
            refTarget={props.refTarget}
            onChangeSelections={handleChangeSelections}
            onClickItem={handleClickItem}
          />
        </Tabs.TabPane>
        <Tabs.TabPane tab="부서발신문서" key="tab4">
          <EADocListForSelection
            type={EADocListType.dsent}
            refTarget={props.refTarget}
            onChangeSelections={handleChangeSelections}
            onClickItem={handleClickItem}
          />
        </Tabs.TabPane>
        {allowAccessAllEADocs && (
          <Tabs.TabPane tab="전체문서" key="tab5">
            <EADocListForSelection
              type={EADocListType.all}
              refTarget={props.refTarget}
              onChangeSelections={handleChangeSelections}
              onClickItem={handleClickItem}
            />
          </Tabs.TabPane>
        )}
      </Tabs>

      <div
        style={{
          position: 'absolute',
          left: 0,
          bottom: 0,
          width: '100%',
          borderTop: '1px solid #e9e9e9',
          padding: '10px 16px',
          background: '#fff',
          textAlign: 'right'
        }}
      >
        <Button onClick={handleClose} style={{ marginRight: 8 }}>
          취소
        </Button>
        <Button onClick={handleOK} type="primary">
          확인
        </Button>
      </div>

      <Drawer
        closable={false}
        placement="right"
        width={'85%'}
        visible={detailVisible}
        onClose={onCloseDetail}
        destroyOnClose={true}
      >
        <EADocDetail id={detailItemId} />
      </Drawer>
    </Drawer>
  );
};

export default inject('authStore')(observer(EADocSelectorDrawer));
