import React from 'react';
import { withRouter, RouteComponentProps } from 'react-router';
import { EADocListType } from '../../models/EAS';
import EADocListView from './EADocListView';

interface Props extends RouteComponentProps {}

const EADocListAll: React.FC<Props> = props => {
  const params = new URLSearchParams(props.location.search);
  const page = Number(params.get('page')) || 1;
  const keyword = params.get('keyword') || '';

  return (
    <EADocListView type={EADocListType.all} keyword={keyword} page={page} />
  );
};

export default withRouter(EADocListAll);
