import React from 'react';
import Text from 'antd/lib/typography/Text';

interface Props {
  width?: string | number;
  alignRight?: boolean;
}

const InlineText: React.FC<Props> = props => {
  const textAlign = props.alignRight ? 'right' : 'left';

  return (
    <Text
      className="hl-form-inline-text"
      style={{
        display: 'inline-block',
        width: props.width,
        textAlign
      }}
    >
      {props.children}
    </Text>
  );
};

export default InlineText;
