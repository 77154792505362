import React, { useState, useEffect } from 'react';
import { Form, Input, Button, Select } from 'antd';
import { FormComponentProps } from 'antd/lib/form';
import { observer, inject } from 'mobx-react';
import { UserStore } from '../../../stores/userStore';
import { User } from '../../../models/User';
import moment from 'moment';
import UserService from '../../../services/UserService';
import Util from '../../../helpers/Util';
import InlineItem from '../../formcomps/InlineItem';
import FIDatePicker from '../../formcomps/FIDatePicker';
const { Option } = Select;

interface Props extends FormComponentProps {
  userStore?: UserStore;
  user: User;
}

const UserPhysicalForm = (props: Props) => {
  const userStore = props.userStore!;
  const { getFieldDecorator, setFieldsValue } = props.form;
  const { user } = props;

  const [userDetail, setUserDetail] = useState<any>({});

  useEffect(() => {
    //setLoading(true);
    UserService.fetchDetail(user.id)
      .then(result => {
        const userDetail = result.user;
        userDetail.ms_start = userDetail.ms_start
          ? moment(userDetail.ms_start)
          : undefined;

        userDetail.ms_end = userDetail.ms_end
          ? moment(userDetail.ms_end)
          : undefined;

        setUserDetail(userDetail);

        //setLoading(false);
      })
      .catch(error => Util.showError(error));
  }, [user, setFieldsValue]);

  const [submitting, setSubmitting] = useState(false);

  const handleSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    props.form.validateFields((err, values) => {
      if (err) return;
      updateItem(values, user);
    });
  };

  const updateItem = async (values: any, item: User) => {
    try {
      setSubmitting(true);

      values.military_service = values.military_service || null;
      values.ms_start = values.ms_start || null;
      values.ms_end = values.ms_end || null;
      values.ms_discharge = values.ms_discharge || null;

      await userStore.updateUser(item, values);
      Util.showSuccess('업데이트 되었습니다.');
    } catch (err) {
      Util.showError(err);
    } finally {
      setSubmitting(false);
    }
  };

  return (
    <Form
      className="hl-form"
      labelCol={{ span: 3 }}
      wrapperCol={{ span: 18 }}
      onSubmit={handleSubmit}
    >
      <Form.Item label="병력">
        {getFieldDecorator('military_service', {
          initialValue: userDetail.military_service
        })(
          <Select allowClear={true} style={{ width: '40%' }}>
            <Option value={1}>군필</Option>
            <Option value={2}>미필</Option>
            <Option value={3}>면제</Option>
          </Select>
        )}
      </Form.Item>

      <Form.Item label="복무기간">
        <InlineItem>
          <FIDatePicker
            form={props.form}
            name="ms_start"
            allowClear
            initialValue={
              userDetail.ms_start && Util.formatDate(userDetail.ms_start)
            }
          />
        </InlineItem>{' '}
        ~{' '}
        <InlineItem>
          <FIDatePicker
            form={props.form}
            name="ms_end"
            allowClear
            initialValue={
              userDetail.ms_end && Util.formatDate(userDetail.ms_end)
            }
          />
        </InlineItem>
      </Form.Item>

      <Form.Item label="군별">
        {getFieldDecorator('ms_type', {
          initialValue: userDetail.ms_type
        })(<Input style={{ width: '40%' }} />)}
      </Form.Item>

      <Form.Item label="군번">
        {getFieldDecorator('ms_number', {
          initialValue: userDetail.ms_number
        })(<Input style={{ width: '40%' }} />)}
      </Form.Item>

      <Form.Item label="병과">
        {getFieldDecorator('ms_branch', {
          initialValue: userDetail.ms_branch
        })(<Input style={{ width: '40%' }} />)}
      </Form.Item>

      <Form.Item label="계급">
        {getFieldDecorator('ms_rank', {
          initialValue: userDetail.ms_rank
        })(<Input style={{ width: '40%' }} />)}
      </Form.Item>

      <Form.Item label="제대구분">
        {getFieldDecorator('ms_discharge', {
          initialValue: userDetail.ms_discharge
        })(
          <Select allowClear={true} style={{ width: '40%' }}>
            <Option value={1}>만기전역</Option>
            <Option value={2}>의가사전역</Option>
          </Select>
        )}
      </Form.Item>

      <Form.Item label="역종">
        {getFieldDecorator('ms_class', {
          initialValue: userDetail.ms_class
        })(<Input style={{ width: '40%' }} />)}
      </Form.Item>

      <Form.Item wrapperCol={{ span: 12, offset: 4 }}>
        <Button type="primary" htmlType="submit" disabled={submitting}>
          저 장
        </Button>
      </Form.Item>
    </Form>
  );
};

const _UserPhysicalForm = Form.create<Props>()(UserPhysicalForm);
export default inject('userStore')(observer(_UserPhysicalForm));
