import React, { useEffect, useState } from 'react';
import './Index.css';
import { Layout } from 'antd';
import AppHeader from './AppHeader';
// import AppHeader from './AppHeaderTest';
import { Route, Switch } from 'react-router-dom';
import Home from './Home';
import AppContent from './AppContent';
import { observer, inject } from 'mobx-react';
import { DataStore } from '../stores/dataStore';
import { UserStore } from '../stores/userStore';
import { AuthStore } from '../stores/authStore';

/*
// 일단 제거
import openSocket from 'socket.io-client';
import { SOCKET_ROOT } from '../config';
const socket = openSocket(SOCKET_ROOT);
socket.on('welcome', (msg: any) => {
  // Util.showSuccess(msg.message);
  console.log(msg);
});
*/

interface Props {
  dataStore: DataStore;
  userStore: UserStore;
  authStore: AuthStore;
}

const Index: React.FC<Props> = props => {
  console.count('Index');

  const [loading, setLoading] = useState(true);
  const { dataStore, userStore, authStore } = props;

  useEffect(() => {
    /*

      NOTE: authStore.verifyUser() 호출 이유
      이미 /App 에서 유저인증을 위해 verifyUser를 호출했지만, 
      해당 시점에서는 dataStore의 정보들이 없는 상황이기에 corp, dept, rank 값들이 제대로 설정되지 않는 이슈가 있음.
      따라서 인증이후 앱 진입시 해당 정보들을 적절히 설정해주기 위해 한번 더 호출

    */
    const fetchData = async () => {
      try {
        await dataStore.fetchStore();
        await userStore.fetchStore();
        await authStore.verifyUser();
        setLoading(false);
      } catch (err) {
        console.error(err);
        setLoading(false);
      }
    };

    fetchData();
  }, [dataStore, userStore, authStore]);

  return (
    <>
      {loading ? (
        <span />
      ) : (
        <Layout>
          <AppHeader />
          <Layout style={{ marginTop: 64 }}>
            <Switch>
              <Route path="/" exact component={Home} />
              <Route path="/" component={AppContent} />
            </Switch>
          </Layout>
        </Layout>
      )}
    </>
  );
};

export default inject('dataStore', 'userStore', 'authStore')(observer(Index));
