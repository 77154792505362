import React, { useState, useEffect, useRef } from 'react';
import { ContractItem, ContractPay, Order } from '../../../models/Order';
import { Modal, Tabs } from 'antd';
import ContractPayEditor from './ContractPayEditor';
import ContractPayLog from './ContractPayLog';
import ContractPaySummary from './ContractPaySummary';
import ContractItemEditor from './ContractItemEditor';
import OrderService from '../../../services/OrderService';

interface Props {
  orderId: number;
  out?: boolean;
  onChange?: () => void;
}

const ContractPayBook: React.FC<Props> = props => {
  const { orderId, out = false } = props;
  const [terms, setTerms] = useState<string[]>([]);
  const [loading, setLoading] = useState(true);
  const [editable, setEditable] = useState(false);
  const [order, setOrder] = useState<Order>(new Order());

  useEffect(() => {
    const fetchDetail = async () => {
      const result = await OrderService.fetchItem(
        orderId,
        'name, terms, ctrin, ctrout'
      );
      const order = Order.fromJson(result.order);
      setOrder(order);
      setTerms(order.terms);
      setEditable(out ? order.ctrout : order.ctrin);
      setLoading(false);
    };

    fetchDetail();
  }, [orderId, out]);

  const [payEditorVisible, setPayEditorVisible] = useState(false);
  const [itemEditorVisible, setItemEditorVisible] = useState(false);
  const [refreshTrigger, setRefreshTrigger] = useState(1);
  const contractItemRef = useRef<ContractItem | undefined>(new ContractItem(0));
  const contractPayRef = useRef<ContractPay | undefined>(new ContractPay());
  const companyNameRef = useRef('');

  const onClickContractItem = (item: ContractItem, companyName: string) => {
    contractItemRef.current = item;
    contractPayRef.current = undefined;
    companyNameRef.current = companyName;
    setPayEditorVisible(true);
  };

  const onClickContractPay = (item: ContractPay) => {
    contractItemRef.current = undefined;
    contractPayRef.current = item;
    companyNameRef.current = item.company_name;
    setPayEditorVisible(true);
  };

  const onSuccessEditingPay = () => {
    setRefreshTrigger(v => v + 1);
    setPayEditorVisible(false);
    props.onChange && props.onChange();
  };

  const onClickDate = (item: ContractItem, companyName: string) => {
    contractItemRef.current = item;
    contractPayRef.current = undefined;
    companyNameRef.current = companyName;
    setItemEditorVisible(true);
  };

  const onSuccessEditingItem = () => {
    setRefreshTrigger(v => v + 1);
    setItemEditorVisible(false);
    props.onChange && props.onChange();
  };

  console.count('ContractPayBook');

  if (loading) {
    return <></>;
  }

  return (
    <>
      <h2>
        {out ? '지급등록' : '수금등록'} - {order.name}
      </h2>

      <ContractPaySummary
        orderId={orderId}
        out={out}
        editable={editable}
        terms={terms}
        onClickItem={onClickContractItem}
        onClickDate={onClickDate}
        refreshTrigger={refreshTrigger}
      />

      <Tabs type="card">
        <Tabs.TabPane tab={out ? '지급내역' : '수금내역'} key="log">
          <ContractPayLog
            orderId={orderId}
            out={out}
            terms={terms}
            onClickItem={onClickContractPay}
            refreshTrigger={refreshTrigger}
          />
        </Tabs.TabPane>
        <Tabs.TabPane tab={out ? '지급완료' : '수금완료'} key="completed">
          <ContractPaySummary
            orderId={orderId}
            out={out}
            editable={editable}
            terms={terms}
            onClickItem={onClickContractItem}
            onClickDate={onClickDate}
            refreshTrigger={refreshTrigger}
            completed
          />
        </Tabs.TabPane>
      </Tabs>

      <div style={{ height: '20px' }} />

      <Modal
        title={out ? '지급등록' : '수금등록'}
        width={800}
        centered
        visible={payEditorVisible}
        footer={null}
        onCancel={() => setPayEditorVisible(false)}
        destroyOnClose={true}
      >
        <ContractPayEditor
          out={out}
          companyName={companyNameRef.current}
          contractItem={contractItemRef.current}
          contractPay={contractPayRef.current}
          onSuccess={onSuccessEditingPay}
        />
      </Modal>

      <Modal
        title="예정일/청구일 수정"
        width={800}
        centered
        visible={itemEditorVisible}
        footer={null}
        onCancel={() => setItemEditorVisible(false)}
        destroyOnClose={true}
      >
        <ContractItemEditor
          out={out}
          companyName={companyNameRef.current}
          contractItem={contractItemRef.current || new ContractItem(0)}
          onSuccess={onSuccessEditingItem}
        />
      </Modal>
    </>
  );
};

export default ContractPayBook;
