import React, { useState, useEffect, useRef } from 'react';
import { Row, Col, Modal, Button } from 'antd';
import { Department } from '../../models/Org';
import { User } from '../../models/User';
import DeptTree from '../../components/general/DeptTree';
import UserSelector from '../../components/general/UserSelector';

interface PanelProps {
  selectedUsers?: User[];
  onChange?: (items: User[]) => void;
}

export const UserPickerPanel: React.FC<PanelProps> = props => {
  const [dept, setDept] = useState<Department | undefined>(undefined);
  const [selectedUsers, setSelectedUsers] = useState<User[]>([]);

  useEffect(() => {
    if (props.selectedUsers) {
      setSelectedUsers(props.selectedUsers);
    }
  }, [props.selectedUsers]);

  const onSelectDept = (item: Department) => {
    setDept(item);
    setSelectedUsers([]);
  };

  const oncChangeSelectedUsers = (items: User[]) => {
    setSelectedUsers(items);
    if (props.onChange) {
      props.onChange(items);
    }
  };

  return (
    <Row gutter={0} style={{ height: '550px' }}>
      <Col span={12}>
        <DeptTree onSelectItem={onSelectDept} />
      </Col>
      <Col span={12}>
        <div style={{ margin: '0px 10px' }}>
          <UserSelector
            dept={dept}
            selectedUsers={selectedUsers}
            onChange={oncChangeSelectedUsers}
          />
        </div>
      </Col>
    </Row>
  );
};

interface Props {
  visible: boolean;
  title?: string;
  onCancel: () => void;
  onOk: (users: User[]) => void;
}

const UserPicker: React.FC<Props> = props => {
  const { visible, onCancel, title = '구성원 선택' } = props;
  const selectionRef = useRef<User[]>([]);
  const [submitEnabled, setSubmitEnabled] = useState(false);

  const onClickOk = () => {
    props.onOk(selectionRef.current);
    selectionRef.current = [];
    setSubmitEnabled(false);
  };

  const onChangeUsers = (users: User[]) => {
    selectionRef.current = users;
    setSubmitEnabled(users.length > 0);
  };

  return (
    <Modal
      title={title}
      centered
      width={700}
      visible={visible}
      onCancel={onCancel}
      footer={[
        <Button key="cancel" onClick={onCancel}>
          취소
        </Button>,
        <Button
          key="submit"
          type="primary"
          disabled={!submitEnabled}
          onClick={onClickOk}
        >
          확인
        </Button>
      ]}
      destroyOnClose
    >
      <UserPickerPanel onChange={onChangeUsers} />
    </Modal>
  );
};

export default UserPicker;
