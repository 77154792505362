import React, { useState, useEffect, useRef } from 'react';
import { Table, Tag, Drawer } from 'antd';
import Util from '../../helpers/Util';
import VacationService, {
  VacationSearchOption
} from '../../services/VacationService';
import { Vacation } from '../../models/Vacation';
import { PaginationConfig } from 'antd/lib/table';
import LinkButton from '../../components/general/LinkButton';
import EADocDetail from '../eas/EADocDetail';

interface Props {
  searchOption?: VacationSearchOption;
}

const VacationLogs: React.FC<Props> = props => {
  const [items, setItems] = useState<Vacation[]>([]);
  const [totalCount, setTotalCount] = useState(0);
  const [currentPage, setCurrentPage] = useState(1);
  const [loading, setLoading] = useState(false);

  const pagination = {
    pageSize: 15,
    current: currentPage,
    total: totalCount,
    showTotal: (total: number, range: [number, number]) =>
      `전체 ${total}건 중 ${range[0]}-${range[1]}`
  };

  useEffect(() => {
    const fetchItems = async () => {
      setLoading(true);
      try {
        const result = await VacationService.fetchItems(
          pagination.pageSize,
          currentPage,
          props.searchOption
        );
        setTotalCount(result.total_count);
        setItems(Vacation.fromJsonArray(result.items));
      } catch (error) {
        Util.showError(error);
      } finally {
        setLoading(false);
      }
    };
    fetchItems();
  }, [pagination.pageSize, currentPage, props.searchOption]);

  const onChangeTable = (pagination: PaginationConfig) => {
    setCurrentPage(pagination.current || 1);
  };

  const itemRef = useRef<Vacation>(new Vacation());
  const [eadocVisible, setEADocVisible] = useState(false);
  const onClickEADoc = (item: Vacation) => {
    itemRef.current = item;
    setEADocVisible(true);
  };

  const columns = [
    {
      title: '이름',
      dataIndex: 'user.name',
      width: 150,
      render: (text: string, record: Vacation) => {
        return record.user.nameWithDeptRank;
      }
    },
    {
      title: '구분',
      dataIndex: 'type',
      width: 40
    },
    {
      title: '사용일수',
      dataIndex: 'used',
      width: 70,
      align: 'right' as 'right',
      render: (text: string, record: Vacation) => {
        return record.type === '연차' || record.type === '휴가'
          ? record.used
          : '';
      }
    },
    {
      title: '신청기간',
      dataIndex: 'durationSummary',
      width: 160
    },
    {
      title: '사용일',
      dataIndex: 'dates',
      render: (text: string, record: Vacation) => {
        return (
          <>
            {record.dates.map(dateString => (
              <Tag key={dateString} closable={false}>
                {dateString}
              </Tag>
            ))}
          </>
        );
      }
    },
    {
      title: '결재문서',
      dataIndex: 'eadoc',
      width: 65,
      render: (text: string, record: Vacation) => {
        return (
          <LinkButton onClick={e => onClickEADoc(record)}>결재문서</LinkButton>
        );
      }
    }
  ];

  return (
    <>
      <h2>휴가 상세이력 조회</h2>

      <Table
        columns={columns}
        dataSource={items}
        rowKey="id"
        pagination={false}
        onChange={onChangeTable}
        bordered={true}
        loading={loading}
        size="middle"
      />

      <Drawer
        closable={false}
        placement="right"
        width="85%"
        visible={eadocVisible}
        onClose={e => setEADocVisible(false)}
        destroyOnClose={true}
      >
        <EADocDetail refTarget="vac" refId={itemRef.current.id} fetchDelayed />
      </Drawer>
    </>
  );
};

export default VacationLogs;
