import React, { useState, useEffect } from 'react';
import { FormComponentProps } from 'antd/lib/form';
import { Input } from 'antd';
import { API_ROOT } from '../../config';
import axios from 'axios';

// Require Editor CSS files.
import 'froala-editor/css/froala_style.min.css';
import 'froala-editor/css/froala_editor.pkgd.min.css';
import FroalaEditorComponent from 'react-froala-wysiwyg';

// Import all Froala Editor plugins;
import 'froala-editor/js/plugins.pkgd.min.js';

// Import a single Froala Editor plugin.
// import 'froala-editor/js/plugins/align.min.js';

// Import a language file.
import 'froala-editor/js/languages/ko.js';

// Include special components if required.
// import FroalaEditorView from 'react-froala-wysiwyg/FroalaEditorView';
// import FroalaEditorA from 'react-froala-wysiwyg/FroalaEditorA';
// import FroalaEditorButton from 'react-froala-wysiwyg/FroalaEditorButton';
// import FroalaEditorImg from 'react-froala-wysiwyg/FroalaEditorImg';
// import FroalaEditorInput from 'react-froala-wysiwyg/FroalaEditorInput';

interface Props extends FormComponentProps {
  name: string;
  value?: string;
}

const FREditor: React.FC<Props> = (props) => {
  // const { value } = props;
  const [model, setModel] = useState(props.value || '');
  const { getFieldDecorator } = props.form;

  useEffect(() => {
    if (props.value) {
      setModel(props.value);
    }
  }, [props.value]);

  const onModelChange = (model: string) => {
    // setFieldsValue({ [props.name]: model });
    setModel(model);
  };

  const config = {
    key:
      'eHE5C-11E2E2I2I2C6D4D-17d1F1FOOLb2KOPQGe1CWCQVTDWXGcA5A4D4E3E4B2F2E3C1C3==',
    attribution: false, // Remove the Powered By Froala message.
    charCounterCount: false,
    heightMin: 300,
    heightMax: 600,
    language: 'ko',
    placeholderText: '내용을 입력해주세요',
    quickInsertEnabled: false,
    videoInsertButtons: ['videoBack', '|', 'videoByURL', 'videoEmbed'],

    // image upload 참고: https://www.froala.com/wysiwyg-editor/docs/concepts/image/upload
    imageInsertButtons: ['imageBack', '|', 'imageUpload', 'imageByURL'],
    imageUploadURL: API_ROOT + '/files/upload',
    imageUploadParam: 'file',
    imageUploadMethod: 'POST',
    requestHeaders: {
      'X-Auth-Token': axios.defaults.headers.common['X-Auth-Token'],
    },
    // events: {
    //   'image.beforeUpload': (images: any[]) => {
    //     console.log('image.beforeUpload: ', images);
    //   },
    //   'image.uploaded': (response: any) => {
    //     console.log('image.uploaded: ', response);
    //   },
    //   'image.inserted': (image: any, response: any) => {
    //     console.log('image.inserted: ', image);
    //   },
    //   'image.replaced': (image: any, response: any) => {
    //     console.log('image.replaced: ', image);
    //   },
    //   'image.error': (error: any, response: any) => {
    //     console.log('image.error: ', error);
    //   }
    // },
    linkList: [
      {
        displayText: '한림 홈페이지',
        href: 'http://www.hanlim-archi.co.kr',
        target: '_blank',
      },
    ],
    toolbarButtons: {
      moreText: {
        buttons: [
          'bold',
          'underline',
          'strikeThrough',
          'fontFamily',
          'fontSize',
          'textColor',
          'backgroundColor',
          // 'italic',
          // 'subscript',
          // 'superscript',
          // 'inlineClass',
          // 'inlineStyle',
          'clearFormatting',
        ],
        buttonsVisible: 8,
      },
      moreParagraph: {
        buttons: [
          'formatOL',
          'formatUL',
          'alignLeft',
          'alignCenter',
          'alignRight',
          'alignJustify',
          // 'formatOLSimple',
          // 'paragraphFormat',
          // 'paragraphStyle'
          'lineHeight',
          'outdent',
          'indent',
          'quote',
        ],
        buttonsVisible: 2,
      },
      moreRich: {
        buttons: [
          'insertTable',
          'insertLink',
          'insertImage',
          'insertVideo',
          // 'emoticons',
          // 'fontAwesome',
          'specialCharacters',
          // 'embedly',
          // 'insertFile',
          'insertHR',
        ],
        buttonsVisible: 2,
      },
      moreMisc: {
        buttons: [
          'undo',
          'redo',
          'fullscreen',
          'print',
          // 'getPDF',
          // 'spellChecker',
          // 'selectAll',
          // 'html'
          // 'help'
        ],
        buttonsVisible: 4,
      },
    },
    /*
    toolbarButtons: [
      [
        'bold',
        'underline',
        'strikeThrough',
        'fontFamily',
        'fontSize',
        'textColor',
        'backgroundColor',
        // 'italic',
        // 'subscript',
        // 'superscript',
        // 'inlineClass',
        // 'inlineStyle',
        'clearFormatting'
      ],
      [
        'alignLeft',
        'alignCenter',
        'alignRight',
        'alignJustify',
        // 'formatOLSimple',
        'formatOL',
        'formatUL'
        // 'paragraphFormat',
        // 'paragraphStyle'
        // 'lineHeight',
        // 'outdent',
        // 'indent',
        // 'quote'
      ],
      [
        'insertLink',
        'insertImage',
        'insertVideo',
        'insertTable',
        'emoticons',
        // 'fontAwesome',
        'specialCharacters',
        // 'embedly',
        // 'insertFile',
        'insertHR'
      ],
      [
        'undo',
        'redo',
        'fullscreen',
        'print',
        // 'getPDF',
        // 'spellChecker',
        // 'selectAll',
        'html'
        // 'help'
      ]
    ]
    */
  };

  return (
    <>
      {getFieldDecorator(props.name, { initialValue: model })(
        <Input type="hidden" />
      )}
      <FroalaEditorComponent
        tag="textarea"
        config={config}
        model={model}
        onModelChange={onModelChange}
      />
    </>
  );
};

export default FREditor;
