import React, { useState } from 'react';
import { Form, Input, Button } from 'antd';
import { FormComponentProps } from 'antd/lib/form';
import FCTextInput from '../../../components/formcomps/FCTextInput';
import InlineItem from '../../../components/formcomps/InlineItem';
import { Company } from '../../../models/Compnay';
import CompanySelector from '../companies/CompanySelector';
import InlineTextRight from '../../../components/formcomps/InlineTextRight';

interface Props extends FormComponentProps {
  name: string;
  required?: boolean;
  title?: string;
  value?: {
    id?: number;
    name?: string;
    owner?: string;
    rank?: string;
    phone?: string;
    note?: string;
    nature?: string;
    relation?: string;
  };
}

const OrderFormCompany: React.FC<Props> = props => {
  const { name, title, value, required = false } = props;
  const { getFieldDecorator, setFieldsValue } = props.form;

  const [companySelectorVisible, setCompanySelectorVisible] = useState(false);
  const onClickSearchCompany = () => {
    setCompanySelectorVisible(true);
  };

  const onCancelCompanySelector = () => {
    setCompanySelectorVisible(false);
  };

  const onSelectCompanies = (items: Company[]) => {
    if (items.length > 0) {
      const company = items[0];
      setFieldsValue({
        [`${name}_id`]: company.id,
        [`${name}_name`]: company.name,
        [`${name}_owner`]: company.ceo,
        [`${name}_rank`]: '대표',
        [`${name}_phone`]: company.phone
      });
      setCompanySelectorVisible(false);
    }
  };

  return (
    <>
      <h3>{title || '회사정보'}</h3>
      <div className="hl-form" style={{ marginBottom: '10px' }}>
        {getFieldDecorator(`${name}_id`, { initialValue: value && value.id })(
          <Input type="hidden" />
        )}

        <Form.Item label="회사명">
          <InlineItem width="50%">
            {getFieldDecorator(`${name}_name`, {
              initialValue: value && value.name,
              rules: [
                {
                  required,
                  message: '회사명을 선택해주세요.'
                }
              ]
            })(<Input readOnly={true} />)}
          </InlineItem>{' '}
          <Button onClick={onClickSearchCompany}>검색...</Button>
        </Form.Item>

        <Form.Item label="이름">
          <InlineItem width="30%">
            <FCTextInput
              form={props.form}
              name={`${name}_owner`}
              options={{ initialValue: value && value.owner }}
            />
          </InlineItem>
          <InlineTextRight>연락처:</InlineTextRight>
          <InlineItem width="40%">
            <FCTextInput
              form={props.form}
              name={`${name}_phone`}
              options={{ initialValue: value && value.phone }}
            />
          </InlineItem>
        </Form.Item>

        <Form.Item label="직급">
          <InlineItem width="30%">
            <FCTextInput
              form={props.form}
              name={`${name}_rank`}
              options={{ initialValue: value && value.rank }}
            />
          </InlineItem>
          <InlineTextRight>성향 (취미등):</InlineTextRight>
          <InlineItem width="50%">
            <FCTextInput
              form={props.form}
              name={`${name}_nature`}
              options={{ initialValue: value && value.nature }}
            />
          </InlineItem>
        </Form.Item>

        <Form.Item label="소개 마케팅">
          <InlineItem width="30%">
            <FCTextInput
              form={props.form}
              name={`${name}_note`}
              options={{ initialValue: value && value.note }}
            />
          </InlineItem>
          <InlineTextRight>당사관계이력:</InlineTextRight>
          <InlineItem width="50%">
            <FCTextInput
              form={props.form}
              name={`${name}_relation`}
              options={{ initialValue: value && value.relation }}
            />{' '}
          </InlineItem>
        </Form.Item>
      </div>

      <CompanySelector
        visible={companySelectorVisible}
        onCancel={onCancelCompanySelector}
        onOk={onSelectCompanies}
      />
    </>
  );
};

export default OrderFormCompany;
