import React, { useState, useEffect } from 'react';
import { Form, Input, Button, Row, Col, Switch } from 'antd';
import { FormComponentProps } from 'antd/lib/form';
import { RouteComponentProps, withRouter } from 'react-router';
import { Post } from '../../models/Post';
import Util from '../../helpers/Util';
import PostService from '../../services/PostService';
import { AuthStore } from '../../stores/authStore';
import { observer, inject } from 'mobx-react';
import FREditor from '../../components/general/FREditor';
import InlineItem from '../../components/formcomps/InlineItem';
import FCFileInput from '../../components/formcomps/FCFileInput';

interface Props extends FormComponentProps, RouteComponentProps {
  id?: number;
  authStore?: AuthStore;
}

const PostEditor: React.FC<Props> = props => {
  const { location } = props;
  const params = new URLSearchParams(location.search);
  const type = Number(params.get('type')) || 0;
  const refId = Number(params.get('refId')) || undefined;
  const itemId = props.id || Number(params.get('id')) || 0;

  const authStore = props.authStore!;
  const [loading, setLoading] = useState(false);
  const [item, setItem] = useState<Post>(new Post());
  const { getFieldDecorator } = props.form;

  useEffect(() => {
    const fetchDetail = async () => {
      setLoading(true);
      try {
        const result = await PostService.fetchItem(itemId);
        setItem(Post.fromJson(result.post));
      } catch (error) {
        Util.showError(error);
      } finally {
        setLoading(false);
      }
    };

    if (itemId > 0) {
      fetchDetail();
    }
  }, [itemId]);

  const [submitting, setSubmitting] = useState(false);

  const onClickSubmitButton = () => {
    props.form.validateFields((err, values) => {
      if (err) return;
      processItem(values);
    });
  };

  const processItem = async (values: any) => {
    try {
      setSubmitting(true);
      itemId === 0
        ? await PostService.create(values)
        : await PostService.update(itemId, values);
      Util.showSuccess('저장되었습니다.');
      setSubmitting(false);
      props.history.push(`./?type=${type}&refId=${refId || ''}`);
    } catch (err) {
      Util.showError(err);
      setSubmitting(false);
    }
  };

  const formItemLayout = {
    labelCol: { span: 4 },
    wrapperCol: { span: 19 }
  };

  console.count('PostEditor');

  const user = itemId === 0 ? authStore.user : item.owner;

  return (
    <>
      <h2>
        {Post.getTypeName(type, refId)} 게시물 {itemId > 0 ? '수정' : '등록'}
      </h2>
      <Row gutter={12}>
        <Col className="gutter-row" span={20}>
          <Form className="hl-form" {...formItemLayout}>
            {getFieldDecorator('type', { initialValue: type })(
              <Input type="hidden" />
            )}
            {getFieldDecorator('ref_id', { initialValue: refId })(
              <Input type="hidden" />
            )}

            <Form.Item label="작성자">
              <InlineItem width="40%">
                <Input value={user.nameWithDeptRank} readOnly />
              </InlineItem>
            </Form.Item>

            <Form.Item label="제목">
              {getFieldDecorator('title', {
                initialValue: item.title,
                rules: [
                  {
                    required: true,
                    message: '제목을 입력해주세요.'
                  },
                  {
                    whitespace: true,
                    message: '제목을 입력해주세요.'
                  }
                ]
              })(<Input placeholder="제목을 입력해주세요." autoFocus />)}
            </Form.Item>

            {type !== 0 && (
              <>
                <Form.Item label="파일 첨부">
                  <FCFileInput form={props.form} items={item.files} />
                </Form.Item>

                <Form.Item label="상단노출 (공지)">
                  <InlineItem>
                    {getFieldDecorator('pinned', {
                      valuePropName: 'checked',
                      initialValue: item.pinned
                    })(<Switch />)}
                  </InlineItem>
                </Form.Item>
              </>
            )}
          </Form>

          <div style={{ marginTop: '15px' }}>
            <FREditor name="content" value={item.content} form={props.form} />
          </div>
        </Col>
        <Col className="gutter-row" span={4}>
          <Row gutter={10}>
            <Col>
              <Button
                block
                type="primary"
                htmlType="submit"
                disabled={loading || submitting}
                onClick={onClickSubmitButton}
              >
                {itemId > 0 ? '수 정' : '등 록'}
              </Button>
            </Col>
          </Row>
        </Col>
      </Row>
    </>
  );
};

const _PostEditor = Form.create<Props>()(PostEditor);
export default inject('authStore')(observer(withRouter(_PostEditor)));
