import React, { useState, useEffect } from 'react';
import { withRouter, RouteComponentProps } from 'react-router';
import { Row, Col, Calendar, Tabs, List, Badge } from 'antd';
import moment from 'moment';
import PlanService from '../services/PlanService';
import { Plan } from '../models/Plan';
import Util from '../helpers/Util';
import { Occasion } from '../models/Occasion';
import OccasionService from '../services/OccasionService';
import AButton from '../components/general/AButton';
import TripService from '../services/TripService';
import { Trip } from '../models/Trip';
const { TabPane } = Tabs;

interface Props extends RouteComponentProps {}

const HomeCalendarWidget: React.FC<Props> = props => {
  const [date, setDate] = useState<moment.Moment>(moment());
  const [year, setYear] = useState(moment().year());
  const [month, setMonth] = useState(moment().month() + 1);

  const [occasions, setOccasions] = useState<Occasion[]>([]);
  const [occasionsDate, setOccasionsDate] = useState<Occasion[]>([]);
  const [plans, setPlans] = useState<Plan[]>([]);
  const [plansDate, setPlansDate] = useState<Plan[]>([]);
  const [trips, setTrips] = useState<Trip[]>([]);
  const [tripsDate, setTripsDate] = useState<Trip[]>([]);
  const [loading, setLoading] = useState(false);

  const [activeTabKey, setActiveTabKey] = useState(
    localStorage.getItem('homeCalendarActiveTabKey') || 'occasions'
  );
  const onChangeTabPane = (key: string) => {
    localStorage.setItem('homeCalendarActiveTabKey', key);
    setActiveTabKey(key);
  };

  useEffect(() => {
    const fetchItems = async () => {
      setLoading(true);
      try {
        const result1 = await OccasionService.fetchItemsForMonth(year, month);
        setOccasions(Occasion.fromJsonArray(result1.items));

        const result2 = await PlanService.fetchItemsForMonth(year, month);
        setPlans(Plan.fromJsonArray(result2.items));

        const result3 = await TripService.fetchTripsForMonth(year, month);
        setTrips(Trip.fromJsonArray(result3.items));
      } catch (error) {
        Util.showError(error);
      } finally {
        setLoading(false);
      }
    };

    fetchItems();
  }, [year, month]);

  // useEffect(() => {
  //   const fetchItems = async () => {
  //     setLoading(true);
  //     try {
  //       const result3 = await TripService.fetchTripsByDate(
  //         Util.formatDate(date)
  //       );
  //       setTrips(Trip.fromJsonArray(result3.items));
  //     } catch (error) {
  //       Util.showError(error);
  //     } finally {
  //       setLoading(false);
  //     }
  //   };

  //   fetchItems();
  // }, [date]);

  const onChangeDate = (dt?: moment.Moment | undefined) => {
    if (!dt) return;

    setDate(dt);
    if (year !== dt.year() || month !== dt.month() + 1) {
      setYear(dt.year());
      setMonth(dt.month() + 1);
    } else {
      setOccasionsDate(
        occasions.filter(el => Util.formatDate(el.date) === Util.formatDate(dt))
      );
      setPlansDate(
        plans.filter(el => Util.formatDate(el.date) === Util.formatDate(dt))
      );
      setTripsDate(
        trips.filter(
          el =>
            Util.formatDate(el.begin_date) <= Util.formatDate(dt) &&
            Util.formatDate(el.end_date) >= Util.formatDate(dt)
        )
      );
    }
  };

  useEffect(() => {
    setOccasionsDate(
      occasions.filter(el => Util.formatDate(el.date) === Util.formatDate(date))
    );
  }, [occasions, date]);

  useEffect(() => {
    setPlansDate(
      plans.filter(el => Util.formatDate(el.date) === Util.formatDate(date))
    );
  }, [plans, date]);

  useEffect(() => {
    setTripsDate(
      trips.filter(
        el =>
          Util.formatDate(el.begin_date) <= Util.formatDate(date) &&
          Util.formatDate(el.end_date) >= Util.formatDate(date)
      )
    );
  }, [trips, date]);

  const onClickOccasion = (item: Occasion) => {
    props.history.push(`/works/board/occasions/view?id=${item.id}`);
  };

  const dateCellRender = (date: moment.Moment) => {
    const found1 = occasions.findIndex(
      el => Util.formatDate(el.date) === Util.formatDate(date)
    );
    // const found2 = plans.findIndex(
    //   el => Util.formatDate(el.date) === Util.formatDate(date)
    // );
    // const found3 = trips.findIndex(
    //   el =>
    //     Util.formatDate(el.begin_date) <= Util.formatDate(date) &&
    //     Util.formatDate(el.end_date) >= Util.formatDate(date)
    // );
    // if (found1 >= 0 || found2 >= 0 || found3 >= 0) {
    if (found1 >= 0) {
      return (
        <div style={{ marginLeft: '8px' }}>
          <Badge status="warning" text="" />
          {/* <Badge status="success" text="" /> */}
        </div>
      );
    } else {
      return <></>;
    }
  };

  const tabPaneStlye: any = {
    border: '1px solid #d9d9d9',
    borderRadius: 4,
    overflow: 'auto',
    maxHeight: '310px',
    padding: '0 20px'
  };

  let occasionsTitle = '전사일정';
  let plansTitle = '개별일정';
  let tripsTitle = '외근/출장';
  if (occasionsDate.length > 0) occasionsTitle += ` (${occasionsDate.length})`;
  if (plansDate.length > 0) plansTitle += ` (${plansDate.length})`;
  if (tripsDate.length > 0) tripsTitle += ` (${tripsDate.length})`;

  return (
    <Row gutter={15}>
      <Col span={9}>
        <div
          style={{
            border: '1px solid #d9d9d9',
            borderRadius: 4
          }}
        >
          <Calendar
            fullscreen={false}
            dateCellRender={dateCellRender}
            onChange={onChangeDate}
          />
        </div>
      </Col>
      <Col span={15}>
        <Tabs type="card" onChange={onChangeTabPane} activeKey={activeTabKey}>
          <TabPane tab={occasionsTitle} key="occasions">
            <div style={tabPaneStlye}>
              <List
                itemLayout="horizontal"
                dataSource={occasionsDate}
                loading={loading}
                split={false}
                renderItem={item => (
                  <List.Item>
                    <List.Item.Meta
                      title={
                        <AButton onClick={e => onClickOccasion(item)}>
                          {item.title}
                        </AButton>
                      }
                      description={item.summary}
                    />
                    {`${Util.formatDateTimeHHMM(item.date)} | ${item.place}`}
                  </List.Item>
                )}
              />
            </div>
          </TabPane>
          <TabPane tab={plansTitle} key="plans">
            <div style={tabPaneStlye}>
              <List
                itemLayout="horizontal"
                dataSource={plansDate}
                loading={loading}
                split={false}
                renderItem={item => (
                  <List.Item>
                    <List.Item.Meta
                      title={item.owner.nameWithDeptRank}
                      description={item.content.split('\n').map((line, i) => {
                        return (
                          <span key={i}>
                            {line}
                            <br />
                          </span>
                        );
                      })}
                    />
                  </List.Item>
                )}
              />
            </div>
          </TabPane>
          <TabPane tab={tripsTitle} key="trips">
            <div style={tabPaneStlye}>
              <List
                itemLayout="horizontal"
                dataSource={tripsDate}
                loading={loading}
                split={false}
                renderItem={item => (
                  <List.Item>
                    <List.Item.Meta
                      title={item.trip_title}
                      description={item.users.map(el => el.name).join(',')}
                    />
                    {item.durationSummary}
                  </List.Item>
                )}
              />
            </div>
          </TabPane>
        </Tabs>
      </Col>
    </Row>
  );
};

export default withRouter(HomeCalendarWidget);
