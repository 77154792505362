import React, { useState, useEffect } from 'react';
import { Row, Col } from 'antd';
import TreeView from '../../../components/general/TreeView';
import { MenuItem } from '../../../models/Common';
import PageService from '../../../services/PageService';
import Util from '../../../helpers/Util';
import { ContentPage } from '../../../models/Page';
import { RouteComponentProps } from 'react-router-dom';
import PageViewer from '../../board/PageViewer';

const menuItems: MenuItem[] = [
  {
    id: '한림건축CM',
    title: '한림건축CM',
    children: [
      { id: '1', title: '건축사사무소(감리)' },
      { id: '2', title: '전력시설물 감리업' },
      { id: '3', title: '소방시설 감리업' },
      { id: '4', title: '건설사업 감리업' },
      { id: '5', title: '엔지니어링(정보통신) 감리업' }
    ]
  },
  {
    id: '한림건축',
    title: '한림건축',
    children: [{ id: '6', title: '건축사사무소' }]
  },
  {
    id: '코리아인월드종합개발',
    title: '코리아인월드종합개발',
    children: [
      { id: '7', title: '부동산개발업' },
      { id: '8', title: '주택건설사업자' }
    ]
  },
  {
    id: '9',
    title: '항목별 요건,기준 관리'
  }
];

interface Props extends RouteComponentProps {}

const CorpPages: React.FC<Props> = props => {
  const params = new URLSearchParams(props.location.search);
  const pageId = Number(params.get('id')) || 1;

  const [, setLoading] = useState(false);
  const [item, setItem] = useState<ContentPage | undefined>(undefined);

  useEffect(() => {
    const fetchDetail = async () => {
      setLoading(true);
      try {
        const result = await PageService.fetchItem(pageId);
        const contentPage = ContentPage.fromJson(result.page);
        setItem(contentPage);
      } catch (error) {
        Util.showError(error);
      } finally {
        setLoading(false);
      }
    };

    fetchDetail();
  }, [pageId]);

  const onSelectItem = async (id: string) => {
    if (Number(id)) {
      props.history.push(`${props.location.pathname}?id=${id}`);
    }
  };

  return (
    <Row gutter={15}>
      <Col xl={5} xxl={4}>
        <h2>법인별 자격관리</h2>
        <TreeView items={menuItems} onSelectItem={onSelectItem} />
      </Col>
      <Col xl={19} xxl={20}>
        {item && <PageViewer id={item.id} showLogs />}
      </Col>
    </Row>
  );
};

export default CorpPages;
