import React, { useState, useEffect } from 'react';
import { Tabs, Table, Form, Button, Icon } from 'antd';
import OrderStateForm from './OrderStateForm';
import { Order, OrderStage } from '../../../models/Order';
import { FormComponentProps } from 'antd/lib/form';
import Util from '../../../helpers/Util';
import OrderService from '../../../services/OrderService';
import FIDatePicker from '../../../components/formcomps/FIDatePicker';
const { TabPane } = Tabs;

interface Props extends FormComponentProps {
  order: Order;
  stages: string[];
  onChange?: () => void;
}

const OrderStagesDate: React.FC<Props> = props => {
  const { order, stages } = props;
  const [items, setItems] = useState<OrderStage[]>([]);
  const [currentStage, setCurentStage] = useState(order.stage);

  useEffect(() => {
    const findStageInOrder = (stage: string) => {
      return order.stages.find(el => el.stage === stage);
    };

    const items = stages.map(stage => {
      const orderStage = new OrderStage();
      orderStage.order_id = order.id;
      orderStage.stage = stage;

      const stageData = findStageInOrder(stage);
      if (stageData) {
        orderStage.finish_date = stageData.finish_date;
        orderStage.finish_est_date = stageData.finish_est_date;
      }

      return orderStage;
    });
    setItems(items);
  }, [order, stages]);

  let columns = [
    {
      title: '진행단계',
      dataIndex: 'stage',
      render: (text: string, record: OrderStage) => {
        if (record.stage === currentStage) {
          return (
            <>
              <b>{text}</b> <Icon type="arrow-left"></Icon>
            </>
          );
        } else {
          return text;
        }
      }
    },
    {
      title: '완료예정일',
      dataIndex: 'finish_est_date',
      width: '200px',
      render: (text: string, record: OrderStage) => {
        return (
          <FIDatePicker
            form={props.form}
            name={`finish_est_date-${record.stage}`}
            initialValue={
              record.finish_est_date && Util.formatDate(record.finish_est_date)
            }
          />
        );
      }
    },
    {
      title: '완료일',
      dataIndex: 'finish_date',
      width: '200px',
      render: (text: string, record: OrderStage) => {
        return (
          <FIDatePicker
            form={props.form}
            name={`finish_date-${record.stage}`}
            initialValue={
              record.finish_date && Util.formatDate(record.finish_date)
            }
          />
        );
      }
    }
  ];

  const [submitting, setSubmitting] = useState(false);

  const onSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    props.form.validateFields((err, values) => {
      if (err) return;
      processUpdate(values);
    });
  };

  const processUpdate = async (values: any) => {
    try {
      setSubmitting(true);

      let data: any = [];
      for (const stage of stages) {
        data.push({
          order_id: order.id,
          stage,
          finish_est_date: values[`finish_est_date-${stage}`],
          finish_date: values[`finish_date-${stage}`]
        });
      }

      await OrderService.updateStages(order.id, data);
      Util.showSuccess('업데이트되었습니다.');
      if (props.onChange) props.onChange();
    } catch (err) {
      Util.showError(err);
    } finally {
      setSubmitting(false);
    }
  };

  const onChangeOrderState = (values: any) => {
    if (values.stage) setCurentStage(values.stage);
    if (props.onChange) props.onChange();
  };

  return (
    <>
      <h2>{order.name}</h2>
      <OrderStateForm
        item={order}
        stages={stages}
        onChange={onChangeOrderState}
      />

      <Tabs type="card" style={{ marginTop: '25px' }}>
        <TabPane tab="단계별 완료 일정" key="default">
          <Form onSubmit={onSubmit}>
            <Table
              bordered={false}
              size="middle"
              columns={columns}
              dataSource={items}
              pagination={false}
              rowKey="stage"
            />
            <div style={{ marginTop: '20px', textAlign: 'center' }}>
              <Button type="primary" htmlType="submit" disabled={submitting}>
                완료일 업데이트
              </Button>
            </div>
          </Form>
        </TabPane>
      </Tabs>
    </>
  );
};

export default Form.create<Props>()(OrderStagesDate);
