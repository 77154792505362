import React, { useState, useEffect, useRef } from 'react';
import { Button, Row, Col, Form, InputNumber, Drawer, Modal } from 'antd';
import { FormComponentProps } from 'antd/lib/form';
import FICorpSelect from '../../../components/formcomps/FICorpSelect';
import AccountReportTable from './AccountReportTable';
import FinanceService, {
  CollectFetchOption
} from '../../../services/FinanceService';
import {
  CollectPlanItem,
  AccountWeekGroup,
  AccountItem,
  AccountVMItem
} from '../../../models/Finance';
import Util from '../../../helpers/Util';
import ContractPayBook from '../../projects/contracts/ContractPayBook';
import AccountPayBook from './AccountPayBook';
import AccountItemEditor from './AccountItemEditor';
import CarryOverList from './CarryOverList';
import dataStore from '../../../stores/dataStore';
import ReactToPrint from 'react-to-print';

interface Props extends FormComponentProps {
  preventEditing?: boolean;
}

const AccountReport: React.FC<Props> = props => {
  const { preventEditing = false } = props;
  const { getFieldDecorator } = props.form;
  const [groups, setGropus] = useState<AccountWeekGroup[]>([]);
  const [refreshTrigger, setRefreshTrigger] = useState(1);
  const [fetchOption, setFetchOption] = useState<CollectFetchOption>({
    year: new Date().getFullYear(),
    month: new Date().getMonth() + 1
  });
  const [loading, setLoading] = useState(false);
  const [prevAmount, setPrevAmount] = useState({ plan: 0, action: 0 });

  const onSubmitSearchForm = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    props.form.validateFields((err, values) => {
      if (err) return;
      setFetchOption(values);
    });
  };

  useEffect(() => {
    const fetchItems = async () => {
      setLoading(true);

      // NOTE: 계획일대로 실행되지 않고 여러번에 걸쳐 나눠 실행되는 경우 실행일 기준으로 표시하기 위해 fetchOrderPayActions 사용
      //   const result = await FinanceService.fetchOrderPayActions(fetchOption);
      //   const plans = CollectPlanItem.fromJsonArray(result.actions);

      // const result = await FinanceService.fetchCollects2(fetchOption);
      const result = await FinanceService.fetchOrderPlans(fetchOption);
      const plans = CollectPlanItem.fromJsonArray(result.plans);

      const result2 = await FinanceService.fetchAccountItems(fetchOption);
      const accountItems = AccountItem.fromJsonArray(result2.items);

      //   const result3 = await FinanceService.fetchPrevAmounts(fetchOption);
      //   setPrevAmount(result3);
      const result3 = await FinanceService.fetchPrevAmounts2(fetchOption);
      setPrevAmount(result3);

      const allWeeks = Util.getAllWeeksInMonth(
        fetchOption.year,
        fetchOption.month
      );

      const groups: AccountWeekGroup[] = allWeeks.map(e => {
        const startDate = Util.formatDate(e.start);
        const endDate = Util.formatDate(e.end);
        const weekItems = plans.filter(i => {
          return i.f_est_date >= startDate && i.f_est_date <= endDate;
        });

        const weekInItems = weekItems.filter(e => e.out === false);
        const weekOutItems = weekItems.filter(e => e.out === true);

        // console.log(startDate, endDate);
        // console.log(weekInItems);
        // console.log(weekOutItems);

        // order_id, est_date가 동일한 항목들 하나로 묶음 처리
        // 예정일이 동일한 항목들은 실행일도 같거나 undefined 라는 가정
        // (실행시 실행일자로 예정일이 업데이트되기 때문)
        let inItems: CollectPlanItem[] = [];
        weekInItems.forEach(e => {
          const foundItem = inItems.find(
            i =>
              i.order_id === e.order_id &&
              Util.formatDate(i.est_date) === Util.formatDate(e.est_date)
          );
          if (foundItem) {
            foundItem.total_price =
              Number(foundItem.total_price) + Number(e.total_price);
            foundItem.total_pay =
              Number(foundItem.total_pay) + Number(e.total_pay);
            if (e.pay_date) foundItem.pay_date = e.pay_date;
          } else {
            inItems.push(e);
          }
        });

        let outItems: CollectPlanItem[] = [];
        weekOutItems.forEach(e => {
          const foundItem = outItems.find(
            i =>
              i.order_id === e.order_id &&
              Util.formatDate(i.est_date) === Util.formatDate(e.est_date)
          );
          if (foundItem) {
            foundItem.total_price =
              Number(foundItem.total_price) + Number(e.total_price);
            foundItem.total_pay =
              Number(foundItem.total_pay) + Number(e.total_pay);
            if (e.pay_date) foundItem.pay_date = e.pay_date;
          } else {
            outItems.push(e);
          }
        });

        const weekAcccountItems = accountItems.filter(i => {
          return i.f_est_date >= startDate && i.f_est_date <= endDate;
        });

        return {
          startDate,
          endDate,
          items: [
            ...AccountItem.fromCollectPlanItems(inItems),
            ...AccountItem.fromCollectPlanItems(outItems),
            ...weekAcccountItems
          ]
        };
      });

      // console.log(groups);
      setLoading(false);
      setGropus(groups);
    };

    fetchItems();
  }, [fetchOption, refreshTrigger]);

  const orderIdRef = useRef(0);
  const orderOutRef = useRef(false);
  const paybookDirtyRef = useRef(false);
  const [collectEditVisible, setCollectEditVisible] = useState(false);

  const accountItemRef = useRef<AccountItem | undefined>(undefined);
  const [itemEditorVisible, setItemEditorVisible] = useState(false);

  const onSuccessEditingAccount = () => {
    setRefreshTrigger(v => v + 1);
    setItemEditorVisible(false);
  };

  const onSelectItem = (item: AccountVMItem, out: boolean) => {
    if (out) {
      accountItemRef.current = item.source2;
      orderIdRef.current = item.order_id2;
    } else {
      accountItemRef.current = item.source1;
      orderIdRef.current = item.order_id1;
    }
    orderOutRef.current = out;

    orderIdRef.current === 0
      ? setItemEditorVisible(true)
      : setCollectEditVisible(true);
  };

  const onChangePayBook = () => {
    paybookDirtyRef.current = true;
  };

  const onCloseCollectEdit = () => {
    setCollectEditVisible(false);
    if (paybookDirtyRef.current) {
      paybookDirtyRef.current = false;
      setRefreshTrigger(refreshTrigger + 1);
    }
  };

  const [accountVisible, setAccountVisible] = useState(false);
  const accountDirtyRef = useRef(false);

  const onClickAccount = (out: boolean) => {
    orderOutRef.current = out;
    setAccountVisible(true);
  };

  const onChangeAccountBook = () => {
    accountDirtyRef.current = true;
  };

  const onCloseAccount = () => {
    setAccountVisible(false);
    if (accountDirtyRef.current) {
      accountDirtyRef.current = false;
      setRefreshTrigger(refreshTrigger + 1);
    }
  };

  const [carryOverVisible, setCarryOverVisible] = useState(false);
  const carryOverDirtyRef = useRef(false);
  const onClickCarryOver = () => {
    setCarryOverVisible(true);
  };

  const onChangeCarryOver = () => {
    carryOverDirtyRef.current = true;
  };

  const onCloseCarryOver = () => {
    setCarryOverVisible(false);
    if (carryOverDirtyRef.current) {
      carryOverDirtyRef.current = false;
      setRefreshTrigger(refreshTrigger + 1);
    }
  };

  const printContentRef = useRef<HTMLDivElement>(null);
  const corp = dataStore.findCorp(fetchOption.corp_id);

  return (
    <>
      <h2>자금계획표</h2>

      <Form
        className="hl-form"
        layout="inline"
        style={{ marginBottom: 7 }}
        onSubmit={onSubmitSearchForm}
      >
        <Row type="flex" justify="space-between">
          <Col>
            <Form.Item>
              {getFieldDecorator('year', {
                initialValue: fetchOption.year
              })(
                <InputNumber
                  placeholder="년도"
                  style={{ width: 100 }}
                  formatter={value => `${value}년`}
                />
              )}
            </Form.Item>
            <Form.Item>
              {getFieldDecorator('month', {
                initialValue: fetchOption.month
              })(
                <InputNumber
                  placeholder="월"
                  style={{ width: 70 }}
                  min={1}
                  max={12}
                  formatter={value => `${value}월`}
                />
              )}
            </Form.Item>

            <FICorpSelect
              form={props.form}
              label="법인"
              width="170px"
              allowClear
            />

            <Form.Item>
              <Button type="primary" htmlType="submit">
                검색
              </Button>
            </Form.Item>
          </Col>
          <Col>
            <ReactToPrint
              trigger={() => <Button>출력</Button>}
              content={() => printContentRef.current}
            />{' '}
            {!preventEditing && (
              <>
                <Button onClick={e => onClickAccount(false)}>수금 등록</Button>{' '}
                <Button onClick={e => onClickAccount(true)}>지급 등록</Button>{' '}
                <Button onClick={e => onClickCarryOver()}>이월 등록</Button>
              </>
            )}
          </Col>
        </Row>
      </Form>

      <div ref={printContentRef}>
        <h2 className="only-print">
          자금계획표 / {fetchOption.year}년 {fetchOption.month}월{' '}
          {corp && corp.name}
        </h2>
        <AccountReportTable
          loading={loading}
          groups={groups}
          prevPlan={prevAmount.plan}
          prevAction={prevAmount.action}
          preventEditing={preventEditing}
          onSelectItem={onSelectItem}
        />
      </div>

      <Drawer
        closable={false}
        placement="right"
        width={1200}
        visible={collectEditVisible}
        onClose={onCloseCollectEdit}
        destroyOnClose={true}
      >
        <ContractPayBook
          orderId={orderIdRef.current}
          out={orderOutRef.current}
          onChange={onChangePayBook}
        />
      </Drawer>

      <Drawer
        closable={false}
        placement="right"
        width={1200}
        visible={accountVisible}
        onClose={onCloseAccount}
        destroyOnClose={true}
      >
        <AccountPayBook
          out={orderOutRef.current}
          fetchOption={fetchOption}
          fetchDelayed
          onChange={onChangeAccountBook}
        />
      </Drawer>

      <Modal
        title="이월금액 관리"
        width={600}
        centered
        visible={carryOverVisible}
        footer={null}
        onCancel={onCloseCarryOver}
        destroyOnClose={true}
      >
        <CarryOverList onChange={onChangeCarryOver} />
      </Modal>

      <Modal
        title={orderOutRef.current ? '지급등록' : '수금등록'}
        width={800}
        centered
        visible={itemEditorVisible}
        footer={null}
        onCancel={() => setItemEditorVisible(false)}
        destroyOnClose={true}
      >
        <AccountItemEditor
          out={orderOutRef.current}
          item={accountItemRef.current}
          onSuccess={onSuccessEditingAccount}
        />
      </Modal>
    </>
  );
};

export default Form.create<Props>()(AccountReport);
