import Service from './Index';

export type TripSearchOption = {
  keyword?: string;
  user_id?: number;
  year?: number;
};

const TripService = {
  // fetchTrips: (limit: number, page: number, options?: TripSearchOption) => {
  //   const offset = (page - 1) * limit;
  //   return Service.get('/etc/trips', {
  //     limit,
  //     offset,
  //     ...options
  //   });
  // }
  fetchTripsByDate: (date: string) => {
    return Service.get('/etc/trips_by_date', { date });
  },
  fetchTripsForMonth: (year: number, month: number) => {
    return Service.get('/etc/trips_for_month', {
      year,
      month
    });
  },

  fetchTripReports: (
    limit: number,
    page: number,
    options?: TripSearchOption
  ) => {
    const offset = (page - 1) * limit;
    return Service.get('/etc/trip_reports', {
      limit,
      offset,
      ...options
    });
  }
};

export default TripService;
