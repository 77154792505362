import _ from 'lodash';

export enum CompanyType {
  buyer = 1,
  partner
}

export class Company {
  id = 0;
  type = CompanyType.buyer;
  name = '';
  business_no = '';
  business_type = '';
  business_category = '';
  zipcode = '';
  address = '';
  phone = '';
  fax = '';
  email = '';
  homepage = '';
  ceo = '';
  note = '';

  [key: string]: any; // for string index (subscript)

  fieldsValue() {
    return _.omit(this, ['id']);
  }

  static fromJsonArray(sources: any[]) {
    return sources.map(source => Company.fromJson(source));
  }

  static fromJson(source: any) {
    let item = new Company();
    for (const key of Object.keys(source)) {
      if (key in item) {
        item[key] = source[key];
      }
    }
    return item;
  }

  static typeName(item?: CompanyType) {
    switch (item) {
      case CompanyType.buyer:
        return '발주처';
      case CompanyType.partner:
        return '협력업체';
      default:
        return '업체';
    }
  }
}
