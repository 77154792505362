import React, { useState } from 'react';
import { Form, Input, Button } from 'antd';
import { FormComponentProps } from 'antd/lib/form';
import Util from '../../helpers/Util';
import UserService from '../../services/UserService';

interface Props extends FormComponentProps {
  userId?: number; // 없으면 내 패스워드 변경
  onSuccess?: () => void;
}

const UserPasswordReset = (props: Props) => {
  const { userId } = props;
  const { getFieldDecorator } = props.form;
  const [confirmDirty, setConfirmDirty] = useState(false);
  const [submitting, setSubmitting] = useState(false);

  const handleSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    props.form.validateFields((err, values) => {
      if (err) return;
      processItem(values);
    });
  };

  const processItem = async (values: any) => {
    try {
      setSubmitting(true);
      if (userId) {
        await UserService.resetPassword(userId, values);
      } else {
        await UserService.resetMyPassword(values);
      }
      Util.showSuccess('변경되었습니다.');
      if (props.onSuccess) props.onSuccess();
    } catch (err) {
      Util.showError(err);
    } finally {
      setSubmitting(false);
    }
  };

  const validateToNextPassword = (rule: any, value: any, callback: any) => {
    const form = props.form;
    if (value && confirmDirty) {
      form.validateFields(['confirm'], { force: true });
    }
    callback();
  };

  const compareToFirstPassword = (rule: any, value: any, callback: any) => {
    const form = props.form;
    if (value && value !== form.getFieldValue('password')) {
      callback('입력하신 두 개의 비밀번호가 일치하지 않습니다.');
    } else {
      callback();
    }
  };

  const handleConfirmBlur = (e: React.FocusEvent<HTMLInputElement>) => {
    const value = e.target.value;
    setConfirmDirty(confirmDirty || !!value);
  };

  return (
    <Form
      labelCol={{ span: 6 }}
      wrapperCol={{ span: 16 }}
      onSubmit={handleSubmit}
    >
      {!userId && (
        <Form.Item label="기존 비밀번호">
          {getFieldDecorator('current_password', {
            rules: [
              {
                required: true,
                message: '기존 비밀번호를 입력해주세요.'
              }
            ]
          })(<Input.Password autoFocus />)}
        </Form.Item>
      )}
      <Form.Item label="새 비밀번호">
        {getFieldDecorator('password', {
          rules: [
            {
              required: true,
              message: '새 비밀번호를 입력해주세요.'
            },
            {
              min: 4,
              message: '비밀번호는 최소 4자 이상 입력해주세요.'
            },
            {
              max: 32,
              message: '비밀번호는 최대 32자 이내로 입력해주세요.'
            },
            {
              validator: validateToNextPassword
            }
          ]
        })(<Input.Password />)}
      </Form.Item>
      <Form.Item label="새 비밀번호 확인">
        {getFieldDecorator('confirm', {
          rules: [
            {
              required: true,
              message: '확인을 위해 비밀번호를 한번 더 입력해주세요.'
            },
            {
              validator: compareToFirstPassword
            }
          ]
        })(<Input.Password onBlur={handleConfirmBlur} />)}
      </Form.Item>
      <Form.Item wrapperCol={{ span: 12, offset: 6 }}>
        <Button type="primary" htmlType="submit" disabled={submitting}>
          변경
        </Button>
      </Form.Item>
    </Form>
  );
};

export default Form.create<Props>()(UserPasswordReset);
