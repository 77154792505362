import React, { useState } from 'react';
import { FormComponentProps } from 'antd/lib/form';
import { Order, Contract } from '../../../models/Order';
import ContractSummaryEditor from './ContractSummaryEditor';
import ContractItemsEditor from './ContractItemsEditor';

interface Props extends FormComponentProps {
  order: Order;
  contract?: Contract;
  restrictEditing?: boolean;
}

const ContractEditor: React.FC<Props> = props => {
  const { order, contract } = props;
  console.count('ContractEditor');

  const [needsRecalc, setNeedsRecalc] = useState(0);
  const onChangeSummary = () => {
    setNeedsRecalc(needsRecalc + 1);
  };

  return (
    <>
      <ContractSummaryEditor
        form={props.form}
        contract={contract}
        onChange={onChangeSummary}
        // 일단 ContractSummaryEditor는 편집제한 조치 X
        // 차후 권한 설정등 필요시 활용하기 위해 restrictEditing prop은 남겨둠
        // restrictEditing={props.restrictEditing}
      />

      <ContractItemsEditor
        form={props.form}
        order={order}
        contract={contract}
        needsRecalc={needsRecalc}
        restrictEditing={props.restrictEditing}
      />
    </>
  );
};

export default ContractEditor;
