import React, { useState, useEffect } from 'react';
import Form, { FormComponentProps } from 'antd/lib/form';
import { Button, InputNumber } from 'antd';
import Util from '../../../helpers/Util';
import { AccountItem } from '../../../models/Finance';
import FinanceService from '../../../services/FinanceService';

interface Props extends FormComponentProps {
  sourceYear: number;
  sourceMonth: number;
  sourceItems: AccountItem[];
  onSuccess?: () => void;
}

const AccountItemsCopier: React.FC<Props> = props => {
  const { sourceYear, sourceMonth, sourceItems } = props;
  const { getFieldDecorator } = props.form;

  const [targetYear, setTargetYear] = useState(sourceYear);
  const [targetMonth, setTargetMonth] = useState(sourceMonth);
  const [submitting, setSubmitting] = useState(false);

  useEffect(() => {
    if (sourceMonth === 12) {
      setTargetYear(sourceYear + 1);
      setTargetMonth(1);
    } else {
      setTargetMonth(sourceMonth + 1);
    }
  }, [sourceYear, sourceMonth]);

  console.count('AccountItemsCopier');

  const onSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    props.form.validateFields((err, values) => {
      if (err) return;
      const itemIds = sourceItems.map(e => e.id);
      processItem(itemIds, values.year, values.month);
    });
  };

  const processItem = async (
    source_ids: number[],
    year: number,
    month: number
  ) => {
    try {
      setSubmitting(true);
      const source = sourceYear * 12 + sourceMonth;
      const target = year * 12 + month;
      const interval = target - source;
      await FinanceService.copyAccountItems(source_ids, interval);
      Util.showSuccess('적용되었습니다.');
      if (props.onSuccess) props.onSuccess();
    } catch (err) {
      Util.showError(err);
    } finally {
      setSubmitting(false);
    }
  };

  return (
    <>
      <Form layout="inline" onSubmit={onSubmit}>
        <div className="hl-form" style={{ marginBottom: '10px' }}>
          <Form.Item>
            {getFieldDecorator('year', {
              initialValue: targetYear
            })(
              <InputNumber
                placeholder="년도"
                style={{ width: 100 }}
                formatter={value => `${value}년`}
              />
            )}
          </Form.Item>
          <Form.Item>
            {getFieldDecorator('month', {
              initialValue: targetMonth
            })(
              <InputNumber
                placeholder="월"
                style={{ width: 70 }}
                min={1}
                max={12}
                formatter={value => `${value}월`}
              />
            )}
          </Form.Item>

          <Form.Item>
            <Button type="primary" htmlType="submit" disabled={submitting}>
              붙여넣기
            </Button>
          </Form.Item>
        </div>
      </Form>
    </>
  );
};

export default Form.create<Props>()(AccountItemsCopier);
