import React from 'react';
import { BUILD_NO } from '../config';
import { Layout, Row, Col, Divider } from 'antd';
import { HLBody } from '../components/layout/Index';
import PostListWidget from './board/PostListWidget';
import { inject, observer } from 'mobx-react';
import { AuthStore } from '../stores/authStore';
import moment from 'moment';
import HomeCalendarWidget from './HomeCalendarWidget';
import HomeEADocWidget from './HomeEADocWidget';
import NotificationSummary from './system/NotificationSummary';
import Text from 'antd/lib/typography/Text';
const { Sider, Footer } = Layout;

interface Props {
  authStore?: AuthStore;
}

const Index: React.FC<Props> = (props) => {
  const authStore = props.authStore!;

  // https://unsplash.com/developers
  // https://source.unsplash.com/

  return (
    <>
      <Sider
        id="sider"
        width={200}
        style={{
          background: '#fff',
          overflowX: 'hidden',
          overflowY: 'hidden', //'auto',
          position: 'fixed',
          left: 0,
        }}
      >
        <img
          src="https://images.unsplash.com/photo-1490351267196-b7a67e26e41b?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=1529&q=80"
          alt="bg"
        ></img>
        {/* <img src="https://source.unsplash.com/random/200x2000" /> */}
      </Sider>
      <Layout id="wrapper" style={{ marginLeft: 200 }}>
        <Layout.Content>
          <HLBody>
            {authStore.user.nameWithDeptRank}님 안녕하세요.
            <h2>{moment().format('YYYY년 M월 D일 dddd')}</h2>
            <Divider />
            <Row style={{ marginTop: 15 }}>
              <Col span={24}>
                <HomeCalendarWidget />
              </Col>
            </Row>
            <Row gutter={15} style={{ marginTop: 20 }}>
              <Col span={12}>
                <PostListWidget type={1} />
                <div style={{ height: 30 }}></div>
                {/* <PostListWidget
                  type={1}
                  refId={authStore.user.rootDept && authStore.user.rootDept.id}
                /> */}
                <PostListWidget type={2} />
              </Col>
              <Col span={12}>
                <h3>주요 알림</h3>
                <div style={{ borderTop: '1px solid #e8e8e8' }} />
                <NotificationSummary itemCount={7} />
              </Col>
            </Row>
            <Row style={{ marginTop: 20 }}>
              <Col>
                <HomeEADocWidget />
              </Col>
            </Row>
            {/* <Row style={{ marginTop: 15 }}>
              <Col span={24}>
                <h3>프로젝트 현황</h3>
                <Row gutter={15}>
                  <Col span={6}>
                    <Statistic title="진행 프로젝트" value={0} />
                    <LinkButton>전체보기</LinkButton>
                  </Col>
                  <Col span={6}>
                    <Statistic title="완료 프로젝트" value={0} />
                    <LinkButton>전체보기</LinkButton>
                  </Col>
                  <Col span={6}>
                    <Statistic
                      title="수금현황 (기성율)"
                      value={0}
                      precision={2}
                    />
                    <LinkButton>수금현황</LinkButton>
                  </Col>
                  <Col span={6}>
                    <Statistic
                      title="지급현황 (기성율)"
                      value={0}
                      precision={2}
                    />
                    <LinkButton>지급현황</LinkButton>
                  </Col>
                </Row>
              </Col>
            </Row> */}
            <Divider />
            {/* <Footer style={{ textAlign: 'center', backgroundColor: 'white' }}> */}
            <Text type="secondary">HANLIM ERP Web Client Build {BUILD_NO}</Text>
            {/* </Footer> */}
          </HLBody>
        </Layout.Content>
      </Layout>
    </>
  );
};

export default inject('authStore')(observer(Index));
