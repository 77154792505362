import React, { useState } from 'react';
import { Button, Row, Col, Icon } from 'antd';
import Util from '../../helpers/Util';
import DragableTable from './DragableTable';
import { StringCode } from '../../models/Common';
import StringEditor from './StringEditor';
import LinkButton from './LinkButton';

interface Props {
  items: StringCode[];
  onSubmit: (items: StringCode[]) => void;
}

const StringCodeListEditor: React.FC<Props> = props => {
  const [items, setItems] = useState<StringCode[]>(props.items);
  const [editingItem, setEditingItem] = useState<StringCode | undefined>(
    undefined
  );
  const [editorVisible, setEditorVisible] = useState(false);

  const columns = [
    {
      title: '이름',
      dataIndex: 'code'
    },
    {
      width: '100px',
      render: (text: string, record: StringCode) => {
        return (
          <>
            <LinkButton onClick={e => onClickEditItemButton(record)}>
              수정
            </LinkButton>{' '}
            |{' '}
            <LinkButton onClick={e => onClickDeleteItemButton(record)}>
              삭제
            </LinkButton>
          </>
        );
      }
    }
  ];

  const onClickAdd = () => {
    setEditorVisible(true);
  };

  const onClickEditItemButton = (item: StringCode) => {
    setEditingItem(item);
    setEditorVisible(true);
  };

  const onSubmitStringEditor = (code: string) => {
    const index = items.findIndex(eachItem => eachItem.code === code);
    if (index >= 0) {
      Util.showError(new Error('이미 해당 항목이 존재합니다.'));
      return;
    }

    if (editingItem) {
      setItems(items.map(el => (el.code === editingItem.code ? { code } : el)));
    } else {
      setItems([...items, { code }]);
    }

    setEditorVisible(false);
    setEditingItem(undefined);
  };

  const onClickDeleteItemButton = (item: StringCode) => {
    setItems(items.filter(el => el.code !== item.code));
  };

  const onChangeOrder = (items: StringCode[]) => {
    setItems(items);
  };

  const onClickSave = () => {
    props.onSubmit(items);
  };

  return (
    <>
      <Row type="flex" justify="space-between" style={{ marginBottom: 10 }}>
        <Col></Col>
        <Col>
          <Button onClick={onClickSave}>
            <Icon type="save" />
            변경사항 저장
          </Button>{' '}
          <Button onClick={onClickAdd}>
            <Icon type="plus" />
            항목 등록
          </Button>
        </Col>
      </Row>

      <DragableTable
        columns={columns}
        dataSource={items}
        rowKey="code"
        bordered={true}
        pagination={false}
        size="middle"
        onChangeOrder={onChangeOrder}
      />

      <StringEditor
        value={editingItem && editingItem.code}
        visible={editorVisible}
        onSubmit={onSubmitStringEditor}
        onCancel={() => setEditorVisible(false)}
      />
    </>
  );
};

export default StringCodeListEditor;
