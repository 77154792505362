import React, { useState } from 'react';
import { Row, Col, Form, Input, Button, Switch } from 'antd';
import { FormComponentProps } from 'antd/lib/form';
import { EAFlowItem, EADoc } from '../../models/EAS';
import { AuthStore } from '../../stores/authStore';
import { RouteComponentProps, withRouter } from 'react-router';
import { observer, inject } from 'mobx-react';
import MultiUserSelect from '../../components/general/MultiUserSelect';
import userStore from '../../stores/userStore';
import MultiDeptSelect from '../../components/general/MultiDeptSelect';
import EADocEditoFlowPanel from './EADocEditorFlowPanel';
import InlineItem from '../../components/formcomps/InlineItem';
import InlineText from '../../components/formcomps/InlineText';
import FCFileInput from '../../components/formcomps/FCFileInput';
import FCEADocInput from '../../components/formcomps/FCEADocInput';
import FICorpSelect from '../../components/formcomps/FICorpSelect';
import EADocEditorGuidePanel from './EADocEditorGuidePanel';

interface Props extends FormComponentProps, RouteComponentProps {
  authStore?: AuthStore;
  title: string;
  docTitle?: string; // docTitle이 있을 경우 해당 값으로 문서제목 설정
  preventEditingTitle?: boolean;
  privateHidden?: boolean; // 부서발신함 비공개 옵션 숨김 처리 여부
  refTarget?: string;
  loading?: boolean;
  ccDocs?: EADoc[];
  onSubmit?: (data: any, submit: boolean) => void;
}

const EADocDraftContainer: React.FC<Props> = props => {
  const [eaItems, setEAItems] = useState<EAFlowItem[]>([]);
  const [ccItems, setCCItems] = useState<EAFlowItem[]>([]);
  const authStore = props.authStore!;
  const { getFieldDecorator } = props.form;
  const { docTitle, privateHidden, preventEditingTitle = false } = props;

  const onClickSubmitButton = () => {
    processSubmit(true);
  };

  const onClickSaveDraft = () => {
    processSubmit(false);
  };

  // submit: 상신 여부 (true: 상신, false: 임시저장)
  const processSubmit = (submit: boolean) => {
    props.form.validateFields((err, values) => {
      if (err) return;
      if (props.onSubmit === undefined) return;

      const rootDept = authStore.user.rootDept;
      const dept_id = rootDept ? rootDept.id : null;

      const ea_flows = eaItems.map(eaItem => {
        return {
          user_id: eaItem.user.id,
          type: eaItem.type
        };
      });
      const ccuser_ids = ccItems.map(ccItem => ccItem.user.id);

      const data = {
        ...values,
        dept_id,
        ea_flows,
        ccuser_ids,
        bccuser_ids: []
      };

      props.onSubmit(data, submit);
    });
  };

  const ccUserIds = ccItems.map(ccItem => ccItem.user.id);
  const handleCCUserChange = (values: number[]) => {
    const ccItems = values.map((value: number) => {
      const user = userStore.findById(value);
      return new EAFlowItem(user, 3);
    });
    setCCItems(ccItems);
  };

  const onChangeFlowPanel = (eaItems: EAFlowItem[], ccItems: EAFlowItem[]) => {
    setEAItems(eaItems);
    setCCItems(ccItems);
  };

  const submitButtonDisabled = eaItems.length === 0 || props.loading;

  const formItemLayout = {
    labelCol: { span: 3 },
    wrapperCol: { span: 20 }
  };

  return (
    <>
      <h2>{props.title}</h2>
      <Row gutter={12}>
        <Col className="gutter-row" span={18}>
          <Form className="hl-form" {...formItemLayout}>
            <Form.Item label="기안자">
              <InlineItem width="40%">
                <Input value={authStore.user.nameWithDeptRank} disabled />
              </InlineItem>{' '}
              <InlineItem width="40%">
                <FICorpSelect
                  allowClear
                  form={props.form}
                  options={{
                    initialValue: authStore.user.corp && authStore.user.corp.id
                  }}
                />
              </InlineItem>
            </Form.Item>

            {/* {docTitle ? (
              <>
                {getFieldDecorator('title', { initialValue: docTitle })(
                  <Input type="hidden" />
                )}
                <Form.Item label="문서 제목">
                  <Input value={docTitle} />
                </Form.Item>
              </>
            ) : (
              <Form.Item label="문서 제목">
                {getFieldDecorator('title', {
                  rules: [
                    {
                      required: true,
                      message: '문서 제목을 입력해주세요.'
                    },
                    {
                      whitespace: true,
                      message: '문서 제목을 입력해주세요.'
                    }
                  ]
                })(<Input placeholder="문서 제목을 입력해주세요." autoFocus />)}
              </Form.Item>
            )} */}

            <Form.Item label="문서 제목">
              {getFieldDecorator('title', {
                initialValue: docTitle,
                rules: [
                  {
                    required: true,
                    message: '문서 제목을 입력해주세요.'
                  },
                  {
                    whitespace: true,
                    message: '문서 제목을 입력해주세요.'
                  }
                ]
              })(
                <Input
                  placeholder="문서 제목을 입력해주세요."
                  disabled={preventEditingTitle}
                  autoFocus={!preventEditingTitle}
                />
              )}
            </Form.Item>

            <Form.Item label="참조">
              <MultiUserSelect
                values={ccUserIds}
                onChange={handleCCUserChange}
              />
            </Form.Item>

            <Form.Item label="수신부서">
              <InlineItem width="60%">
                <MultiDeptSelect form={props.form} name="ccdept_ids" />
              </InlineItem>
              {!privateHidden && (
                <>
                  <InlineText>부서발신함 비공개: </InlineText>
                  <InlineItem>
                    {getFieldDecorator('is_private', {
                      valuePropName: 'checked',
                      initialValue: false
                    })(<Switch />)}
                  </InlineItem>
                </>
              )}
            </Form.Item>

            <Form.Item label="문서 첨부">
              <FCEADocInput form={props.form} items={props.ccDocs} />
            </Form.Item>

            <Form.Item label="파일 첨부">
              <FCFileInput form={props.form} />
            </Form.Item>
          </Form>

          <div style={{ marginTop: '15px' }}>{props.children}</div>
        </Col>
        <Col className="gutter-row" span={6}>
          <EADocEditoFlowPanel
            eaItems={eaItems}
            ccItems={ccItems}
            onChange={onChangeFlowPanel}
            refTarget={props.refTarget}
          />

          <Row gutter={10} style={{ marginTop: '10px' }}>
            <Col>
              <Button
                block
                disabled={submitButtonDisabled}
                onClick={onClickSaveDraft}
              >
                임시저장
              </Button>
            </Col>
          </Row>
          <Row gutter={10} style={{ marginTop: '10px', marginBottom: '20px' }}>
            <Col>
              <Button
                block
                type="primary"
                htmlType="submit"
                disabled={submitButtonDisabled}
                onClick={onClickSubmitButton}
              >
                기안하기
              </Button>
            </Col>
          </Row>

          <EADocEditorGuidePanel />
        </Col>
      </Row>
    </>
  );
};

const _EADocDraftContainer = Form.create<Props>()(EADocDraftContainer);
export default inject('authStore')(observer(withRouter(_EADocDraftContainer)));
