import React, { useState, useEffect, useRef } from 'react';
import {
  Table,
  Button,
  Row,
  Col,
  Icon,
  Input,
  Form,
  Drawer,
  Popconfirm
} from 'antd';
import LinkButton from '../../../components/general/LinkButton';
import { FormComponentProps } from 'antd/lib/form';
import { PaginationConfig } from 'antd/lib/table';
import Util from '../../../helpers/Util';
import AButton from '../../../components/general/AButton';
import AssetEdit from './AssetEdit';
import AssetService, {
  AssetSearchOption
} from '../../../services/AssetService';
import { Asset } from '../../../models/Asset';
import AssetDetail from './AssetDetail';

interface Props extends FormComponentProps {
  category1?: string;
  category2?: string;
  includeDisposal?: boolean;
  hideToolbar?: boolean;
  onChange?: () => void;
}

const AssetList: React.FC<Props> = props => {
  const {
    hideToolbar = false,
    includeDisposal = true,
    category1,
    category2
  } = props;
  const [loading, setLoading] = useState(false);
  const [totalCount, setTotalCount] = useState(0);
  const [currentPage, setCurrentPage] = useState(1);
  const [refreshTrigger, setRefreshTrigger] = useState(1);
  const [searchOption, setSearchOption] = useState<AssetSearchOption>({
    category1,
    category2,
    include_disposal: includeDisposal
  });
  const [items, setItems] = useState<Asset[]>([]);
  const { getFieldDecorator } = props.form;

  const pagination = {
    pageSize: 15,
    current: currentPage,
    total: totalCount,
    showTotal: (total: number, range: [number, number]) =>
      `전체 ${total}건 중 ${range[0]}-${range[1]}`
  };

  useEffect(() => {
    const fetchItems = async () => {
      setLoading(true);
      try {
        const result = await AssetService.fetchItems(
          pagination.pageSize,
          currentPage,
          searchOption
        );
        setTotalCount(result.total_count);
        setItems(Asset.fromJsonArray(result.items));
      } catch (error) {
        Util.showError(error);
      } finally {
        setLoading(false);
      }
    };
    fetchItems();
  }, [currentPage, pagination.pageSize, searchOption, refreshTrigger]);

  const columns = [
    {
      title: '자산번호',
      dataIndex: 'code',
      width: '100px'
    },
    {
      title: '자산명',
      dataIndex: 'name',
      render: (text: string, record: Asset) => (
        <AButton onClick={e => onClickItem(record)}>{text}</AButton>
      )
    },
    {
      title: '대분류',
      dataIndex: 'category1'
    },
    {
      title: '중분류',
      dataIndex: 'category2'
    },
    {
      title: '제조사',
      dataIndex: 'manufacturer'
    },
    {
      title: '모델명',
      dataIndex: 'model'
    },
    {
      title: '구입비용',
      dataIndex: 'price',
      align: 'right' as 'right',
      render: (text: string, record: Asset) => Util.formatNumber(record.price)
    },
    {
      title: '구입일자',
      dataIndex: 'purchase_date',
      width: 90,
      render: (text: string, record: Asset) =>
        Util.formatDate(record.purchase_date)
    },
    {
      title: '폐기일자',
      dataIndex: 'dispose_date',
      width: 90,
      render: (text: string, record: Asset) =>
        Util.formatDate(record.dispose_date)
    },
    {
      title: '사번',
      width: 60,
      align: 'right' as 'right',
      dataIndex: 'user.id'
    },
    {
      title: '성명',
      width: 80,
      dataIndex: 'user.name'
    },
    {
      title: '부서',
      dataIndex: 'user.deptName'
    },
    {
      title: '직급',
      width: 80,
      dataIndex: 'user.rank'
    },
    {
      width: '80px',
      render: (text: string, record: Asset) => {
        return (
          <>
            <LinkButton onClick={e => onClickUpdateButton(record)}>
              수정
            </LinkButton>{' '}
            |{' '}
            <Popconfirm
              title="정말 삭제하시겠습니까?"
              onConfirm={e => onClickDeleteButton(record)}
            >
              <LinkButton>삭제</LinkButton>
            </Popconfirm>
          </>
        );
      }
    }
  ];

  const onChangeTable = (pagination: PaginationConfig) => {
    setCurrentPage(pagination.current || 1);
  };

  const onSubmitSearchForm = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    props.form.validateFields((err, values) => {
      if (err) return;
      setSearchOption(values);
      setCurrentPage(1);
    });
  };

  const itemRef = useRef<Asset | undefined>(undefined);
  const itemDirtyRef = useRef(false);
  const [editorVisible, setEditorVisible] = useState(false);
  const [detailVisible, setDetailVisible] = useState(false);

  const onClickAddButton = () => {
    itemRef.current = undefined;
    setEditorVisible(true);
  };

  const onClickUpdateButton = async (item: Asset) => {
    itemRef.current = item;
    setEditorVisible(true);
  };

  const onCloseEditor = () => {
    setEditorVisible(false);
  };

  const onSuccessEditing = () => {
    setRefreshTrigger(v => v + 1);
    setEditorVisible(false);
    props.onChange && props.onChange();
  };

  const onClickDeleteButton = async (item: Asset) => {
    try {
      await AssetService.delete(item.id);
      Util.showSuccess('삭제되었습니다.');
      setRefreshTrigger(v => v + 1);
      props.onChange && props.onChange();
    } catch (err) {
      Util.showError(err);
    }
  };

  const onClickItem = (item: Asset) => {
    itemRef.current = item;
    setDetailVisible(true);
  };

  const onCloseDetail = () => {
    setDetailVisible(false);
    if (itemDirtyRef.current) {
      itemDirtyRef.current = false;
      setRefreshTrigger(v => v + 1);
    }
  };

  const onChangeDetail = () => {
    itemDirtyRef.current = true;
  };

  let title = '자산관리';

  if (category1 || category2) {
    if (category1 && category2) {
      title = `자산목록 ${category1} / ${category2}`;
    } else if (category1) {
      title = `자산목록 ${category1}`;
    } else {
      title = `자산목록 ${category2}`;
    }
  }

  return (
    <>
      <h2>{title}</h2>

      {!hideToolbar && (
        <Form
          className="hl-form"
          layout="inline"
          style={{ marginBottom: 7 }}
          onSubmit={onSubmitSearchForm}
        >
          <Row type="flex" justify="space-between">
            <Col>
              <Form.Item>
                {getFieldDecorator('keyword')(
                  <Input.Search
                    placeholder="검색어"
                    allowClear={true}
                    style={{ width: 150 }}
                  />
                )}
              </Form.Item>

              <Form.Item>
                <Button type="primary" htmlType="submit">
                  검색
                </Button>
              </Form.Item>
            </Col>

            <Col>
              <Button onClick={onClickAddButton}>
                <Icon type="plus" />
                자산 등록
              </Button>
            </Col>
          </Row>
        </Form>
      )}

      <Table
        columns={columns}
        dataSource={items}
        pagination={pagination}
        onChange={onChangeTable}
        rowKey="id"
        loading={loading}
        bordered={true}
        size="middle"
      />

      <Drawer
        closable={false}
        placement="right"
        width={1000}
        visible={editorVisible}
        onClose={onCloseEditor}
        destroyOnClose={true}
      >
        <AssetEdit
          id={itemRef.current ? itemRef.current.id : undefined}
          onSuccess={onSuccessEditing}
        />
      </Drawer>

      <Drawer
        closable={false}
        placement="right"
        width="85%"
        visible={detailVisible}
        onClose={onCloseDetail}
        destroyOnClose={true}
      >
        <AssetDetail
          item={itemRef.current || new Asset()}
          onChange={onChangeDetail}
        />
      </Drawer>
    </>
  );
};

export default Form.create<Props>()(AssetList);
