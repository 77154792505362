import React, { useState, useEffect, useRef } from 'react';
import { withRouter, RouteComponentProps } from 'react-router';
import {
  Table,
  Row,
  Col,
  Input,
  Form,
  Button,
  InputNumber,
  Drawer,
  Tag,
} from 'antd';
import { FormComponentProps } from 'antd/lib/form';
import { Order, OrderState } from '../../../models/Order';
import LinkButton from '../../../components/general/LinkButton';
import OrderService, {
  OrderSearchOption,
} from '../../../services/OrderService';
import Util from '../../../helpers/Util';
import { PaginationConfig, SorterResult } from 'antd/lib/table';
import OrderDetail from '../orders/OrderDetail';
import FIHQSelect from '../../../components/formcomps/FIHQSelect';

interface Props extends RouteComponentProps, FormComponentProps {
  title: string;
  menu?: string;
  deptId?: number;
  deptIds?: number[];
  hideDeptSelector?: boolean;
  target?: string;
  state?: OrderState;
}

const OrderLedger: React.FC<Props> = (props) => {
  const {
    menu = 'projects',
    state = OrderState.ongoing,
    deptId,
    title,
    target,
  } = props;
  const { getFieldDecorator } = props.form;

  const [items, setItems] = useState<Order[]>([]);
  const [searchOption, setSearchOption] = useState<OrderSearchOption>({
    dept_id: deptId,
    target,
    state,
    sort_field: localStorage.getItem('orderSorterField') || 'year',
    sort_order: localStorage.getItem('orderSorterOrder') || 'descend',
  });

  const [totalCount, setTotalCount] = useState(0);
  const [currentPage, setCurrentPage] = useState(1);
  const [loading, setLoading] = useState(false);
  const [refreshTrigger, setRefreshTrigger] = useState(1);

  const pagination = {
    pageSize: 15,
    current: currentPage,
    total: totalCount,
    showTotal: (total: number, range: [number, number]) =>
      `전체 ${total}건 중 ${range[0]}-${range[1]}`,
  };

  const firstRenderRef = useRef(true);
  useEffect(() => {
    if (firstRenderRef.current) {
      firstRenderRef.current = false;
      return;
    }
    // 최초 렌더링시 setSearchOption 호출로 인해 fetchItems가 두번 호출되는 것을 방지하기 위해 firstRender 아닐때에만 설정
    setSearchOption({
      dept_id: deptId,
      target,
      state,
      sort_field: localStorage.getItem('orderSorterField') || 'year',
      sort_order: localStorage.getItem('orderSorterOrder') || 'descend',
    });
  }, [deptId, target, state]);

  useEffect(() => {
    const fetchItems = async () => {
      setLoading(true);
      try {
        const result = await OrderService.fetchItems(
          pagination.pageSize,
          currentPage,
          searchOption
        );
        setTotalCount(result.total_count);
        setItems(Order.fromJsonArray(result.items));
      } catch (error) {
        Util.showError(error);
      } finally {
        setLoading(false);
      }
    };

    fetchItems();
  }, [currentPage, searchOption, pagination.pageSize, refreshTrigger]);

  const onChangeTable = (
    pagination: PaginationConfig,
    filters: Partial<Record<React.ReactText, string[]>>,
    sorter: SorterResult<Order>
  ) => {
    setCurrentPage(pagination.current || 1);
    setSearchOption({
      ...searchOption,
      sort_field: sorter.field,
      sort_order: sorter.order,
    });
    localStorage.setItem('orderSorterField', sorter.field);
    localStorage.setItem('orderSorterOrder', sorter.order);
  };

  const onSubmitSearchForm = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    props.form.validateFields((err, values) => {
      if (err) return;
      setSearchOption({ ...values, target });
      setCurrentPage(1);
    });
  };

  const [currentItem, setCurrentItem] = useState<Order>(new Order());
  const [detailVisible, setDetailVisible] = useState(false);

  const onClickItemName = (item: Order) => {
    setCurrentItem(item);
    setDetailVisible(true);
    // props.history.push(`/${menu}/${submenu}/orders/view?id=${item.id}`);
  };

  const orderDetailDirtyRef = useRef(false);
  const onChangeDetail = () => {
    orderDetailDirtyRef.current = true;
  };

  const onCloseDetail = () => {
    setDetailVisible(false);
    if (orderDetailDirtyRef.current) {
      orderDetailDirtyRef.current = false;
      setRefreshTrigger((v) => v + 1);
    }
  };

  const sortOrderForField = (
    field: string
  ): 'ascend' | 'descend' | undefined => {
    const sortOrder =
      searchOption.sort_order === 'ascend' ? 'ascend' : 'descend';
    return searchOption.sort_field === field ? sortOrder : undefined;
  };

  const columns = [
    {
      title: 'No',
      dataIndex: 'id',
      fixed: 'left' as 'left',
      align: 'right' as 'right',
      sorter: true,
      defaultSortOrder: sortOrderForField('id'),
      width: 55,
    },
    {
      title: 'BOM',
      dataIndex: 'bom',
      fixed: 'left' as 'left',
      width: 70,
    },
    {
      title: '계약건명',
      dataIndex: 'name',
      fixed: 'left' as 'left',
      sorter: true,
      defaultSortOrder: sortOrderForField('name'),
      width: 300,
      render: (text: string, record: Order) => (
        <LinkButton onClick={(e) => onClickItemName(record)}>
          <div className="truncate" style={{ width: '300px' }}>
            {text}
          </div>
        </LinkButton>
      ),
    },
    {
      title: '연도',
      dataIndex: 'year',
      width: 60,
      sorter: true,
      defaultSortOrder: sortOrderForField('year'),
      render: (text: string, record: Order) => record.year,
    },
    {
      title: '업체명',
      dataIndex: 'comp_name',
      // width: 150
    },
    {
      title: '수주',
      children: [
        {
          title: '계약금액',
          key: '수주1',
          align: 'right' as 'right',
          // width: '120px',
          render: (text: string, record: Order) => ({
            children: (
              <Tag color={record.in_total_price ? 'blue' : ''}>
                {record.in_total_price ? record.f_in_total_price : 'N/A'}
              </Tag>
            ),
          }),
        },
        {
          title: '수금금액',
          key: '수주2',
          align: 'right' as 'right',
          // width: '120px',
          render: (text: string, record: Order) =>
            record.in_total_price ? record.f_in_pay : '',
        },
        {
          title: '잔 액',
          key: '수주3',
          align: 'right' as 'right',
          // width: '120px',
          render: (text: string, record: Order) =>
            record.in_total_price ? record.f_in_balance : '',
        },
        {
          title: '기성율',
          key: '수주4',
          align: 'right' as 'right',
          // width: '60px',
          render: (text: string, record: Order) =>
            record.in_total_price ? `${record.f_in_payrate} %` : '',
        },
      ],
    },
    {
      title: '외주',
      children: [
        {
          title: '계약금액',
          key: '외주1',
          align: 'right' as 'right',
          width: '120px',
          render: (text: string, record: Order) => {
            if (record.in_total_price) {
              return (
                <Tag color={record.out_total_price ? 'red' : ''}>
                  {record.out_total_price ? record.f_out_total_price : 'N/A'}
                </Tag>
              );
            } else {
              return '';
            }
          },
        },
        {
          title: '지급금액',
          key: '외주12',
          align: 'right' as 'right',
          // width: '120px',
          render: (text: string, record: Order) =>
            record.out_total_price ? record.f_out_pay : '',
        },
        {
          title: '도래미지급',
          key: '외주3',
          dataIndex: 'f_unpaid_advent',
          align: 'right' as 'right',
          // width: '120px'
        },
        {
          title: '미도래잔액',
          key: '외주4',
          dataIndex: 'f_unpaid_inadvent',
          align: 'right' as 'right',
          // width: '120px'
        },
        {
          title: '기성율',
          key: '외주5',
          align: 'right' as 'right',
          // width: '60px',
          render: (text: string, record: Order) =>
            record.out_total_price ? `${record.f_out_payrate} %` : '',
        },
      ],
    },
    {
      title: '외주비율',
      fixed: 'right' as 'right',
      align: 'right' as 'right',
      width: 70,
      render: (text: string, record: Order) =>
        record.in_total_price && record.out_total_price
          ? `${record.f_out_rate} %`
          : '',
    },
  ];

  return (
    <>
      <h2>{title}</h2>

      <Form
        className="hl-form"
        layout="inline"
        style={{ marginBottom: 7 }}
        onSubmit={onSubmitSearchForm}
      >
        <Row type="flex" justify="space-between">
          <Col>
            {getFieldDecorator('state', { initialValue: state })(
              <Input type="hidden" />
            )}
            <Form.Item>
              {getFieldDecorator('keyword')(
                <Input.Search
                  placeholder="검색어"
                  allowClear={true}
                  style={{ width: 150 }}
                />
              )}
            </Form.Item>

            <Form.Item>
              {getFieldDecorator('year')(
                <InputNumber placeholder="년도" style={{ width: 100 }} />
              )}
            </Form.Item>

            <FIHQSelect
              form={props.form}
              options={{ initialValue: deptId }}
              width="180px"
              allowClear
              hide={props.hideDeptSelector}
              deptIds={props.deptIds}
              onChange={(value) => {
                localStorage.setItem(
                  `${menu}DefaultDeptId`,
                  value ? String(value) : ''
                );
              }}
            />

            <Form.Item>
              <Button type="primary" htmlType="submit">
                검색
              </Button>
            </Form.Item>
          </Col>

          <Col></Col>
        </Row>
      </Form>

      <Table
        columns={columns}
        dataSource={items}
        rowKey="id"
        pagination={pagination}
        onChange={onChangeTable}
        bordered={true}
        loading={loading}
        size="middle"
        scroll={{ x: 'max-content' }}
      />

      <Drawer
        closable={false}
        placement="right"
        width={'85%'}
        visible={detailVisible}
        onClose={onCloseDetail}
        destroyOnClose={true}
      >
        <OrderDetail
          id={currentItem.id}
          fetchDelayed
          onChangeDetail={onChangeDetail}
        />
      </Drawer>
    </>
  );
};

const _OrderLedger = Form.create<Props>()(OrderLedger);
export default withRouter(_OrderLedger);
