import { MenuItem } from '../../../models/Common';

// const link = '/business/cm2/uc';

const menuItems: MenuItem[] = [
  {
    id: '프로젝트총괄',
    title: '프로젝트 총괄',
    icon: 'desktop',
    link: '/business/cm2/dashboard'
  },
  {
    id: '진행 프로젝트',
    title: '진행 프로젝트',
    icon: 'desktop',
    link: '/business/cm2/ongoing'
  },
  {
    id: '업무프로세스',
    title: '업무프로세스',
    icon: 'desktop',
    children: [
      {
        id: 'ord',
        title: '수주정보보고',
        link: '/business/cm2/eadocs/ord'
      },
      {
        id: 'orders',
        title: '수주심의',
        link: '/business/cm2/orders'
      },
      {
        id: 'ordins',
        title: '수주견적',
        link: '/business/cm2/ordins'
      },
      {
        id: 'ctrins',
        title: '수주계약',
        link: '/business/cm2/ctrins'
      },
      {
        id: 'ordouts',
        title: '외주실행',
        link: '/business/cm2/ordouts'
      },
      {
        id: 'ctrouts',
        title: '외주계약',
        link: '/business/cm2/ctrouts'
      }
      // {
      //   id: 'comp',
      //   title: '협력업체등록신청',
      //   link: '/business/cm2/uc'
      // }
    ]
  },
  {
    id: '수금계획표',
    title: '수금계획표',
    icon: 'desktop',
    link: '/business/cm2/collect'
  },
  {
    id: '3302',
    title: '계약관리',
    icon: 'desktop'
  },
  {
    id: '3303',
    title: '업무보고 (자료실)',
    icon: 'desktop'
  },
  {
    id: '교육',
    title: '교육',
    icon: 'desktop',
    children: [
      {
        id: '교육1',
        title: '교육수강내역',
        link: '/business/cm2/myedu'
      },
      {
        id: '교육2',
        title: '교육일정',
        link: '/business/cm2/edu'
      }
    ]
  },
  {
    id: '설정',
    title: '설정',
    icon: 'desktop',
    children: [
      {
        id: 'stages',
        title: '부서별 진행단계',
        link: '/business/cm2/settings/stages'
      },
      {
        id: 'ctypes',
        title: '부서별 수금구분',
        link: '/business/cm2/settings/ctypes'
      }
    ]
  }
];

export default menuItems;
