import React, { useState, useEffect } from 'react';
import { Row, Col, Form, Input, Button, Switch } from 'antd';
import { FormComponentProps } from 'antd/lib/form';
import { EAFlowItem, EADoc, EAFlowItemType } from '../../models/EAS';
import { AuthStore } from '../../stores/authStore';
import { RouteComponentProps, withRouter } from 'react-router';
import { observer, inject } from 'mobx-react';
import MultiUserSelect from '../../components/general/MultiUserSelect';
import userStore from '../../stores/userStore';
import MultiDeptSelect from '../../components/general/MultiDeptSelect';
import InlineItem from '../../components/formcomps/InlineItem';
import InlineText from '../../components/formcomps/InlineText';
import FCFileInput from '../../components/formcomps/FCFileInput';
import FCEADocInput from '../../components/formcomps/FCEADocInput';
import EADocEditorFlowPanel from './EADocEditorFlowPanel';

interface Props extends FormComponentProps, RouteComponentProps {
  authStore?: AuthStore;
  title: string;
  doc?: EADoc;
  docTitle?: string; // docTitle이 있을 경우 해당 값으로 문서제목 설정, 편집 불가
  privateHidden?: boolean; // 부서발신함 비공개 옵션 숨김 처리 여부
  loading?: boolean;
  onSubmit?: (data: any) => void;
}

const EADocEditContainer: React.FC<Props> = (props) => {
  const [item, setItem] = useState<EADoc>(new EADoc());
  const [eaItems, setEAItems] = useState<EAFlowItem[]>([]);
  const [ccItems, setCCItems] = useState<EAFlowItem[]>([]);
  const [ccDeptIds, setCCDeptIds] = useState<number[]>([]);
  const authStore = props.authStore!;
  const { getFieldDecorator } = props.form;
  const { docTitle, privateHidden } = props;

  useEffect(() => {
    const doc = props.doc;
    if (doc) {
      setItem(doc);
      setEAItems(doc.eaFlow.items);
      const ccItems = doc.ccUsers.map(
        (user) => new EAFlowItem(user, EAFlowItemType.refer)
      );
      setCCItems(ccItems);
      const ccDeptIds = doc.ccDepts.map((dept) => dept.id);
      setCCDeptIds(ccDeptIds);
    }
  }, [props.doc]);

  const onClickSubmitButton = () => {
    props.form.validateFields((err, values) => {
      if (err) return;
      if (props.onSubmit === undefined) return;

      const rootDept = authStore.user.rootDept;
      const dept_id = rootDept ? rootDept.id : null;

      const ea_flows = eaItems.map((e) => ({
        user_id: e.user.id,
        type: e.type,
        action: e.action,
      }));

      const ccuser_ids = ccItems.map((ccItem) => ccItem.user.id);

      const data = {
        ...values,
        dept_id,
        ea_flows,
        ccuser_ids,
        bccuser_ids: [],
      };

      props.onSubmit(data);
    });
  };

  const ccUserIds = ccItems.map((ccItem) => ccItem.user.id);
  const handleCCUserChange = (values: number[]) => {
    const ccItems = values.map((value: number) => {
      const user = userStore.findById(value);
      return new EAFlowItem(user, 3);
    });
    setCCItems(ccItems);
  };

  const onChangeFlowPanel = (eaItems: EAFlowItem[], ccItems: EAFlowItem[]) => {
    setEAItems(eaItems);
    setCCItems(ccItems);
  };

  const submitButtonDisabled = eaItems.length === 0 || props.loading;

  const formItemLayout = {
    labelCol: { span: 3 },
    wrapperCol: { span: 20 },
  };

  return (
    <>
      <h2>{props.title}</h2>
      <Row gutter={12}>
        <Col className="gutter-row" span={18}>
          <Form className="hl-form" {...formItemLayout}>
            <Form.Item label="기안자">
              <InlineItem width="40%">
                <Input value={authStore.user.nameWithDeptRank} disabled />
              </InlineItem>
            </Form.Item>

            <Form.Item label="문서 제목">
              {getFieldDecorator('title', {
                rules: [
                  {
                    required: true,
                    message: '문서 제목을 입력해주세요.',
                  },
                  {
                    whitespace: true,
                    message: '문서 제목을 입력해주세요.',
                  },
                ],
                initialValue: docTitle || item.title,
              })(
                <Input
                  placeholder="문서 제목을 입력해주세요."
                  autoFocus
                  disabled={!!docTitle}
                />
              )}
            </Form.Item>

            <Form.Item label="참조">
              <MultiUserSelect
                values={ccUserIds}
                onChange={handleCCUserChange}
              />
            </Form.Item>

            <Form.Item label="수신부서">
              <InlineItem width="60%">
                <MultiDeptSelect
                  form={props.form}
                  name="ccdept_ids"
                  values={ccDeptIds}
                />
              </InlineItem>
              {!privateHidden && (
                <>
                  <InlineText>부서발신함 비공개: </InlineText>
                  <InlineItem>
                    {getFieldDecorator('is_private', {
                      valuePropName: 'checked',
                      initialValue: item.is_private,
                    })(<Switch />)}
                  </InlineItem>
                </>
              )}
            </Form.Item>

            <Form.Item label="문서 첨부">
              <FCEADocInput form={props.form} items={item.ccDocs} />
            </Form.Item>

            <Form.Item label="파일 첨부">
              <FCFileInput form={props.form} items={item.files} />
            </Form.Item>
          </Form>

          <div style={{ marginTop: '15px' }}>{props.children}</div>
        </Col>
        <Col className="gutter-row" span={6}>
          {item.id !== 0 && (
            <>
              <EADocEditorFlowPanel
                eaItems={eaItems}
                ccItems={ccItems}
                hideOpenButton
                onChange={onChangeFlowPanel}
                refTarget={item.refTarget}
              />

              <Row gutter={10} style={{ marginTop: '10px' }}>
                <Col>
                  <Button
                    block
                    type="primary"
                    htmlType="submit"
                    disabled={submitButtonDisabled}
                    onClick={onClickSubmitButton}
                  >
                    수정하기
                  </Button>
                </Col>
              </Row>
            </>
          )}
        </Col>
      </Row>
    </>
  );
};

const _EADocEditContainer = Form.create<Props>()(EADocEditContainer);
export default inject('authStore')(observer(withRouter(_EADocEditContainer)));
