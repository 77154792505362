import React, { useState, useEffect, useRef } from 'react';
import {
  Table,
  Button,
  Row,
  Col,
  Icon,
  Input,
  Form,
  Popconfirm,
  Modal,
  Tag
} from 'antd';
import LinkButton from '../../../components/general/LinkButton';
import { FormComponentProps } from 'antd/lib/form';
import { PaginationConfig } from 'antd/lib/table';
import Util from '../../../helpers/Util';
import AssetService from '../../../services/AssetService';
import { Asset, AssetUser } from '../../../models/Asset';
import AssetUserEdit from './AssetUserEdit';
import { User } from '../../../models/User';

interface Props extends FormComponentProps {
  asset: Asset;
  onChange?: () => void;
}

const AssetUserList: React.FC<Props> = props => {
  const { asset } = props;
  const [loading, setLoading] = useState(false);
  const [refreshTrigger, setRefreshTrigger] = useState(1);
  const [items, setItems] = useState<AssetUser[]>([]);
  const [currentUser, setCurrentUser] = useState<User | undefined>(asset.user);
  const [filteredItems, setFilteredItems] = useState<AssetUser[]>([]);
  const [keyword, setKeyword] = useState('');
  const { getFieldDecorator } = props.form;

  useEffect(() => {
    const fetchItems = async (assetId: number) => {
      setLoading(true);
      try {
        const result = await AssetService.fetchAssetUsers(assetId);
        const items = AssetUser.fromJsonArray(result.items);
        setItems(items);
      } catch (error) {
        Util.showError(error);
      } finally {
        setLoading(false);
      }
    };
    fetchItems(asset.id);
  }, [asset, refreshTrigger]);

  useEffect(() => {
    const filtered =
      keyword === ''
        ? items
        : items.filter(el => el.user.name.includes(keyword));
    setFilteredItems(filtered);
  }, [items, keyword]);

  const columns = [
    {
      title: '사번',
      width: 80,
      align: 'right' as 'right',
      dataIndex: 'user.id'
    },
    {
      title: '성명',
      width: 100,
      dataIndex: 'user.name'
    },
    {
      title: '부서',
      dataIndex: 'user.deptName'
    },
    {
      title: '직급',
      width: 100,
      dataIndex: 'user.rank'
    },
    {
      title: '시작일',
      dataIndex: 'begin_date',
      width: 90,
      render: (text: string, record: AssetUser) =>
        Util.formatDate(record.begin_date)
    },
    {
      title: '종료일',
      dataIndex: 'end_date',
      width: 90,
      render: (text: string, record: AssetUser) =>
        Util.formatDate(record.end_date)
    },
    {
      title: '사용중',
      dataIndex: 'using',
      width: 70,
      render: (text: string, record: AssetUser) => {
        if (currentUser && currentUser.id === record.user_id) {
          return <Tag color="blue">사용중</Tag>;
        }
      }
    },
    {
      title: '자산',
      dataIndex: 'assign',
      width: 74,
      render: (text: string, record: AssetUser) => {
        if (currentUser && currentUser.id === record.user_id) {
          return (
            <Button type="danger" onClick={e => onClickReturn(record)}>
              반환
            </Button>
          );
        } else {
          return <Button onClick={e => onClickAssign(record)}>사용</Button>;
        }
      }
    },
    {
      title: '비고',
      dataIndex: 'note'
    },
    {
      width: '80px',
      render: (text: string, record: AssetUser) => {
        return (
          <>
            <LinkButton onClick={e => onClickUpdateButton(record)}>
              수정
            </LinkButton>{' '}
            |{' '}
            <Popconfirm
              title="정말 삭제하시겠습니까?"
              onConfirm={e => onClickDeleteButton(record)}
            >
              <LinkButton>삭제</LinkButton>
            </Popconfirm>
          </>
        );
      }
    }
  ];

  const onChangeTable = (pagination: PaginationConfig) => {
    // setCurrentPage(pagination.current || 1);
  };

  const onSubmitSearchForm = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    props.form.validateFields((err, values) => {
      if (err) return;
      setKeyword(values.user_keyword || '');
    });
  };

  const itemRef = useRef<AssetUser | undefined>(undefined);
  const [editorVisible, setEditorVisible] = useState(false);

  const onClickAddButton = () => {
    itemRef.current = undefined;
    setEditorVisible(true);
  };

  const onClickUpdateButton = async (item: AssetUser) => {
    itemRef.current = item;
    setEditorVisible(true);
  };

  const onCloseEditor = () => {
    setEditorVisible(false);
  };

  const onSuccessEditing = () => {
    setRefreshTrigger(v => v + 1);
    setEditorVisible(false);
  };

  const onClickDeleteButton = async (item: AssetUser) => {
    try {
      await AssetService.deleteAssetUser(item.id);
      Util.showSuccess('삭제되었습니다.');
      setRefreshTrigger(v => v + 1);
    } catch (err) {
      Util.showError(err);
    }
  };

  const onClickAssign = async (item: AssetUser) => {
    try {
      await AssetService.assignAssetUser(asset.id, item.user_id);
      Util.showSuccess('처리되었습니다.');
      setCurrentUser(item.user);
      props.onChange && props.onChange();
    } catch (err) {
      Util.showError(err);
    }
  };

  const onClickReturn = async (item: AssetUser) => {
    try {
      await AssetService.assignAssetUser(asset.id, null);
      Util.showSuccess('처리되었습니다.');
      setCurrentUser(undefined);
      props.onChange && props.onChange();
    } catch (err) {
      Util.showError(err);
    }
  };

  return (
    <>
      <Form
        className="hl-form"
        layout="inline"
        style={{ marginBottom: 7 }}
        onSubmit={onSubmitSearchForm}
      >
        <Row type="flex" justify="space-between">
          <Col>
            <Form.Item>
              {getFieldDecorator('user_keyword')(
                <Input.Search
                  placeholder="검색어"
                  allowClear={true}
                  style={{ width: 150 }}
                />
              )}
            </Form.Item>

            <Form.Item>
              <Button type="primary" htmlType="submit">
                검색
              </Button>
            </Form.Item>
          </Col>

          <Col>
            <Button onClick={onClickAddButton}>
              <Icon type="plus" />
              사용자 등록
            </Button>
          </Col>
        </Row>
      </Form>

      <Table
        columns={columns}
        dataSource={filteredItems}
        pagination={false}
        onChange={onChangeTable}
        rowKey="id"
        loading={loading}
        bordered={true}
        size="middle"
      />

      <Modal
        title={itemRef.current ? '사용자 수정' : '사용자 등록'}
        width={800}
        centered
        visible={editorVisible}
        footer={null}
        onCancel={onCloseEditor}
        destroyOnClose={true}
      >
        <AssetUserEdit
          assetId={asset.id}
          item={itemRef.current}
          onSuccess={onSuccessEditing}
        />
      </Modal>
    </>
  );
};

export default Form.create<Props>()(AssetUserList);
