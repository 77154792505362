import React, { useState } from 'react';
import { Button, Form, Select, Input } from 'antd';
import { FormComponentProps } from 'antd/lib/form';
import Util from '../../../helpers/Util';
import FCFileInput from '../../../components/formcomps/FCFileInput';
import FIDatePicker from '../../../components/formcomps/FIDatePicker';
import { User, UserRNP } from '../../../models/User';
import UserService from '../../../services/UserService';

interface Props extends FormComponentProps {
  user: User;
  item?: UserRNP;
  onSuccess?: () => void;
}

const UserRNPEdit: React.FC<Props> = props => {
  const { user, item } = props;
  const [submitting, setSubmitting] = useState(false);
  const { getFieldDecorator } = props.form;

  const onSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    props.form.validateFields((err, values) => {
      if (err) return;
      processItem(values);
    });
  };

  const processItem = async (values: any) => {
    try {
      setSubmitting(true);

      values.date = (values.date && Util.formatDate(values.date)) || null;
      values.type = values.type || null;

      if (item) {
        await UserService.updateRNP(item.user_id, item.seq, values);
      } else {
        await UserService.createRNP(user.id, values);
      }

      Util.showSuccess('저장되었습니다.');
      if (props.onSuccess) props.onSuccess();
    } catch (err) {
      Util.showError(err);
      setSubmitting(false);
    }
  };

  return (
    <>
      <h2>상벌사항 {item ? '수정' : '등록'}</h2>

      <Form
        className="hl-form"
        labelCol={{ span: 4 }}
        wrapperCol={{ span: 18 }}
        onSubmit={onSubmit}
      >
        <FIDatePicker
          form={props.form}
          name="date"
          label="일자"
          allowClear
          initialValue={item && Util.formatDate(item.date)}
        />

        <Form.Item label="상벌구분">
          {getFieldDecorator('type', {
            initialValue: item && item.type
          })(
            <Select allowClear style={{ width: '150px' }}>
              <Select.Option value={1}>징계</Select.Option>
              <Select.Option value={2}>포상</Select.Option>
            </Select>
          )}
        </Form.Item>

        <Form.Item label="상벌내용">
          {getFieldDecorator('content', {
            initialValue: item && item.content
          })(<Input />)}
        </Form.Item>

        <Form.Item label="비고">
          {getFieldDecorator('note', {
            initialValue: item && item.note
          })(<Input />)}
        </Form.Item>

        <Form.Item label="관련자료">
          <FCFileInput form={props.form} items={item && item.files} />
        </Form.Item>

        <Form.Item wrapperCol={{ span: 12, offset: 4 }}>
          <Button type="primary" htmlType="submit" disabled={submitting}>
            저 장
          </Button>
        </Form.Item>
      </Form>
    </>
  );
};

export default Form.create<Props>()(UserRNPEdit);
