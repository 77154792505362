import React from 'react';
import Text from 'antd/lib/typography/Text';

interface Props {
  width?: string | number;
}

const InlineTextRight: React.FC<Props> = props => {
  return (
    <Text
      className="hl-form-inline-text"
      style={{
        display: 'inline-block',
        width: props.width || '100px',
        textAlign: 'right'
      }}
    >
      {props.children}
    </Text>
  );
};

export default InlineTextRight;
