import React, { useState } from 'react';
import { Button, Drawer } from 'antd';
import { Company, CompanyType } from '../../../models/Compnay';
import CompanyList from './CompanyList';

interface Props {
  type?: CompanyType;
  visible: boolean;
  onCancel: () => void;
  onOk: (selectedItems: Company[]) => void;
}

const CompanySelector: React.FC<Props> = props => {
  const { type = CompanyType.buyer } = props;
  const [selectedItems, setSelectedItems] = useState<Company[]>([]);

  const handleOK = () => {
    props.onOk(selectedItems);
  };

  const handleClose = () => {
    props.onCancel();
  };

  const onSelectItems = (items: Company[]) => {
    setSelectedItems(items);
  };

  console.count('CompanySelector');

  return (
    <Drawer
      closable={false}
      placement="right"
      width={1000}
      visible={props.visible}
      onClose={handleClose}
      destroyOnClose={true}
    >
      <CompanyList
        title={`${Company.typeName(type)} 선택`}
        type={type}
        onSelectItems={onSelectItems}
      />
      <div
        style={{
          position: 'absolute',
          left: 0,
          bottom: 0,
          width: '100%',
          borderTop: '1px solid #e9e9e9',
          padding: '10px 16px',
          background: '#fff',
          textAlign: 'right'
        }}
      >
        <Button onClick={handleClose} style={{ marginRight: 8 }}>
          취소
        </Button>
        <Button onClick={handleOK} type="primary">
          선택
        </Button>
      </div>
    </Drawer>
  );
};

export default CompanySelector;
