import React from 'react';
import { Tree } from 'antd';
import { MenuItem } from '../../models/Common';
const { TreeNode } = Tree;

interface Props {
  items: MenuItem[];
  onSelectItem?: (key: string) => void;
}

const TreeView: React.FC<Props> = props => {
  const { items } = props;

  console.count('TreeView');

  const onSelectTreeNode = (selectedKeys: string[]) => {
    props.onSelectItem && props.onSelectItem(selectedKeys[0]);
  };

  const renderMenuItem = (item: MenuItem) => {
    return (
      <TreeNode title={item.title} key={item.id}>
        {item.children && item.children.map(child => renderMenuItem(child))}
      </TreeNode>
    );
  };

  return (
    <div className="hl-form" style={{ backgroundColor: 'white' }}>
      <Tree showLine defaultExpandAll onSelect={onSelectTreeNode}>
        {items.map(item => renderMenuItem(item))}
      </Tree>
    </div>
  );
};

export default TreeView;
