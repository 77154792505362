import React, { useState, useEffect } from 'react';
import Form, { FormComponentProps } from 'antd/lib/form';
import { Button, Input } from 'antd';
import { ContractItem } from '../../../models/Order';
import FCTextInput from '../../../components/formcomps/FCTextInput';
import Util from '../../../helpers/Util';
import OrderService from '../../../services/OrderService';
import FIDatePicker from '../../../components/formcomps/FIDatePicker';

interface Props extends FormComponentProps {
  companyName: string;
  contractItem: ContractItem;
  out?: boolean;
  onSuccess?: () => void;
}

const ContractItemEditor: React.FC<Props> = props => {
  const { companyName, contractItem, out = false } = props;
  const { getFieldDecorator, setFieldsValue } = props.form;

  console.count('ContractItemEditor');

  useEffect(() => {
    setFieldsValue({
      contract_id: contractItem.contract_id,
      term: contractItem.term
    });
  }, [contractItem, setFieldsValue]);

  const onSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    props.form.validateFields((err, values) => {
      if (err) return;
      Util.nullifyUndifined(values);
      processItem(values);
    });
  };

  const [submitting, setSubmitting] = useState(false);

  const processItem = async (values: any) => {
    try {
      setSubmitting(true);
      await OrderService.updateContractItem(
        contractItem.contract_id,
        contractItem.seq,
        {
          est_date: values.est_date,
          req_date: values.req_date
        }
      );
      Util.showSuccess('저장되었습니다.');
      if (props.onSuccess) props.onSuccess();
    } catch (err) {
      Util.showError(err);
    } finally {
      setSubmitting(false);
    }
  };

  const formItemLayout = {
    labelCol: { span: 4 },
    wrapperCol: { span: 19 }
  };

  return (
    <>
      <Form {...formItemLayout} onSubmit={onSubmit}>
        <div className="hl-form" style={{ marginBottom: '10px' }}>
          {getFieldDecorator('contract_id')(<Input type="hidden" />)}

          <Form.Item label="업체명">
            <Input value={companyName} disabled />
          </Form.Item>
          <Form.Item label="구분">
            <FCTextInput form={props.form} name="term" disabled />
          </Form.Item>

          <Form.Item label={out ? '지급 예정일' : '수금 예정일'}>
            <FIDatePicker
              form={props.form}
              name="est_date"
              allowClear
              initialValue={
                contractItem.est_date && Util.formatDate(contractItem.est_date)
              }
            />
          </Form.Item>

          <Form.Item label="청구일">
            <FIDatePicker
              form={props.form}
              name="req_date"
              allowClear
              initialValue={
                contractItem.req_date && Util.formatDate(contractItem.req_date)
              }
            />{' '}
          </Form.Item>

          <Form.Item wrapperCol={{ span: 12, offset: 4 }}>
            <Button type="primary" htmlType="submit" disabled={submitting}>
              저 장
            </Button>
          </Form.Item>
        </div>
      </Form>
    </>
  );
};

export default Form.create<Props>()(ContractItemEditor);
