import React, { useState, useEffect } from 'react';
import { Form, Button, Icon, Upload } from 'antd';
import TextArea from 'antd/lib/input/TextArea';
import Util from '../../helpers/Util';
import VacationService from '../../services/VacationService';
import Text from 'antd/lib/typography/Text';

interface Props {
  onSuccess?: () => void;
}

const VacationBatchUpdateForm: React.FC<Props> = props => {
  const [content, setContent] = useState('');
  const [submitting, setSubmitting] = useState(false);
  const [formValidated, setFormValidated] = useState(false);

  useEffect(() => {
    setFormValidated(content.trim().length > 0);
  }, [content]);

  const handleTextChange = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
    const value = e.target.value;
    setContent(value);
  };

  const onClickSubmit = async () => {
    try {
      setSubmitting(true);
      await VacationService.batchUpdate(content.trim());
      setContent('');
      setSubmitting(false);
      if (props.onSuccess) {
        props.onSuccess();
      }
    } catch (err) {
      Util.showError(err);
      setSubmitting(false);
    }
  };

  const uploadProps = {
    accept: '.csv',
    beforeUpload: (file: any) => {
      const reader = new FileReader();
      reader.readAsText(file);
      reader.onload = () => {
        if (reader.result) {
          setContent(String(reader.result));
        }
      };
      return false;
    }
  };

  return (
    <>
      <Upload {...uploadProps}>
        <Button>
          <Icon type="upload" /> CSV 파일 업로드...
        </Button>
      </Upload>

      <ul style={{ marginTop: 10 }}>
        <li>
          입력시 <Text code>사번,년도,발생연차</Text> 형식으로 입력해주세요.
        </li>
        <li>
          <Text code>사번,년도</Text>가 이미 존재할 경우 새로 입력된{' '}
          <Text code>발생연차</Text> 값으로 업데이트 됩니다.
        </li>
      </ul>

      <Form.Item>
        <TextArea
          rows={15}
          onChange={handleTextChange}
          value={content}
          autoFocus={true}
        />
      </Form.Item>
      <Form.Item>
        <Button
          htmlType="submit"
          disabled={!formValidated}
          loading={submitting}
          onClick={onClickSubmit}
          type="primary"
        >
          등록
        </Button>
      </Form.Item>
    </>
  );
};

export default VacationBatchUpdateForm;
