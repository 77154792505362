import React, { useState, useEffect } from 'react';
import { Order } from '../../../models/Order';
import Util from '../../../helpers/Util';
import OrderService from '../../../services/OrderService';
import {
  HDescriptions,
  HDRow,
  HDItem,
  HDRowItem
} from '../../../components/general/HDescriptions';
import MapView from '../../../components/general/MapView';

interface CompProps {
  item: Order;
  name: string;
}

const OrderCompanyPanel: React.FC<CompProps> = props => {
  const { item, name } = props;
  return (
    <HDescriptions>
      <HDRowItem label="회사명" colSpan={3}>
        {item[`${name}_name`]}
      </HDRowItem>
      <HDRow>
        <HDItem label="이름">{item[`${name}_owner`]}</HDItem>
        <HDItem label="연락처">{item[`${name}_phone`]}</HDItem>
      </HDRow>
      <HDRow>
        <HDItem label="직급">{item[`${name}_rank`]}</HDItem>
        <HDItem label="성향 (취미등)">{item[`${name}_nature`]}</HDItem>
      </HDRow>
      <HDRow>
        <HDItem label="소개 마케팅">{item[`${name}_note`]}</HDItem>
        <HDItem label="당사관계이력"> {item[`${name}_relation`]}</HDItem>
      </HDRow>
    </HDescriptions>
  );
};

interface Props {
  data?: any;
  id?: number;
  refreshTrigger?: number;
}

const OrderViewer: React.FC<Props> = props => {
  const { id, data, refreshTrigger } = props;
  const [, setLoading] = useState(false);
  const [item, setItem] = useState<Order>(new Order());

  useEffect(() => {
    const fetchDetail = async (id: number) => {
      setLoading(true);
      try {
        const result = await OrderService.fetchItem(id);
        configDetail(result.order);
      } catch (error) {
        Util.showError(error);
      } finally {
        setLoading(false);
      }
    };

    const configDetail = (data: any) => {
      setItem(Order.fromJson(data));
    };

    if (data) {
      configDetail(data);
    } else if (id) {
      fetchDetail(id);
    }
  }, [id, data, refreshTrigger]);

  console.count('OrderViewer');

  return (
    <>
      <h3>접수</h3>
      <HDescriptions>
        <HDRowItem label="계약건명" colSpan={5}>
          {item.name}
        </HDRowItem>
        <HDRow>
          <HDItem label="프로젝트 코드">{item.project.id}</HDItem>
          <HDItem label="법인" small>
            {item.corp && item.corp.name}
          </HDItem>
          <HDItem label="부서" small>
            {item.dept && item.dept.name}
          </HDItem>
        </HDRow>
        <HDRow>
          <HDItem label="접수날짜">
            {Util.formatDate(item.register_date)}
          </HDItem>
          <HDItem label="종류" small>
            {item.kind}
          </HDItem>
          <HDItem label="등급" small>
            {item.grade}
          </HDItem>
        </HDRow>
        <HDRow>
          <HDItem label="팀장">
            {item.leaders.map(e => e.name).join(', ')}
          </HDItem>
          <HDItem label="담당" small colSpan={3}>
            {item.managers.map(e => e.name).join(', ')}
          </HDItem>
        </HDRow>
        <HDRow>
          <HDItem label="협업" colSpan={5}>
            {item.collabs.map(e => e.name).join(', ')}
          </HDItem>
        </HDRow>
      </HDescriptions>

      <h3>대지정보</h3>
      <HDescriptions>
        <HDRowItem label="위치" colSpan={5}>
          {item.project.address}
        </HDRowItem>
        <HDRowItem label=" " colSpan={5}>
          <MapView address={item.project.address} />
        </HDRowItem>
        <HDRow>
          <HDItem label="대지면적">
            {Util.formatNumber(item.project.site_area)} ㎡
          </HDItem>
          <HDItem label="연면적">
            {Util.formatNumber(item.project.total_floor_area)} ㎡
          </HDItem>
          <HDItem label="층수">{item.project.level_info}</HDItem>
        </HDRow>
        <HDRow>
          <HDItem label="토지상태">{item.site_state}</HDItem>
          <HDItem label="토지가격">{Util.formatNumber(item.price)} 원</HDItem>
        </HDRow>
        <HDRowItem label="지역" colSpan={5}>
          {item.region.join(', ')}
        </HDRowItem>
        <HDRowItem label="지구" colSpan={5}>
          {item.districts.join(', ')}
        </HDRowItem>
        <HDRowItem label="구역" colSpan={5}>
          {item.areas.join(', ')}
        </HDRowItem>
        <HDRowItem label="용도" colSpan={5}>
          {item.f_use}
        </HDRowItem>
      </HDescriptions>

      <h3>사업주</h3>
      <OrderCompanyPanel item={item} name="comp" />

      <h3>의뢰처</h3>
      <OrderCompanyPanel item={item} name="client" />

      <h3>사업구도</h3>
      <HDescriptions>
        <HDRow>
          <HDItem label="종류">{item.type}</HDItem>
          <HDItem label="경과">{item.progress}</HDItem>
        </HDRow>
        <HDRow>
          <HDItem label="시공사">{item.constructor_name}</HDItem>
          <HDItem label="공사비(예정)">
            {Util.formatNumber(item.est_cost)}
          </HDItem>
        </HDRow>
        <HDRow>
          <HDItem label="금융사">{item.finance_comp}</HDItem>
          <HDItem label="금융관계(절차)">{item.finance_state}</HDItem>
        </HDRow>
      </HDescriptions>
      <h3>기타</h3>
      <HDescriptions>
        <HDRow>
          <HDItem label="계획안제출예정일">
            {Util.formatDate(item.plan_est_date)}
          </HDItem>
          <HDItem label="계획안제출일">
            {Util.formatDate(item.plan_date)}
          </HDItem>
        </HDRow>
        <HDRowItem label="검토 및 요구사항" colSpan={3}>
          <div
            dangerouslySetInnerHTML={{ __html: Util.stringToHTML(item.note) }}
          />
        </HDRowItem>
      </HDescriptions>
    </>
  );
};

export default OrderViewer;
