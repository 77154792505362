import React from 'react';
import { Link, withRouter } from 'react-router-dom';
import { Layout, Menu, Icon, PageHeader } from 'antd';
const { SubMenu } = Menu;
const { Sider } = Layout;

interface MenuItem {
  id: string;
  title: string;
  icon?: string;
  link?: string;
  children?: MenuItem[];
}

//
// NOTE: link가 없을 경우 문서함으로 간주 & menu.id => archiveGroupId로 간주
//
const menuItems: MenuItem[] = [
  {
    id: '101',
    title: 'Layout Test 1',
    icon: 'desktop',
    link: '/test/test1'
  },
  {
    id: '102',
    title: 'Layout Test 2',
    icon: 'desktop',
    link: '/test/test2'
  },
  {
    id: '103',
    title: 'Process Update',
    icon: 'desktop',
    link: '/test/process-update'
  },
  {
    id: '1',
    title: '사업일반',
    icon: 'desktop',
    children: [
      { id: '2001', title: '보고관리' },
      { id: '2002', title: '회의관리' }
    ]
  },
  {
    id: '2',
    title: '계약기성',
    icon: 'desktop',
    children: [{ id: '2003', title: '계약기성자료' }]
  },
  {
    id: '3',
    title: '설계관리',
    icon: 'desktop',
    children: [{ id: '2004', title: '설계도면,도서' }]
  },
  {
    id: '4',
    title: '시공,사업관리',
    icon: 'desktop',
    children: [{ id: '2005', title: '문서함' }]
  },
  {
    id: '5',
    title: '준공유지관리',
    icon: 'desktop',
    children: [{ id: '2006', title: '문서함' }]
  },
  {
    id: '6',
    title: '문서관리',
    icon: 'desktop',
    children: [
      { id: '2007', title: '접수문서' },
      { id: '2008', title: '발송문서' }
    ]
  }
];

const Sidebar = withRouter(({ location, history }) => {
  const params = new URLSearchParams(location.search);
  const archiveGroupId = params.get('id') || '';
  let defaultMenuItem: MenuItem | undefined;
  if (archiveGroupId) {
    defaultMenuItem = menuItems.find(item => {
      if (item.children) {
        return (
          item.children.find(child => child.id === archiveGroupId) !== undefined
        );
      }
      return false;
    });
  }

  const renderMenuItem = (item: MenuItem) => {
    if (item.children) {
      return (
        <SubMenu
          key={item.id}
          title={
            <span>
              {item.icon && <Icon type={item.icon} />}
              <span>{item.title}</span>
            </span>
          }
        >
          {item.children.map((menu: any) => renderMenuItem(menu))}
        </SubMenu>
      );
    } else {
      return (
        <Menu.Item key={item.id}>
          <Link to={item.link || `/test/archive?id=${item.id}`}>
            {item.icon && <Icon type={item.icon} />}
            <span>{item.title}</span>
          </Link>
        </Menu.Item>
      );
    }
  };

  return (
    <Sider
      id="sider"
      width={200}
      style={{
        background: '#fff',
        overflowX: 'hidden',
        overflowY: 'auto',
        // height: "100vh",
        position: 'fixed',
        left: 0
      }}
    >
      <PageHeader title="Title" subTitle="Subtitle" />
      <Menu
        mode="inline"
        defaultOpenKeys={[defaultMenuItem ? defaultMenuItem.id : '']}
        defaultSelectedKeys={[archiveGroupId]}
        style={{ borderRight: 0 }}
      >
        {menuItems.map(menu => renderMenuItem(menu))}
      </Menu>
    </Sider>
  );
});

export default Sidebar;
