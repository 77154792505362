import React, { useState, useEffect } from 'react';
import { Input, Button, Form, DatePicker, Select } from 'antd';
import { FormComponentProps } from 'antd/lib/form';
import Util from '../../helpers/Util';
import FCNumberInput from '../../components/formcomps/FCNumberInput';
import InlineItem from '../../components/formcomps/InlineItem';
import FCTextInput from '../../components/formcomps/FCTextInput';
import _ from 'lodash';
import FCFileInput from '../../components/formcomps/FCFileInput';
import EducationService from '../../services/EducationService';
import { Education } from '../../models/Education';

interface Props extends FormComponentProps {
  id?: number;
  onSuccess?: () => void;
}

const EducationEdit: React.FC<Props> = props => {
  const itemId = props.id || 0;
  const [, setLoading] = useState(true);
  const [item, setItem] = useState<Education>(new Education());
  const { getFieldDecorator } = props.form;

  useEffect(() => {
    const fetchItem = async (id: number) => {
      setLoading(true);
      try {
        const result = await EducationService.fetchItem(id);
        const education = Education.fromJson(result.education);
        setItem(education);
      } catch (error) {
        Util.showError(error);
      } finally {
        setLoading(false);
      }
    };

    if (itemId > 0) {
      fetchItem(itemId);
    }
  }, [itemId]);

  const [submitting, setSubmitting] = useState(false);

  const onSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    props.form.validateFields((err, values) => {
      if (err) return;
      processItem(_.omit(values, ['survey_id', 'survey_title']));
    });
  };

  const processItem = async (values: any) => {
    try {
      // console.log(values);
      setSubmitting(true);

      itemId === 0
        ? await EducationService.create(values)
        : await EducationService.update(itemId, values);
      Util.showSuccess('저장되었습니다.');

      if (props.onSuccess) props.onSuccess();
    } catch (err) {
      Util.showError(err);
      setSubmitting(false);
    }
  };

  const onClickSearchPoll = () => {};

  return (
    <>
      <h2>교육 {itemId > 0 ? '수정' : '등록'}</h2>

      <Form
        className="hl-form"
        labelCol={{ span: 4 }}
        wrapperCol={{ span: 18 }}
        onSubmit={onSubmit}
      >
        <Form.Item label="교육명">
          <InlineItem width="70%">
            {getFieldDecorator('title', {
              initialValue: item.title,
              rules: [
                {
                  required: true,
                  message: '교육명을 입력해주세요.'
                }
              ]
            })(<Input />)}
          </InlineItem>
        </Form.Item>

        <Form.Item label="교육일시">
          <Form.Item style={{ display: 'inline-block' }}>
            {getFieldDecorator('date', {
              initialValue: item.date,
              rules: [
                { required: true, message: '교육일시를 입력해주세요' },
                { type: 'object' }
              ]
            })(
              <DatePicker
                format="YYYY-MM-DD HH:mm"
                showTime={{
                  format: 'HH:mm'
                }}
              />
            )}
          </Form.Item>
        </Form.Item>

        <Form.Item label="분야구분">
          <InlineItem width="30%">
            {getFieldDecorator('category', { initialValue: item.category })(
              <Select>
                {['전사', '경영', '설계', 'CS', 'CM', '기타'].map(el => {
                  return (
                    <Select.Option key={el} value={el}>
                      {el}
                    </Select.Option>
                  );
                })}
              </Select>
            )}
          </InlineItem>
        </Form.Item>

        <Form.Item label="강사명">
          <InlineItem width="30%">
            <FCTextInput
              form={props.form}
              name="instructor"
              options={{ initialValue: item.instructor }}
            />{' '}
          </InlineItem>
        </Form.Item>

        <Form.Item label="교육개요">
          <FCTextInput
            form={props.form}
            name="summary"
            options={{ initialValue: item.summary }}
          />
        </Form.Item>

        <Form.Item label="교육인원">
          <FCNumberInput
            form={props.form}
            name="seat_count"
            options={{ initialValue: item.seat_count }}
          />
        </Form.Item>

        <Form.Item label="교육자료">
          <FCFileInput form={props.form} items={item.files} />
        </Form.Item>

        {getFieldDecorator('survey_id', { initialValue: item.survey_id })(
          <Input type="hidden" />
        )}
        <Form.Item label="설문조사">
          <InlineItem width="70%">
            {getFieldDecorator('survey_title', {
              initialValue: item.survey_title
            })(<Input readOnly disabled />)}
          </InlineItem>{' '}
          <Button onClick={onClickSearchPoll} disabled>
            설문조사 검색...
          </Button>
        </Form.Item>

        <Form.Item wrapperCol={{ span: 12, offset: 4 }}>
          <Button type="primary" htmlType="submit" disabled={submitting}>
            저 장
          </Button>
        </Form.Item>
      </Form>
    </>
  );
};

export default Form.create<Props>()(EducationEdit);
