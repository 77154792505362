import React, { useState, useEffect } from 'react';
import { withRouter, RouteComponentProps } from 'react-router';
import { Table, Row, Col, Input, Button, Icon, Drawer } from 'antd';
import { Project } from '../../models/Project';
import ProjectService from '../../services/ProjectService';
import Util from '../../helpers/Util';
import { PaginationConfig } from 'antd/lib/table';
import ProjectEdit from './ProjectEdit';
import LinkButton from '../../components/general/LinkButton';

interface Props extends RouteComponentProps {
  title?: string;
  useDrawer?: boolean;
  onSelectItems?: (items: Project[]) => void;
}

const ProjectList: React.FC<Props> = props => {
  const { title = '프로젝트 관리', onSelectItems } = props;
  const [items, setItems] = useState<Project[]>([]);
  const [keyword, setKeyword] = useState<string>('');
  const [totalCount, setTotalCount] = useState(0);
  const [currentPage, setCurrentPage] = useState(1);
  const [loading, setLoading] = useState(false);
  const [refreshTrigger, setRefreshTrigger] = useState(0);

  const pagination = {
    pageSize: 15,
    current: currentPage,
    total: totalCount,
    showTotal: (total: number, range: [number, number]) =>
      `전체 ${total}건 중 ${range[0]}-${range[1]}`
  };

  useEffect(() => {
    const fetchItems = async () => {
      setLoading(true);
      try {
        const result = await ProjectService.fetchItems(
          pagination.pageSize,
          currentPage,
          keyword
        );
        setTotalCount(result.total_count);
        setItems(Project.fromJsonArray(result.items));
      } catch (error) {
        Util.showError(error);
      } finally {
        setLoading(false);
      }
    };

    fetchItems();
  }, [refreshTrigger, currentPage, keyword, pagination.pageSize]);

  const columns = [
    {
      title: '코드',
      dataIndex: 'id',
      align: 'right' as 'right'
    },
    {
      title: '연도',
      dataIndex: 'year'
    },
    {
      title: '프로젝트명',
      dataIndex: 'name',
      render: (text: string, record: Project) => (
        <LinkButton onClick={e => onClickItem(record)}>
          {record.name}
        </LinkButton>
      )
    },
    {
      title: '부서',
      dataIndex: 'dept.name'
    },
    {
      title: '종류',
      dataIndex: 'kind'
    }
  ];

  const [editorVisible, setEditorVisible] = useState(false);
  const [editingItemId, setEditingItemId] = useState(0);

  const onClickItem = (item: Project) => {
    if (props.useDrawer) {
      setEditingItemId(item.id);
      setEditorVisible(true);
    } else {
      props.history.push(`/projects/edit?id=${item.id}`);
    }
  };

  const onClickAddButton = () => {
    if (props.useDrawer) {
      setEditingItemId(0);
      setEditorVisible(true);
    } else {
      props.history.push('/projects/edit');
    }
  };

  const onCloseEditor = () => {
    setEditorVisible(false);
  };

  const onCreateProject = () => {
    setEditorVisible(false);
    setTimeout(() => {
      setRefreshTrigger(Date.now());
    }, 200);
  };

  const onSearch = (keyword: string) => {
    setKeyword(keyword);
    setCurrentPage(1);
  };

  const onChangeTable = (pagination: PaginationConfig) => {
    setCurrentPage(pagination.current || 1);
  };

  let rowSelection: any = undefined;
  if (onSelectItems) {
    rowSelection = {
      onChange: (selectedRowKeys: any, selectedRows: any) => {
        onSelectItems(selectedRows as Project[]);
      }
    };
  }

  return (
    <>
      <h2>{title}</h2>

      <Row type="flex" justify="space-between" style={{ marginBottom: 10 }}>
        <Col>
          <Input.Search
            placeholder="검색어"
            allowClear={true}
            onSearch={onSearch}
            style={{ width: 200 }}
          />
        </Col>
        <Col>
          <Button onClick={onClickAddButton}>
            <Icon type="plus" />
            프로젝트 등록
          </Button>
        </Col>
      </Row>

      <Table
        columns={columns}
        dataSource={items}
        rowKey="id"
        pagination={pagination}
        onChange={onChangeTable}
        bordered={true}
        loading={loading}
        size="middle"
        rowSelection={rowSelection}
      />

      <Drawer
        closable={false}
        placement="right"
        width={1000}
        visible={editorVisible}
        onClose={onCloseEditor}
        destroyOnClose={true}
      >
        <ProjectEdit onSuccess={onCreateProject} id={editingItemId} />
      </Drawer>
    </>
  );
};

export default withRouter(ProjectList);
