import React, { useState } from 'react';
import { Button, Input, Form, Select } from 'antd';
import Util from '../../helpers/Util';
import { FormComponentProps } from 'antd/lib/form';
import { Archive, File } from '../../models/File';
import FileService from '../../services/FileService';
import ArchiveFileGroup from './ArchiveFileGroup';
const { Option } = Select;

interface Props extends FormComponentProps {
  archives: Archive[];
  orderId?: number;
  file: File;
  onSuccess?: () => void;
  onChange?: () => void;
}

const ArchiveFileGroupEditor: React.FC<Props> = props => {
  const { archives, orderId, file } = props;
  const { getFieldDecorator } = props.form;
  const [submitting, setSubmitting] = useState(false);

  const onClickSubmitButton = () => {
    props.form.validateFields((err, values) => {
      if (err) return;
      processItem(values);
    });
  };

  const processItem = async (values: any) => {
    try {
      setSubmitting(true);
      await FileService.updateFileGroup(
        file.group_id,
        file.archive_id,
        orderId,
        values.archive_id,
        values.tags
      );
      Util.showSuccess('수정되었습니다.');
      props.onSuccess && props.onSuccess();
    } catch (err) {
      Util.showError(err);
    } finally {
      setSubmitting(false);
    }
  };

  const onChangeFileGroup = () => {
    props.onChange && props.onChange();
  };

  const formItemLayout = {
    labelCol: { span: 4 },
    wrapperCol: { span: 16 }
  };

  return (
    <>
      <Form {...formItemLayout}>
        {getFieldDecorator('order_id', {
          initialValue: orderId
        })(<Input type="hidden" />)}
        <Form.Item label="구분">
          {getFieldDecorator('archive_id', {
            rules: [
              {
                required: true,
                message: '구분을 선택해주세요.'
              }
            ],
            initialValue: file.archive_id
          })(
            <Select>
              {archives.map(archive => (
                <Option key={archive.id} value={archive.id}>
                  {archive.name}
                </Option>
              ))}
            </Select>
          )}
        </Form.Item>

        <Form.Item label="태그">
          {getFieldDecorator('tags', { initialValue: file.tags })(
            <Select mode="tags" allowClear></Select>
          )}
        </Form.Item>

        <Form.Item wrapperCol={{ span: 12, offset: 4 }}>
          <Button
            type="primary"
            disabled={submitting}
            onClick={onClickSubmitButton}
          >
            수 정
          </Button>
        </Form.Item>
      </Form>

      <ArchiveFileGroup file={file} onChange={onChangeFileGroup} />
    </>
  );
};

export default Form.create<Props>()(ArchiveFileGroupEditor);
