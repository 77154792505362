import React, { useEffect, useState } from 'react';
import { Form, Input, Button, Select, Modal } from 'antd';
import { FormComponentProps } from 'antd/lib/form';
import { User } from '../../../models/User';
import RankSelector from '../../general/RankSelect';
import DeptSelect from '../../general/DeptSelect';
import { observer, inject } from 'mobx-react';
import moment, { Moment } from 'moment';
import { UserStore } from '../../../stores/userStore';
import UserService from '../../../services/UserService';
import Util from '../../../helpers/Util';
import InlineText from '../../formcomps/InlineText';
import InlineItem from '../../formcomps/InlineItem';
import UserPasswordReset from '../UserPasswordReset';
import FICorpSelect from '../../formcomps/FICorpSelect';
import FIDatePicker from '../../formcomps/FIDatePicker';
import FISingleImageInput from '../../formcomps/FISingleImageInput';
const { Option } = Select;

interface Props extends FormComponentProps {
  userStore?: UserStore;
  user: User;
}

const UserDefaultForm = (props: Props) => {
  const userStore = props.userStore!;
  const { getFieldDecorator } = props.form;
  const { user } = props;

  const [userDetail, setUserDetail] = useState<any>({});
  const [westernAge, setWesternAge] = useState<number | undefined>(undefined);

  useEffect(() => {
    //setLoading(true);
    UserService.fetchDetail(user.id)
      .then(result => {
        const userDetail = result.user;
        userDetail.birth_date = userDetail.birth_date
          ? moment(userDetail.birth_date)
          : undefined;
        userDetail.entry_date = userDetail.entry_date
          ? moment(userDetail.entry_date)
          : undefined;
        userDetail.retire_date = userDetail.retire_date
          ? moment(userDetail.retire_date)
          : undefined;
        userDetail.retire_reckon_date = userDetail.retire_reckon_date
          ? moment(userDetail.retire_reckon_date)
          : undefined;

        updateWesternAge(userDetail.birth_date);
        setUserDetail(userDetail);
        //setLoading(false);
      })
      .catch(error => Util.showError(error));
  }, [user]);

  const updateWesternAge = (birth_date: Moment | undefined) => {
    if (birth_date === undefined) {
      setWesternAge(undefined);
      return;
    }

    // 올해년도 - 태어난년도 => 생일이 지나지 않은 경우 -1
    let age = moment().year() - birth_date.year();
    const now = moment();
    const a = moment({ month: now.month(), date: now.date() });
    const b = moment({ month: birth_date.month(), date: birth_date.date() });
    if (a.diff(b, 'days') > 0 && age > 0) {
      age -= 1;
    }
    setWesternAge(age);
  };

  const handleBirthDateChange = (value: any) => {
    updateWesternAge(value);
  };

  const [submitting, setSubmitting] = useState(false);

  const handleSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    props.form.validateFields((err, values) => {
      if (err) return;
      updateItem(values, user);
    });
  };

  const updateItem = async (values: any, item: User) => {
    try {
      setSubmitting(true);
      // console.log(values);

      values.gender = values.gender || null;
      values.birth_date = values.birth_date || null;
      values.corp_id = values.corp_id || null;
      values.dept_id = values.dept_id || null;
      values.rank = values.rank || null;
      values.entry_date = values.entry_date || null;
      values.retire_date = values.retire_date || null;
      values.retire_reckon_date = values.retire_reckon_date || null;

      await userStore.updateUser(item, values);
      Util.showSuccess('업데이트 되었습니다.');
    } catch (err) {
      Util.showError(err);
    } finally {
      setSubmitting(false);
    }
  };

  const [passwordResetVisible, setPasswordResetVisible] = useState(false);

  const onClickResetPassword = () => {
    setPasswordResetVisible(true);
  };

  return (
    <>
      <Form
        className="hl-form"
        labelCol={{ span: 3 }}
        wrapperCol={{ span: 18 }}
        onSubmit={handleSubmit}
      >
        <Form.Item label="계정 이메일주소">
          {getFieldDecorator('email', {
            rules: [
              {
                required: true,
                message: '이메일을 입력해주세요.'
              }
            ],
            initialValue: user.email
          })(<Input style={{ width: '40%' }} />)}{' '}
          <Button onClick={onClickResetPassword}>비밀번호 변경...</Button>
        </Form.Item>

        <Form.Item label="성명 국문">
          {getFieldDecorator('name', {
            rules: [
              {
                required: true,
                message: '이름을 입력해주세요.'
              }
            ],
            initialValue: user.name
          })(<Input style={{ width: '40%' }} />)}
        </Form.Item>

        <Form.Item label="한문">
          {getFieldDecorator('name_zh', {
            initialValue: userDetail.name_zh
          })(<Input style={{ width: '40%' }} />)}
        </Form.Item>

        <Form.Item label="영문">
          {getFieldDecorator('name_en', {
            initialValue: userDetail.name_en
          })(<Input style={{ width: '40%' }} />)}
        </Form.Item>

        <FISingleImageInput
          form={props.form}
          label="사진"
          name="avatar_id"
          item={user.avatar}
        />

        <Form.Item label="연락처">
          {getFieldDecorator('phone', {
            initialValue: userDetail.phone
          })(<Input style={{ width: '40%' }} />)}
        </Form.Item>

        <Form.Item label="성별">
          {getFieldDecorator('gender', {
            initialValue: userDetail.gender || undefined
          })(
            <Select
              placeholder="성별"
              allowClear={true}
              style={{ width: '40%' }}
            >
              <Option value={1}>남자</Option>
              <Option value={2}>여자</Option>
            </Select>
          )}
        </Form.Item>

        <Form.Item label="생년월일">
          <InlineItem>
            <FIDatePicker
              form={props.form}
              name="birth_date"
              allowClear
              initialValue={
                userDetail.birth_date && Util.formatDate(userDetail.birth_date)
              }
              onChange={handleBirthDateChange}
            />
          </InlineItem>
          <InlineText>만 {westernAge}세</InlineText>
        </Form.Item>

        <Form.Item label="주민등록번호">
          <Form.Item style={{ display: 'inline-block', width: '120px' }}>
            {getFieldDecorator('regno1', {
              initialValue: userDetail.regno1
            })(<Input maxLength={6} />)}
          </Form.Item>
          <span
            style={{
              display: 'inline-block',
              width: '24px',
              textAlign: 'center'
            }}
          >
            -
          </span>
          <Form.Item style={{ display: 'inline-block', width: '120px' }}>
            {getFieldDecorator('regno2', {
              initialValue: userDetail.regno2
            })(<Input maxLength={7} />)}
          </Form.Item>
        </Form.Item>

        <FICorpSelect
          form={props.form}
          label="소속회사"
          width="40%"
          allowClear
          options={{ initialValue: userDetail.corp_id }}
        />

        <Form.Item label="부서 / 직급">
          <InlineItem width="40%">
            <DeptSelect
              form={props.form}
              maxDepth={0}
              allowClear={true}
              value={userDetail.dept_id}
            />
          </InlineItem>{' '}
          <InlineItem width="30%">
            <RankSelector form={props.form} value={userDetail.rank} />
          </InlineItem>
        </Form.Item>

        <FIDatePicker
          form={props.form}
          label="입사일"
          allowClear
          name="entry_date"
          initialValue={
            userDetail.entry_date && Util.formatDate(userDetail.entry_date)
          }
        />

        <FIDatePicker
          form={props.form}
          label="퇴사일"
          allowClear
          name="retire_date"
          initialValue={
            userDetail.retire_date && Util.formatDate(userDetail.retire_date)
          }
        />

        <FIDatePicker
          form={props.form}
          label="퇴직금 기산일"
          allowClear
          name="retire_reckon_date"
          initialValue={
            userDetail.retire_reckon_date &&
            Util.formatDate(userDetail.retire_reckon_date)
          }
        />

        <Form.Item label="직종">
          {getFieldDecorator('profession', {
            initialValue: userDetail.profession
          })(<Input style={{ width: '40%' }} />)}
        </Form.Item>

        <Form.Item label="출생지">
          {getFieldDecorator('birth_place', {
            initialValue: userDetail.birth_place
          })(<Input />)}
        </Form.Item>

        <Form.Item label="거주지">
          {getFieldDecorator('address', {
            initialValue: userDetail.address
          })(<Input />)}
        </Form.Item>

        <Form.Item label="유학생활">
          {getFieldDecorator('study_abroad', {
            initialValue: userDetail.study_abroad
          })(<Input />)}
        </Form.Item>

        <Form.Item label="종교">
          {getFieldDecorator('religion', {
            initialValue: userDetail.religion
          })(<Input style={{ width: '40%' }} />)}
        </Form.Item>

        <Form.Item label="취미">
          {getFieldDecorator('hobby', {
            initialValue: userDetail.hobby
          })(<Input style={{ width: '40%' }} />)}
        </Form.Item>

        <Form.Item label="특기">
          {getFieldDecorator('specialty', {
            initialValue: userDetail.specialty
          })(<Input />)}
        </Form.Item>

        <Form.Item label="기호">
          {getFieldDecorator('liking', {
            initialValue: userDetail.liking
          })(<Input />)}
        </Form.Item>

        <Form.Item label="기타">
          {getFieldDecorator('etc', {
            initialValue: userDetail.etc
          })(<Input />)}
        </Form.Item>

        <Form.Item wrapperCol={{ span: 12, offset: 4 }}>
          <Button type="primary" htmlType="submit" disabled={submitting}>
            저 장
          </Button>
        </Form.Item>
      </Form>

      <Modal
        title="비밀번호 변경"
        centered
        visible={passwordResetVisible}
        footer={null}
        onCancel={() => setPasswordResetVisible(false)}
        destroyOnClose={true}
      >
        <UserPasswordReset
          userId={user.id}
          onSuccess={() => setPasswordResetVisible(false)}
        />
      </Modal>
    </>
  );
};

const _UserDefaultForm = Form.create<Props>()(UserDefaultForm);
export default inject('userStore')(observer(_UserDefaultForm));
