import React from 'react';
import { inject, observer } from 'mobx-react';
import { Route, Switch, Redirect } from 'react-router-dom';
import UnderConst from '../../components/UnderConst';
import Dashboard from '../projects/common/Dashboard';
import EADocDrafter from '../eas/EADocDrafter';
import OrderList from '../projects/common/OrderList';
import OrderLedger from '../projects/common/OrderLedger';
import OrderDetail from '../projects/orders/OrderDetail';
import OrderEADocList from '../projects/orders/OrderEADocList';
import { OrderState } from '../../models/Order';
import DeptCodeList from '../../components/general/DeptCodeList';
import { AuthStore } from '../../stores/authStore';
import EADocListByCat from '../eas/EADocListByCat';
import EADocDrafterWrapper from '../eas/EADocDrafterWrapper';
import CollectPlan from '../management/finance/CollectPlan';
import ArchiveGroupBrowser from '../../components/general/ArchiveGroupBrowser';

interface Props {
  authStore?: AuthStore;
}

const Index: React.FC<Props> = props => {
  const menu = 'development';
  const submenu = 'main';
  let deptId = Number(localStorage.getItem(`${menu}DefaultDeptId`)) || 40;
  const authStore = props.authStore!;
  if (
    authStore.user.rootDept &&
    authStore.user.rootDept.id === 8 &&
    authStore.user.hq
  ) {
    deptId = authStore.user.hq.id;
  }

  return (
    <Switch>
      <Route path="/development/main/uc" component={UnderConst} />
      {/* <Route path="/development/main/archive" component={ArchiveGroupBrowser} /> */}

      <Route path="/development/main" exact>
        <Redirect to="/development/main/dashboard" />
      </Route>

      <Route path="/development/main/dashboard">
        <Dashboard menu={menu} deptId={deptId} hideDeptSelector />
      </Route>
      <Route path="/development/main/ongoing">
        <OrderLedger
          title="진행 프로젝트"
          menu={menu}
          deptId={deptId}
          hideDeptSelector
          state={OrderState.ongoing}
        />
      </Route>
      <Route path="/development/main/eadocs/ord">
        <OrderEADocList
          menu={menu}
          submenu={submenu}
          deptId={deptId}
          hideDeptSelector
        />
      </Route>
      <Route path="/development/main/draft/ord">
        <EADocDrafter title="수주정보보고서" refTarget="ord" />
      </Route>
      <Route path="/development/main/orders/view" component={OrderDetail} />
      <Route path="/development/main/orders">
        <OrderList deptId={deptId} hideDeptSelector />
      </Route>
      <Route path="/development/main/ordins">
        <OrderLedger
          title="수주견적"
          menu={menu}
          deptId={deptId}
          hideDeptSelector
          target="ordin"
        />
      </Route>
      <Route path="/development/main/ctrins">
        <OrderLedger
          title="수주계약"
          menu={menu}
          deptId={deptId}
          hideDeptSelector
          target="ctrin"
        />
      </Route>
      <Route path="/development/main/ordouts">
        <OrderLedger
          title="외주실행"
          menu={menu}
          deptId={deptId}
          hideDeptSelector
          target="ordout"
        />
      </Route>
      <Route path="/development/main/ctrouts">
        <OrderLedger
          title="외주계약"
          menu={menu}
          deptId={deptId}
          hideDeptSelector
          target="ctrout"
        />
      </Route>

      <Route path="/development/main/collect">
        <CollectPlan menu="development" hideCorpSelect />
      </Route>

      <Route path="/development/main/eas/list" component={EADocListByCat} />
      <Route
        path="/development/main/eas/draft"
        component={EADocDrafterWrapper}
      />

      <Route path="/development/main/archive" component={ArchiveGroupBrowser} />

      <Route path="/development/main/settings/stages">
        <DeptCodeList
          menu={menu}
          deptId={deptId}
          title="부서별 진행단계"
          fieldName="order_stages"
        />
      </Route>
      <Route path="/development/main/settings/ctypes">
        <DeptCodeList
          menu={menu}
          deptId={deptId}
          title="부서별 수금구분"
          fieldName="cp_terms"
        />
      </Route>
    </Switch>
  );
};

export default inject('authStore')(observer(Index));
