import React from 'react';

interface Props {
  width?: string | number;
}

const InlineItem: React.FC<Props> = props => {
  return (
    <div style={{ display: 'inline-block', width: props.width }}>
      {props.children}
    </div>
  );
};

export default InlineItem;
