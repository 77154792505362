import React, { useState, useEffect } from 'react';
import { withRouter, RouteComponentProps } from 'react-router';
import { EADocListType, EADoc } from '../../models/EAS';
import EADocListPanel from './EADocListPanel';
import { Row, Col, Input, Icon } from 'antd';
import { Button } from 'antd/lib/radio';
import EAService from '../../services/EAService';
import Util from '../../helpers/Util';

interface Props extends RouteComponentProps {
  type?: EADocListType;

  category?: number;
  refTarget?: string;
  refId?: number;

  keyword?: string;
  page?: number;
  pageSize?: number;
  hideTitle?: boolean;
  hideToolbar?: boolean;
  hidePagination?: boolean;
}

const EADocListView: React.FC<Props> = props => {
  const {
    type,
    category,
    refTarget,
    refId,
    hideTitle = false,
    hideToolbar = false,
    hidePagination = false,
    page = 1,
    pageSize = 15,
    keyword = ''
  } = props;

  const [loading, setLoading] = useState(false);
  const [items, setItems] = useState<EADoc[]>([]);
  const [totalCount, setTotalCount] = useState(0);

  useEffect(() => {
    const fetchItems = async () => {
      setLoading(true);
      try {
        const result = await EAService.fetchItems(pageSize, page, {
          type,
          category,
          ref_target: refTarget,
          ref_id: refId,
          keyword
        });
        setTotalCount(result.total_count);
        setItems(EADoc.fromJsonArray(result.items));
      } catch (error) {
        Util.showError(error);
      } finally {
        setLoading(false);
      }
    };

    fetchItems();
  }, [type, category, refTarget, refId, page, keyword, pageSize]);

  const [draftable, setDraftable] = useState(false);
  useEffect(() => {
    if (refTarget === 'pmis' && refId === undefined) {
      setDraftable(false);
    } else {
      setDraftable(true);
    }
  }, [refTarget, refId]);

  const onClickAddButton = () => {
    // props.history.push('/works/eas/draft');
    props.history.push(
      `./draft?refTarget=${refTarget || ''}&cat=${category || ''}`
    );
  };

  const onSearch = (keyword: string) => {
    props.history.push(
      `${props.location.pathname}?type=${type || ''}&cat=${category ||
        ''}&refTarget=${refTarget || ''}&page=1&keyword=${keyword}`
    );
  };

  const onChangeCurrentPage = (page: number) => {
    props.history.push(
      `${props.location.pathname}?type=${type || ''}&cat=${category ||
        ''}&refTarget=${refTarget || ''}&page=${page}&keyword=${keyword}`
    );
  };

  const pagination = hidePagination
    ? false
    : {
        pageSize,
        current: page,
        total: totalCount,
        showTotal: (total: number, range: [number, number]) =>
          `전체 ${total}건 중 ${range[0]}-${range[1]}`
      };

  let title = type
    ? EADoc.listTypeName(type)
    : category
    ? EADoc.categoryName(category)
    : '결재문서';

  return (
    <>
      {!hideTitle && <h2>{title}</h2>}

      {!hideToolbar && (
        <Row type="flex" justify="space-between" style={{ marginBottom: 10 }}>
          <Col>
            <Input.Search
              placeholder="제목 또는 기안자명"
              allowClear={true}
              onSearch={onSearch}
              defaultValue={keyword}
              style={{ width: 200 }}
            />
          </Col>
          <Col>
            <Button onClick={onClickAddButton} disabled={!draftable}>
              <Icon type="plus" />
              기안하기
            </Button>
          </Col>
        </Row>
      )}

      <EADocListPanel
        items={items}
        loading={loading}
        pagination={pagination}
        onChangeCurrentPage={onChangeCurrentPage}
      />
    </>
  );
};

export default withRouter(EADocListView);
