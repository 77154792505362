import { MenuItem } from '../../../models/Common';

const menuItems: MenuItem[] = [
  { id: 'files', icon: 'desktop', title: '통합파일검색' },
  { id: '4001', icon: 'desktop', title: '사업일반' },
  { id: '4002', icon: 'desktop', title: '사업계획' },
  { id: '4003', icon: 'desktop', title: '설계/인허가관리' },
  { id: '4004', icon: 'desktop', title: '계약/사업비관리' },
  { id: '4005', icon: 'desktop', title: '광고/분양관리' },
  { id: '4006', icon: 'desktop', title: '공사관리' },
  { id: '4008', icon: 'desktop', title: '문서,회의록 외' },
  { id: '101', icon: 'desktop', title: '포트폴리오' },
  {
    id: '기안/품의',
    title: '기안/품의',
    icon: 'desktop',
    children: [
      {
        id: 'eas/list?refTarget=pmis&cat=4002',
        title: '사업계획서'
      },
      {
        id: 'eas/list?refTarget=pmis&cat=4001',
        title: '사업수지(정산)'
      },
      {
        id: 'eas/list?refTarget=pmis&cat=4005',
        title: '분양계획'
      },
      {
        id: 'eas/list?refTarget=pmis&cat=4006',
        title: '광고계획'
      }
    ]
  }
];

export default menuItems;
