import React, { useState, useEffect } from 'react';
import { Modal } from 'antd';
import { File } from '../../models/File';
import ReactImageGallery, { ReactImageGalleryItem } from 'react-image-gallery';

interface Props {
  items: File[];
  visible?: boolean;
  startIndex?: number;
  onCancel?: () => void;
}

const ImageGalleryModal: React.FC<Props> = props => {
  const { visible = false, startIndex = 0 } = props;
  const [images, setImages] = useState<ReactImageGalleryItem[]>([]);

  useEffect(() => {
    if (!visible) return;
  }, [visible]);

  useEffect(() => {
    const images: ReactImageGalleryItem[] = props.items.map(item => ({
      // original: item.originalFullPath,
      original: item.mediumFullPath,
      thumbnail: item.thumbnailFullPath,
      renderThumbInner: (item?: ReactImageGalleryItem) => {
        if (item) {
          return (
            <div className="absolute-center-parent-gallery">
              <img
                className="absolute-center-child"
                src={item.thumbnail}
                alt=""
              />
            </div>
          );
        }
      },
      renderItem: (item?: ReactImageGalleryItem) => {
        if (item) {
          // return (
          //   <div style={{ width: '100%', height: 500 }}>
          //     <img src={item.original} />
          //   </div>
          // );
          return (
            <div>
              <img src={item.original} alt="" />
            </div>
          );
        }
      }
    }));
    setImages(images);
  }, [props.items]);

  const onCancel = () => {
    props.onCancel && props.onCancel();
  };

  return (
    <Modal
      width={1000}
      centered
      visible={visible}
      footer={null}
      closable={false}
      onCancel={onCancel}
      destroyOnClose={true}
    >
      <ReactImageGallery
        items={images}
        showFullscreenButton={false}
        showPlayButton={false}
        startIndex={startIndex}
      />
    </Modal>
  );
};

export default ImageGalleryModal;
