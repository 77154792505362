import { Department } from './Org';
import { User } from './User';
import moment from 'moment';
import dataStore from '../stores/dataStore';
import userStore from '../stores/userStore';
import authStore from '../stores/authStore';
import Util from '../helpers/Util';
import _ from 'lodash';

export class Project {
  id = 0;
  name = '';
  year: number = new Date().getFullYear();
  dept_id?: number = authStore.user.hq && authStore.user.hq.id;
  drafter_id: number = authStore.user.id;
  register_date?: moment.Moment = moment();
  type = '';
  kind = '';
  use1: string[] = [];
  use2: string[] = [];
  use3: string[] = [];
  use = '';
  zipcode = '';
  address = '';
  site_area?: number;
  total_floor_area?: number;
  floor_level?: number;
  base_level?: number;
  begin_date?: moment.Moment;
  end_date?: moment.Moment;
  comp_id?: number;
  comp_name = '';
  note = '';

  [key: string]: any; // for string index (subscript)
  dept?: Department = authStore.user.hq;
  drafter: User = authStore.user;

  fieldsValue() {
    return _.omit(this, ['id', 'dept', 'drafter', 'comp_id']);
  }

  static fromJsonArray(sources: any[]) {
    return sources.map((source) => Project.fromJson(source));
  }

  static fromJson(source: any) {
    let item = new Project();
    for (const key of Object.keys(source)) {
      if (key in item) {
        item[key] = source[key];
      }
    }

    item.dept = dataStore.findDepartment(source.dept_id);
    item.drafter = userStore.findById(source.drafter_id);

    item.register_date = Util.stringToMoment(source.register_date);
    item.begin_date = Util.stringToMoment(source.begin_date);
    item.end_date = Util.stringToMoment(source.end_date);

    item.use1 = (source.use1 && source.use1.split('/')) || [];
    item.use2 = (source.use2 && source.use2.split('/')) || [];
    item.use3 = (source.use3 && source.use3.split('/')) || [];

    return item;
  }

  get level_info(): string {
    const floor = this.floor_level ? '지상 ' + this.floor_level : undefined;
    const base = this.base_level ? '지하 ' + this.base_level : undefined;
    if (floor && base) return `${floor}, ${base}`;
    return floor || base || '';
  }

  get f_site_area(): string {
    return Util.formatNumber(this.site_area);
  }

  get f_total_floor_area(): string {
    return Util.formatNumber(this.total_floor_area);
  }

  static kindCode(kind: string) {
    switch (kind) {
      case '신축':
      case '증축':
      case '리모델링':
      case '설계변경':
      case '용도변경':
      case '대수선':
      case '현상설계':
      case '재건축':
      case '인허가':
        return 'P';

      case '법규검토':
      case '도시계획':
      case '지구단위계획':
        return 'U';

      case 'CM':
        return 'S';

      case 'CS':
        return 'C';

      case 'LTA':
        return 'L';

      case 'NPL물리실사용역':
        return 'N';

      case 'PM':
      case '사업성검토':
        return 'D';

      case '분양가조사':
      case '분양기획':
      case '분양대행':
        return 'M';

      default:
        return 'X';
    }
  }
}
