import React, { useState, useEffect } from 'react';
import { Table, Button, Row, Col, Icon, Drawer } from 'antd';
import AppService from '../../services/AppService';
import Util from '../../helpers/Util';
import StringCodeListEditor from './StringCodeListEditor';
import { StringCode } from '../../models/Common';

interface Props {
  title: string;
  fieldName: string;
}

const StringCodeList: React.FC<Props> = props => {
  const { title, fieldName } = props;
  const [loading, setLoading] = useState(true);
  const [items, setItems] = useState<StringCode[]>([]);

  useEffect(() => {
    const fetchItems = async () => {
      setLoading(true);
      try {
        const result = await AppService.fetchStoreValues([fieldName]);
        const codes = result[fieldName];
        const items = codes.map((code: string) => ({ code }));
        setItems(items);
      } catch (error) {
        Util.showError(error);
      } finally {
        setLoading(false);
      }
    };

    fetchItems();
  }, [fieldName]);

  const [editorVisible, setEditorVisible] = useState(false);

  const onClickEditButton = () => {
    setEditorVisible(true);
  };
  const onCloseEditor = () => {
    setEditorVisible(false);
  };

  const onSubmitChanges = async (changes: StringCode[]) => {
    setLoading(true);
    try {
      const values = changes.map(el => el.code);
      await AppService.updateStoreValues(fieldName, values);
      setItems(changes);
      setEditorVisible(false);
    } catch (error) {
      Util.showError(error);
    } finally {
      setLoading(false);
    }
  };

  const columns = [
    {
      title: '이름',
      dataIndex: 'code'
    }
  ];

  return (
    <>
      <h2>{title}</h2>

      <Row type="flex" justify="space-between" style={{ marginBottom: 10 }}>
        <Col />
        <Col>
          <Button onClick={onClickEditButton}>
            <Icon type="edit" />
            {title} 관리
          </Button>
        </Col>
      </Row>

      <Table
        columns={columns}
        dataSource={items}
        pagination={false}
        rowKey="code"
        loading={loading}
        bordered={true}
        size="middle"
      />

      <Drawer
        title={`${title} 관리`}
        placement="right"
        width={800}
        visible={editorVisible}
        onClose={onCloseEditor}
        destroyOnClose={true}
      >
        <StringCodeListEditor items={items} onSubmit={onSubmitChanges} />
      </Drawer>
    </>
  );
};

export default StringCodeList;
