import React from 'react';
import { Layout } from 'antd';

// 상단 서브헤더 없는 경우
export const HLBody: React.FC = props => {
  return (
    <Layout className="hl-body">
      <Layout.Content className="hl-content-layout">
        {props.children}
      </Layout.Content>
    </Layout>
  );
};

// 상단 서브헤더 포함
export const HLContent: React.FC = props => {
  return (
    <Layout className="hl-content">
      <Layout.Content className="hl-content-layout">
        {props.children}
      </Layout.Content>
    </Layout>
  );
};

export const HLContentRight: React.FC = props => {
  return (
    <Layout className="hl-content" style={{ padding: '18px 18px 18px 0px' }}>
      <Layout.Content className="hl-content-layout">
        {props.children}
      </Layout.Content>
    </Layout>
  );
};
