import Util from '../helpers/Util';
import moment from 'moment';
import { User } from './User';
import { File } from './File';
import { EADoc } from './EAS';

export class ContentPage {
  id = 0;
  title = '';
  path = '';
  content = '';
  owner_id = 0;
  created_at = '';
  updated_at = '';
  files: File[] = [];

  // derived
  [key: string]: any; // for string index (subscript)

  static fromJsonArray(sources: any[]) {
    return sources.map(source => ContentPage.fromJson(source));
  }

  static fromJson(source: any) {
    let item = new ContentPage();
    for (const key of Object.keys(source)) {
      if (key in item) {
        item[key] = source[key];
      }
    }

    // item.owner = userStore.findById(source.owner_id);

    if (source.files) {
      item.files = File.fromJsonArray(source.files);
    }

    return item;
  }

  get isEmpty(): boolean {
    return (
      this.content === null || this.content === undefined || this.content === ''
    );
  }
}

export class PageLog {
  id = 0;
  page_id = 0;
  date?: moment.Moment = moment();
  content = '';
  note = '';
  file_id = 0;
  owner_id = 0;
  created_at = '';

  owner: User = new User();
  file?: File;
  files: File[] = []; // NOTE: 실제로 로그당 첨부파일 하나지만 FCFileInput 파라미터 전달시 배열전달하는 편의를 위해

  // derived
  [key: string]: any; // for string index (subscript)

  static fromJsonArray(sources: any[]) {
    return sources.map(source => PageLog.fromJson(source));
  }

  static fromJson(source: any) {
    let item = new PageLog();
    for (const key of Object.keys(source)) {
      if (key in item) {
        item[key] = source[key];
      }
    }

    item.date = Util.stringToMoment(source.date);
    // item.owner = userStore.findById(source.owner_id);
    item.owner = User.fromJson(source.owner);
    if (source.file) {
      item.file = File.fromJson(source.file);
      item.files = [item.file];
    }

    return item;
  }
}

export class PageEADoc {
  id = 0;
  page_id = 0;
  eadoc_id = 0;
  title = '';
  file_id = 0;
  note = '';

  // derived
  [key: string]: any; // for string index (subscript)
  eadoc: EADoc = new EADoc();
  eadocs: EADoc[] = []; // NOTE: 실제로 관련문서 하나지만 FCEADocInput 파라미터 전달시 배열전달하는 편의를 위해
  file?: File;
  files: File[] = []; // NOTE: 실제로 첨부파일 하나지만 FCFileInput 파라미터 전달시 배열전달하는 편의를 위해

  static fromJsonArray(sources: any[]) {
    return sources.map(source => PageEADoc.fromJson(source));
  }

  static fromJson(source: any) {
    let item = new PageEADoc();
    for (const key of Object.keys(source)) {
      if (key in item) {
        item[key] = source[key];
      }
    }

    item.eadoc.id = source.eadoc_id;
    item.eadoc.title = source.title;
    item.eadocs = [item.eadoc];

    if (source.file) {
      item.file = File.fromJson(source.file);
      item.files = [item.file];
    }

    return item;
  }
}
