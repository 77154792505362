import React, { useState } from 'react';
import { Button, Input, Form, Select } from 'antd';
import Util from '../../helpers/Util';
import { FormComponentProps } from 'antd/lib/form';
import { Archive } from '../../models/File';
import FCFileInput from '../formcomps/FCFileInput';
import FileService from '../../services/FileService';
const { Option } = Select;

interface Props extends FormComponentProps {
  archives: Archive[];
  orderId?: number;
  onSuccess?: () => void;
}

const ArchiveFileUploader: React.FC<Props> = props => {
  const { archives, orderId } = props;
  const { getFieldDecorator } = props.form;
  const [submitting, setSubmitting] = useState(false);
  const [uploading, setUploading] = useState(false);

  const onClickSubmitButton = () => {
    props.form.validateFields((err, values) => {
      if (err) return;
      if (values.file_ids.length === 0) return;
      processItem(values);
    });
  };

  const processItem = async (values: any) => {
    try {
      setSubmitting(true);
      await FileService.createArchiveFile(values);
      Util.showSuccess('등록되었습니다.');
      if (props.onSuccess) props.onSuccess();
    } catch (err) {
      Util.showError(err);
    } finally {
      setSubmitting(false);
    }
  };

  const formItemLayout = {
    labelCol: { span: 4 },
    wrapperCol: { span: 16 }
  };

  return (
    <Form {...formItemLayout}>
      {getFieldDecorator('order_id', {
        initialValue: orderId
      })(<Input type="hidden" />)}
      <Form.Item label="구분">
        {getFieldDecorator('archive_id', {
          rules: [
            {
              required: true,
              message: '구분을 선택해주세요.'
            }
          ],
          initialValue: archives[0].id
        })(
          <Select>
            {archives.map(archive => (
              <Option key={archive.id} value={archive.id}>
                {archive.name}
              </Option>
            ))}
          </Select>
        )}
      </Form.Item>

      <Form.Item label="태그">
        {getFieldDecorator('tags')(<Select mode="tags" allowClear></Select>)}
      </Form.Item>

      <Form.Item label="파일 첨부">
        <FCFileInput form={props.form} onChangeUploading={setUploading} />
      </Form.Item>

      <Form.Item wrapperCol={{ span: 12, offset: 4 }}>
        <Button
          type="primary"
          disabled={uploading || submitting}
          onClick={onClickSubmitButton}
        >
          등 록
        </Button>
      </Form.Item>
    </Form>
  );
};

export default Form.create<Props>()(ArchiveFileUploader);
