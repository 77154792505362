import { Department, Corp } from './Org';
import dataStore from '../stores/dataStore';
import { File } from './File';
import moment from 'moment';
import Util from '../helpers/Util';

export type UpdateUserData = {
  name?: string;
  email?: string;
  corp_id?: number;
  dept_id?: number;
  rank?: string;
};

export class User {
  id = 0;
  email = '';
  name = '';
  created_at = '';
  entry_date = '';
  retire_date = '';
  rank?: string;

  acl_project = false;
  acl_management = false;
  acl_report = false;
  acl_planning = false;
  acl_business = false;
  acl_development = false;
  acl_marketing = false;
  acl_oversea = false;

  // derived
  [key: string]: any; // for string index (subscript)
  corp?: Corp;
  dept?: Department;
  avatar?: File;

  static currentUserId: number;

  static fromJsonArray(sources: any[]) {
    return sources ? sources.map((source) => User.fromJson(source)) : [];
  }

  static fromJson(source: any) {
    let item = new User();
    for (const key of Object.keys(source)) {
      if (key in item) {
        item[key] = source[key];
      }
    }

    item.corp = source.corp_id ? dataStore.findCorp(source.corp_id) : undefined;
    item.dept = source.dept_id
      ? dataStore.findDepartment(source.dept_id)
      : undefined;

    if (source.avatar_id) {
      const file: any = {
        id: source.avatar_id,
        original_name: source.original_name,
        path: source.path,
        name: source.file_name,
        ext: source.ext,
        t_width: source.t_width,
        t_height: source.t_height,
      };
      item.avatar = File.fromJson(file);
    }

    return item;
  }

  updateWith(data: UpdateUserData) {
    if (data.name) this.name = data.name;
    if (data.email) this.email = data.email;
    if (data.corp_id) this.corp = dataStore.findCorp(data.corp_id);
    if (data.dept_id) this.dept = dataStore.findDepartment(data.dept_id);
    if (data.rank) this.rank = data.rank;
  }

  get isMe(): boolean {
    return this.id === User.currentUserId;
  }

  get isAdmin(): boolean {
    return this.id === 1;
  }

  get isExecutive(): boolean {
    if (this.dept) {
      return this.dept.id === 1;
    }
    return false;
  }

  get rootDept(): Department | undefined {
    return this.dept ? this.dept.rootDept : undefined;
  }

  get hq(): Department | undefined {
    return this.dept ? dataStore.findHQ(this.dept) : undefined;
  }

  get compName(): string {
    return this.corp ? this.corp.name : '';
  }

  get deptName(): string {
    return this.dept && this.dept.id !== 1 ? this.dept.name : '';
  }

  get rankName(): string {
    return this.rank || '';
  }

  get nameWithRank(): string {
    return this.name + ' ' + this.rankName;
  }

  get nameWithDept(): string {
    return this.deptName + ' ' + this.name;
  }

  get nameWithDeptRank(): string {
    return this.deptName + ' ' + this.name + ' ' + this.rankName;
  }

  get retireState(): string {
    return this.retire_date ? '퇴사' : '재직';
  }
}

export class UserContract {
  user_id = 0;
  seq = 0;
  begin_date?: moment.Moment = moment();
  end_date?: moment.Moment = moment();
  type = 0;
  salary = 0;
  note = '';
  files: File[] = [];

  // derived
  [key: string]: any; // for string index (subscript)
  user: User = new User();

  static fromJsonArray(sources: any[]) {
    return sources.map((source) => UserContract.fromJson(source));
  }

  static fromJson(source: any) {
    let item = new UserContract();
    for (const key of Object.keys(source)) {
      if (key in item) {
        item[key] = source[key];
      }
    }

    item.begin_date = Util.stringToMoment(source.begin_date);
    item.end_date = Util.stringToMoment(source.end_date);
    item.files = source.files ? File.fromJsonArray(source.files) : [];
    item.user = source.user ? User.fromJson(source.user) : new User();

    return item;
  }

  get id() {
    return this.user_id + '_' + this.seq;
  }

  get typeName() {
    switch (this.type) {
      case 1:
        return '임원';
      case 2:
        return '정규직';
      case 3:
        return '프로젝트 계약직';
      case 4:
        return '일반 계약직';
      case 5:
        return '파견직';
      default:
        return '';
    }
  }
}

export class UserRNP {
  user_id = 0;
  seq = 0;
  date?: moment.Moment = moment();
  type = 0;
  content = '';
  note = '';
  files: File[] = [];

  // derived
  [key: string]: any; // for string index (subscript)

  static fromJsonArray(sources: any[]) {
    return sources.map((source) => UserRNP.fromJson(source));
  }

  static fromJson(source: any) {
    let item = new UserRNP();
    for (const key of Object.keys(source)) {
      if (key in item) {
        item[key] = source[key];
      }
    }

    item.date = Util.stringToMoment(source.date);
    item.files = File.fromJsonArray(source.files);

    return item;
  }

  get typeName() {
    switch (this.type) {
      case 1:
        return '징계';
      case 2:
        return '포상';
      default:
        return '';
    }
  }
}

export class UserTransfer {
  user_id = 0;
  seq = 0;
  date?: moment.Moment = moment();
  type = 0;
  content = '';
  note = '';
  files: File[] = [];

  // derived
  [key: string]: any; // for string index (subscript)

  static fromJsonArray(sources: any[]) {
    return sources.map((source) => UserTransfer.fromJson(source));
  }

  static fromJson(source: any) {
    let item = new UserTransfer();
    for (const key of Object.keys(source)) {
      if (key in item) {
        item[key] = source[key];
      }
    }

    item.date = Util.stringToMoment(source.date);
    item.files = File.fromJsonArray(source.files);

    return item;
  }

  get typeName() {
    switch (this.type) {
      case 1:
        return '신규입사';
      case 2:
        return '승진';
      case 3:
        return '부서이동';
      case 4:
        return '겸직';
      case 5:
        return '겸직해제';
      case 6:
        return '보직명';
      case 7:
        return '보직면';
      case 8:
        return '근무지변경';
      case 9:
        return '파견';
      case 10:
        return '파견복귀';
      case 11:
        return '퇴사(의원)';
      case 12:
        return '퇴사(당연)';
      case 13:
        return '퇴사(징계)';
      default:
        return '';
    }
  }
}

export class UserETC {
  user_id = 0;
  seq = 0;
  content = '';
  files: File[] = [];

  // derived
  [key: string]: any; // for string index (subscript)

  static fromJsonArray(sources: any[]) {
    return sources.map((source) => UserETC.fromJson(source));
  }

  static fromJson(source: any) {
    let item = new UserETC();
    for (const key of Object.keys(source)) {
      if (key in item) {
        item[key] = source[key];
      }
    }

    item.files = File.fromJsonArray(source.files);

    return item;
  }
}
