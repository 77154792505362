import React from 'react';

interface Props {
  style?: any;
}

const HDescriptions: React.FC<Props> = props => {
  const { style = {} } = props;

  return (
    <div
      className="ant-descriptions ant-descriptions-small ant-descriptions-bordered"
      style={{ marginBottom: '15px', ...style }}
    >
      <div className="ant-descriptions-view">
        <table>
          <tbody>{props.children}</tbody>
        </table>
      </div>
    </div>
  );
};

interface RowProps {}

const HDRow: React.FC<RowProps> = props => {
  return <tr className="ant-descriptions-row">{props.children}</tr>;
};

interface ItemProps {
  label: string;
  colSpan?: number;
  rowSpan?: number;
  small?: boolean;
  align?: string; //'left | right | center';
  width?: string;
  // style?: any;
}

const HDItem: React.FC<ItemProps> = props => {
  const hstyle: any = props.small ? { width: '75px' } : { width: '150px' };
  // const dstyle: any = props.align
  //   ? { textAlign: props.align }
  //   : { textAlign: 'left' };

  let dstyle: any = { textAlign: 'left' };
  if (props.align) {
    dstyle.textAlign = props.align;
  }
  if (props.width) {
    dstyle.width = props.width;
  }
  return (
    <>
      <th
        className="ant-descriptions-item-label ant-descriptions-item-colon"
        rowSpan={props.rowSpan || 1}
        style={hstyle}
      >
        {props.label}
      </th>
      <td
        className="ant-descriptions-item-content"
        colSpan={props.colSpan || 1}
        rowSpan={props.rowSpan || 1}
        style={dstyle}
      >
        {props.children}
      </td>
    </>
  );
};

const HDRowItem: React.FC<ItemProps> = props => {
  return (
    <HDRow>
      <HDItem {...props} />
    </HDRow>
  );
};

export { HDescriptions, HDRow, HDItem, HDRowItem };
