import { User } from './User';
import userStore from '../stores/userStore';
import moment from 'moment';
import Util from '../helpers/Util';
import { File } from './File';

export class Occasion {
  id = 0;
  owner_id = 0;
  title = '';
  content = '';
  date = moment();
  summary = '';
  place = '';
  attendees = '';
  read_count = 0;
  comment_count = 0;
  created_at = '';
  files: File[] = [];

  // derived
  [key: string]: any; // for string index (subscript)
  owner: User = new User();

  static fromJsonArray(sources: any[]) {
    return sources.map(source => Occasion.fromJson(source));
  }

  static fromJson(source: any) {
    let item = new Occasion();
    for (const key of Object.keys(source)) {
      if (key in item) {
        item[key] = source[key];
      }
    }

    item.date = Util.stringToMoment(source.date) || moment();
    item.owner = userStore.findById(source.owner_id);

    if (source.files) {
      item.files = File.fromJsonArray(source.files);
    }

    return item;
  }

  get hasRightToEdit() {
    return this.owner.isMe;
  }
}
