import React, { useState, useEffect } from 'react';
import {
  Table,
  Row,
  Col,
  Form,
  Button,
  InputNumber,
  Drawer,
  Tag,
  Radio
} from 'antd';
import { FormComponentProps } from 'antd/lib/form';
import LinkButton from '../../../components/general/LinkButton';
import Util from '../../../helpers/Util';
import FIHQSelect from '../../../components/formcomps/FIHQSelect';
import OrderDetail from '../../projects/orders/OrderDetail';
import FinanceService, {
  CollectFetchOption
} from '../../../services/FinanceService';
import { MonthlyCollectStatus } from '../../../models/Finance';
import useWindowSize from '../../../helpers/useWindowSize';
import { RadioChangeEvent } from 'antd/lib/radio';
import _ from 'lodash';
import { PaginationConfig, SorterResult } from 'antd/lib/table';
import FCTextInput from '../../../components/formcomps/FCTextInput';

interface Props extends FormComponentProps {}

const CollectStatus: React.FC<Props> = props => {
  const [items, setItems] = useState<MonthlyCollectStatus[]>([]);
  const [sumItem, setSumItem] = useState<MonthlyCollectStatus>(
    new MonthlyCollectStatus()
  );
  const [searchOption, setSearchOption] = useState<CollectFetchOption>({
    year: new Date().getFullYear(),
    month: new Date().getMonth() + 1
  });
  const [loading, setLoading] = useState(false);
  const [displayType, setDisplayType] = useState(1);
  const windowSize = useWindowSize();

  const { getFieldDecorator } = props.form;

  useEffect(() => {
    const fetchItems = async () => {
      setLoading(true);
      try {
        const result = await FinanceService.fetchCollectStatusForYear(
          searchOption
        );
        const items = MonthlyCollectStatus.fromJsonArray(result.items);
        setItems(items);

        const sumItem = new MonthlyCollectStatus();
        sumItem.in_total_price = _.sumBy(items, i => Number(i.in_total_price));
        sumItem.in_pay = _.sumBy(items, i => Number(i.in_pay));
        for (let i = 1; i <= 12; i++) {
          const planField = 'p' + i;
          const actionField = 'a' + i;
          sumItem[planField] = _.sumBy(items, i => Number(i[planField]));
          sumItem[actionField] = _.sumBy(items, i => Number(i[actionField]));
        }
        setSumItem(sumItem);
      } catch (error) {
        Util.showError(error);
      } finally {
        setLoading(false);
      }
    };

    fetchItems();
  }, [searchOption]);

  const [currentItem, setCurrentItem] = useState<MonthlyCollectStatus>(
    new MonthlyCollectStatus()
  );
  const [detailVisible, setDetailVisible] = useState(false);

  const onClickItemName = (item: MonthlyCollectStatus) => {
    setCurrentItem(item);
    setDetailVisible(true);
  };

  const onCloseDetail = () => {
    setDetailVisible(false);
  };

  const onChangeType = (e: RadioChangeEvent) => {
    setDisplayType(e.target.value);
  };

  const onSubmitSearchForm = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    props.form.validateFields((err, values) => {
      if (err) return;
      if (
        values.year === undefined ||
        values.yaer === null ||
        String(values.year).trim() === ''
      ) {
        return;
      }
      setSearchOption(values);
    });
  };

  const onChangeTable = (
    pagination: PaginationConfig,
    filters: Partial<Record<React.ReactText, string[]>>,
    sorter: SorterResult<MonthlyCollectStatus>
  ) => {
    const numberField = _.includes(
      ['in_total_price', 'balance', 'f_in_payrate'],
      sorter.field
    );
    const order = sorter.order === 'descend' ? 'desc' : 'asc';
    const sortedItems = _.orderBy(
      items,
      i => {
        return numberField ? Number(i[sorter.field]) : i[sorter.field];
      },
      order
    );
    setItems([...sortedItems]);
  };

  const columsByMonth = [
    '1',
    '2',
    '3',
    '4',
    '5',
    '6',
    'sum1',
    '7',
    '8',
    '9',
    '10',
    '11',
    '12',
    'sum2'
  ].map(key => {
    return {
      title: MonthlyCollectStatus.columnName(key),
      align: 'right' as 'right',
      width: 96,
      className: key === 'sum1' || key === 'sum2' ? 'hl-bg-gray' : undefined,
      render: (text: string, record: MonthlyCollectStatus) => {
        let result = '';
        if (displayType === 1) {
          const amount = Number(record[`p${key}`]);
          result = amount === 0 ? '' : Util.formatNumber(record[`p${key}`]);
        } else {
          const amount = Number(record[`a${key}`]);
          result = amount === 0 ? '' : Util.formatNumber(record[`a${key}`]);
        }

        return key === 'sum1' || key === 'sum2' ? <b>{result}</b> : result;
      }
    };
  });

  const columns = [
    {
      title: '프로젝트명',
      dataIndex: 'name',
      fixed: 'left' as 'left',
      width: 280,
      sorter: true,
      defaultSortOrder: 'ascend' as 'ascend',
      render: (text: string, record: MonthlyCollectStatus) => (
        <LinkButton onClick={e => onClickItemName(record)}>
          <div className="truncate" style={{ width: '265px' }}>
            {text}
          </div>
        </LinkButton>
      )
    },
    {
      title: '연도',
      dataIndex: 'year',
      width: 50,
      render: (text: string, record: MonthlyCollectStatus) =>
        record.year === 0 ? '' : text
    },
    {
      title: '부서',
      dataIndex: 'dept_name',
      width: 80,
      sorter: true
    },
    {
      title: '업체명',
      dataIndex: 'comp_name',
      width: 130,
      ellipsis: true,
      sorter: true,
      render: (text: string, record: MonthlyCollectStatus) =>
        record.year === 0 ? <b>합 계</b> : text
    },
    {
      title: '계약금액',
      dataIndex: 'in_total_price',
      align: 'right' as 'right',
      width: 120,
      sorter: true,
      render: (text: string, record: MonthlyCollectStatus) => (
        <Tag color="blue">{Util.formatNumber(record.in_total_price)}</Tag>
      )
    },
    {
      title: '기수금금액',
      dataIndex: 'in_pay',
      align: 'right' as 'right',
      width: 110,
      render: (text: string, record: MonthlyCollectStatus) =>
        Util.formatNumber(record.in_pay)
    },
    ...columsByMonth,
    {
      title: '잔액',
      dataIndex: 'balance',
      fixed: 'right' as 'right',
      align: 'right' as 'right',
      width: 120,
      sorter: true,
      render: (text: string, record: MonthlyCollectStatus) => (
        <Tag color="red">{Util.formatNumber(record.balance)}</Tag>
      )
    },
    {
      title: '기성율',
      dataIndex: 'f_in_payrate',
      fixed: 'right' as 'right',
      align: 'right' as 'right',
      width: 65,
      sorter: true,
      render: (text: string, record: MonthlyCollectStatus) =>
        record.in_total_price ? `${record.f_in_payrate} %` : ''
    }
  ];

  return (
    <>
      <h2>월별 수금현황</h2>

      <Form
        className="hl-form"
        layout="inline"
        style={{ marginBottom: 7 }}
        onSubmit={onSubmitSearchForm}
      >
        <Row type="flex" justify="space-between">
          <Col>
            <Form.Item>
              <FCTextInput
                form={props.form}
                name="keyword"
                placeholder="검색어"
                allowClear
              />
            </Form.Item>

            <Form.Item>
              {getFieldDecorator('year', {
                initialValue: searchOption.year
              })(
                <InputNumber
                  placeholder="년도"
                  style={{ width: 100 }}
                  formatter={value => `${value}년`}
                />
              )}
            </Form.Item>

            <FIHQSelect form={props.form} width="180px" allowClear />

            <Form.Item>
              <Button type="primary" htmlType="submit">
                검색
              </Button>
            </Form.Item>
          </Col>

          <Col>
            <Form.Item>
              <Radio.Group defaultValue={1} onChange={onChangeType}>
                <Radio value={1}>예정</Radio>
                <Radio value={2}>실행</Radio>
              </Radio.Group>
            </Form.Item>
          </Col>
        </Row>
      </Form>

      <Table
        columns={columns}
        dataSource={[...items, sumItem]}
        rowKey="id"
        rowClassName={record => {
          let className = '';
          if (record.id === 0) className += ' hl-bg-gray';
          return className;
        }}
        pagination={false}
        bordered={true}
        loading={loading}
        size="middle"
        onChange={onChangeTable}
        // scroll={{ x: 'max-content' }}
        scroll={{ x: 2300, y: windowSize.height - 400 }}
      />

      <Drawer
        closable={false}
        placement="right"
        width={'85%'}
        visible={detailVisible}
        onClose={onCloseDetail}
        destroyOnClose={true}
      >
        <OrderDetail id={currentItem.id} fetchDelayed />
      </Drawer>
    </>
  );
};

export default Form.create<Props>()(CollectStatus);
