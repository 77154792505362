import React, { useState, useEffect, useRef } from 'react';
import { Table, Row, Col, Button, Icon, Drawer, Popconfirm } from 'antd';
import { PaginationConfig } from 'antd/lib/table';
import Util from '../../helpers/Util';
import LinkButton from '../../components/general/LinkButton';
import PageService from '../../services/PageService';
import { PageLog } from '../../models/Page';
import PageLogEdit from './PageLogEdit';
import AButton from '../../components/general/AButton';
import FileService from '../../services/FileService';
import { File } from '../../models/File';

interface Props {
  pageId: number;
}

const PageLogs: React.FC<Props> = props => {
  const pageId = props.pageId || 0;

  const [items, setItems] = useState<PageLog[]>([]);
  const [totalCount, setTotalCount] = useState(0);
  const [currentPage, setCurrentPage] = useState(1);
  const [loading, setLoading] = useState(false);
  const [refreshTrigger, setRefreshTrigger] = useState(1);

  const pagination = {
    pageSize: 15,
    current: currentPage,
    total: totalCount,
    showTotal: (total: number, range: [number, number]) =>
      `전체 ${total}건 중 ${range[0]}-${range[1]}`
  };

  useEffect(() => {
    const fetchItems = async () => {
      setLoading(true);
      try {
        const result = await PageService.fetchPageLogs(
          pagination.pageSize,
          currentPage,
          pageId
        );
        setTotalCount(result.total_count);
        setItems(PageLog.fromJsonArray(result.items));
      } catch (error) {
        Util.showError(error);
      } finally {
        setLoading(false);
      }
    };
    fetchItems();
  }, [currentPage, refreshTrigger, pagination.pageSize, pageId]);

  const columns = [
    {
      title: '일자',
      dataIndex: 'date',
      width: 120,
      render: (text: string, record: PageLog) => Util.formatDate(text)
    },
    {
      title: '변경내용',
      dataIndex: 'content',
      render: (text: string, record: PageLog) => (
        <LinkButton onClick={e => onClickItem(record)}>{text}</LinkButton>
      )
    },
    {
      title: '변경사유',
      dataIndex: 'note'
    },
    {
      title: '게시자',
      dataIndex: 'owner.name',
      width: 120
    },
    {
      title: '첨부파일',
      dataIndex: 'file_id',
      render: (text: string, record: PageLog) => {
        const file = record.file;
        if (file) {
          return (
            <>
              <span key={file.id} style={{ display: 'block' }}>
                <LinkButton onClick={e => onClickFile(file)}>
                  <Icon type="download" />
                </LinkButton>{' '}
                {file.isPreviewable ? (
                  <AButton onClick={e => FileService.previewFile(file)}>
                    {file.original_name}
                  </AButton>
                ) : (
                  <>{file.original_name}</>
                )}
              </span>
            </>
          );
        } else {
          return <></>;
        }
      }
    },
    {
      width: '90px',
      render: (text: string, record: PageLog) => {
        return (
          <Popconfirm
            title="정말 삭제하시겠습니까?"
            onConfirm={e => onClickDeleteButton(record)}
          >
            <LinkButton>삭제</LinkButton>
          </Popconfirm>
        );
      }
    }
  ];

  const onClickDeleteButton = async (item: PageLog) => {
    try {
      await PageService.deletePageLog(item.id);
      Util.showSuccess('삭제되었습니다.');
      setRefreshTrigger(v => v + 1);
    } catch (err) {
      Util.showError(err);
    }
  };

  const onClickFile = async (item: File) => {
    try {
      setLoading(true);
      await FileService.downloadFile(item.id, item.original_name);
    } catch (error) {
      Util.showError(error);
    } finally {
      setLoading(false);
    }
  };

  const onChangeTable = (pagination: PaginationConfig) => {
    setCurrentPage(pagination.current || 1);
  };

  const itemRef = useRef<PageLog | undefined>(undefined);
  const [editorVisible, setEditorVisible] = useState(false);

  const onClickAddButton = () => {
    itemRef.current = undefined;
    setEditorVisible(true);
  };

  const onClickItem = (item: PageLog) => {
    itemRef.current = item;
    setEditorVisible(true);
  };

  const onCloseEditor = () => {
    setEditorVisible(false);
  };

  const onSuccessEditing = () => {
    setRefreshTrigger(v => v + 1);
    setEditorVisible(false);
  };

  return (
    <>
      <Row type="flex" justify="space-between" style={{ marginBottom: 10 }}>
        <Col></Col>
        <Col>
          <Button onClick={onClickAddButton}>
            <Icon type="plus" /> 추가하기
          </Button>
        </Col>
      </Row>

      <Table
        columns={columns}
        dataSource={items}
        rowKey="id"
        pagination={pagination}
        onChange={onChangeTable}
        bordered={true}
        loading={loading}
        size="middle"
      />

      <Drawer
        closable={false}
        placement="right"
        width={800}
        visible={editorVisible}
        onClose={onCloseEditor}
        destroyOnClose={true}
      >
        <PageLogEdit
          pageId={pageId}
          item={itemRef.current}
          onSuccess={onSuccessEditing}
        />
      </Drawer>
    </>
  );
};

export default PageLogs;
