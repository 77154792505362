import { User } from './User';
import userStore from '../stores/userStore';
import moment from 'moment';
import Util from '../helpers/Util';

export class Plan {
  id = 0;
  owner_id = 0;
  content = '';
  date?: moment.Moment;
  created_at = '';

  // derived
  [key: string]: any; // for string index (subscript)
  owner: User = new User();

  static fromJsonArray(sources: any[]) {
    return sources.map(source => Plan.fromJson(source));
  }

  static fromJson(source: any) {
    let item = new Plan();
    for (const key of Object.keys(source)) {
      if (key in item) {
        item[key] = source[key];
      }
    }

    item.date = Util.stringToMoment(source.date);
    item.owner = userStore.findById(source.owner_id);

    return item;
  }

  get hasRightToEdit() {
    return this.owner.isMe;
  }

  get content_summary() {
    const summary = this.content.substring(0, 70);
    return this.content.length <= 70 ? summary : summary + '...';
  }
}
