import { observable, action } from 'mobx';
import { User } from '../models/User';
import axios from 'axios';
import { API_ROOT } from '../config';
import { Department } from '../models/Org';

export class UserStore {
  @observable users: User[] = [];
  @observable updatingStore = false;

  @action
  async fetchStore() {
    const { data: result } = await axios(API_ROOT + '/users');
    const rawUsers = result.users;
    this.users = User.fromJsonArray(rawUsers);
  }

  @action
  async createUser(values: any) {
    const config = { headers: { 'Content-Type': 'application/json' } };
    const body = JSON.stringify(values);
    const { data: result } = await axios.post(
      API_ROOT + '/users',
      body,
      config
    );

    const user = User.fromJson(result.user);
    this.users.unshift(user);
  }

  @action
  async updateUser(user: User, values: any, autoRefetch: boolean = true) {
    const config = { headers: { 'Content-Type': 'application/json' } };
    const body = JSON.stringify(values);
    await axios.put(API_ROOT + `/users/${user.id}`, body, config);

    // NOTE: autoRefresh일 경우에만 서버 페치 처리
    if (autoRefetch) {
      await this.fetchStore();
      return;
    }

    // NOTE: autoRefresh가 아닐 경우 userStore의 해당 user정보 갱신 처리
    let storeUser = this.findById(user.id);
    for (const key of Object.keys(values)) {
      if (key in storeUser) {
        storeUser[key] = values[key];
      }
    }
  }

  // dept부서의 소속 유저 목록 리턴
  // 해당 부서의 직접 소속된 유저뿐 아니라 하위부서의 소속 유저도 모두 리턴
  usersInDept(dept: Department): User[] {
    if (dept.id < 0) return this.users;

    let deptIds: Number[] = [dept.id];

    const makeIds = (depts: Department[]) => {
      depts.forEach((child) => {
        deptIds.push(child.id);
        if (child.children) {
          makeIds(child.children);
        }
      });
    };

    if (dept.children) {
      makeIds(dept.children);
    }

    return this.users.filter(
      (user) => user.dept && deptIds.includes(user.dept.id)
    );
  }

  findById(id: number): User {
    return this.users.find((user) => user.id === id) || new User();
  }
}

export default new UserStore();
