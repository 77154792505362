import React, { useState, useEffect } from 'react';
import { Table } from 'antd';
import { AccountItem } from '../../../models/Finance';
import AButton from '../../../components/general/AButton';

interface Props {
  items: AccountItem[];
  out?: boolean;
  loading?: boolean;
  selectedRowKeys?: number[];
  onClickItem?: (item: AccountItem) => void;
  onSelectItems?: (items: AccountItem[]) => void;
}

const AccountItemListAll: React.FC<Props> = props => {
  const { items, out = false, loading = false, onSelectItems } = props;
  const [selectedRowKeys, setSelectedRowKeys] = useState<number[]>(
    props.selectedRowKeys || []
  );

  useEffect(() => {
    if (props.selectedRowKeys) {
      setSelectedRowKeys(props.selectedRowKeys);
    }
  }, [props.selectedRowKeys]);

  let columns = [
    {
      title: '법인명',
      dataIndex: 'corp.name',
      width: '160px',
      sorter: (a: AccountItem, b: AccountItem) =>
        a.corp_name > b.corp_name ? 1 : -1
    },
    {
      title: '종류',
      dataIndex: 'kind',
      width: '120px',
      sorter: (a: AccountItem, b: AccountItem) => (a.kind > b.kind ? 1 : -1)
    },
    {
      title: out ? '지급예정일' : '수금예정일',
      dataIndex: 'est_date',
      width: '120px',
      sorter: (a: AccountItem, b: AccountItem) => {
        return a.f_est_date > b.f_est_date ? 1 : -1;
      },
      defaultSortOrder: 'descend' as 'descend',
      render: (text: string, record: AccountItem) => {
        return record.f_est_date;
      }
    },
    {
      title: out ? '지급일' : '수금일',
      dataIndex: 'pay_date',
      width: '120px',
      sorter: (a: AccountItem, b: AccountItem) => {
        return a.f_pay_date > b.f_pay_date ? 1 : -1;
      },
      defaultSortOrder: 'descend' as 'descend',
      render: (text: string, record: AccountItem) => {
        return record.f_pay_date;
      }
    },
    {
      title: out ? '지급예정금액' : '수금예정금액',
      dataIndex: 'price',
      width: '130px',
      align: 'right' as 'right',
      render: (text: string, record: AccountItem) => {
        return record.f_price;
      }
    },
    {
      title: out ? '지급금액' : '수금금액',
      dataIndex: 'pay',
      width: '130px',
      align: 'right' as 'right',
      render: (text: string, record: AccountItem) => {
        return record.f_pay;
      }
    },
    {
      title: '내용',
      dataIndex: 'title',
      render: (text: string, record: AccountItem) => (
        <AButton onClick={e => onClickItem(record)}>{text}</AButton>
      )
    },
    {
      title: '비고',
      dataIndex: 'note'
    }
  ];

  // 지급일 경우 '종류' 컬럼 제거
  if (out) columns = columns.filter(e => e.dataIndex !== 'kind');

  const onClickItem = (item: AccountItem) => {
    if (props.onClickItem) props.onClickItem(item);
  };

  console.count('AccountItemList');

  let rowSelection: any = undefined;
  if (onSelectItems) {
    rowSelection = {
      selectedRowKeys,
      onChange: (selectedRowKeys: number[], selectedRows: AccountItem[]) => {
        setSelectedRowKeys(selectedRowKeys);
        onSelectItems(selectedRows);
      }
    };
  }

  return (
    <Table
      columns={columns}
      dataSource={items}
      rowKey="id"
      pagination={false}
      bordered={true}
      loading={loading}
      rowSelection={rowSelection}
      size="middle"
    />
  );
};

export default AccountItemListAll;
