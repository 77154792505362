import React, { useState } from 'react';
import { Menu, Icon } from 'antd';
import { ClickParam } from 'antd/lib/menu';
const SubMenu = Menu.SubMenu;
const MenuItemGroup = Menu.ItemGroup;

const Header = () => {
  const [current, setCurrent] = useState('setting:1');

  const handleClick = (e: ClickParam) => {
    setCurrent(e.key);
  };

  return (
    <Menu onClick={handleClick} selectedKeys={[current]} mode="horizontal">
      <Menu.Item key="key1" disabled={true}>
        <Icon type="appstore" />
        수주정보
      </Menu.Item>
      <Menu.Item key="key2" disabled={true}>
        <Icon type="appstore" />
        계약현황
      </Menu.Item>
      <Menu.Item key="key3" disabled={true}>
        <Icon type="appstore" />
        수금관리
      </Menu.Item>
      <Menu.Item key="key4" disabled={true}>
        <Icon type="appstore" />
        사업현황
      </Menu.Item>
      <Menu.Item key="key5" disabled={true}>
        <Icon type="appstore" />
        사업부문보고
      </Menu.Item>
      <Menu.Item key="key6" disabled={true}>
        <Icon type="appstore" />
        인원관리
      </Menu.Item>
      <Menu.Item key="key7" disabled={true}>
        <Icon type="appstore" />
        업무관리
      </Menu.Item>
      <SubMenu
        title={
          <span className="submenu-title-wrapper">
            <Icon type="appstore" />
            프로젝트: 프로젝트 1
          </span>
        }
      >
        <MenuItemGroup title="진행중">
          <Menu.Item key="setting:1">프로젝트 1</Menu.Item>
          <Menu.Item key="setting:2">프로젝트 2</Menu.Item>
        </MenuItemGroup>
      </SubMenu>
    </Menu>
  );
};

export default Header;
