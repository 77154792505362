import React, { useState, useEffect } from 'react';
import { Form, Button } from 'antd';
import { FormComponentProps } from 'antd/lib/form';
import MultiDeptSelect from '../../components/general/MultiDeptSelect';
import AppService from '../../services/AppService';
import Util from '../../helpers/Util';

interface Props extends FormComponentProps {
  title: string;
  onSubmit?: (data: any) => void;
}

const ProjectBaseSetting: React.FC<Props> = props => {
  const [deptIds, setDeptIds] = useState<number[]>([]);
  useEffect(() => {
    AppService.fetchStoreValues(['hq_ids']).then(result => {
      setDeptIds(result.hq_ids);
    });
  }, []);

  const [submitting, setSubmitting] = useState(false);
  const onSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    props.form.validateFields((err, values) => {
      if (err) return;
      processItem(values);
    });
  };

  const processItem = async (values: any) => {
    try {
      setSubmitting(true);
      await AppService.updateStoreValues('hq_ids', values.hq_ids);
      Util.showSuccess('저장되었습니다.');
    } catch (err) {
      Util.showError(err);
      setSubmitting(false);
    }
  };

  return (
    <>
      <h2>기본 설정</h2>
      <Form
        className="hl-form"
        labelCol={{ span: 4 }}
        wrapperCol={{ span: 18 }}
        onSubmit={onSubmit}
      >
        <Form.Item label="프로젝트 진행 부서">
          <MultiDeptSelect
            form={props.form}
            name="hq_ids"
            maxDepth={0}
            values={deptIds}
          />
        </Form.Item>

        <Form.Item wrapperCol={{ span: 12, offset: 4 }}>
          <Button type="primary" htmlType="submit" disabled={submitting}>
            저 장
          </Button>
        </Form.Item>
      </Form>
    </>
  );
};

export default Form.create<Props>()(ProjectBaseSetting);
