import Service from './Index';

const CommentService = {
  fetchItems: (ref_target: number, ref_id: number) => {
    return Service.get(`/etc/comments`, { ref_target, ref_id });
  },
  create: (
    ref_target: number,
    ref_id: number,
    content: string,
    parent_id?: number
  ) => {
    return Service.post(`/etc/comments`, {
      ref_target,
      ref_id,
      content,
      parent_id
    });
  }
};

export default CommentService;
