import { User } from './User';
import userStore from '../stores/userStore';
import { STATIC_ROOT } from '../config';

export class Archive {
  id = 0;
  name = '';
  group_id = 0;
  group_name = '';

  // derived
  [key: string]: any; // for string index (subscript)

  static fromJsonArray(sources: any[]) {
    return sources.map(source => Archive.fromJson(source));
  }

  static fromJson(source: any) {
    let item = new Archive();
    for (const key of Object.keys(source)) {
      if (key in item) {
        item[key] = source[key];
      }
    }

    return item;
  }
}

export class File {
  id = 0;
  original_name = '';
  path = '';
  name = '';
  ext?: string;
  owner_id = 0;
  mimetype = '';
  size = 0;
  revision = 1;
  group_id = 0;
  archive_id = 0;
  archive_name = '';
  order_id?: number;
  created_at = '';
  updated_at = '';
  tags: string[] = [];

  // image related
  width?: number;
  height?: number;
  t_size?: number;
  t_width?: number;
  t_height?: number;
  m_size?: number;
  m_width?: number;
  m_height?: number;

  // derived
  [key: string]: any; // for string index (subscript)
  owner: User = new User();

  static fromJsonArray(sources: any[]) {
    return sources.map(source => File.fromJson(source));
  }

  static fromJson(source: any) {
    let item = new File();
    for (const key of Object.keys(source)) {
      if (key in item) {
        item[key] = source[key];
      }
    }

    item.owner = userStore.findById(source.owner_id);
    item.tags = source.tags ? source.tags.split(',') : [];

    return item;
  }

  get isPDF(): boolean {
    return this.mimetype === 'application/pdf';
  }

  get isImage(): boolean {
    return this.mimetype.startsWith('image/');
  }

  get isPreviewable(): boolean {
    return this.isPDF || this.isImage;
  }

  get fullFileName(): string {
    return `${STATIC_ROOT}/${this.path}/${this.name}`;
  }

  get originalFullPath(): string {
    return this.ext ? this.fullFileName + '.' + this.ext : this.fullFileName;
  }

  get mediumFullPath(): string {
    if (this.m_width && this.m_height) {
      return this.ext
        ? this.fullFileName + '_m.' + this.ext
        : this.fullFileName;
    }
    return this.originalFullPath;
  }

  get thumbnailFullPath(): string {
    if (this.t_width && this.t_height) {
      return this.ext
        ? this.fullFileName + '_t.' + this.ext
        : this.fullFileName;
    }
    return this.mediumFullPath;
  }
}
