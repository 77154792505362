import React, { useState, useEffect } from 'react';
import Util from '../../../helpers/Util';
import Spinner from '../../../components/general/Spinner';
import {
  HDescriptions,
  HDRow,
  HDItem,
  HDRowItem
} from '../../../components/general/HDescriptions';
import { Trip, TripAccount } from '../../../models/Trip';
import HTMLContentViewer from '../../../components/general/HTMLContentViewer';
import { Table } from 'antd';
import _ from 'lodash';

interface Props {
  id?: number;
  data?: any;
}

const TripApplyViewer: React.FC<Props> = props => {
  const [loading, setLoading] = useState(false);
  const [item, setItem] = useState<Trip>(new Trip());
  const [total, setTotal] = useState<TripAccount>(new TripAccount(0));

  useEffect(() => {
    const fetchDetail = async (id: number) => {
      setLoading(true);
      try {
        // const data = await VacationService.fetchItem(id);
        // configDetail(data.vacation);
      } catch (error) {
        Util.showError(error);
      } finally {
        setLoading(false);
      }
    };

    const configDetail = (data: any) => {
      const item = Trip.fromJson(data);
      setItem(item);

      const total = new TripAccount(0);
      total.type = '합계';
      total.request_price = _.sumBy(item.items, 'request_price');
      setTotal(total);
    };

    if (props.data) {
      configDetail(props.data);
    } else if (props.id) {
      fetchDetail(props.id);
    }
  }, [props.id, props.data]);

  const columns = [
    {
      title: '구분',
      width: 100,
      dataIndex: 'type',
      render: (text: string, record: TripAccount) => {
        return record.id === 0 ? <b>{text}</b> : text;
      }
    },
    {
      title: '금액',
      dataIndex: 'f_request_price',
      width: 120,
      align: 'right' as 'right',
      render: (text: string, record: TripAccount) => {
        return record.id === 0 ? <b>{text}</b> : text;
      }
    },
    {
      title: '예산근거',
      dataIndex: 'request_base'
    }
  ];

  if (loading) {
    return <Spinner />;
  }

  return (
    <>
      <h3>개요</h3>
      <HDescriptions>
        <HDRow>
          <HDItem label="출장/외근명">{item.trip_title}</HDItem>
          <HDItem label="구분">{item.type === 1 ? '출장' : '외근'}</HDItem>
        </HDRow>
        <HDRowItem label="출장사유" colSpan={3}>
          {item.reason}
        </HDRowItem>
        <HDRowItem label="출장자" colSpan={3}>
          {item.users.map(el => el.name).join(', ')}
        </HDRowItem>
        <HDRowItem label="출장지" colSpan={3}>
          {item.place}
        </HDRowItem>
        <HDRowItem label="출장일시" colSpan={3}>
          {item.durationSummary}
        </HDRowItem>
      </HDescriptions>

      <h3>예산</h3>
      <Table
        columns={columns}
        dataSource={[...item.items, total]}
        rowKey="id"
        pagination={false}
        bordered={true}
        loading={loading}
        size="middle"
        style={{ marginBottom: '18px' }}
      />

      <h3>상세일정</h3>
      <HTMLContentViewer content={item.content} />
    </>
  );
};

export default TripApplyViewer;
