import { MenuItem } from '../../../models/Common';

const menuItems: MenuItem[] = [
  {
    id: '진행 프로젝트',
    title: '진행 프로젝트',
    icon: 'desktop',
    link: '/business/main/ongoing'
  },
  {
    id: '업무프로세스',
    title: '업무프로세스',
    icon: 'desktop',
    children: [
      {
        id: 'ord',
        title: '수주정보보고',
        link: '/business/main/eadocs/ord'
      },
      {
        id: 'orders',
        title: '수주심의',
        link: '/business/main/orders'
      },
      {
        id: 'ordins',
        title: '수주견적',
        link: '/business/main/ordins'
      },
      {
        id: 'ctrins',
        title: '수주계약',
        link: '/business/main/ctrins'
      },
      {
        id: 'ordouts',
        title: '외주실행',
        link: '/business/main/ordouts'
      },
      {
        id: 'ctrouts',
        title: '외주계약',
        link: '/business/main/ctrouts'
      }
      // {
      //   id: 'comp',
      //   title: '협력업체등록신청',
      //   link: '/business/main/uc'
      // }
    ]
  },
  {
    id: '수금계획표',
    title: '수금계획표',
    icon: 'desktop',
    link: '/business/main/collect'
  },
  {
    id: '수주정보',
    title: '수주정보',
    icon: 'desktop',
    children: [
      {
        id: '업무보고1',
        title: '전체 수주 진행 목록',
        link: '/business/main/reports_all'
      },
      {
        id: '업무보고2',
        title: '핵심 프로젝트',
        link: '/business/main/reports_urgent'
      },
      {
        id: '업무보고3',
        title: '이슈 프로젝트',
        link: '/business/main/reports_issue'
      },
      {
        id: '업무보고4',
        title: '신규 프로젝트',
        link: '/business/main/reports_new'
      },
      // {
      //   id: '업무보고3',
      //   title: '핵심수주 일일 보고',
      //   link: '/business/main/reports3'
      // },
      {
        id: '업무보고5',
        title: '인적자원 관리 리스트',
        link: '/business/main/reports_hr'
      }
    ]
  },
  {
    id: '사업',
    title: '업무보고',
    icon: 'desktop',
    children: [
      {
        id: '사업1',
        title: '주간 업무보고',
        link: '/business/main/eas/list?cat=3102'
      },
      {
        id: '사업2',
        title: '진행프로젝트 보고',
        link: '/business/main/eas/list?cat=3103'
      },
      {
        id: '사업3',
        title: '금융CM팀 수주,매출 현황',
        link: '/business/main/eas/list?cat=3104'
      },
      {
        id: '사업4',
        title: '현장별 사업현황',
        link: '/business/main/eas/list?cat=3105'
      }
    ]
  },
  {
    id: '3106',
    title: '업무보고 (자료실)',
    icon: 'desktop'
  },
  {
    id: '3101',
    title: '인원관리',
    icon: 'desktop'
  },
  {
    id: '3102',
    title: '면허 및 경력관리',
    icon: 'desktop'
  },
  {
    id: '교육',
    title: '교육',
    icon: 'desktop',
    children: [
      {
        id: '교육1',
        title: '교육수강내역',
        link: '/business/main/myedu'
      },
      {
        id: '교육2',
        title: '교육일정',
        link: '/business/main/edu'
      }
    ]
  },
  {
    id: '기안/품의',
    title: '기안/품의',
    icon: 'desktop',
    children: [
      {
        id: 'report1',
        title: '현장배치 인사명령서',
        link: '/business/main/eas/list?cat=3101'
      },
      {
        id: 'report2',
        title: '기타업무품의',
        link: '/business/main/eas/list?cat=3100'
      }
    ]
  },
  {
    id: '3103',
    title: '문서관리',
    icon: 'desktop'
  },
  {
    id: '3105',
    title: '업무매뉴얼',
    icon: 'desktop'
  }
];

export default menuItems;
