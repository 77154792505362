import React, { useState, useEffect } from 'react';
import CommentView from './CommentView';
import CommentEditor from './CommentEditor';
import { Divider, Spin } from 'antd';
import CommentService from '../../services/CommentService';
import Util from '../../helpers/Util';
import { Comment } from '../../models/Comment';

interface Props {
  refTarget: number;
  refId: number;
  headerHidden?: boolean;
  onChangeItemCount?: (count: number) => void;
}

const CommentSection: React.FC<Props> = props => {
  const { refTarget, refId, headerHidden, onChangeItemCount } = props;
  const [loading, setLoading] = useState(false);
  const [items, setItems] = useState<Comment[]>([]);
  const [itemCount, setItemCount] = useState(0);

  useEffect(() => {
    const fetchItems = async () => {
      setLoading(true);
      try {
        const result = await CommentService.fetchItems(refTarget, refId);
        setItemCount(result.comments.length);
        setItems(Comment.fromJsonArray(result.comments));
      } catch (error) {
        Util.showError(error);
      } finally {
        setLoading(false);
      }
    };

    fetchItems();
  }, [refTarget, refId]);

  const onCreateComment = (comment: Comment) => {
    setItemCount(itemCount + 1);
    setItems([...items, comment]);
  };

  const onCreateChildComment = () => {
    console.log('onCreateChildComment');
    setItemCount(itemCount + 1);
  };

  useEffect(() => {
    if (onChangeItemCount && itemCount > 0) {
      onChangeItemCount(itemCount);
    }
  }, [itemCount, onChangeItemCount]);

  return (
    <>
      {!headerHidden && (
        <Divider orientation="left">의견 ({itemCount})</Divider>
      )}
      {loading && (
        <div style={{ textAlign: 'center' }}>
          <Spin />
        </div>
      )}
      {items.map(item => (
        <CommentView
          key={item.id}
          comment={item}
          refTarget={refTarget}
          refId={refId}
          depth={0}
          onCreateComment={onCreateChildComment}
        />
      ))}
      <CommentEditor
        refTarget={refTarget}
        refId={refId}
        onCreateComment={onCreateComment}
      />
    </>
  );
};

export default CommentSection;
