import Service from './Index';

export type LawsuitSearchOption = {
  keyword?: string;
};

const LawsuitService = {
  fetchItems: (limit: number, page: number, options?: LawsuitSearchOption) => {
    const offset = (page - 1) * limit;
    return Service.get('/mgmt/lawsuits', {
      limit,
      offset,
      ...options
    });
  },
  fetchItem: (id: number) => {
    return Service.get(`/mgmt/lawsuits/${id}`);
  },
  create: (data: any) => {
    return Service.post('/mgmt/lawsuits', data);
  },
  update: (id: number, data: any) => {
    return Service.put(`/mgmt/lawsuits/${id}`, data);
  },
  delete: (id: number) => {
    return Service.delete(`/mgmt/lawsuits/${id}`);
  },

  //
  // lawsuit events
  //
  fetchEvents: (lawsuit_id: number) => {
    return Service.get(`/mgmt/lawsuits/${lawsuit_id}/events`);
  },
  createEvent: (data: any) => {
    return Service.post('/mgmt/lawsuit_events', data);
  },
  updateEvent: (id: number, data: any) => {
    return Service.put(`/mgmt/lawsuit_events/${id}`, data);
  },
  deleteEvent: (id: number) => {
    return Service.delete(`/mgmt/lawsuit_events/${id}`);
  }
};

export default LawsuitService;
