import React, { useState, useEffect, useRef } from 'react';
import {
  Table,
  Row,
  Col,
  Input,
  Form,
  InputNumber,
  Button,
  Steps,
  Drawer,
  Progress,
  Switch,
  Tag,
} from 'antd';
import FCStringCodeSelect from '../../../components/formcomps/FCStringCodeSelect';
import { FormComponentProps } from 'antd/lib/form';
import OrderService, {
  OrderSearchOption,
} from '../../../services/OrderService';
import { Order, OrderState } from '../../../models/Order';
import Util from '../../../helpers/Util';
import { PaginationConfig, SorterResult } from 'antd/lib/table';
import AppService from '../../../services/AppService';
import LinkButton from '../../../components/general/LinkButton';
import OrderStagesDate from '../orders/OrderStagesDate';
import InlineItem from '../../../components/formcomps/InlineItem';
import { withRouter, RouteComponentProps } from 'react-router-dom';
import FIHQSelect from '../../../components/formcomps/FIHQSelect';
const { Step } = Steps;

interface Props extends FormComponentProps, RouteComponentProps {
  menu: string;
  deptId: number;
  deptIds?: number[];
  hideDeptSelector?: boolean;
}

const Dashboard: React.FC<Props> = (props) => {
  const { menu, deptId } = props;

  const [items, setItems] = useState<Order[]>([]);
  const [searchOption, setSearchOption] = useState<OrderSearchOption>({
    dept_id: deptId,
    state: '진행',
    sort_field: localStorage.getItem('orderSorterField') || 'year',
    sort_order: localStorage.getItem('orderSorterOrder') || 'descend',
  });
  const [totalCount, setTotalCount] = useState(0);
  const [currentPage, setCurrentPage] = useState(1);
  const [loading, setLoading] = useState(false);
  const [refreshTrigger, setRefreshTrigger] = useState(0);
  const refreshNeeded = useRef(false);
  const { getFieldDecorator } = props.form;

  const [stages, setStages] = useState<string[]>([]);
  const [stepsWidth, setStepsWidth] = useState(2000);

  const pagination = {
    pageSize: 15,
    current: currentPage,
    total: totalCount,
    showTotal: (total: number, range: [number, number]) =>
      `전체 ${total}건 중 ${range[0]}-${range[1]}`,
  };

  useEffect(() => {
    const fetchItems = async () => {
      setLoading(true);
      try {
        const stageResult = await AppService.fetchDeptValues(
          searchOption.dept_id!,
          ['order_stages']
        );
        const stages = stageResult.order_stages || [];
        setStages(stages);

        // 진행과정 steps에 따른 테이블 너비 계산
        // 글자당 28 + 여백 50
        const stageLengths = stages.map((el: string) => el.length * 28 + 50);
        // console.log('stageLengths: ', stageLengths);
        const stepsWidth = stageLengths.reduce(
          (acc: number, value: number) => acc + value,
          0
        );
        // 종류 + 제목 + stepsWidth + 비고
        // setTableWidth(90 + 300 + stepsWidth + 50);
        setStepsWidth(stepsWidth);

        const result = await OrderService.fetchItems(
          pagination.pageSize,
          currentPage,
          searchOption
        );
        setTotalCount(result.total_count);
        setItems(Order.fromJsonArray(result.items));
      } catch (error) {
        Util.showError(error);
      } finally {
        setLoading(false);
      }
    };

    fetchItems();
  }, [currentPage, searchOption, pagination.pageSize, refreshTrigger]);

  const onChangeTable = (
    pagination: PaginationConfig,
    filters: Partial<Record<React.ReactText, string[]>>,
    sorter: SorterResult<Order>
  ) => {
    setCurrentPage(pagination.current || 1);
    setSearchOption({
      ...searchOption,
      sort_field: sorter.field,
      sort_order: sorter.order,
    });
    localStorage.setItem('orderSorterField', sorter.field);
    localStorage.setItem('orderSorterOrder', sorter.order);
  };

  const onSubmitSearchForm = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    props.form.validateFields((err, values) => {
      if (err) return;
      setSearchOption(values);
      setCurrentPage(1);
    });
  };

  const [progressView, setProgressView] = useState(false);
  const onChangeViewType = (checked: boolean) => {
    setProgressView(checked);
  };

  const onClickItemName = (item: Order) => {
    props.history.push(`/${menu}/orders/${item.id}`);
  };

  const [currentItem, setCurrentItem] = useState<Order>(new Order());
  const [detailVisible, setDetailVisible] = useState(false);
  const onClickItemStage = (item: Order) => {
    setCurrentItem(item);
    setDetailVisible(true);
  };

  const onCloseDetail = () => {
    setDetailVisible(false);
    if (refreshNeeded.current) {
      setRefreshTrigger(Date.now());
      refreshNeeded.current = false;
    }
  };

  const onChangeOrderDetail = () => {
    refreshNeeded.current = true;
  };

  const sortOrderForField = (
    field: string
  ): 'ascend' | 'descend' | undefined => {
    const sortOrder =
      searchOption.sort_order === 'ascend' ? 'ascend' : 'descend';
    return searchOption.sort_field === field ? sortOrder : undefined;
  };

  const columns = [
    {
      title: 'No',
      dataIndex: 'id',
      fixed: 'left' as 'left',
      align: 'right' as 'right',
      sorter: true,
      defaultSortOrder: sortOrderForField('id'),
      width: 55,
    },
    {
      dataIndex: 'mark',
      fixed: 'left' as 'left',
      width: 40,
      render: (text: string, record: Order) => {
        if (record.getUrgentLevel(stages) === 3)
          return <Tag color="#f50">!</Tag>;
        if (record.getUrgentLevel(stages) === 2)
          return <Tag color="volcano">!</Tag>;
        return <></>;
      },
    },
    {
      title: 'BOM',
      dataIndex: 'bom',
      fixed: 'left' as 'left',
      width: 70,
    },
    {
      title: '계약건명',
      dataIndex: 'name',
      fixed: 'left' as 'left',
      width: 300,
      sorter: true,
      defaultSortOrder: sortOrderForField('name'),
      render: (text: string, record: Order) => {
        return (
          <LinkButton onClick={(e) => onClickItemName(record)}>
            <div className="truncate" style={{ width: '300px' }}>
              {text}
            </div>
          </LinkButton>
        );
      },
    },
    {
      title: '연도',
      dataIndex: 'year',
      width: 60,
      sorter: true,
      defaultSortOrder: sortOrderForField('year'),
      render: (text: string, record: Order) => record.year,
    },
    {
      title: '종류',
      dataIndex: 'kind',
      // fixed: 'left' as 'left',
      width: 90,
    },
    {
      title: '진행과정',
      render: (text: string, record: Order) => {
        const current = stages.findIndex((el) => el === record.stage);
        return progressView ? (
          <>{renderProgressItem(record)}</>
        ) : (
          <Steps
            size="small"
            current={current}
            className="hl-steps"
            style={{ width: stepsWidth }}
          >
            {stages.map((stage) => renderStepItem(record, stage))}
          </Steps>
        );
      },
    },
    {
      title: '비고',
      fixed: 'right' as 'right',
      width: 50,
    },
  ];

  // TODO: 리팩토링
  // description = order.dateForStage(stage)
  const renderStepItem = (record: Order, stage: string) => {
    const orderStage = record.getStage(stage);
    let description = '';
    if (orderStage) {
      if (orderStage.finish_date) {
        description = Util.formatDate(orderStage.finish_date);
      } else if (orderStage.finish_est_date) {
        description = Util.formatDate(orderStage.finish_est_date);
      }
    }
    return (
      <Step
        title={stage}
        key={stage}
        description={description}
        onClick={(e) => onClickItemStage(record)}
      />
    );
  };

  const renderProgressItem = (record: Order) => {
    const current = stages.findIndex((el) => el === record.stage);
    const percent = (((current + 1) / stages.length) * 100).toFixed(1);

    const orderStage = record.getStage(record.stage);
    let description = '';
    if (orderStage) {
      if (orderStage.finish_date) {
        description = Util.formatDate(orderStage.finish_date);
      } else if (orderStage.finish_est_date) {
        description = Util.formatDate(orderStage.finish_est_date);
      }
    }
    return (
      <Row
        type="flex"
        justify="space-around"
        onClick={(e) => onClickItemStage(record)}
      >
        <Col span={19}>
          <Progress percent={Number(percent)} />
        </Col>
        <Col>
          <Steps size="small" current={0} className="hl-steps">
            <Step
              title={record.stage}
              key={record.stage}
              description={description}
              // onClick={e => onClickItemStage(record)}
              // icon={<Icon type="flag" />}
              icon={<></>}
            />
          </Steps>
        </Col>
      </Row>
    );
  };

  return (
    <>
      <h2>프로젝트총괄</h2>

      <Form
        className="hl-form"
        layout="inline"
        style={{ marginBottom: 7 }}
        onSubmit={onSubmitSearchForm}
      >
        <Row type="flex" justify="space-between">
          <Col>
            <Form.Item>
              {getFieldDecorator('keyword')(
                <Input.Search
                  placeholder="검색어"
                  allowClear={true}
                  style={{ width: 150 }}
                />
              )}
            </Form.Item>

            <Form.Item>
              {getFieldDecorator('year')(
                <InputNumber placeholder="년도" style={{ width: 100 }} />
              )}
            </Form.Item>

            <FIHQSelect
              form={props.form}
              options={{ initialValue: deptId }}
              width="180px"
              hide={props.hideDeptSelector}
              deptIds={props.deptIds}
              onChange={(value) => {
                localStorage.setItem(
                  `${menu}DefaultDeptId`,
                  value ? String(value) : ''
                );
              }}
            />

            <Form.Item>
              <FCStringCodeSelect
                form={props.form}
                name="state"
                allowClear={true}
                placeholder="진행상태"
                items={Object.values(OrderState)}
                style={{ width: '120px' }}
                options={{ initialValue: '진행' }}
              />
            </Form.Item>

            <Form.Item>
              <Button type="primary" htmlType="submit">
                검색
              </Button>
            </Form.Item>
          </Col>
          <Col>
            <Form.Item label="진척도보기">
              <InlineItem>
                {getFieldDecorator('progress', {
                  valuePropName: 'checked',
                  initialValue: progressView,
                })(<Switch onChange={onChangeViewType} />)}
              </InlineItem>
            </Form.Item>
          </Col>
        </Row>
      </Form>

      <Table
        columns={columns}
        dataSource={items}
        rowKey="id"
        pagination={pagination}
        onChange={onChangeTable}
        bordered={true}
        loading={loading}
        size="middle"
        scroll={{ x: 'max-content' }}
      />

      <Drawer
        closable={false}
        placement="right"
        width={700}
        visible={detailVisible}
        onClose={onCloseDetail}
        destroyOnClose={true}
      >
        <OrderStagesDate
          order={currentItem}
          stages={stages}
          onChange={onChangeOrderDetail}
        />
      </Drawer>
    </>
  );
};

const _Dashboard = Form.create<Props>()(Dashboard);
export default withRouter(_Dashboard);
