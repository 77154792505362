import React, { useState, useEffect } from 'react';
import { ContractPay } from '../../../models/Order';
import OrderService from '../../../services/OrderService';
import Util from '../../../helpers/Util';
import { Table } from 'antd';
import LinkButton from '../../../components/general/LinkButton';

interface Props {
  orderId: number;
  out?: boolean;
  terms: string[];
  refreshTrigger: number;
  onClickItem?: (item: ContractPay) => void;
}

const ContractPayLog: React.FC<Props> = props => {
  const { orderId, out = false, terms, refreshTrigger } = props;
  const [loading, setLoading] = useState(false);
  const [items, setItems] = useState<ContractPay[]>([]);

  useEffect(() => {
    const fetchDetail = async () => {
      setLoading(true);
      try {
        const result = await OrderService.fetchContractPays(orderId, out);
        const pays = ContractPay.fromJsonArray(result.contract_pays);

        pays.forEach(e => {
          const index = terms.findIndex(term => term === e.term);
          e.index = index + 1;
        });

        setItems(pays);
      } catch (error) {
        Util.showError(error);
      } finally {
        setLoading(false);
      }
    };

    fetchDetail();
  }, [orderId, out, terms, refreshTrigger]);

  const columns = [
    {
      title: '업체명',
      dataIndex: 'company_name',
      sorter: (a: ContractPay, b: ContractPay) =>
        a.company_name > b.company_name ? 1 : -1
    },
    {
      title: '구분',
      dataIndex: 'term',
      sorter: (a: ContractPay, b: ContractPay) => (a.index > b.index ? 1 : -1),
      render: (text: string, record: ContractPay) => {
        return (
          <LinkButton onClick={e => onClickItem(record)}>
            {record.index}. {text}
          </LinkButton>
        );
      }
    },
    {
      title: out ? '지급일' : '수금일',
      dataInex: 'pay_date',
      width: '120px',
      sorter: (a: ContractPay, b: ContractPay) =>
        Util.compareMoments(a.pay_date, b.pay_date),
      defaultSortOrder: 'descend' as 'descend',
      render: (text: string, record: ContractPay) =>
        Util.formatDate(record.pay_date)
    },
    // {
    //   title: '과세',
    //   dataIndex: 'f_tax_pay',
    //   align: 'right' as 'right'
    // },
    // {
    //   title: '면세',
    //   dataIndex: 'f_notax_pay',
    //   align: 'right' as 'right'
    // },
    // {
    //   title: '부가세',
    //   dataIndex: 'f_vat',
    //   align: 'right' as 'right'
    // },
    {
      title: out ? '지급액' : '수금액',
      dataIndex: 'f_total_pay',
      align: 'right' as 'right'
    }
  ];

  const onClickItem = (item: ContractPay) => {
    if (props.onClickItem) props.onClickItem(item);
  };

  console.count('ContractPayLog');

  return (
    <Table
      columns={columns}
      dataSource={items}
      rowKey="id"
      pagination={false}
      bordered={true}
      loading={loading}
      size="middle"
    />
  );
};

export default ContractPayLog;
