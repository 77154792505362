import React from 'react';
import { Timeline, Empty } from 'antd';
import { EAFlowItem, EAFlowItemType, EAFlowItemAction } from '../../models/EAS';
import Util from '../../helpers/Util';

interface Props {
  items: EAFlowItem[];
  currentUserId?: number;
}

const EAFlowView: React.FC<Props> = props => {
  const { items, currentUserId } = props;

  const renderItem = (item: EAFlowItem) => {
    // 결재가 아닐경우 타입명 표시 (기안, 합의)
    const typeName =
      item.type !== EAFlowItemType.approve ? `(${item.typeName})` : '';

    let color = 'blue';
    let extraInfo = <></>;
    if (Util.isDefined(item.updatedAt)) {
      color = item.action === EAFlowItemAction.approve ? 'green' : 'red';
      extraInfo = (
        <>
          <br />
          <span style={{ fontSize: '0.9em', color: '#888' }}>
            {Util.formatDateTimeHHMM(item.updatedAt)}
          </span>
        </>
      );
    }

    let displayName = <>{item.user.nameWithRank + ' ' + typeName}</>;
    if (currentUserId && currentUserId === item.user.id) {
      displayName = <strong>{displayName}</strong>;
    }

    return (
      <Timeline.Item key={item.id} color={color}>
        {displayName}
        {extraInfo}
      </Timeline.Item>
    );
  };

  return (
    <>
      {items.length === 0 ? (
        <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />
      ) : (
        <Timeline>{items.map(item => renderItem(item))}</Timeline>
      )}
    </>
  );
};

export default EAFlowView;
