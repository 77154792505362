import { observable, action, reaction, computed } from 'mobx';
import axios from 'axios';
import { User } from '../models/User';
import { API_ROOT } from '../config';

export class AuthStore {
  @observable token = window.localStorage.getItem('token');
  @observable user: User = new User();

  constructor() {
    reaction(
      () => this.token,
      (token) => {
        this.syncAxioHeaderToken();
        if (token) {
          window.localStorage.setItem('token', token);
        } else {
          window.localStorage.removeItem('token');
        }
      }
    );
    reaction(
      () => this.user,
      (user) => {
        User.currentUserId = user.id;
      }
    );
  }

  @computed get userLoggedIn(): boolean {
    return this.user.id > 0;
  }

  @action async verifyUser() {
    const { data: result } = await axios(API_ROOT + '/users/me');
    this.user = User.fromJson(result.user);
  }

  @action async login(email: string, password: string) {
    const config = { headers: { 'Content-Type': 'application/json' } };
    const body = JSON.stringify({ email, password });
    const { data: result } = await axios.post(API_ROOT + '/auth', body, config);
    this.token = result.token;
    this.user = User.fromJson(result.user);
  }

  @action logout() {
    this.token = '';
    this.user = new User();
  }

  syncAxioHeaderToken() {
    if (this.token) {
      axios.defaults.headers.common['X-Auth-Token'] = this.token;
    } else {
      delete axios.defaults.headers.common['X-Auth-Token'];
    }
  }
}

export default new AuthStore();
