import React, { useState, useEffect } from 'react';
import { Tree } from 'antd';
import { Archive } from '../../models/File';
const { TreeNode } = Tree;

type TreeNode = {
  id?: number;
  name: string;
  path: string;
  parentId?: number;
  archiveIds?: number[];
  children?: TreeNode[];
};

interface Props {
  archives: Archive[];
  onSelectItem?: (archive_ids: number[]) => void;
}

const ArchiveTree: React.FC<Props> = props => {
  const { archives } = props;
  const [nodes, setNodes] = useState<TreeNode[]>([]); // node 아이템 배열
  const [treeNodes, setTreeNodes] = useState<TreeNode[]>([]); // 트리 렌더링을 위한 뷰모델

  useEffect(() => {
    let itemId = 1;
    let treeNodes: TreeNode[] = [];
    let parentId = 0;

    // makeFlatItems:
    // - { 'A', 'B', 'C' } 와 같은 형태의 배열을 받아서
    // - [ { id:1, name:'A', parent:0 }, .... ] 과 같은 형태로 변환
    // arrayToTree에 전달하기 위한 선행작업
    const makeFlatItems = (arr: string[], archiveId: number) => {
      let nodes: TreeNode[] = arr.map(name => {
        return { name, path: '' };
      });

      let path = '';
      nodes = nodes.reduce(
        (result, current) => {
          path += current.name + '/';
          current.path = path;
          result.push(current);
          return result;
        },
        [] as TreeNode[]
      );

      nodes.forEach((item, index) => {
        const existingItem = treeNodes.find(
          treeNode => treeNode.name === item.name && treeNode.path === item.path
        );
        if (existingItem) {
          parentId = existingItem.id!;
          existingItem.archiveIds!.push(archiveId);
        } else {
          const obj: TreeNode = {
            id: itemId,
            name: item.name,
            path: item.path,
            parentId: index === 0 ? undefined : parentId,
            archiveIds: [archiveId]
          };
          treeNodes.push(obj);
          parentId = itemId;
          itemId += 1;
        }
      });
    };

    //   makeFlatItems(['A', 'B', 'C']);
    //   makeFlatItems(['A', 'D', 'C']);
    //   makeFlatItems(['A', 'X', 'Y']);
    archives.forEach(archive =>
      makeFlatItems(archive.name.split('/'), archive.id)
    );

    treeNodes.forEach(current => {
      if (current.parentId) {
        const parentNode = treeNodes.find(
          eachNode => eachNode.id === current.parentId
        );
        if (parentNode) {
          if (parentNode.children) {
            parentNode.children.push(current);
          } else {
            parentNode.children = [current];
          }
        }
      }
    });

    setNodes(treeNodes);
    setTreeNodes(treeNodes.filter(item => item.parentId === undefined));
  }, [archives]);

  console.count('ArchiveTree');

  const onSelectTreeNode = (selectedKeys: string[]) => {
    const treeNode = nodes.find(node => String(node.id) === selectedKeys[0]);
    if (props.onSelectItem && treeNode && treeNode.archiveIds) {
      props.onSelectItem(treeNode.archiveIds);
    }
  };

  const renderTreeNode = (node: TreeNode) => {
    return (
      <TreeNode title={node.name} key={String(node.id)}>
        {node.children &&
          node.children.map(treeNode => renderTreeNode(treeNode))}
      </TreeNode>
    );
  };

  return (
    <div className="hl-form" style={{ backgroundColor: 'white' }}>
      {nodes.length > 0 && (
        <Tree
          showLine
          defaultExpandAll={true}
          //   selectedKeys={[String(nodes[0].id)]}
          onSelect={onSelectTreeNode}
        >
          {treeNodes.map(node => renderTreeNode(node))}
        </Tree>
      )}
    </div>
  );
};

export default ArchiveTree;
