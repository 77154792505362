import { User } from './User';
import userStore from '../stores/userStore';
import authStore from '../stores/authStore';
import { File } from './File';

export class Post {
  id = 0;
  type = 0;
  ref_id?: number;
  title = '';
  owner_id = 0;
  content = '';
  read_count = 0;
  comment_count = 0;
  pinned = false;
  created_at = '';
  files: File[] = [];

  // derived
  [key: string]: any; // for string index (subscript)
  name = ''; // from users
  owner: User = new User();

  static fromJsonArray(sources: any[]) {
    return sources.map(source => Post.fromJson(source));
  }

  static fromJson(source: any) {
    let item = new Post();
    for (const key of Object.keys(source)) {
      if (key in item) {
        item[key] = source[key];
      }
    }

    item.owner = userStore.findById(source.owner_id);

    if (source.files) {
      item.files = File.fromJsonArray(source.files);
    }

    return item;
  }

  get hasRightToEdit() {
    return this.owner.isMe || authStore.user.isAdmin;
  }

  static getTypeName(type: number, refId?: number) {
    switch (type) {
      case 0:
        return '결재문서 템플릿';
      case 1:
        return refId ? '본부공지' : '회사공지';
      case 2:
        return refId ? '본부회람' : '회사회람';
      // case 3:
      //   return '법인관리';
      case 4:
        return '계약관리';
      case 5:
        return '지명원 자료실';
      case 6:
        return '업무경비';
      case 7:
        return '전도금';
      case 8:
        return '기타 일반관리비';
      case 9:
        return '사규관리';
      case 10:
        return '문서관리';
      case 9999:
        return '시스템 업데이트 내역';
      default:
        return '게시판';
    }
  }

  static getTypePath(type: number) {
    switch (type) {
      case 0:
        return '/management/support/eas-templates';
      case 1:
      case 2:
        return '/works/board/posts';
      // case 3:
      case 4:
      case 5:
      case 6:
      case 7:
      case 8:
      case 9:
      case 10:
        return '/management/support/posts';
      default:
        return '/';
    }
  }
}
