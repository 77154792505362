import React, { useState, useEffect } from 'react';
import Form, { FormComponentProps } from 'antd/lib/form';
import { Button, Input, Popconfirm } from 'antd';
import { ContractItem, ContractPay } from '../../../models/Order';
import FCTextInput from '../../../components/formcomps/FCTextInput';
import FCNumberInput from '../../../components/formcomps/FCNumberInput';
import moment from 'moment';
import Util from '../../../helpers/Util';
import OrderService from '../../../services/OrderService';
import FIDatePicker from '../../../components/formcomps/FIDatePicker';

interface Props extends FormComponentProps {
  companyName: string;
  out?: boolean;
  contractItem?: ContractItem; // 등록
  contractPay?: ContractPay; // 수정
  onSuccess?: () => void;
}

const ContractPayEditor: React.FC<Props> = props => {
  const { companyName, out = false, contractItem, contractPay } = props;
  const { getFieldDecorator, setFieldsValue } = props.form;
  const [initialPayDate, setInitialPayDate] = useState('');

  console.count('ContractPayEditor');

  useEffect(() => {
    if (contractItem) {
      setFieldsValue({
        contract_id: contractItem.contract_id,
        term: contractItem.term,
        pay_date: moment(),
        // tax_pay: contractItem.remain_tax_price,
        // notax_pay: contractItem.remain_notax_price,
        total_pay: contractItem.remain_total_price,
        note: contractItem.note
      });
      if (contractItem.est_date) {
        // 예정일이 있으면 예정일로 디폴트 설정
        setInitialPayDate(Util.formatDate(contractItem.est_date));
      } else {
        // 없으면 오늘 날짜로 설정
        setInitialPayDate(Util.formatDate(moment()));
      }
    } else if (contractPay) {
      setFieldsValue({
        contract_id: contractPay.contract_id,
        term: contractPay.term,
        pay_date: contractPay.pay_date,
        // tax_pay: contractPay.tax_pay,
        // notax_pay: contractPay.notax_pay,
        total_pay: contractPay.total_pay,
        note: contractPay.note
      });
      contractPay.pay_date &&
        setInitialPayDate(Util.formatDate(contractPay.pay_date));
    }
  }, [contractItem, contractPay, setFieldsValue]);

  const onSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    props.form.validateFields((err, values) => {
      if (err) return;
      processItem(values);
    });
  };

  const [submitting, setSubmitting] = useState(false);

  const processItem = async (values: any) => {
    try {
      setSubmitting(true);

      if (contractItem) {
        // 추가
        await OrderService.createContractPay(values);
      } else if (contractPay) {
        // 수정
        await OrderService.updateContractPay(contractPay.id, values);
      }

      Util.showSuccess('저장되었습니다.');

      if (props.onSuccess) props.onSuccess();
    } catch (err) {
      Util.showError(err);
    } finally {
      setSubmitting(false);
    }
  };

  const onClickDelete = async () => {
    if (contractPay === undefined) return;

    try {
      setSubmitting(true);
      await OrderService.deleteContractPay(contractPay.id);
      Util.showSuccess('삭제되었습니다.');

      if (props.onSuccess) props.onSuccess();
    } catch (err) {
      Util.showError(err);
    } finally {
      setSubmitting(false);
    }
  };

  const formItemLayout = {
    labelCol: { span: 4 },
    wrapperCol: { span: 19 }
  };

  return (
    <>
      <Form {...formItemLayout} onSubmit={onSubmit}>
        <div className="hl-form" style={{ marginBottom: '10px' }}>
          {getFieldDecorator('contract_id')(<Input type="hidden" />)}

          <Form.Item label="업체명">
            <Input value={companyName} disabled />
          </Form.Item>
          <Form.Item label="구분">
            <FCTextInput form={props.form} name="term" disabled />
          </Form.Item>
          <Form.Item label={out ? '지급일' : '수금일'}>
            <FIDatePicker
              form={props.form}
              name="pay_date"
              initialValue={initialPayDate}
            />
          </Form.Item>
          {/* <Form.Item label={out ? '지급금액 (과세)' : '수금금액 (과세)'}>
            <FCNumberInput form={props.form} name="tax_pay" />
          </Form.Item>
          <Form.Item label={out ? '지급금액 (면세)' : '수금금액 (면세)'}>
            <FCNumberInput form={props.form} name="notax_pay" />
          </Form.Item> */}
          <Form.Item label={out ? '지급금액 (합계)' : '수금금액 (합계)'}>
            <FCNumberInput form={props.form} name="total_pay" />
          </Form.Item>
          <Form.Item label="비고">
            <FCTextInput form={props.form} name="note" />
          </Form.Item>
          <Form.Item wrapperCol={{ span: 12, offset: 4 }}>
            <Button type="primary" htmlType="submit" disabled={submitting}>
              {contractPay ? '수정' : '등록'}
            </Button>{' '}
            {contractPay && (
              <Popconfirm
                title="정말 삭제하시겠습니까?"
                onConfirm={e => onClickDelete()}
              >
                <Button type="danger" disabled={submitting}>
                  삭제
                </Button>
              </Popconfirm>
            )}
          </Form.Item>
        </div>
      </Form>
    </>
  );
};

export default Form.create<Props>()(ContractPayEditor);
