import React, { useState, useEffect } from 'react';
import { Table } from 'antd';
import { DailyReportItem, DepositItem } from '../../../models/Finance';
import _ from 'lodash';

interface Props {
  item?: DailyReportItem;
  loading?: boolean;
}

const DailyDepositTable: React.FC<Props> = props => {
  const [cashItems, setCashItems] = useState<DepositItem[]>([]);
  const [bankItems, setBankItems] = useState<DepositItem[]>([]);
  const [extraItems, setExtraItems] = useState<DepositItem[]>([]);

  useEffect(() => {
    // console.log(props.item);
    if (props.item) {
      const cashItems = props.item.items.filter(e => e.type === 1);
      const cashItem =
        (cashItems && cashItems.length > 0 && cashItems[0]) ||
        new DepositItem();
      cashItem.title = '① 현 금';
      setCashItems([cashItem]);

      const bankItems = props.item.items.filter(e => e.type === 0);

      let sumItem1 = new DepositItem();
      sumItem1.title = '② 보통예금 소계';
      sumItem1.titleSpan = true;
      sumItem1.amount_prev = _.sumBy(bankItems, e => e.amount_prev);
      sumItem1.amount_in = _.sumBy(bankItems, e => e.amount_in);
      sumItem1.amount_out = _.sumBy(bankItems, e => e.amount_out);
      sumItem1.amount_rest = _.sumBy(bankItems, e => e.amount_rest);
      sumItem1.amount_etc = _.sumBy(bankItems, e => e.amount_etc);

      let sumItem2 = new DepositItem();
      sumItem2.title = '합 계 ① + ②';
      sumItem2.titleSpan = true;
      sumItem2.summary = true;
      sumItem2.amount_prev = cashItem.amount_prev + sumItem1.amount_prev;
      sumItem2.amount_in = cashItem.amount_in + sumItem1.amount_in;
      sumItem2.amount_out = cashItem.amount_out + sumItem1.amount_out;
      sumItem2.amount_rest = cashItem.amount_rest + sumItem1.amount_rest;
      sumItem2.amount_etc = cashItem.amount_etc + sumItem1.amount_etc;

      setBankItems([...bankItems, sumItem1, sumItem2]);

      const credit = (props.item.corp && props.item.corp.credit) || 0;
      let sumItem3 = new DepositItem();
      sumItem3.text_out = '지급가능액';
      sumItem3.amount_rest = sumItem2.amount_rest + Number(credit);
      setExtraItems([sumItem3]);
    } else {
      const cashItem = new DepositItem();
      cashItem.title = '① 현 금';
      setCashItems([cashItem]);

      let sumItem1 = new DepositItem();
      sumItem1.title = '② 보통예금 소계';
      sumItem1.titleSpan = true;

      let sumItem2 = new DepositItem();
      sumItem2.title = '합 계 ① + ②';
      sumItem2.titleSpan = true;
      sumItem2.summary = true;

      setBankItems([sumItem1, sumItem2]);

      let sumItem3 = new DepositItem();
      sumItem3.text_out = '지급가능액';
      setExtraItems([sumItem3]);
    }
  }, [props.item]);

  const columns1 = [
    {
      title: '구 분',
      dataIndex: 'title',
      align: 'center' as 'center',
      width: 150
    },
    {
      title: '전일 잔액',
      dataIndex: 'f_amount_prev',
      align: 'right' as 'right',
      width: 50
    },
    {
      title: '입금액',
      dataIndex: 'f_amount_in',
      align: 'right' as 'right',
      width: 50
    },
    {
      title: '출금액',
      dataIndex: 'f_amount_out',
      align: 'right' as 'right',
      width: 50
    },
    {
      title: '금일 잔액',
      dataIndex: 'f_amount_rest',
      align: 'right' as 'right',
      width: 50
    },
    {
      title: '비고',
      dataIndex: 'f_amount_etc',
      align: 'right' as 'right',
      width: 50
    }
  ];

  const columns2 = [
    {
      title: '은행명',
      dataIndex: 'title',
      align: 'center' as 'center',
      width: 40,
      render: (text: string, record: any) => {
        if (record.titleSpan) return { props: { colSpan: 3 }, children: text };
        return text;
      }
    },
    {
      title: '계좌명',
      dataIndex: 'subtitle',
      align: 'center' as 'center',
      width: 40,
      render: (text: string, record: any) => {
        if (record.titleSpan) return { props: { colSpan: 0 } };
        return text;
      }
    },
    {
      title: '계좌번호',
      dataIndex: 'account_no',
      align: 'center' as 'center',
      width: 70,
      render: (text: string, record: any) => {
        if (record.titleSpan) return { props: { colSpan: 0 } };
        return text;
      }
    },
    {
      title: '전일 잔액',
      dataIndex: 'f_amount_prev',
      align: 'right' as 'right',
      width: 50
    },
    {
      title: '입금액',
      dataIndex: 'f_amount_in',
      align: 'right' as 'right',
      width: 50
    },
    {
      title: '출금액',
      dataIndex: 'f_amount_out',
      align: 'right' as 'right',
      width: 50
    },
    {
      title: '금일 잔액',
      dataIndex: 'f_amount_rest',
      align: 'right' as 'right',
      width: 50
    },
    {
      title: '비고',
      dataIndex: 'f_amount_etc',
      align: 'right' as 'right',
      width: 50
    }
  ];

  const columns3 = [
    {
      dataIndex: 'title',
      align: 'right' as 'right',
      width: 40 + 40 + 70 + 50 + 50 + 50,
      render: (text: string, record: any) => {
        return <b>지급가능액</b>;
      }
    },
    {
      dataIndex: 'f_amount_rest',
      align: 'right' as 'right',
      width: 50
    },
    {
      title: '',
      width: 50
    }
  ];

  return (
    <>
      <Table
        columns={columns1}
        dataSource={cashItems}
        pagination={false}
        rowKey="id"
        bordered={true}
        size="middle"
        style={{ marginBottom: '5px' }}
      />
      <Table
        columns={columns2}
        dataSource={bankItems}
        pagination={false}
        rowKey="id"
        rowClassName={record => {
          let className = '';
          if (record.summary) className += ' hl-bg-gray';
          return className;
        }}
        loading={props.loading}
        bordered={true}
        size="middle"
        style={{ marginBottom: '5px' }}
      />
      <Table
        columns={columns3}
        dataSource={extraItems}
        pagination={false}
        rowKey="id"
        bordered={true}
        size="middle"
        showHeader={false}
      />
    </>
  );
};

export default DailyDepositTable;
