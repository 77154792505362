import React, { useState, useEffect, useRef } from 'react';
import {
  Table,
  Button,
  Row,
  Col,
  Icon,
  Input,
  Form,
  Drawer,
  Popconfirm,
  InputNumber,
  Select
} from 'antd';
import LinkButton from '../../components/general/LinkButton';
import { FormComponentProps } from 'antd/lib/form';
import EducationEdit from './EducationEdit';
import { PaginationConfig } from 'antd/lib/table';
import { Education } from '../../models/Education';
import EducationService, {
  EducationSearchOption
} from '../../services/EducationService';
import Util from '../../helpers/Util';
import AButton from '../../components/general/AButton';
import EducationDetail from './EducationDetail';

interface Props extends FormComponentProps {}

const EducationList: React.FC<Props> = props => {
  const [loading, setLoading] = useState(false);
  const [totalCount, setTotalCount] = useState(0);
  const [currentPage, setCurrentPage] = useState(1);
  const [refreshTrigger, setRefreshTrigger] = useState(1);
  const [searchOption, setSearchOption] = useState<EducationSearchOption>({});
  const [items, setItems] = useState<Education[]>([]);
  const { getFieldDecorator } = props.form;

  const pagination = {
    pageSize: 15,
    current: currentPage,
    total: totalCount,
    showTotal: (total: number, range: [number, number]) =>
      `전체 ${total}건 중 ${range[0]}-${range[1]}`
  };

  useEffect(() => {
    const fetchItems = async () => {
      setLoading(true);
      try {
        const result = await EducationService.fetchItems(
          pagination.pageSize,
          currentPage,
          searchOption
        );
        setTotalCount(result.total_count);
        setItems(Education.fromJsonArray(result.items));
      } catch (error) {
        Util.showError(error);
      } finally {
        setLoading(false);
      }
    };
    fetchItems();
  }, [currentPage, pagination.pageSize, searchOption, refreshTrigger]);

  const columns = [
    {
      title: '번호',
      dataIndex: 'id',
      align: 'right' as 'right',
      width: 60
    },
    {
      title: '분야',
      dataIndex: 'category',
      width: 60
    },
    {
      title: '교육명',
      dataIndex: 'title',
      render: (text: string, record: Education) => (
        <AButton onClick={e => onClickItem(record)}>{record.title}</AButton>
      )
    },
    {
      title: '교육일자',
      dataIndex: 'date',
      width: 150,
      render: (text: string, record: Education) => Util.formatDateTimeHHMM(text)
    },
    {
      title: '신청인원',
      align: 'right' as 'right',
      width: 100,
      render: (text: string, record: Education) => {
        return `${record.apply_count} / ${record.seat_count}`;
      }
    },
    {
      title: '출결',
      align: 'right' as 'right',
      width: 100,
      render: (text: string, record: Education) => {
        return `${record.attend_count} / ${record.apply_count}`;
      }
    },
    {
      title: '설문조사',
      dataIndex: 'poll',
      width: 100,
      render: (text: string, record: Education) => {
        return 'N/A';
      }
    },
    {
      title: '설문점수',
      dataIndex: 'score',
      align: 'right' as 'right',
      width: 80,
      render: (text: string, record: Education) => {
        return 'N/A';
      }
    },
    {
      title: '상태',
      dataIndex: 'state',
      width: 80,
      render: (text: string, record: Education) => {
        return (
          <Select
            style={{ width: '70px' }}
            defaultValue={record.finished ? 0 : 1}
            onChange={(value: number) => onChangeState(record, value)}
          >
            <Select.Option value={1}>진행</Select.Option>
            <Select.Option value={0}>종료</Select.Option>
          </Select>
        );
      }
    },
    {
      width: '90px',
      render: (text: string, record: Education) => {
        return (
          <>
            <LinkButton onClick={e => onClickUpdateButton(record)}>
              수정
            </LinkButton>{' '}
            |{' '}
            <Popconfirm
              title="정말 삭제하시겠습니까?"
              onConfirm={e => onClickDeleteButton(record)}
            >
              <LinkButton>삭제</LinkButton>
            </Popconfirm>
          </>
        );
      }
    }
  ];

  const onChangeState = async (item: Education, value: number) => {
    try {
      await EducationService.update(item.id, { finished: value === 0 });
      Util.showSuccess('반영되었습니다.');
    } catch (err) {
      Util.showError(err);
    }
  };

  const onChangeTable = (pagination: PaginationConfig) => {
    setCurrentPage(pagination.current || 1);
  };

  const onSubmitSearchForm = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    props.form.validateFields((err, values) => {
      if (err) return;
      setSearchOption(values);
      setCurrentPage(1);
    });
  };

  const itemRef = useRef<Education | undefined>(undefined);
  const itemDirtyRef = useRef(false);
  const [editorVisible, setEditorVisible] = useState(false);
  const [detailVisible, setDetailVisible] = useState(false);

  const onClickAddButton = () => {
    itemRef.current = undefined;
    setEditorVisible(true);
  };

  const onClickUpdateButton = async (item: Education) => {
    itemRef.current = item;
    setEditorVisible(true);
  };

  const onCloseEditor = () => {
    setEditorVisible(false);
  };

  const onSuccessEditing = () => {
    setRefreshTrigger(v => v + 1);
    setEditorVisible(false);
  };

  const onClickDeleteButton = async (item: Education) => {
    try {
      await EducationService.delete(item.id);
      Util.showSuccess('삭제되었습니다.');
      setRefreshTrigger(v => v + 1);
    } catch (err) {
      Util.showError(err);
    }
  };

  const onClickItem = (item: Education) => {
    itemRef.current = item;
    setDetailVisible(true);
  };

  const onCloseDetail = () => {
    setDetailVisible(false);
    if (itemDirtyRef.current) {
      itemDirtyRef.current = false;
      setRefreshTrigger(v => v + 1);
    }
  };

  const onChangeDetail = () => {
    itemDirtyRef.current = true;
  };

  return (
    <>
      <h2>교육관리</h2>

      <Form
        className="hl-form"
        layout="inline"
        style={{ marginBottom: 7 }}
        onSubmit={onSubmitSearchForm}
      >
        <Row type="flex" justify="space-between">
          <Col>
            <Form.Item>
              {getFieldDecorator('keyword')(
                <Input.Search
                  placeholder="검색어"
                  allowClear={true}
                  style={{ width: 150 }}
                />
              )}
            </Form.Item>

            <Form.Item>
              {getFieldDecorator('year')(
                <InputNumber placeholder="년도" style={{ width: 100 }} />
              )}
            </Form.Item>

            <Form.Item>
              <Button type="primary" htmlType="submit">
                검색
              </Button>
            </Form.Item>
          </Col>

          <Col>
            <Button onClick={onClickAddButton}>
              <Icon type="plus" />
              교육 등록
            </Button>
          </Col>
        </Row>
      </Form>

      <Table
        columns={columns}
        dataSource={items}
        pagination={pagination}
        onChange={onChangeTable}
        rowKey="id"
        loading={loading}
        bordered={true}
        size="middle"
      />

      <Drawer
        closable={false}
        placement="right"
        width={1000}
        visible={editorVisible}
        onClose={onCloseEditor}
        destroyOnClose={true}
      >
        <EducationEdit
          id={itemRef.current ? itemRef.current.id : undefined}
          onSuccess={onSuccessEditing}
        />
      </Drawer>

      <Drawer
        closable={false}
        placement="right"
        width="85%"
        visible={detailVisible}
        onClose={onCloseDetail}
        destroyOnClose={true}
      >
        <EducationDetail
          id={(itemRef.current && itemRef.current.id) || 0}
          fetchDelayed
          onChange={onChangeDetail}
        />
      </Drawer>
    </>
  );
};

export default Form.create<Props>()(EducationList);
