import React, { useState } from 'react';
import { Row, Col, Button, Form } from 'antd';
import OrderEditor from './OrderEditor';
import { FormComponentProps } from 'antd/lib/form';
import Util from '../../../helpers/Util';
import _ from 'lodash';
import OrderService from '../../../services/OrderService';
import { Order } from '../../../models/Order';

interface Props extends FormComponentProps {
  title: string;
  id: number;
  onSave?: (order: Order) => void;
}

const OrderEdit: React.FC<Props> = props => {
  console.count('OrderEdit');

  const [submitting, setSubmitting] = useState(false);

  const onClickSubmitButton = () => {
    props.form.validateFields((err, values) => {
      if (err) return;
      updateItem(values);
    });
  };

  const updateItem = async (values: any) => {
    try {
      setSubmitting(true);

      let ordData: any = _.omit(values, [
        // 프로젝 정보 제거
        'project_id',
        'zipcode',
        'address',
        'site_area',
        'total_floor_area',
        'floor_level',
        'base_level',

        // 회사 및 부서 정보 제거 (업데이트 처리해도 이슈 없는지 확인 필요)
        'corp_id',
        'dept_id',

        // 가공필요한 필드 제거
        'leaders', // leader_ids로 처리
        'managers', // manager_ids로 처리
        'use1',
        'use2',
        'use3',
        'region',
        'districts',
        'areas'
      ]);
      ordData.use1 = values.use1.join('/');
      ordData.use2 = values.use2.join('/');
      ordData.use3 = values.use3.join('/');
      ordData.region = values.region.join(',');
      ordData.districts = values.districts.join(',');
      ordData.areas = values.areas.join(',');

      // console.log('values:', values);
      // console.log(ordData);

      const result = await OrderService.updateAndFetch(props.id, ordData);
      const updatedOrder = Order.fromJson(result.order);
      Util.showSuccess('수정되었습니다.');
      setSubmitting(false);

      props.onSave && props.onSave(updatedOrder);
    } catch (err) {
      setSubmitting(false);
      Util.showError(err);
    }
  };

  return (
    <>
      <h2>수주정보수정 - {props.title}</h2>
      <Row gutter={12}>
        <Col className="gutter-row" span={20}>
          <OrderEditor
            form={props.form}
            id={props.id}
            fetchDelayed
            restrictEditing
          />
        </Col>
        <Col className="gutter-row" span={4}>
          <Button
            block
            type="primary"
            htmlType="submit"
            disabled={submitting}
            onClick={onClickSubmitButton}
          >
            수정하기
          </Button>
        </Col>
      </Row>
    </>
  );
};

export default Form.create<Props>()(OrderEdit);
