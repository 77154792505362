import React, { useState } from 'react';
import {
  HDescriptions,
  HDRow,
  HDItem,
  HDRowItem,
} from '../../../components/general/HDescriptions';
import { Order } from '../../../models/Order';
import AButton from '../../../components/general/AButton';
import { Drawer } from 'antd';
import OrderView from './OrderView';

interface Props {
  order: Order;
  allowEditing?: boolean;
  onChangeOrderInfo?: (order: Order) => void;
}

const OrderSummaryPanel: React.FC<Props> = (props) => {
  const [order, setOrder] = useState<Order>(props.order);

  const [orderViewVisible, setOrderViewVisible] = useState(false);
  const onClickOrderName = () => {
    setOrderViewVisible(true);
  };

  const onCloseOrderView = () => {
    setOrderViewVisible(false);
  };

  const onChangeOrderInfo = async (updatedOrder: Order) => {
    setOrder(updatedOrder);
    props.onChangeOrderInfo && props.onChangeOrderInfo(updatedOrder);
  };

  return (
    <>
      <HDescriptions>
        <HDRow>
          <HDItem label="계약건명" colSpan={5}>
            <AButton onClick={onClickOrderName}>{order.name}</AButton>
          </HDItem>
          <HDItem label="수주번호">{order.id}</HDItem>
        </HDRow>
        <HDRow>
          <HDItem label="BOM">{order.bom}</HDItem>
          <HDItem label="발주처">{order.comp_name}</HDItem>
          <HDItem label="종류" small>
            {order.kind}
          </HDItem>
          <HDItem label="등급" small>
            {order.grade}
          </HDItem>
        </HDRow>
        <HDRow>
          <HDItem label="토지상태">{order.site_state}</HDItem>
          <HDItem label="대지면적" small>
            {order.project.f_site_area} ㎡
          </HDItem>
          <HDItem label="연면적" small>
            {order.project.f_total_floor_area} ㎡
          </HDItem>
          <HDItem label="토지가격(매매가액)">{order.f_price}</HDItem>
        </HDRow>
      </HDescriptions>

      <Drawer
        closable={false}
        placement="right"
        width="85%"
        visible={orderViewVisible}
        onClose={onCloseOrderView}
        destroyOnClose={true}
      >
        <OrderView
          id={order.id}
          title={order.name}
          allowEditing={props.allowEditing}
          onChangeOrderInfo={onChangeOrderInfo}
        />
      </Drawer>
    </>
  );
};

export default OrderSummaryPanel;
