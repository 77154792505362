import { MenuItem } from '../../../models/Common';

const menuItems: MenuItem[] = [
  {
    id: '프로젝트',
    title: '프로젝트',
    icon: 'desktop',
    children: [
      {
        id: 'projects',
        title: '프로젝트 관리',
        link: '/projects/list'
      },
      {
        id: 'eadocs',
        title: '수주정보보고',
        link: '/projects/eadocs/ord'
      },
      {
        id: 'orders',
        title: '수주심의',
        link: '/projects/orders'
      },
      {
        id: 'ongoing',
        title: '진행 프로젝트',
        link: '/projects/ledger?state=ongoing'
      },
      {
        id: 'pending',
        title: '보류 프로젝트',
        link: '/projects/ledger?state=pending'
      },
      {
        id: 'done',
        title: '완료 프로젝트',
        link: '/projects/ledger?state=done'
      },
      {
        id: 'forced',
        title: '강제완료 프로젝트',
        link: '/projects/ledger?state=forced'
      }
    ]
  },
  // {
  //   id: '수금',
  //   title: '수금',
  //   icon: 'desktop',
  //   children: [
  //     {
  //       id: 'collects',
  //       title: '수금현황',
  //       link: '/projects/collects'
  //     }
  //   ]
  // },
  {
    id: '설정',
    title: '설정',
    icon: 'desktop',
    children: [
      // {
      //   id: 'base',
      //   title: '기본 설정',
      //   link: '/projects/settings/base'
      // },
      {
        id: 'kinds',
        title: '프로젝트 종류',
        link: '/projects/settings/kinds'
      },
      {
        id: 'uses',
        title: '프로젝트 용도',
        link: '/projects/settings/uses'
      },
      {
        id: 'regions',
        title: '지역',
        link: '/projects/settings/regions'
      },
      {
        id: 'districts',
        title: '지구',
        link: '/projects/settings/districts'
      },
      {
        id: 'areas',
        title: '용도구역',
        link: '/projects/settings/areas'
      },
      {
        id: 'stages',
        title: '부서별 진행단계',
        link: '/projects/settings/stages'
      },
      {
        id: 'ctypes',
        title: '부서별 수금구분',
        link: '/projects/settings/ctypes'
      }
    ]
  }
];

export default menuItems;
