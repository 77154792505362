import React, { useState, useEffect } from 'react';
import { Row, Col, Input, Table, Tag } from 'antd';
import EAService, { EADocSearchOption } from '../../services/EAService';
import Util from '../../helpers/Util';
import { EADoc, EADocListType } from '../../models/EAS';
import { PaginationConfig } from 'antd/lib/table';
import LinkButton from '../../components/general/LinkButton';

interface Props {
  type: EADocListType;
  refTarget?: string;
  onChangeSelections: (items: EADoc[]) => void;
  onClickItem?: (item: EADoc) => void;
}

const EADocListForSelection: React.FC<Props> = props => {
  const [loading, setLoading] = useState(false);
  const [items, setItems] = useState<EADoc[]>([]);
  const [totalCount, setTotalCount] = useState(0);
  const [currentPage, setCurrentPage] = useState(1);
  const [searchOption, setSearchOption] = useState<EADocSearchOption>({});

  const pagination = {
    pageSize: 15,
    current: currentPage,
    total: totalCount,
    showTotal: (total: number, range: [number, number]) =>
      `전체 ${total}건 중 ${range[0]}-${range[1]}`
  };

  useEffect(() => {
    const fetchItems = async () => {
      setLoading(true);
      try {
        const result = await EAService.fetchItems(
          pagination.pageSize,
          currentPage,
          { ...searchOption, type: props.type, ref_target: props.refTarget }
        );
        setTotalCount(result.total_count);
        setItems(EADoc.fromJsonArray(result.items));
      } catch (error) {
        Util.showError(error);
      } finally {
        setLoading(false);
      }
    };

    fetchItems();
  }, [
    currentPage,
    searchOption,
    pagination.pageSize,
    props.type,
    props.refTarget
  ]);

  const onClickItem = (item: EADoc) => {
    if (props.onClickItem) {
      props.onClickItem(item);
    }
  };

  const onSearch = (keyword: string) => {
    setSearchOption({ ...searchOption, keyword });
    setCurrentPage(1);
  };

  const onChangeTable = (pagination: PaginationConfig) => {
    setCurrentPage(pagination.current || 1);
  };

  // rowSelection object indicates the need for row selection
  const rowSelection = {
    onChange: (selectedRowKeys: any, selectedRows: any) => {
      props.onChangeSelections(selectedRows);
    }
  };

  const columns = [
    {
      title: '제목',
      dataIndex: 'title',
      render: (text: string, record: EADoc) => {
        return (
          <LinkButton onClick={e => onClickItem(record)}>{text}</LinkButton>
        );
      }
    },
    {
      title: '기안자',
      dataIndex: 'owner.name'
    },
    {
      title: '상태',
      dataIndex: 'state',
      render: (text: string, record: EADoc) => {
        return <Tag color={record.stateColor}>{record.stateName}</Tag>;
      }
    },
    {
      title: '작성일',
      dataIndex: 'createdAt',
      render: (text: string, record: EADoc) => {
        return Util.formatDate(text);
      }
    },
    {
      title: '최종업데이트',
      dataIndex: 'updatedAt',
      render: (text: string, record: EADoc) => {
        return Util.formatDateTimeHHMM(text);
      }
    }
  ];

  return (
    <>
      <Row type="flex" justify="space-between" style={{ marginBottom: 10 }}>
        <Col>
          <Input.Search
            placeholder="제목 또는 기안자명"
            allowClear={true}
            onSearch={onSearch}
            style={{ width: 200 }}
          />
        </Col>
      </Row>

      <Table
        rowSelection={rowSelection}
        columns={columns}
        dataSource={items}
        rowKey="id"
        pagination={pagination}
        onChange={onChangeTable}
        bordered={true}
        loading={loading}
        size="middle"
      />
    </>
  );
};

export default EADocListForSelection;
