import React, { useState, useEffect, useRef } from 'react';
import { Table, Tag, Drawer, Row, Col, Select } from 'antd';
import { withRouter, RouteComponentProps } from 'react-router';
import Util from '../../helpers/Util';
import { Activity } from '../../models/Activity';
import AppService from '../../services/AppService';
import { PaginationConfig } from 'antd/lib/table';
import LinkButton from '../../components/general/LinkButton';
import EADocDetail from '../eas/EADocDetail';
import { SelectValue } from 'antd/lib/select';

interface Props extends RouteComponentProps {
  //   type?: number;
  //   page?: number;
  onSelectItem?: (activity: Activity) => void;
}

const NotificationList: React.FC<Props> = props => {
  //   const { type = 0, page = 1 } = props;
  const params = new URLSearchParams(props.location.search);
  const page = Number(params.get('page')) || 1;
  const type = Number(params.get('type')) || 0;

  const [loading, setLoading] = useState(false);
  const [items, setItems] = useState<Activity[]>([]);
  const [totalCount, setTotalCount] = useState(0);

  const pagination = {
    pageSize: 15,
    current: page,
    total: totalCount,
    showTotal: (total: number, range: [number, number]) =>
      `전체 ${total}건 중 ${range[0]}-${range[1]}`
  };

  useEffect(() => {
    const fetchItems = async () => {
      setLoading(true);
      try {
        const result = await AppService.fetchNotifications(
          pagination.pageSize,
          page,
          type === 0 ? undefined : type
        );
        setTotalCount(result.total_count);
        setItems(Activity.fromJsonArray(result.items));
      } catch (error) {
        Util.showError(error);
      } finally {
        setLoading(false);
      }
    };

    fetchItems();
  }, [type, page, pagination.pageSize]);

  const onChangeTable = (pagination: PaginationConfig) => {
    props.history.push(
      `${props.location.pathname}?page=${pagination.current || 1}&type=${type}`
    );
  };

  const onChangeType = (value: SelectValue) => {
    props.history.push(`${props.location.pathname}?page=1&type=${value}`);
  };

  const itemIdRef = useRef<number>(0);
  const [detailVisible, setDetailVisible] = useState(false);

  const onClickItem = (item: Activity) => {
    itemIdRef.current = item.targetId;
    setDetailVisible(true);
    // props.history.push('/works/eas/view?id=' + item.targetId);
  };

  const onCloseDetail = () => {
    setDetailVisible(false);
  };

  const columns = [
    {
      title: 'No',
      align: 'right' as 'right',
      width: '80px',
      dataIndex: 'id'
    },
    {
      title: '게시자',
      width: '110px',
      dataIndex: 'user.name'
    },
    {
      title: '구분',
      width: '130px',
      dataIndex: 'type',
      render: (text: string, record: Activity) => {
        return <Tag color={record.typeColor}>{record.typeName}</Tag>;
      }
    },
    {
      title: '내용',
      dataIndex: 'content',
      ellipsis: true,
      render: (text: string, record: Activity) => {
        return (
          <LinkButton onClick={e => onClickItem(record)}>{text}</LinkButton>
        );
      }
    },
    {
      title: '등록일',
      dataIndex: 'createdAt',
      width: 180,
      render: (text: string, record: Activity) => Util.formatDateTime(text)
    }
  ];

  return (
    <>
      <h2>알림</h2>

      <Row type="flex" justify="space-between" style={{ marginBottom: 10 }}>
        <Col>
          <Select
            onChange={onChangeType}
            style={{ width: 100 }}
            defaultValue={type}
          >
            <Select.Option value={0}>전체</Select.Option>
            <Select.Option value={6}>의견</Select.Option>
          </Select>
        </Col>
        <Col></Col>
      </Row>

      <Table
        columns={columns}
        dataSource={items}
        rowKey="id"
        pagination={pagination}
        onChange={onChangeTable}
        bordered={true}
        loading={loading}
        size="middle"
      />

      <Drawer
        closable={false}
        placement="right"
        width={'85%'}
        visible={detailVisible}
        onClose={onCloseDetail}
        destroyOnClose={true}
      >
        <EADocDetail id={itemIdRef.current} fetchDelayed />
      </Drawer>
    </>
  );
};

export default withRouter(NotificationList);
