import axios from 'axios';
import { API_ROOT, VERSION, BUILD_NO } from '../config';
import authStore from '../stores/authStore';
import Util from '../helpers/Util';

axios.defaults.headers.common['X-Client-Version'] = VERSION;
axios.defaults.headers.common['X-Client-Build'] = BUILD_NO;

const Service = {
  get: async (endpoint: string, data?: any) => {
    try {
      const result = await axios(API_ROOT + endpoint, {
        params: data,
      });
      return result.data;
    } catch (e) {
      if (e.response.status === 401) {
        Util.showError('세션이 만료되었습니다. 로그인 화면으로 이동합니다.');
        authStore.logout();
        return;
      }
      throw e;
    }
  },
  post: async (endpoint: string, data?: any) => {
    const config = { headers: { 'Content-Type': 'application/json' } };
    const body = JSON.stringify(data);
    const result = await axios.post(API_ROOT + endpoint, body, config);
    return result.data;
  },
  put: async (endpoint: string, data?: any) => {
    const config = { headers: { 'Content-Type': 'application/json' } };
    const body = JSON.stringify(data);
    const result = await axios.put(API_ROOT + endpoint, body, config);
    return result.data;
  },
  delete: async (endpoint: string) => {
    const result = await axios.delete(API_ROOT + endpoint);
    return result.data;
  },
  download: async (endpoint: string, file_name: string) => {
    const result = await axios({
      url: API_ROOT + endpoint,
      responseType: 'blob',
    });

    const url = window.URL.createObjectURL(new Blob([result.data]));
    const link = document.createElement('a');
    link.href = url;
    link.setAttribute('download', file_name);
    document.body.appendChild(link);
    link.click();

    return new Promise((resolve) => {
      resolve();
    });
  },
};

export default Service;
