import React from 'react';
import { Route, Switch, Redirect } from 'react-router-dom';
import UnderConst from '../../components/UnderConst';
import EADocDrafter from '../eas/EADocDrafter';
import OrderList from '../projects/common/OrderList';
import OrderLedger from '../projects/common/OrderLedger';
import OrderDetail from '../projects/orders/OrderDetail';
import OrderEADocList from '../projects/orders/OrderEADocList';
import { OrderState } from '../../models/Order';
import ArchiveGroupBrowser from '../../components/general/ArchiveGroupBrowser';
import MyEducationList from '../works/educations/MyEducationList';
import EducationSchedules from '../works/educations/EducationSchedules';
import EADocListByCat from '../eas/EADocListByCat';
import EADocDrafterWrapper from '../eas/EADocDrafterWrapper';
import CollectPlan from '../management/finance/CollectPlan';
import OrderReport, { OrderReportListType } from '../reports/OrderReport';
import OrderReportHR from '../reports/OrderReportHR';

interface Props {}

const Index: React.FC<Props> = props => {
  const menu = 'business';
  const submenu = 'main';
  const deptId = 7; // 사업부문

  return (
    <Switch>
      <Route path="/business/main/uc" component={UnderConst} />
      <Route path="/business/main/archive" component={ArchiveGroupBrowser} />

      <Route path="/business/main" exact>
        <Redirect to="/business/main/ongoing" />
      </Route>

      <Route path="/business/main/ongoing">
        <OrderLedger
          title="진행 프로젝트"
          menu={menu}
          deptId={deptId}
          hideDeptSelector
          state={OrderState.ongoing}
        />
      </Route>
      <Route path="/business/main/eadocs/ord">
        <OrderEADocList
          menu={menu}
          submenu={submenu}
          deptId={deptId}
          hideDeptSelector
        />
      </Route>
      <Route path="/business/main/draft/ord">
        <EADocDrafter title="수주정보보고서" refTarget="ord" />
      </Route>
      <Route path="/business/main/orders/view" component={OrderDetail} />
      <Route path="/business/main/orders">
        <OrderList deptId={deptId} hideDeptSelector />
      </Route>
      <Route path="/business/main/ordins">
        <OrderLedger
          title="수주견적"
          menu={menu}
          deptId={deptId}
          hideDeptSelector
          target="ordin"
        />
      </Route>
      <Route path="/business/main/ctrins">
        <OrderLedger
          title="수주계약"
          menu={menu}
          deptId={deptId}
          hideDeptSelector
          target="ctrin"
        />
      </Route>
      <Route path="/business/main/ordouts">
        <OrderLedger
          title="외주실행"
          menu={menu}
          deptId={deptId}
          hideDeptSelector
          target="ordout"
        />
      </Route>
      <Route path="/business/main/ctrouts">
        <OrderLedger
          title="외주계약"
          menu={menu}
          deptId={deptId}
          hideDeptSelector
          target="ctrout"
        />
      </Route>

      <Route path="/business/main/collect">
        <CollectPlan menu="business" hideCorpSelect />
      </Route>

      <Route path="/business/main/myedu" component={MyEducationList} />
      <Route path="/business/main/edu" component={EducationSchedules} />

      <Route path="/business/main/eas/list" component={EADocListByCat} />
      <Route path="/business/main/eas/draft" component={EADocDrafterWrapper} />

      <Route path="/business/main/reports_all">
        <OrderReport title="전체 수주 진행 목록" />
      </Route>

      <Route path="/business/main/reports_urgent">
        <OrderReport title="핵심 프로젝트" isUrgent />
      </Route>

      <Route path="/business/main/reports_issue">
        <OrderReport
          title="이슈 프로젝트"
          hasIssue
          listType={OrderReportListType.hasIssue}
        />
      </Route>

      <Route path="/business/main/reports_new">
        <OrderReport
          title="신규 프로젝트"
          isNew
          listType={OrderReportListType.isNew}
        />
      </Route>

      <Route path="/business/main/reports_hr">
        <OrderReportHR />
      </Route>
    </Switch>
  );
};

export default Index;
