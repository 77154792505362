import React from 'react';
import { observer, inject } from 'mobx-react';
import { DataStore } from '../../stores/dataStore';
import { Select } from 'antd';
const { Option } = Select;

interface Props {
  dataStore?: DataStore;
  form: any;
  value?: number;
}

const RankSelect = (props: Props) => {
  const dataStore = props.dataStore!;
  const { getFieldDecorator } = props.form;

  return (
    <>
      {getFieldDecorator('rank', {
        initialValue: props.value || undefined
      })(
        <Select placeholder="직급" allowClear={true}>
          {dataStore.ranks.map(rank => (
            <Option key={rank} value={rank}>
              {rank}
            </Option>
          ))}
        </Select>
      )}
    </>
  );
};

export default inject('dataStore')(observer(RankSelect));
