import React from 'react';
import { inject, observer } from 'mobx-react';
import { Route, Switch, Redirect } from 'react-router-dom';
import UnderConst from '../../components/UnderConst';
import Dashboard from '../projects/common/Dashboard';
import EADocDrafter from '../eas/EADocDrafter';
import OrderList from '../projects/common/OrderList';
import OrderLedger from '../projects/common/OrderLedger';
import OrderDetail from '../projects/orders/OrderDetail';
import OrderEADocList from '../projects/orders/OrderEADocList';
import { OrderState } from '../../models/Order';
import DeptCodeList from '../../components/general/DeptCodeList';
import { AuthStore } from '../../stores/authStore';
import MyEducationList from '../works/educations/MyEducationList';
import EducationSchedules from '../works/educations/EducationSchedules';
import ArchiveGroupBrowser from '../../components/general/ArchiveGroupBrowser';
import EADocListByCat from '../eas/EADocListByCat';
import EADocDrafterWrapper from '../eas/EADocDrafterWrapper';
import CollectPlan from '../management/finance/CollectPlan';
import OrderReport, { OrderReportListType } from '../reports/OrderReport';
import _ from 'lodash';
import OrderReportHR from '../reports/OrderReportHR';

interface Props {
  authStore?: AuthStore;
}

const Index: React.FC<Props> = props => {
  // default 설계 1본부
  const menu = 'planning';
  const submenu = 'main';
  let deptId = Number(localStorage.getItem(`${menu}DefaultDeptId`)) || 16;
  const authStore = props.authStore!;
  if (
    authStore.user.rootDept &&
    _.includes([4, 5, 6], authStore.user.rootDept.id) &&
    authStore.user.hq
  ) {
    deptId = authStore.user.hq.id;
  }

  const planningHQIDs = [16, 19, 24, 26,54];
  if (!_.includes(planningHQIDs, deptId)) {
    deptId = 16;
  }

  return (
    <Switch>
      <Route path="/planning/main/uc" component={UnderConst} />
      <Route path="/planning/main/archive" component={ArchiveGroupBrowser} />

      <Route path="/planning/main" exact>
        <Redirect to="/planning/main/dashboard" />
      </Route>

      <Route path="/planning/main/dashboard">
        <Dashboard menu={menu} deptId={deptId} deptIds={planningHQIDs} />
      </Route>
      <Route path="/planning/main/ongoing">
        <OrderLedger
          title="진행 프로젝트"
          menu={menu}
          deptId={deptId}
          deptIds={planningHQIDs}
          state={OrderState.ongoing}
        />
      </Route>
      <Route path="/planning/main/eadocs/ord">
        <OrderEADocList
          menu={menu}
          submenu={submenu}
          deptId={deptId}
          deptIds={planningHQIDs}
        />
      </Route>
      <Route path="/planning/main/draft/ord">
        <EADocDrafter title="수주정보보고서" refTarget="ord" />
      </Route>
      <Route path="/planning/main/orders/view" component={OrderDetail} />
      <Route path="/planning/main/orders">
        <OrderList deptId={deptId} deptIds={planningHQIDs} />
      </Route>
      <Route path="/planning/main/ordins">
        <OrderLedger
          title="수주견적"
          menu={menu}
          deptId={deptId}
          deptIds={planningHQIDs}
          target="ordin"
        />
      </Route>
      <Route path="/planning/main/ctrins">
        <OrderLedger
          title="수주계약"
          menu={menu}
          deptId={deptId}
          deptIds={planningHQIDs}
          target="ctrin"
        />
      </Route>
      <Route path="/planning/main/ordouts">
        <OrderLedger
          title="외주실행"
          menu={menu}
          deptId={deptId}
          deptIds={planningHQIDs}
          target="ordout"
        />
      </Route>
      <Route path="/planning/main/ctrouts">
        <OrderLedger
          title="외주계약"
          menu={menu}
          deptId={deptId}
          deptIds={planningHQIDs}
          target="ctrout"
        />
      </Route>

      <Route path="/planning/main/collect">
        <CollectPlan menu="planning/main" hideCorpSelect />
      </Route>

      <Route path="/planning/main/myedu" component={MyEducationList} />
      <Route path="/planning/main/edu" component={EducationSchedules} />

      <Route path="/planning/main/eas/list" component={EADocListByCat} />
      <Route path="/planning/main/eas/draft" component={EADocDrafterWrapper} />
      {/* <Route path="/planning/main/eas/view">문서상세</Route> */}

      <Route path="/planning/main/reports_all">
        <OrderReport title="전체 수주 진행 목록" />
      </Route>

      <Route path="/planning/main/reports_urgent">
        <OrderReport title="핵심 프로젝트" isUrgent />
      </Route>

      <Route path="/planning/main/reports_issue">
        <OrderReport
          title="이슈 프로젝트"
          hasIssue
          listType={OrderReportListType.hasIssue}
        />
      </Route>

      <Route path="/planning/main/reports_new">
        <OrderReport
          title="신규 프로젝트"
          isNew
          listType={OrderReportListType.isNew}
        />
      </Route>

      <Route path="/planning/main/reports_hr">
        <OrderReportHR />
      </Route>

      <Route path="/planning/main/settings/stages">
        <DeptCodeList
          menu={menu}
          deptId={deptId}
          title="부서별 진행단계"
          fieldName="order_stages"
        />
      </Route>
      <Route path="/planning/main/settings/ctypes">
        <DeptCodeList
          menu={menu}
          deptId={deptId}
          title="부서별 수금구분"
          fieldName="cp_terms"
        />
      </Route>
    </Switch>
  );
};

export default inject('authStore')(observer(Index));
