import { Department, Corp } from './Org';
import dataStore from '../stores/dataStore';
import Util from '../helpers/Util';
import moment from 'moment';
import short from 'short-uuid';

export type AccountWeekGroup = {
  startDate: string;
  endDate: string;
  items: AccountItem[];
};

export class AccountItem {
  id = 0;
  out = false;
  title = '';
  note = '';
  kind = '';
  corp_id = 0;
  price = 0;
  pay = 0;
  est_date?: moment.Moment;
  pay_date?: moment.Moment;
  order_id = 0;
  type = 0; // 0 통장, 1 현금

  // derived
  [key: string]: any; // for string index (subscript)
  corp?: Corp;

  static fromJsonArray(sources: any[]) {
    return sources.map(source => AccountItem.fromJson(source));
  }

  static fromJson(source: any) {
    let item = new AccountItem();
    for (const key of Object.keys(source)) {
      if (key in item) {
        item[key] = source[key];
      }
    }

    item.corp = dataStore.findCorp(source.corp_id);
    item.est_date = Util.stringToMoment(source.est_date);
    item.pay_date = Util.stringToMoment(source.pay_date);
    item.price = source.amount;
    item.pay = source.pay_date ? source.amount : 0;

    return item;
  }

  static fromCollectPlanItems(sources: CollectPlanItem[]) {
    return sources.map(source => AccountItem.fromCollectPlanItem(source));
  }

  static fromCollectPlanItem(source: CollectPlanItem) {
    let item = new AccountItem();
    item.id = Math.random();
    item.est_date = source.est_date;
    item.pay_date = source.pay_date;
    item.out = source.out;
    item.order_id = source.order_id;
    item.title = source.name;
    item.kind = source.kind;
    item.corp_id = source.corp_id;
    item.corp = dataStore.findCorp(source.corp_id);
    item.price = source.total_price;
    item.pay = source.total_pay;
    return item;
  }

  get f_est_date() {
    return Util.formatDate(this.est_date);
  }

  get f_pay_date() {
    return Util.formatDate(this.pay_date);
  }

  get f_price() {
    return Util.formatNumber(this.price);
  }

  get f_pay() {
    return Util.formatNumber(this.pay);
  }

  get corp_name() {
    return this.corp ? this.corp.name : '';
  }
}

export class AccountVMItem {
  id = 0;
  summary = false;
  divider = false;
  date = '';

  source1?: AccountItem;
  title1 = '';
  kind1 = '';
  corp1?: Corp;
  price1 = 0;
  pay1 = 0;
  note1 = '';
  order_id1 = 0;

  source2?: AccountItem;
  title2 = '';
  corp2?: Corp;
  price2 = 0;
  pay2 = 0;
  note2 = '';
  order_id2 = 0;

  acc_price = 0;
  acc_pay = 0;

  static fromAccountItem(item1?: AccountItem, item2?: AccountItem) {
    let item = new AccountVMItem();
    item.id = Math.random();
    item.source1 = item1;
    item.source2 = item2;
    if (item1) {
      item.title1 = item1.title;
      item.kind1 = item1.kind;
      item.corp1 = item1.corp;
      item.price1 = item1.price;
      item.pay1 = item1.pay;
      item.order_id1 = item1.order_id;
      item.note1 = item1.note;
    }
    if (item2) {
      item.title2 = item2.title;
      item.corp2 = item2.corp;
      item.price2 = item2.price;
      item.pay2 = item2.pay;
      item.order_id2 = item2.order_id;
      item.note2 = item2.note;
    }
    return item;
  }

  get f_price1() {
    return Number(this.price1) === 0 ? '' : Util.formatNumber(this.price1);
  }

  get f_pay1() {
    return Number(this.pay1) === 0 ? '' : Util.formatNumber(this.pay1);
  }

  get f_price2() {
    return Number(this.price2) === 0 ? '' : Util.formatNumber(this.price2);
  }

  get f_pay2() {
    return Number(this.pay2) === 0 ? '' : Util.formatNumber(this.pay2);
  }

  get f_acc_price() {
    return Number(this.acc_price) === 0
      ? ''
      : Util.formatNumber(this.acc_price);
  }

  get f_acc_pay() {
    return Number(this.acc_pay) === 0 ? '' : Util.formatNumber(this.acc_pay);
  }
}

export type CollectWeekGroup = {
  startDate: string;
  endDate: string;
  items: CollectPlanItem[];
};

export class CollectPlanItem {
  id = 0;
  out = false;
  contract_id = 0;
  order_id = 0;
  name = '';
  corp_id = 0;
  dept_id = 0;
  kind = '';
  term = '';
  est_date?: moment.Moment;
  pay_date?: moment.Moment;
  total_price = 0;
  total_pay = 0;

  // derived
  [key: string]: any; // for string index (subscript)
  corp?: Corp;
  dept?: Department;

  static fromJsonArray(sources: any[]) {
    return sources.map(source => CollectPlanItem.fromJson(source));
  }

  static fromJson(source: any) {
    let item = new CollectPlanItem();
    for (const key of Object.keys(source)) {
      if (key in item) {
        item[key] = source[key];
      }
    }

    item.id = Math.random();
    item.corp = dataStore.findCorp(source.corp_id);
    item.dept = dataStore.findDepartment(source.dept_id);
    item.est_date = Util.stringToMoment(source.est_date);
    item.pay_date = Util.stringToMoment(source.pay_date);

    // NOTE: FinanceService.fetchOrderPayActions 호출 결과 파싱처리시
    // 1. 예정일 정보: 실행일 정보와 동일하게 설정
    if (source.est_date === undefined) {
      item.est_date = item.pay_date;
    }
    // 2. 계획금액: 실행금액과 동일하게 설정
    if (source.total_price === undefined) {
      item.total_price = item.total_pay;
    }

    return item;
  }

  get f_est_date() {
    return Util.formatDate(this.est_date);
  }

  get f_total_price() {
    return Util.formatNumber(this.total_price);
  }

  get f_total_pay() {
    return Util.formatNumber(this.total_pay);
  }
}

export class DailyReportItem {
  corp_id = 1;
  target_date?: moment.Moment = moment();
  create_date?: moment.Moment = moment();
  items: DepositItem[] = [];
  amount_cash = 0;
  amount_bank = 0;

  // derived
  [key: string]: any; // for string index (subscript)
  corp?: Corp;

  static fromJsonArray(sources: any[]) {
    return sources.map(source => DailyReportItem.fromJson(source));
  }

  static fromJson(source: any) {
    let item = new DailyReportItem();
    for (const key of Object.keys(source)) {
      if (key in item) {
        item[key] = source[key];
      }
    }

    item.corp = dataStore.findCorp(source.corp_id);
    item.target_date = Util.stringToMoment(source.target_date);
    item.create_date = Util.stringToMoment(source.create_date);
    item.items = DepositItem.fromJsonArray(source.items);

    return item;
  }

  get f_target_date() {
    return Util.formatDate(this.target_date);
  }

  get f_create_date() {
    return Util.formatDate(this.create_date);
  }
}

export class DepositItem {
  id = short().new();
  type = 0; // 구분 (0: 통장, 1: 현금)
  title = '';
  subtitle = '';
  account_no = '';
  amount_prev = 0;
  amount_in = 0;
  amount_out = 0;
  amount_rest = 0;
  amount_etc = 0;

  // derived
  [key: string]: any; // for string index (subscript)

  static fromJsonArray(sources: any[]) {
    return sources.map(source => DepositItem.fromJson(source));
  }

  static fromJson(source: any) {
    let item = new DepositItem();
    for (const key of Object.keys(source)) {
      if (key in item) {
        item[key] = source[key];
      }
    }

    return item;
  }

  get f_amount_prev() {
    return Util.formatNumber(this.amount_prev);
  }

  get f_amount_in() {
    return Util.formatNumber(this.amount_in);
  }

  get f_amount_out() {
    return Util.formatNumber(this.amount_out);
  }

  get f_amount_rest() {
    return Util.formatNumber(this.amount_rest);
  }

  get f_amount_etc() {
    return Util.formatNumber(this.amount_etc);
  }
}

export class CarryOver {
  year = 0;
  corp_id = 0;
  amount = 0;

  // derived
  [key: string]: any; // for string index (subscript)
  corp?: Corp;

  static fromJsonArray(sources: any[]) {
    return sources.map(source => CarryOver.fromJson(source));
  }

  static fromJson(source: any) {
    let item = new CarryOver();
    for (const key of Object.keys(source)) {
      if (key in item) {
        item[key] = source[key];
      }
    }

    item.corp = dataStore.findCorp(source.corp_id);
    return item;
  }

  get f_amount() {
    return Util.formatNumber(this.amount);
  }
}

export class MonthlyCollectStatus {
  id = 0;
  name = '';
  year = 0;
  dept_id = 0;
  kind = '';
  comp_name = '';
  in_total_price = 0;
  in_pay = 0;
  p1 = 0; // plan for month
  p2 = 0;
  p3 = 0;
  p4 = 0;
  p5 = 0;
  p6 = 0;
  p7 = 0;
  p8 = 0;
  p9 = 0;
  p10 = 0;
  p11 = 0;
  p12 = 0;
  a1 = 0; // action for month
  a2 = 0;
  a3 = 0;
  a4 = 0;
  a5 = 0;
  a6 = 0;
  a7 = 0;
  a8 = 0;
  a9 = 0;
  a10 = 0;
  a11 = 0;
  a12 = 0;

  // derived
  [key: string]: any; // for string index (subscript)
  dept?: Department;

  static fromJsonArray(sources: any[]) {
    return sources.map(source => MonthlyCollectStatus.fromJson(source));
  }

  static fromJson(source: any) {
    let item = new MonthlyCollectStatus();
    for (const key of Object.keys(source)) {
      if (key in item) {
        item[key] = source[key];
      }
    }

    // item.corp = dataStore.findCorp(source.corp_id);
    item.dept = dataStore.findDepartment(source.dept_id);

    return item;
  }

  static columnName(key: string): string {
    if (key === 'sum1') return '상반기';
    if (key === 'sum2') return '하반기';
    return `${key}월`;
  }

  get dept_name(): string {
    return this.dept ? this.dept.name : '';
  }

  get psum1(): number {
    return (
      Number(this.p1) +
      Number(this.p2) +
      Number(this.p3) +
      Number(this.p4) +
      Number(this.p5) +
      Number(this.p6)
    );
  }

  get psum2(): number {
    return (
      Number(this.p7) +
      Number(this.p8) +
      Number(this.p9) +
      Number(this.p10) +
      Number(this.p11) +
      Number(this.p12)
    );
  }

  get asum1(): number {
    return (
      Number(this.a1) +
      Number(this.a2) +
      Number(this.a3) +
      Number(this.a4) +
      Number(this.a5) +
      Number(this.a6)
    );
  }

  get asum2(): number {
    return (
      Number(this.a7) +
      Number(this.a8) +
      Number(this.a9) +
      Number(this.a10) +
      Number(this.a11) +
      Number(this.a12)
    );
  }

  get balance(): number {
    return this.in_total_price - this.in_pay;
  }

  get f_in_payrate(): string {
    if (Util.isDefined(this.in_total_price) && Util.isDefined(this.in_pay)) {
      return ((this.in_pay / this.in_total_price) * 100).toFixed(1);
    }
    return '0';
  }
}
