import React, { useState } from 'react';
import { Card, Drawer } from 'antd';
import AButton from '../../components/general/AButton';
import PostViewer from '../board/PostViewer';

interface Props {}

const EADocEditorGuidePanel: React.FC<Props> = props => {
  const [detailVisible, setDetailVisible] = useState(false);
  const [detailItemId, setDetailItemId] = useState(0);

  const onClick = (id: number) => {
    setDetailItemId(id);
    setDetailVisible(true);
  };

  const onCloseDetail = () => {
    setDetailVisible(false);
  };

  return (
    <>
      <Card size="small" title="결재선 안내">
        <ul>
          <li style={{ margin: '5px 0' }}>
            <AButton onClick={e => onClick(55)}>전사공통</AButton>
          </li>
          <li style={{ margin: '5px 0' }}>
            <AButton onClick={e => onClick(54)}>설계</AButton>
          </li>
          <li style={{ margin: '5px 0' }}>
            <AButton onClick={e => onClick(51)}>CS/CM</AButton>
          </li>
          <li style={{ margin: '5px 0' }}>
            <AButton onClick={e => onClick(52)}>개발/마케팅</AButton>
          </li>
          <li style={{ margin: '5px 0' }}>
            <AButton onClick={e => onClick(53)}>경영관리본부</AButton>
          </li>
        </ul>
      </Card>

      <Drawer
        closable={false}
        placement="right"
        width={'85%'}
        visible={detailVisible}
        onClose={onCloseDetail}
        destroyOnClose={true}
      >
        <PostViewer type={1} id={detailItemId} preventEditing />
      </Drawer>
    </>
  );
};

export default EADocEditorGuidePanel;
