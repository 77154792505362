import React, { useState, useEffect } from 'react';
import Form, { FormComponentProps } from 'antd/lib/form';
import { Button, Input, Popconfirm, Radio } from 'antd';
import FCNumberInput from '../../../components/formcomps/FCNumberInput';
import moment from 'moment';
import Util from '../../../helpers/Util';
import FIDatePicker from '../../../components/formcomps/FIDatePicker';
import InlineItem from '../../../components/formcomps/InlineItem';
import FICorpSelect from '../../../components/formcomps/FICorpSelect';
import FCStringCodeSelect from '../../../components/formcomps/FCStringCodeSelect';
import AppService from '../../../services/AppService';
import { AccountItem } from '../../../models/Finance';
import FinanceService from '../../../services/FinanceService';

interface Props extends FormComponentProps {
  item?: AccountItem;
  out?: boolean;
  onSuccess?: () => void;
}

const AccountItemEditor: React.FC<Props> = props => {
  const { out = false, item } = props;
  const { getFieldDecorator, setFieldsValue } = props.form;
  const [, setLoading] = useState(true);
  const [kinds, setKinds] = useState<string[]>([]);
  const [estDisabled, setEstDisabled] = useState(false);

  console.count('AccountItemEditor');

  useEffect(() => {
    const fetchStringCodes = async () => {
      setLoading(true);
      try {
        const result = await AppService.fetchStoreValues(['project_kinds']);
        setKinds(['경영', ...result['project_kinds']]);

        if (item) {
          setFieldsValue({
            corp_id: item.corp_id,
            kind: item.kind,
            amount: item.price,
            title: item.title,
            note: item.note,
            type: item.type
          });
          setEstDisabled(item.pay_date !== undefined);
        }
      } catch (error) {
        Util.showError(error);
      } finally {
        setLoading(false);
      }
    };

    fetchStringCodes();
  }, [item, setFieldsValue]);

  const onSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    props.form.validateFields((err, values) => {
      if (err) return;
      // console.log(values);
      processItem(values);
    });
  };

  const [submitting, setSubmitting] = useState(false);

  const processItem = async (values: any) => {
    try {
      // TODO: getFieldDecorator rules로 처리
      if (!values.corp_id) {
        Util.showError('법인을 선택해주세요.');
        return;
      }
      if (!values.est_date && !values.pay_date) {
        Util.showError('예정일 또는 실행일을 입력해주세요.');
        return;
      }

      if (values.pay_date) {
        values.est_date = values.pay_date;
      } else {
        // NOTE: pay_date가 없을 경우 null로 업데이트 처리
        values.pay_date = null;
      }

      setSubmitting(true);
      if (item) {
        await FinanceService.updateAccountItem(item.id, values);
      } else {
        await FinanceService.createAccountItem(values);
      }

      Util.showSuccess('저장되었습니다.');
      if (props.onSuccess) props.onSuccess();
    } catch (err) {
      Util.showError(err);
    } finally {
      setSubmitting(false);
    }
  };

  const onClickDelete = async () => {
    if (item === undefined) return;
    try {
      setSubmitting(true);
      await FinanceService.deleteAccountItem(item.id);
      Util.showSuccess('삭제되었습니다.');

      if (props.onSuccess) props.onSuccess();
    } catch (err) {
      Util.showError(err);
    } finally {
      setSubmitting(false);
    }
  };

  const onChangePayDate = (
    date: moment.Moment | undefined,
    dateString: string
  ) => {
    // setFieldsValue({ est_date: dateString });
    setEstDisabled(date !== undefined);
  };

  const formItemLayout = {
    labelCol: { span: 4 },
    wrapperCol: { span: 19 }
  };

  return (
    <>
      <Form {...formItemLayout} onSubmit={onSubmit}>
        <div className="hl-form" style={{ marginBottom: '10px' }}>
          {getFieldDecorator('out', { initialValue: out })(
            <Input type="hidden" />
          )}
          <Form.Item label="법인명">
            <InlineItem width="40%">
              <FICorpSelect form={props.form} options={{ initialValue: 1 }} />
            </InlineItem>{' '}
            <InlineItem width="200px">
              {!out && (
                <FCStringCodeSelect
                  form={props.form}
                  name="kind"
                  items={kinds}
                  options={{ initialValue: '경영' }}
                />
              )}
            </InlineItem>
          </Form.Item>
          <Form.Item label="내용">
            {getFieldDecorator('title', {
              rules: [
                {
                  required: true,
                  message: '내용을 입력해주세요.'
                }
              ]
            })(<Input autoFocus />)}
          </Form.Item>
          <Form.Item label="금액">
            <FCNumberInput form={props.form} name="amount" />
          </Form.Item>
          <Form.Item label="구분">
            {getFieldDecorator('type', { initialValue: 0 })(
              <Radio.Group>
                <Radio value={0}>통장</Radio>
                <Radio value={1}>현금</Radio>
              </Radio.Group>
            )}
          </Form.Item>
          <Form.Item label={out ? '지급예정일' : '수금예정일'}>
            <InlineItem>
              <FIDatePicker
                form={props.form}
                name="est_date"
                initialValue={item && item.est_date && item.f_est_date}
                disabled={estDisabled}
              />
            </InlineItem>{' '}
            <InlineItem>(키보드 입력: 엔터)</InlineItem>
          </Form.Item>
          <Form.Item label={out ? '지급일' : '수금일'}>
            <FIDatePicker
              form={props.form}
              name="pay_date"
              allowClear
              onChange={onChangePayDate}
              initialValue={item && item.pay_date && item.f_pay_date}
            />
          </Form.Item>
          <Form.Item label="비고">
            {getFieldDecorator('note')(<Input />)}
          </Form.Item>

          <Form.Item wrapperCol={{ span: 12, offset: 4 }}>
            <Button type="primary" htmlType="submit" disabled={submitting}>
              {item ? '수정' : '등록'}
            </Button>{' '}
            {item && (
              <Popconfirm
                title="정말 삭제하시겠습니까?"
                onConfirm={e => onClickDelete()}
              >
                <Button type="danger" disabled={submitting}>
                  삭제
                </Button>
              </Popconfirm>
            )}
          </Form.Item>
        </div>
      </Form>
    </>
  );
};

export default Form.create<Props>()(AccountItemEditor);
