import Service from './Index';

export type CollectFetchOption = {
  year: number;
  month: number;
  day?: number;
  corp_id?: number;
  dept_id?: number;
  keyword?: string;
  menu?: string;
};

const FinanceService = {
  fetchCollects: (options: CollectFetchOption) => {
    return Service.get('/mgmt/finance/collects', options);
  },
  fetchCollects2: (options: CollectFetchOption) => {
    return Service.get('/mgmt/finance/collects2', options);
  },
  fetchOrderPlans: (options: CollectFetchOption) => {
    return Service.get('/mgmt/finance/order_plans', options);
  },
  fetchOrderPayPlans: (options: CollectFetchOption) => {
    return Service.get('/mgmt/finance/order_payplans', options);
  },
  fetchOrderPayActions: (options: CollectFetchOption) => {
    return Service.get('/mgmt/finance/order_payactions', options);
  },

  // 수금현황(예정,실행 포함)
  fetchCollectStatusForYear: (options: CollectFetchOption) => {
    return Service.get('/mgmt/finance/collect_status', options);
  },

  // 자금계획표
  fetchAccountItems: (options: CollectFetchOption, out?: boolean) => {
    return Service.get('/mgmt/account_items', { ...options, out });
  },
  fetchAccountPaidItems: (options: CollectFetchOption, out?: boolean) => {
    return Service.get('/mgmt/account_items_paid', { ...options, out });
  },
  createAccountItem: (data: any) => {
    return Service.post('/mgmt/account_items', data);
  },
  updateAccountItem: (id: number, data: any) => {
    return Service.put(`/mgmt/account_items/${id}`, data);
  },
  deleteAccountItem: (id: number) => {
    return Service.delete(`/mgmt/account_items/${id}`);
  },
  copyAccountItems: (source_ids: number[], interval: number) => {
    return Service.post(`/mgmt/account_items_copy`, {
      source_ids,
      interval
    });
  },

  // 일일자금보고
  fetchDailyReports: (options: CollectFetchOption) => {
    return Service.get('/mgmt/finance/daily_reports', options);
  },
  createDailyReports: (data: any) => {
    return Service.post('/mgmt/finance/daily_reports', data);
  },
  updateDailyReports: (corp_id: number, target_date: string, data: any) => {
    return Service.put('/mgmt/finance/daily_reports', {
      corp_id,
      target_date,
      data
    });
  },
  fetchPrevDailyReport: (corp_id: number, target_date: string) => {
    return Service.get('/mgmt/finance/prev_report', {
      corp_id,
      target_date
    });
  },
  // fetchLatestDailyReport: (corp_id: number) => {
  //   return Service.get('/mgmt/finance/latest_report', { corp_id });
  // },
  fetchLatestDailyReportDate: (corp_id: number) => {
    return Service.get('/mgmt/finance/latest_report_date', { corp_id });
  },
  fetchCarryOvers: (year?: number, corp_id?: number) => {
    return Service.get('/mgmt/finance/carry_overs', { year, corp_id });
  },
  createCarryOver: (data: any) => {
    return Service.post('/mgmt/finance/carry_overs', data);
  },
  updateCarryOver: (year: number, corp_id: number, data: any) => {
    return Service.put('/mgmt/finance/carry_overs', { ...data, year, corp_id });
  },
  fetchPrevAmounts: (options: CollectFetchOption) => {
    return Service.get('/mgmt/finance/prev_amounts', {
      year: options.year,
      month: options.month,
      corp_id: options.corp_id
    });
  },
  fetchPrevAmounts2: (options: CollectFetchOption) => {
    return Service.get('/mgmt/finance/prev_amounts2', {
      year: options.year,
      month: options.month,
      corp_id: options.corp_id
    });
  }
};

export default FinanceService;
