// export const API_ROOT = 'http://localhost:8081/api/v1';
// export const STATIC_ROOT = 'http://localhost:8081/static';
// export const SOCKET_ROOT = 'http://localhost:8081';
// export const RELEASE_NOTE_ID = 5; // http://localhost:3000/works/board/posts/?type=9999

export const API_ROOT = 'https://api.hanlimgroup.com/api/v1';
export const STATIC_ROOT = 'https://api.hanlimgroup.com/static';
export const SOCKET_ROOT = 'https://api.hanlimgroup.com';
export const RELEASE_NOTE_ID = 23; // https://erp.hanlimgroup.com/works/board/posts/?type=9999

export const VERSION = '1.0.0';
export const BUILD_NO = 1587;
