import { MenuItem } from '../../../models/Common';

const menuItems: MenuItem[] = [
  {
    id: '1001',
    title: '경영지원',
    icon: 'desktop'
  },
  {
    id: '1101',
    title: '재무회계',
    icon: 'desktop'
  },
  {
    id: '1105',
    title: '경영참고자료',
    icon: 'desktop'
  },
  {
    id: '사업부문',
    title: '사업부문',
    icon: 'desktop',
    children: [
      { id: '3106', title: '사업기획' },
      { id: '3203', title: '금융1팀' },
      { id: '3303', title: '금융2팀' },
      { id: '3402', title: 'CS' },
      { id: '3503', title: 'CM팀' }
    ]
  },
  {
    id: '4106',
    title: '개발부문',
    icon: 'desktop'
  },
  {
    id: '5101',
    title: '마케팅부문',
    icon: 'desktop'
  },
  {
    id: '6001',
    title: '해외사업부문',
    icon: 'desktop'
  }
];

export default menuItems;
