import React from 'react';
import { Route, Switch, Redirect } from 'react-router-dom';
import UnderConst from '../../components/UnderConst';
import ArchiveGroupBrowser from '../../components/general/ArchiveGroupBrowser';

interface Props {}

const Index: React.FC<Props> = props => {
  return (
    <Switch>
      <Route path="/reports/data/uc" component={UnderConst} />
      <Route path="/reports/data/archive" component={ArchiveGroupBrowser} />

      <Route path="/reports/data" exact>
        <Redirect to="/reports/data/archive?id=1001" />
      </Route>
    </Switch>
  );
};

export default Index;
