import React from 'react';
import { Asset } from '../../../models/Asset';
import {
  HDescriptions,
  HDRow,
  HDItem
} from '../../../components/general/HDescriptions';
import Util from '../../../helpers/Util';
import { Tabs } from 'antd';
import AssetUserList from './AssetUserList';
import AssetEADocList from './AssetEADocList';
const { TabPane } = Tabs;

interface Props {
  item: Asset;
  onChange?: () => void;
}

const AssetDetail: React.FC<Props> = props => {
  const { item } = props;

  return (
    <>
      <h2>
        자산번호 {item.code} - {item.name}
      </h2>
      <HDescriptions>
        <HDRow>
          <HDItem label="대분류">{item.category1}</HDItem>
          <HDItem label="중분류" colSpan={3}>
            {item.category2}
          </HDItem>
        </HDRow>
        <HDRow>
          <HDItem label="제조사">{item.manufacturer}</HDItem>
          <HDItem label="모델명" colSpan={3}>
            {item.model}
          </HDItem>
        </HDRow>
        <HDRow>
          <HDItem label="구입비용">{Util.formatNumber(item.price)}</HDItem>
          <HDItem label="구입일자">
            {Util.formatDate(item.purchase_date)}
          </HDItem>
          <HDItem label="폐기일자">{Util.formatDate(item.dispose_date)}</HDItem>
        </HDRow>
      </HDescriptions>

      <Tabs type="card">
        <TabPane tab="사용자" key="users">
          <AssetUserList asset={item} onChange={props.onChange} />
        </TabPane>
        <TabPane tab="관련문서/자료" key="eadocs">
          <AssetEADocList asset={item} onChange={props.onChange} />
        </TabPane>
      </Tabs>
    </>
  );
};

export default AssetDetail;
