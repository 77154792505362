import { MenuItem } from '../../../models/Common';

const menuItems: MenuItem[] = [
  {
    id: '프로젝트총괄',
    title: '프로젝트 총괄',
    icon: 'desktop',
    link: '/planning/urban/dashboard'
  },
  {
    id: '진행 프로젝트',
    title: '진행 프로젝트',
    icon: 'desktop',
    link: '/planning/urban/ongoing'
  },
  {
    id: '업무프로세스',
    title: '업무프로세스',
    icon: 'desktop',
    children: [
      {
        id: 'ord',
        title: '수주정보보고',
        link: '/planning/urban/eadocs/ord'
      },
      {
        id: 'orders',
        title: '수주심의',
        link: '/planning/urban/orders'
      },
      {
        id: 'ordins',
        title: '수주견적',
        link: '/planning/urban/ordins'
      },
      {
        id: 'ctrins',
        title: '수주계약',
        link: '/planning/urban/ctrins'
      },
      {
        id: 'ordouts',
        title: '외주실행',
        link: '/planning/urban/ordouts'
      },
      {
        id: 'ctrouts',
        title: '외주계약',
        link: '/planning/urban/ctrouts'
      }
      // {
      //   id: 'comp',
      //   title: '협력업체등록신청',
      //   link: '/planning/urban/uc'
      // }
    ]
  },
  {
    id: '수금계획표',
    title: '수금계획표',
    icon: 'desktop',
    link: '/planning/urban/collect'
  },
  {
    id: '2301',
    title: '업무매뉴얼',
    icon: 'desktop'
  },
  {
    id: '기안/품의',
    title: '기안/품의',
    icon: 'desktop',
    children: [
      {
        id: 'report1',
        title: '검토서',
        link: '/planning/urban/eas/list?refTarget=pmis&cat=2102'
      },
      {
        id: 'report2',
        title: '제안도서',
        link: '/planning/urban/eas/list?refTarget=pmis&cat=2103'
      },
      {
        id: 'report3',
        title: '자문도서',
        link: '/planning/urban/eas/list?refTarget=pmis&cat=2104'
      },
      {
        id: 'report4',
        title: '입안도서',
        link: '/planning/urban/eas/list?refTarget=pmis&cat=2105'
      },
      {
        id: 'report5',
        title: '심의도서',
        link: '/planning/urban/eas/list?refTarget=pmis&cat=2106'
      },
      {
        id: 'report6',
        title: '승인도서',
        link: '/planning/urban/eas/list?refTarget=pmis&cat=2107'
      },
      {
        id: 'report7',
        title: '정기 경영보고',
        link: '/planning/urban/eas/list?cat=2100'
      }
    ]
  },
  {
    id: '설정',
    title: '설정',
    icon: 'desktop',
    children: [
      {
        id: 'stages',
        title: '부서별 진행단계',
        link: '/planning/urban/settings/stages'
      },
      {
        id: 'ctypes',
        title: '부서별 수금구분',
        link: '/planning/urban/settings/ctypes'
      }
    ]
  }
];

export default menuItems;
