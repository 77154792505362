import Service from './Index';

const ProjectService = {
  fetchItems: (limit: number, page: number, keyword?: string) => {
    const offset = (page - 1) * limit;
    return Service.get('/projects', {
      limit,
      offset,
      keyword
    });
  },
  fetchItem: (id: number) => {
    return Service.get(`/projects/${id}`);
  },
  create: (data: any) => {
    return Service.post('/projects', data);
  },
  update: (id: number, data: any) => {
    return Service.put(`/projects/${id}`, data);
  }
};

export default ProjectService;
