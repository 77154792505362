import { MenuItem } from '../../../models/Common';

const menuItems: MenuItem[] = [
  { id: 'files', icon: 'desktop', title: '통합파일검색' },
  { id: '3001', icon: 'desktop', title: '공정관리' },
  { id: '3002', icon: 'desktop', title: '설계도면,도서' },
  { id: '3003', icon: 'desktop', title: '시공,사업관리' },
  { id: '3004', icon: 'desktop', title: '준공유지관리' },
  { id: '3005', icon: 'desktop', title: '인허가관리' },
  { id: '3007', icon: 'desktop', title: '문서,회의록 외' },
  { id: '3010', icon: 'desktop', title: '업무보고' },
  { id: '101', icon: 'desktop', title: '포트폴리오' }
];

export default menuItems;
