import React, { useState, useEffect } from 'react';
import { Input, InputNumber } from 'antd';
import { GetFieldDecoratorOptions } from 'antd/lib/form/Form';
import { FormComponentProps } from 'antd/lib/form';
import Util from '../../helpers/Util';

interface Props extends FormComponentProps {
  name: string;
  unit?: string;
  size?: 'small';
  readOnly?: boolean;
  disabled?: boolean;
  autoFocus?: boolean;
  min?: number;
  max?: number;
  style?: any;
  options?: GetFieldDecoratorOptions;
  onEnter?: (value: number | undefined) => void;
}

const FCNumberInput = (props: Props) => {
  const { getFieldDecorator, getFieldValue, setFieldsValue } = props.form;
  const [value, setValue] = useState(0);
  const unit = props.unit || '';

  // const fieldValue = Number(getFieldValue(props.name));
  // useEffect(() => {
  //   if (!isNaN(fieldValue)) {
  //     setValue(fieldValue);
  //   }
  // }, [fieldValue]);

  const fieldValue = getFieldValue(props.name);
  useEffect(() => {
    setValue(fieldValue);
  }, [fieldValue]);

  const parse = (value: string | undefined) => {
    return (value && value.replace(/\$\s?|(,*)/g, '')) || '';
  };

  const onBlur = (e: React.FocusEvent<HTMLInputElement>) => {
    submitValue(parse(e.currentTarget.value));
  };

  const onPressEnter = (e: React.KeyboardEvent<HTMLInputElement>) => {
    submitValue(parse(e.currentTarget.value));
  };

  const submitValue = (value: string) => {
    const finalValue = isNaN(Number(value)) ? undefined : Number(value);
    setFieldsValue({ [props.name]: finalValue });
    if (props.onEnter) {
      props.onEnter(finalValue);
    }
  };

  return (
    <>
      {getFieldDecorator(props.name, props.options)(<Input type="hidden" />)}
      <InputNumber
        value={value}
        formatter={value => Util.formatNumber(value)}
        parser={parse}
        readOnly={props.readOnly}
        disabled={props.disabled}
        autoFocus={props.autoFocus}
        min={props.min}
        max={props.max}
        onChange={value => value && setValue(value)}
        onPressEnter={onPressEnter}
        onBlur={onBlur}
        style={{ width: props.size === 'small' ? 80 : 150 }}
      />
      {` ${unit}`}
    </>
  );
};

export default FCNumberInput;
