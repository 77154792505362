import React, { useState, useEffect } from 'react';
import { Route, Switch } from 'react-router-dom';
import { Layout, PageHeader } from 'antd';
import Sidebar from '../../components/Sidebar';
import Header from '../../components/Header';
import { HLContent } from '../../components/layout/Index';
import { RouteComponentProps, withRouter, Redirect } from 'react-router';
import { MenuItem } from '../../models/Common';
import mainMenus from './menus/main';
import dataMenus from './menus/data';
import DataRouter from './DataRouter';
import MainRouter from './MainRouter';
import { inject, observer } from 'mobx-react';
import { AuthStore } from '../../stores/authStore';

const defaultHeaderItems: MenuItem[] = [
  {
    id: 'main',
    title: '업무보고',
    icon: 'appstore',
    link: '/reports/main',
  },
  {
    id: 'data',
    title: '업무보고 (자료실)',
    icon: 'appstore',
    link: '/reports/data',
  },
];

const sideItemsInfo: { [key: string]: MenuItem[] } = {
  main: mainMenus,
  data: dataMenus,
};

interface Props extends RouteComponentProps {
  authStore?: AuthStore;
}

const Index: React.FC<Props> = (props) => {
  const paths = props.location.pathname.split('/');
  const submenu = paths.length >= 3 ? paths[2] : undefined;

  let [sideItems, setSideItems] = useState<MenuItem[]>([]);
  useEffect(() => {
    if (submenu === undefined) return;
    setSideItems(sideItemsInfo[submenu]);
  }, [submenu]);

  // console.log(props.location.pathname);

  // let flatItems: MenuItem[] = [];
  // sideItems.forEach(sideItem => {
  //   flatItems.push(sideItem);
  //   if (sideItem.children) {
  //     flatItems = flatItems.concat(sideItem.children);
  //   }
  // });

  const authStore = props.authStore!;
  if (!authStore.user.acl_report) {
    return <></>;
  }

  return (
    <>
      <Sidebar menuItems={sideItems}>
        <div style={{ textAlign: 'left', margin: '30px 0px 0px 24px' }}>
          <img src="/images/hanlim-group.png" width="120" alt="한림그룹" />
        </div>
        <PageHeader
          title="경영"
          subTitle="한림그룹"
          style={{ paddingTop: 5, paddingBottom: 20 }}
        />
      </Sidebar>

      <Layout id="wrapper" style={{ marginLeft: 200 }}>
        <Layout.Content>
          <Header menuItems={defaultHeaderItems} />
          <HLContent>
            <Switch>
              <Route path="/reports" exact>
                <Redirect to="/reports/main" />
              </Route>
              <Route path="/reports/main" component={MainRouter} />
              <Route path="/reports/data" component={DataRouter} />
            </Switch>
          </HLContent>
        </Layout.Content>
      </Layout>
    </>
  );
};

export default inject('authStore')(observer(withRouter(Index)));
