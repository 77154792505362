import React, { useState, useEffect } from 'react';
import { observer, inject } from 'mobx-react';
import { DataStore } from '../../stores/dataStore';
import { Form, Select, Input } from 'antd';
import { Department } from '../../models/Org';
import { FormComponentProps } from 'antd/lib/form';
import { GetFieldDecoratorOptions } from 'antd/lib/form/Form';
import InlineItem from './InlineItem';
import { SelectValue } from 'antd/lib/select';

interface Props extends FormComponentProps {
  dataStore?: DataStore;
  label?: string;
  name?: string;
  options?: GetFieldDecoratorOptions;
  width?: string;
  allowClear?: boolean;
  hide?: boolean;
  deptIds?: number[]; // 값이 설정된 경우 deptIds 대상으로 선택
  disabled?: boolean;
  onChange?: (value: number) => void;
}

const FIHQSelect = (props: Props) => {
  const dataStore = props.dataStore!;
  const { getFieldDecorator } = props.form;
  const {
    allowClear = false,
    hide = false,
    disabled = false,
    deptIds = dataStore.hq_ids
  } = props;

  const [items, setItems] = useState<Department[]>([]);
  useEffect(() => {
    const depts: Department[] = [];
    deptIds.forEach(id => {
      const dept = dataStore.findDepartment(id);
      if (dept) depts.push(dept);
    });
    setItems(depts);
  }, [deptIds, dataStore]);

  const onChange = (value: SelectValue) => {
    props.onChange && props.onChange(Number(value));
  };

  return (
    <>
      {hide ? (
        <>
          {getFieldDecorator(
            props.name || 'dept_id',
            props.options
          )(<Input type="hidden" />)}
        </>
      ) : (
        <Form.Item label={props.label}>
          <InlineItem width={props.width || '100%'}>
            {getFieldDecorator(
              props.name || 'dept_id',
              props.options
            )(
              <Select
                placeholder="부서"
                allowClear={allowClear}
                onChange={onChange}
                disabled={disabled}
              >
                {items.map(dept => (
                  <Select.Option key={dept.id} value={dept.id}>
                    {dept.name}
                  </Select.Option>
                ))}
              </Select>
            )}
          </InlineItem>
        </Form.Item>
      )}
    </>
  );
};

export default inject('dataStore')(observer(FIHQSelect));
