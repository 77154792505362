import React, { useState, useEffect } from 'react';
import Form, { FormComponentProps } from 'antd/lib/form';
import { Switch } from 'antd';
import { Contract } from '../../../models/Order';
import FCTextInput from '../../../components/formcomps/FCTextInput';
import FCNumberInput from '../../../components/formcomps/FCNumberInput';
import InlineText from '../../../components/formcomps/InlineText';
import InlineItem from '../../../components/formcomps/InlineItem';
import FIDatePicker from '../../../components/formcomps/FIDatePicker';
import Util from '../../../helpers/Util';

interface Props extends FormComponentProps {
  contract?: Contract;
  onChange?: () => void;
  restrictEditing?: boolean;
}

const ContractSummaryEditor: React.FC<Props> = props => {
  const { restrictEditing = false } = props;
  const { getFieldDecorator, getFieldValue, setFieldsValue } = props.form;
  const [contract] = useState<Contract>(props.contract || new Contract());

  console.count('ContractSummaryEditor');

  useEffect(() => {
    setFieldsValue({
      //   id: contract.id,
      contract_date:
        contract.contract_date && Util.formatDate(contract.contract_date),
      autocalc: contract.autocalc,
      tax_rate: contract.tax_rate,
      price: contract.price,
      tax_price: contract.tax_price,
      notax_price: contract.notax_price,
      vat: contract.vat,
      total_price: contract.total_price,
      note: contract.note
      //   company_id: contract.company_id
    });

    // NOTE: 자동계산 모드이고 합계금액이 없는 경우 자동계산 수행 (ex: 외주계약등록 최초 입력시)
    if (contract.autocalc && Number(contract.total_price) === 0) {
      const price = Number(contract.price);
      const taxRate = Number(contract.tax_rate);
      const taxPrice = (price * Number(taxRate)) / 100;
      const vat = taxPrice * 0.1;

      setFieldsValue({
        tax_price: taxPrice,
        notax_price: price - taxPrice,
        vat: vat,
        total_price: price + vat
      });
    }
  }, [contract, setFieldsValue]);

  const autoCalc: boolean = getFieldValue('autocalc');

  const onEnterForCalcPrice = (value: number | undefined) => {
    if (value !== undefined) calcPrice(autoCalc);
  };

  const onChangeAutoCalc = (checked: boolean) => {
    if (checked) calcPrice(true);
  };

  const calcPrice = (autoCalc: boolean) => {
    const price = Number(getFieldValue('price'));
    const taxRate = Number(getFieldValue('tax_rate'));
    const taxPrice = autoCalc
      ? (price * taxRate) / 100
      : Number(getFieldValue('tax_price'));
    const vat = Math.floor(taxPrice * 0.1);

    setFieldsValue({
      tax_price: taxPrice,
      notax_price: price - taxPrice,
      vat: vat,
      total_price: price + vat
    });

    if (props.onChange) props.onChange();
  };

  const onEnterForCalcTotal = (value: number | undefined) => {
    if (value !== undefined) calcTotal();
  };

  const calcTotal = () => {
    const taxPrice = Number(getFieldValue('tax_price'));
    const notaxPrice = Number(getFieldValue('notax_price'));
    const vat = Number(getFieldValue('vat'));
    setFieldsValue({
      total_price: taxPrice + notaxPrice + vat
    });
  };

  const formItemLayout = {
    labelCol: { span: 4 },
    wrapperCol: { span: 19 }
  };

  return (
    <>
      <Form {...formItemLayout}>
        <div className="hl-form" style={{ marginBottom: '10px' }}>
          <Form.Item label="계약금액">
            <InlineItem width="35%">
              <FCNumberInput
                form={props.form}
                name="price"
                onEnter={onEnterForCalcPrice}
                disabled={restrictEditing}
              />
            </InlineItem>
            <InlineText>계약일: </InlineText>
            <InlineItem>
              <FIDatePicker
                form={props.form}
                name="contract_date"
                initialValue={
                  contract.contract_date &&
                  Util.formatDate(contract.contract_date)
                }
              />
            </InlineItem>
          </Form.Item>
          <Form.Item label="과세공급가">
            <InlineItem width="35%">
              <FCNumberInput
                form={props.form}
                name="tax_price"
                disabled={autoCalc || restrictEditing}
                onEnter={onEnterForCalcPrice}
              />
            </InlineItem>
            <InlineText>자동계산</InlineText>
            <InlineItem>
              {getFieldDecorator('autocalc', {
                valuePropName: 'checked',
                initialValue: false
              })(<Switch onChange={onChangeAutoCalc} />)}
            </InlineItem>{' '}
            <InlineText>과세율:</InlineText>
            <InlineItem>
              <FCNumberInput
                form={props.form}
                name="tax_rate"
                size="small"
                onEnter={onEnterForCalcPrice}
                disabled={restrictEditing}
              />
            </InlineItem>{' '}
            %
          </Form.Item>
          <Form.Item label="면세공급가">
            <InlineItem width="35%">
              <FCNumberInput
                form={props.form}
                name="notax_price"
                disabled={autoCalc || restrictEditing}
                onEnter={onEnterForCalcTotal}
              />
            </InlineItem>
          </Form.Item>
          <Form.Item label="부가세액">
            <InlineItem width="35%">
              <FCNumberInput
                form={props.form}
                name="vat"
                disabled={autoCalc || restrictEditing}
                onEnter={onEnterForCalcTotal}
              />
            </InlineItem>
          </Form.Item>
          <Form.Item label="합계금액">
            <InlineItem width="35%">
              <FCNumberInput
                form={props.form}
                name="total_price"
                disabled={autoCalc || restrictEditing}
              />
            </InlineItem>
          </Form.Item>
          <Form.Item label="비고">
            <FCTextInput form={props.form} name="note" />
          </Form.Item>
        </div>
      </Form>
    </>
  );
};

export default ContractSummaryEditor;
