import React, { useState, useEffect } from 'react';
import {
  Table,
  Button,
  Row,
  Col,
  Input,
  Form,
  InputNumber,
  Drawer
} from 'antd';
import LinkButton from '../../../components/general/LinkButton';
import { FormComponentProps } from 'antd/lib/form';
import { PaginationConfig } from 'antd/lib/table';
import Util from '../../../helpers/Util';
import EADocDrafter from '../../eas/EADocDrafter';
import EADocDetail from '../../eas/EADocDetail';
import TripService, { TripSearchOption } from '../../../services/TripService';
import { TripReport } from '../../../models/Trip';
import { inject, observer } from 'mobx-react';
import { AuthStore } from '../../../stores/authStore';
import { EADocState, EADoc } from '../../../models/EAS';
import EAService from '../../../services/EAService';

interface Props extends FormComponentProps {
  authStore?: AuthStore;
}

const _MyTripList: React.FC<Props> = props => {
  const authStore = props.authStore!;
  const [loading, setLoading] = useState(false);
  const [totalCount, setTotalCount] = useState(0);
  const [currentPage, setCurrentPage] = useState(1);
  const [searchOption, setSearchOption] = useState<TripSearchOption>({
    user_id: authStore.user.id
  });
  const [items, setItems] = useState<TripReport[]>([]);
  const { getFieldDecorator } = props.form;

  const pagination = {
    pageSize: 15,
    current: currentPage,
    total: totalCount,
    showTotal: (total: number, range: [number, number]) =>
      `전체 ${total}건 중 ${range[0]}-${range[1]}`
  };

  useEffect(() => {
    const fetchItems = async () => {
      setLoading(true);
      try {
        const result = await TripService.fetchTripReports(
          pagination.pageSize,
          currentPage,
          searchOption
        );
        setTotalCount(result.total_count);
        setItems(TripReport.fromJsonArray(result.items));
      } catch (error) {
        Util.showError(error);
      } finally {
        setLoading(false);
      }
    };
    fetchItems();
  }, [currentPage, pagination.pageSize, searchOption]);

  const columns = [
    {
      title: '번호',
      dataIndex: 'id',
      align: 'right' as 'right',
      width: 70
    },
    {
      title: '구분',
      dataIndex: 'type',
      width: 50,
      render: (text: string, record: TripReport) => {
        return record.trip.type === 1 ? '출장' : '외근';
      }
    },
    {
      title: '출장/외근명',
      dataIndex: 'trip.trip_title',
      render: (text: string, record: TripReport) => (
        <LinkButton onClick={e => onClickItem(record)}>{text}</LinkButton>
      )
    },
    {
      title: '출장/외근 기간',
      dataIndex: 'trip.durationSummary',
      width: 250
    },
    {
      title: '신청인',
      dataIndex: 'trip.user.name',
      width: 100
    },
    {
      title: '출장자',
      dataIndex: 'user.name',
      width: 100
    },
    {
      title: '결과보고서',
      dataIndex: 'eaid',
      width: 100,
      render: (text: string, record: TripReport) => {
        if (record.eaid) {
          return (
            <LinkButton onClick={e => onClickViewReport(record)}>
              결과보고서 (
              {record.ea_state === EADocState.done ? '완료' : '결재중'})
            </LinkButton>
          );
        } else {
          return (
            <LinkButton onClick={e => onClickDraftReport(record)}>
              기안
            </LinkButton>
          );
        }
      }
    },
    {
      title: '청구금액',
      dataIndex: 'request_total',
      align: 'right' as 'right',
      width: 120,
      render: (text: string, record: TripReport) => {
        return record.eaid ? record.f_request_total : 'N/A';
      }
    },
    {
      title: '승인금액',
      dataIndex: 'approve_total',
      align: 'right' as 'right',
      width: 120,
      render: (text: string, record: TripReport) => {
        return record.eaid && record.ea_state === EADocState.done
          ? record.f_approve_total
          : 'N/A';
      }
    }
  ];

  const onChangeTable = (pagination: PaginationConfig) => {
    setCurrentPage(pagination.current || 1);
  };

  const onSubmitSearchForm = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    props.form.validateFields((err, values) => {
      if (err) return;
      setSearchOption({ ...values, user_id: authStore.user.id });
      setCurrentPage(1);
    });
  };

  const [ccDoc, setCCDoc] = useState<EADoc | undefined>(undefined);
  const [detailInfo, setDetailInfo] = useState<any>({
    show: false,
    draft: false,
    ref_target: '',
    ref_id: undefined,
    id: undefined,
    doc_title: ''
  });

  const onClickItem = (item: TripReport) => {
    setDetailInfo({
      show: true,
      draft: false,
      ref_target: 'trip',
      ref_id: item.trip.id,
      id: undefined
    });
  };

  const onClickViewReport = (item: TripReport) => {
    setDetailInfo({
      show: true,
      draft: false,
      // ref_target: 'trrpt',
      // ref_id: report.id,
      // id: undefined
      ref_target: undefined,
      ref_id: undefined,
      id: item.eaid
    });
  };

  const onClickDraftReport = async (item: TripReport) => {
    const result = await EAService.fetchItemByRef('trip', item.trip.id, true);
    const eadoc = EADoc.fromJson(result.eadoc);
    setCCDoc(eadoc);

    setDetailInfo({
      show: true,
      draft: true,
      ref_target: 'trrpt',
      ref_id: item.id,
      doc_title: `출장결과보고 - ${item.trip.trip_title}`
    });
  };

  const onCloseDetailInfo = () => {
    setDetailInfo({ ...detailInfo, show: false });
  };

  return (
    <>
      <h2>나의 출장 내역</h2>

      <Form
        className="hl-form"
        layout="inline"
        style={{ marginBottom: 7 }}
        onSubmit={onSubmitSearchForm}
      >
        <Row type="flex" justify="space-between">
          <Col>
            <Form.Item>
              {getFieldDecorator('keyword')(
                <Input.Search
                  placeholder="검색어"
                  allowClear={true}
                  style={{ width: 150 }}
                />
              )}
            </Form.Item>

            <Form.Item>
              {getFieldDecorator('year')(
                <InputNumber placeholder="년도" style={{ width: 100 }} />
              )}
            </Form.Item>

            <Form.Item>
              <Button type="primary" htmlType="submit">
                검색
              </Button>
            </Form.Item>
          </Col>
          <Col></Col>
        </Row>
      </Form>

      <Table
        columns={columns}
        dataSource={items}
        pagination={pagination}
        onChange={onChangeTable}
        rowKey="id"
        loading={loading}
        bordered={true}
        size="middle"
      />

      <Drawer
        closable={false}
        placement="right"
        width="85%"
        visible={detailInfo.show}
        onClose={onCloseDetailInfo}
        destroyOnClose={true}
      >
        {detailInfo.draft ? (
          <EADocDrafter
            title="출장결과보고"
            docTitle={detailInfo.doc_title}
            refTarget={detailInfo.ref_target}
            refId={detailInfo.ref_id}
            ccDocs={ccDoc ? [ccDoc] : undefined}
            fetchDelayed={true}
          />
        ) : (
          <EADocDetail
            id={detailInfo.id}
            refTarget={detailInfo.ref_target}
            refId={detailInfo.ref_id}
            fetchDelayed={true}
          />
        )}
      </Drawer>
    </>
  );
};

const MyTripList = Form.create<Props>()(_MyTripList);
export default inject('authStore')(observer(MyTripList));
