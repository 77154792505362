import React from 'react';
import { Form, Input, Button, Modal } from 'antd';
import { FormComponentProps } from 'antd/lib/form';

interface Props {
  name?: string;
  value?: string;
  submitting?: boolean;
  visible: boolean;
  onSubmit: (item: string) => void;
  onCancel: () => void;
}

const StringEditor = (props: Props) => {
  const itemName = props.name || '항목';
  return (
    <Modal
      title={props.value ? `${itemName} 수정` : `${itemName} 등록`}
      centered
      visible={props.visible}
      footer={null}
      onCancel={props.onCancel}
      destroyOnClose={true}
    >
      <StringInputForm {...props} />
    </Modal>
  );
};

export default StringEditor;

interface Props2 extends FormComponentProps {
  value?: string;
  submitting?: boolean;
  onSubmit: (item: string) => void;
}

const StringInputForm = Form.create<Props2>()((props: Props2) => {
  const { value } = props;
  const { getFieldDecorator } = props.form;

  const onSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    props.form.validateFields((err, values) => {
      if (err) return;
      props.onSubmit(values.value.trim());
    });
  };

  return (
    <Form labelCol={{ span: 4 }} wrapperCol={{ span: 18 }} onSubmit={onSubmit}>
      <Form.Item label="이름">
        {getFieldDecorator('value', {
          rules: [
            {
              required: true,
              message: '이름을 입력해주세요.'
            },
            {
              whitespace: true,
              message: '이름을 입력해주세요.'
            }
          ],
          initialValue: value
        })(<Input autoFocus />)}
      </Form.Item>
      <Form.Item wrapperCol={{ span: 12, offset: 4 }}>
        <Button type="primary" htmlType="submit" disabled={props.submitting}>
          {value ? '수정' : '등록'}
        </Button>
      </Form.Item>
    </Form>
  );
});
