import React, { useState, useEffect } from 'react';
import { Modal, Row, Col, Button } from 'antd';
import { observer, inject } from 'mobx-react';
import UserListPanel from '../../components/general/UserListPanel';
import { User } from '../../models/User';
import { EAFlowItem } from '../../models/EAS';
import { AuthStore } from '../../stores/authStore';
import { UserPickerPanel } from '../../components/general/UserPicker';

interface EditorProps {
  authStore?: AuthStore;
  initialEAItems: EAFlowItem[];
  initialCCItems: EAFlowItem[];
  onChangeItems: (eaItems: EAFlowItem[], ccItems: EAFlowItem[]) => void;
}

const EAFlowEditor: React.FC<EditorProps> = inject('authStore')(
  observer(props => {
    const authStore = props.authStore!;
    const { initialEAItems, initialCCItems, onChangeItems } = props;
    const [selectedUsers, setSelectedUsers] = useState<User[]>([]);
    const [eaItems, setEAItems] = useState<EAFlowItem[]>(initialEAItems);
    const [ccItems, setCCItems] = useState<EAFlowItem[]>(initialCCItems);

    console.count('EAFlowEditor');

    useEffect(() => {
      onChangeItems(eaItems, ccItems);
    }, [onChangeItems, eaItems, ccItems]);

    const onChangeSelectedUsers = (users: User[]) => {
      console.log('EAFlowEditor.onChangeSelectedUsers: ', users);
      setSelectedUsers(users);
    };

    // TODO: handleAdd... 중복코드 제거
    const handleAddAuto = () => {
      const existUserIds = eaItems.map(item => item.user.id);
      const uniqNewUsers = selectedUsers.filter(
        user => !existUserIds.includes(user.id)
      );
      const items = uniqNewUsers.map(user => {
        // user가 임원이거나 authStore.user와 같은 부서 소속이면 결재(ea1), 아니면 합의(ea2)
        const currentUserRootDept = authStore.user.rootDept;
        const rootDept = user.rootDept;
        if (
          user.isExecutive ||
          (currentUserRootDept &&
            rootDept &&
            currentUserRootDept.id === rootDept.id)
        ) {
          return new EAFlowItem(user, 1);
        }
        return new EAFlowItem(user, 2);
      });
      setEAItems(eaItems.concat(items));
      setSelectedUsers([]);
    };

    const handleAddPrimary = () => {
      const existUserIds = eaItems.map(item => item.user.id);
      const uniqNewUsers = selectedUsers.filter(
        user => !existUserIds.includes(user.id)
      );
      const items = uniqNewUsers.map(user => new EAFlowItem(user, 1));
      setEAItems(eaItems.concat(items));
      setSelectedUsers([]);
    };

    const handleAddSecondary = () => {
      const existUserIds = eaItems.map(item => item.user.id);
      const uniqNewUsers = selectedUsers.filter(
        user => !existUserIds.includes(user.id)
      );
      const items = uniqNewUsers.map(user => new EAFlowItem(user, 2));
      setEAItems(eaItems.concat(items));
      setSelectedUsers([]);
    };

    const handleAddCC = () => {
      const existUserIds = ccItems.map(item => item.user.id);
      const uniqNewUsers = selectedUsers.filter(
        user => !existUserIds.includes(user.id)
      );
      const items = uniqNewUsers.map(user => new EAFlowItem(user, 3));
      setCCItems(ccItems.concat(items));
      setSelectedUsers([]);
    };

    const handleDeleteEAItemRequest = (id: number) => {
      const items = eaItems.filter(item => item.user.id !== id);
      setEAItems(items);
    };

    const handleDeleteCCItemRequest = (id: number) => {
      const items = ccItems.filter(item => item.user.id !== id);
      setCCItems(items);
    };

    const onChangeEAItemsOrder = (items: any) => {
      setEAItems(items);
    };

    return (
      <Row gutter={0} style={{ height: '560px' }}>
        <Col span={14}>
          <UserPickerPanel
            selectedUsers={selectedUsers}
            onChange={onChangeSelectedUsers}
          />
        </Col>
        <Col span={10}>
          <Row>
            <Col style={{ marginBottom: '15px' }}>
              <Row type="flex" align="middle">
                <Col span={5}>
                  <Button
                    type="primary"
                    icon="arrow-right"
                    style={{ margin: '2px' }}
                    onClick={handleAddAuto}
                  >
                    추가
                  </Button>
                  <Button
                    icon="arrow-right"
                    style={{ margin: '2px' }}
                    onClick={handleAddPrimary}
                  >
                    결재
                  </Button>
                  <Button
                    icon="arrow-right"
                    style={{ margin: '2px' }}
                    onClick={handleAddSecondary}
                  >
                    합의
                  </Button>
                </Col>
                <Col span={19}>
                  <div style={{ marginLeft: '10px' }}>
                    <UserListPanel
                      title="결재 (드래그하여 순서 변경)"
                      items={eaItems}
                      dragable={true}
                      onDeleteRequest={handleDeleteEAItemRequest}
                      onChangeOrder={onChangeEAItemsOrder}
                    />
                  </div>
                </Col>
              </Row>
            </Col>
            <Col>
              <Row type="flex" align="middle">
                <Col span={5}>
                  <div>
                    <Button
                      icon="arrow-right"
                      style={{ margin: '2px' }}
                      onClick={handleAddCC}
                    >
                      참조
                    </Button>
                  </div>
                </Col>
                <Col span={19}>
                  <div style={{ marginLeft: '10px' }}>
                    <UserListPanel
                      title="참조"
                      items={ccItems}
                      onDeleteRequest={handleDeleteCCItemRequest}
                    />
                  </div>
                </Col>
              </Row>
            </Col>
          </Row>
        </Col>
      </Row>
    );
  })
);

interface Props {
  visible: boolean;
  initialEAItems: EAFlowItem[];
  initialCCItems: EAFlowItem[];
  onCancel: () => void;
  onOk: (eaItems: EAFlowItem[], ccItems: EAFlowItem[]) => void;
}

const EAFlowEditorModal: React.FC<Props> = props => {
  const { visible, initialEAItems, initialCCItems, onCancel, onOk } = props;
  const [eaItems, setEAItems] = useState<EAFlowItem[]>([]);
  const [ccItems, setCCItems] = useState<EAFlowItem[]>([]);

  const handleOK = () => {
    onOk(eaItems, ccItems);
  };

  const handleChangeItems = (items1: EAFlowItem[], items2: EAFlowItem[]) => {
    setEAItems(items1);
    setCCItems(items2);
  };

  console.count('EAFlowEditorModal');

  return (
    <Modal
      title="결재선 지정"
      centered
      width={1000}
      visible={visible}
      onCancel={onCancel}
      footer={[
        <Button key="back" onClick={onCancel}>
          취소
        </Button>,
        <Button key="submit" type="primary" onClick={handleOK}>
          등록
        </Button>
      ]}
      destroyOnClose={true}
    >
      <EAFlowEditor
        initialEAItems={initialEAItems}
        initialCCItems={initialCCItems}
        onChangeItems={handleChangeItems}
      />
    </Modal>
  );
};

export default EAFlowEditorModal;
