import React, { useState, useEffect } from 'react';
import { Order } from '../../../models/Order';
import OrderService from '../../../services/OrderService';
import { RouteComponentProps, withRouter } from 'react-router';
import Util from '../../../helpers/Util';
import OrderSummaryPanel from './OrderSummaryPanel';
import Spinner from '../../../components/general/Spinner';
import OrderContracts from './OrderContracts';

interface Props extends RouteComponentProps {
  id?: number;
  fetchDelayed?: boolean;
  onChangeDetail?: () => void;
}

const OrderDetail: React.FC<Props> = props => {
  const { location, fetchDelayed } = props;
  const params = new URLSearchParams(location.search);
  const orderId = Number(props.id || params.get('id') || '0');

  const [loading, setLoading] = useState(true);
  const [order, setOrder] = useState<Order>(new Order());
  const [orderName, setOrderName] = useState('');

  useEffect(() => {
    const fetchDetail = async (id: number) => {
      setLoading(true);
      try {
        const result = await OrderService.fetchItem(id);
        const order = Order.fromJson(result.order);
        setOrder(order);
        setOrderName(order.name);
      } catch (error) {
        Util.showError(error);
      } finally {
        setLoading(false);
      }
    };

    if (orderId > 0) {
      if (fetchDelayed) {
        setTimeout(() => {
          fetchDetail(orderId);
        }, 400);
      } else {
        fetchDetail(orderId);
      }
    }
  }, [orderId, fetchDelayed]);

  console.count('OrderDetail');

  const onChangeOrderInfo = (order: Order) => {
    setOrderName(order.name);
    props.onChangeDetail && props.onChangeDetail();
  };

  if (loading) {
    return <Spinner />;
  }

  return (
    <>
      <h2>{orderName}</h2>
      <OrderSummaryPanel
        order={order}
        allowEditing
        onChangeOrderInfo={onChangeOrderInfo}
      />
      <OrderContracts
        order={order}
        onChangePayBook={props.onChangeDetail}
        onChangeContract={props.onChangeDetail}
      />
    </>
  );
};

export default withRouter(OrderDetail);
