import { Department, Corp } from './Org';
import moment from 'moment';
import dataStore from '../stores/dataStore';
import authStore from '../stores/authStore';
import Util from '../helpers/Util';
import { Project } from './Project';
import { User } from './User';
import short from 'short-uuid';

export enum OrderState {
  ongoing = '진행',
  pending = '보류',
  done = '완료',
  forced = '강제완료',
}

export class Order {
  id = 0;
  year = 0;
  seq = 1;
  name = '';
  state: OrderState = OrderState.ongoing;
  stage = '';
  stages: OrderStage[] = [];
  kind = '';
  grade = '';
  register_date?: moment.Moment = moment();
  corp_id: number = (authStore.user.corp && authStore.user.corp.id) || 1;
  dept_id?: number = authStore.user.hq && authStore.user.hq.id;
  collab_ids: number[] = [];
  leaders: User[] = [];
  managers: User[] = [];
  is_new = false;
  is_urgent = false;
  has_issue = false;

  // 대지정보
  region: string[] = [];
  districts: string[] = [];
  areas: string[] = [];
  site_state = '';
  price = 0;
  use1: string[] = [];
  use2: string[] = [];
  use3: string[] = [];
  use = '';

  // 사업주
  comp_id = 0;
  comp_name = '';
  comp_owner = '';
  comp_rank = '';
  comp_phone = '';
  comp_note = '';
  comp_nature = '';
  comp_relation = '';

  // 의뢰처
  client_id?: number;
  client_name = '';
  client_owner = '';
  client_rank = '';
  client_phone = '';
  client_note = '';
  client_nature = '';
  client_relation = '';

  // 사업구도
  type = '';
  progress = '';
  constructor_name = '';
  finance_comp = '';
  finance_state = '';
  est_cost = 0;

  // 기타
  plan_date?: moment.Moment;
  plan_est_date?: moment.Moment;
  note = '';

  // 계약정보요약
  ordin_eaid?: number;
  ordin = false;
  ctrin_eaid?: number;
  ctrin = false;
  ordout_eaid?: number;
  ordout = false;
  ctrout_eaid?: number;
  ctrout = false;
  in_price?: number;
  in_total_price?: number;
  in_pay?: number;
  out_price?: number;
  out_total_price?: number;
  out_pay?: number;
  unpaid_advent?: number;
  order_outs: OrderOut[] = [];
  terms: string[] = [];

  core_est_price?: number;
  key_order_price?: number;
  status_note = '';
  issue = '';
  task = '';
  remark = '';

  // derived
  [key: string]: any; // for string index (subscript)
  corp?: Corp = authStore.user.corp;
  dept?: Department = authStore.user.hq;
  collabs: Department[] = [];
  project: Project = new Project();

  // TODO: project: Project 으로 처리?
  // project_name = ''; // project
  // year?: number; // project
  // end_date?: moment.Moment; // project

  // nullifyMissingValues & omit name
  // static submitValues(values: any) {
  //   [
  //     'grade',
  //     'kind',
  //     'dept_id',
  //     'site_state',
  //     'use1',
  //     'use2',
  //     'use3',
  //     'type',
  //     'progress',
  //     'finance_state'
  //   ].forEach(key => (values[key] = values[key] || null));
  //   return _.omit(values, ['project_name', 'stages']);
  // }

  static fromJsonArray(sources: any[]) {
    return sources.map((source) => Order.fromJson(source));
  }

  static fromJson(source: any) {
    let item = new Order();
    for (const key of Object.keys(source)) {
      if (key in item) {
        item[key] = source[key];
      }
    }

    if (source.project) item.project = Project.fromJson(source.project);

    // NOTE: legacy eadoc.data 처리용 (region: string -> string[]로 변경됨)
    if (typeof source.region === 'string') {
      item.region = source.region === '' ? [] : [source.region];
    }

    item.corp = dataStore.findCorp(source.corp_id);
    item.dept = dataStore.findDepartment(source.dept_id);
    item.register_date = Util.stringToMoment(source.register_date);
    item.plan_date = Util.stringToMoment(source.plan_date);
    item.plan_est_date = Util.stringToMoment(source.plan_est_date);
    item.stages = OrderStage.fromJsonArray(source.stages || []);
    item.terms = source.terms || [];
    item.order_outs = source.order_outs
      ? OrderOut.fromJsonArray(source.order_outs)
      : [];

    item.use1 = (source.use1 && source.use1.split('/')) || [];
    item.use2 = (source.use2 && source.use2.split('/')) || [];
    item.use3 = (source.use3 && source.use3.split('/')) || [];
    item.use = source.use || '';

    item.leaders = User.fromJsonArray(source.leaders);
    item.managers = User.fromJsonArray(source.managers);

    if (source.collab_ids) {
      item.collabs = source.collab_ids.map((id: number) =>
        dataStore.findDepartment(id)
      );
    }

    return item;
  }

  getStage(stage: string): OrderStage | undefined {
    return this.stages.find((el) => el.stage === stage);
  }

  // 1: 보통, 2: 주의, 3: 긴급
  // 참고: allStages: 전체 단계명 배열
  // 참고: this.stages: 해당 order의 완료일,예정일 정보를 담고있는 OrderStage 배열
  getUrgentLevel(allStages: string[]): number {
    // console.log(this.stages, this.stage);

    // 현단계가 마지막 단계면 보통 처리
    if (allStages.length > 0) {
      const lastStage = allStages[allStages.length - 1];
      if (lastStage === this.stage) {
        return 1;
      }
    }

    // 현단계의 스테이지 정보가 없으면 긴급 처리
    if (this.stages.length === 0) return 3;
    if (!this.stage) return 3;

    const orderStage = this.getStage(this.stage);
    if (!orderStage) return 3;

    // 현단계에 완료일, 예정일 없으면 긴급 처리
    if (
      orderStage.finish_date === undefined &&
      orderStage.finish_est_date === undefined
    ) {
      return 3;
    }

    const momentToday = moment(Util.formatDate(moment()));

    // 현단계 완료일이 있을 경우
    if (orderStage.finish_date) {
      const daysPassed = momentToday.diff(orderStage.finish_date, 'days');
      if (daysPassed >= 7) return 3; // 일주일이상 지났으면 긴급
      if (daysPassed >= 1) return 2; // 오늘 지났으면 주의
    }

    // 현단계 예정일이 있을 경우
    if (orderStage.finish_est_date) {
      const daysPassed = momentToday.diff(orderStage.finish_est_date, 'days');
      if (daysPassed >= 0) return 3; // 오늘 지났으면 긴급
      if (daysPassed >= -7) return 2; // 일주일 이내이면 주의
    }

    return 1;
  }

  static stateFromString(string: string) {
    switch (string) {
      case 'pending':
        return OrderState.pending;
      case 'done':
        return OrderState.done;
      case 'forced':
        return OrderState.forced;
      default:
        return OrderState.ongoing;
    }
  }

  static stateName(item: OrderState) {
    switch (item) {
      case OrderState.ongoing:
        return '진행';
      case OrderState.pending:
        return '보류';
      case OrderState.done:
        return '완료';
      case OrderState.forced:
        return '강제완료';
    }
  }

  // static stateName(item: OrderState) {
  //   const index = Object.keys(OrderState).indexOf(item);
  //   if (index >= 0) {
  //     return Object.values(OrderState)[index];
  //   } else {
  //     return 'Unknown';
  //   }
  // }

  get bom(): string {
    const kc = Project.kindCode(this.kind); // 종류별 구분코드
    const y = String(this.year).substr(-2); // 연도 마지막 두자리수
    const s = ('00' + String(this.seq)).substr(-3); // 연도별 일련번호
    const c = this.ctrin ? 'C' : 'F'; // 계약/검토 구분
    return kc + y + s + c;
  }

  get f_use1(): string {
    return this.use1.join('/');
  }

  get f_use2(): string {
    return this.use2.join('/');
  }

  get f_use3(): string {
    return this.use3.join('/');
  }

  get f_use(): string {
    let result: string[] = [];
    if (this.use1.length > 0) result.push(this.f_use1);
    if (this.use2.length > 0) result.push(this.f_use2);
    if (this.use3.length > 0) result.push(this.f_use3);
    if (this.use.trim() !== '') result.push(this.use);
    return result.join(', ');
  }

  get f_price(): string {
    return Util.formatNumber(this.price);
  }

  get f_in_price(): string {
    return Util.formatNumber(this.in_price);
  }

  get f_in_total_price(): string {
    return Util.formatNumber(this.in_total_price);
  }

  get f_in_pay(): string {
    return Util.formatNumber(this.in_pay);
  }

  get f_in_balance(): string {
    if (Util.isDefined(this.in_total_price) && Util.isDefined(this.in_pay)) {
      return Util.formatNumber(this.in_total_price - this.in_pay);
    }
    return this.f_in_total_price;
  }

  get f_in_payrate(): string {
    if (Util.isDefined(this.in_total_price) && Util.isDefined(this.in_pay)) {
      return ((this.in_pay / this.in_total_price) * 100).toFixed(1);
    }
    return '0';
  }

  get f_out_price(): string {
    return Util.formatNumber(this.out_price);
  }

  get f_out_total_price(): string {
    return Util.formatNumber(this.out_total_price);
  }

  get f_out_pay(): string {
    return Util.formatNumber(this.out_pay);
  }

  // 도래미지급
  get f_unpaid_advent(): string {
    return Util.formatNumber(this.unpaid_advent);
  }

  // 미도래잔액
  get f_unpaid_inadvent(): string {
    let result = this.out_total_price || 0;
    if (this.out_pay) result -= this.out_pay;
    if (this.unpaid_advent) result -= this.unpaid_advent;
    return Util.formatNumber(result);
  }

  get f_out_payrate(): string {
    if (Util.isDefined(this.out_total_price) && Util.isDefined(this.out_pay)) {
      return ((this.out_pay / this.out_total_price) * 100).toFixed(1);
    }
    return '0';
  }

  get f_out_rate(): string {
    if (
      Util.isDefined(this.in_total_price) &&
      Util.isDefined(this.out_total_price)
    ) {
      return ((this.out_total_price / this.in_total_price) * 100).toFixed(1);
    }
    return '0';
  }
}

export class OrderStage {
  order_id = 0;
  stage = '';
  finish_date?: moment.Moment; // = moment();
  finish_est_date?: moment.Moment; // = moment();

  // derived
  [key: string]: any; // for string index (subscript)

  static fromJsonArray(sources: any[]) {
    return sources.map((source) => OrderStage.fromJson(source));
  }

  static fromJson(source: any) {
    let item = new OrderStage();
    for (const key of Object.keys(source)) {
      if (key in item) {
        item[key] = source[key];
      }
    }

    item.finish_date = Util.stringToMoment(source.finish_date);
    item.finish_est_date = Util.stringToMoment(source.finish_est_date);

    return item;
  }
}

export class Contract {
  id = 0;
  order_id = 0;
  out = false;
  company_id = 0;
  company_name = '';
  company_category = '';
  autocalc = true;
  tax_rate?: number = 100;
  contract_date?: moment.Moment;
  price = 0;
  tax_price = 0;
  notax_price = 0;
  vat = 0;
  total_price = 0;
  note?: string;
  items: ContractItem[] = [];

  // derived
  [key: string]: any; // for string index (subscript)

  static fromJsonArray(sources: any[]) {
    return sources.map((source) => Contract.fromJson(source));
  }

  static fromJson(source: any) {
    let item = new Contract();
    for (const key of Object.keys(source)) {
      if (key in item) {
        item[key] = source[key];
      }
    }

    if (source.autocalc === undefined || source.autocalc === null)
      item.autocalc = true;
    if (source.tax_rate === undefined || source.tax_rate === null)
      item.tax_rate = 100;
    if (source.items) {
      item.items = ContractItem.fromJsonArray(source.items);
    }
    item.contract_date = Util.stringToMoment(source.contract_date);

    return item;
  }

  get f_tax_rate(): string {
    return Util.formatNumber(this.tax_rate);
  }

  get f_price(): string {
    return Util.formatNumber(this.price);
  }

  get f_tax_price(): string {
    return Util.formatNumber(this.tax_price);
  }

  get f_notax_price(): string {
    return Util.formatNumber(this.notax_price);
  }

  get f_vat(): string {
    return Util.formatNumber(Math.floor(this.vat));
  }

  get f_total_price(): string {
    return Util.formatNumber(this.total_price);
  }
}

export class OrderOut {
  id: string;
  order_id = 0;
  company_id = 0;
  company_name = '';
  company_category = '';
  selected = false;
  est_price?: number;
  c1_price?: number;
  c2_price?: number;
  final_price?: number;
  note = '';

  // derived
  [key: string]: any; // for string index (subscript)

  constructor() {
    this.id = short().new();
  }

  static fromJsonArray(sources: any[]) {
    return sources.map((source) => OrderOut.fromJson(source));
  }

  static fromJson(source: any) {
    let item = new OrderOut();
    for (const key of Object.keys(source)) {
      if (key in item) {
        item[key] = source[key];
      }
    }

    return Util.parseNumbers(item, source, [
      'est_price',
      'c1_price',
      'c2_price',
      'final_price',
    ]);
  }

  getFinalPrice(): number {
    if (this.c2_price) return this.c2_price;
    if (this.c1_price) return this.c1_price;
    if (this.est_price) return this.est_price;
    return 0;
  }

  get f_est_price(): string {
    return Util.formatNumber(this.est_price);
  }

  get f_c1_price(): string {
    return Util.formatNumber(this.c1_price);
  }

  get f_c2_price(): string {
    return Util.formatNumber(this.c2_price);
  }

  get f_final_price(): string {
    return Util.formatNumber(this.final_price);
  }
}

export class ContractItem {
  contract_id = 0;
  seq = 0;
  term = '';
  rate?: number;
  tax_price = 0;
  notax_price = 0;
  total_price = 0;
  est_date?: moment.Moment;
  req_date?: moment.Moment;
  pay_date?: moment.Moment;
  advent = false;
  total_pay = 0;
  note = '';

  // derived
  [key: string]: any; // for string index (subscript)

  constructor(seq: number) {
    this.seq = seq;
  }

  static fromJsonArray(sources: any[]) {
    return sources.map((source) => ContractItem.fromJson(source));
  }

  static fromJson(source: any) {
    let item = new ContractItem(source.seq);
    for (const key of Object.keys(source)) {
      if (key in item) {
        item[key] = source[key];
      }
    }

    item.est_date = Util.stringToMoment(source.est_date);
    item.req_date = Util.stringToMoment(source.req_date);
    item.pay_date = Util.stringToMoment(source.pay_date);

    return item;
  }

  get id(): string {
    return `${this.contract_id}_${this.seq}`;
  }

  get f_rate(): string {
    return Util.formatNumber(this.rate);
  }

  get f_tax_price(): string {
    return Util.formatNumber(this.tax_price);
  }

  get f_notax_price(): string {
    return Util.formatNumber(this.notax_price);
  }

  get f_total_price(): string {
    return Util.formatNumber(this.total_price);
  }

  get f_vat(): string {
    return Util.formatNumber(Math.floor(this.tax_price * 0.1));
  }

  get f_total_pay(): string {
    return Util.formatNumber(this.total_pay);
  }

  get remain_total_price(): number {
    return this.total_price - this.total_pay;
  }

  get completed(): boolean {
    return this.total_pay === this.total_price;
  }
}

export class ContractPay {
  id = 0;
  contract_id = 0;
  term = '';
  req_date?: moment.Moment;
  pay_date?: moment.Moment;
  total_pay = 0;
  note = '';

  // derived
  [key: string]: any; // for string index (subscript)
  company_name = '';

  static fromJsonArray(sources: any[]) {
    return sources.map((source) => ContractPay.fromJson(source));
  }

  static fromJson(source: any) {
    let item = new ContractPay();
    for (const key of Object.keys(source)) {
      if (key in item) {
        item[key] = source[key];
      }
    }

    item.req_date = Util.stringToMoment(source.req_date);
    item.pay_date = Util.stringToMoment(source.pay_date);

    return item;
  }

  get f_total_pay(): string {
    return Util.formatNumber(this.total_pay);
  }
}
