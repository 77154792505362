import React, { useState, useEffect, useRef } from 'react';
import { RouteComponentProps, withRouter } from 'react-router';
import {
  Row,
  Col,
  Button,
  Input,
  Icon,
  Table,
  Drawer,
  Popconfirm,
  Tag,
  Form
} from 'antd';
import Util from '../../helpers/Util';
import ArchiveTree from './ArchiveTree';
import { PaginationConfig } from 'antd/lib/table';
import ArchiveFileUploader from './ArchiveFileUploader';
import filesize from 'filesize';
import LinkButton from './LinkButton';
import FileService from '../../services/FileService';
import { Archive, File } from '../../models/File';
import ArchiveFileGroupEditor from './ArchiveFileGroupEditor';
import AButton from './AButton';
import { FormComponentProps } from 'antd/lib/form';

interface Props extends FormComponentProps, RouteComponentProps {
  id?: number;
  orderId?: number;
}

const ArchiveGroupBrowser: React.FC<Props> = props => {
  const { location } = props;
  const params = new URLSearchParams(location.search);
  const archiveGroupId = props.id || Number(params.get('id')) || 0;

  const paths = props.location.pathname.split('/');
  const param1 = paths.length >= 4 ? paths[3] : undefined;
  const orderId =
    props.orderId || Number(param1) || Number(params.get('oid')) || undefined;

  const [loading, setLoading] = useState(false);
  const [archives, setArchives] = useState<Archive[]>([]);
  const [items, setItems] = useState<File[]>([]);
  const [totalCount, setTotalCount] = useState(0);
  const [currentPage, setCurrentPage] = useState(1);
  const [keyword, setKeyword] = useState('');
  const [archiveIds, setArchiveIds] = useState('');
  const [refreshTrigger, setRefreshTrigger] = useState(1);
  const { getFieldDecorator, setFieldsValue } = props.form;

  useEffect(() => {
    const fetchDetail = async () => {
      setLoading(true);
      try {
        const result = await FileService.fetchArchives(archiveGroupId);
        const archives = Archive.fromJsonArray(result.archives);
        const archive_ids = archives.map(el => el.id);
        setArchives(archives);
        setArchiveIds(archive_ids.join(','));
      } catch (error) {
        Util.showError(error);
      } finally {
        setLoading(false);
      }
    };

    if (archiveGroupId > 0) {
      fetchDetail();
    }
  }, [archiveGroupId]);

  const pagination = {
    pageSize: 15,
    current: currentPage,
    total: totalCount,
    showTotal: (total: number, range: [number, number]) =>
      `전체 ${total}건 중 ${range[0]}-${range[1]}`
  };

  useEffect(() => {
    const fetchItems = async () => {
      setLoading(true);
      try {
        const result = await FileService.fetchArchiveItems(
          pagination.pageSize,
          currentPage,
          keyword,
          archiveIds,
          orderId
        );
        setTotalCount(result.total_count);
        setItems(File.fromJsonArray(result.items));
      } catch (error) {
        Util.showError(error);
      } finally {
        setLoading(false);
      }
    };

    if (archiveIds.length > 0) {
      fetchItems();
    }
  }, [
    currentPage,
    pagination.pageSize,
    keyword,
    archiveIds,
    orderId,
    refreshTrigger
  ]);

  const columns = [
    {
      title: '구분',
      dataIndex: 'archive_name',
      ellipsis: true,
      width: '24%'
    },
    {
      title: '이름',
      dataIndex: 'original_name',
      ellipsis: true,
      render: (text: string, record: File) => {
        return (
          <>
            <LinkButton onClick={e => onClickDownloadButton(record)}>
              <Icon type="download" />
            </LinkButton>{' '}
            {record.isPreviewable ? (
              <AButton onClick={e => FileService.previewFile(record)}>
                {text}
              </AButton>
            ) : (
              <>{text}</>
            )}
            {record.tags.length > 0 && (
              <div>
                {record.tags.map((tag, index) => (
                  <Tag key={index}>{tag}</Tag>
                ))}
              </div>
            )}
          </>
        );
      }
    },
    {
      title: '버전',
      dataIndex: 'revision',
      width: 45,
      align: 'right' as 'right'
    },
    {
      title: '크기',
      dataIndex: 'size',
      width: 90,
      align: 'right' as 'right',
      render: (text: string, record: File) => {
        return filesize(record.size, { round: 0 });
      }
    },
    // {
    //   title: '태그',
    //   dataIndex: 'tags',
    //   render: (text: string, record: File) => {
    //     return (
    //       <>
    //         {record.tags.map((tag, index) => (
    //           <Tag key={index}>{tag}</Tag>
    //         ))}
    //       </>
    //     );
    //   }
    // },
    {
      title: '등록자',
      width: 100,
      dataIndex: 'owner.name'
    },
    {
      title: '등록일',
      dataIndex: 'created_at',
      width: 100,
      render: (text: string, record: File) => Util.formatDate(record.created_at)
    },
    {
      width: 80,
      render: (text: string, record: File) => {
        return (
          <>
            <LinkButton onClick={e => onClickEditButton(record)}>
              수정
            </LinkButton>{' '}
            |{' '}
            <Popconfirm
              title="정말 삭제하시겠습니까?"
              onConfirm={e => onClickDeleteButton(record)}
            >
              <LinkButton>삭제</LinkButton>
            </Popconfirm>
          </>
        );
      }
    }
  ];

  const onSelectArchives = (archive_ids: number[]) => {
    setArchiveIds(archive_ids.join(','));
    setCurrentPage(1);
    setKeyword('');
    setFieldsValue({ keyword: '' });
  };

  const onChangeTable = (pagination: PaginationConfig) => {
    setCurrentPage(pagination.current || 1);
  };

  const [fileUploaderVisible, setFileUploaderVisible] = useState(false);
  const [fileEditorVisible, setFileEditorVisible] = useState(false);
  const currentFileRef = useRef<File>(new File());
  const isFileGroupDirtyRef = useRef(false);

  const handleAddButtonClick = () => {
    setFileUploaderVisible(true);
  };

  const onCloseUploader = () => {
    setFileUploaderVisible(false);
  };

  const onSuccessUploading = () => {
    setRefreshTrigger(v => v + 1);
    setFileUploaderVisible(false);
  };

  const onClickEditButton = (item: File) => {
    currentFileRef.current = item;
    setFileEditorVisible(true);
  };

  const onCloseEditor = () => {
    setFileEditorVisible(false);
    if (isFileGroupDirtyRef.current) {
      isFileGroupDirtyRef.current = false;
      setRefreshTrigger(v => v + 1);
    }
  };

  const onSuccessEditing = () => {
    setRefreshTrigger(v => v + 1);
    setFileEditorVisible(false);
  };

  const onChangeFileGroup = () => {
    // 버전 추가,삭제등 file group에 변경 발생시 dirty 플래그 세팅
    isFileGroupDirtyRef.current = true;
  };

  const onClickDeleteButton = async (item: File) => {
    try {
      await FileService.deleteItem(item.id);
      Util.showSuccess('삭제되었습니다.');
      setRefreshTrigger(v => v + 1);
    } catch (err) {
      Util.showError(err);
    }
  };

  const onClickDownloadButton = async (item: File) => {
    try {
      setLoading(true);
      await FileService.downloadFile(item.id, item.original_name);
    } catch (error) {
      Util.showError(error);
    } finally {
      setLoading(false);
    }
  };

  const onSubmitSearchForm = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    props.form.validateFields((err, values) => {
      if (err) return;
      setKeyword(values.keyword);
      setCurrentPage(1);
    });
  };

  console.count('ArchiveGroupBrowser');

  const title = archives.length > 0 ? archives[0].group_name : '';

  return (
    <div>
      <h2>{title}</h2>

      <Row>
        <Col xl={5} xxl={4}>
          <ArchiveTree archives={archives} onSelectItem={onSelectArchives} />
        </Col>
        <Col xl={19} xxl={20}>
          <Form
            className="hl-form"
            layout="inline"
            style={{ marginBottom: 10, marginLeft: 15 }}
            onSubmit={onSubmitSearchForm}
          >
            <Row type="flex" justify="space-between">
              <Col>
                <Form.Item>
                  {getFieldDecorator('keyword')(
                    <Input.Search
                      placeholder="검색어"
                      allowClear
                      style={{ width: 200 }}
                    />
                  )}
                </Form.Item>

                <Form.Item>
                  <Button type="primary" htmlType="submit">
                    검색
                  </Button>
                </Form.Item>
              </Col>
              <Col>
                <Button onClick={handleAddButtonClick}>
                  <Icon type="plus" />
                  파일 등록
                </Button>
              </Col>
            </Row>
          </Form>

          <Table
            columns={columns}
            dataSource={items}
            rowKey="id"
            pagination={pagination}
            onChange={onChangeTable}
            loading={loading}
            size="middle"
            bordered={true}
            style={{ marginLeft: 15 }}
          />
        </Col>
      </Row>

      <Drawer
        title="파일 등록"
        width={800}
        visible={fileUploaderVisible}
        onClose={onCloseUploader}
        destroyOnClose={true}
      >
        <ArchiveFileUploader
          archives={archives}
          orderId={orderId}
          onSuccess={onSuccessUploading}
        />
      </Drawer>

      <Drawer
        title="파일 수정"
        width={800}
        visible={fileEditorVisible}
        onClose={onCloseEditor}
        destroyOnClose={true}
      >
        <ArchiveFileGroupEditor
          archives={archives}
          orderId={orderId}
          file={currentFileRef.current}
          onSuccess={onSuccessEditing}
          onChange={onChangeFileGroup}
        />
      </Drawer>
    </div>
  );
};

const _ArchiveGroupBrowser = Form.create<Props>()(ArchiveGroupBrowser);
export default withRouter(_ArchiveGroupBrowser);
