import React, { useState, useEffect, useRef } from 'react';
import { Modal, Row, Col, Button, Table, InputNumber, Icon, Form } from 'antd';
import FinanceService from '../../../services/FinanceService';
import { CarryOver } from '../../../models/Finance';
import Util from '../../../helpers/Util';
import CarryOverEditor from './CarryOverEditor';
import LinkButton from '../../../components/general/LinkButton';
import { FormComponentProps } from 'antd/lib/form';

interface Props extends FormComponentProps {
  onChange?: () => void;
}

const CarryOverList: React.FC<Props> = props => {
  const [itemEditorVisible, setItemEditorVisible] = useState(false);
  const [refreshTrigger, setRefreshTrigger] = useState(1);
  const [year, setYear] = useState(new Date().getFullYear());
  const { getFieldDecorator } = props.form;

  const [loading, setLoading] = useState(false);
  const [items, setItems] = useState<CarryOver[]>([]);
  useEffect(() => {
    const fetchDetail = async () => {
      setLoading(true);
      try {
        const result = await FinanceService.fetchCarryOvers(year);
        setItems(CarryOver.fromJsonArray(result.items));
      } catch (error) {
        Util.showError(error);
      } finally {
        setLoading(false);
      }
    };

    fetchDetail();
  }, [year, refreshTrigger]);

  const itemRef = useRef<CarryOver | undefined>(undefined);

  const onClickAddItem = () => {
    itemRef.current = undefined;
    setItemEditorVisible(true);
  };

  const onClickItem = (item: CarryOver) => {
    itemRef.current = item;
    setItemEditorVisible(true);
  };

  const onSuccessEditingItem = () => {
    setRefreshTrigger(v => v + 1);
    setItemEditorVisible(false);
    props.onChange && props.onChange();
  };

  let columns = [
    {
      title: '년도',
      dataIndex: 'year',
      width: 100
    },
    {
      title: '법인명',
      dataIndex: 'corp.name',
      width: 200,
      render: (text: string, record: CarryOver) => {
        return (
          <LinkButton onClick={e => onClickItem(record)}>{text}</LinkButton>
        );
      }
    },
    {
      title: '이월금액',
      dataIndex: 'f_amount',
      align: 'right' as 'right'
    }
  ];

  const onSubmitSearchForm = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    props.form.validateFields((err, values) => {
      if (err) return;
      if (values.year) setYear(values.year);
    });
  };

  console.count('CarryOverList');

  return (
    <>
      <Form
        className="hl-form"
        layout="inline"
        style={{ marginBottom: 7 }}
        onSubmit={onSubmitSearchForm}
      >
        <Row type="flex" justify="space-between">
          <Col>
            <Form.Item>
              {getFieldDecorator('year', {
                initialValue: new Date().getFullYear()
              })(
                <InputNumber
                  placeholder="년도"
                  style={{ width: 100 }}
                  formatter={value => `${value}년`}
                />
              )}
            </Form.Item>

            <Form.Item>
              <Button type="primary" htmlType="submit">
                검색
              </Button>
            </Form.Item>
          </Col>

          <Col>
            <Button onClick={onClickAddItem}>
              <Icon type="plus" />
              이월금액 등록
            </Button>
          </Col>
        </Row>
      </Form>

      <Table
        columns={columns}
        dataSource={items}
        rowKey="corp_id"
        pagination={false}
        bordered={true}
        loading={loading}
        size="middle"
      />

      <Modal
        title={itemRef.current ? '이월금액 수정' : '이월금액 등록'}
        width={500}
        centered
        visible={itemEditorVisible}
        footer={null}
        onCancel={() => setItemEditorVisible(false)}
        destroyOnClose={true}
      >
        <CarryOverEditor
          item={itemRef.current}
          onSuccess={onSuccessEditingItem}
        />
      </Modal>
    </>
  );
};

export default Form.create<Props>()(CarryOverList);
