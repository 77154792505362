import React, { useState, useEffect } from 'react';
import { Row, Col, Empty, Button, Icon, Tabs } from 'antd';
import { withRouter, RouteComponentProps } from 'react-router-dom';
import PageService from '../../services/PageService';
import Util from '../../helpers/Util';
import { ContentPage } from '../../models/Page';
import HTMLContentViewer from '../../components/general/HTMLContentViewer';
import PageLogs from './PageLogs';
import {
  HDescriptions,
  HDRowItem
} from '../../components/general/HDescriptions';
import AttachedFileList from '../../components/general/AttachedFileList';

interface Props extends RouteComponentProps {
  id: number;
  showLogs?: boolean;
}

const PageViewer: React.FC<Props> = props => {
  const pageId = props.id;
  const { showLogs = false } = props;
  const [loading, setLoading] = useState(false);
  const [item, setItem] = useState<ContentPage | undefined>(undefined);

  useEffect(() => {
    const fetchDetail = async () => {
      setLoading(true);
      try {
        const result = await PageService.fetchItem(pageId);
        const contentPage = ContentPage.fromJson(result.page);
        setItem(contentPage);
      } catch (error) {
        Util.showError(error);
      } finally {
        setLoading(false);
      }
    };

    fetchDetail();
  }, [pageId]);

  const onClickEdit = () => {
    props.history.push(
      `./page_editor?id=${pageId}&pathname=${props.location.pathname}`
    );
  };

  const renderContentSection = () => {
    if (!item) return <></>;

    if (item.isEmpty) {
      return (
        <div className="hl-form" style={{ backgroundColor: 'white' }}>
          <Empty image={Empty.PRESENTED_IMAGE_SIMPLE}>
            <Button type="primary" onClick={onClickEdit}>
              문서 생성
            </Button>
          </Empty>
        </div>
      );
    }

    return (
      <>
        {item.files.length > 0 && (
          <HDescriptions>
            <HDRowItem label="첨부 파일" colSpan={5}>
              <AttachedFileList items={item.files} />
            </HDRowItem>
          </HDescriptions>
        )}
        <HTMLContentViewer content={item.content || ''} style={{ margin: 0 }} />
        <Row type="flex" justify="space-between" style={{ marginTop: '10px' }}>
          <Col></Col>
          <Col className="gutter-row">
            <Row gutter={10}>
              <Col span={12}>
                <Button disabled={loading} onClick={e => onClickEdit()}>
                  <Icon type="edit" />수 정
                </Button>
              </Col>
            </Row>
          </Col>
        </Row>
      </>
    );
  };

  return (
    <>
      <h2>{item && item.title}</h2>

      {showLogs ? (
        <Tabs type="card">
          <Tabs.TabPane tab="문서내용" key="tab1">
            {renderContentSection()}
          </Tabs.TabPane>
          <Tabs.TabPane tab="변경내역" key="tab2">
            <PageLogs pageId={(item && item.id) || 0} />
          </Tabs.TabPane>
        </Tabs>
      ) : (
        <>{renderContentSection()}</>
      )}
    </>
  );
};

export default withRouter(PageViewer);
