import Service from './Index';

const PostService = {
  fetchItems: (
    limit: number,
    page: number,
    type: number,
    ref_id?: number,
    keyword?: string
  ) => {
    const offset = (page - 1) * limit;
    return Service.get('/posts', {
      limit,
      offset,
      type,
      ref_id,
      keyword
    });
  },
  fetchItem: (id: number) => {
    return Service.get(`/posts/${id}`);
  },
  create: (data: any) => {
    return Service.post('/posts', data);
  },
  update: (id: number, data: any) => {
    return Service.put(`/posts/${id}`, data);
  },
  delete: (id: number) => {
    return Service.delete(`/posts/${id}`);
  },
  read: (id: number) => {
    return Service.put(`/posts/${id}/read`);
  }
};

export default PostService;
