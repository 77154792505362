import React, { useState, useEffect, useRef } from 'react';
import { Table, Row, Col, Input, Button, Icon, Drawer, Form } from 'antd';
import { withRouter, RouteComponentProps } from 'react-router';
import { inject, observer } from 'mobx-react';
import { Company, CompanyType } from '../../../models/Compnay';
import CompanyService, {
  CompanySearchOption
} from '../../../services/CompanyService';
import Util from '../../../helpers/Util';
import { PaginationConfig } from 'antd/lib/table';
import LinkButton from '../../../components/general/LinkButton';
import CompanyEdit from './CompanyEdit';
import FCStringCodeSelect from '../../../components/formcomps/FCStringCodeSelect';
import { FormComponentProps } from 'antd/lib/form';
import { AuthStore } from '../../../stores/authStore';

interface Props extends FormComponentProps, RouteComponentProps {
  authStore?: AuthStore;
  title?: string;
  type?: CompanyType;
  onSelectItems?: (items: Company[]) => void;
}

const CompanyList: React.FC<Props> = props => {
  const { location, onSelectItems } = props;
  const authStore = props.authStore!;
  const params = new URLSearchParams(location.search);
  const type = Number(props.type || params.get('type') || 1) as CompanyType;
  const [allowEditing, setAllowEditing] = useState(false);

  let title = props.title;
  if (title === undefined) {
    title = `${Company.typeName(type)} 관리`;
  }

  const [items, setItems] = useState<Company[]>([]);
  const [searchOption, setSearchOption] = useState<CompanySearchOption>({});
  const [totalCount, setTotalCount] = useState(0);
  const [currentPage, setCurrentPage] = useState(1);
  const [loading, setLoading] = useState(false);
  const [refreshTrigger, setRefreshTrigger] = useState(0);
  const [categories, setCategories] = useState<string[]>([]);
  const { getFieldDecorator } = props.form;

  const pagination = {
    pageSize: 15,
    current: currentPage,
    total: totalCount,
    showTotal: (total: number, range: [number, number]) =>
      `전체 ${total}건 중 ${range[0]}-${range[1]}`
  };

  useEffect(() => {
    const fetchCategories = async () => {
      const result = await CompanyService.fetchCategories(type);
      setCategories(result.categories);
    };

    fetchCategories();

    // TODO: 임시 권한설정
    setAllowEditing(
      (authStore.user.rootDept && authStore.user.rootDept.id === 2) || // 경영관리부문 소속이거나
      authStore.user.id === 1 || // admin 이거나
        type === CompanyType.buyer // 발주처인 경우
    );
  }, [type, authStore.user]);

  useEffect(() => {
    const fetchItems = async () => {
      setLoading(true);
      try {
        const result = await CompanyService.fetchItems(
          pagination.pageSize,
          currentPage,
          { ...searchOption, type }
        );
        setTotalCount(result.total_count);
        setItems(Company.fromJsonArray(result.items));
      } catch (error) {
        Util.showError(error);
      } finally {
        setLoading(false);
      }
    };

    fetchItems();
  }, [type, currentPage, searchOption, refreshTrigger, pagination.pageSize]);

  const columns = [
    {
      title: 'No',
      dataIndex: 'id',
      align: 'right' as 'right'
    },
    {
      title: '업종',
      dataIndex: 'business_category'
    },
    {
      title: '상호',
      dataIndex: 'name',
      render: (text: string, record: Company) => (
        <LinkButton onClick={e => onClickItem(record)}>
          {record.name}
        </LinkButton>
      )
    },
    {
      title: '대표자',
      dataIndex: 'ceo'
    },
    {
      title: '사업자번호',
      dataIndex: 'business_no'
    },
    {
      title: '전화',
      dataIndex: 'phone'
    },
    {
      width: '50px',
      render: (text: string, record: Company) => {
        return (
          <LinkButton onClick={e => onClickDelete(record)} disabled>
            삭제
          </LinkButton>
        );
      }
    }
  ];

  const [editorVisible, setEditorVisible] = useState(false);
  const editingItemRef = useRef(0);

  const onClickItem = (item: Company) => {
    editingItemRef.current = item.id;
    setEditorVisible(true);
  };

  const onClickAddButton = () => {
    editingItemRef.current = 0;
    setEditorVisible(true);
  };

  const onCloseEditor = () => {
    setEditorVisible(false);
  };

  const onCreateCompany = () => {
    setEditorVisible(false);
    setTimeout(() => {
      setRefreshTrigger(Date.now());
    }, 200);
  };

  const onClickDelete = async (item: Company) => {
    try {
      await CompanyService.delete(item.id);
      setRefreshTrigger(Date.now());
    } catch (err) {
      Util.showError(err);
    }
  };

  const onChangeTable = (pagination: PaginationConfig) => {
    setCurrentPage(pagination.current || 1);
  };

  let rowSelection: any = undefined;
  if (onSelectItems) {
    rowSelection = {
      onChange: (selectedRowKeys: any, selectedRows: any) => {
        onSelectItems(selectedRows as Company[]);
      }
    };
  }

  const onSubmitSearchForm = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    props.form.validateFields((err, values) => {
      if (err) return;
      setSearchOption(values);
      setCurrentPage(1);
    });
  };

  return (
    <>
      <h2>{title}</h2>

      <Form
        className="hl-form"
        layout="inline"
        style={{ marginBottom: 7 }}
        onSubmit={onSubmitSearchForm}
      >
        <Row type="flex" justify="space-between">
          <Col>
            <Form.Item>
              {getFieldDecorator('keyword')(
                <Input.Search
                  placeholder="검색어"
                  allowClear={true}
                  style={{ width: 180 }}
                />
              )}
            </Form.Item>

            <Form.Item>
              <FCStringCodeSelect
                form={props.form}
                name="category"
                allowClear={true}
                placeholder="업종"
                items={categories}
                style={{ width: '180px' }}
              />
            </Form.Item>

            <Form.Item>
              <Button type="primary" htmlType="submit">
                검색
              </Button>
            </Form.Item>
          </Col>
          <Col>
            {allowEditing && (
              <Button onClick={onClickAddButton}>
                <Icon type="plus" /> 업체 등록
              </Button>
            )}
          </Col>{' '}
        </Row>
      </Form>

      <Table
        columns={columns}
        dataSource={items}
        rowKey="id"
        pagination={pagination}
        onChange={onChangeTable}
        bordered={true}
        loading={loading}
        size="middle"
        rowSelection={rowSelection}
      />

      <Drawer
        closable={false}
        placement="right"
        width={1000}
        visible={editorVisible}
        onClose={onCloseEditor}
        destroyOnClose={true}
      >
        <CompanyEdit
          onSuccess={onCreateCompany}
          id={editingItemRef.current}
          type={type}
        />
      </Drawer>
    </>
  );
};

const _CompanyList = Form.create<Props>()(CompanyList);
export default inject('authStore')(observer(withRouter(_CompanyList)));
