import React from 'react';
import { Route, Switch, Redirect } from 'react-router-dom';
import UnderConst from '../../components/UnderConst';
import EADocDrafter from '../eas/EADocDrafter';
import EADocListByType from '../eas/EADocListByType';
import EADocListAll from '../eas/EADocListAll';
import EAFlowTemplateList from '../eas/EAFlowTemplateList';
import EADocView from '../eas/EADocView';
import EADocEditor from '../eas/EADocEditor';
import UserVacationRecords from './UserVacationRecords';
import MyEducationList from './educations/MyEducationList';
import MyTripList from './trips/MyTripList';
import TripEADocList from './trips/TripEADocList';
import EducationSchedules from './educations/EducationSchedules';
import { AuthStore } from '../../stores/authStore';
import { inject, observer } from 'mobx-react';
import _ from 'lodash';

interface Props {
  authStore?: AuthStore;
}

const Index: React.FC<Props> = props => {
  const authStore = props.authStore!;

  // TODO: 리팩토링
  // menus/eas.js, SidebarNew.tsx, EADocSelectorDrawer 참고
  let allowAccessAllEADocs = undefined;
  if (authStore.user.dept) {
    const userDeptId = authStore.user.dept.id;
    allowAccessAllEADocs = _.find([1, 2, 11, 12, 13], e => e === userDeptId);
  }

  return (
    <Switch>
      <Route path="/works/eas/uc" component={UnderConst} />

      <Route path="/works/eas" exact>
        <Redirect
          to={`/works/eas/list?type=${localStorage.getItem(
            'homeEADocActiveTabKey'
          ) || 'sent'}`}
        />
      </Route>

      {allowAccessAllEADocs && (
        <Route path="/works/eas/list_all" component={EADocListAll} />
      )}

      <Route path="/works/eas/list" component={EADocListByType} />
      <Route path="/works/eas/view" component={EADocView} />
      <Route path="/works/eas/flow_templates" component={EAFlowTemplateList} />
      <Route path="/works/eas/draft">
        <EADocDrafter title="기안하기" />
      </Route>
      <Route path="/works/eas/edit" component={EADocEditor} />

      <Route
        path="/works/eas/vacations/records"
        component={UserVacationRecords}
      />
      <Route path="/works/eas/vacations/apply">
        <EADocDrafter
          title="근태신청서"
          docTitle="연차/휴가신청"
          refTarget="vac"
          privateHidden
        />
      </Route>

      <Route path="/works/eas/myedu" component={MyEducationList} />
      <Route path="/works/eas/edu" component={EducationSchedules} />

      <Route path="/works/eas/trips/eadocs">
        <TripEADocList userId={authStore.user.id} hideDeptSelector />
      </Route>
      <Route path="/works/eas/trips/mytrip" component={MyTripList} />
      <Route path="/works/eas/trips/apply">
        <EADocDrafter
          title="출장명령품의서"
          docTitle="출장명령품의서"
          preventEditingTitle
          refTarget="trip"
          privateHidden
        />
      </Route>
    </Switch>
  );
};

export default inject('authStore')(observer(Index));
