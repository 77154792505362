import React, { useState, useEffect, useCallback } from 'react';
import Form, { FormComponentProps } from 'antd/lib/form';
import { Table, Button, Icon, Input, Checkbox } from 'antd';
import LinkButton from '../../../components/general/LinkButton';
import AppService from '../../../services/AppService';
import Util from '../../../helpers/Util';
import { Order, ContractItem, Contract } from '../../../models/Order';
import _ from 'lodash';
import FCStringCodeSelect from '../../../components/formcomps/FCStringCodeSelect';
import FCTextInput from '../../../components/formcomps/FCTextInput';
import FCNumberInput from '../../../components/formcomps/FCNumberInput';
import Spinner from '../../../components/general/Spinner';
import FIDatePicker from '../../../components/formcomps/FIDatePicker';

interface Props extends FormComponentProps {
  order: Order; // order 필수
  contract?: Contract; // contract 없을 경우 수주 신규 등록으로 간주, 있으면 해당 contract items 수정
  needsRecalc: number;
  restrictEditing?: boolean;
}

const ContractItemsEditor: React.FC<Props> = props => {
  const { getFieldDecorator, getFieldValue, setFieldsValue } = props.form;
  const { order, contract, needsRecalc, restrictEditing = false } = props;

  console.count('ContractItemsEditor');

  const [loading, setLoading] = useState(true);
  const [terms, setTerms] = useState<string[]>([]);
  const [items, setItems] = useState<ContractItem[]>([]);
  const [totalItem] = useState<ContractItem>(new ContractItem(0));
  const [nextId, setNextId] = useState(1);

  useEffect(() => {
    const fetchItems = async () => {
      setLoading(true);
      try {
        if (order.dept_id) {
          const deptValues = await AppService.fetchDeptValues(order.dept_id, [
            'cp_terms'
          ]);
          const terms = deptValues.cp_terms || [];
          setTerms(terms);
        }

        let items: ContractItem[] = [];
        if (contract && contract.items) {
          // order.terms 순서대로 재정렬
          const itemsUnsorted = contract.items;
          for (const term of order.terms) {
            const found = itemsUnsorted.find(item => item.term === term);
            if (found) {
              items.push(found);
            }
          }
          // NOTE: order.terms 보다 contract.items가 더 많은 경우 누락된 항목을 포함하기 위해
          // 정렬된 결과 배열에 contract.items를 더함
          // (상황: 외주계약품의 결재문서상에서 수정하는 경우, order.terms 업데이트되지 않은 상태에서 contract.items 편집하는 경우)
          items = _.uniq([...items, ...contract.items]);
        }

        if (items.length === 0) {
          // 기존 입력 항목이 없을 경우 디폴트 항목 표시
          items = order.terms.map((el: string, i: number) => {
            const item = new ContractItem(i + 1);
            item.contract_id = contract ? contract.id : 0;
            item.term = el;
            return item;
          });
        }
        setItems(items);

        const maxIdItem = _.maxBy(items, 'seq');
        const maxId = maxIdItem ? maxIdItem.seq : items.length;
        setNextId(maxId + 1);
      } catch (error) {
        Util.showError(error);
      } finally {
        setLoading(false);
      }
    };

    fetchItems();
  }, [order, contract]);

  const autoCalc: boolean = getFieldValue('autocalc');

  let columns = [
    {
      dataIndex: 'index',
      render: (text: string, record: any, index: number) => {
        return (
          <div style={{ width: '20px', textAlign: 'right' }}>
            {record.seq === 0 ? '' : index + 1 + '.'}
          </div>
        );
      }
    },
    {
      title: '구분',
      dataIndex: 'term',
      width: '230px',
      render: (text: string, record: any) => {
        if (record.seq === 0) {
          return <div style={{ textAlign: 'center' }}>합 계</div>;
        }
        return (
          <Form.Item>
            <FCStringCodeSelect
              form={props.form}
              name={`term_${record.seq}`}
              items={terms}
              style={{ width: '230px' }}
              options={{ initialValue: record.term }}
              onChange={onChangeTerm}
              disabled={restrictEditing}
            />
          </Form.Item>
        );
      }
    },
    {
      title: '비율(%)',
      dataIndex: 'rate',
      render: (text: string, record: any) => {
        return (
          <Form.Item>
            <FCNumberInput
              form={props.form}
              name={`rate_${record.seq}`}
              size="small"
              readOnly={record.seq === 0}
              options={{ initialValue: record.rate }}
              onEnter={value => onEnterItemValue(record.seq, value)}
            />
          </Form.Item>
        );
      }
    },
    {
      title: '과세공급가',
      dataIndex: 'tax_price',
      render: (text: string, record: any) => {
        return (
          <Form.Item>
            <FCNumberInput
              form={props.form}
              name={`tax_price_${record.seq}`}
              disabled={record.seq === 0 || autoCalc}
              options={{ initialValue: record.tax_price }}
              onEnter={value => onEnterItemValue(record.seq, value)}
            />
          </Form.Item>
        );
      }
    },
    {
      title: '면세공급가',
      dataIndex: 'notax_price',
      render: (text: string, record: any) => {
        return (
          <Form.Item>
            <FCNumberInput
              form={props.form}
              name={`notax_price_${record.seq}`}
              disabled={record.seq === 0 || autoCalc}
              options={{ initialValue: record.notax_price }}
              onEnter={value => onEnterItemValue(record.seq, value)}
            />
          </Form.Item>
        );
      }
    },
    {
      title: '합계금액',
      dataIndex: 'total_price',
      render: (text: string, record: any) => {
        return (
          <Form.Item>
            <FCNumberInput
              form={props.form}
              name={`total_price_${record.seq}`}
              disabled={record.seq === 0 || autoCalc}
              options={{ initialValue: record.total_price }}
              onEnter={value => onEnterItemTotalValue(record.seq, value)}
            />
          </Form.Item>
        );
      }
    },
    {
      title: contract && contract.out ? '지급예정일' : '수금예정일',
      dataIndex: 'est_date',
      render: (text: string, record: any) => {
        if (record.seq === 0) {
          return <></>;
        }
        return (
          <FIDatePicker
            form={props.form}
            name={`est_date_${record.seq}`}
            initialValue={record.est_date && Util.formatDate(record.est_date)}
            onChange={onChangeEstDate}
          />
        );
      }
    },
    {
      title: '비고',
      dataIndex: 'note',
      render: (text: string, record: any) => {
        if (record.seq === 0) {
          return <></>;
        }
        return (
          <Form.Item style={{ width: '240px' }}>
            <FCTextInput
              form={props.form}
              name={`note_${record.seq}`}
              options={{ initialValue: record.note }}
              onEnter={value => onChangeNote()}
            />
          </Form.Item>
        );
      }
    },
    {
      render: (text: string, record: any) => {
        if (record.seq === 0 || restrictEditing) {
          return <></>;
        }
        return (
          <LinkButton onClick={e => handleDeleteItem(record.seq)}>
            <Icon type="close" />
          </LinkButton>
        );
      }
    }
  ];

  if (contract && contract.out) {
    columns.splice(6, 0, {
      title: '도래',
      dataIndex: 'advent',
      render: (text: string, record: any) => {
        if (record.seq === 0) {
          return <></>;
        }
        return (
          <Form.Item style={{ width: '40px' }}>
            {getFieldDecorator(`advent_${record.seq}`, {
              valuePropName: 'checked',
              initialValue: record.advent
            })(<Checkbox onChange={onChangeAdvent} />)}
          </Form.Item>
        );
      }
    });
  }

  const handleAddButtonClick = () => {
    const count = Number(getFieldValue('countToAdd'));

    let nextItemId = nextId;
    const addedItems = Array.from(Array(count).keys()).map((e: number) => {
      const item = new ContractItem(nextItemId++);
      item.contract_id = contract ? contract.id : 0;
      return item;
    });

    const newItems = [...items, ...addedItems];
    setItems(newItems);
    setFieldsValue({ item_count: newItems.length });

    setNextId(nextItemId);
  };

  const handleDeleteItem = (id: number) => {
    let newItems = items.filter((item: any) => item.seq !== id);
    setItems(newItems);
    setFieldsValue({ item_count: newItems.length });
  };

  const onEnterItemValue = (
    seq: number,
    value: number | string | undefined
  ) => {
    if (value === undefined) return;

    if (autoCalc) {
      calcItems();
    } else {
      calcItemTotalForSeq(seq);
      calcAllItemsTotal(); // 전체 행의 합계 계산 (최하단 행)
    }
  };

  const onEnterItemTotalValue = (
    seq: number,
    value: number | string | undefined
  ) => {
    if (value === undefined) return;
    if (autoCalc) return;
    calcAllItemsTotal(); // 전체 행의 합계 계산 (최하단 행)
  };

  useEffect(() => {
    calcItems();
  }, [needsRecalc]);

  // 비율에 따른 자동 계산 처리
  const calcItems = () => {
    const taxPrice = Number(getFieldValue('tax_price'));
    const noTaxPrice = Number(getFieldValue('notax_price'));
    const totalPrice = Number(getFieldValue('total_price'));
    if (isNaN(taxPrice) || isNaN(noTaxPrice) || isNaN(totalPrice)) return;
    for (const item of items) {
      const itemRate = Number(getFieldValue(`rate_${item.seq}`));
      if (!isNaN(itemRate)) {
        const itemTaxPrice = Math.floor((taxPrice * itemRate) / 100);
        const itemNoTaxPrice = Math.floor((noTaxPrice * itemRate) / 100);
        const itemTotalPrice = Math.floor((totalPrice * itemRate) / 100);
        setFieldsValue({
          [`tax_price_${item.seq}`]: itemTaxPrice,
          [`notax_price_${item.seq}`]: itemNoTaxPrice,
          [`total_price_${item.seq}`]: itemTotalPrice
        });
      }
    }
    calcAllItemsTotal();
  };

  const updateAllItems = useCallback(() => {
    const updatedItems = items.map((item: ContractItem) => {
      item.term = getFieldValue(`term_${item.seq}`);
      item.rate = getFieldValue(`rate_${item.seq}`);
      item.tax_price = getFieldValue(`tax_price_${item.seq}`);
      item.notax_price = getFieldValue(`notax_price_${item.seq}`);
      item.total_price = getFieldValue(`total_price_${item.seq}`);
      item.note = getFieldValue(`note_${item.seq}`);
      item.est_date = getFieldValue(`est_date_${item.seq}`);
      if (contract && contract.out) {
        item.advent = getFieldValue(`advent_${item.seq}`);
      }
      return item;
    });

    // 실제 유효한 항목만 저장하기 위해 total_price > 0인 것들만 필터링해서 all_items 히든 필드에 저장
    // const validItems = updatedItems.filter(item => item.total_price > 0);
    // 했었으나 0으로 업데이트 처리를 해야할 경우 동작하지 않는 문제가 있어 해당 조건 제거
    setFieldsValue({
      all_items: JSON.stringify(updatedItems)
    });

    return updatedItems;
  }, [items, getFieldValue, setFieldsValue]);

  // 특정 Seq 행의 합계 계산 (오른쪽 컬럼)
  const calcItemTotalForSeq = useCallback(
    (seq: number) => {
      const taxPrice = Number(getFieldValue(`tax_price_${seq}`));
      const notaxPrice = Number(getFieldValue(`notax_price_${seq}`));
      const itemTotalPrice = taxPrice + Math.floor(taxPrice * 0.1) + notaxPrice;
      setFieldsValue({
        [`total_price_${seq}`]: itemTotalPrice
      });
    },
    [getFieldValue, setFieldsValue]
  );

  // 각 행의 합계 계산 (오른쪽 컬럼)
  // const calcItemTotal = useCallback(() => {
  //   for (const item of items) {
  //     const taxPrice = Number(getFieldValue(`tax_price_${item.seq}`));
  //     const notaxPrice = Number(getFieldValue(`notax_price_${item.seq}`));
  //     const itemTotalPrice = taxPrice + Math.floor(taxPrice * 0.1) + notaxPrice;
  //     setFieldsValue({
  //       [`total_price_${item.seq}`]: itemTotalPrice
  //     });
  //   }
  // }, [items, getFieldValue, setFieldsValue]);

  // 전체 행의 합계 계산 (최하단 행)
  const calcAllItemsTotal = useCallback(() => {
    if (loading) return;
    const updatedItems = updateAllItems();
    setFieldsValue({
      rate_0: _.sumBy(updatedItems, item => Number(item.rate) || 0),
      tax_price_0: _.sumBy(updatedItems, item => Number(item.tax_price) || 0),
      notax_price_0: _.sumBy(
        updatedItems,
        item => Number(item.notax_price) || 0
      ),
      total_price_0: _.sumBy(
        updatedItems,
        item => Number(item.total_price) || 0
      )
    });
  }, [setFieldsValue, updateAllItems, loading]);

  // 항목 삭제시 합계 자동 계산
  useEffect(() => {
    calcAllItemsTotal();
  }, [items.length, calcAllItemsTotal]);

  const [allItemsUpdateTrigger, setAllItemsUpdateTrigger] = useState(0);
  const onChangeTerm = () => {
    setAllItemsUpdateTrigger(allItemsUpdateTrigger + 1);
  };

  const onChangeEstDate = () => {
    setAllItemsUpdateTrigger(allItemsUpdateTrigger + 1);
  };

  const onChangeNote = () => {
    setAllItemsUpdateTrigger(allItemsUpdateTrigger + 1);
  };

  const onChangeAdvent = () => {
    setAllItemsUpdateTrigger(allItemsUpdateTrigger + 1);
  };

  // 구분항목 변경시 all_items 필드 업데이트 처리
  useEffect(() => {
    if (allItemsUpdateTrigger > 0) updateAllItems();
  }, [allItemsUpdateTrigger, updateAllItems]);

  if (loading) {
    return <Spinner />;
  }

  const formItemLayout = {
    labelCol: { span: 4 },
    wrapperCol: { span: 19 }
  };

  return (
    <>
      <Form {...formItemLayout}>
        <FCNumberInput
          form={props.form}
          name="countToAdd"
          min={1}
          max={100}
          size="small"
          options={{ initialValue: 10 }}
        />{' '}
        <Button
          onClick={handleAddButtonClick}
          style={{ marginBottom: 10 }}
          disabled={restrictEditing}
        >
          <Icon type="plus" />열 추가
        </Button>
        <Table
          bordered={false}
          size="middle"
          columns={columns}
          dataSource={[...items, totalItem]}
          pagination={false}
          rowKey="seq"
        />
        {getFieldDecorator('item_count', { initialValue: items.length })(
          <Input type="hidden" />
        )}
        {getFieldDecorator('all_items', {
          initialValue: JSON.stringify(items)
        })(<Input type="hidden" />)}
      </Form>
    </>
  );
};

export default ContractItemsEditor;
