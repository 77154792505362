import React, { useState, useEffect } from 'react';
import { Form } from 'antd';
import { FormComponentProps } from 'antd/lib/form';
import { EADoc } from '../../models/EAS';
import { RouteComponentProps, withRouter } from 'react-router';
import Util from '../../helpers/Util';
import EAService from '../../services/EAService';
import EADocEditContainer from './EADocEditContainer';
import VacationApplyEditor from '../works/VacationApplyEditor';
import OrderEditor from '../projects/orders/OrderEditor';
import OrderService from '../../services/OrderService';
import FREditor from '../../components/general/FREditor';
import OrderOutEditor from '../projects/orders/OrderOutEditor';
import OrderInEditor from '../projects/orders/OrderInEditor';
import _ from 'lodash';
import ContractOutEditor from '../projects/orders/ContractOutEditor';
import TripApplyEditor from '../works/trips/TripApplyEditor';
import TripReportEditor from '../works/trips/TripReportEditor';

interface Props extends FormComponentProps, RouteComponentProps {}

const EADocEditor: React.FC<Props> = props => {
  const { location } = props;
  const params = new URLSearchParams(location.search);
  const docId = Number(params.get('id')) || 0;
  const [item, setItem] = useState<EADoc>(new EADoc());
  const [, setLoading] = useState(true);
  const [submitting, setSubmitting] = useState(false);

  useEffect(() => {
    const fetchItem = async () => {
      setLoading(true);
      try {
        const result = await EAService.fetchItem(docId);
        const doc = EADoc.fromJson(result.eadoc);
        setItem(doc);
      } catch (error) {
        Util.showError(error);
      } finally {
        setLoading(false);
      }
    };

    if (docId > 0) {
      fetchItem();
    }
  }, [docId]);

  // if (loading) {
  //   return <Spinner />;
  // }

  const onSubmit = (eadata: any) => {
    props.form.validateFields((err, values) => {
      if (err) return;
      updateItem(eadata, values);
    });
  };

  const updateItem = async (eadata: any, values: any) => {
    try {
      // console.log('eadata:', eadata);
      // console.log('values:', values);
      // return;

      setSubmitting(true);

      if (item.refTarget) {
        switch (item.refTarget) {
          case 'pmis':
            eadata.data = { content: values.content };
            break;
          case 'eduser':
            eadata.data = { content: values.content };
            break;
          case 'vac':
            values.begin_date =
              values.begin_date && Util.formatDate(values.begin_date);
            values.end_date =
              values.end_date && Util.formatDate(values.end_date);
            eadata.data = values;
            break;
          case 'trip':
            eadata.bccuser_ids = values.users
              ? values.users.map((el: any) => el.id)
              : [];
            eadata.data = _.pick(values, [
              'type',
              'trip_title',
              'reason',
              'place',
              'user',
              'users',
              'begin_date',
              'end_date',
              'content',
              'items'
            ]);
            break;
          case 'trrpt':
            let reportData: any = {};
            reportData.report = values.report;
            reportData.ticket_no = values.ticket_no;
            reportData.request_total = values.request_price_0;
            reportData.approve_total = values.approve_price_0;
            reportData.items = values.items
              .filter((item: any) => item.request_price > 0)
              .map((item: any) => ({
                ...item,
                file_ids: values[`file_ids_${item.id}`]
              }));

            eadata.data = reportData;
            break;
          case 'ord':
            let ordData = _.omit(values, [
              'zipcode',
              'address',
              'site_area',
              'total_floor_area',
              'floor_level',
              'base_level'
            ]);
            ordData.use1 = values.use1.join('/');
            ordData.use2 = values.use2.join('/');
            ordData.use3 = values.use3.join('/');
            ordData.project = {
              id: values.project_id,
              zipcode: values.zipcode,
              address: values.address,
              site_area: values.site_area,
              total_floor_area: values.total_floor_area,
              floor_level: values.floor_level,
              base_level: values.base_level
            };
            eadata.data = ordData;
            break;
          case 'ordin':
            let ordinData: any = _.pick(values, [
              'order_id',
              'out',
              'company_id',
              'contract_date',
              'autocalc',
              'tax_rate',
              'price',
              'tax_price',
              'notax_price',
              'vat',
              'total_price',
              'note'
            ]);
            ordinData.items = JSON.parse(values.all_items);
            eadata.data = ordinData;
            break;
          case 'ctrin':
            let ctrinData: any = _.pick(values, [
              'id', // contract.id
              'order_id',
              'out',
              'company_id',
              'contract_date',
              'autocalc',
              'tax_rate',
              'price',
              'tax_price',
              'notax_price',
              'vat',
              'total_price',
              'note'
            ]);
            ctrinData.items = JSON.parse(values.all_items);
            eadata.data = ctrinData;
            break;
          case 'ordout':
            // NOTE: 수정시 order.order_outs도 함께 최신 데이타로 업데이트
            if (item.refId) {
              await OrderService.update(item.refId, {
                order_outs: values.all_items
              });
            }
            eadata.data = { order_outs: JSON.parse(values.all_items) };
            break;
          case 'ctrout':
            eadata.data = values;
            break;
        }
      } else {
        eadata.data = { content: values.content };
      }

      // console.log(eadata);
      // return;

      await EAService.update(docId, eadata);
      Util.showSuccess('수정되었습니다.');
      props.history.push('/works/eas/view?id=' + docId);
    } catch (err) {
      setSubmitting(false);
      Util.showError(err);
    }
  };

  const renderChildren = () => {
    // console.log('renderChildren,item.refTarget : ', item.refTarget);
    if (item.refTarget === 'vac')
      return <VacationApplyEditor form={props.form} data={item.data} />;
    if (item.refTarget === 'trip')
      return <TripApplyEditor form={props.form} data={item.data} />;
    if (item.refTarget === 'trrpt')
      return (
        <TripReportEditor
          form={props.form}
          data={item.data}
          approvePriceEditable={item.hasRightToApprove}
        />
      );
    if (item.refTarget === 'ord')
      return <OrderEditor form={props.form} data={item.data} />;
    if (item.refTarget === 'ordin' || item.refTarget === 'ctrin')
      return <OrderInEditor form={props.form} data={item.data} />;
    if (item.refTarget === 'ordout')
      return (
        <OrderOutEditor
          form={props.form}
          orderId={item.refId || 0}
          data={item.data}
        />
      );
    if (item.refTarget === 'ctrout')
      return (
        <ContractOutEditor
          form={props.form}
          orderId={item.refId || 0}
          data={item.data}
        />
      );
    if (item.refTarget === 'eduser' || item.refTarget === 'pmis')
      return (
        <FREditor form={props.form} name="content" value={item.data.content} />
      );
    if (item.refTarget === undefined && item.id > 0) {
      // NOTE: 렌더링시 FREditor가 잠깐 표시됐다 사라지는 현상 방지 위해 id > 0 조건 추가
      return (
        <FREditor form={props.form} name="content" value={item.data.content} />
      );
    }
    return <></>;
  };

  let docTitle: string | undefined;
  let privateHidden: boolean | undefined;
  if (item.refTarget === 'vac') {
    docTitle = item.title;
    privateHidden = true;
  }

  return (
    <>
      <EADocEditContainer
        title="문서 수정하기"
        doc={item}
        loading={submitting}
        onSubmit={onSubmit}
        docTitle={docTitle}
        privateHidden={privateHidden}
      >
        {renderChildren()}
      </EADocEditContainer>
    </>
  );
};

const _EADocEditor = Form.create<Props>()(EADocEditor);
export default withRouter(_EADocEditor);
