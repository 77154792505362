import React, { useState, useEffect } from 'react';
import { Menu, Icon } from 'antd';
import { Link, withRouter, RouteComponentProps } from 'react-router-dom';
import { MenuItem } from '../models/Common';
import { SelectParam } from 'antd/lib/menu';

interface Props extends RouteComponentProps {
  menuItems: MenuItem[];
  onSelectMenu?: (key: string) => void;
}

const Header: React.FC<Props> = props => {
  const { menuItems } = props;

  const paths = props.location.pathname.split('/');
  const submenu = paths.length >= 3 ? paths[2] : undefined;

  const [selectedHeaderItem, setSelectedHeaderItem] = useState('');
  useEffect(() => {
    if (submenu) setSelectedHeaderItem(submenu);
  }, [submenu]);

  const onSelectMenu = (param: SelectParam) => {
    props.onSelectMenu && props.onSelectMenu(param.key);
  };

  const renderMenuItem = (item: MenuItem) => {
    return (
      <Menu.Item key={item.id}>
        <Link to={item.link || ''}>
          {item.icon && <Icon type={item.icon} />}
          <span>{item.title}</span>
        </Link>
      </Menu.Item>
    );
  };

  return (
    <Menu
      mode="horizontal"
      selectedKeys={[selectedHeaderItem]}
      onSelect={onSelectMenu}
    >
      {menuItems.map(menu => renderMenuItem(menu))}
    </Menu>
  );
};
export default withRouter(Header);
