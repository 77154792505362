import React, { useState, useEffect } from 'react';
import { Form, Input, Upload, Icon } from 'antd';
import { FormComponentProps } from 'antd/lib/form';
import { GetFieldDecoratorOptions } from 'antd/lib/form/Form';
import InlineItem from './InlineItem';
import { API_ROOT } from '../../config';
import axios from 'axios';
import { UploadChangeParam } from 'antd/lib/upload';
import { UploadFile } from 'antd/lib/upload/interface';
import Util from '../../helpers/Util';
import { File } from '../../models/File';

interface Props extends FormComponentProps {
  label?: string;
  name?: string;
  options?: GetFieldDecoratorOptions;
  width?: string;
  item?: File;
  onChange?: (value: number) => void;
}

const FISingleImageInput = (props: Props) => {
  const { getFieldDecorator, setFieldsValue } = props.form;
  const fieldName = props.name || 'image_id';
  const [loading] = useState(false);
  const [fileList, setFileList] = useState<UploadFile[]>([]);
  const [, setUploading] = useState(false);
  const [imageUrl, setImageUrl] = useState<string | undefined>(undefined);

  useEffect(() => {
    if (props.item) {
      const file: UploadFile = {
        uid: String(props.item.id),
        size: props.item.size,
        name: props.item.original_name,
        status: 'done',
        // url: e.path,
        response: { file: { id: props.item.id } },
      } as UploadFile;

      setFileList([file]); // TODO: 필요?
      setImageUrl(props.item.thumbnailFullPath);
    }
  }, [props.item]);

  useEffect(() => {
    const fileIds = fileList
      .filter((e) => e.response && e.response.file && e.response.file.id)
      .map((e) => e.response.file.id);
    console.log('useEffect fileIds: ', fileIds);
    if (fileIds.length > 0) {
      setFieldsValue({ [fieldName]: fileIds[0] });
    }
  }, [fileList, fieldName, setFieldsValue]);

  const getBase64 = (img: any, callback: any) => {
    const reader = new FileReader();
    reader.addEventListener('load', () => callback(reader.result));
    reader.readAsDataURL(img);
  };

  const uploadProps = {
    name: 'file',
    action: API_ROOT + '/files/upload',
    headers: {
      'X-Auth-Token': axios.defaults.headers.common['X-Auth-Token'],
    },
    multiple: false,
    showUploadList: false,
    // data: { group_id: groupId },
    // beforeUpload(file: RcFile, fileList: RcFile[]) {
    //   // const extension = path.extname(file.name);
    //   // const basename = path.basename(file.name, extension);
    //   // setFieldsValue({ name: basename.normalize() });
    //   return true;
    // },
    onChange(info: UploadChangeParam) {
      // console.log('onChange: info: ', info);
      setFileList([info.file]);

      const { status } = info.file;
      if (status === 'uploading') {
        setUploading(true);
      } else if (status === 'error') {
        setUploading(false);
        Util.showError(`${info.file.name} 파일 업로드가 실패하였습니다.`);
      } else if (status === 'done') {
        // console.log('info.file.originFileObj: ', info.file.originFileObj);
        getBase64(info.file.originFileObj, (imageUrl: string) =>
          setImageUrl(imageUrl)
        );
        setUploading(false);
      } else if (status === 'removed') {
        //
      }
    },
  };

  const uploadButton = (
    <div>
      <Icon type={loading ? 'loading' : 'plus'} />
      <div className="ant-upload-text">Upload</div>
    </div>
  );

  return (
    <Form.Item label={props.label}>
      <InlineItem width={props.width || '100%'}>
        {getFieldDecorator(fieldName, props.options)(<Input type="hidden" />)}
        <Upload
          {...uploadProps}
          fileList={fileList}
          listType="picture-card"
          className="avatar-uploader"
        >
          {imageUrl ? (
            <img src={imageUrl} alt="avatar" style={{ width: '100%' }} />
          ) : (
            uploadButton
          )}
        </Upload>
      </InlineItem>
    </Form.Item>
  );
};

export default FISingleImageInput;
