import React, { useState, useEffect, useRef } from 'react';
import { Modal, Form, Row, Col, InputNumber, Button } from 'antd';
import { FormComponentProps } from 'antd/lib/form';
import FinanceService, {
  CollectFetchOption
} from '../../../services/FinanceService';
import AccountItemEditor from './AccountItemEditor';
import { AccountItem } from '../../../models/Finance';
import Util from '../../../helpers/Util';
import AccountItemsCopier from './AccountItemsCopier';
import AccountItemListAll from './AccountItemListAll';

interface Props extends FormComponentProps {
  out?: boolean;
  fetchOption?: CollectFetchOption;
  fetchDelayed?: boolean;
  onChange?: () => void;
}

const AccountPayBook: React.FC<Props> = props => {
  const { out = false, fetchDelayed = false } = props;
  const { getFieldDecorator } = props.form;
  const [itemEditorVisible, setItemEditorVisible] = useState(false);
  const [refreshTrigger, setRefreshTrigger] = useState(1);
  const [fetchOption, setFetchOption] = useState<CollectFetchOption>(
    props.fetchOption || {
      year: new Date().getFullYear(),
      month: new Date().getMonth() + 1
    }
  );

  const [loading, setLoading] = useState(false);
  // const [planItems, setPlanItems] = useState<AccountItem[]>([]);
  // const [actionItems, setActionItems] = useState<AccountItem[]>([]);
  const [items, setItems] = useState<AccountItem[]>([]);
  useEffect(() => {
    const fetchDetail = async () => {
      setLoading(true);
      try {
        const result = await FinanceService.fetchAccountItems(fetchOption, out);
        const items = AccountItem.fromJsonArray(result.items);
        // console.log(items);
        // setPlanItems(items.filter(e => e.pay_date === undefined));
        // setActionItems(items.filter(e => e.pay_date !== undefined));
        setItems(items);
        setSelectedItems([]);
      } catch (error) {
        Util.showError(error);
      } finally {
        setLoading(false);
      }
    };

    if (fetchDelayed) {
      setTimeout(() => {
        fetchDetail();
      }, 400);
    } else {
      fetchDetail();
    }
  }, [fetchOption, fetchDelayed, out, refreshTrigger]);

  const accountItemRef = useRef<AccountItem | undefined>(undefined);

  const onClickAddItem = () => {
    accountItemRef.current = undefined;
    setItemEditorVisible(true);
  };

  const onClickAccountItem = (item: AccountItem) => {
    accountItemRef.current = item;
    setItemEditorVisible(true);
  };

  const onSuccessEditingAccount = () => {
    setRefreshTrigger(v => v + 1);
    setItemEditorVisible(false);
    props.onChange && props.onChange();
  };

  console.count('AccountPayBook');

  const onSubmitSearchForm = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    props.form.validateFields((err, values) => {
      if (err) return;
      setFetchOption(values);
    });
  };

  const [selectedItems, setSelectedItems] = useState<AccountItem[]>([]);
  const [itemsCopierVisible, setItemsCopierVisible] = useState(false);
  const onSelectItems = (items: AccountItem[]) => {
    setSelectedItems(items);
  };

  const onClickCopyItems = () => {
    setItemsCopierVisible(true);
  };

  const onSuccessCopy = () => {
    setItemsCopierVisible(false);
    setSelectedItems([]);
  };

  return (
    <>
      <h2>{out ? '지급관리' : '수금관리'}</h2>
      <Form
        className="hl-form"
        layout="inline"
        style={{ marginBottom: 7 }}
        onSubmit={onSubmitSearchForm}
      >
        <Row type="flex" justify="space-between">
          <Col>
            <Form.Item>
              {getFieldDecorator('year', {
                initialValue: fetchOption.year
              })(
                <InputNumber
                  placeholder="년도"
                  style={{ width: 100 }}
                  formatter={value => `${value}년`}
                />
              )}
            </Form.Item>
            <Form.Item>
              {getFieldDecorator('month', {
                initialValue: fetchOption.month
              })(
                <InputNumber
                  placeholder="월"
                  style={{ width: 70 }}
                  min={1}
                  max={12}
                  formatter={value => `${value}월`}
                />
              )}
            </Form.Item>

            <Form.Item>
              <Button type="primary" htmlType="submit">
                검색
              </Button>
            </Form.Item>
          </Col>
          <Col>
            {out && (
              <Button
                disabled={selectedItems.length === 0}
                onClick={onClickCopyItems}
              >
                계획복사
              </Button>
            )}{' '}
            <Button onClick={e => onClickAddItem()}>
              {out ? '지급' : '수금'} 등록
            </Button>
          </Col>
        </Row>
      </Form>

      {/* <AccountItemList
        items={planItems}
        plan
        out={out}
        loading={loading}
        onClickItem={onClickAccountItem}
      />

      <AccountItemList
        items={actionItems}
        out={out}
        loading={loading}
        onClickItem={onClickAccountItem}
        onSelectItems={out ? onSelectItems : undefined}
        selectedRowKeys={selectedItems.map(e => e.id)}
      /> */}

      <AccountItemListAll
        items={items}
        out={out}
        loading={loading}
        onClickItem={onClickAccountItem}
        onSelectItems={out ? onSelectItems : undefined}
        selectedRowKeys={selectedItems.map(e => e.id)}
      />

      <Modal
        title={out ? '지급등록' : '수금등록'}
        width={800}
        centered
        visible={itemEditorVisible}
        footer={null}
        onCancel={() => setItemEditorVisible(false)}
        destroyOnClose={true}
      >
        <AccountItemEditor
          out={out}
          item={accountItemRef.current}
          onSuccess={onSuccessEditingAccount}
        />
      </Modal>

      <Modal
        title="계획복사"
        width={400}
        centered
        visible={itemsCopierVisible}
        footer={null}
        onCancel={() => setItemsCopierVisible(false)}
        destroyOnClose={true}
      >
        <AccountItemsCopier
          sourceYear={fetchOption.year}
          sourceMonth={fetchOption.month}
          sourceItems={selectedItems}
          onSuccess={onSuccessCopy}
        />
      </Modal>
    </>
  );
};

export default Form.create<Props>()(AccountPayBook);
