import React from 'react';
import { Form, Button, Checkbox, Col, Row } from 'antd';
import { FormComponentProps } from 'antd/lib/form';

interface Props extends FormComponentProps {
  sourceColumnNames: string[];
  selectedColumnNames: string[];
  onUpdate: (values: string[]) => void;
}

const OrderReportColumnSeelctor = (props: Props) => {
  const { getFieldDecorator } = props.form;
  const { sourceColumnNames, selectedColumnNames } = props;

  const handleSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    props.form.validateFields((err, values) => {
      if (err) return;

      const selectedValues: string[] = [];
      sourceColumnNames.forEach((column) => {
        if (values[column]) {
          selectedValues.push(column);
        }
      });

      props.onUpdate(selectedValues);
    });
  };

  const getFields = () => {
    const children = [];
    for (let i = 0; i < sourceColumnNames.length; i++) {
      const isChecked = selectedColumnNames.includes(sourceColumnNames[i]);
      children.push(
        <Col span={6} key={i}>
          <Form.Item>
            {getFieldDecorator(sourceColumnNames[i], {
              valuePropName: 'checked',
              initialValue: isChecked,
            })(<Checkbox style={{ paddingRight: 10 }} />)}
            {sourceColumnNames[i]}
          </Form.Item>
        </Col>
      );
    }
    return children;
  };

  const formItemLayout = {
    labelCol: { span: 4 },
    wrapperCol: { span: 19 },
  };

  return (
    <Form {...formItemLayout} onSubmit={handleSubmit}>
      <div className="hl-form" style={{ marginBottom: '10px' }}>
        <Row gutter={24}>{getFields()}</Row>
        <Form.Item wrapperCol={{ span: 12, offset: 6 }}>
          <Button type="primary" htmlType="submit">
            저장
          </Button>
        </Form.Item>
      </div>
    </Form>
  );
};

export default Form.create<Props>()(OrderReportColumnSeelctor);
