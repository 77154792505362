import React, { useState } from 'react';
import { Table, Button, Row, Col, Icon, Modal, Popconfirm } from 'antd';
import { observer, inject } from 'mobx-react';
import { DataStore } from '../../stores/dataStore';
import { Corp } from '../../models/Org';
import CorpEditor from './CorpEditor';
import LinkButton from '../../components/general/LinkButton';
import Util from '../../helpers/Util';

interface Props {
  dataStore?: DataStore;
}

const CompList: React.FC<Props> = props => {
  const [editingItem, setEditingItem] = useState<Corp | undefined>(undefined);
  const [editorVisible, setEditorVisible] = useState(false);
  const dataStore = props.dataStore!;
  const items = dataStore.corps.slice();

  const columns = [
    {
      title: '코드',
      dataIndex: 'code',
      width: '100px'
    },
    {
      title: '이름',
      dataIndex: 'name'
    },
    {
      width: '90px',
      render: (text: string, record: Corp) => {
        return (
          <>
            <LinkButton onClick={e => handleUpdateItemButton(record)}>
              수정
            </LinkButton>{' '}
            |{' '}
            <Popconfirm
              title="정말 삭제하시겠습니까?"
              onConfirm={e => handleDeleteItemButton(record)}
            >
              <LinkButton disabled>삭제</LinkButton>
            </Popconfirm>
          </>
        );
      }
    }
  ];

  const handleCreateButtonClick = async () => {
    setEditingItem(undefined);
    setEditorVisible(true);
  };

  const handleUpdateItemButton = async (item: Corp) => {
    setEditingItem(item);
    setEditorVisible(true);
  };

  const handleDeleteItemButton = async (item: Corp) => {
    try {
      dataStore.updatingStore = true;
      await dataStore.deleteCorp(item.id);
    } catch (err) {
      Util.showError(err);
    } finally {
      dataStore.updatingStore = false;
    }
  };

  return (
    <>
      <h2>회사관리</h2>

      <Row type="flex" justify="space-between" style={{ marginBottom: 10 }}>
        <Col />
        <Col>
          <Button
            onClick={handleCreateButtonClick}
            disabled={dataStore.updatingStore}
          >
            <Icon type="plus" />
            회사 등록
          </Button>
        </Col>
      </Row>

      <Table
        columns={columns}
        dataSource={items}
        pagination={false}
        rowKey="code"
        loading={dataStore.updatingStore}
        bordered={true}
        size="middle"
      />

      <Modal
        title={editingItem ? '회사 수정' : '회사 등록'}
        centered
        visible={editorVisible}
        footer={null}
        onCancel={() => setEditorVisible(false)}
        destroyOnClose={true}
      >
        <CorpEditor
          onSuccess={() => setEditorVisible(false)}
          item={editingItem}
        />
      </Modal>
    </>
  );
};

export default inject('dataStore')(observer(CompList));
