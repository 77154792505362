import React from 'react';
import { Route, Switch, Redirect } from 'react-router-dom';
import { Layout, PageHeader } from 'antd';
import Sidebar from '../../components/Sidebar';
import { HLBody } from '../../components/layout/Index';
import OrderEADocList from './orders/OrderEADocList';
import OrderList from './common/OrderList';
import ProjectList from './ProjectList';
import ProjectEdit from './ProjectEdit';
import OrderDetail from './orders/OrderDetail';
import StringCodeList from '../../components/general/StringCodeList';
import DeptCodeList from '../../components/general/DeptCodeList';
import EADocDrafter from '../eas/EADocDrafter';
import CollectStatus from '../management/finance/CollectStatus';
import OrderLedgerForState from './orders/OrderLedgerForState';
import ProjectBaseSetting from './ProjectBaseSetting';
import projectsMenus from './menus/projects';
import { AuthStore } from '../../stores/authStore';
import { inject, observer } from 'mobx-react';

interface Props {
  authStore?: AuthStore;
}

const Index: React.FC<Props> = (props) => {
  const menu = 'projects';
  let deptId =
    Number(localStorage.getItem(`${menu}DefaultDeptId`)) || undefined;

  const authStore = props.authStore!;
  if (!authStore.user.acl_project) {
    return <></>;
  }

  return (
    <>
      <Sidebar menuItems={projectsMenus} defaultOpenKeys={['프로젝트', '수금']}>
        <div style={{ textAlign: 'left', margin: '30px 0px 0px 24px' }}>
          <img src="/images/hanlim-group.png" width="120" alt="한림그룹" />
        </div>
        <PageHeader
          title="프로젝트"
          style={{ paddingTop: 5, paddingBottom: 20 }}
        />
      </Sidebar>

      <Layout id="wrapper" style={{ marginLeft: 200 }}>
        <Layout.Content>
          <HLBody>
            <Switch>
              <Route path="/projects" exact>
                <Redirect to="/projects/ledger?state=ongoing" />
              </Route>

              <Route path="/projects/list" component={ProjectList} />
              <Route path="/projects/edit" component={ProjectEdit} />
              <Route path="/projects/eadocs/ord" exact>
                <OrderEADocList deptId={deptId} />
              </Route>
              <Route path="/projects/main/draft/ord">
                <EADocDrafter title="수주정보보고서" refTarget="ord" />
              </Route>
              <Route path="/projects/orders" exact>
                <OrderList deptId={deptId} />
              </Route>

              <Route path="/projects/ledger">
                <OrderLedgerForState deptId={deptId} />
              </Route>

              <Route path="/projects/collects" component={CollectStatus} />

              <Route
                path="/projects/main/orders/view"
                component={OrderDetail}
              />

              <Route
                path="/projects/settings/base"
                component={ProjectBaseSetting}
              />
              <Route path="/projects/settings/kinds">
                <StringCodeList
                  title="프로젝트 종류"
                  fieldName="project_kinds"
                />
              </Route>
              <Route path="/projects/settings/uses">
                <StringCodeList
                  title="프로젝트 용도"
                  fieldName="project_uses"
                />
              </Route>
              <Route path="/projects/settings/regions">
                <StringCodeList title="지역" fieldName="regions" />
              </Route>
              <Route path="/projects/settings/districts">
                <StringCodeList title="지구" fieldName="districts" />
              </Route>
              <Route path="/projects/settings/areas">
                <StringCodeList title="용도구역" fieldName="areas" />
              </Route>
              <Route path="/projects/settings/stages">
                <DeptCodeList
                  menu={menu}
                  deptId={deptId}
                  title="부서별 진행단계"
                  fieldName="order_stages"
                />
              </Route>
              <Route path="/projects/settings/ctypes">
                <DeptCodeList
                  menu={menu}
                  deptId={deptId}
                  title="부서별 수금구분"
                  fieldName="cp_terms"
                />
              </Route>
            </Switch>
          </HLBody>
        </Layout.Content>
      </Layout>
    </>
  );
};

export default inject('authStore')(observer(Index));
