import React, { useState, useEffect, useCallback } from 'react';
import { Order, OrderOut } from '../../../models/Order';
import OrderService from '../../../services/OrderService';
import Util from '../../../helpers/Util';
import OrderSummaryPanel from './OrderSummaryPanel';
import { Table, Icon } from 'antd';
import _ from 'lodash';
import InlineText from '../../../components/formcomps/InlineText';
import InlineItem from '../../../components/formcomps/InlineItem';
import Spinner from '../../../components/general/Spinner';

interface Props {
  orderId: number;
  data?: any;
}

const OrderOutViewer: React.FC<Props> = props => {
  const { orderId, data } = props;
  const [loading, setLoading] = useState(true);
  const [order, setOrder] = useState<Order>(new Order());
  const [items, setItems] = useState<OrderOut[]>([]);

  useEffect(() => {
    const fetchDetail = async (id: number) => {
      setLoading(true);
      try {
        const result = await OrderService.fetchItem(id);
        setOrder(Order.fromJson(result.order));
      } catch (error) {
        Util.showError(error);
      } finally {
        setLoading(false);
      }
    };

    fetchDetail(orderId);
  }, [orderId]);

  useEffect(() => {
    let items = OrderOut.fromJsonArray(data.order_outs);
    items = _.sortBy(items, ['company_category', 'company_name']);
    setItems(items);
  }, [data]);

  const [totalPrice, setTotalPrice] = useState(0);
  const [outRate, setOutRate] = useState(0);

  const calcItems = useCallback(() => {
    let outTotal = 0;
    for (const item of items) {
      if (item.selected && item.final_price) {
        outTotal += item.final_price;
      }
    }
    setTotalPrice(outTotal);

    // 외주비율
    if (order.in_price) {
      const outRate = ((outTotal / order.in_price) * 100).toFixed(1);
      setOutRate(Number(outRate));
    }
  }, [items, order]);

  useEffect(() => {
    if (!loading) calcItems();
  }, [loading, items.length, calcItems]);

  const columns = [
    {
      title: '구분',
      dataIndex: 'company_category'
    },
    {
      title: '선정',
      dataIndex: 'selected',
      width: '50px',
      render: (text: string, record: any) => {
        return record.selected ? <Icon type="check" /> : '';
      }
    },
    {
      title: '업체명',
      dataIndex: 'company_name'
    },
    {
      title: '견적가',
      dataIndex: 'f_est_price',
      align: 'right' as 'right'
    },
    {
      title: '1차조정',
      dataIndex: 'f_c1_price',
      align: 'right' as 'right'
    },
    {
      title: '2차조정',
      dataIndex: 'f_c2_price',
      align: 'right' as 'right'
    },
    {
      title: '최종가',
      dataIndex: 'f_final_price',
      align: 'right' as 'right'
    }
  ];

  console.count('OrderOutViewer');

  if (loading) {
    return <Spinner />;
  }

  return (
    <>
      <OrderSummaryPanel order={order} />

      <Table
        columns={columns}
        dataSource={items}
        rowKey="id"
        pagination={false}
        bordered={true}
        loading={loading}
        size="middle"
      />

      <div className="hl-form" style={{ marginTop: '10px' }}>
        <InlineText>설계수주액:</InlineText>
        <InlineItem width="20%">{order.f_in_price}</InlineItem>
        <InlineText>외주비총액:</InlineText>
        <InlineItem width="20%">{Util.formatNumber(totalPrice)}</InlineItem>
        <InlineText>외주비율:</InlineText>
        <InlineItem width="20%">{Util.formatNumber(outRate)} %</InlineItem>
      </div>
    </>
  );
};

export default OrderOutViewer;
