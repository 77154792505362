import React, { useState } from 'react';
import { Input, Button, Form } from 'antd';
import { FormComponentProps } from 'antd/lib/form';
import Util from '../../../helpers/Util';
import _ from 'lodash';
import AssetService from '../../../services/AssetService';
import { AssetEADoc } from '../../../models/Asset';
import FCFileInput from '../../../components/formcomps/FCFileInput';
import FCEADocInput from '../../../components/formcomps/FCEADocInput';

interface Props extends FormComponentProps {
  assetId: number;
  item?: AssetEADoc;
  onSuccess?: () => void;
}

const AssetEADocEdit: React.FC<Props> = props => {
  const { assetId, item } = props;
  const { getFieldDecorator } = props.form;
  const [submitting, setSubmitting] = useState(false);

  const onSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    props.form.validateFields((err, values) => {
      if (err) return;

      if (!values.eadoc_ids || values.eadoc_ids.length === 0) {
        Util.showError('관련문서를 선택해주세요.');
        return;
      }

      values.eadoc_id = values.eadoc_ids[0];
      values.file_id =
        (values.file_ids && values.file_ids.length > 0 && values.file_ids[0]) ||
        null;

      processItem(_.omit(values, ['eadoc_ids', 'file_ids']));
    });
  };

  const processItem = async (values: any) => {
    try {
      setSubmitting(true);

      if (item) {
        await AssetService.updateAssetEADoc(item.id, values);
      } else {
        values.asset_id = assetId;
        await AssetService.createAssetEADoc(values);
      }

      Util.showSuccess('저장되었습니다.');

      if (props.onSuccess) props.onSuccess();
    } catch (err) {
      Util.showError(err);
      setSubmitting(false);
    }
  };

  return (
    <Form
      className="hl-form"
      labelCol={{ span: 4 }}
      wrapperCol={{ span: 18 }}
      onSubmit={onSubmit}
    >
      <Form.Item label="문서 첨부">
        <FCEADocInput form={props.form} items={item && item.eadocs} single />
      </Form.Item>

      <Form.Item label="파일 첨부">
        <FCFileInput form={props.form} items={item && item.files} single />
      </Form.Item>

      <Form.Item label="비고">
        {getFieldDecorator('note', {
          initialValue: item && item.note
        })(<Input />)}
      </Form.Item>

      <Form.Item wrapperCol={{ span: 12, offset: 4 }}>
        <Button type="primary" htmlType="submit" disabled={submitting}>
          저 장
        </Button>
      </Form.Item>
    </Form>
  );
};

export default Form.create<Props>()(AssetEADocEdit);
