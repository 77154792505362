import React from 'react';
import { observer, inject } from 'mobx-react';
import { DataStore } from '../../stores/dataStore';
import { Form, Select } from 'antd';
import { FormComponentProps } from 'antd/lib/form';
import { GetFieldDecoratorOptions } from 'antd/lib/form/Form';
import InlineItem from './InlineItem';
import { SelectValue } from 'antd/lib/select';

interface Props extends FormComponentProps {
  dataStore?: DataStore;
  label?: string;
  name?: string;
  options?: GetFieldDecoratorOptions;
  width?: string;
  allowClear?: boolean;
  disabled?: boolean;
  onChange?: (value: number) => void;
}

const FICorpSelect = (props: Props) => {
  const dataStore = props.dataStore!;
  const { getFieldDecorator } = props.form;

  const onChange = (value: SelectValue) => {
    props.onChange && props.onChange(Number(value));
  };

  return (
    <Form.Item label={props.label}>
      <InlineItem width={props.width || '100%'}>
        {getFieldDecorator(
          props.name || 'corp_id',
          props.options
        )(
          <Select
            placeholder="회사"
            allowClear={props.allowClear}
            disabled={props.disabled}
            onChange={onChange}
          >
            {dataStore.corps.map(corp => (
              <Select.Option key={corp.id} value={corp.id}>
                {corp.name}
              </Select.Option>
            ))}
          </Select>
        )}
      </InlineItem>
    </Form.Item>
  );
};

export default inject('dataStore')(observer(FICorpSelect));
