import React, { useState, useEffect } from 'react';
import { withRouter, RouteComponentProps } from 'react-router';
import { Table, Col, Row } from 'antd';
import { Post } from '../../models/Post';
import Util from '../../helpers/Util';
import PostService from '../../services/PostService';
import LinkButton from '../../components/general/LinkButton';

interface Props extends RouteComponentProps {
  type?: number;
  refId?: number;
}

const PostListWidget: React.FC<Props> = props => {
  const { location } = props;
  const params = new URLSearchParams(location.search);
  const type = props.type || Number(params.get('type')) || 0;
  const refId = props.refId || Number(params.get('refId')) || undefined;

  const [items, setItems] = useState<Post[]>([]);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    const fetchItems = async () => {
      setLoading(true);
      try {
        const result = await PostService.fetchItems(5, 1, type, refId);
        setItems(Post.fromJsonArray(result.items));
      } catch (error) {
        Util.showError(error);
      } finally {
        setLoading(false);
      }
    };
    fetchItems();
  }, [type, refId]);

  const columns = [
    {
      title: '제목',
      dataIndex: 'title',
      render: (text: string, record: Post) => (
        <>
          <LinkButton onClick={e => onClickItem(record)}>{text}</LinkButton>
          {record.comment_count > 0 && ` (${record.comment_count})`}
        </>
      )
    },
    {
      title: '등록일',
      dataIndex: 'created_at',
      width: '120px',
      render: (text: string, record: Post) => Util.formatDate(text)
    }
  ];

  const onClickItem = (item: Post) => {
    const path = Post.getTypePath(type) + `/view?type=${type}&id=${item.id}`;
    props.history.push(path);
  };

  const onClickShowAll = () => {
    const path = Post.getTypePath(type) + `/list?type=${type}&refId=${refId}`;
    props.history.push(path);
  };

  return (
    <>
      <Row type="flex" justify="space-between">
        <Col>
          <h3>{Post.getTypeName(type, refId)}</h3>
        </Col>
        <Col>
          <LinkButton onClick={onClickShowAll}>전체보기</LinkButton>
        </Col>
      </Row>

      <Table
        columns={columns}
        dataSource={items}
        rowKey="id"
        pagination={false}
        bordered={true}
        loading={loading}
        size="middle"
      />
    </>
  );
};

export default withRouter(PostListWidget);
