import React, { useEffect } from 'react';

interface Props {
  address: string;
}

const MapView = (props: Props) => {
  useEffect(() => {
    // 지도를 담을 영역의 DOM 레퍼런스
    var container: any = document.getElementById('map');
    container.style.display = '';
    var options = {
      // 지도를 생성할 때 필요한 기본 옵션
      center: new (window as any).kakao.maps.LatLng(33.450701, 126.570667), // 지도의 중심좌표.
      level: 3 // 지도의 레벨(확대, 축소 정도)
    };
    var map = new (window as any).kakao.maps.Map(container, options); // 지도 생성 및 객체 리턴

    // 주소-좌표 변환 객체를 생성합니다
    var geocoder = new (window as any).kakao.maps.services.Geocoder();

    // 주소로 좌표를 검색합니다
    geocoder.addressSearch(props.address, function(result: any, status: any) {
      // 정상적으로 검색이 완료됐으면
      if (status === (window as any).kakao.maps.services.Status.OK) {
        var coords = new (window as any).kakao.maps.LatLng(
          result[0].y,
          result[0].x
        );

        // 결과값으로 받은 위치를 마커로 표시합니다
        new (window as any).kakao.maps.Marker({
          map: map,
          position: coords
        });

        // 지도의 중심을 결과값으로 받은 위치로 이동시킵니다
        map.setCenter(coords);
      }
    });
  }, [props.address]);

  return (
    <div
      id="map"
      style={{ width: '100%', height: '300px', display: 'none' }}
    ></div>
  );
};

export default MapView;
