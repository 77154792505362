import { User } from './User';
import userStore from '../stores/userStore';

export enum ActivityTarget {
  eadoc = 1
}

export enum ActivityType {
  eadocDraft = 1,
  eadocUpdate,
  eadocApprove,
  eadocCancelApprove,
  eadocReturn,
  eadocCreateComment
}

export class Activity {
  id = 0;
  user: User = new User();
  target: ActivityTarget = ActivityTarget.eadoc;
  targetId = 0;
  type: ActivityType = ActivityType.eadocDraft;
  content = '';
  createdAt = '';

  static fromJsonArray(sources: any[]) {
    return sources.map(source => Activity.fromJson(source));
  }

  static fromJson(source: any) {
    const item = new Activity();
    item.id = source.id;
    item.user = userStore.findById(source.user_id);
    item.target = source.target;
    item.targetId = source.target_id;
    item.type = source.type;
    item.content = source.content;
    item.createdAt = source.created_at;
    return item;
  }

  static targetName(item: ActivityTarget) {
    switch (item) {
      case ActivityTarget.eadoc:
        return '결재문서';
    }
  }

  static typeName(item: ActivityType) {
    switch (item) {
      case ActivityType.eadocDraft:
        return '결재 기안';
      case ActivityType.eadocUpdate:
        return '문서 수정';
      case ActivityType.eadocApprove:
        return '결재 승인';
      case ActivityType.eadocCancelApprove:
        return '결재 승인취소';
      case ActivityType.eadocReturn:
        return '결재 반려';
      case ActivityType.eadocCreateComment:
        return '의견 등록';
    }
  }
  static typeColor(item: ActivityType) {
    switch (item) {
      case ActivityType.eadocApprove:
        return 'green';
      case ActivityType.eadocCancelApprove:
      case ActivityType.eadocReturn:
        return 'red';
      default:
        return 'blue';
    }
  }

  get targetName() {
    return Activity.targetName(this.target);
  }

  get typeName() {
    return Activity.typeName(this.type);
  }

  get typeColor() {
    return Activity.typeColor(this.type);
  }
}
