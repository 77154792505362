import React, { useState, useEffect, useRef } from 'react';
import {
  Table,
  Button,
  Row,
  Col,
  Input,
  Form,
  Drawer,
  InputNumber,
  Tabs,
  Tag
} from 'antd';
import LinkButton from '../../components/general/LinkButton';
import { FormComponentProps } from 'antd/lib/form';
import { PaginationConfig } from 'antd/lib/table';
import { Education } from '../../models/Education';
import EducationService, {
  EducationSearchOption
} from '../../services/EducationService';
import Util from '../../helpers/Util';
import EducationDetail from './EducationDetail';
import _ from 'lodash';
const { TabPane } = Tabs;

interface Props extends FormComponentProps {}

const CATEGORIES = ['전사', '경영', '설계', 'CS', 'CM', '기타 '];

interface CategoryInfo {
  category: string;
  finishedCount: number;
  unfinishedCount: number;
}

const EducationSummary: React.FC<Props> = props => {
  const [loading, setLoading] = useState(false);
  const [totalCount, setTotalCount] = useState(0);
  const [currentPage, setCurrentPage] = useState(1);
  const [refreshTrigger, setRefreshTrigger] = useState(1);
  const [searchOption, setSearchOption] = useState<EducationSearchOption>({});
  const [items, setItems] = useState<Education[]>([]);
  const [categories, setCategories] = useState<CategoryInfo[]>([]);
  const { getFieldDecorator } = props.form;

  const pagination = {
    pageSize: 15,
    current: currentPage,
    total: totalCount,
    showTotal: (total: number, range: [number, number]) =>
      `전체 ${total}건 중 ${range[0]}-${range[1]}`
  };

  useEffect(() => {
    const fetchItems = async () => {
      setLoading(true);
      try {
        const result1 = await EducationService.fetchSummary();
        const categories: CategoryInfo[] = CATEGORIES.map(el => {
          return { category: el, finishedCount: 0, unfinishedCount: 0 };
        });
        result1.items.forEach((item: any) => {
          const summaryItem = categories.find(
            el => el.category === item.category
          );
          if (summaryItem) {
            if (item.finished) {
              summaryItem.finishedCount += Number(item.count);
            } else {
              summaryItem.unfinishedCount += Number(item.count);
            }
          }
        });

        const total: CategoryInfo = {
          category: '전체',
          finishedCount: _.sumBy(categories, e => Number(e.finishedCount)),
          unfinishedCount: _.sumBy(categories, e => Number(e.unfinishedCount))
        };
        setCategories([total, ...categories]);

        const result2 = await EducationService.fetchItems(
          pagination.pageSize,
          currentPage,
          searchOption
        );
        setTotalCount(result2.total_count);
        setItems(Education.fromJsonArray(result2.items));
      } catch (error) {
        Util.showError(error);
      } finally {
        setLoading(false);
      }
    };
    fetchItems();
  }, [currentPage, pagination.pageSize, searchOption, refreshTrigger]);

  const columns = [
    {
      title: '번호',
      dataIndex: 'id',
      align: 'right' as 'right',
      width: 60
    },
    {
      title: '상태',
      dataIndex: 'state',
      width: 80,
      render: (text: string, record: Education) => {
        return record.finished ? (
          <Tag color="green">종료</Tag>
        ) : (
          <Tag color="blue">진행</Tag>
        );
      }
    },
    {
      title: '교육일자',
      dataIndex: 'date',
      width: 150,
      render: (text: string, record: Education) => Util.formatDateTimeHHMM(text)
    },
    {
      title: '분야',
      dataIndex: 'category',
      width: 60
    },
    {
      title: '교육명',
      dataIndex: 'title',
      render: (text: string, record: Education) => (
        <LinkButton onClick={e => onClickItem(record)}>
          {record.title}
        </LinkButton>
      )
    },
    {
      title: '강사명',
      dataIndex: 'instructor'
    },
    {
      title: '교육개요',
      dataIndex: 'summary'
    },
    {
      title: '교육인원',
      align: 'right' as 'right',
      width: 100,
      render: (text: string, record: Education) => {
        return `${record.apply_count} / ${record.seat_count}`;
      }
    }
  ];

  const onChangeTable = (pagination: PaginationConfig) => {
    setCurrentPage(pagination.current || 1);
  };

  const onSubmitSearchForm = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    props.form.validateFields((err, values) => {
      if (err) return;
      setSearchOption(values);
      setCurrentPage(1);
    });
  };

  const itemRef = useRef<Education | undefined>(undefined);
  const itemDirtyRef = useRef(false);
  const [detailVisible, setDetailVisible] = useState(false);

  const onClickItem = (item: Education) => {
    itemRef.current = item;
    setDetailVisible(true);
  };

  const onCloseDetail = () => {
    setDetailVisible(false);
    if (itemDirtyRef.current) {
      itemDirtyRef.current = false;
      setRefreshTrigger(v => v + 1);
    }
  };

  const onChangeDetail = () => {
    itemDirtyRef.current = true;
  };

  const [activeTabKey, setActiveTabKey] = useState('전체');

  const onChangeEADocType = (key: string) => {
    setSearchOption({
      ...searchOption,
      category: key === '전체' ? undefined : key
    });
    setActiveTabKey(key);
  };

  return (
    <>
      <h2>교육일정</h2>

      <Tabs type="card" onChange={onChangeEADocType} activeKey={activeTabKey}>
        {categories.map(el => (
          <TabPane
            tab={`${el.category} (${el.finishedCount}/${el.finishedCount +
              el.unfinishedCount})`}
            key={el.category}
          />
        ))}
      </Tabs>

      <Form
        className="hl-form"
        layout="inline"
        style={{ marginBottom: 7 }}
        onSubmit={onSubmitSearchForm}
      >
        <Row type="flex" justify="space-between">
          <Col>
            <Form.Item>
              {getFieldDecorator('keyword')(
                <Input.Search
                  placeholder="검색어"
                  allowClear={true}
                  style={{ width: 150 }}
                />
              )}
            </Form.Item>

            <Form.Item>
              {getFieldDecorator('year')(
                <InputNumber placeholder="년도" style={{ width: 100 }} />
              )}
            </Form.Item>

            <Form.Item>
              <Button type="primary" htmlType="submit">
                검색
              </Button>
            </Form.Item>
          </Col>

          <Col></Col>
        </Row>
      </Form>

      <Table
        columns={columns}
        dataSource={items}
        pagination={pagination}
        onChange={onChangeTable}
        rowKey="id"
        loading={loading}
        bordered={true}
        size="middle"
      />

      <Drawer
        closable={false}
        placement="right"
        width="85%"
        visible={detailVisible}
        onClose={onCloseDetail}
        destroyOnClose={true}
      >
        <EducationDetail
          id={(itemRef.current && itemRef.current.id) || 0}
          fetchDelayed
          onChange={onChangeDetail}
        />
      </Drawer>
    </>
  );
};

export default Form.create<Props>()(EducationSummary);
