import React, { useState, useEffect } from 'react';
import { Form } from 'antd';
import { EADoc } from '../../models/EAS';
import { RouteComponentProps, withRouter } from 'react-router';
import Util from '../../helpers/Util';
import EAService from '../../services/EAService';
import EADocViewer from './EADocViewer';
import VacationApplyViewer from '../works/VacationApplyViewer';
import TripApplyViewer from '../works/trips/TripApplyViewer';
import TripReportViewer from '../works/trips/TripReportViewer';
import Spinner from '../../components/general/Spinner';
import { FormComponentProps } from 'antd/lib/form';
import OrderViewer from '../projects/orders/OrderViewer';
import OrderInViewer from '../projects/orders/OrderInViewer';
import OrderOutViewer from '../projects/orders/OrderOutViewer';
import ContractOutViewer from '../projects/orders/ContractOutViewer';
import HTMLContentViewer from '../../components/general/HTMLContentViewer';

interface Props extends FormComponentProps, RouteComponentProps {
  id?: number;
  refTarget?: string;
  refId?: number;
  fetchDelayed?: boolean;
}

const EADocDetail: React.FC<Props> = props => {
  const { id = 0, refTarget, refId, fetchDelayed } = props;
  const [item, setItem] = useState<EADoc>(new EADoc());
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    const fetchItem = async () => {
      setLoading(true);
      try {
        if (id > 0) {
          const result = await EAService.fetchItem(id);
          setItem(EADoc.fromJson(result.eadoc));
        } else if (refTarget && refId && refId > 0) {
          const result = await EAService.fetchItemByRef(refTarget, refId);
          setItem(EADoc.fromJson(result.eadoc));
        }
      } catch (error) {
        Util.showError(error);
      } finally {
        setLoading(false);
      }
    };

    if (fetchDelayed) {
      setTimeout(() => {
        fetchItem();
      }, 400);
    } else {
      fetchItem();
    }
  }, [id, refTarget, refId, fetchDelayed]);

  const renderChildren = () => {
    if (item.refTarget === undefined)
      return <HTMLContentViewer content={item.data.content} />;
    if (item.refTarget === 'eduser' || item.refTarget === 'pmis')
      return <HTMLContentViewer content={item.data.content} />;
    if (item.refTarget === 'vac')
      return <VacationApplyViewer data={item.data} />;
    if (item.refTarget === 'trip') return <TripApplyViewer data={item.data} />;
    if (item.refTarget === 'trrpt')
      return <TripReportViewer data={item.data} />;
    if (item.refTarget === 'ord') return <OrderViewer data={item.data} />;
    if (item.refTarget === 'ordin') return <OrderInViewer data={item.data} />;
    if (item.refTarget === 'ctrin') return <OrderInViewer data={item.data} />;
    if (item.refTarget === 'ordout')
      return <OrderOutViewer orderId={item.refId || 0} data={item.data} />;
    if (item.refTarget === 'ctrout')
      return <ContractOutViewer data={item.data} />;
  };

  if (loading) {
    return <Spinner />;
  }

  if (item.id === 0) {
    return <></>;
  }

  return (
    <EADocViewer title={item.title || ''} id={item.id} doc={item}>
      {renderChildren()}
    </EADocViewer>
  );
};

const _EADocDetail = Form.create<Props>()(EADocDetail);
export default withRouter(_EADocDetail);
