import userStore from '../stores/userStore';
import { User } from './User';
import moment from 'moment';
import { Department } from './Org';
import dataStore from '../stores/dataStore';
import Util from '../helpers/Util';

export class VacationRecord {
  user: User = new User();
  year = 0;
  given = 0.0;
  used = 0.0;

  static fromJsonArray(sources: any[]) {
    return sources.map(source => VacationRecord.fromJson(source));
  }

  static fromJson(source: any) {
    const item = new VacationRecord();
    item.user = userStore.findById(source.user_id);
    item.year = source.year;
    item.given = source.given;
    item.used = source.used;
    return item;
  }

  get id() {
    return this.user.id + '_' + this.year;
  }

  get usedRate(): number {
    return this.given === 0
      ? 0.0
      : Number(((this.used / this.given) * 100).toFixed(1));
  }
}

export class Vacation {
  id = 0;
  user: User = new User();
  type = '';
  begin_date: moment.Moment = moment();
  end_date?: moment.Moment;
  begin_type = 1;
  end_type = 1;
  dates: string[] = [];
  used = 0.0;
  given = 0.0;
  note = '';

  // derived
  [key: string]: any; // for string index (subscript)

  static fromJsonArray(sources: any[]) {
    return sources.map(source => Vacation.fromJson(source));
  }

  static fromJson(source: any) {
    let item = new Vacation();
    for (const key of Object.keys(source)) {
      if (key in item) {
        item[key] = source[key];
      }
    }

    item.user = User.fromJson(source.user);
    item.begin_date = Util.stringToMoment(source.begin_date) || moment();
    item.end_date = Util.stringToMoment(source.end_date);
    item.dates = String(source.dates).split(',');

    // const item = new Vacation();
    // item.id = source.id;
    // item.type = source.type;
    // item.beginDate = moment(source.begin_date);
    // item.endDate =
    //   source.end_date === null ? undefined : moment(source.end_date);
    // item.beginType = source.begin_type;
    // item.endType = source.end_type;
    // item.dates = String(source.dates).split(',');
    // item.used = source.used;
    // item.given = source.given;
    // item.note = source.note;
    return item;
  }

  static dateTypeName(type: number) {
    switch (type) {
      case 1:
        return '종일';
      case 2:
        return '오전반차';
      case 3:
        return '오후반차';
    }
  }

  get durationSummary() {
    let result = Util.formatDate(this.begin_date);
    if (this.begin_type > 1)
      result += ` (${Vacation.dateTypeName(this.begin_type)})`;
    if (this.end_date) {
      result += ` ~ ${Util.formatDate(this.end_date)}`;
      if (this.end_type > 1)
        result += ` (${Vacation.dateTypeName(this.end_type)})`;
    }
    return result;
  }
}

export class VacationStat {
  dept: Department = new Department();
  year = 0;
  given = 0.0;
  used = 0.0;

  static fromJsonArray(sources: any[]) {
    return sources.map(source => VacationStat.fromJson(source));
  }

  static fromJson(source: any) {
    const item = new VacationStat();
    item.dept = dataStore.findDepartment(source.dept_id) || new Department();
    item.year = source.year;
    item.given = source.given;
    item.used = source.used;
    return item;
  }

  get id(): string {
    return this.dept.id + '_' + this.year;
  }

  get remains(): number {
    return this.given - this.used;
  }

  get usedRate(): number {
    return this.given === 0
      ? 0.0
      : Number(((this.used / this.given) * 100).toFixed(1));
  }
}
