import React from 'react';
import { Route, Switch, Redirect } from 'react-router-dom';
import { inject, observer } from 'mobx-react';
import { AuthStore } from '../../stores/authStore';
import UnderConst from '../../components/UnderConst';
import PostList from '../board/PostList';
import PostEditor from '../board/PostEditor';
import PostViewer from '../board/PostViewer';
import OccasionViewer from '../management/OccasionViewer';
import OccasionList from '../management/OccasionList';
import PlanList from '../plans/PlanList';
import EducationList from './EducationList';
import PollList from './PollList';
import UserList from '../../components/hr/UserList';
import UserDetail from '../../components/hr/UserDetail';
import UserContractList from '../../components/hr/UserContractList';
import OrderListForPortfolio from './OrderListForPortfolio';
import CorpList from './CorpList';
import RankList from '../../components/general/RankList';
import DepartmentList from '../../components/general/DepartmentList';
import VacationRecords from './VacationRecords';
import VacationStats from './VacationStats';
import LawsuitList from './LawsuitList';
import CorpPages from './support/CorpPages';
import ArchiveGroupBrowser from '../../components/general/ArchiveGroupBrowser';
import TripEADocList from '../works/trips/TripEADocList';
import AllTripList from '../works/trips/AllTripList';
import AssetList from './assets/AssetList';
import AssetReportList from './assets/AssetReportList';
import EducationSummary from './EducationSummary';
import PageViewer from '../board/PageViewer';
import PageEditor from '../board/PageEditor';
import HBPages from './support/HBPages';
import PageEADocList from '../board/PageEADocList';
import _ from 'lodash';
import EADocListByCat from '../eas/EADocListByCat';
import EADocDrafterWrapper from '../eas/EADocDrafterWrapper';

interface Props {
  authStore?: AuthStore;
}

const Index: React.FC<Props> = props => {
  const authStore = props.authStore!;

  // TODO: 리팩토링
  // menus/support.js, SidebarNew.tsx 참고
  const allowAccessUser = _.find(
    [1, 15001, 13005, 18001, 18020, 11004],
    e => e === authStore.user.id
  );
  // console.log(allowAccessUser);

  return (
    <Switch>
      <Route path="/management/support/uc" component={UnderConst} />
      <Route
        path="/management/support/archive"
        component={ArchiveGroupBrowser}
      />

      <Route path="/management/support" exact>
        <Redirect to="/management/support/edu_summary" />
      </Route>

      <Route
        exact
        path="/management/support/educations"
        component={EducationList}
      />
      <Route
        exact
        path="/management/support/edu_summary"
        component={EducationSummary}
      />
      <Route exact path="/management/support/edu_docs">
        <h2>교육자료실</h2>
        <PageEADocList pageId={17} />
      </Route>
      <Route exact path="/management/support/polls" component={PollList} />

      <Route path="/management/support/page_editor" component={PageEditor} />
      <Route path="/management/support/corp_summary">
        <PageViewer id={10} />
      </Route>
      <Route path="/management/support/corp_pages" component={CorpPages} />

      {allowAccessUser && (
        <Route exact path="/management/support/orgchart">
          <PageViewer id={11} />
        </Route>
      )}
      {allowAccessUser && (
        <Route exact path="/management/support/hb" component={HBPages} />
      )}
      {allowAccessUser && (
        <Route exact path="/management/support/users" component={UserList} />
      )}
      {allowAccessUser && (
        <Route path="/management/support/users/:id" component={UserDetail} />
      )}
      {allowAccessUser && (
        <Route
          path="/management/support/user_contracts"
          component={UserContractList}
        />
      )}

      <Route
        path="/management/support/portfolio"
        component={OrderListForPortfolio}
      />
      <Route path="/management/support/plans">
        <PlanList title="개별 일정관리" preventCreation browseAll />
      </Route>
      <Route
        path="/management/support/occasions/view"
        component={OccasionViewer}
      />
      <Route path="/management/support/occasions" component={OccasionList} />
      <Route path="/management/support/corps" component={CorpList} />
      <Route path="/management/support/ranks" component={RankList} />
      <Route path="/management/support/depts" component={DepartmentList} />
      <Route path="/management/support/assets" component={AssetList} />
      <Route
        path="/management/support/assets_report"
        component={AssetReportList}
      />

      <Route
        path="/management/support/vacations/records"
        component={VacationRecords}
      />
      <Route
        path="/management/support/vacations/stats"
        component={VacationStats}
      />

      <Route path="/management/support/trips/eadocs">
        <TripEADocList title="실적관리" hideDraftButton hideDeptSelector />
      </Route>
      <Route path="/management/support/trips/reports" component={AllTripList} />

      <Route
        path="/management/support/lawsuits"
        component={LawsuitList}
        exact
      />
      <Route path="/management/support/eas-templates/" exact>
        <PostList type={0} />
      </Route>
      <Route
        path="/management/support/eas-templates/edit"
        component={PostEditor}
      />
      <Route
        path="/management/support/eas-templates/view"
        component={PostViewer}
      />

      <Route path="/management/support/eas/list" component={EADocListByCat} />
      <Route
        path="/management/support/eas/draft"
        component={EADocDrafterWrapper}
      />

      <Route path="/management/support/posts/edit" component={PostEditor} />
      <Route path="/management/support/posts/view" component={PostViewer} />
      <Route path="/management/support/posts/" component={PostList} />
    </Switch>
  );
};

export default inject('authStore')(observer(Index));
