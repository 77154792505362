import Service from './Index';

const PageService = {
  fetchItem: (id: number) => {
    return Service.get(`/pages/${id}`);
  },
  //   fetchItems: (
  //     limit: number,
  //     page: number,
  //     type: number,
  //     ref_id?: number,
  //     keyword?: string
  //   ) => {
  //     const offset = (page - 1) * limit;
  //     return Service.get('/posts', {
  //       limit,
  //       offset,
  //       type,
  //       ref_id,
  //       keyword
  //     });
  //   },
  update: (id: number, data: any) => {
    return Service.put(`/pages/${id}`, data);
  },
  // delete: (id: number) => {
  //   return Service.delete(`/posts/${id}`);
  // },

  fetchPageLogs: (limit: number, page: number, page_id: number) => {
    const offset = (page - 1) * limit;
    return Service.get(`/pages/${page_id}/logs`, {
      limit,
      offset
    });
  },
  createPageLog: (page_id: number, data: any) => {
    return Service.post(`/pages/${page_id}/logs`, data);
  },
  updatePageLog: (id: number, data: any) => {
    return Service.put(`/pages/logs/${id}`, data);
  },
  deletePageLog: (id: number) => {
    return Service.delete(`/pages/logs/${id}`);
  },

  fetchPageEADocs: (page_id: number) => {
    return Service.get(`/pages/${page_id}/eadocs`);
  },
  createPageEADoc: (data: any) => {
    return Service.post('/pages/page_eadocs', data);
  },
  updatePageEADoc: (id: number, data: any) => {
    return Service.put(`/pages/page_eadocs/${id}`, data);
  },
  deletePageEADoc: (id: number) => {
    return Service.delete(`/pages/page_eadocs/${id}`);
  }
};

export default PageService;
