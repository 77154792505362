import React, { useState, useEffect, useRef } from 'react';
import {
  Table,
  Row,
  Col,
  Form,
  Button,
  InputNumber,
  Drawer,
  Tabs,
  Checkbox,
  Switch,
} from 'antd';
import { withRouter, RouteComponentProps } from 'react-router-dom';
import OrderService from '../../services/OrderService';
import { Order } from '../../models/Order';
import Util from '../../helpers/Util';
import OrderExtraEdit from '../projects/orders/OrderExtraEdit';
import { FormComponentProps } from 'antd/lib/form';
import ButtonGroup from 'antd/lib/button/button-group';
import FCTextInput from '../../components/formcomps/FCTextInput';
import { CheckboxChangeEvent } from 'antd/lib/checkbox';
import moment from 'moment';
import useWindowSize from '../../helpers/useWindowSize';
import OrderView from '../projects/orders/OrderView';
import InlineItem from '../../components/formcomps/InlineItem';
import ReactToPrint from 'react-to-print';
import AButton from '../../components/general/AButton';
import OrderReportColumnSelector from './OrderReportColumnSelector';
import { Modal } from 'antd';
const { TabPane } = Tabs;

export enum OrderReportListType {
  default,
  isNew,
  hasIssue,
}

interface Props extends FormComponentProps, RouteComponentProps {
  title: string;
  isNew?: boolean;
  isUrgent?: boolean;
  hasIssue?: boolean;
  listType?: OrderReportListType;
}

const OrderReport: React.FC<Props> = (props) => {
  const params = new URLSearchParams(props.location.search);
  const hq = params.get('hq') || 'all';
  const dept_id = Number(params.get('dept_id')) || 0;
  const year =
    Number(params.get('year')) ||
    Number(localStorage.getItem('orderReportYear')) ||
    new Date().getFullYear();
  const keyword = params.get('keyword') || '';
  const now = params.get('now') || '';
  const {
    title,
    isNew,
    isUrgent,
    hasIssue,
    listType = OrderReportListType.default,
  } = props;

  const { getFieldDecorator, setFieldsValue } = props.form;
  const [items, setItems] = useState<Order[]>([]);
  const [loading, setLoading] = useState(false);
  const [submitting, setSubmitting] = useState(false);
  const windowSize = useWindowSize();

  useEffect(() => {
    const fetchItems = async () => {
      setLoading(true);
      try {
        const result = await OrderService.fetchItemsForReport({
          state: '진행',
          year,
          hq,
          dept_id,
          keyword,
          is_new: isNew,
          is_urgent: isUrgent,
          has_issue: hasIssue,
        });
        setItems(Order.fromJsonArray(result.items));
      } catch (error) {
        Util.showError(error);
      } finally {
        setLoading(false);
      }
    };

    fetchItems();

    setFieldsValue({
      year,
      keyword,
    });
  }, [
    now,
    hq,
    dept_id,
    year,
    keyword,
    isNew,
    isUrgent,
    hasIssue,
    setFieldsValue,
  ]);

  const storageKeyNameRef = useRef('');
  const [selectedColumnNames, setSelectedColumnNames] = useState<string[]>([]);
  const [columnSelectorVisible, setColumnSelectorVisible] = useState(false);

  useEffect(() => {
    storageKeyNameRef.current = 'orderReportColumnsAll';
    if (isNew) storageKeyNameRef.current = 'orderReportColumnsNew';
    else if (isUrgent) storageKeyNameRef.current = 'orderReportColumnsUrgent';
    else if (hasIssue) storageKeyNameRef.current = 'orderReportColumnsIssue';
    const selectedColumnCSV =
      localStorage.getItem(storageKeyNameRef.current) ?? '';
    setSelectedColumnNames(selectedColumnCSV.split(','));
  }, [isNew, isUrgent, hasIssue]);

  const onClickColumnSelection = () => {
    setColumnSelectorVisible(true);
  };

  const onUpdateSelectedColumns = (values: string[]) => {
    localStorage.setItem(storageKeyNameRef.current, values.join(','));
    setSelectedColumnNames(values);
    setColumnSelectorVisible(false);
  };

  const onClickDownloadExcel = () => {
    const table = document.getElementsByTagName('table')[0];

    let tab_text = '<html xmlns:x="urn:schemas-microsoft-com:office:excel">';
    tab_text +=
      '<head><meta http-equiv="content-type" content="application/vnd.ms-excel; charset=UTF-8">';
    tab_text += '<xml><x:ExcelWorkbook><x:ExcelWorksheets><x:ExcelWorksheet>';
    tab_text += '<x:Name>수주목록</x:Name>';
    tab_text +=
      '<x:WorksheetOptions><x:Panes></x:Panes></x:WorksheetOptions></x:ExcelWorksheet>';
    tab_text += '</x:ExcelWorksheets></x:ExcelWorkbook></xml></head><body>';
    tab_text += "<table border='1px'>";

    let exportTable: any = table.cloneNode(true);
    tab_text += exportTable.outerHTML;

    tab_text += '</table></body></html>';

    // browser 처리
    let blob2 = new Blob([tab_text], {
      type: 'application/csv;charset=utf-8;',
    });
    let filename = title + '.xls';
    let elem = window.document.createElement('a');
    elem.href = window.URL.createObjectURL(blob2);
    elem.download = filename;
    document.body.appendChild(elem);
    elem.click();
    document.body.removeChild(elem);
  };

  const [currentItem, setCurrentItem] = useState<Order>(new Order());
  const [detailVisible, setDetailVisible] = useState(false);
  const [extraVisible, setExtraVisible] = useState(false);

  const onClickItemName = (item: Order) => {
    setCurrentItem(item);
    setDetailVisible(true);
  };

  const onCloseDetail = () => {
    setDetailVisible(false);
  };

  const onChangeOrderInfo = (updatedOrder: Order) => {
    setItems(
      items.map((el) => (el.id === updatedOrder.id ? updatedOrder : el))
    );
  };

  const onClickExtra = (item: Order) => {
    setCurrentItem(item);
    setExtraVisible(true);
  };

  const onCloseExtra = () => {
    setExtraVisible(false);
  };

  const onChangeExtraInfo = (updatedOrder: Order) => {
    setExtraVisible(false);
    setItems(
      items.map((el) => (el.id === updatedOrder.id ? updatedOrder : el))
    );
  };

  const onChangeCheckItem = async (item: Order, data: any) => {
    try {
      setSubmitting(true);

      await OrderService.update(item.id, data);
      // const result = await OrderService.updateAndFetch(item.id, data);
      // const updatedOrder = Order.fromJson(result.order);
      // setItems(
      //   items.map((el) => (el.id === updatedOrder.id ? updatedOrder : el))
      // );

      setSubmitting(false);
    } catch (err) {
      Util.showError(err);
      setSubmitting(false);
    }
  };

  const onChangePlanSubmitted = async (item: Order, e: CheckboxChangeEvent) => {
    try {
      setSubmitting(true);

      await OrderService.update(item.id, {
        plan_date: e.target.checked ? Util.formatDate(moment()) : null,
      });
      // const result = await OrderService.updateAndFetch(item.id, {
      //   plan_date: e.target.checked ? Util.formatDate(moment()) : null,
      // });
      // const updatedOrder = Order.fromJson(result.order);
      // setItems(
      //   items.map((el) => (el.id === updatedOrder.id ? updatedOrder : el))
      // );

      setSubmitting(false);
    } catch (err) {
      Util.showError(err);
      setSubmitting(false);
    }
  };

  const columnsCommon = [
    {
      title: 'No',
      dataIndex: 'id',
      fixed: 'left' as 'left',
      align: 'right' as 'right',
      width: 60,
    },
    {
      title: '신규',
      dataIndex: 'is_new',
      fixed: 'left' as 'left',
      align: 'center' as 'center',
      width: 40,
      render: (text: string, record: Order) => {
        return (
          <>
            {getFieldDecorator(`is_new_${record.id}`, {
              valuePropName: 'checked',
              initialValue: !!record.is_new,
            })(
              <Checkbox
                onChange={(e) =>
                  onChangeCheckItem(record, { is_new: e.target.checked })
                }
              />
            )}
          </>
        );
      },
    },
    {
      title: '핵심',
      dataIndex: 'is_urgent',
      fixed: 'left' as 'left',
      align: 'center' as 'center',
      width: 40,
      render: (text: string, record: Order) => {
        return (
          <>
            {getFieldDecorator(`is_urgent_${record.id}`, {
              valuePropName: 'checked',
              initialValue: !!record.is_urgent,
            })(
              <Checkbox
                onChange={(e) =>
                  onChangeCheckItem(record, { is_urgent: e.target.checked })
                }
              />
            )}
          </>
        );
      },
    },
    {
      title: '이슈',
      dataIndex: 'has_issue',
      fixed: 'left' as 'left',
      align: 'center' as 'center',
      width: 40,
      render: (text: string, record: Order) => {
        return (
          <>
            {getFieldDecorator(`has_issue_${record.id}`, {
              valuePropName: 'checked',
              initialValue: !!record.has_issue,
            })(
              <Checkbox
                onChange={(e) =>
                  onChangeCheckItem(record, { has_issue: e.target.checked })
                }
              />
            )}
          </>
        );
      },
    },
    {
      title: '계획안제출',
      dataIndex: 'plan_submitted',
      fixed: 'left' as 'left',
      align: 'center' as 'center',
      width: 50,
      render: (text: string, record: Order) => {
        return (
          <>
            {getFieldDecorator(`plan_submitted_${record.id}`, {
              valuePropName: 'checked',
              initialValue: !!record.plan_date,
            })(<Checkbox onChange={(e) => onChangePlanSubmitted(record, e)} />)}
          </>
        );
      },
    },
    {
      title: '부서',
      dataIndex: 'dept_id',
      fixed: 'left' as 'left',
      width: 80,
      render: (text: string, record: Order) => record.dept && record.dept.name,
    },
    {
      title: '프로젝트명',
      dataIndex: 'name',
      fixed: 'left' as 'left',
      width: 250,
      render: (text: string, record: Order) => (
        <AButton onClick={(e) => onClickItemName(record)}>
          <div className="truncate" style={{ width: '230px' }}>
            {text}
          </div>
        </AButton>
      ),
    },
  ];

  const columnsOptions = [
    {
      title: '발주처',
      dataIndex: 'comp_name',
    },
    {
      title: '소개자',
      dataIndex: 'client_name',
    },
    {
      title: '접수날짜',
      dataIndex: 'register_date',
      width: 85,
      render: (text: string, record: Order) =>
        Util.formatDate(record.register_date),
    },
    {
      title: '계획안 제출예정일',
      dataIndex: 'plan_est_date',
      width: 110,
      render: (text: string, record: Order) =>
        Util.formatDate(record.plan_est_date),
    },
    {
      title: '계획안 제출일',
      dataIndex: 'plan_date',
      width: 85,
      render: (text: string, record: Order) =>
        Util.formatDate(record.plan_date),
    },
    {
      title: '등급',
      dataIndex: 'grade',
      align: 'center' as 'center',
      width: 40,
    },
    {
      title: '협업',
      dataIndex: '협업',
      render: (text: string, record: Order) =>
        record.collabs.map((e) => e.name).join(', '),
    },
    {
      title: '팀장',
      dataIndex: '팀장',
      render: (text: string, record: Order) =>
        record.leaders.map((e) => e.name).join(', '),
    },
    {
      title: '담당',
      dataIndex: '담당',
      render: (text: string, record: Order) =>
        record.managers.map((e) => e.name).join(', '),
    },
    {
      title: '수주진행사항',
      dataIndex: 'stage',
    },
    {
      title: '핵심수주예상금액',
      dataIndex: '핵심수주예상금액',
      width: 110,
      align: 'right' as 'right',
      render: (text: string, record: Order) =>
        Util.formatNumber(record.core_est_price),
    },
    {
      title: '수주금액',
      dataIndex: 'f_in_total_price',
      width: 100,
      align: 'right' as 'right',
    },
    {
      title: '진성수주계약',
      dataIndex: '진성수주계약',
      width: 100,
      align: 'right' as 'right',
      render: (text: string, record: Order) =>
        Util.formatNumber(record.key_order_price),
    },
    {
      title: '견적금액',
      dataIndex: 'f_in_total_price',
      width: 100,
      align: 'right' as 'right',
    },
    {
      title: '진행사항',
      dataIndex: 'status_note',
      fixed: 'right' as 'right',
      width: 200,
    },
    {
      title: '이슈',
      dataIndex: 'issue',
      fixed: 'right' as 'right',
      width: 150,
    },
    {
      title: '비고',
      dataIndex: 'remark',
      fixed: 'right' as 'right',
      width: 150,
    },
  ];

  const editColumn = {
    title: '',
    fixed: 'right' as 'right',
    width: 40,
    render: (text: string, record: Order) => (
      <AButton onClick={(e) => onClickExtra(record)}>수정</AButton>
    ),
  };

  const [printMode, setPrintMode] = useState(false);
  const [tableMaxWidth, setTableMaxWidth] = useState(2000);
  const [tableMaxHeight, setTableMaxHeight] = useState(windowSize.height - 400);
  const [columns, setColumns] = useState<any[]>([]);
  const onChangeViewType = (checked: boolean) => {
    setPrintMode(checked);
  };

  const getOptionColumns = (columnNames: string[]) => {
    return columnsOptions.filter((column) => {
      return columnNames.includes(column.title);
    });
  };

  useEffect(() => {
    let tw = 2000;
    let th = windowSize.height - 400;
    let tableColumns: any[] = [];

    if (listType === OrderReportListType.default) {
      tableColumns = [
        ...columnsCommon,
        ...getOptionColumns([
          '발주처',
          '소개자',
          '접수날짜',
          '계획안 제출예정일',
          '계획안 제출일',
          '등급',
          '협업',
          '팀장',
          '담당',
          '수주진행사항',
          '핵심수주예상금액',
          '수주금액',
          '진성수주계약',
          '비고',
        ]),
        editColumn,
      ];
      tw = 2000;
    } else if (listType === OrderReportListType.hasIssue) {
      tableColumns = [
        ...columnsCommon,
        ...getOptionColumns([
          '발주처',
          '소개자',
          '접수날짜',
          '견적금액',
          '진행사항',
        ]),
        editColumn,
      ];
      tw = 1300;
    } else if (listType === OrderReportListType.isNew) {
      tableColumns = [
        ...columnsCommon,
        ...getOptionColumns([
          '발주처',
          '소개자',
          '접수날짜',
          '계획안 제출예정일',
          '계획안 제출일',
          '등급',
          '협업',
          '이슈',
        ]),
        editColumn,
      ];
      tw = 1500;
    }

    if (printMode) {
      tableColumns = [
        ...columnsCommon,
        ...getOptionColumns(selectedColumnNames),
      ];
      tableColumns = tableColumns.map((column) => ({
        ...column,
        fixed: undefined,
      }));
    }

    setTableMaxWidth(printMode ? 0 : tw);
    setTableMaxHeight(printMode ? 0 : th);
    setColumns(tableColumns);
  }, [listType, windowSize, printMode, selectedColumnNames]);

  const onChangeTab = (key: string) => {
    pushHistory(key, 0, year, keyword);
  };

  const onSelectDept = (dept_id: number) => {
    pushHistory(hq, dept_id, year, keyword);
  };

  const onSubmitSearchForm = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    props.form.validateFields((err, values) => {
      if (err) return;

      const year = values.year || new Date().getFullYear();
      localStorage.setItem('orderReportYear', year);

      pushHistory(hq, dept_id, year, values.keyword || '');
    });
  };

  const pushHistory = (
    hq: string,
    dept_id: number,
    year: number,
    keyword: string
  ) => {
    props.history.push(
      `${
        props.location.pathname
      }?hq=${hq}&dept_id=${dept_id}&year=${year}&keyword=${keyword}&now=${Date.now()}`
    );
  };

  const printContentRef = useRef<HTMLDivElement>(null);

  let hqTitle = '전체';
  switch (hq) {
    case 'planning':
      hqTitle = '설계부문';
      break;
    case 'business':
      hqTitle = '사업부문';
      break;
    case 'development':
      hqTitle = '개발부문';
      break;
    case 'marketing':
      hqTitle = '마케팅부문';
      break;
    case 'oversea':
      hqTitle = '해외지사';
      break;
  }

  console.count('OrderReport');

  return (
    <>
      <h2>{title}</h2>
      <Tabs type="card" onChange={onChangeTab} activeKey={hq}>
        <TabPane tab="전체" key="all" />
        <TabPane tab="설계부문" key="planning" />
        <TabPane tab="사업부문" key="business" />
        <TabPane tab="개발부문" key="development" />
        <TabPane tab="마케팅부문" key="marketing" />
        <TabPane tab="해외지사" key="oversea" />
      </Tabs>

      <Form
        className="hl-form"
        layout="inline"
        style={{ marginBottom: 7 }}
        onSubmit={onSubmitSearchForm}
      >
        <Row type="flex" justify="space-between">
          <Col>
            <Form.Item>
              <FCTextInput
                form={props.form}
                name="keyword"
                allowClear
                placeholder="검색어"
                options={{ initialValue: keyword }}
              />
            </Form.Item>

            <Form.Item>
              {getFieldDecorator('year', { initialValue: year })(
                <InputNumber placeholder="년도" style={{ width: 100 }} />
              )}
            </Form.Item>

            {hq === 'planning' && (
              <Form.Item>
                <ButtonGroup>
                  <Button
                    onClick={(e) => onSelectDept(0)}
                    type={dept_id === 0 ? 'primary' : undefined}
                  >
                    전체
                  </Button>
                  <Button
                    onClick={(e) => onSelectDept(16)}
                    type={dept_id === 16 ? 'primary' : undefined}
                  >
                    설계1본부
                  </Button>
                  <Button
                    onClick={(e) => onSelectDept(19)}
                    type={dept_id === 19 ? 'primary' : undefined}
                  >
                    설계2본부
                  </Button>
                  <Button
                    onClick={(e) => onSelectDept(24)}
                    type={dept_id === 24 ? 'primary' : undefined}
                  >
                    설계3본부
                  </Button>
                  <Button
                    onClick={(e) => onSelectDept(26)}
                    type={dept_id === 26 ? 'primary' : undefined}
                  >
                    설계4본부
                  </Button>
		<Button
                    onClick={(e) => onSelectDept(54)}
                    type={dept_id === 54 ? 'primary' : undefined}
                  >
                    설계5본부
                  </Button>
                  <Button
                    onClick={(e) => onSelectDept(22)}
                    type={dept_id === 22 ? 'primary' : undefined}
                  >
                    도시본부
                  </Button>
                </ButtonGroup>
              </Form.Item>
            )}
            {hq === 'business' && (
              <Form.Item>
                <ButtonGroup>
                  <Button
                    onClick={(e) => onSelectDept(0)}
                    type={dept_id === 0 ? 'primary' : undefined}
                  >
                    전체
                  </Button>
                  <Button
                    onClick={(e) => onSelectDept(32)}
                    type={dept_id === 32 ? 'primary' : undefined}
                  >
                    본사CM본부
                  </Button>
                  <Button
                    onClick={(e) => onSelectDept(30)}
                    type={dept_id === 30 ? 'primary' : undefined}
                  >
                    금융1팀
                  </Button>
                  <Button
                    onClick={(e) => onSelectDept(31)}
                    type={dept_id === 31 ? 'primary' : undefined}
                  >
                    금융2팀
                  </Button>
                  <Button
                    onClick={(e) => onSelectDept(33)}
                    type={dept_id === 33 ? 'primary' : undefined}
                  >
                    CS
                  </Button>
                </ButtonGroup>
              </Form.Item>
            )}

            <Form.Item>
              <Button type="primary" htmlType="submit">
                검색
              </Button>
            </Form.Item>
          </Col>
          <Col>
            <Form.Item label="출력모드">
              <InlineItem>
                {getFieldDecorator('printMode', {
                  valuePropName: 'checked',
                  initialValue: printMode,
                })(<Switch onChange={onChangeViewType} />)}
              </InlineItem>
            </Form.Item>
            <Form.Item>
              <Button disabled={!printMode} onClick={onClickColumnSelection}>
                컬럼선택...
              </Button>
            </Form.Item>
            <Form.Item>
              <ReactToPrint
                trigger={() => <Button disabled={!printMode}>출력</Button>}
                content={() => printContentRef.current}
              />
            </Form.Item>
            <Form.Item>
              <Button disabled={!printMode} onClick={onClickDownloadExcel}>
                다운로드
              </Button>
            </Form.Item>
          </Col>
        </Row>
      </Form>

      <div ref={printContentRef}>
        <h2 className="only-print">
          {title} / {year}년 {hqTitle}
        </h2>
        <Table
          columns={columns}
          dataSource={items}
          rowKey="id"
          pagination={false}
          bordered={true}
          loading={loading || submitting}
          size="middle"
          scroll={{ x: tableMaxWidth, y: tableMaxHeight }}
          rowClassName={(record) => {
            return 'hl-body-small';
          }}
        />
      </div>

      <Drawer
        closable={false}
        placement="right"
        width={'85%'}
        visible={detailVisible}
        onClose={onCloseDetail}
        destroyOnClose={true}
      >
        {/* <OrderDetail
          id={currentItem.id}
          fetchDelayed
        /> */}
        <OrderView
          id={currentItem.id}
          title={currentItem.name}
          allowEditing
          onChangeOrderInfo={onChangeOrderInfo}
        />
      </Drawer>

      <Drawer
        closable={false}
        placement="right"
        width={1000}
        visible={extraVisible}
        onClose={onCloseExtra}
        destroyOnClose={true}
      >
        <OrderExtraEdit id={currentItem.id} onSuccess={onChangeExtraInfo} />
      </Drawer>

      <Modal
        title="컬럼 선택"
        width={800}
        centered
        visible={columnSelectorVisible}
        footer={null}
        onCancel={() => setColumnSelectorVisible(false)}
        destroyOnClose={true}
      >
        <OrderReportColumnSelector
          sourceColumnNames={columnsOptions.map((column) => column.title)}
          selectedColumnNames={selectedColumnNames}
          onUpdate={onUpdateSelectedColumns}
        />
      </Modal>
    </>
  );
};

export default withRouter(Form.create<Props>()(OrderReport));
