import React from 'react';
import { Route, Switch, Redirect } from 'react-router-dom';
import UnderConst from '../../components/UnderConst';
import ArchiveGroupBrowser from '../../components/general/ArchiveGroupBrowser';

interface Props {}

const Index: React.FC<Props> = props => {
  return (
    <Switch>
      <Route path="/development/support/uc" component={UnderConst} />
      <Route
        path="/development/support/archive"
        component={ArchiveGroupBrowser}
      />

      <Route path="/development/support" exact>
        <Redirect to="/development/support/archive?id=4101" />
      </Route>
    </Switch>
  );
};

export default Index;
