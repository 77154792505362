import React, { useState, useEffect } from 'react';
import { RouteComponentProps, withRouter } from 'react-router';
import Util from '../../../helpers/Util';
import Spinner from '../../../components/general/Spinner';
import {
  HDescriptions,
  HDRowItem
} from '../../../components/general/HDescriptions';
import AttachedFileList from '../../../components/general/AttachedFileList';
import { Education } from '../../../models/Education';
import EducationService from '../../../services/EducationService';

interface Props extends RouteComponentProps {
  id: number;
}

const EducationDetail: React.FC<Props> = props => {
  const itemId = props.id || 0;
  const [loading, setLoading] = useState(false);
  const [item, setItem] = useState<Education>(new Education());

  useEffect(() => {
    const fetchItem = async (id: number) => {
      setLoading(true);
      try {
        const result = await EducationService.fetchItem(id);
        const education = Education.fromJson(result.education);
        setItem(education);
      } catch (error) {
        Util.showError(error);
      } finally {
        setLoading(false);
      }
    };

    if (itemId > 0) {
      fetchItem(itemId);
    }
  }, [itemId]);

  if (loading) {
    return <Spinner />;
  }

  return (
    <>
      <h2>{item.title}</h2>
      <HDescriptions>
        <HDRowItem label="강사명">{item.instructor}</HDRowItem>
        <HDRowItem label="교육일시">{Util.formatDateTime(item.date)}</HDRowItem>
        <HDRowItem label="교육개요">{item.summary}</HDRowItem>
        <HDRowItem label="신청인원">
          {item.apply_count} / {item.seat_count}
        </HDRowItem>
        {item.files.length > 0 && (
          <HDRowItem label="교육자료">
            <AttachedFileList items={item.files} />
          </HDRowItem>
        )}
      </HDescriptions>
    </>
  );
};

export default withRouter(EducationDetail);
