import Service from './Index';
import { CompanyType } from '../models/Compnay';

export type CompanySearchOption = {
  type?: CompanyType;
  keyword?: string;
  category?: string;
};

const CompanyService = {
  fetchCategories: (type: CompanyType) => {
    return Service.get('/etc/companies/categories', { type });
  },
  fetchItems: (limit: number, page: number, options?: CompanySearchOption) => {
    const offset = (page - 1) * limit;
    return Service.get('/etc/companies', { limit, offset, ...options });
  },
  create: (data: any) => {
    return Service.post('/etc/companies', data);
  },
  fetchItem: (id: number) => {
    return Service.get(`/etc/companies/${id}`);
  },
  update: (id: number, data: any) => {
    return Service.put(`/etc/companies/${id}`, data);
  },
  delete: (id: number) => {
    return Service.delete(`/etc/companies/${id}`);
  }
};

export default CompanyService;
