import React, { useState, useEffect } from 'react';
import { Button, Row, Col, Icon, Popconfirm } from 'antd';
import { RouteComponentProps, withRouter } from 'react-router';
import { Post } from '../../models/Post';
import Util from '../../helpers/Util';
import PostService from '../../services/PostService';
import CommentSection from '../../components/general/CommentSection';
import HTMLContentViewer from '../../components/general/HTMLContentViewer';
import Spinner from '../../components/general/Spinner';
import {
  HDescriptions,
  HDRowItem,
  HDRow,
  HDItem
} from '../../components/general/HDescriptions';
import AttachedFileList from '../../components/general/AttachedFileList';

interface Props extends RouteComponentProps {
  type?: number;
  id?: number;
  preventEditing?: boolean;
}

const PostViewer: React.FC<Props> = props => {
  const { location, preventEditing = false } = props;
  const params = new URLSearchParams(location.search);
  const type = Number(params.get('type')) || props.type || 0;
  const postId = Number(params.get('id')) || props.id || 0;

  const [loading, setLoading] = useState(true);
  const [item, setItem] = useState<Post>(new Post());

  useEffect(() => {
    const fetchDetail = async () => {
      setLoading(true);
      try {
        const result = await PostService.fetchItem(postId);
        await PostService.read(postId);
        setItem(Post.fromJson(result.post));
      } catch (error) {
        Util.showError(error);
      } finally {
        setLoading(false);
      }
    };

    if (postId > 0) {
      fetchDetail();
    }
  }, [postId]);

  const onClickEdit = () => {
    props.history.push(`./edit?type=${type}&id=${postId}`);
  };

  const onClickDelete = async () => {
    try {
      setLoading(true);
      await PostService.delete(postId);
      Util.showSuccess('삭제되었습니다.');
      props.history.push(`./?type=${item.type}&refId=${item.ref_id || ''}`);
    } catch (error) {
      setLoading(false);
      Util.showError(error);
    }
  };

  if (loading) {
    return <Spinner />;
  }

  return (
    <>
      <h2>{item.title}</h2>
      <Row>
        <Col className="gutter-row" span={24}>
          <HDescriptions>
            <HDRow>
              <HDItem label="작성자">{item.owner.nameWithDeptRank}</HDItem>
              <HDItem label="작성일">
                {Util.formatDateTime(item.created_at)}
              </HDItem>
              <HDItem label="조회">{item.read_count + 1}</HDItem>
            </HDRow>
            {item.files.length > 0 && (
              <HDRowItem label="첨부 파일" colSpan={5}>
                <AttachedFileList items={item.files} />
              </HDRowItem>
            )}
          </HDescriptions>
          <HTMLContentViewer content={item.content} />
        </Col>
      </Row>

      <Row>
        <Col className="gutter-row" span={24}>
          {!preventEditing && item.hasRightToEdit && (
            <Row
              type="flex"
              justify="space-between"
              style={{ marginTop: '10px' }}
            >
              <Col></Col>
              <Col className="gutter-row">
                <Row gutter={10}>
                  <Col span={12}>
                    <Button
                      block
                      disabled={loading}
                      onClick={e => onClickEdit()}
                    >
                      <Icon type="edit" />수 정
                    </Button>
                  </Col>
                  <Col span={12}>
                    <Popconfirm
                      title="정말 삭제하시겠습니까?"
                      onConfirm={e => onClickDelete()}
                    >
                      <Button block disabled={loading}>
                        <Icon type="delete" />삭 제
                      </Button>
                    </Popconfirm>
                  </Col>
                </Row>
              </Col>
            </Row>
          )}
          <CommentSection refTarget={2} refId={postId} />
        </Col>
      </Row>
    </>
  );
};

export default withRouter(PostViewer);
