import { MenuItem } from '../../../models/Common';

const menuItems: MenuItem[] = [
  {
    id: '알림',
    title: '알림',
    icon: 'desktop',
    link: '/works/board/notifications'
  },
  {
    id: '공지사항',
    title: '공지사항',
    icon: 'desktop',
    children: [
      {
        id: '공지1',
        title: '회사공지',
        link: '/works/board/posts/notice-all'
      },
      {
        id: '공지2',
        title: '본부공지',
        link: '/works/board/posts/notice-dept'
      },
      {
        id: '공지3',
        title: '전사일정',
        link: '/works/board/occasions'
      }
    ]
  },
  {
    id: '회람',
    title: '회람',
    icon: 'desktop',
    children: [
      {
        id: '회람1',
        title: '회사회람',
        link: '/works/board/posts/circular-all'
      },
      {
        id: '회람2',
        title: '본부회람',
        link: '/works/board/posts/circular-dept'
      }
    ]
  },
  {
    id: '일정관리',
    title: '일정관리',
    icon: 'desktop',
    link: '/works/board/plans'
  },
  {
    id: '문서함',
    title: '문서함',
    icon: 'desktop',
    children: [
      { id: '1', title: '사내양식' },
      { id: '2', title: '계약서' },
      { id: '3', title: '매뉴얼' }
    ]
  },
  {
    id: '기타',
    title: '기타',
    icon: 'desktop',
    children: [
      {
        id: '기타1',
        title: '시스템 업데이트내역',
        link: '/works/board/release-notes'
      }
    ]
  }
];

export default menuItems;
