import Service from './Index';
import { EADocListType } from '../models/EAS';

export type EADocSearchOption = {
  type?: EADocListType;
  keyword?: string;
  ref_target?: string;
  ref_id?: number;
  category?: number;
  menu?: string;
  data_dept_id?: number;
};

const EAService = {
  create: (data: any) => {
    return Service.post(`/eas`, data);
  },
  saveDraft: (data: any) => {
    return Service.post(`/eas/save_draft`, data);
  },
  // 결재문서 수정 (eadocs뿐만 아니라 참조문서, 첨부파일등 UI상에서 결재문서 수정시 호출)
  update: (id: number, data: any) => {
    return Service.put(`/eas/${id}`, data);
  },
  // eadocs만 업데이트 (eadocs table update)
  updateEADoc: (id: number, data: any) => {
    return Service.put(`/eas/docs/${id}`, data);
  },
  delete: (id: number) => {
    return Service.delete(`/eas/${id}`);
  },
  fetchItems: (limit: number, page: number, options?: EADocSearchOption) => {
    const offset = (page - 1) * limit;
    return Service.get('/eas', { limit, offset, ...options });
  },
  fetchTripItems: (
    limit: number,
    page: number,
    user_id?: number,
    keyword?: string
  ) => {
    const offset = (page - 1) * limit;
    return Service.get('/eas/trip_items', { limit, offset, user_id, keyword });
  },
  fetchItem: (id: number, title_only?: boolean) => {
    return Service.get(`/eas/${id}`, { title_only });
  },
  fetchItemByRef: (
    ref_target: string,
    ref_id: number,
    title_only?: boolean
  ) => {
    return Service.get(`/eas/ref`, { ref_target, ref_id, title_only });
  },
  submitItem: (id: number) => {
    return Service.post(`/eas/${id}/submit`);
  },
  approveItem: (id: number) => {
    return Service.post(`/eas/${id}/approve`);
  },
  returnItem: (id: number) => {
    return Service.post(`/eas/${id}/return`);
  },
  cancelApproveItem: (id: number) => {
    return Service.post(`/eas/${id}/cancel`);
  },
  pendItem: (id: number) => {
    return Service.post(`/eas/${id}/pend`);
  },
  fetchActivities: (docId: number, limit: number, page: number) => {
    const offset = (page - 1) * limit;
    return Service.get(`/eas/${docId}/activities`, { limit, offset });
  }
};

export default EAService;
