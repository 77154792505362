import React, { useState, useEffect, useRef } from 'react';
import { Order, Contract } from '../../../models/Order';
import { Row, Col, Input, Drawer } from 'antd';
import ContractTable from './ContractTable';
import OrderService from '../../../services/OrderService';
import Util from '../../../helpers/Util';
import { FormComponentProps } from 'antd/lib/form';
import Spinner from '../../../components/general/Spinner';
import ContractEdit from '../contracts/ContractEdit';
import _ from 'lodash';

interface Props extends FormComponentProps {
  orderId: number;
  data?: any;
  fetchDelayed?: boolean;
}

const ContractOutEditor: React.FC<Props> = props => {
  const { orderId, data, fetchDelayed = false } = props;
  const [loading, setLoading] = useState(true);
  const [inContracts, setInContracts] = useState<Contract[]>([]);
  const [outContracts, setOutContracts] = useState<Contract[]>([]);
  const [terms, setTerms] = useState<string[]>([]);
  const { getFieldDecorator, setFieldsValue } = props.form;

  console.count('ContractOutEditor');

  useEffect(() => {
    const fetchItems = async () => {
      try {
        setLoading(true);

        if (data) {
          setTerms(data.terms);
          setInContracts(Contract.fromJsonArray(data.ctrins));
          setOutContracts(Contract.fromJsonArray(data.ctrouts));
        } else {
          const result = await OrderService.fetchItem(orderId);
          const order = Order.fromJson(result.order);
          setTerms(order.terms);

          const result2 = await OrderService.fetchContracts(orderId, false);
          const result3 = await OrderService.fetchContracts(orderId, true);
          setInContracts(Contract.fromJsonArray(result2.contracts));
          setOutContracts(Contract.fromJsonArray(result3.contracts));
        }
      } catch (error) {
        Util.showError(error);
      } finally {
        setLoading(false);
      }
    };

    if (fetchDelayed) {
      setTimeout(() => {
        fetchItems();
      }, 400);
    } else {
      fetchItems();
    }
  }, [orderId, data, fetchDelayed]);

  const contractRef = useRef<Contract>(new Contract());
  const [contractEditVisible, setContractEditVisible] = useState(false);

  const onSelectOutContract = (itemId: number) => {
    const contract = outContracts.find(el => el.id === itemId);
    if (contract) {
      console.log('onSelectOutContract.contract: ', contract);
      contractRef.current = contract;
      setContractEditVisible(true);
    }
  };

  const onCloseContractEdit = () => {
    setContractEditVisible(false);
  };

  const onClickSaveContract = (values: any) => {
    // console.log('onClickSaveContract: ', values);
    const savedContract = Contract.fromJson(values);

    const updatedOutContracts = outContracts.map(el => {
      if (el.id === savedContract.id) {
        return savedContract;
      } else {
        return el;
      }
    });
    setOutContracts(updatedOutContracts);

    const savedTerms = savedContract.items.map(el => el.term || '');
    const updatedTerms = _.uniq([...terms, ...savedTerms]);
    setTerms(updatedTerms);
    // console.log('updatedTerms: ', updatedTerms);

    setFieldsValue({ ctrouts: updatedOutContracts, terms: updatedTerms });

    // console.log('savedContract: ', savedContract);
    // console.log('outContracts: ', outContracts);
    // console.log('terms: ', terms);
    setContractEditVisible(false);
  };

  if (loading) {
    return <Spinner />;
  }

  return (
    <>
      <Row type="flex" justify="space-between" style={{ marginBottom: 10 }}>
        <Col>
          <h3>수주계약</h3>
        </Col>
      </Row>
      <ContractTable out={false} contracts={inContracts} terms={terms} />

      <Row
        type="flex"
        justify="space-between"
        style={{ marginBottom: 10, marginTop: 20 }}
      >
        <Col>
          <h3>외주계약 (총 {outContracts.length}건)</h3>
        </Col>
      </Row>
      <ContractTable
        out={true}
        contracts={outContracts}
        terms={terms}
        onSelectItem={onSelectOutContract}
      />

      <Drawer
        closable={false}
        placement="right"
        width={1200}
        visible={contractEditVisible}
        onClose={onCloseContractEdit}
        destroyOnClose={true}
      >
        <ContractEdit
          // id={itemIdRef.current}
          contract={contractRef.current}
          fetchDelayed
          onClickSave={onClickSaveContract}
        />
      </Drawer>

      {getFieldDecorator('order_id', { initialValue: orderId })(
        <Input type="hidden" />
      )}
      {getFieldDecorator('terms', { initialValue: terms })(
        <Input type="hidden" />
      )}
      {getFieldDecorator('ctrins', { initialValue: inContracts })(
        <Input type="hidden" />
      )}
      {getFieldDecorator('ctrouts', { initialValue: outContracts })(
        <Input type="hidden" />
      )}
    </>
  );
};

export default ContractOutEditor;
