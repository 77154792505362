import React from 'react';
import { Route, Switch } from 'react-router-dom';
import UnderConst from '../../components/UnderConst';
import ArchiveGroupBrowser from '../../components/general/ArchiveGroupBrowser';
import { Order } from '../../models/Order';
import OrderFileBrowser from '../../components/general/OrderFileBrowser';

interface Props {
  order: Order;
}

const Index: React.FC<Props> = props => {
  return (
    <Switch>
      <Route path="/business/orders/uc" component={UnderConst} />
      <Route path="/business/orders/:oid/uc" component={UnderConst} />
      <Route path="/business/orders/:oid/files" component={OrderFileBrowser} />
      <Route
        path="/business/orders/:oid/archive"
        component={ArchiveGroupBrowser}
      />

      {/* <Route path="/business/orders/:oid" exact>
        <h2>{props.order && props.order.name}</h2>
      </Route> */}
      <Route path="/business/orders/:oid" exact component={OrderFileBrowser} />
    </Switch>
  );
};

export default Index;
