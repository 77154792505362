import React, { useState, useEffect } from 'react';
import { Input } from 'antd';
import { GetFieldDecoratorOptions } from 'antd/lib/form/Form';
import { FormComponentProps } from 'antd/lib/form';
import TextArea from 'antd/lib/input/TextArea';

interface Props extends FormComponentProps {
  name: string;
  options?: GetFieldDecoratorOptions;
}

const FCTextArea = (props: Props) => {
  const { getFieldDecorator, getFieldValue, setFieldsValue } = props.form;
  const [value, setValue] = useState('');

  const fieldValue = getFieldValue(props.name);
  useEffect(() => {
    setValue(fieldValue);
  }, [fieldValue]);

  return (
    <>
      {getFieldDecorator(props.name, props.options)(<Input type="hidden" />)}
      <TextArea
        rows={8}
        value={value}
        onChange={e => {
          setValue(e.target.value);
        }}
        onBlur={e => {
          setFieldsValue({ [props.name]: e.target.value });
        }}
      />
    </>
  );
};

export default FCTextArea;
