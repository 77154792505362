import React, { useState } from 'react';
import { Button, Form } from 'antd';
import { FormComponentProps } from 'antd/lib/form';
import TextArea from 'antd/lib/input/TextArea';
import Util from '../../helpers/Util';
import { Lawsuit, LawsuitEvent } from '../../models/Lawsuit';
import LawsuitService from '../../services/LawsuitService';
import FCFileInput from '../../components/formcomps/FCFileInput';
import FIDatePicker from '../../components/formcomps/FIDatePicker';

interface Props extends FormComponentProps {
  lawsuit: Lawsuit;
  item?: LawsuitEvent;
  onSuccess?: () => void;
}

const LawsuitEventEdit: React.FC<Props> = props => {
  const { item } = props;
  const [submitting, setSubmitting] = useState(false);
  const { getFieldDecorator } = props.form;

  const onSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    props.form.validateFields((err, values) => {
      if (err) return;
      processItem(values);
    });
  };

  const processItem = async (values: any) => {
    try {
      setSubmitting(true);

      values.date = values.date && Util.formatDate(values.date);

      if (item) {
        await LawsuitService.updateEvent(item.id, values);
      } else {
        values.lawsuit_id = props.lawsuit.id;
        await LawsuitService.createEvent(values);
      }

      Util.showSuccess('저장되었습니다.');
      if (props.onSuccess) props.onSuccess();
    } catch (err) {
      Util.showError(err);
      setSubmitting(false);
    }
  };

  return (
    <>
      <h2>사건경과 {item ? '수정' : '등록'}</h2>

      <Form
        className="hl-form"
        labelCol={{ span: 4 }}
        wrapperCol={{ span: 18 }}
        onSubmit={onSubmit}
      >
        <FIDatePicker
          form={props.form}
          label="날짜"
          initialValue={item && Util.formatDate(item.date)}
          required="날짜를 선택해주세요."
        />

        <Form.Item label="경과내용">
          {getFieldDecorator('note', {
            initialValue: item && item.note,
            rules: [{ required: true, message: '경과내용을 입력해주세요.' }]
          })(<TextArea rows={8} />)}
        </Form.Item>

        <Form.Item label="파일 첨부">
          <FCFileInput form={props.form} items={item && item.files} />
        </Form.Item>

        <Form.Item wrapperCol={{ span: 12, offset: 4 }}>
          <Button type="primary" htmlType="submit" disabled={submitting}>
            저 장
          </Button>
        </Form.Item>
      </Form>
    </>
  );
};

export default Form.create<Props>()(LawsuitEventEdit);
