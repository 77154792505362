import React, { useState } from 'react';
import { Table, Tag, Drawer, Icon, Popover } from 'antd';
import { EADoc } from '../../models/EAS';
import { PaginationConfig } from 'antd/lib/table';
import LinkButton from '../../components/general/LinkButton';
import EADocDetail from './EADocDetail';
import { withRouter, RouteComponentProps } from 'react-router';
import Util from '../../helpers/Util';
import AButton from '../../components/general/AButton';
import FileService from '../../services/FileService';
import { File } from '../../models/File';
import filesize from 'filesize';

interface Props extends RouteComponentProps {
  items: EADoc[];
  loading?: boolean;
  pagination?: PaginationConfig | false;
  onChangeCurrentPage?: (page: number) => void;
}

const EADocListPanel: React.FC<Props> = props => {
  const { items, loading = false, pagination } = props;

  const onChangeTable = (pagination: PaginationConfig) => {
    if (props.onChangeCurrentPage)
      props.onChangeCurrentPage(pagination.current || 1);
  };

  const [detailVisible, setDetailVisible] = useState(false);
  const [eadocId, setEADocId] = useState(0);
  const onClickItem = (item: EADoc) => {
    setEADocId(item.id);
    setDetailVisible(true);

    // NOTE: 승인,반려등 처리 후 history.back 되는 이슈때문에 일단 페이지 이동 처리
    // props.history.push(`/works/eas/view?id=${item.id}`);
  };

  const onCloseDetail = () => {
    setDetailVisible(false);
  };

  const onClickFile = async (item: File) => {
    try {
      await FileService.downloadFile(item.id, item.original_name);
    } catch (error) {
      Util.showError(error);
    }
  };

  const columns = [
    {
      title: '문서코드',
      dataIndex: 'code',
      width: 110
    },
    {
      title: '제목',
      dataIndex: 'title',
      render: (text: string, record: EADoc) => {
        return (
          <>
            <LinkButton onClick={e => onClickItem(record)}>{text}</LinkButton>
            {record.comment_count > 0 && ` (${record.comment_count})`}
          </>
        );
      }
    },
    {
      title: '',
      dataIndex: 'files2',
      width: 30,
      align: 'center' as 'center',
      render: (text: string, record: EADoc) => {
        return (
          <>
            {record.files.length > 0 && (
              <Popover
                content={
                  <>
                    {record.files.map(file => (
                      <span key={file.id} style={{ display: 'block' }}>
                        <LinkButton onClick={e => onClickFile(file)}>
                          <Icon type="download" />
                        </LinkButton>{' '}
                        {file.isPreviewable ? (
                          <AButton onClick={e => FileService.previewFile(file)}>
                            {file.original_name}
                          </AButton>
                        ) : (
                          <>{file.original_name}</>
                        )}{' '}
                        ({filesize(file.size, { round: 0 })})
                      </span>
                    ))}
                  </>
                }
                title="첨부파일"
                trigger="click"
              >
                <LinkButton>
                  <Icon type="paper-clip" />
                </LinkButton>
              </Popover>
            )}
          </>
        );
      }
    },
    {
      title: '기안자',
      dataIndex: 'owner.name'
    },
    {
      title: '대기자',
      dataIndex: 'assignedTo.nameWithRank'
    },
    {
      title: '상태',
      dataIndex: 'state',
      render: (text: string, record: EADoc) => {
        return <Tag color={record.stateColor}>{record.stateName}</Tag>;
      }
    },
    {
      title: '작성일',
      dataIndex: 'created_at',
      render: (text: string, record: EADoc) => {
        return Util.formatDate(text);
      }
    },
    {
      title: '최종업데이트',
      dataIndex: 'updated_at',
      render: (text: string, record: EADoc) => {
        return Util.formatDateTimeHHMM(text);
      }
    }
  ];

  return (
    <>
      <Table
        columns={columns}
        dataSource={items}
        rowKey="id"
        pagination={pagination}
        onChange={onChangeTable}
        bordered={true}
        loading={loading}
        size="middle"
      />

      <Drawer
        closable={false}
        placement="right"
        width={'85%'}
        visible={detailVisible}
        onClose={onCloseDetail}
        destroyOnClose={true}
      >
        <EADocDetail id={eadocId} fetchDelayed={true} />
      </Drawer>
    </>
  );
};

export default withRouter(EADocListPanel);
