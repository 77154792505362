import React, { useState, useEffect, useRef } from 'react';
import { Order, Contract } from '../../../models/Order';
import ContractPayBook from '../contracts/ContractPayBook';
import { Button, Icon, Row, Col, Drawer } from 'antd';
import ContractTable from './ContractTable';
import ContractTermsEditor from './ContractTermsEditor';
import EADocDetail from '../../eas/EADocDetail';
import EADocDrafter from '../../eas/EADocDrafter';
import OrderService from '../../../services/OrderService';
import Util from '../../../helpers/Util';
import ContractEdit from '../contracts/ContractEdit';

interface Props {
  order: Order;
  onChangePayBook?: () => void;
  onChangeContract?: () => void;
}

const OrderContracts: React.FC<Props> = props => {
  const [order, setOrder] = useState<Order>(props.order);
  const [inContracts, setInContracts] = useState<Contract[]>([]);
  const [outContracts, setOutContracts] = useState<Contract[]>([]);
  const [refreshTrigger, setRefreshTrigger] = useState(1);

  console.count('OrderContracts');

  useEffect(() => {
    const fetchItems = async () => {
      try {
        const result1 = await OrderService.fetchContracts(order.id, false);
        setInContracts(Contract.fromJsonArray(result1.contracts));

        const result2 = await OrderService.fetchContracts(order.id, true);
        setOutContracts(Contract.fromJsonArray(result2.contracts));
      } catch (error) {
        Util.showError(error);
      }
    };

    fetchItems();
  }, [refreshTrigger, order.id]);

  useEffect(() => {
    const fetchItems = async () => {
      try {
        const result = await OrderService.fetchItem(order.id);
        setOrder(Order.fromJson(result.order));
      } catch (error) {
        Util.showError(error);
      }
    };

    if (refreshTrigger > 1) {
      fetchItems();
    }
  }, [refreshTrigger, order.id]);

  const isOutConract = useRef(false);
  const paybookDirtyRef = useRef(false);
  const [collectEditVisible, setCollectEditVisible] = useState(false);

  const onClickCollect = () => {
    isOutConract.current = false;
    setCollectEditVisible(true);
  };
  const onClickPayment = () => {
    isOutConract.current = true;
    setCollectEditVisible(true);
  };
  const onCloseCollectEdit = () => {
    setCollectEditVisible(false);
    if (paybookDirtyRef.current) {
      paybookDirtyRef.current = false;
      setRefreshTrigger(refreshTrigger + 1);
    }
  };

  const onChangePayBook = () => {
    paybookDirtyRef.current = true;
    props.onChangePayBook && props.onChangePayBook();
  };

  const [termsEditorVisible, setTermsEditorVisible] = useState(false);
  const onClickEditTerms = () => {
    setTermsEditorVisible(true);
  };
  const onCloseTermsEditor = () => {
    setTermsEditorVisible(false);
  };
  const onSubmitTermsEditor = () => {
    setTermsEditorVisible(false);
    setRefreshTrigger(refreshTrigger + 1);
  };

  const [detailInfo, setDetailInfo] = useState({
    show: false,
    ref_target: 'ord',
    draft: false
  });

  const onClickOrderInfo = () => {
    setDetailInfo({ show: true, ref_target: 'ord', draft: false });
  };

  const onClickOrderIn = () => {
    setDetailInfo({
      show: true,
      ref_target: 'ordin',
      draft: !order.ordin_eaid
    });
  };

  const onClickContractIn = () => {
    setDetailInfo({
      show: true,
      ref_target: 'ctrin',
      draft: !order.ctrin_eaid
    });
  };

  const onClickOrderOut = () => {
    setDetailInfo({
      show: true,
      ref_target: 'ordout',
      draft: !order.ordout_eaid
    });
  };

  const onClickContractOut = () => {
    setDetailInfo({
      show: true,
      ref_target: 'ctrout',
      draft: !order.ctrout_eaid
    });
  };

  const onCloseDetailInfo = () => {
    setDetailInfo({ ...detailInfo, show: false });
  };

  const allowContractIn = !!order.ordin_eaid && order.ordin;
  const allowEditingCollect = !!order.ctrin_eaid && order.ctrin;
  const allowOrderOut = allowEditingCollect;
  const allowContractOut =
    !!order.ordout_eaid && order.ordout && outContracts.length > 0;
  const allowEditingPayment = !!order.ctrout_eaid && order.ctrout;

  const itemIdRef = useRef(0);
  // const restrictEditingRef = useRef(false);
  const [contractEditVisible, setContractEditVisible] = useState(false);

  const onSelectInContract = (itemId: number) => {
    itemIdRef.current = itemId;
    // restrictEditingRef.current = allowEditingCollect;
    setContractEditVisible(true);
  };

  const onSelectOutContract = (itemId: number) => {
    itemIdRef.current = itemId;
    // restrictEditingRef.current = allowEditingPayment;
    setContractEditVisible(true);
  };

  const onCloseContractEdit = () => {
    setContractEditVisible(false);
  };

  const onSaveContract = () => {
    setContractEditVisible(false);
    setRefreshTrigger(refreshTrigger + 1);
    props.onChangeContract && props.onChangeContract();
  };

  let drafterTitle = '';
  switch (detailInfo.ref_target) {
    case 'ordin':
      drafterTitle = '수주견적품의서';
      break;
    case 'ctrin':
      drafterTitle = '수주계약품의서';
      break;
    case 'ordout':
      drafterTitle = '외주실행보고서';
      break;
    case 'ctrout':
      drafterTitle = '외주계약품의서';
      break;
  }
  const docTitle = `${drafterTitle} - ${order.name}`;

  return (
    <>
      <Row type="flex" justify="space-between" style={{ marginBottom: 10 }}>
        <Col>
          <h3>수주계약</h3>
        </Col>
        <Col>
          <Button onClick={onClickEditTerms}>
            <Icon type="ordered-list" />
            수금/지급조건 정렬
          </Button>{' '}
          <Button onClick={onClickOrderInfo}>
            <Icon type="check" />
            수주정보보고
          </Button>{' '}
          <Button onClick={onClickOrderIn}>
            {order.ordin && <Icon type="check" />}
            수주견적품의
          </Button>{' '}
          <Button onClick={onClickContractIn} disabled={!allowContractIn}>
            {order.ctrin && <Icon type="check" />}
            수주계약품의
          </Button>{' '}
          <Button onClick={onClickCollect} disabled={!allowEditingCollect}>
            <Icon type="plus" />
            수금등록
          </Button>
        </Col>
      </Row>
      <ContractTable
        out={false}
        contracts={inContracts}
        terms={order.terms}
        onSelectItem={onSelectInContract}
      />

      <Row
        type="flex"
        justify="space-between"
        style={{ marginBottom: 10, marginTop: 20 }}
      >
        <Col>
          <h3>외주계약 (총 {outContracts.length}건)</h3>
        </Col>
        <Col>
          <Button onClick={onClickOrderOut} disabled={!allowOrderOut}>
            {order.ordout && <Icon type="check" />}
            외주실행보고
          </Button>{' '}
          <Button onClick={onClickContractOut} disabled={!allowContractOut}>
            {order.ctrout && <Icon type="check" />}
            외주계약품의
          </Button>{' '}
          <Button onClick={onClickPayment} disabled={!allowEditingPayment}>
            <Icon type="plus" />
            지급등록
          </Button>
        </Col>
      </Row>
      <ContractTable
        out={true}
        contracts={outContracts}
        terms={order.terms}
        onSelectItem={onSelectOutContract}
      />

      <Drawer
        closable={false}
        placement="right"
        width="85%"
        visible={contractEditVisible}
        onClose={onCloseContractEdit}
        destroyOnClose={true}
      >
        <ContractEdit
          id={itemIdRef.current}
          fetchDelayed
          // restrictEditing={restrictEditingRef.current}
          onSave={onSaveContract}
        />
      </Drawer>

      <Drawer
        closable={false}
        placement="right"
        width="85%"
        visible={collectEditVisible}
        onClose={onCloseCollectEdit}
        destroyOnClose={true}
      >
        <ContractPayBook
          orderId={order.id}
          out={isOutConract.current}
          onChange={onChangePayBook}
        />
      </Drawer>

      <Drawer
        closable={false}
        placement="right"
        width="85%"
        visible={detailInfo.show}
        onClose={onCloseDetailInfo}
        destroyOnClose={true}
      >
        {detailInfo.draft ? (
          <EADocDrafter
            title={drafterTitle}
            docTitle={docTitle}
            refTarget={detailInfo.ref_target}
            refId={order.id}
            fetchDelayed={true}
          />
        ) : (
          <EADocDetail
            refTarget={detailInfo.ref_target}
            refId={order.id}
            fetchDelayed={true}
          />
        )}
      </Drawer>

      <Drawer
        title="수금/지급조건 정렬"
        placement="right"
        width={800}
        visible={termsEditorVisible}
        onClose={onCloseTermsEditor}
        destroyOnClose={true}
      >
        <ContractTermsEditor order={order} onSubmit={onSubmitTermsEditor} />
      </Drawer>
    </>
  );
};

export default OrderContracts;
