import React from 'react';
import { Route, Switch } from 'react-router-dom';
import Works from './works/Index';
import ProjectIndex from './projects/Index';
import Management from './management/Index';
import Planning from './planning/Index';
import Business from './business/Index';
import Development from './development/Index';
import Marketing from './marketing/Index';
import Oversea from './oversea/Index';
import Reports from './reports/Index';
import Settings from './settings/Index';
import Test from './test/Index';

const AppContent = () => {
  return (
    <>
      <Switch>
        <Route path="/works" component={Works} />
        <Route path="/projects" component={ProjectIndex} />
        <Route path="/planning" component={Planning} />
        <Route path="/management" component={Management} />
        <Route path="/business" component={Business} />
        <Route path="/development" component={Development} />
        <Route path="/marketing" component={Marketing} />
        <Route path="/oversea" component={Oversea} />
        <Route path="/reports" component={Reports} />
        <Route path="/settings" component={Settings} />
        <Route path="/test" component={Test} />
      </Switch>
    </>
  );
};
export default AppContent;
