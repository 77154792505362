import React, { useState, useEffect } from 'react';
import Util from '../../../helpers/Util';
import { Order, Contract } from '../../../models/Order';
import Spinner from '../../../components/general/Spinner';
import OrderSummaryPanel from '../orders/OrderSummaryPanel';
import OrderService from '../../../services/OrderService';
import { Form, Button, Input } from 'antd';
import { FormComponentProps } from 'antd/lib/form';
import ContractEditor from './ContractEditor';
import _ from 'lodash';

interface Props extends FormComponentProps {
  id?: number;
  onSave?: () => void; // 내부에서 DB 저장 완료후 호출 (props.id 와 함께 사용)

  contract?: Contract;
  onClickSave?: (values: any) => void; // 해당 prop이 있을 경우 DB저장하지 않고 values 외부에 전달 (props.data와 함께 사용)

  restrictEditing?: boolean;
  fetchDelayed?: boolean;
}

const ContractEdit: React.FC<Props> = props => {
  const { id, fetchDelayed = false } = props;
  const [loading, setLoading] = useState(true);
  const [order, setOrder] = useState<Order>(new Order());
  const [contract, setContract] = useState<Contract>(new Contract());
  const { getFieldDecorator } = props.form;

  console.count('ContractEdit');

  useEffect(() => {
    const fetchDetail = async () => {
      setLoading(true);
      try {
        if (id) {
          const result = await OrderService.fetchContractById(id);
          const contract = Contract.fromJson(result.contract);
          setContract(contract);
          const result2 = await OrderService.fetchItem(contract.order_id);
          setOrder(Order.fromJson(result2.order));
        } else if (props.contract) {
          console.log('ContractEdit props.contract: ', props.contract);
          setContract(props.contract);
          const result2 = await OrderService.fetchItem(props.contract.order_id);
          setOrder(Order.fromJson(result2.order));
        }
      } catch (error) {
        Util.showError(error);
      } finally {
        setLoading(false);
      }
    };

    if (fetchDelayed) {
      setTimeout(() => {
        fetchDetail();
      }, 400);
    } else {
      fetchDetail();
    }
  }, [id, props.contract, fetchDelayed]);

  const onClickSave = () => {
    props.form.validateFields((err, values) => {
      if (err) return;
      updateItem(values);
    });
  };

  const [submitting, setSubmitting] = useState(false);
  const updateItem = async (values: any) => {
    let submitData: any = _.pick(values, [
      'order_id',
      'out',
      'contract_date',
      'autocalc',
      'tax_rate',
      'price',
      'tax_price',
      'notax_price',
      'vat',
      'total_price',
      'note'
    ]);

    // submitData.items = JSON.parse(values.all_items);
    // console.log(submitData.items);

    const contractItems = JSON.parse(values.all_items);
    submitData.items = contractItems.map((el: any) => _.omit(el, ['pay_date']));

    if (props.onClickSave) {
      // NOTE: id, company 정보등 포함시키기 위해 `...contract` 사용
      props.onClickSave({ ...contract, ...submitData });
      return;
    }

    if (id) {
      try {
        setSubmitting(true);
        await OrderService.updateContract(id, submitData);
        Util.showSuccess('업데이트 되었습니다.');
        setSubmitting(false);

        if (props.onSave) {
          props.onSave();
        }
      } catch (err) {
        setSubmitting(false);
        Util.showError(err);
      }
    }
  };

  if (loading) {
    return <Spinner />;
  }

  let company = contract.company_name;
  if (contract.company_category && contract.company_category !== '') {
    company = contract.company_category + ' - ' + company;
  }

  return (
    <>
      <h2>
        {contract.out ? '외주계약등록' : '수주계약등록'} - {order.name}
      </h2>

      <OrderSummaryPanel order={order} />

      <h3>{company}</h3>

      {/* <ContractEditor
        form={props.form}
        order={order}
        contract={contract}
        restrictEditing={props.restrictEditing}
      /> */}

      <ContractEditor
        form={props.form}
        order={order}
        contract={contract}
        restrictEditing={false}
      />

      {getFieldDecorator('out', { initialValue: contract.out })(
        <Input type="hidden" />
      )}
      {getFieldDecorator('order_id', { initialValue: order.id })(
        <Input type="hidden" />
      )}

      <div style={{ textAlign: 'center', marginTop: '10px' }}>
        <Button type="primary" onClick={onClickSave} disabled={submitting}>
          저장
        </Button>
      </div>
    </>
  );
};

export default Form.create<Props>()(ContractEdit);
