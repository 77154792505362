import React from 'react';
import { Route, Switch } from 'react-router-dom';
import { Layout, Row, Col, PageHeader } from 'antd';
import Header from './Header';
import Sidebar from './Sidebar';
import ProcessUpdate from './ProcessUpdate';
import { HLContent, HLContentRight } from '../../components/layout/Index';
import ArchiveGroupBrowser from '../../components/general/ArchiveGroupBrowser';

const Index = () => {
  return (
    <>
      <Sidebar />
      <Layout id="wrapper" style={{ marginLeft: 200 }}>
        <Layout.Content>
          <Header />
          <Switch>
            <Route path="/test" exact>
              <HLContent>
                <PageHeader title="Title" subTitle="Subtitle" />
              </HLContent>
            </Route>

            <Route path="/test/process-update" exact>
              <HLContent>
                <ProcessUpdate />
              </HLContent>
            </Route>

            <Route path="/test/archive">
              <HLContent>
                <ArchiveGroupBrowser />
              </HLContent>
            </Route>

            <Route path="/test/test1">
              <HLContent>
                <h2>Test1 - DefaultLayout</h2>
              </HLContent>
            </Route>

            <Route path="/test/test2">
              <Row gutter={0}>
                <Col className="gutter-row" span={18}>
                  <HLContent>
                    <h2>Test2 - DefaultLayout</h2>
                  </HLContent>
                </Col>
                <Col className="gutter-row" span={6}>
                  <HLContentRight>
                    <h3>Test2 - Side</h3>
                    <div
                      style={{
                        width: '100px',
                        height: '2000px',
                        backgroundColor: 'yellow'
                      }}
                    >
                      box
                    </div>
                  </HLContentRight>
                </Col>
              </Row>
            </Route>
          </Switch>
        </Layout.Content>
      </Layout>
    </>
  );
};

export default Index;
