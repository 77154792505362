import React, { useState, useEffect } from 'react';
import { inject, observer } from 'mobx-react';
import { Input, Button, Form } from 'antd';
import { Company, CompanyType } from '../../../models/Compnay';
import { FormComponentProps } from 'antd/lib/form';
import TextArea from 'antd/lib/input/TextArea';
import Util from '../../../helpers/Util';
import CompanyService from '../../../services/CompanyService';
import FCAddressInput from '../../../components/formcomps/FCAddressInput';
import { AuthStore } from '../../../stores/authStore';

interface Props extends FormComponentProps {
  authStore?: AuthStore;
  id?: number;
  type?: CompanyType;
  onSuccess?: () => void;
}

const CompanyEdit: React.FC<Props> = props => {
  const authStore = props.authStore!;
  const { type = CompanyType.buyer } = props;
  const { getFieldDecorator, setFieldsValue } = props.form;

  const itemId = Number(props.id);
  const [, setLoading] = useState(true);
  const [address, setAddress] = useState('');

  useEffect(() => {
    const fetchItem = async () => {
      setLoading(true);
      try {
        const result = await CompanyService.fetchItem(itemId);
        const company = Company.fromJson(result.company);
        setFieldsValue(company.fieldsValue());
        setAddress(company.address);
      } catch (error) {
        Util.showError(error);
      } finally {
        setLoading(false);
      }
    };

    if (itemId > 0) {
      fetchItem();
    }
  }, [itemId, setFieldsValue]);

  const [allowEditing, setAllowEditing] = useState(false);
  useEffect(() => {
    // TODO: 임시 권한설정
    setAllowEditing(
      (authStore.user.rootDept && authStore.user.rootDept.id === 2) || // 경영관리부문 소속이거나
      authStore.user.id === 1 || // admin 이거나
        type === CompanyType.buyer // 발주처인 경우
    );
  }, [type, authStore.user]);

  const [submitting, setSubmitting] = useState(false);

  const onSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    props.form.validateFields((err, values) => {
      if (err) return;
      processItem(values);
    });
  };

  const processItem = async (values: any) => {
    try {
      setSubmitting(true);
      itemId === 0
        ? await CompanyService.create(values)
        : await CompanyService.update(itemId, values);
      Util.showSuccess('저장되었습니다.');

      if (props.onSuccess) props.onSuccess();
    } catch (err) {
      Util.showError(err);
      setSubmitting(false);
    }
  };

  return (
    <>
      <h2>
        {Company.typeName(type)} {itemId === 0 ? '등록' : '수정'}
      </h2>

      <Form
        className="hl-form"
        labelCol={{ span: 4 }}
        wrapperCol={{ span: 18 }}
        onSubmit={onSubmit}
      >
        {getFieldDecorator('type', { initialValue: type })(
          <Input type="hidden" />
        )}

        <Form.Item label="상호명">
          {getFieldDecorator('name', {
            rules: [
              {
                required: true,
                message: '상호명을 입력해주세요.'
              }
            ]
          })(<Input autoFocus />)}
        </Form.Item>

        <Form.Item label="대표자">
          {getFieldDecorator('ceo')(<Input style={{ width: '150px' }} />)}
        </Form.Item>

        <Form.Item label="사업자번호">
          {getFieldDecorator('business_no')(<Input style={{ width: '40%' }} />)}
        </Form.Item>

        <Form.Item label="업종">
          {getFieldDecorator('business_category')(
            <Input style={{ width: '40%' }} />
          )}
        </Form.Item>

        <Form.Item label="업태">
          {getFieldDecorator('business_type')(
            <Input style={{ width: '40%' }} />
          )}
        </Form.Item>

        <FCAddressInput form={props.form} address={address} />

        <Form.Item label="전화">
          {getFieldDecorator('phone')(<Input style={{ width: '40%' }} />)}
        </Form.Item>

        <Form.Item label="팩스">
          {getFieldDecorator('fax')(<Input style={{ width: '40%' }} />)}
        </Form.Item>

        <Form.Item label="이메일">
          {getFieldDecorator('email')(<Input style={{ width: '40%' }} />)}
        </Form.Item>

        <Form.Item label="홈페이지">
          {getFieldDecorator('homepage')(<Input style={{ width: '40%' }} />)}
        </Form.Item>

        <Form.Item label="비고">
          {getFieldDecorator('note')(<TextArea rows={4} />)}
        </Form.Item>

        <Form.Item wrapperCol={{ span: 12, offset: 4 }}>
          <Button
            type="primary"
            htmlType="submit"
            disabled={submitting || !allowEditing}
          >
            저 장
          </Button>
        </Form.Item>
      </Form>
    </>
  );
};

const _CompanyEdit = Form.create<Props>()(CompanyEdit);
export default inject('authStore')(observer(_CompanyEdit));
