import React, { useState, useEffect, useRef } from 'react';
import { List, Icon, Card } from 'antd';
import { File } from '../../models/File';
import FileService from '../../services/FileService';
import Util from '../../helpers/Util';
import AButton from './AButton';
import ImageGalleryModal from './ImageGalleryModal';
import filesize from 'filesize';
import _ from 'lodash';

interface Props {
  items: File[];
}

const AttachedFileList: React.FC<Props> = props => {
  const [files, setFiles] = useState<File[]>([]);
  const [images, setImages] = useState<File[]>([]);
  const [imageViewerVisible, setImageViewerVisible] = useState(false);
  const startIndexRef = useRef(0);

  useEffect(() => {
    const files = props.items.filter(e => !e.isImage);
    const images = props.items.filter(e => e.isImage);
    setFiles(_.sortBy(files, ['original_name']));
    setImages(_.sortBy(images, ['original_name']));
  }, [props.items]);

  const onClickItem = (item: File, index: number) => {
    if (item.isImage) {
      startIndexRef.current = index;
      setImageViewerVisible(true);
    }
  };

  const onClickDownload = async (item: File) => {
    try {
      await FileService.downloadFile(item.id, item.original_name);
    } catch (error) {
      Util.showError(error);
    }
  };

  const gridStyle: any = {
    width: '110px',
    textAlign: 'center',
    padding: '7px',
    margin: '4px'
  };

  return (
    <>
      {files.length > 0 && (
        <List
          size="small"
          bordered={false}
          split={false}
          dataSource={files}
          renderItem={(item: File) => (
            <List.Item>
              <AButton onClick={e => onClickDownload(item)}>
                <Icon type="download" />
              </AButton>{' '}
              {item.isPreviewable ? (
                <AButton onClick={e => FileService.previewFile(item)}>
                  {item.original_name}
                </AButton>
              ) : (
                <>{item.original_name}</>
              )}{' '}
              ({filesize(item.size, { round: 0 })})
            </List.Item>
          )}
        />
      )}

      {/* {images.length > 0 && (
        <Card bordered={false}>
          {images.map(item => (
            <Card.Grid key={item.id} style={gridStyle}>
              <AButton onClick={e => onClickItem(item)}>
                <img
                  src={item.thumbnailFullPath}
                  width="100%"
                  alt={item.original_name}
                />
              </AButton>
              <AButton onClick={e => onClickDownload(item)}>
                <Icon type="download" /> Download
              </AButton>
            </Card.Grid>
          ))}
        </Card>
      )} */}

      {images.length > 0 && (
        <Card bordered={false}>
          {images.map((item, index) => (
            <Card.Grid key={item.id} style={gridStyle}>
              <AButton onClick={e => onClickItem(item, index)}>
                <div className="absolute-center-parent">
                  <img
                    className="absolute-center-child"
                    src={item.thumbnailFullPath}
                    alt={item.original_name}
                  />
                </div>
              </AButton>
              <AButton onClick={e => onClickDownload(item)}>
                <Icon type="download" /> Download
              </AButton>
            </Card.Grid>
          ))}
        </Card>
      )}

      <ImageGalleryModal
        items={images}
        visible={imageViewerVisible}
        startIndex={startIndexRef.current}
        onCancel={() => setImageViewerVisible(false)}
      />
    </>
  );
};

export default AttachedFileList;
