import React, { useState, useEffect } from 'react';
import { Form, Input, Button, Select, Icon, Col, Row, Table } from 'antd';
import { FormComponentProps } from 'antd/lib/form';
import { TripAccount, TripReport } from '../../../models/Trip';
import FCTextInput from '../../../components/formcomps/FCTextInput';
import FREditor from '../../../components/general/FREditor';
import LinkButton from '../../../components/general/LinkButton';
import FCNumberInput from '../../../components/formcomps/FCNumberInput';
import _ from 'lodash';
import { SelectValue } from 'antd/lib/select';
import FCFileInput from '../../../components/formcomps/FCFileInput';

interface Props extends FormComponentProps {
  id?: number;
  data?: any;
  approvePriceEditable?: boolean;
}

const TRIP_TYPES = ['일비', '교통비', '식비', '숙박비', '기타'];

const TripReportEditor: React.FC<Props> = props => {
  const { approvePriceEditable = false } = props;
  const { getFieldDecorator, setFieldsValue } = props.form;
  const [item, setItem] = useState<TripReport>(new TripReport());
  const [items, setItems] = useState<TripAccount[]>([]);
  const [totalItem] = useState<TripAccount>(new TripAccount(0));
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const fetchDetail = async (id: number) => {
      // setLoading(true);
      // try {
      //   const data = await VacationService.fetchItem(id);
      //   configDetail(data.vacation);
      // } catch (error) {
      //   Util.showError(error);
      // } finally {
      //   setLoading(false);
      // }
    };

    const configDetail = (data: any) => {
      const report = TripReport.fromJson(data);
      setItem(report);
      setItems(report.items);
    };

    if (props.data) {
      configDetail(props.data);
      setLoading(false);
    } else if (props.id) {
      fetchDetail(props.id);
    } else {
      // 기본 예산항목들 설정
      const items = TRIP_TYPES.map((e, index) => {
        let item = new TripAccount(index + 1);
        item.type = e;
        return item;
      });
      setItems(items);
      setLoading(false);
    }
  }, [props.data, props.id]);

  let columns = [
    {
      dataIndex: 'index',
      width: 50,
      render: (text: string, record: TripAccount, index: number) => {
        return (
          <div style={{ width: '20px', textAlign: 'right' }}>
            {record.id === 0 ? '' : index + 1 + '.'}
          </div>
        );
      }
    },
    {
      title: '구분',
      dataIndex: 'type',
      width: 120,
      render: (text: string, record: TripAccount) => {
        if (record.id === 0) {
          return <div style={{ textAlign: 'center' }}>합 계</div>;
        }
        return (
          <Form.Item>
            {getFieldDecorator(`type_${record.id}`, {
              initialValue: record.type
            })(
              <Select
                style={{ width: '110px' }}
                onChange={value => onChangeItemType(record, value)}
              >
                {TRIP_TYPES.map(e => (
                  <Select.Option value={e} key={e}>
                    {e}
                  </Select.Option>
                ))}
              </Select>
            )}
          </Form.Item>
        );
      }
    },
    {
      title: '청구금액',
      dataIndex: 'request_price',
      width: 110,
      render: (text: string, record: TripAccount) => {
        return (
          <Form.Item>
            <FCNumberInput
              form={props.form}
              name={`request_price_${record.id}`}
              disabled={record.id === 0}
              options={{ initialValue: record.request_price }}
              onEnter={value => onEnterRequestPrice(record, value)}
            />
          </Form.Item>
        );
      }
    },
    {
      title: '승인금액',
      dataIndex: 'approve_price',
      width: 110,
      render: (text: string, record: TripAccount) => {
        return (
          <Form.Item>
            <FCNumberInput
              form={props.form}
              name={`approve_price_${record.id}`}
              disabled={!approvePriceEditable}
              options={{ initialValue: record.approve_price }}
              onEnter={value => onEnterApprovePrice(record, value)}
            />
          </Form.Item>
        );
      }
    },
    {
      title: '산출근거',
      dataIndex: 'request_base',
      width: '70%',
      render: (text: string, record: TripAccount) => {
        if (record.id === 0) {
          return <></>;
        }
        return (
          <>
            <Form.Item>
              <FCTextInput
                form={props.form}
                name={`request_base_${record.id}`}
                options={{ initialValue: record.request_base }}
                onEnter={value => onEnterItemBase(record, value)}
              />
            </Form.Item>
            <div style={{ width: '300px' }}>
              <FCFileInput
                form={props.form}
                name={`file_ids_${record.id}`}
                items={record.files}
                title="증빙 첨부"
              />
            </div>
          </>
        );
      }
    },
    {
      width: 40,
      render: (text: string, record: TripAccount) => {
        if (record.id === 0) {
          return <></>;
        }
        return (
          <LinkButton onClick={e => onClickDeleteItem(record.id)}>
            <Icon type="close" />
          </LinkButton>
        );
      }
    }
  ];

  const onClickAddItem = () => {
    let newItems = items.concat(new TripAccount());
    setItems(newItems);
  };

  const onClickDeleteItem = (id: number) => {
    let newItems = items.filter((item: any) => item.id !== id);
    setItems(newItems);
  };

  const onChangeItemType = (item: TripAccount, value: SelectValue) => {
    const updatedItems = items.map(el => {
      return el.id === item.id ? ({ ...item, type: value } as TripAccount) : el;
    });

    setItems(updatedItems);
  };

  const onEnterRequestPrice = (
    item: TripAccount,
    value: number | string | undefined
  ) => {
    if (value === undefined) return;

    const updatedItems = items.map(el => {
      return el.id === item.id
        ? ({
            ...item,
            request_price: value,
            approve_price: approvePriceEditable ? item.approve_price : value
          } as TripAccount)
        : el;
    });

    setItems(updatedItems);
  };

  const onEnterApprovePrice = (
    item: TripAccount,
    value: number | string | undefined
  ) => {
    if (value === undefined) return;

    const updatedItems = items.map(el => {
      return el.id === item.id
        ? ({
            ...item,
            approve_price: value
          } as TripAccount)
        : el;
    });

    setItems(updatedItems);
  };

  useEffect(() => {
    const sum1 = _.sumBy(items, 'request_price');
    const sum2 = _.sumBy(items, 'approve_price');
    setFieldsValue({ request_price_0: sum1, approve_price_0: sum2, items });
  }, [items, setFieldsValue]);

  const onEnterItemBase = (
    item: TripAccount,
    value: number | string | undefined
  ) => {
    if (value === undefined) return;

    const updatedItems = items.map(el => {
      return el.id === item.id
        ? ({ ...item, request_base: value } as TripAccount)
        : el;
    });

    setItems(updatedItems);
  };

  const formItemLayout = {
    labelCol: { span: 3 },
    wrapperCol: { span: 20 }
  };

  return (
    <>
      <Form {...formItemLayout}>
        {getFieldDecorator('items', { initialValue: items })(
          <Input type="hidden" />
        )}
        <Row type="flex" justify="space-between">
          <Col>
            <h3>출장비 정산</h3>
          </Col>
          <Col>
            <Button onClick={onClickAddItem} style={{ marginBottom: 10 }}>
              <Icon type="plus" />열 추가
            </Button>
          </Col>
        </Row>

        <div className="hl-form" style={{ marginBottom: '15px' }}>
          <Table
            columns={columns}
            dataSource={[...items, totalItem]}
            pagination={false}
            rowKey="id"
            rowClassName={record => {
              return record.id === 0 ? '' : 'hl-vertical-top';
            }}
            loading={loading}
            bordered={false}
            size="middle"
          />
        </div>

        {approvePriceEditable && (
          <>
            <h3>전표번호</h3>
            <div className="hl-form" style={{ marginBottom: '15px' }}>
              <Form.Item label="전표번호">
                {getFieldDecorator('ticket_no', {
                  initialValue: item.ticket_no
                })(<Input style={{ width: '300px' }} />)}
              </Form.Item>
            </div>
          </>
        )}

        {!loading && (
          <>
            <h3>결과보고</h3>
            <FREditor name="report" value={item.report} form={props.form} />
          </>
        )}
      </Form>
    </>
  );
};

export default TripReportEditor;
