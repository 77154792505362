import React, { useState, useEffect } from 'react';
import { Tag } from 'antd';
import Util from '../../helpers/Util';
import VacationService from '../../services/VacationService';
import { Vacation } from '../../models/Vacation';
import Spinner from '../../components/general/Spinner';
import {
  HDescriptions,
  HDRow,
  HDItem,
  HDRowItem
} from '../../components/general/HDescriptions';

interface Props {
  id?: number;
  data?: any;
}

const VacationApplyViewer: React.FC<Props> = props => {
  const [loading, setLoading] = useState(false);
  const [item, setItem] = useState<Vacation>(new Vacation());

  useEffect(() => {
    const fetchDetail = async (id: number) => {
      setLoading(true);
      try {
        const data = await VacationService.fetchItem(id);
        configDetail(data.vacation);

        // const data2 = await VacationService.fetchRecords(1, 1, {
        //   keyword: String(vacation.user.id),
        //   year: vacation.beginDate.year()
        // });
        // const records = data2.items;
        // if (records.length > 0) {
        //   setGiven(records[0].given);
        // } else {
        //   setGiven(0);
        // }
      } catch (error) {
        Util.showError(error);
      } finally {
        setLoading(false);
      }
    };

    const configDetail = (data: any) => {
      setItem(Vacation.fromJson(data));
    };

    if (props.data) {
      configDetail(props.data);
    } else if (props.id) {
      fetchDetail(props.id);
    }
  }, [props.id, props.data]);

  if (loading) {
    return <Spinner />;
  }

  return (
    <HDescriptions>
      <HDRowItem label="휴가대상자" colSpan={3}>
        {item.user.nameWithDeptRank}
      </HDRowItem>
      <HDRowItem label="구분" colSpan={3}>
        {item.type}
      </HDRowItem>
      <HDRowItem label="신청기간" colSpan={3}>
        {item.durationSummary}
      </HDRowItem>
      <HDRowItem label="사용일" colSpan={3}>
        {item.dates.map(dateString => (
          <Tag key={dateString} closable={false}>
            {dateString}
          </Tag>
        ))}
      </HDRowItem>
      <HDRow>
        <HDItem label="사용일수">{item.used}일</HDItem>
        <HDItem label="가능일수">{item.given}일</HDItem>
      </HDRow>
      <HDRowItem label="비고" colSpan={3}>
        {item.note}
      </HDRowItem>
    </HDescriptions>
  );
};

export default VacationApplyViewer;
