import React, { useState, useEffect } from 'react';
import { inject, observer } from 'mobx-react';
import { AuthStore } from './stores/authStore';
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';
import Index from './pages/Index';
import Login from './pages/login/Login';
import ProtectedRoute from './components/ProtectedRoute';

interface Props {
  authStore?: AuthStore;
}

const App: React.FC<Props> = props => {
  const [loading, setLoading] = useState(true);
  const authStore = props.authStore!;

  console.count('App');

  useEffect(() => {
    const fetchData = async () => {
      try {
        console.log('verfiyUser');
        await authStore.verifyUser();
        setLoading(false);
      } catch (err) {
        console.error(err);
        setLoading(false);
      }
    };

    if (authStore.token) {
      authStore.syncAxioHeaderToken();
      fetchData();
    } else {
      // token이 없을 경우는 user정보 페치할 필요 자체가 없음
      setLoading(false);
    }
  }, [authStore]);

  return (
    <>
      {loading ? (
        <span>loading...</span>
      ) : (
        <Router>
          <Switch>
            <Route path="/login" component={Login} />
            <ProtectedRoute path="/" component={Index} />
          </Switch>
        </Router>
      )}
    </>
  );
};

export default inject('authStore')(observer(App));

/*
import React, { useState, useEffect, useReducer } from 'react';
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';
import Index from './pages/Index';
import Login from './pages/Login';
import axios from 'axios';
import ProtectedRoute from './components/ProtectedRoute';
import { API_ROOT } from './config';
import Test from './pages/Test';
import { authReducer, initialAuthState, AuthHelper } from './state/auth';
import { orgReducer, initialOrgState } from './state/org';
import { AppState, AppContext, ActionTypes, actions } from './state/index';

interface Props {}

const App: React.FC<Props> = props => {
  const [loading, setLoading] = useState(true);

  console.log('App');

  const [authState, authDispatch] = useReducer(authReducer, initialAuthState);
  const [orgState, orgDispatch] = useReducer(orgReducer, initialOrgState);

  // global state
  const appState: AppState = {
    authState,
    orgState
  };

  // global dispatch
  const appDispatch = (action: ActionTypes) => {
    [authDispatch, orgDispatch].forEach(fn => {
      (fn as React.Dispatch<ActionTypes>)(action);
    });
  };

  console.log('authState: ', authState);
  console.log('orgState: ', orgState);

  useEffect(() => {
    console.log('App.useEffect');
    const fetchData = async () => {
      try {
        const { data: result } = await axios(API_ROOT + '/users/me');
        actions.setUser(result.user);
        setLoading(false);
      } catch (err) {
        console.error(err);
        setLoading(false);
      }
    };

    if (authState.token) {
      AuthHelper.syncAxioHeaderToken(authState.token);
      fetchData();
    } else {
      // token이 없을 경우는 user정보 페치할 필요 자체가 없음
      setLoading(false);
    }
  }, []);

  const handleLoginClick = () => {
    appDispatch(actions.login('email', 'password'));
  };

  const handleLogoutClick = () => {
    appDispatch(actions.logout());
  };

  return (
    <>
      {loading ? (
        <span>loading...</span>
      ) : (
        <Router>
          <Switch>
            <AppContext.Provider value={{ appState, appDispatch }}>
              <Route path="/login" component={Login} />
              <ProtectedRoute path="/" component={Index} />
            </AppContext.Provider>
          </Switch>
        </Router>
      )}
    </>
  );
};

export default App;
*/
