import React from 'react';
import { Link, withRouter, RouteComponentProps } from 'react-router-dom';
import { inject, observer } from 'mobx-react';
import { Layout, Menu, Icon } from 'antd';
import { MenuItem } from '../models/Common';
import { AuthStore } from '../stores/authStore';
import _ from 'lodash';
const { SubMenu } = Menu;
const { Sider } = Layout;

interface Props extends RouteComponentProps {
  menuItems: MenuItem[];
  defaultOpenKeys?: string[];
  defaultSelectedKeys?: string[];
  authStore?: AuthStore;
}

const Sidebar: React.FC<Props> = props => {
  const { menuItems, defaultOpenKeys, defaultSelectedKeys } = props;
  const authStore = props.authStore!;

  const renderMenuItem = (item: MenuItem) => {
    // 접근 허용 부서 확인
    // TODO: 리팩토링 (실제 router에서도 차단되게 / 현재 router에 하드코딩)
    if (item.allow_dept_ids && item.allow_dept_ids.length > 0) {
      if (authStore.user.dept === undefined) return;
      const userDeptId = authStore.user.dept.id;
      const found = _.find(item.allow_dept_ids, e => e === userDeptId);
      if (!found) return;
    }

    // 접근 허용 유저 확인
    // TODO: 리팩토링 (실제 router에서도 차단되게 / 현재 router에 하드코딩)
    if (item.allow_user_ids && item.allow_user_ids.length > 0) {
      const found = _.find(item.allow_user_ids, e => e === authStore.user.id);
      if (!found) return;
    }

    if (item.children) {
      return (
        <SubMenu
          key={item.id}
          title={
            <span>
              {item.icon && <Icon type={item.icon} />}
              <span>{item.title}</span>
            </span>
          }
        >
          {item.children.map(menu => renderMenuItem(menu))}
        </SubMenu>
      );
    } else {
      const paths = props.location.pathname.split('/');
      const menu = paths[1];
      const submenu = paths.length >= 3 ? paths[2] : undefined;
      const param1 = paths.length >= 4 ? paths[3] : undefined;

      let linkTo = item.link;
      if (linkTo === undefined) {
        // item.link가 정의되지 않은 경우 자료실로 간주
        if (submenu && submenu === 'orders' && param1) {
          // submenu가 orders이고 orderId값이 있을 경우 주문별 자료실로 링크
          if (isNaN(Number(item.id))) {
            linkTo = `/${menu}/orders/${param1}/${item.id}`;
          } else {
            linkTo = `/${menu}/orders/${param1}/archive?id=${item.id}`;
          }
        } else {
          // 그 외의 경우 일반 자료실로 링크
          const fullpath = submenu ? menu + '/' + submenu : menu;
          linkTo = `/${fullpath}/archive?id=${item.id}`;
        }
      }

      return (
        <Menu.Item key={item.id}>
          <Link to={linkTo || `/${menu}`}>
            {item.icon && <Icon type={item.icon} />}
            <span>{item.title}</span>
          </Link>
        </Menu.Item>
      );
    }
  };

  return (
    <Sider
      id="sider"
      width={200}
      style={{
        background: '#fff',
        overflowX: 'hidden',
        overflowY: 'auto',
        position: 'fixed',
        left: 0
      }}
    >
      {props.children}
      <Menu
        mode="inline"
        defaultOpenKeys={defaultOpenKeys}
        defaultSelectedKeys={defaultSelectedKeys}
        style={{ borderRight: 0 }}
      >
        {menuItems && menuItems.map(menu => renderMenuItem(menu))}
      </Menu>
    </Sider>
  );
};

export default inject('authStore')(observer(withRouter(Sidebar)));
