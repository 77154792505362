import React, { useState } from 'react';
import { Table, Button, Row, Col, Icon, Popconfirm } from 'antd';
import { observer, inject } from 'mobx-react';
import { DataStore } from '../../stores/dataStore';
import StringEditor from './StringEditor';
import Util from '../../helpers/Util';
import LinkButton from './LinkButton';

interface Props {
  dataStore?: DataStore;
}

const RankList: React.FC<Props> = props => {
  const [editingItem, setEditingItem] = useState<string | undefined>(undefined);
  const [nameEditorVisible, setNameEditorVisible] = useState(false);
  const [submitting, setSubmitting] = useState(false);
  const dataStore = props.dataStore!;
  const items = dataStore.ranks.slice().map(e => ({ id: e, name: e }));

  const columns = [
    {
      title: '이름',
      dataIndex: 'name'
    },
    {
      title: '순서',
      width: '100px',
      render: (text: string, record: any) => {
        return (
          <>
            <Button
              size="small"
              onClick={e => processOperation(record.name, 'up')}
              disabled={submitting}
            >
              <Icon type="up" />
            </Button>{' '}
            <Button
              size="small"
              onClick={e => processOperation(record.name, 'down')}
              disabled={submitting}
            >
              <Icon type="down" />
            </Button>
          </>
        );
      }
    },
    {
      width: '90px',
      render: (text: string, record: any) => {
        return (
          <>
            <LinkButton onClick={e => handleUpdateItemButton(record.name)}>
              수정
            </LinkButton>{' '}
            |{' '}
            <Popconfirm
              title="정말 삭제하시겠습니까?"
              onConfirm={e => handleDeleteItemButton(record.name)}
            >
              <LinkButton>삭제</LinkButton>
            </Popconfirm>
          </>
        );
      }
    }
  ];

  const handleCreateButtonClick = async () => {
    setNameEditorVisible(true);
  };

  const handleUpdateItemButton = async (item: string) => {
    setEditingItem(item);
    setNameEditorVisible(true);
  };

  const onCancelNameEditor = () => {
    setNameEditorVisible(false);
    setEditingItem(undefined);
  };

  const onSubmitNameEditor = async (name: string) => {
    try {
      setSubmitting(true);
      editingItem
        ? await dataStore.updateRank(editingItem, name)
        : await dataStore.createRank(name);
      setNameEditorVisible(false);
      setEditingItem(undefined);
    } catch (err) {
      Util.showError(err);
    } finally {
      setSubmitting(false);
    }
  };

  const handleDeleteItemButton = (item: string) => {
    processOperation(item, 'del');
  };

  const processOperation = async (item: string, op: 'up' | 'down' | 'del') => {
    try {
      setSubmitting(true);
      if (op === 'up') await dataStore.moveRankUp(item);
      if (op === 'down') await dataStore.moveRankDown(item);
      if (op === 'del') await dataStore.deleteRank(item);
    } catch (err) {
      Util.showError(err);
    } finally {
      setSubmitting(false);
    }
  };

  return (
    <>
      <h2>직위관리</h2>

      <Row type="flex" justify="space-between" style={{ marginBottom: 10 }}>
        <Col />
        <Col>
          <Button onClick={handleCreateButtonClick} disabled={submitting}>
            <Icon type="plus" />
            직위 등록
          </Button>
        </Col>
      </Row>

      <Table
        columns={columns}
        dataSource={items}
        pagination={false}
        rowKey="id"
        loading={submitting}
        bordered={true}
        size="middle"
      />

      <StringEditor
        name="직위"
        visible={nameEditorVisible}
        value={editingItem}
        submitting={submitting}
        onSubmit={onSubmitNameEditor}
        onCancel={onCancelNameEditor}
      />
    </>
  );
};

export default inject('dataStore')(observer(RankList));
