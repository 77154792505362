import React, { useState, useEffect } from 'react';
import { Table, Row, Col, Input, Button, InputNumber, Drawer } from 'antd';
import { RouteComponentProps, withRouter } from 'react-router';
import { Order, OrderState } from '../../../models/Order';
import OrderService, {
  OrderSearchOption
} from '../../../services/OrderService';
import Form, { FormComponentProps } from 'antd/lib/form';
import Util from '../../../helpers/Util';
import { PaginationConfig } from 'antd/lib/table';
import AppService from '../../../services/AppService';
import EADocDetail from '../../eas/EADocDetail';
import LinkButton from '../../../components/general/LinkButton';
import FCStringCodeSelect from '../../../components/formcomps/FCStringCodeSelect';
import { SelectValue } from 'antd/lib/select';
import FIHQSelect from '../../../components/formcomps/FIHQSelect';

interface Props extends RouteComponentProps, FormComponentProps {
  hq?: string;
  deptId?: number;
  deptIds?: number[];
  hideDeptSelector?: boolean;
}

const _OrderList: React.FC<Props> = props => {
  const { hq = 'projects', deptId } = props;
  const [items, setItems] = useState<Order[]>([]);
  const [searchOption, setSearchOption] = useState<OrderSearchOption>({
    dept_id: deptId,
    state: 'null'
  });
  const [totalCount, setTotalCount] = useState(0);
  const [currentPage, setCurrentPage] = useState(1);
  const [kinds, setKinds] = useState<string[]>([]);
  const [loading, setLoading] = useState(false);
  const { getFieldDecorator } = props.form;

  useEffect(() => {
    const fetchStringCodes = async () => {
      setLoading(true);
      try {
        const result = await AppService.fetchStoreValues(['project_kinds']);
        setKinds(result['project_kinds']);
      } catch (error) {
        Util.showError(error);
      } finally {
        setLoading(false);
      }
    };

    fetchStringCodes();
  }, []);

  const pagination = {
    pageSize: 15,
    current: currentPage,
    total: totalCount,
    showTotal: (total: number, range: [number, number]) =>
      `전체 ${total}건 중 ${range[0]}-${range[1]}`
  };

  useEffect(() => {
    const fetchItems = async () => {
      setLoading(true);
      try {
        const result = await OrderService.fetchItems(
          pagination.pageSize,
          currentPage,
          searchOption
        );
        setTotalCount(result.total_count);
        setItems(Order.fromJsonArray(result.items));
      } catch (error) {
        Util.showError(error);
      } finally {
        setLoading(false);
      }
    };

    fetchItems();
  }, [currentPage, searchOption, pagination.pageSize]);

  const onChangeTable = (pagination: PaginationConfig) => {
    setCurrentPage(pagination.current || 1);
  };

  const onSubmitSearchForm = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    props.form.validateFields((err, values) => {
      if (err) return;
      setSearchOption({ ...values, state: 'null' });
      setCurrentPage(1);
    });
  };

  const [detailVisible, setDetailVisible] = useState(false);
  const [refId, setRefId] = useState(0);
  const onClickItem = (item: Order) => {
    setRefId(item.id);
    setDetailVisible(true);
  };

  const onCloseDetail = () => {
    setDetailVisible(false);
  };

  const onChangeItemState = async (item: Order, value: SelectValue) => {
    try {
      await OrderService.update(item.id, { state: value });
      Util.showSuccess('저장되었습니다.');
    } catch (err) {
      Util.showError(err);
    }
  };

  const columns = [
    {
      title: 'No',
      dataIndex: 'id',
      fixed: 'left' as 'left',
      align: 'right' as 'right',
      width: 60
    },
    {
      title: '계약건명',
      dataIndex: 'name',
      render: (text: string, record: Order) => (
        <LinkButton onClick={e => onClickItem(record)}>{text}</LinkButton>
      )
    },
    {
      title: '소개자',
      dataIndex: 'client_name'
    },
    {
      title: '발주처',
      dataIndex: 'comp_name'
    },
    {
      title: '팀장',
      dataIndex: 'leaders',
      render: (text: string, record: Order) => {
        return record.leaders.map(e => e.name).join(', ');
      }
    },
    {
      title: '담당',
      dataIndex: 'managers',
      render: (text: string, record: Order) => {
        return record.managers.map(e => e.name).join(', ');
      }
    },
    {
      title: '접수일',
      dataIndex: 'register_date',
      render: (text: string, record: Order) =>
        Util.formatDate(record.register_date)
    },
    {
      title: '등급',
      dataIndex: 'grade'
    },
    {
      title: '계획안예정일',
      dataIndex: 'plan_est_date',
      render: (text: string, record: Order) =>
        Util.formatDate(record.plan_est_date)
    },
    {
      title: '계획안제출일',
      dataIndex: 'plan_date',
      render: (text: string, record: Order) => Util.formatDate(record.plan_date)
    },
    {
      title: '상태',
      dataIndex: 'state',
      render: (text: string, record: Order) => {
        return (
          <FCStringCodeSelect
            form={props.form}
            name={`state_${record.id}`}
            style={{ width: 90 }}
            items={Object.values(OrderState)}
            options={{ initialValue: record.state }}
            onChange={value => onChangeItemState(record, value)}
          />
        );
      }
    }
  ];

  return (
    <>
      <h2>수주심의</h2>

      <Form
        className="hl-form"
        layout="inline"
        style={{ marginBottom: 7 }}
        onSubmit={onSubmitSearchForm}
      >
        <Row type="flex" justify="space-between">
          <Col>
            <Form.Item>
              {getFieldDecorator('keyword')(
                <Input.Search
                  placeholder="검색어"
                  allowClear={true}
                  style={{ width: 150 }}
                />
              )}
            </Form.Item>

            <Form.Item>
              {getFieldDecorator('year')(
                <InputNumber placeholder="년도" style={{ width: 100 }} />
              )}
            </Form.Item>

            <FIHQSelect
              form={props.form}
              options={{ initialValue: deptId }}
              width="180px"
              allowClear
              hide={props.hideDeptSelector}
              deptIds={props.deptIds}
              onChange={value => {
                localStorage.setItem(
                  `${hq}DefaultDeptId`,
                  value ? String(value) : ''
                );
              }}
            />

            <Form.Item>
              <FCStringCodeSelect
                form={props.form}
                name="kind"
                placeholder="종류"
                items={kinds}
                allowClear={true}
                style={{ width: '120px' }}
              />
            </Form.Item>

            <Form.Item>
              <Button type="primary" htmlType="submit">
                검색
              </Button>
            </Form.Item>
          </Col>
        </Row>
      </Form>

      <Table
        columns={columns}
        dataSource={items}
        rowKey="id"
        pagination={pagination}
        onChange={onChangeTable}
        bordered={true}
        loading={loading}
        size="middle"
      />

      <Drawer
        closable={false}
        placement="right"
        width={'85%'}
        visible={detailVisible}
        onClose={onCloseDetail}
        destroyOnClose={true}
      >
        <EADocDetail refTarget="ord" refId={refId} fetchDelayed={true} />
      </Drawer>
    </>
  );
};

const OrderList = Form.create<Props>()(_OrderList);
export default withRouter(OrderList);
