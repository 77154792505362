import React from 'react';
import { Route, Switch, Redirect } from 'react-router-dom';
import UnderConst from '../../components/UnderConst';
import CompanyList from '../projects/companies/CompanyList';
import CollectPlan from './finance/CollectPlan';
import AccountReport from './finance/AccountReport';
import DailyReport from './finance/DailyReport';
import ArchiveGroupBrowser from '../../components/general/ArchiveGroupBrowser';
import OrderLedgerForState from '../projects/orders/OrderLedgerForState';
import EADocListByCat from '../eas/EADocListByCat';
import EADocDrafterWrapper from '../eas/EADocDrafterWrapper';
import CollectStatus from './finance/CollectStatus';

interface Props {}

const Index: React.FC<Props> = props => {
  return (
    <Switch>
      <Route path="/management/finance/uc" component={UnderConst} />
      <Route
        path="/management/finance/archive"
        component={ArchiveGroupBrowser}
      />

      <Route path="/management/finance" exact>
        <Redirect to="/management/finance/daily" />
      </Route>

      <Route path="/management/finance/daily" component={DailyReport} />
      <Route path="/management/finance/collect" component={CollectPlan} />
      <Route path="/management/finance/cstatus" component={CollectStatus} />
      <Route path="/management/finance/account" component={AccountReport} />
      <Route
        path="/management/finance/companies/list"
        component={CompanyList}
      />

      <Route path="/management/finance/ledger">
        <OrderLedgerForState />
      </Route>

      <Route path="/management/finance/eas/list" component={EADocListByCat} />
      <Route
        path="/management/finance/eas/draft"
        component={EADocDrafterWrapper}
      />
    </Switch>
  );
};

export default Index;
