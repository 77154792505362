import React from 'react';
import { Select } from 'antd';
import { GetFieldDecoratorOptions } from 'antd/lib/form/Form';
import { FormComponentProps } from 'antd/lib/form';
import { SelectValue, ModeOption } from 'antd/lib/select';
const { Option } = Select;

interface Props extends FormComponentProps {
  name: string;
  items: string[];
  placeholder?: string;
  style?: any;
  allowClear?: boolean;
  disabled?: boolean;
  mode?: ModeOption;
  options?: GetFieldDecoratorOptions;
  onChange?: (value: SelectValue) => void;
}

const FCStringCodeSelect = (props: Props) => {
  const { getFieldDecorator } = props.form;
  const { allowClear = false, disabled = false, mode = 'default' } = props;

  const onChange = (value: SelectValue) => {
    if (props.onChange) props.onChange(value);
  };

  return (
    <>
      {getFieldDecorator(
        props.name,
        props.options
      )(
        <Select
          mode={mode}
          placeholder={props.placeholder}
          allowClear={allowClear}
          style={props.style}
          onChange={onChange}
          disabled={disabled}
        >
          {props.items.map(item => (
            <Option key={item} value={item}>
              {item}
            </Option>
          ))}
        </Select>
      )}
    </>
  );
};

export default FCStringCodeSelect;
