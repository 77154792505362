import React, { useState, useEffect, useRef } from 'react';
import { Table, Button, Row, Col, Input, Form, Drawer, Checkbox } from 'antd';
import { FormComponentProps } from 'antd/lib/form';
import { PaginationConfig } from 'antd/lib/table';
import Util from '../../../helpers/Util';
import AButton from '../../../components/general/AButton';
import AssetService, {
  AssetSearchOption
} from '../../../services/AssetService';
import { AssetReport } from '../../../models/Asset';
import AssetList from './AssetList';

interface Props extends FormComponentProps {}

const AssetReportList: React.FC<Props> = props => {
  const [loading, setLoading] = useState(false);
  const [refreshTrigger, setRefreshTrigger] = useState(1);
  const [searchOption, setSearchOption] = useState<AssetSearchOption>({
    include_disposal: false
  });
  const [items, setItems] = useState<AssetReport[]>([]);
  const [filteredItems, setFilteredItems] = useState<AssetReport[]>([]);
  const [keyword, setKeyword] = useState('');
  const { getFieldDecorator } = props.form;

  useEffect(() => {
    const fetchItems = async () => {
      setLoading(true);
      try {
        const result = await AssetService.fetchReports(searchOption);
        setItems(AssetReport.fromJsonArray(result.items));
      } catch (error) {
        Util.showError(error);
      } finally {
        setLoading(false);
      }
    };
    fetchItems();
  }, [searchOption, refreshTrigger]);

  useEffect(() => {
    const filtered =
      keyword === ''
        ? items
        : items.filter(
            el =>
              el.category1.includes(keyword) || el.category2.includes(keyword)
          );
    setFilteredItems(filtered);
  }, [items, keyword]);

  const columns = [
    {
      title: '분류',
      dataIndex: 'category',
      render: (text: string, record: AssetReport) => (
        <AButton onClick={e => onClickItem(record)}>
          {record.category1} / {record.category2}
        </AButton>
      )
    },
    {
      title: '수량',
      dataIndex: 'count',
      align: 'right' as 'right',
      render: (text: string, record: AssetReport) =>
        Util.formatNumber(record.count)
    },
    {
      title: '구입금액',
      dataIndex: 'total_price',
      align: 'right' as 'right',
      render: (text: string, record: AssetReport) =>
        Util.formatNumber(record.total_price)
    }
  ];

  const onChangeTable = (pagination: PaginationConfig) => {
    // setCurrentPage(pagination.current || 1);
  };

  const onSubmitSearchForm = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    props.form.validateFields((err, values) => {
      if (err) return;
      setKeyword(values.keyword || '');
    });
  };

  const itemDirtyRef = useRef(false);
  const [category1, setCategory1] = useState('');
  const [category2, setCategory2] = useState('');
  const [detailVisible, setDetailVisible] = useState(false);

  const onClickItem = (item: AssetReport) => {
    setCategory1(item.category1);
    setCategory2(item.category2);
    setDetailVisible(true);
  };

  const onCloseDetail = () => {
    setDetailVisible(false);
    if (itemDirtyRef.current) {
      itemDirtyRef.current = false;
      setRefreshTrigger(v => v + 1);
    }
  };

  const onChangeDetail = () => {
    itemDirtyRef.current = true;
  };

  const onChangeDispose = (checked: boolean) => {
    setSearchOption({ ...searchOption, include_disposal: checked });
  };

  return (
    <>
      <h2>자산보고서</h2>

      <Form
        className="hl-form"
        layout="inline"
        style={{ marginBottom: 7 }}
        onSubmit={onSubmitSearchForm}
      >
        <Row type="flex" justify="space-between">
          <Col>
            <Form.Item>
              {getFieldDecorator('keyword')(
                <Input.Search
                  placeholder="검색어"
                  allowClear={true}
                  style={{ width: 150 }}
                />
              )}
            </Form.Item>

            <Form.Item>
              <Button type="primary" htmlType="submit">
                검색
              </Button>
            </Form.Item>
          </Col>

          <Col>
            <Form.Item>
              <Checkbox onChange={e => onChangeDispose(e.target.checked)}>
                폐기 포함
              </Checkbox>
            </Form.Item>
          </Col>
        </Row>
      </Form>

      <Table
        columns={columns}
        dataSource={filteredItems}
        pagination={false}
        onChange={onChangeTable}
        rowKey="id"
        loading={loading}
        bordered={true}
        size="middle"
      />

      <Drawer
        closable={false}
        placement="right"
        width={'85%'}
        visible={detailVisible}
        onClose={onCloseDetail}
        destroyOnClose={true}
      >
        <AssetList
          hideToolbar
          category1={category1}
          category2={category2}
          includeDisposal={searchOption.include_disposal}
          onChange={onChangeDetail}
        />
      </Drawer>
    </>
  );
};

export default Form.create<Props>()(AssetReportList);
