import { MenuItem } from '../../../models/Common';

const menuItems: MenuItem[] = [
  {
    id: '일일자금보고',
    title: '일일자금보고',
    icon: 'desktop',
    link: '/reports/main/daily'
  },
  {
    id: '자금계획표',
    title: '자금계획표',
    icon: 'desktop',
    link: '/reports/main/account'
  },
  {
    id: '수주정보보고',
    title: '수주정보보고',
    icon: 'desktop',
    children: [
      {
        id: '업무보고1',
        title: '전체 수주 진행 목록',
        link: '/reports/main/reports_all'
      },
      {
        id: '업무보고2',
        title: '핵심 프로젝트',
        link: '/reports/main/reports_urgent'
      },
      // {
      //   id: '업무보고3',
      //   title: '핵심수주 일일 보고',
      //   link: '/planning/main/reports3'
      // },
      {
        id: '업무보고3',
        title: '이슈 프로젝트',
        link: '/reports/main/reports_issue'
      },
      {
        id: '업무보고4',
        title: '신규 프로젝트',
        link: '/reports/main/reports_new'
      }
    ]
  },
  {
    id: '설계',
    title: '설계',
    icon: 'desktop',
    children: [
      {
        id: '설계1',
        title: '진행프로젝트 보고',
        link: '/reports/main/eas/list?cat=2005'
      },
      {
        id: '설계2',
        title: '프로젝트 인원관리',
        link: '/reports/main/reports_hr'
      }
    ]
  },
  {
    id: '사업',
    title: '사업',
    icon: 'desktop',
    children: [
      {
        id: '사업1',
        title: '주간 업무보고',
        link: '/reports/main/eas/list?cat=3102'
      },
      {
        id: '사업2',
        title: '진행프로젝트 보고',
        link: '/reports/main/eas/list?cat=3103'
      },
      {
        id: '사업3',
        title: '금융CM팀 수주,매출 현황',
        link: '/reports/main/eas/list?cat=3104'
      },
      {
        id: '사업4',
        title: '현장별 사업현황',
        link: '/reports/main/eas/list?cat=3105'
      }
    ]
  },
  {
    id: '개발',
    title: '개발',
    icon: 'desktop',
    link: '/reports/main/eas/list?cat=4007'
  },
  {
    id: '마케팅',
    title: '마케팅',
    icon: 'desktop',
    link: '/reports/main/eas/list?cat=5008'
  },
  {
    id: '해외',
    title: '해외',
    icon: 'desktop',
    link: '/reports/main/eas/list?cat=6001'
  },
  {
    id: '그룹1',
    title: '그룹 - 인사총무',
    icon: 'desktop',
    children: [
      {
        id: '그룹11',
        title: '법무 진행현황 리스트',
        link: '/reports/main/eas/list?cat=1000'
      },
      {
        id: '그룹12',
        title: '인원현황 리스트',
        link: '/reports/main/eas/list?cat=1001'
      },
      {
        id: '그룹13',
        title: '경영,교육,행사',
        link: '/reports/main/eas/list?cat=1002'
      }
    ]
  },
  {
    id: '그룹2',
    title: '그룹 - 자금회계',
    icon: 'desktop',
    children: [
      {
        id: '그룹21',
        title: '외주비 현황보고',
        link: '/reports/main/eas/list?cat=1102'
      },
      {
        id: '그룹22',
        title: '매출,수금 현황보고',
        link: '/reports/main/eas/list?cat=1103'
      },
      {
        id: '그룹23',
        title: '손익 현황보고',
        link: '/reports/main/eas/list?cat=1104'
      },
      {
        id: '그룹24',
        title: '자산부채 현황보고',
        link: '/reports/main/eas/list?cat=1105'
      }
    ]
  },
  {
    id: '1105',
    title: '경영참고자료',
    icon: 'desktop'
  }
];

export default menuItems;
