import React, { useState, useEffect } from 'react';
import { Input, Button, Upload } from 'antd';
import { FormComponentProps } from 'antd/lib/form';
import { UploadFile, UploadChangeParam } from 'antd/lib/upload/interface';
import { API_ROOT } from '../../config';
import axios from 'axios';
import Util from '../../helpers/Util';
import { File } from '../../models/File';

interface Props extends FormComponentProps {
  name?: string;
  items?: File[];
  single?: boolean;
  title?: string;
  onChangeUploading?: (uploading: boolean) => void;
}

const FCFileInput = (props: Props) => {
  const { getFieldDecorator, setFieldsValue } = props.form;
  const {
    name = 'file_ids',
    title = '파일 선택',
    single = false,
    onChangeUploading,
  } = props;
  const [fileList, setFileList] = useState<UploadFile[]>([]);
  const [uploading, setUploading] = useState(false);

  useEffect(() => {
    if (props.items && props.items.length > 0) {
      const files: UploadFile[] = props.items.map((e) => {
        return {
          uid: String(e.id),
          size: e.size,
          name: e.original_name,
          status: 'done',
          // url: e.path,
          response: { file: { id: e.id } },
        } as UploadFile;
      });

      setFileList(files);
    }
  }, [props.items]);

  useEffect(() => {
    const fileIds = fileList
      .filter((e) => e.response && e.response.file && e.response.file.id)
      .map((e) => e.response.file.id);

    let fieldsValue: any = {};
    fieldsValue[name] = fileIds;
    setFieldsValue(fieldsValue);
  }, [fileList, name, setFieldsValue]);

  const uploadProps = {
    name: 'file',
    action: API_ROOT + '/files/upload',
    headers: {
      'X-Auth-Token': axios.defaults.headers.common['X-Auth-Token'],
    },
    multiple: !single,
    showUploadList: {
      showRemoveIcon: true,
      showDownloadIcon: false,
    },
    // data: { group_id: groupId },
    // beforeUpload(file: RcFile, fileList: RcFile[]) {
    //   // const extension = path.extname(file.name);
    //   // const basename = path.basename(file.name, extension);
    //   // setFieldsValue({ name: basename.normalize() });
    //   return true;
    // },
    onChange(info: UploadChangeParam) {
      setFileList([...info.fileList]);

      const { status } = info.file;
      if (status === 'uploading') {
        setUploading(true);
      } else if (status === 'error') {
        setUploading(false);
        Util.showError(`${info.file.name} 파일 업로드가 실패하였습니다.`);
      } else if (status === 'done') {
        setUploading(false);
      } else if (status === 'removed') {
        //
      }
    },
  };

  useEffect(() => {
    if (onChangeUploading) onChangeUploading(uploading);
  }, [uploading, onChangeUploading]);

  return (
    <>
      {getFieldDecorator(name)(<Input type="hidden" />)}
      <Upload {...uploadProps} fileList={fileList}>
        <Button disabled={single && fileList.length >= 1}>{title}...</Button>
      </Upload>
    </>
  );
};
export default FCFileInput;
