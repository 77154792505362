import React, { useState, useEffect } from 'react';
import { Table } from 'antd';
import Util from '../../../helpers/Util';
import { Contract } from '../../../models/Order';
import LinkButton from '../../../components/general/LinkButton';

interface Props {
  out: boolean;
  contracts: Contract[];
  terms: string[];
  onSelectItem?: (itemId: number) => void;
}

const ContractTable: React.FC<Props> = props => {
  const { out, contracts, terms } = props;
  const [loading, setLoading] = useState(false);
  const [items, setItems] = useState<any[]>([]);
  const [billColumns, setBillColumns] = useState<any[]>([]);

  useEffect(() => {
    const fetchDetail = async () => {
      setLoading(true);
      try {
        let items: any[] = [];
        let rates: any = {};
        for (const contract of contracts) {
          let item1: any = {
            company_name: contract.company_name,
            company_category: contract.company_category || '',
            id: contract.id,
            h1: '계약금액',
            h2: contract.f_price,
            h3: '과세공급'
          };
          let item2: any = {
            id: contract.id + '_2',
            h1: '과세공급',
            h2: contract.f_tax_price,
            h3: '면세공급'
          };
          let item3: any = {
            id: contract.id + '_3',
            h1: '면세공급',
            h2: contract.f_notax_price,
            h3: '합계금액'
          };
          let item4: any = {
            id: contract.id + '_4',
            h1: '부가세액',
            h2: contract.f_vat,
            h3: '세금계산'
          };
          let item5: any = {
            id: contract.id + '_5',
            h1: '',
            h2: '',
            h3: out ? '지급일' : '수금일'
          };
          let item6: any = {
            id: contract.id + '_6',
            h1: '합계',
            h2: contract.f_total_price,
            h3: out ? '지급금액' : '수금금액'
          };

          // 수주 청구합계
          let req_tax_price = 0;
          let req_notax_price = 0;
          let req_total_price = 0;

          // 수주 수금합계
          let total_pay = 0;

          // 수주 미청구잔액
          let rem_total_price = 0;

          // 외주 기지급금
          let paid_total_price = 0;

          // 외주 도래미지급
          let adv_total_price = 0;

          // 외주 미도래잔액
          let inadv_total_price = 0;

          contract.items.forEach(cItem => {
            if (cItem.term) {
              item1[cItem.term] = cItem.f_tax_price;
              item2[cItem.term] = cItem.f_notax_price;
              item3[cItem.term] = cItem.f_total_price;
              item4[cItem.term] = Util.formatDate(cItem.req_date);
              item5[cItem.term] = Util.formatDate(cItem.pay_date);
              item6[cItem.term] = cItem.f_total_pay;
              rates[cItem.term] = cItem.f_rate;

              if (out) {
                paid_total_price += Number(cItem.total_pay);

                if (cItem.advent) {
                  // 도래 항목인 경우
                  adv_total_price += Number(
                    cItem.total_price - cItem.total_pay
                  );
                } else {
                  inadv_total_price += Number(cItem.total_price);
                }
              } else {
                total_pay += Number(cItem.total_pay);

                if (cItem.req_date) {
                  req_tax_price += Number(cItem.tax_price);
                  req_notax_price += Number(cItem.notax_price);
                  req_total_price += Number(cItem.total_price);
                } else {
                  rem_total_price += Number(cItem.total_price);
                }
              }
            }
          });

          if (out) {
            item3.t1 = Util.formatNumber(paid_total_price); // 기지급금 합계

            item3.t2 = Util.formatNumber(adv_total_price); // 도래미지급 합계

            item3.t3 = Util.formatNumber(inadv_total_price); // 잔금 합계
          } else {
            // 청구합계
            item1.t1 = Util.formatNumber(req_tax_price);
            item2.t1 = Util.formatNumber(req_notax_price);
            item3.t1 = Util.formatNumber(req_total_price);

            item3.t2 = Util.formatNumber(total_pay); // 수금 합계

            item3.t3 = Util.formatNumber(rem_total_price); // 미청구잔액 합계
          }

          items.push(item1, item2, item3, item4, item5, item6);
        }

        setItems(items);

        const billColumns = terms.map((term, index) => {
          if (out) {
            // 외주일 경우 비율 표시 안함
            return {
              title: index + 1 + '. ' + term,
              align: 'right' as 'right',
              dataIndex: term
            };
          } else {
            // 수주일 경우 비율 표시
            return {
              title: index + 1 + '. ' + term,
              align: 'right' as 'right',
              children: [
                {
                  title: `${rates[term] || '0'} %`,
                  dataIndex: term,
                  align: 'right' as 'right'
                }
              ]
            };
          }
        });
        setBillColumns(billColumns);
        // setTableWidth(120 * 5 + 90 * 2 + terms.length * 120);
      } catch (error) {
        Util.showError(error);
      } finally {
        setLoading(false);
      }
    };

    fetchDetail();
  }, [out, contracts, terms]);

  const onClickContract = (record: any) => {
    if (props.onSelectItem) props.onSelectItem(record.id);
  };

  const renderCompany = (record: any) => {
    if (record.company_category === '') {
      return record.company_name;
    } else {
      return (
        <>
          {record.company_category}
          <br />
          {record.company_name}
        </>
      );
    }
  };

  const columns = [
    {
      title: '업체명',
      dataIndex: 'name',
      fixed: 'left' as 'left',
      align: 'center' as 'center',
      width: 120,
      render: (text: string, record: any, index: number) => {
        return {
          children: (
            <>
              {props.onSelectItem ? (
                <LinkButton onClick={e => onClickContract(record)}>
                  {renderCompany(record)}
                </LinkButton>
              ) : (
                <>{renderCompany(record)}</>
              )}
            </>
          ),
          props: { rowSpan: index % 6 === 0 ? 6 : 0 }
        };
      }
    },
    {
      title: '항목',
      dataIndex: 'h1',
      fixed: 'left' as 'left',
      align: 'center' as 'center',
      width: 90
    },
    {
      title: '금액',
      dataIndex: 'h2',
      fixed: 'left' as 'left',
      align: 'right' as 'right',
      width: 120
    },
    {
      title: out ? '지급조건' : '수금조건',
      dataIndex: 'h3',
      fixed: 'left' as 'left',
      align: 'center' as 'center',
      width: 90
    },
    ...billColumns,
    {
      title: out ? '기지급금' : '청구합계',
      dataIndex: 't1',
      align: 'right' as 'right',
      fixed: 'right' as 'right',
      width: 120
    },
    {
      title: out ? '도래미지급' : '수금합계',
      dataIndex: 't2',
      align: 'right' as 'right',
      fixed: 'right' as 'right',
      width: 120
    },
    {
      title: out ? '잔금' : '미청구잔액',
      dataIndex: 't3',
      align: 'right' as 'right',
      fixed: 'right' as 'right',
      width: 120
    }
  ];

  return (
    <Table
      columns={columns}
      dataSource={items}
      rowClassName={record => (record.h1 === '합계' ? 'hl-bg-blue' : '')}
      rowKey="id"
      pagination={false}
      bordered={true}
      loading={loading}
      size="middle"
      scroll={{ x: 'max-content' }}
    />
  );
};

export default ContractTable;
