import React from 'react';
import { Route, Switch, Redirect } from 'react-router-dom';
import { Layout, PageHeader } from 'antd';
import Sidebar from '../../components/Sidebar';
import { HLBody } from '../../components/layout/Index';
import { MenuItem } from '../../models/Common';
import UserAuthByMenus from './UserAuthByMenus';
import { AuthStore } from '../../stores/authStore';
import { inject, observer } from 'mobx-react';

const mainItems: MenuItem[] = [
  {
    id: '메뉴별권한설정',
    title: '메뉴별 권한설정',
    icon: 'desktop',
    link: '/settings/auth',
  },
];
interface Props {
  authStore?: AuthStore;
}

const Index: React.FC<Props> = (props) => {
  const authStore = props.authStore!;
  if (authStore.user.dept?.id !== 1) {
    return <></>;
  }

  return (
    <>
      <Sidebar menuItems={mainItems} defaultOpenKeys={['법인관리']}>
        <PageHeader title="설정" />
      </Sidebar>
      <Layout id="wrapper" style={{ marginLeft: 200 }}>
        <Layout.Content>
          <HLBody>
            <Switch>
              <Route path="/settings" exact>
                <Redirect to="/settings/auth" />
              </Route>

              <Route path="/settings/auth" component={UserAuthByMenus} />
            </Switch>
          </HLBody>
        </Layout.Content>
      </Layout>
    </>
  );
};

export default inject('authStore')(observer(Index));
