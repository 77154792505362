import React, { useState } from 'react';
import { Button, Drawer } from 'antd';
import ProjectList from './ProjectList';
import { Project } from '../../models/Project';

interface Props {
  visible: boolean;
  onCancel: () => void;
  onOk: (selectedItems: Project[]) => void;
}

const ProjectSelector: React.FC<Props> = props => {
  const [selectedItems, setSelectedItems] = useState<Project[]>([]);

  const handleOK = () => {
    props.onOk(selectedItems);
  };

  const handleClose = () => {
    props.onCancel();
  };

  const onSelectItems = (items: Project[]) => {
    setSelectedItems(items);
  };

  console.count('ProjectSelector');

  return (
    <Drawer
      closable={false}
      placement="right"
      width={1000}
      visible={props.visible}
      onClose={handleClose}
      destroyOnClose={true}
    >
      <ProjectList
        title="프로젝트 선택"
        useDrawer={true}
        onSelectItems={onSelectItems}
      />
      <div
        style={{
          position: 'absolute',
          left: 0,
          bottom: 0,
          width: '100%',
          borderTop: '1px solid #e9e9e9',
          padding: '10px 16px',
          background: '#fff',
          textAlign: 'right'
        }}
      >
        <Button onClick={handleClose} style={{ marginRight: 8 }}>
          취소
        </Button>
        <Button onClick={handleOK} type="primary">
          선택
        </Button>
      </div>
    </Drawer>
  );
};

export default ProjectSelector;
