import React, { useState, useEffect } from 'react';
import { Table, Tag } from 'antd';
import { withRouter, RouteComponentProps } from 'react-router';
import EAService from '../../services/EAService';
import Util from '../../helpers/Util';
import { Activity } from '../../models/Activity';
import { PaginationConfig } from 'antd/lib/table';

interface Props extends RouteComponentProps {
  docId: number;
}

const EADocActivityList: React.FC<Props> = props => {
  const [loading, setLoading] = useState(false);
  const [items, setItems] = useState<Activity[]>([]);
  const [totalCount, setTotalCount] = useState(0);
  const [currentPage, setCurrentPage] = useState(1);

  const pagination = {
    pageSize: 15,
    current: currentPage,
    total: totalCount,
    showTotal: (total: number, range: [number, number]) =>
      `전체 ${total}건 중 ${range[0]}-${range[1]}`
  };

  useEffect(() => {
    const fetchItems = async () => {
      setLoading(true);
      try {
        const result = await EAService.fetchActivities(
          props.docId,
          pagination.pageSize,
          currentPage
        );
        setTotalCount(result.total_count);
        setItems(Activity.fromJsonArray(result.items));
      } catch (error) {
        Util.showError(error);
      } finally {
        setLoading(false);
      }
    };

    fetchItems();
  }, [props.docId, currentPage, pagination.pageSize]);

  const onChangeTable = (pagination: PaginationConfig) => {
    setCurrentPage(pagination.current || 1);
  };

  // const handleItemClick = (item: Activity) => {
  //   props.history.push('/eas/view?id=' + item.id);
  // };

  const columns = [
    {
      dataIndex: 'createdAt',
      width: '150px',
      render: (text: string, record: Activity) => {
        return Util.formatDateTimeHHMM(text);
      }
    },
    {
      width: '100px',
      dataIndex: 'user.name'
    },
    {
      width: '130px',
      dataIndex: 'type',
      render: (text: string, record: Activity) => {
        return <Tag color={record.typeColor}>{record.typeName}</Tag>;
      }
    },
    {
      dataIndex: 'content',
      ellipsis: true
    }
  ];

  return (
    <Table
      columns={columns}
      dataSource={items}
      rowKey="id"
      pagination={pagination}
      onChange={onChangeTable}
      bordered={false}
      showHeader={false}
      loading={loading}
      size="middle"
    />
  );
};

export default withRouter(EADocActivityList);
