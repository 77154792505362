import React, { useState, useEffect } from 'react';
import { Form, Input, Button, Row, Col } from 'antd';
import { FormComponentProps } from 'antd/lib/form';
import { withRouter, RouteComponentProps } from 'react-router-dom';
import Util from '../../helpers/Util';
import { AuthStore } from '../../stores/authStore';
import { observer, inject } from 'mobx-react';
import FREditor from '../../components/general/FREditor';
import InlineItem from '../../components/formcomps/InlineItem';
import FCFileInput from '../../components/formcomps/FCFileInput';
import { ContentPage } from '../../models/Page';
import PageService from '../../services/PageService';

interface Props extends FormComponentProps, RouteComponentProps {
  authStore?: AuthStore;
}

const PageEditor: React.FC<Props> = props => {
  const params = new URLSearchParams(props.location.search);
  const pageId = Number(params.get('id')) || 0;
  const historyPathname = params.get('pathname') || '';

  const authStore = props.authStore!;
  const [, setLoading] = useState(false);
  const [item, setItem] = useState<ContentPage | undefined>();

  useEffect(() => {
    const fetchDetail = async () => {
      setLoading(true);
      try {
        const result = await PageService.fetchItem(pageId);
        const contentPage = ContentPage.fromJson(result.page);
        setItem(contentPage);
      } catch (error) {
        Util.showError(error);
      } finally {
        setLoading(false);
      }
    };

    fetchDetail();
  }, [pageId]);

  const [submitting, setSubmitting] = useState(false);

  const onClickSubmitButton = () => {
    props.form.validateFields((err, values) => {
      if (err) return;
      processItem(values);
    });
  };

  const processItem = async (values: any) => {
    try {
      if (item) {
        setSubmitting(true);
        await PageService.update(item.id, values);
        Util.showSuccess('저장되었습니다.');
        setSubmitting(false);

        props.history.push(`${historyPathname}?id=${pageId}`);
      }
    } catch (err) {
      Util.showError(err);
      setSubmitting(false);
    }
  };

  const formItemLayout = {
    labelCol: { span: 4 },
    wrapperCol: { span: 19 }
  };

  console.count('PageEditor');

  return (
    <>
      <h2>{item && item.title}</h2>
      <Row gutter={12}>
        <Col className="gutter-row" span={20}>
          <Form className="hl-form" {...formItemLayout}>
            <Form.Item label="작성자">
              <InlineItem width="40%">
                <Input value={authStore.user.nameWithDeptRank} readOnly />
              </InlineItem>
            </Form.Item>

            <Form.Item label="파일 첨부">
              <FCFileInput form={props.form} items={item && item.files} />
            </Form.Item>
          </Form>

          <div style={{ marginTop: '15px' }}>
            {item && (
              <FREditor name="content" value={item.content} form={props.form} />
            )}
          </div>
        </Col>
        <Col className="gutter-row" span={4}>
          <Row gutter={10}>
            <Col>
              <Button
                block
                type="primary"
                htmlType="submit"
                disabled={submitting}
                onClick={onClickSubmitButton}
              >
                업데이트
              </Button>
            </Col>
          </Row>
        </Col>
      </Row>
    </>
  );
};

const _PageEditor = Form.create<Props>()(PageEditor);
export default withRouter(inject('authStore')(observer(_PageEditor)));
