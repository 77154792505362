import React, { useState, useEffect, useRef } from 'react';
import { Table, Button, Icon, Form, Drawer, Popconfirm } from 'antd';
import LinkButton from '../../general/LinkButton';
import { FormComponentProps } from 'antd/lib/form';
import Util from '../../../helpers/Util';
import AButton from '../../general/AButton';
import { File } from '../../../models/File';
import FileService from '../../../services/FileService';
import { User, UserETC } from '../../../models/User';
import UserETCEdit from './UserETCEdit';
import UserService from '../../../services/UserService';

interface Props extends FormComponentProps {
  user: User;
}

const UserETCs: React.FC<Props> = props => {
  const { user } = props;
  const [loading, setLoading] = useState(false);
  const [refreshTrigger, setRefreshTrigger] = useState(1);
  const [items, setItems] = useState<UserETC[]>([]);

  useEffect(() => {
    const fetchItems = async () => {
      setLoading(true);
      try {
        const result = await UserService.fetchCardItems('etcs', user.id);
        setItems(UserETC.fromJsonArray(result.items));
      } catch (error) {
        Util.showError(error);
      } finally {
        setLoading(false);
      }
    };
    fetchItems();
  }, [user, refreshTrigger]);

  const columns = [
    {
      title: '제목',
      dataIndex: 'content'
    },
    {
      title: '관련자료',
      dataIndex: 'files',
      render: (text: string, record: UserETC) => {
        return (
          <>
            {record.files.map(file => (
              <span key={file.id} style={{ display: 'block' }}>
                <LinkButton onClick={e => onClickFile(file)}>
                  <Icon type="download" />
                </LinkButton>{' '}
                {file.isPreviewable ? (
                  <AButton onClick={e => FileService.previewFile(file)}>
                    {file.original_name}
                  </AButton>
                ) : (
                  <>{file.original_name}</>
                )}
              </span>
            ))}
          </>
        );
      }
    },
    {
      width: 80,
      render: (text: string, record: UserETC) => {
        return (
          <>
            <LinkButton onClick={e => onClickUpdateButton(record)}>
              수정
            </LinkButton>{' '}
            |{' '}
            <Popconfirm
              title="정말 삭제하시겠습니까?"
              onConfirm={e => onClickDeleteButton(record)}
            >
              <LinkButton>삭제</LinkButton>
            </Popconfirm>
          </>
        );
      }
    }
  ];

  const onClickFile = async (item: File) => {
    try {
      setLoading(true);
      await FileService.downloadFile(item.id, item.original_name);
    } catch (error) {
      Util.showError(error);
    } finally {
      setLoading(false);
    }
  };

  const itemRef = useRef<UserETC | undefined>(undefined);
  const [editorVisible, setEditorVisible] = useState(false);

  const onClickAddButton = () => {
    itemRef.current = undefined;
    setEditorVisible(true);
  };

  const onClickUpdateButton = async (item: UserETC) => {
    itemRef.current = item;
    setEditorVisible(true);
  };

  const onCloseEditor = () => {
    setEditorVisible(false);
  };

  const onSuccessEditing = () => {
    setRefreshTrigger(v => v + 1);
    setEditorVisible(false);
  };

  const onClickDeleteButton = async (item: UserETC) => {
    try {
      await UserService.deleteETC(item.user_id, item.seq);
      Util.showSuccess('삭제되었습니다.');
      setRefreshTrigger(v => v + 1);
    } catch (err) {
      Util.showError(err);
    }
  };

  return (
    <>
      <Table
        columns={columns}
        dataSource={items}
        pagination={false}
        rowKey="seq"
        loading={loading}
        bordered={true}
        size="middle"
        scroll={{ x: 'max-content' }}
      />

      <Button onClick={onClickAddButton} style={{ marginTop: 10 }}>
        <Icon type="plus" />열 추가
      </Button>

      <Drawer
        closable={false}
        placement="right"
        width={800}
        visible={editorVisible}
        onClose={onCloseEditor}
        destroyOnClose={true}
      >
        <UserETCEdit
          user={user}
          item={itemRef.current}
          onSuccess={onSuccessEditing}
        />
      </Drawer>
    </>
  );
};

export default Form.create<Props>()(UserETCs);
