import React, { useState, useEffect } from 'react';
import { Table, Form } from 'antd';
import { FormComponentProps } from 'antd/lib/form';
import { AccountWeekGroup, AccountVMItem } from '../../../models/Finance';
import _ from 'lodash';
import AButton from '../../../components/general/AButton';

interface Props extends FormComponentProps {
  groups: AccountWeekGroup[];
  prevPlan: number;
  prevAction: number;
  loading: boolean;
  preventEditing?: boolean;
  onSelectItem?: (item: AccountVMItem, out: boolean) => void;
}

const AccountReportTable: React.FC<Props> = props => {
  const { preventEditing = false } = props;
  const [items, setItems] = useState<AccountVMItem[]>([]);

  useEffect(() => {
    let items: AccountVMItem[] = [];
    let acc_price = props.prevPlan; // 잔액 계획
    let acc_pay = props.prevAction; // 잔액 실행

    const prevItem = new AccountVMItem();
    prevItem.id = -1;
    prevItem.divider = true;
    prevItem.date = '전월이월';
    prevItem.acc_price = props.prevPlan;
    prevItem.acc_pay = props.prevAction;

    props.groups.forEach((group, index) => {
      if (group.items.length > 0) {
        const weekItems = _.sortBy(group.items, 'f_est_date');
        const weekDates = _.uniq(weekItems.map(e => e.f_est_date));
        // console.log(weekItems);
        // console.log(weekDates);

        let price1Total = 0; // 주별 입금계획 합계
        let pay1total = 0; // 주별 입금실행 합계
        let price2Total = 0; // 주별 지급계획 합계
        let pay2total = 0; // 주별 지급실행 합계

        weekDates.forEach(date => {
          const dateItems = weekItems.filter(e => e.f_est_date === date);
          const inItems = dateItems.filter(e => e.out === false);
          const outItems = dateItems.filter(e => e.out === true);

          const maxCount = Math.max(inItems.length, outItems.length);
          for (let i = 0; i < maxCount; i++) {
            let item = AccountVMItem.fromAccountItem(inItems[i], outItems[i]);
            item.date = i === 0 ? date : '';
            item.divider = i === 0;
            items.push(item);
          }

          const inPriceTotal = _.sumBy(inItems, e => Number(e.price));
          const outPriceTotal = _.sumBy(outItems, e => Number(e.price));
          const inPayTotal = _.sumBy(inItems, e => Number(e.pay));
          const outPayTotal = _.sumBy(outItems, e => Number(e.pay));

          price1Total += inPriceTotal;
          price2Total += outPriceTotal;
          pay1total += inPayTotal;
          pay2total += outPayTotal;

          // 마지막 항목에 잔액 기록
          acc_price += inPriceTotal - outPriceTotal;
          acc_pay += inPayTotal - outPayTotal;

          let lastItem = _.last(items);
          if (lastItem) {
            lastItem.acc_price = acc_price;
            lastItem.acc_pay = acc_pay;
          }
        });

        const summaryItem = new AccountVMItem();
        summaryItem.id = index;
        summaryItem.divider = true;
        summaryItem.summary = true;
        summaryItem.title1 = `${index + 1}주차: ${group.startDate} ~ ${
          group.endDate
        }`;
        summaryItem.price1 = price1Total;
        summaryItem.pay1 = pay1total;
        summaryItem.price2 = price2Total;
        summaryItem.pay2 = pay2total;
        summaryItem.acc_price = acc_price;
        summaryItem.acc_pay = acc_pay;
        items.push(summaryItem);
      }
    });

    setItems([prevItem, ...items]);
  }, [props.groups, props.prevPlan, props.prevAction]);

  const onClickItem = (item: AccountVMItem, out: boolean) => {
    props.onSelectItem && props.onSelectItem(item, out);
  };

  const columns = [
    {
      title: '일자',
      dataIndex: 'date',
      // className: 'min-width-100',
      width: 120,
      align: 'center' as 'center',
      render: (text: string, record: AccountVMItem) => {
        if (record.summary) {
          return {
            children: (
              <div style={{ marginBottom: '10px' }}>
                <b>{record.title1}</b>
              </div>
            ),
            props: { colSpan: 4 }
          };
        }
        // return <b>{text}</b>;
        return text;
      }
    },
    {
      title: '입금',
      className: 'hl-border-left',
      children: [
        {
          title: '내용',
          dataIndex: 'title1',
          // className: 'hl-border-left min-width-200',
          className: 'hl-border-left',
          width: 300,
          render: (text: string, record: AccountVMItem) => {
            if (record.summary) return { props: { colSpan: 0 } };
            if (record.title1 === '') return { props: { colSpan: 5 } };

            const displayText =
              text.length > 20 ? text.substring(0, 20) + '..' : text;

            if (preventEditing) return displayText;
            return (
              <AButton onClick={e => onClickItem(record, false)}>
                {displayText}
              </AButton>
            );
          }
        },
        {
          title: '종류',
          dataIndex: 'kind1',
          // className: 'min-width-70',
          width: 70,
          render: (text: string, record: AccountVMItem) => {
            if (record.summary) return { props: { colSpan: 0 } };
            if (record.title1 === '') return { props: { colSpan: 0 } };
            return text;
          }
        },
        {
          title: '법인',
          dataIndex: 'corp1.code',
          // width: '40px',
          width: 40,
          render: (text: string, record: AccountVMItem) => {
            if (record.summary) return { props: { colSpan: 0 } };
            if (record.title1 === '') return { props: { colSpan: 0 } };
            return <div style={{ width: '40px' }}>{text}</div>;
          }
        },
        {
          title: '계획',
          dataIndex: 'f_price1',
          align: 'right' as 'right',
          width: 123,
          render: (text: string, record: AccountVMItem) => {
            if (record.summary) {
              return (
                <div style={{ marginBottom: '10px' }}>
                  <b>{text}</b>
                </div>
              );
            }
            if (record.title1 === '') return { props: { colSpan: 0 } };
            return text;
          }
        },
        {
          title: '실행',
          dataIndex: 'f_pay1',
          align: 'right' as 'right',
          width: 123,
          render: (text: string, record: AccountVMItem) => {
            if (record.summary) {
              return (
                <div style={{ marginBottom: '10px' }}>
                  <b>{text}</b>
                </div>
              );
            }
            if (record.title1 === '') return { props: { colSpan: 0 } };
            return text;
          }
        }
      ]
    },
    {
      title: '출금',
      className: 'hl-border-left',
      children: [
        {
          title: '내용',
          dataIndex: 'title2',
          // className: 'hl-border-left min-width-200',
          className: 'hl-border-left',
          width: 300,
          render: (text: string, record: AccountVMItem) => {
            if (!record.summary && record.title2 === '')
              return { props: { colSpan: 4 } };

            const displayText =
              text.length > 20 ? text.substring(0, 20) + '..' : text;

            if (preventEditing) return displayText;
            return (
              <AButton onClick={e => onClickItem(record, true)}>
                {displayText}
              </AButton>
            );
          }
        },
        {
          title: '법인',
          dataIndex: 'corp2.code',
          // width: '40px',
          width: 40,
          render: (text: string, record: AccountVMItem) => {
            if (!record.summary && record.title2 === '')
              return { props: { colSpan: 0 } };
            return <div style={{ width: '40px' }}>{text}</div>;
          }
        },
        {
          title: '계획',
          dataIndex: 'f_price2',
          align: 'right' as 'right',
          width: 123,
          render: (text: string, record: AccountVMItem) => {
            if (record.summary) {
              return (
                <div style={{ marginBottom: '10px' }}>
                  <b>{text}</b>
                </div>
              );
            }
            if (!record.summary && record.title2 === '')
              return { props: { colSpan: 0 } };
            return text;
          }
        },
        {
          title: '실행',
          dataIndex: 'f_pay2',
          align: 'right' as 'right',
          width: 123,
          render: (text: string, record: AccountVMItem) => {
            if (record.summary) {
              return (
                <div style={{ marginBottom: '10px' }}>
                  <b>{text}</b>
                </div>
              );
            }
            if (!record.summary && record.title2 === '')
              return { props: { colSpan: 0 } };
            return text;
          }
        }
      ]
    },
    {
      title: '잔액',
      className: 'hl-border-left',
      children: [
        {
          title: '계획',
          dataIndex: 'f_acc_price',
          className: 'hl-border-left hl-fg-blue',
          align: 'right' as 'right',
          width: 130,
          render: (text: string, record: AccountVMItem) => {
            if (record.summary) {
              return (
                <div style={{ marginBottom: '10px' }}>
                  <b>{text}</b>
                </div>
              );
            }
            return <b>{text}</b>;
          }
        },
        {
          title: '실행',
          dataIndex: 'f_acc_pay',
          className: 'hl-fg-black',
          align: 'right' as 'right',
          width: 130,
          render: (text: string, record: AccountVMItem) => {
            if (record.summary) {
              return (
                <div style={{ marginBottom: '10px' }}>
                  <b>{text}</b>
                </div>
              );
            }
            return <b>{text}</b>;
          }
        }
      ]
    }
  ];

  return (
    <Table
      columns={columns}
      dataSource={items}
      pagination={false}
      rowKey="id"
      rowClassName={record => {
        let className = 'hl-body-small';
        if (record.divider) className += ' hl-border-top';
        if (record.summary) className += ' hl-bg-gray';
        return className;
      }}
      loading={props.loading}
      bordered={true}
      size="middle"
    />
  );
};

export default Form.create<Props>()(AccountReportTable);
