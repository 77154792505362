import React, { useState, useEffect, useRef } from 'react';
import { withRouter, RouteComponentProps } from 'react-router';
import { Table, Row, Col, Input, Button, Icon, Modal, Popconfirm } from 'antd';
import { Plan } from '../../models/Plan';
import { PaginationConfig } from 'antd/lib/table';
import Util from '../../helpers/Util';
import PlanService from '../../services/PlanService';
import PlanEditor from './PlanEditor';
import LinkButton from '../../components/general/LinkButton';

interface Props extends RouteComponentProps {
  title?: string;
  preventCreation?: boolean;
  browseAll?: boolean;
}

const PlanList: React.FC<Props> = props => {
  const {
    title = '나의 일정관리',
    preventCreation = false,
    browseAll = false
  } = props;
  const [items, setItems] = useState<Plan[]>([]);
  const [keyword, setKeyword] = useState<string>('');
  const [totalCount, setTotalCount] = useState(0);
  const [currentPage, setCurrentPage] = useState(1);
  const [loading, setLoading] = useState(false);
  const [refreshTrigger, setRefreshTrigger] = useState(1);

  const pagination = {
    pageSize: 15,
    current: currentPage,
    total: totalCount,
    showTotal: (total: number, range: [number, number]) =>
      `전체 ${total}건 중 ${range[0]}-${range[1]}`
  };

  useEffect(() => {
    const fetchItems = async () => {
      setLoading(true);
      try {
        const result = await PlanService.fetchItems(
          pagination.pageSize,
          currentPage,
          keyword,
          browseAll
        );
        setTotalCount(result.total_count);
        setItems(Plan.fromJsonArray(result.items));
      } catch (error) {
        Util.showError(error);
      } finally {
        setLoading(false);
      }
    };
    fetchItems();
  }, [currentPage, keyword, browseAll, pagination.pageSize, refreshTrigger]);

  const columns = [
    {
      title: 'No',
      dataIndex: 'id',
      width: '80px',
      align: 'right' as 'right'
    },
    {
      title: '날짜',
      dataIndex: 'date',
      width: '120px',
      render: (text: string, record: Plan) => Util.formatDate(record.date)
    },
    {
      title: '내용',
      dataIndex: 'content_summary',
      ellipsis: true
    },
    {
      title: '게시자',
      dataIndex: 'owner',
      width: '200px',
      render: (text: string, record: Plan) => {
        return record.owner.nameWithDeptRank;
      }
    },
    {
      title: '등록일',
      dataIndex: 'created_at',
      width: '120px',
      render: (text: string, record: Plan) => Util.formatDate(text)
    },
    {
      width: '90px',
      render: (text: string, record: Plan) => {
        return (
          <>
            <LinkButton onClick={e => onClickEditButton(record)}>
              수정
            </LinkButton>{' '}
            |{' '}
            <Popconfirm
              title="정말 삭제하시겠습니까?"
              onConfirm={e => onClickDeleteButton(record)}
            >
              <LinkButton>삭제</LinkButton>
            </Popconfirm>
          </>
        );
      }
    }
  ];

  const onSearch = (keyword: string) => {
    setKeyword(keyword);
    setCurrentPage(1);
  };

  const onChangeTable = (pagination: PaginationConfig) => {
    setCurrentPage(pagination.current || 1);
  };

  const [itemEditorVisible, setItemEditorVisible] = useState(false);
  const itemRef = useRef<Plan | undefined>(new Plan());

  const onClickAddButton = () => {
    itemRef.current = undefined;
    setItemEditorVisible(true);
  };

  const onClickEditButton = (item: Plan) => {
    itemRef.current = item;
    setItemEditorVisible(true);
  };

  const onSuccessEditing = () => {
    setRefreshTrigger(v => v + 1);
    setItemEditorVisible(false);
  };

  const onClickDeleteButton = async (item: Plan) => {
    try {
      await PlanService.delete(item.id);
      Util.showSuccess('삭제되었습니다.');
      setRefreshTrigger(v => v + 1);
    } catch (err) {
      Util.showError(err);
    }
  };

  return (
    <>
      <h2>{title}</h2>

      <Row type="flex" justify="space-between" style={{ marginBottom: 10 }}>
        <Col>
          <Input.Search
            placeholder="검색어"
            allowClear={true}
            onSearch={onSearch}
            style={{ width: 200 }}
          />
        </Col>
        <Col>
          {!preventCreation && (
            <Button onClick={onClickAddButton}>
              <Icon type="plus" />
              일정 등록
            </Button>
          )}
        </Col>
      </Row>

      <Table
        columns={columns}
        dataSource={items}
        rowKey="id"
        pagination={pagination}
        onChange={onChangeTable}
        bordered={true}
        loading={loading}
        size="middle"
      />

      <Modal
        title={itemRef.current ? '일정 수정' : '일정 등록'}
        width={800}
        centered
        visible={itemEditorVisible}
        footer={null}
        onCancel={() => setItemEditorVisible(false)}
        destroyOnClose={true}
      >
        <PlanEditor
          item={itemRef.current}
          onSuccessEditing={onSuccessEditing}
        />
      </Modal>
    </>
  );
};

export default withRouter(PlanList);
