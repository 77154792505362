import React, { useState, useEffect, useRef } from 'react';
import {
  Table,
  Button,
  Row,
  Col,
  Icon,
  Input,
  Form,
  Popconfirm,
  Modal
} from 'antd';
import LinkButton from '../../components/general/LinkButton';
import { FormComponentProps } from 'antd/lib/form';
import { PaginationConfig } from 'antd/lib/table';
import Util from '../../helpers/Util';
import PageEADocEdit from './PageEADocEdit';
import AButton from '../../components/general/AButton';
import FileService from '../../services/FileService';
import { File } from '../../models/File';
import { PageEADoc } from '../../models/Page';
import PageService from '../../services/PageService';

interface Props extends FormComponentProps {
  pageId: number;
  onChange?: () => void;
}

const PageEADocList: React.FC<Props> = props => {
  const { pageId } = props;
  const [loading, setLoading] = useState(false);
  const [refreshTrigger, setRefreshTrigger] = useState(1);
  const [items, setItems] = useState<PageEADoc[]>([]);
  const [filteredItems, setFilteredItems] = useState<PageEADoc[]>([]);
  const [keyword, setKeyword] = useState('');
  const { getFieldDecorator } = props.form;

  useEffect(() => {
    const fetchItems = async (pageId: number) => {
      setLoading(true);
      try {
        const result = await PageService.fetchPageEADocs(pageId);
        const items = PageEADoc.fromJsonArray(result.items);
        setItems(items);
      } catch (error) {
        Util.showError(error);
      } finally {
        setLoading(false);
      }
    };
    fetchItems(pageId);
  }, [pageId, refreshTrigger]);

  useEffect(() => {
    const filtered =
      keyword === '' ? items : items.filter(el => el.title.includes(keyword));
    setFilteredItems(filtered);
  }, [items, keyword]);

  const columns = [
    {
      title: '문서번호',
      width: 80,
      align: 'right' as 'right',
      dataIndex: 'eadoc_id'
    },
    {
      title: '문서명',
      width: 350,
      dataIndex: 'title'
    },
    {
      title: '첨부파일',
      dataIndex: 'file_id',
      render: (text: string, record: PageEADoc) => {
        const file = record.file;
        if (file) {
          return (
            <>
              <span key={file.id} style={{ display: 'block' }}>
                <LinkButton onClick={e => onClickFile(file)}>
                  <Icon type="download" />
                </LinkButton>{' '}
                {file.isPreviewable ? (
                  <AButton onClick={e => FileService.previewFile(file)}>
                    {file.original_name}
                  </AButton>
                ) : (
                  <>{file.original_name}</>
                )}
              </span>
            </>
          );
        } else {
          return <></>;
        }
      }
    },
    {
      title: '비고',
      dataIndex: 'note'
    },
    {
      width: '80px',
      render: (text: string, record: PageEADoc) => {
        return (
          <>
            <LinkButton onClick={e => onClickUpdateButton(record)}>
              수정
            </LinkButton>{' '}
            |{' '}
            <Popconfirm
              title="정말 삭제하시겠습니까?"
              onConfirm={e => onClickDeleteButton(record)}
            >
              <LinkButton>삭제</LinkButton>
            </Popconfirm>
          </>
        );
      }
    }
  ];

  const onChangeTable = (pagination: PaginationConfig) => {
    // setCurrentPage(pagination.current || 1);
  };

  const onSubmitSearchForm = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    props.form.validateFields((err, values) => {
      if (err) return;
      setKeyword(values.eadoc_keyword || '');
    });
  };

  const itemRef = useRef<PageEADoc | undefined>(undefined);
  const [editorVisible, setEditorVisible] = useState(false);

  const onClickAddButton = () => {
    itemRef.current = undefined;
    setEditorVisible(true);
  };

  const onClickUpdateButton = async (item: PageEADoc) => {
    itemRef.current = item;
    setEditorVisible(true);
  };

  const onCloseEditor = () => {
    setEditorVisible(false);
  };

  const onSuccessEditing = () => {
    setRefreshTrigger(v => v + 1);
    setEditorVisible(false);
  };

  const onClickDeleteButton = async (item: PageEADoc) => {
    try {
      await PageService.deletePageEADoc(item.id);
      Util.showSuccess('삭제되었습니다.');
      setRefreshTrigger(v => v + 1);
    } catch (err) {
      Util.showError(err);
    }
  };

  const onClickFile = async (item: File) => {
    try {
      setLoading(true);
      await FileService.downloadFile(item.id, item.original_name);
    } catch (error) {
      Util.showError(error);
    } finally {
      setLoading(false);
    }
  };

  return (
    <>
      <Form
        className="hl-form"
        layout="inline"
        style={{ marginBottom: 7 }}
        onSubmit={onSubmitSearchForm}
      >
        <Row type="flex" justify="space-between">
          <Col>
            <Form.Item>
              {getFieldDecorator('eadoc_keyword')(
                <Input.Search
                  placeholder="검색어"
                  allowClear={true}
                  style={{ width: 150 }}
                />
              )}
            </Form.Item>

            <Form.Item>
              <Button type="primary" htmlType="submit">
                검색
              </Button>
            </Form.Item>
          </Col>

          <Col>
            <Button onClick={onClickAddButton}>
              <Icon type="plus" />
              관련문서 등록
            </Button>
          </Col>
        </Row>
      </Form>

      <Table
        columns={columns}
        dataSource={filteredItems}
        pagination={false}
        onChange={onChangeTable}
        rowKey="id"
        loading={loading}
        bordered={true}
        size="middle"
      />

      <Modal
        title={itemRef.current ? '관련문서 수정' : '관련문서 등록'}
        width={800}
        centered
        visible={editorVisible}
        footer={null}
        onCancel={onCloseEditor}
        destroyOnClose={true}
      >
        <PageEADocEdit
          pageId={pageId}
          item={itemRef.current}
          onSuccess={onSuccessEditing}
        />
      </Modal>
    </>
  );
};

export default Form.create<Props>()(PageEADocList);
