import React from 'react';
import { Route, Switch, Redirect } from 'react-router-dom';
import UnderConst from '../../components/UnderConst';
import Dashboard from '../projects/common/Dashboard';
import EADocDrafter from '../eas/EADocDrafter';
import OrderList from '../projects/common/OrderList';
import OrderLedger from '../projects/common/OrderLedger';
import OrderDetail from '../projects/orders/OrderDetail';
import OrderEADocList from '../projects/orders/OrderEADocList';
import { OrderState } from '../../models/Order';
import DeptCodeList from '../../components/general/DeptCodeList';
import EADocListByCat from '../eas/EADocListByCat';
import EADocDrafterWrapper from '../eas/EADocDrafterWrapper';
import ArchiveGroupBrowser from '../../components/general/ArchiveGroupBrowser';
import CollectPlan from '../management/finance/CollectPlan';

interface Props {}

const Index: React.FC<Props> = props => {
  const menu = 'planning';
  const submenu = 'urban';
  const deptId = 22;

  return (
    <Switch>
      <Route path="/planning/urban/uc" component={UnderConst} />
      <Route path="/planning/urban/archive" component={ArchiveGroupBrowser} />

      {/* default path 지정 */}
      <Route path="/planning/urban" exact>
        <Redirect to="/planning/urban/dashboard" />
      </Route>

      <Route path="/planning/urban/dashboard">
        <Dashboard menu={menu} deptId={deptId} hideDeptSelector />
      </Route>
      <Route path="/planning/urban/ongoing">
        <OrderLedger
          title="진행 프로젝트"
          menu={menu}
          deptId={deptId}
          hideDeptSelector
          state={OrderState.ongoing}
        />
      </Route>
      <Route path="/planning/urban/eadocs/ord">
        <OrderEADocList
          menu={menu}
          submenu={submenu}
          deptId={deptId}
          hideDeptSelector
        />
      </Route>
      <Route path="/planning/urban/draft/ord">
        <EADocDrafter title="수주정보보고서" refTarget="ord" />
      </Route>
      <Route path="/planning/urban/orders/view" component={OrderDetail} />
      <Route path="/planning/urban/orders">
        <OrderList deptId={deptId} hideDeptSelector />
      </Route>
      <Route path="/planning/urban/ordins">
        <OrderLedger
          title="수주견적"
          menu={menu}
          deptId={deptId}
          hideDeptSelector
          target="ordin"
        />
      </Route>
      <Route path="/planning/urban/ctrins">
        <OrderLedger
          title="수주계약"
          menu={menu}
          deptId={deptId}
          hideDeptSelector
          target="ctrin"
        />
      </Route>
      <Route path="/planning/urban/ordouts">
        <OrderLedger
          title="외주실행"
          menu={menu}
          deptId={deptId}
          hideDeptSelector
          target="ordout"
        />
      </Route>
      <Route path="/planning/urban/ctrouts">
        <OrderLedger
          title="외주계약"
          menu={menu}
          deptId={deptId}
          hideDeptSelector
          target="ctrout"
        />
      </Route>

      <Route path="/planning/urban/collect">
        <CollectPlan menu="planning/urban" hideCorpSelect />
      </Route>

      <Route path="/planning/urban/eas/list" component={EADocListByCat} />
      <Route path="/planning/urban/eas/draft" component={EADocDrafterWrapper} />

      <Route path="/planning/urban/settings/stages">
        <DeptCodeList
          menu={menu}
          deptId={deptId}
          title="부서별 진행단계"
          fieldName="order_stages"
        />
      </Route>
      <Route path="/planning/urban/settings/ctypes">
        <DeptCodeList
          menu={menu}
          deptId={deptId}
          title="부서별 수금구분"
          fieldName="cp_terms"
        />
      </Route>
    </Switch>
  );
};

export default Index;
