import React from 'react';
import { Route, Switch } from 'react-router-dom';
import UnderConst from '../../components/UnderConst';
import ArchiveGroupBrowser from '../../components/general/ArchiveGroupBrowser';
import { Order } from '../../models/Order';
import OrderFileBrowser from '../../components/general/OrderFileBrowser';
import EADocListByCat from '../eas/EADocListByCat';
import EADocDrafterWrapper from '../eas/EADocDrafterWrapper';

interface Props {
  order: Order;
}

const Index: React.FC<Props> = props => {
  return (
    <Switch>
      <Route path="/development/orders/uc" component={UnderConst} />
      <Route path="/development/orders/:oid/uc" component={UnderConst} />
      <Route
        path="/development/orders/:oid/files"
        component={OrderFileBrowser}
      />
      <Route
        path="/development/orders/:oid/archive"
        component={ArchiveGroupBrowser}
      />

      <Route
        path="/development/orders/:oid"
        exact
        component={OrderFileBrowser}
      />

      <Route
        path="/development/orders/:oid/eas/list"
        component={EADocListByCat}
      />
      <Route path="/development/orders/:oid/eas/draft">
        <EADocDrafterWrapper order={props.order} />
      </Route>
    </Switch>
  );
};

export default Index;
