import { MenuItem } from '../../../models/Common';

const menuItems: MenuItem[] = [
  {
    id: 'eaflows',
    title: '결재선 관리',
    icon: 'desktop',
    link: '/works/eas/flow_templates'
  },
  {
    id: '결재문서함',
    title: '결재문서함',
    icon: 'desktop',
    children: [
      {
        id: 'draft',
        title: '임시저장문서',
        link: '/works/eas/list?type=draft'
      },
      {
        id: 'sent',
        title: '발신문서',
        link: '/works/eas/list?type=sent'
      },
      {
        id: 'queue',
        title: '미결문서',
        link: '/works/eas/list?type=queued'
      },
      {
        id: 'waiting',
        title: '대기문서',
        link: '/works/eas/list?type=waiting'
      },
      {
        id: 'ongoing',
        title: '진행문서',
        link: '/works/eas/list?type=ongoing'
      },
      {
        id: 'returned',
        title: '반려문서',
        link: '/works/eas/list?type=returned'
      },
      {
        id: 'done',
        title: '완료문서',
        link: '/works/eas/list?type=done'
      },
      {
        id: 'cc',
        title: '참조문서',
        link: '/works/eas/list?type=cc'
      },
      {
        id: 'dreceived',
        title: '부서수신문서',
        link: '/works/eas/list?type=dreceived'
      },
      {
        id: 'dsent',
        title: '부서발신문서',
        link: '/works/eas/list?type=dsent'
      },
      {
        id: 'all',
        title: '전체문서',
        link: '/works/eas/list_all',
        allow_dept_ids: [1, 2, 11, 12, 13] // 임원, 경영관리부문
      }
    ]
  },
  {
    id: '근태신청',
    title: '근태신청',
    icon: 'desktop',
    children: [
      {
        id: '휴가1',
        title: '근태신청서',
        link: '/works/eas/vacations/apply'
      },
      {
        id: '휴가2',
        title: '근태현황',
        link: '/works/eas/vacations/records'
      }
    ]
  },
  // {
  //   id: '전도금/업무경비',
  //   title: '전도금/업무경비',
  //   icon: 'desktop',
  //   link: '/works/eas/uc'
  // },
  // {
  //   id: '공용자산',
  //   title: '공용자산(비품) 사용신청',
  //   icon: 'desktop',
  //   link: '/works/eas/uc'
  // },
  {
    id: '교육',
    title: '교육',
    icon: 'desktop',
    children: [
      {
        id: '교육1',
        title: '교육수강내역',
        link: '/works/eas/myedu'
      },
      {
        id: '교육2',
        title: '교육일정',
        link: '/works/eas/edu'
      }
    ]
  },
  {
    id: '출장',
    title: '출장',
    icon: 'desktop',
    allow_user_ids: [1, 3, 18020, 11004], // 임시 오픈: 어드민, 홍길동, 공, 박
    children: [
      {
        id: '출장1',
        title: '출장명령품의',
        link: '/works/eas/trips/eadocs'
      },
      {
        id: '출장2',
        title: '나의 출장 내역',
        link: '/works/eas/trips/mytrip'
      }
    ]
  },
  {
    id: '기타결재',
    title: '기타결재',
    icon: 'desktop',
    link: '/works/eas/draft'
  }
];

export default menuItems;
