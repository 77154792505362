import React from 'react';
import { withRouter, RouteComponentProps } from 'react-router';
import { Activity } from '../../models/Activity';
import NotificationListView from './NotificationListView';

interface Props extends RouteComponentProps {
  onSelectItem?: (activity: Activity) => void;
}

const NotificationList: React.FC<Props> = props => {
  //   const params = new URLSearchParams(props.location.search);
  //   const page = Number(params.get('page')) || 1;
  //   const type = Number(params.get('type')) || undefined;

  return <NotificationListView />;
};

export default withRouter(NotificationList);
