import React from 'react';
import { Route, Switch, Redirect } from 'react-router-dom';
import UnderConst from '../../components/UnderConst';
import ArchiveGroupBrowser from '../../components/general/ArchiveGroupBrowser';
import OrderReport, { OrderReportListType } from '../reports/OrderReport';
import OrderReportHR from '../reports/OrderReportHR';
import DailyReport from '../management/finance/DailyReport';
import AccountReport from '../management/finance/AccountReport';
import EADocListByCat from '../eas/EADocListByCat';
import EADocDrafterWrapper from '../eas/EADocDrafterWrapper';

interface Props {}

const Index: React.FC<Props> = props => {
  return (
    <Switch>
      <Route path="/reports/main" exact>
        <Redirect to="/reports/main/daily" />
      </Route>
      <Route path="/reports/main/uc" component={UnderConst} />

      <Route path="/reports/main/archive" component={ArchiveGroupBrowser} />

      <Route path="/reports/main/eas/list" component={EADocListByCat} />
      <Route path="/reports/main/eas/draft" component={EADocDrafterWrapper} />

      <Route path="/reports/main/daily">
        <DailyReport preventEditing />
      </Route>
      <Route path="/reports/main/account">
        <AccountReport preventEditing />
      </Route>

      <Route path="/reports/main/reports_all">
        <OrderReport title="전체 수주 진행 목록" />
      </Route>

      <Route path="/reports/main/reports_urgent">
        <OrderReport title="핵심 프로젝트" isUrgent />
      </Route>

      <Route path="/reports/main/reports_issue">
        <OrderReport
          title="이슈 프로젝트"
          hasIssue
          listType={OrderReportListType.hasIssue}
        />
      </Route>

      <Route path="/reports/main/reports_new">
        <OrderReport
          title="신규 프로젝트"
          isNew
          listType={OrderReportListType.isNew}
        />
      </Route>

      <Route path="/reports/main/reports_hr">
        <OrderReportHR />
      </Route>
    </Switch>
  );
};

export default Index;
