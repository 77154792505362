import React from 'react';
import { RouteComponentProps, withRouter } from 'react-router';
import EADocDetail from './EADocDetail';

interface Props extends RouteComponentProps {}

const EADocView: React.FC<Props> = props => {
  const { location } = props;
  const params = new URLSearchParams(location.search);
  const id = Number(params.get('id')) || 0;

  return <EADocDetail id={id} />;
};

export default withRouter(EADocView);
