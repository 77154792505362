import { User } from './User';
import Util from '../helpers/Util';
import moment from 'moment';
import { EADocState } from './EAS';
import { File } from './File';

export class Trip {
  id = 0;
  trip_title = '';
  type = 1; // 1 출장, 2 외근
  reason = '';
  place = '';
  begin_date = moment();
  end_date = moment();
  content = '';
  user: User = new User(); // 신청인

  users: User[] = []; // 결재문서의 출장자 정보 처리용
  items: TripAccount[] = []; // 결재문서의 예산정보 처리용

  // derived
  [key: string]: any; // for string index (subscript)

  static fromJsonArray(sources: any[]) {
    return sources.map(source => Trip.fromJson(source));
  }

  static fromJson(source: any) {
    let item = new Trip();
    for (const key of Object.keys(source)) {
      if (key in item) {
        item[key] = source[key];
      }
    }

    item.user = source.user ? User.fromJson(source.user) : new User();
    item.begin_date = Util.stringToMoment(source.begin_date) || moment();
    item.end_date = Util.stringToMoment(source.end_date) || moment();
    item.items = source.items ? TripAccount.fromJsonArray(source.items) : [];
    item.users = source.users ? User.fromJsonArray(source.users) : [];

    return item;
  }

  get durationSummary() {
    return `${Util.formatDateTimeHHMM(
      this.begin_date
    )} ~ ${Util.formatDateTimeHHMM(this.end_date)}`;
  }
}

export class TripReport {
  id = 0;
  trip_id = 0;
  report = '';
  ticket_no = '';
  eaid?: number;
  ea_state: EADocState = EADocState.draft;
  items: TripAccount[] = [];
  request_total = 0;
  approve_total = 0;
  user: User = new User();
  trip: Trip = new Trip();

  // derived
  [key: string]: any; // for string index (subscript)

  static fromJsonArray(sources: any[]) {
    return sources.map(source => TripReport.fromJson(source));
  }

  static fromJson(source: any) {
    let item = new TripReport();
    for (const key of Object.keys(source)) {
      if (key in item) {
        item[key] = source[key];
      }
    }

    item.items = source.items ? TripAccount.fromJsonArray(source.items) : [];
    item.user = source.user ? User.fromJson(source.user) : new User();
    item.trip = source.trip ? Trip.fromJson(source.trip) : new Trip();

    return item;
  }

  get f_request_total(): string {
    return Util.formatNumber(this.request_total);
  }

  get f_approve_total(): string {
    return Util.formatNumber(this.approve_total);
  }
}

export class TripAccount {
  id = 0;
  report_id = 0;
  seq = 0;
  type = '기타';
  request_price = 0;
  approve_price = 0;
  request_base = ''; // 산출근거
  files: File[] = [];

  // derived
  [key: string]: any; // for string index (subscript)

  constructor(id?: number) {
    this.id = id === undefined ? Date.now() : id;
  }

  static fromJsonArray(sources: any[]) {
    return sources.map(source => TripAccount.fromJson(source));
  }

  static fromJson(source: any) {
    let item = new TripAccount();
    for (const key of Object.keys(source)) {
      if (key in item) {
        item[key] = source[key];
      }
    }

    item.files = source.files ? File.fromJsonArray(source.files) : [];

    return item;
  }

  get f_request_price(): string {
    return Util.formatNumber(this.request_price);
  }

  get f_approve_price(): string {
    return Util.formatNumber(this.approve_price);
  }
}
