import React, { useState, useEffect } from 'react';
import { Input, Button, Form, Select } from 'antd';
import { FormComponentProps } from 'antd/lib/form';
import TextArea from 'antd/lib/input/TextArea';
import Util from '../../helpers/Util';
import { Lawsuit } from '../../models/Lawsuit';
import LawsuitService from '../../services/LawsuitService';
import { Project } from '../../models/Project';
import FCNumberInput from '../../components/formcomps/FCNumberInput';
import InlineItem from '../../components/formcomps/InlineItem';
import ProjectSelector from '../projects/ProjectSelector';
import FCTextInput from '../../components/formcomps/FCTextInput';
import InlineTextRight from '../../components/formcomps/InlineTextRight';
import _ from 'lodash';

interface Props extends FormComponentProps {
  id?: number;
  onSuccess?: () => void;
}

const LawsuitEdit: React.FC<Props> = props => {
  const itemId = props.id || 0;
  const [, setLoading] = useState(true);
  const [item, setItem] = useState<Lawsuit>(new Lawsuit());
  const { getFieldDecorator, setFieldsValue } = props.form;

  useEffect(() => {
    const fetchItem = async (id: number) => {
      setLoading(true);
      try {
        const result = await LawsuitService.fetchItem(id);
        const lawsuit = Lawsuit.fromJson(result.lawsuit);
        setItem(lawsuit);
      } catch (error) {
        Util.showError(error);
      } finally {
        setLoading(false);
      }
    };

    if (itemId > 0) {
      fetchItem(itemId);
    }
  }, [itemId]);

  const [submitting, setSubmitting] = useState(false);

  const onSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    props.form.validateFields((err, values) => {
      if (err) return;
      processItem(_.omit(values, ['name']));
    });
  };

  const processItem = async (values: any) => {
    try {
      setSubmitting(true);

      itemId === 0
        ? await LawsuitService.create(values)
        : await LawsuitService.update(itemId, values);
      Util.showSuccess('저장되었습니다.');

      if (props.onSuccess) props.onSuccess();
    } catch (err) {
      Util.showError(err);
      setSubmitting(false);
    }
  };

  const [projectSelectorVisible, setProjectSelectorVisible] = useState(false);
  const onClickSearchProject = () => {
    setProjectSelectorVisible(true);
  };

  const onCancelProjectSelector = () => {
    setProjectSelectorVisible(false);
  };

  const onSelectProjects = async (items: Project[]) => {
    if (items.length > 0) {
      const project = items[0];
      setFieldsValue({
        project_id: project.id,
        name: project.name,
        comp_name: project.comp_name
      });
      setProjectSelectorVisible(false);
    }
  };

  return (
    <>
      <h2>사건 {itemId > 0 ? '수정' : '등록'}</h2>

      <Form
        className="hl-form"
        labelCol={{ span: 4 }}
        wrapperCol={{ span: 18 }}
        onSubmit={onSubmit}
      >
        <Form.Item label="사건명">
          <InlineItem width="70%">
            {getFieldDecorator('title', {
              initialValue: item.title,
              rules: [
                {
                  required: true,
                  message: '사건명을 입력해주세요.'
                }
              ]
            })(<Input />)}
          </InlineItem>
        </Form.Item>

        <Form.Item label="사건번호">
          <InlineItem width="40%">
            <FCTextInput
              form={props.form}
              name="code"
              options={{ initialValue: item.code }}
            />{' '}
          </InlineItem>
        </Form.Item>

        <Form.Item label="진행여부">
          <InlineItem width="130px">
            {getFieldDecorator('ongoing', {
              initialValue: item.ongoing ? 1 : 0
            })(
              <Select>
                <Select.Option value={1}>진행</Select.Option>
                <Select.Option value={0}>완료</Select.Option>
              </Select>
            )}
          </InlineItem>
        </Form.Item>

        {getFieldDecorator('project_id', { initialValue: item.project_id })(
          <Input type="hidden" />
        )}
        <Form.Item label="관련 프로젝트명">
          <InlineItem width="70%">
            {getFieldDecorator('name', {
              initialValue: item.project_name,
              rules: [
                {
                  required: true,
                  message: '관련 프로젝트를 선택해주세요.'
                }
              ]
            })(<Input readOnly />)}
          </InlineItem>{' '}
          <Button onClick={onClickSearchProject}>프로젝트 검색...</Button>
        </Form.Item>

        <Form.Item label="사업주">
          <InlineItem width="40%">
            {getFieldDecorator(`comp_name`, { initialValue: item.comp_name })(
              <Input />
            )}
          </InlineItem>
        </Form.Item>

        <Form.Item label="관할법원">
          <InlineItem width="40%">
            <FCTextInput
              form={props.form}
              name="court"
              options={{ initialValue: item.court }}
            />
          </InlineItem>
          <InlineTextRight>소송금액: </InlineTextRight>
          <InlineItem width="30%">
            <FCNumberInput
              form={props.form}
              name="lawsuit_pay"
              options={{ initialValue: item.lawsuit_pay }}
            />
          </InlineItem>
        </Form.Item>

        <Form.Item label="원고(채권자)">
          <InlineItem width="40%">
            <FCTextInput
              form={props.form}
              name="accuser"
              options={{ initialValue: item.accuser }}
            />
          </InlineItem>
          <InlineTextRight>협의가능금액: </InlineTextRight>
          <InlineItem width="30%">
            <FCNumberInput
              form={props.form}
              name="settle_pay"
              options={{ initialValue: item.settle_pay }}
            />
          </InlineItem>
        </Form.Item>

        <Form.Item label="피고(채무자)">
          <InlineItem width="40%">
            <FCTextInput
              form={props.form}
              name="accused"
              options={{ initialValue: item.accuser }}
            />
          </InlineItem>
          <InlineTextRight>착수금: </InlineTextRight>
          <InlineItem width="30%">
            <FCNumberInput
              form={props.form}
              name="deposit"
              options={{ initialValue: item.deposit }}
            />
          </InlineItem>
        </Form.Item>

        <Form.Item label="당사담당자">
          <InlineItem width="40%">
            <FCTextInput
              form={props.form}
              name="director"
              options={{ initialValue: item.director }}
            />
          </InlineItem>
          <InlineTextRight>성사금: </InlineTextRight>
          <InlineItem width="30%">
            <FCNumberInput
              form={props.form}
              name="confirm_fee"
              options={{ initialValue: item.confirm_fee }}
            />
          </InlineItem>
        </Form.Item>

        <Form.Item label="담당변호사">
          <InlineItem width="40%">
            <FCTextInput
              form={props.form}
              name="lawyer"
              options={{ initialValue: item.lawyer }}
            />
          </InlineItem>
          <InlineTextRight>총지급액: </InlineTextRight>
          <InlineItem width="30%">
            <FCNumberInput
              form={props.form}
              name="total_pay"
              options={{ initialValue: item.total_pay }}
            />
          </InlineItem>
        </Form.Item>

        <Form.Item label="비 고">
          {getFieldDecorator('note', { initialValue: item.note })(
            <TextArea rows={8} />
          )}
        </Form.Item>

        <Form.Item wrapperCol={{ span: 12, offset: 4 }}>
          <Button type="primary" htmlType="submit" disabled={submitting}>
            저 장
          </Button>
        </Form.Item>
      </Form>

      <ProjectSelector
        visible={projectSelectorVisible}
        onCancel={onCancelProjectSelector}
        onOk={onSelectProjects}
      />
    </>
  );
};

export default Form.create<Props>()(LawsuitEdit);
