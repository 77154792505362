import React, { ReactNode } from 'react';

interface Props {
  children: ReactNode;
  style?: React.CSSProperties | undefined;
  onClick?: (event: React.MouseEvent<HTMLElement, MouseEvent>) => void;
}

const AButton: React.FC<Props> = (props: Props) => {
  /* eslint-disable-next-line */
  return (
    <a onClick={props.onClick} style={props.style}>
      {props.children}
    </a>
  );
};

export default AButton;
