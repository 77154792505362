import React, { useState, useEffect } from 'react';
import { Input } from 'antd';
import { GetFieldDecoratorOptions } from 'antd/lib/form/Form';
import { FormComponentProps } from 'antd/lib/form';

interface Props extends FormComponentProps {
  name: string;
  placeholder?: string;
  options?: GetFieldDecoratorOptions;
  readOnly?: boolean;
  disabled?: boolean;
  allowClear?: boolean;
  onEnter?: (value: string | undefined) => void;
}

const FCTextInput = (props: Props) => {
  const { getFieldDecorator, getFieldValue, setFieldsValue } = props.form;
  const [value, setValue] = useState('');

  const fieldValue = getFieldValue(props.name);
  useEffect(() => {
    setValue(fieldValue);
  }, [fieldValue]);

  const onBlur = (e: React.FocusEvent<HTMLInputElement>) => {
    submitValue(e.currentTarget.value);
  };

  const onPressEnter = (e: React.KeyboardEvent<HTMLInputElement>) => {
    submitValue(e.currentTarget.value);
  };

  const submitValue = (value: string) => {
    setFieldsValue({ [props.name]: value });
    if (props.onEnter) {
      props.onEnter(value);
    }
  };

  return (
    <>
      {getFieldDecorator(props.name, props.options)(<Input type="hidden" />)}
      <Input
        value={value}
        placeholder={props.placeholder}
        readOnly={props.readOnly}
        disabled={props.disabled}
        allowClear={props.allowClear}
        onChange={e => setValue(e.target.value)}
        onBlur={onBlur}
        onPressEnter={onPressEnter}
      />
    </>
  );
};
export default FCTextInput;
