import React, { useState, useEffect } from 'react';
import FREditor from '../../components/general/FREditor';
import { FormComponentProps } from 'antd/lib/form';
import PostService from '../../services/PostService';
import { Post } from '../../models/Post';
import { Row, Col, Select, Button } from 'antd';
import { SelectValue } from 'antd/lib/select';
import EADocSelectorDrawer from './EADocSelectorDrawer';
import { EADoc } from '../../models/EAS';
const { Option } = Select;

interface Props extends FormComponentProps {}

const EADocContentEditor: React.FC<Props> = props => {
  const [items, setItems] = useState<Post[]>([]);
  const [content, setContent] = useState('');
  const { getFieldDecorator } = props.form;

  useEffect(() => {
    const fetchItems = async () => {
      const result = await PostService.fetchItems(100, 1, 0);
      setItems(Post.fromJsonArray(result.items));
    };
    fetchItems();
  }, []);

  const onChangeTemplate = async (value: SelectValue) => {
    if (value) {
      const result = await PostService.fetchItem(Number(value));
      setContent(result.post.content);
    } else {
      setContent('');
    }
  };

  const [selectorVisible, setSelectorVisible] = useState(false);

  const handleSelectDoc = () => {
    setSelectorVisible(true);
  };

  const handleSelectorCancel = () => {
    setSelectorVisible(false);
  };

  const handleSelectorOk = (selectedItems: EADoc[]) => {
    setSelectorVisible(false);
    if (selectedItems.length > 0) {
      const eadoc = selectedItems[0];
      setContent(eadoc.data.content);
    }
  };

  return (
    <>
      <Row style={{ marginBottom: 10 }}>
        <Col>
          {getFieldDecorator('template')(
            <Select
              placeholder="문서 템플릿 선택"
              allowClear={true}
              style={{ width: '300px' }}
              onChange={onChangeTemplate}
            >
              {items.map(item => (
                <Option key={item.id} value={item.id}>
                  {item.title}
                </Option>
              ))}
            </Select>
          )}{' '}
          <Button onClick={handleSelectDoc}>기존 문서 불러오기...</Button>
          <EADocSelectorDrawer
            refTarget="null"
            visible={selectorVisible}
            onCancel={handleSelectorCancel}
            onOk={handleSelectorOk}
          />
        </Col>
      </Row>
      <FREditor name="content" value={content} form={props.form} />
    </>
  );
};

export default EADocContentEditor;
