import React, { useState } from 'react';
import { BUILD_NO } from '../../config';
import { Form, Icon, Input, Button, Alert } from 'antd';
import './Login.css';
import { FormComponentProps } from 'antd/lib/form';
import { RouteComponentProps } from 'react-router';
import { Redirect } from 'react-router-dom';
import { inject, observer } from 'mobx-react';
import { AuthStore } from '../../stores/authStore';
import Text from 'antd/lib/typography/Text';

type Error = {
  msg: string;
};

interface Props extends RouteComponentProps, FormComponentProps {
  authStore?: AuthStore;
}

const Login: React.FC<Props> = (props) => {
  const [redirectToReferrer, setRedirectToReferrer] = useState(false);
  const [errors, setErrors] = useState<Array<Error>>([]);
  const [loading, setLoading] = useState(false);
  const { from } = props.location.state || { from: { pathname: '/' } };
  const authStore = props.authStore!;

  console.count('Login');

  if (redirectToReferrer || authStore.userLoggedIn) {
    return <Redirect to={from.pathname} />;
  }

  const handleSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    props.form.validateFields(async (err, values) => {
      if (!err) {
        try {
          setLoading(true);
          await authStore.login(
            values.email + '@hanlimgroup.com',
            values.password
          );
          localStorage.setItem('lastLoggedInId', values.email);
          setRedirectToReferrer(true);
        } catch (err) {
          setLoading(false);
          if (err.response && err.response.data) {
            const { errors } = err.response.data;
            setErrors(errors);
          }
        }
      }
    });
  };

  const { getFieldDecorator } = props.form;

  return (
    <div className="login-wrapper">
      <div className="logo-section">
        <img width="100%" src="/images/hanlim-group.png" alt="Hanlim Logo" />
      </div>
      <Form onSubmit={handleSubmit} className="login-form">
        <Form.Item>
          {getFieldDecorator('email', {
            rules: [
              { required: true, message: '이메일 주소를 입력해주세요.' },
              // { type: 'email', message: '이메일 형식이 올바르지 않습니다.' }
            ],
            initialValue: localStorage.getItem('lastLoggedInId'),
          })(
            <Input
              disabled={loading}
              prefix={<Icon type="user" style={{ color: 'rgba(0,0,0,.25)' }} />}
              // type="email"
              placeholder="이메일"
              addonAfter="@hanlimgroup.com"
            />
          )}
        </Form.Item>
        <Form.Item>
          {getFieldDecorator('password', {
            rules: [{ required: true, message: '비밀번호를 입력해주세요.' }],
          })(
            <Input
              disabled={loading}
              prefix={<Icon type="lock" style={{ color: 'rgba(0,0,0,.25)' }} />}
              type="password"
              placeholder="비밀번호"
            />
          )}
        </Form.Item>
        <Button
          type="primary"
          htmlType="submit"
          className="login-form-button"
          disabled={loading}
        >
          {loading ? (
            <>
              <Icon type="loading" /> 로그인 중입니다...
            </>
          ) : (
            '로그인'
          )}
        </Button>
      </Form>
      <div className="error-alerts">
        {errors.length > 0 &&
          errors.map((error, index) => (
            <Alert key={index} type="error" message={error.msg} />
          ))}
      </div>
      <Text type="secondary">HANLIM ERP Web Client Build {BUILD_NO}</Text>
    </div>
  );
};

const LoginForm = Form.create()(Login);
export default inject('authStore')(observer(LoginForm));
