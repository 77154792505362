import React, { useState, useEffect } from 'react';
import { Order, ContractItem, Contract } from '../../../models/Order';
import OrderService from '../../../services/OrderService';
import Util from '../../../helpers/Util';
import ContractSummaryPanel from './ContractSummaryPanel';
import OrderSummaryPanel from './OrderSummaryPanel';
import { Table } from 'antd';
import _ from 'lodash';

interface Props {
  id?: number;
  data?: any;
}

const OrderInViewer: React.FC<Props> = props => {
  const [loading, setLoading] = useState(false);
  const [order, setOrder] = useState<Order>(new Order());
  const [contract, setContract] = useState<Contract>(new Contract());
  const [items, setItems] = useState<ContractItem[]>([]);
  const [totalItem, setTotalItem] = useState<ContractItem>(new ContractItem(0));

  useEffect(() => {
    const fetchDetail = async (id: number) => {
      setLoading(true);
      try {
        const result = await OrderService.fetchItem(id);
        const result2 = await OrderService.fetchContract(
          id,
          result.order.comp_id,
          false
        );
        const result3 = await OrderService.fetchContractItems(
          result2.contract.id
        );
        configureDetail(result.order, result2.contract, result3);
      } catch (error) {
        Util.showError(error);
      } finally {
        setLoading(false);
      }
    };

    const fetchDetailForData = async (data: any) => {
      setLoading(true);
      try {
        const result = await OrderService.fetchItem(data.order_id);
        configureDetail(result.order, data, data.items);
      } catch (error) {
        Util.showError(error);
      } finally {
        setLoading(false);
      }
    };

    const configureDetail = (
      orderData: any,
      contractData: any,
      itemsData: any
    ) => {
      setOrder(Order.fromJson(orderData));
      setContract(Contract.fromJson(contractData));
      const items = ContractItem.fromJsonArray(itemsData);
      setItems(items);

      const total = new ContractItem(0);
      total.term = '합계';
      total.rate = _.sumBy(items, item => Number(item.rate));
      total.tax_price = _.sumBy(items, item => Number(item.tax_price));
      total.notax_price = _.sumBy(items, item => Number(item.notax_price));
      total.total_price = _.sumBy(items, item => Number(item.total_price));
      setTotalItem(total);
    };

    if (props.data) {
      fetchDetailForData(props.data);
    } else if (props.id) {
      fetchDetail(props.id);
    }
  }, [props.id, props.data]);

  const render = (text: string, record: ContractItem) => {
    return record.seq === 0 ? <b>{text}</b> : text;
  };

  const columns = [
    {
      title: '구분',
      dataIndex: 'term',
      render
    },
    {
      title: '비율(%)',
      dataIndex: 'f_rate',
      align: 'right' as 'right',
      render
    },
    {
      title: '과세공급가',
      dataIndex: 'f_tax_price',
      align: 'right' as 'right',
      render
    },
    {
      title: '면세공급가',
      dataIndex: 'f_notax_price',
      align: 'right' as 'right',
      render
    },
    {
      title: '합계금액',
      dataIndex: 'f_total_price',
      align: 'right' as 'right',
      render
    },
    {
      title: '수금예정일',
      dataInex: 'est_date',
      render: (text: string, record: ContractItem) =>
        Util.formatDate(record.est_date)
    },
    {
      title: '비고',
      dataIndex: 'note'
    }
  ];

  console.count('OrderInViewer');

  return (
    <>
      <OrderSummaryPanel order={order} />
      <ContractSummaryPanel contract={contract} />
      <Table
        columns={columns}
        dataSource={[...items, totalItem]}
        rowKey="seq"
        pagination={false}
        bordered={true}
        loading={loading}
        size="middle"
      />
    </>
  );
};

export default OrderInViewer;
