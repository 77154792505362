import React, { useState, useEffect } from 'react';
import { Input, Button, Form, InputNumber } from 'antd';
import { withRouter, RouteComponentProps } from 'react-router';
import { FormComponentProps } from 'antd/lib/form';
import TextArea from 'antd/lib/input/TextArea';
import Util from '../../helpers/Util';
import ProjectService from '../../services/ProjectService';
import { Project } from '../../models/Project';
import AppService from '../../services/AppService';
import FCAddressInput from '../../components/formcomps/FCAddressInput';
import FCNumberInput from '../../components/formcomps/FCNumberInput';
import FCStringCodeSelect from '../../components/formcomps/FCStringCodeSelect';
import FCCascader from '../../components/formcomps/FCCascader';
import InlineText from '../../components/formcomps/InlineText';
import InlineItem from '../../components/formcomps/InlineItem';
import { Company } from '../../models/Compnay';
import CompanySelector from './companies/CompanySelector';
import FIHQSelect from '../../components/formcomps/FIHQSelect';
import FIDatePicker from '../../components/formcomps/FIDatePicker';

interface Props extends RouteComponentProps, FormComponentProps {
  id?: number;
  onSuccess?: () => void;
}

const ProjectEdit: React.FC<Props> = props => {
  const { location } = props;
  const params = new URLSearchParams(location.search);
  const itemId = props.id || Number(params.get('id')) || 0;
  const { getFieldDecorator, setFieldsValue } = props.form;

  const [, setLoading] = useState(true);
  const [item, setItem] = useState<Project>();

  const types = ['신규', '관리', '중점'];
  const [kinds, setKinds] = useState<string[]>([]);
  const [uses, setUses] = useState<string[]>([]);

  useEffect(() => {
    const fetchStringCodes = async () => {
      setLoading(true);
      try {
        const result = await AppService.fetchStoreValues([
          'project_kinds',
          'project_uses'
        ]);
        setKinds(result['project_kinds']);
        setUses(result['project_uses']);
      } catch (error) {
        Util.showError(error);
      } finally {
        setLoading(false);
      }
    };

    fetchStringCodes();
  }, []);

  useEffect(() => {
    const fetchItem = async () => {
      setLoading(true);
      try {
        const result = await ProjectService.fetchItem(itemId);
        const project = Project.fromJson(result.project);
        setItem(project);
        setFieldsValue(project.fieldsValue());
      } catch (error) {
        Util.showError(error);
      } finally {
        setLoading(false);
      }
    };
    if (itemId > 0) {
      fetchItem();
    } else {
      const project = new Project();
      setItem(project);
      setFieldsValue(project.fieldsValue());
    }
  }, [itemId, setFieldsValue]);

  const [submitting, setSubmitting] = useState(false);

  const onSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    props.form.validateFields((err, values) => {
      if (err) return;

      values.use1 = values.use1.join('/');
      values.use2 = values.use2.join('/');
      values.use3 = values.use3.join('/');

      processItem(values);
    });
  };

  const processItem = async (values: any) => {
    try {
      setSubmitting(true);
      itemId === 0
        ? await ProjectService.create(values)
        : await ProjectService.update(itemId, values);
      Util.showSuccess('저장되었습니다.');

      props.onSuccess
        ? props.onSuccess()
        : props.history.push('/projects/list');
    } catch (err) {
      Util.showError(err);
      setSubmitting(false);
    }
  };

  const [companySelectorVisible, setCompanySelectorVisible] = useState(false);
  const onClickSearchCompany = () => {
    setCompanySelectorVisible(true);
  };

  const onCancelCompanySelector = () => {
    setCompanySelectorVisible(false);
  };

  const onSelectCompanies = (items: Company[]) => {
    if (items.length > 0) {
      const company = items[0];
      setFieldsValue({
        comp_id: company.id,
        comp_name: company.name
      });
      setCompanySelectorVisible(false);
    }
  };

  return (
    <>
      <h2>프로젝트 {itemId === 0 ? '등록' : '수정'}</h2>

      <Form
        className="hl-form"
        labelCol={{ span: 4 }}
        wrapperCol={{ span: 18 }}
        onSubmit={onSubmit}
      >
        {getFieldDecorator('drafter_id')(<Input type="hidden" />)}
        <Form.Item label="프로젝트년도">
          {getFieldDecorator('year', {
            rules: [
              {
                required: true,
                message: '프로젝트년도를 입력해주세요.'
              }
            ]
          })(<InputNumber />)}
        </Form.Item>

        <Form.Item label="프로젝트명">
          {getFieldDecorator('name', {
            rules: [
              {
                required: true,
                message: '프로젝트명을 입력해주세요.'
              }
            ]
          })(<Input autoFocus />)}
        </Form.Item>

        {getFieldDecorator('comp_id')(<Input type="hidden" />)}
        <Form.Item label="발주처">
          <InlineItem>
            {getFieldDecorator('comp_name', {
              rules: [
                {
                  required: true,
                  message: '발주처를 선택해주세요.'
                }
              ]
            })(<Input style={{ width: '200px' }} disabled />)}
          </InlineItem>{' '}
          <Button onClick={onClickSearchCompany}>검색...</Button>
        </Form.Item>

        <FIHQSelect form={props.form} label="부서" width="200px" />

        <Form.Item label="기안자">
          <InlineItem width="200px">
            <Input value={item && item.drafter.nameWithDeptRank} disabled />
          </InlineItem>
        </Form.Item>

        <Form.Item label="접수일">
          <InlineItem>
            <FIDatePicker
              form={props.form}
              name="register_date"
              initialValue={
                item &&
                item.register_date &&
                Util.formatDate(item.register_date)
              }
              width="200px"
            />
          </InlineItem>
          <InlineText>구분:</InlineText>
          <InlineItem width="100px">
            <FCStringCodeSelect
              form={props.form}
              name="type"
              items={types}
              allowClear={true}
            />
          </InlineItem>
        </Form.Item>

        <Form.Item label="종류">
          <InlineItem width="200px">
            <FCStringCodeSelect
              form={props.form}
              name="kind"
              items={kinds}
              allowClear={true}
            />
          </InlineItem>
        </Form.Item>

        <Form.Item label="용도">
          <InlineItem width="240px">
            <FCCascader form={props.form} name="use1" items={uses} />
          </InlineItem>{' '}
          <InlineItem width="240px">
            <FCCascader form={props.form} name="use2" items={uses} />
          </InlineItem>{' '}
          <InlineItem width="240px">
            <FCCascader form={props.form} name="use3" items={uses} />
          </InlineItem>
          <InlineItem width="240px">
            {getFieldDecorator('use')(<Input placeholder="용도 기타" />)}
          </InlineItem>
        </Form.Item>

        <FCAddressInput
          form={props.form}
          required
          useMap
          address={item && item.address}
        />

        <Form.Item label="대지면적">
          <FCNumberInput form={props.form} name="site_area" unit="㎡" />
          <InlineText>연면적:</InlineText>
          <FCNumberInput form={props.form} name="total_floor_area" unit="㎡" />
          <InlineText>층수 (지상):</InlineText>
          <FCNumberInput form={props.form} name="floor_level" size="small" />
          <InlineText>(지하):</InlineText>
          <FCNumberInput form={props.form} name="base_level" size="small" />
        </Form.Item>

        <Form.Item label="시작일자">
          <InlineItem>
            <FIDatePicker
              form={props.form}
              name="begin_date"
              initialValue={
                item && item.begin_date && Util.formatDate(item.begin_date)
              }
            />
          </InlineItem>
          <InlineText>종료일자:</InlineText>
          <InlineItem>
            <FIDatePicker
              form={props.form}
              name="end_date"
              initialValue={
                item && item.end_date && Util.formatDate(item.end_date)
              }
            />
          </InlineItem>
        </Form.Item>

        <Form.Item label="세부내용">
          {getFieldDecorator('note')(<TextArea rows={8} />)}
        </Form.Item>

        <Form.Item wrapperCol={{ span: 12, offset: 4 }}>
          <Button type="primary" htmlType="submit" disabled={submitting}>
            저 장
          </Button>
        </Form.Item>
      </Form>

      <CompanySelector
        visible={companySelectorVisible}
        onCancel={onCancelCompanySelector}
        onOk={onSelectCompanies}
      />
    </>
  );
};

const _ProjectEdit = Form.create<Props>()(ProjectEdit);
export default withRouter(_ProjectEdit);
