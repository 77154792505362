import React, { useState, useEffect } from 'react';
import { Table } from 'antd';
import { DailyReportItem } from '../../../models/Finance';
import { observer, inject } from 'mobx-react';
import { DataStore } from '../../../stores/dataStore';
import Util from '../../../helpers/Util';

interface Props {
  dataStore?: DataStore;
  items: DailyReportItem[];
}

const DailyReportSummary: React.FC<Props> = props => {
  const dataStore = props.dataStore!;
  const [loading] = useState(false);
  const [items, setItems] = useState<any[]>([]);
  const [corpColumns, setCorpColumns] = useState<any[]>([]);

  useEffect(() => {
    const corpColumns = dataStore.corps.map((corp, index) => {
      return {
        title: corp.name,
        dataIndex: corp.id,
        align: 'right' as 'right',
        width: `${80 / dataStore.corps.length}%`
      };
    });
    setCorpColumns(corpColumns);

    const cashItem: any = { id: '현금' };
    const bankItem: any = { id: '예금' };
    const sumItem: any = { id: '합계', summary: true };

    let cashSum = 0;
    let bankSum = 0;
    props.items.forEach(item => {
      cashSum += Number(item.amount_cash);
      bankSum += Number(item.amount_bank);
      cashItem[item.corp_id] = Util.formatNumber(item.amount_cash);
      bankItem[item.corp_id] = Util.formatNumber(item.amount_bank);
      sumItem[item.corp_id] = Util.formatNumber(
        Number(item.amount_cash) + Number(item.amount_bank)
      );
    });

    cashItem.f_sum = Util.formatNumber(cashSum);
    bankItem.f_sum = Util.formatNumber(bankSum);
    sumItem.f_sum = Util.formatNumber(cashSum + bankSum);

    setItems([cashItem, bankItem, sumItem]);
  }, [props.items, dataStore.corps]);

  const columns = [
    {
      title: '구분',
      dataIndex: 'id',
      align: 'center' as 'center',
      width: 50
    },
    ...corpColumns,
    {
      title: '합 계',
      dataIndex: 'f_sum',
      width: 140,
      align: 'right' as 'right'
    },
    {
      title: '비고',
      width: 50
    }
  ];

  return (
    <Table
      columns={columns}
      dataSource={items}
      pagination={false}
      rowKey="id"
      rowClassName={record => {
        let className = '';
        if (record.summary) className += ' hl-bg-gray';
        return className;
      }}
      loading={loading}
      bordered={true}
      size="middle"
    />
  );
};

export default inject('dataStore')(observer(DailyReportSummary));
