import React, { useState, useEffect } from 'react';
import { Table } from 'antd';
import { AccountVMItem, AccountItem } from '../../../models/Finance';
import _ from 'lodash';
import AButton from '../../../components/general/AButton';

interface Props {
  items: AccountItem[];
  title1: string;
  title2: string;
  loading?: boolean;
  preventEditing?: boolean;
  onSelectItem?: (item: AccountVMItem) => void;
}

const DailyReportTable: React.FC<Props> = props => {
  const { preventEditing = false } = props;
  const [items, setItems] = useState<AccountVMItem[]>([]);

  useEffect(() => {
    let items: AccountVMItem[] = [];
    const cashItems = props.items.filter(e => e.type === 1);
    const bankItems = props.items.filter(e => e.type === 0);
    const maxCount = Math.max(cashItems.length, bankItems.length);
    for (let i = 0; i < maxCount; i++) {
      items.push(AccountVMItem.fromAccountItem(cashItems[i], bankItems[i]));
    }

    const cashTotal = _.sumBy(cashItems, e => Number(e.pay)); // 현금 합계
    const bankTotal = _.sumBy(bankItems, e => Number(e.pay)); // 통장 합계

    const summaryItem = new AccountVMItem();
    summaryItem.divider = true;
    summaryItem.summary = true;
    summaryItem.title1 = `${props.title1} 합계금액`;
    summaryItem.pay1 = cashTotal;
    summaryItem.title2 = `${props.title2} 합계금액`;
    summaryItem.pay2 = bankTotal;
    items.push(summaryItem);

    setItems(items);
  }, [props.items, props.title1, props.title2]);

  const onClickItem = (item: AccountVMItem) => {
    props.onSelectItem && props.onSelectItem(item);
  };

  const columns = [
    {
      title: '구분',
      dataIndex: 'index',
      align: 'center' as 'center',
      width: 50,
      render: (text: string, record: AccountVMItem, index: number) => {
        return record.summary ? '' : index + 1;
      }
    },
    {
      title: `${props.title1} 내역`,
      dataIndex: 'title1',
      width: 500,
      // className: 'hl-border-left min-width-200',
      render: (text: string, record: AccountVMItem) => {
        if (record.summary)
          return (
            <div style={{ marginBottom: '10px' }}>
              <b>{text}</b>
            </div>
          );
        if (record.title1 === '') return { props: { colSpan: 3 } };
        return preventEditing ? (
          text
        ) : (
          <AButton onClick={e => onClickItem(record)}>{text}</AButton>
        );
      }
    },
    {
      title: '금 액',
      dataIndex: 'f_pay1',
      width: 120,
      align: 'right' as 'right',
      render: (text: string, record: AccountVMItem) => {
        if (record.summary) {
          return (
            <div style={{ marginBottom: '10px' }}>
              <b>{text}</b>
            </div>
          );
        }
        if (record.title1 === '') return { props: { colSpan: 0 } };
        return text;
      }
    },
    {
      title: '비 고',
      dataIndex: 'note1',
      width: 100,
      render: (text: string, record: AccountVMItem) => {
        if (record.title1 === '') return { props: { colSpan: 0 } };
        return text;
      }
    },
    {
      title: `${props.title2} 내역`,
      dataIndex: 'title2',
      // className: 'hl-border-left min-width-200',
      width: 500,
      render: (text: string, record: AccountVMItem) => {
        if (record.summary)
          return (
            <div style={{ marginBottom: '10px' }}>
              <b>{text}</b>
            </div>
          );
        if (record.title2 === '') return { props: { colSpan: 3 } };
        return preventEditing ? (
          text
        ) : (
          <AButton onClick={e => onClickItem(record)}>{text}</AButton>
        );
      }
    },
    {
      title: '금 액',
      dataIndex: 'f_pay2',
      width: 120,
      align: 'right' as 'right',
      render: (text: string, record: AccountVMItem) => {
        if (record.summary) {
          return (
            <div style={{ marginBottom: '10px' }}>
              <b>{text}</b>
            </div>
          );
        }
        if (record.title2 === '') return { props: { colSpan: 0 } };
        return text;
      }
    },
    {
      title: '비 고',
      dataIndex: 'note2',
      width: 100,
      render: (text: string, record: AccountVMItem) => {
        if (record.title2 === '') return { props: { colSpan: 0 } };
        return text;
      }
    }
  ];

  return (
    <Table
      columns={columns}
      dataSource={items}
      pagination={false}
      rowKey="id"
      rowClassName={record => {
        let className = '';
        if (record.divider) className += ' hl-border-top';
        if (record.summary) className += ' hl-bg-gray';
        return className;
      }}
      loading={props.loading}
      bordered={true}
      size="middle"
    />
  );
};

export default DailyReportTable;
