import { MenuItem } from '../../../models/Common';

const menuItems: MenuItem[] = [
  {
    id: '프로젝트총괄',
    title: '프로젝트 총괄',
    icon: 'desktop',
    link: '/marketing/main/dashboard'
  },
  {
    id: '진행 프로젝트',
    title: '진행 프로젝트',
    icon: 'desktop',
    link: '/marketing/main/ongoing'
  },
  {
    id: '업무프로세스',
    title: '업무프로세스',
    icon: 'desktop',
    children: [
      {
        id: 'ord',
        title: '수주정보보고',
        link: '/marketing/main/eadocs/ord'
      },
      {
        id: 'orders',
        title: '수주심의',
        link: '/marketing/main/orders'
      },
      {
        id: 'ordins',
        title: '수주견적',
        link: '/marketing/main/ordins'
      },
      {
        id: 'ctrins',
        title: '수주계약',
        link: '/marketing/main/ctrins'
      },
      {
        id: 'ordouts',
        title: '외주실행',
        link: '/marketing/main/ordouts'
      },
      {
        id: 'ctrouts',
        title: '외주계약',
        link: '/marketing/main/ctrouts'
      }
      // {
      //   id: 'comp',
      //   title: '협력업체등록신청',
      //   link: '/marketing/main/uc'
      // }
    ]
  },

  // {
  //   id: '수금관리',
  //   title: '수금관리',
  //   icon: 'desktop',
  //   children: [
  //     {
  //       id: '수금계획표',
  //       title: '수금계획표',
  //       link: '/marketing/main/collect'
  //     },
  //     {
  //       id: '기성청구',
  //       title: '기성청구',
  //       link: '/marketing/main/uc'
  //     }
  //   ]
  // },
  {
    id: '수금계획표',
    title: '수금계획표',
    icon: 'desktop',
    link: '/marketing/main/collect'
  },
  {
    id: '마케팅',
    title: '업무보고',
    icon: 'desktop',
    link: '/marketing/main/eas/list?cat=5008'
  },
  { id: '5101', icon: 'desktop', title: '업무보고 (자료실)' },
  { id: '5102', icon: 'desktop', title: '업무매뉴얼' },

  {
    id: '기안/품의',
    title: '기안/품의',
    icon: 'desktop',
    children: [
      {
        id: 'report1',
        title: '사전원가보고',
        link: '/marketing/main/eas/list?refTarget=pmis&cat=5004'
      },
      {
        id: 'report2',
        title: '실행원가보고',
        link: '/marketing/main/eas/list?refTarget=pmis&cat=5005'
      },
      {
        id: 'report3',
        title: '정산보고',
        link: '/marketing/main/eas/list?refTarget=pmis&cat=5007'
      },
      {
        id: 'report4',
        title: '기타업무품의',
        link: '/marketing/main/eas/list?cat=5000'
      }
    ]
  },
  {
    id: '설정',
    title: '설정',
    icon: 'desktop',
    children: [
      {
        id: 'stages',
        title: '부서별 진행단계',
        link: '/marketing/main/settings/stages'
      },
      {
        id: 'ctypes',
        title: '부서별 수금구분',
        link: '/marketing/main/settings/ctypes'
      }
    ]
  }
];

export default menuItems;
