import React, { useState, useEffect, useRef } from 'react';
import {
  Input,
  Button,
  Table,
  Form,
  InputNumber,
  Modal,
  Progress,
  Drawer
} from 'antd';
import { FormComponentProps } from 'antd/lib/form';
import { withRouter, RouteComponentProps } from 'react-router';
import Util from '../../helpers/Util';
import { VacationRecord } from '../../models/Vacation';
import DeptSelect from '../../components/general/DeptSelect';
import RankSelector from '../../components/general/RankSelect';
import VacationBatchUpdateForm from './VacationBatchUpdateForm';
import VacationService, {
  VacationSearchOption
} from '../../services/VacationService';
import { PaginationConfig } from 'antd/lib/table';
import LinkButton from '../../components/general/LinkButton';
import VacationLogs from '../works/VacationLogs';
import UserPicker from '../../components/general/UserPicker';
import { User } from '../../models/User';
import AppService from '../../services/AppService';

interface Props extends RouteComponentProps, FormComponentProps {}

const _VacationRecords: React.FC<Props> = props => {
  const [items, setItems] = useState<VacationRecord[]>([]);
  const [searchOption, setSearchOption] = useState<VacationSearchOption>({});
  const [totalCount, setTotalCount] = useState(0);
  const [currentPage, setCurrentPage] = useState(1);
  const [loading, setLoading] = useState(false);
  const [refreshTrigger, setRefreshTrigger] = useState(0);
  const { getFieldDecorator } = props.form;

  const pagination = {
    pageSize: 15,
    current: currentPage,
    total: totalCount,
    showTotal: (total: number, range: [number, number]) =>
      `전체 ${total}건 중 ${range[0]}-${range[1]}`
  };

  const [vacManager, setVacManager] = useState<User | undefined>(undefined);
  useEffect(() => {
    const fetchInfo = async () => {
      setLoading(true);
      try {
        const result = await AppService.fetchStoreValues(['vac_manager']);
        if (result && result.vac_manager) {
          setVacManager(User.fromJson(result.vac_manager));
        }
      } catch (error) {
        Util.showError(error);
      } finally {
        setLoading(false);
      }
    };

    fetchInfo();
  }, []);

  useEffect(() => {
    const fetchItems = async () => {
      setLoading(true);
      try {
        const result = await VacationService.fetchRecords(
          pagination.pageSize,
          currentPage,
          searchOption
        );
        setTotalCount(result.total_count);
        setItems(VacationRecord.fromJsonArray(result.items));
      } catch (error) {
        Util.showError(error);
      } finally {
        setLoading(false);
      }
    };

    fetchItems();
  }, [refreshTrigger, currentPage, searchOption, pagination.pageSize]);

  const onChangeTable = (pagination: PaginationConfig) => {
    setCurrentPage(pagination.current || 1);
  };

  const onSubmitSearchForm = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    props.form.validateFields((err, values) => {
      if (err) return;
      setSearchOption(values);
      setCurrentPage(1);
    });
  };

  const [userPickerVisible, setUserPickerVisible] = useState(false);

  const onClickVacManager = () => {
    setUserPickerVisible(true);
  };

  const onCancelUserPicker = () => {
    setUserPickerVisible(false);
  };

  const onOkUserPicker = async (selectedItems: User[]) => {
    setUserPickerVisible(false);
    if (selectedItems.length <= 0) return;
    const user = selectedItems[0];
    try {
      await AppService.updateStoreValues('vac_manager', {
        id: user.id,
        name: user.name
      });
      setVacManager(user);
      Util.showSuccess('처리되었습니다.');
    } catch (error) {
      Util.showError(error);
    }
  };

  const [batchFormVisible, setBatchFormVisible] = useState(false);

  const onClickBatchRegister = () => {
    setBatchFormVisible(true);
  };

  const onSuccessBatchRegister = () => {
    setBatchFormVisible(false);
    Util.showSuccess('등록처리 되었습니다.');
    setRefreshTrigger(Date.now());
  };

  let changedGivens: any = useRef({});

  const onChangeGivenField = (
    item: VacationRecord,
    value: number | undefined
  ) => {
    changedGivens.current[item.user.id + '_' + item.year] =
      item.user.id + ',' + item.year + ',' + Number(value);
  };

  const onClickBatchUpdate = () => {
    if (Object.keys(changedGivens.current).length === 0) {
      return;
    }

    const values = Object.values(changedGivens.current);
    const content = values.join('\n');
    doUpdate(content);
  };

  const [submitting, setSubmitting] = useState(false);

  const doUpdate = async (content: string) => {
    try {
      setSubmitting(true);
      await VacationService.batchUpdate(content);
      Util.showSuccess('업데이트 되었습니다.');
      changedGivens.current = {};
      setRefreshTrigger(Date.now());
      setSubmitting(false);
    } catch (err) {
      Util.showError(err);
      setSubmitting(false);
    }
  };

  const itemRef = useRef<VacationRecord>(new VacationRecord());
  const [detailVisible, setDetailVisible] = useState(false);

  // const onClickItem = (item: VacationRecord) => {
  //   props.history.push('/management/support/users/' + item.user.id);
  // };

  const onClickItem = (item: VacationRecord) => {
    itemRef.current = item;
    setDetailVisible(true);
  };

  const columns = [
    {
      title: '사번',
      dataIndex: 'user.id',
      align: 'right' as 'right'
    },
    {
      title: '성명',
      dataIndex: 'user.name',
      render: (text: string, record: VacationRecord) => (
        <LinkButton onClick={e => onClickItem(record)}>{text}</LinkButton>
      )
    },
    {
      title: '부서',
      dataIndex: 'user.deptName'
    },
    {
      title: '직급',
      dataIndex: 'user.rankName'
    },
    {
      title: '년도',
      dataIndex: 'year'
    },
    {
      title: '발생연차',
      dataIndex: 'given',
      render: (text: string, record: VacationRecord) => {
        return (
          <InputNumber
            style={{ width: 100 }}
            defaultValue={record.given}
            onChange={value => onChangeGivenField(record, value)}
          />
        );
      }
    },
    {
      title: '사용연차',
      dataIndex: 'used',
      align: 'right' as 'right'
    },
    {
      title: '잔여연차',
      align: 'right' as 'right',
      render: (text: string, record: VacationRecord) => {
        return record.given - record.used;
      }
    },
    {
      title: '사용률',
      dataIndex: 'usedRate',
      width: '200px',
      render: (text: string, record: VacationRecord) => {
        return (
          <Progress percent={record.usedRate} style={{ width: '170px' }} />
        );
      }
    }
  ];

  return (
    <>
      <h2>휴가관리</h2>

      <Form
        className="hl-form"
        layout="inline"
        labelCol={{ span: 0 }}
        wrapperCol={{ span: 24 }}
        style={{ marginBottom: 7 }}
        onSubmit={onSubmitSearchForm}
      >
        <Form.Item>
          {getFieldDecorator('keyword')(
            <Input.Search
              placeholder="사번 또는 성명"
              allowClear={true}
              style={{ width: 150 }}
            />
          )}
        </Form.Item>

        <Form.Item style={{ width: '180px' }}>
          <DeptSelect form={props.form} allowClear={true} />
        </Form.Item>

        <Form.Item style={{ width: '120px' }}>
          <RankSelector form={props.form} />
        </Form.Item>

        <Form.Item>
          {getFieldDecorator('year')(
            <InputNumber placeholder="년도" style={{ width: 100 }} />
          )}
        </Form.Item>

        <Form.Item>
          <Button type="primary" htmlType="submit">
            검색
          </Button>
        </Form.Item>

        <Form.Item>
          <Button onClick={onClickVacManager} disabled={submitting}>
            인사관리 담당 {(vacManager && ` : ${vacManager.name}`) || ''}
          </Button>{' '}
          <Button onClick={onClickBatchRegister} disabled={submitting}>
            발생연차 일괄등록...
          </Button>{' '}
          <Button onClick={onClickBatchUpdate} disabled={submitting}>
            발생연차 업데이트
          </Button>
        </Form.Item>
      </Form>

      <Table
        columns={columns}
        dataSource={items}
        rowKey="id"
        pagination={pagination}
        bordered={true}
        loading={loading}
        onChange={onChangeTable}
        size="middle"
      />

      <Modal
        title="발생연차 일괄등록"
        centered
        width={700}
        visible={batchFormVisible}
        footer={null}
        onCancel={() => setBatchFormVisible(false)}
        destroyOnClose={true}
      >
        <VacationBatchUpdateForm onSuccess={onSuccessBatchRegister} />
      </Modal>

      <Drawer
        closable={false}
        placement="right"
        width="80%"
        visible={detailVisible}
        onClose={e => setDetailVisible(false)}
        destroyOnClose={true}
      >
        <VacationLogs
          searchOption={{
            year: itemRef.current.year,
            keyword: String(itemRef.current.user.id)
          }}
        />
      </Drawer>

      <UserPicker
        title="인사관리 담당 지정"
        visible={userPickerVisible}
        onCancel={onCancelUserPicker}
        onOk={onOkUserPicker}
      />
    </>
  );
};

const VacationRecords = Form.create<Props>()(_VacationRecords);
export default withRouter(VacationRecords);
