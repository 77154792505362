import React from 'react';
import { withRouter, RouteComponentProps } from 'react-router';
import EADocListView from './EADocListView';

interface Props extends RouteComponentProps {}

const EADocListByCat: React.FC<Props> = props => {
  const params = new URLSearchParams(props.location.search);
  const page = Number(params.get('page')) || 1;
  const keyword = params.get('keyword') || '';

  const category = Number(params.get('cat')) || 0;
  const refTarget = params.get('refTarget') || '';
  const orderId: number = (props.match.params as any).oid;

  return (
    <EADocListView
      category={category}
      refTarget={refTarget}
      refId={orderId}
      keyword={keyword}
      page={page}
    />
  );
};

export default withRouter(EADocListByCat);
