import { MenuItem } from '../../../models/Common';

const menuItems: MenuItem[] = [
  { id: 'files', icon: 'desktop', title: '통합파일검색' },
  { id: '2101', icon: 'desktop', title: '입찰제안서' },
  { id: '2102', icon: 'desktop', title: '검토서' },
  { id: '2103', icon: 'desktop', title: '제안서' },
  { id: '2104', icon: 'desktop', title: '자문도서' },
  { id: '2105', icon: 'desktop', title: '입안도서' },
  { id: '2106', icon: 'desktop', title: '심의도서' },
  { id: '2107', icon: 'desktop', title: '승인도서' },
  { id: '2109', icon: 'desktop', title: '문서,회의록 외' },
  { id: '101', icon: 'desktop', title: '포트폴리오' },
  {
    id: '기안/품의',
    icon: 'desktop',
    title: '기안/품의',
    children: [
      {
        id: 'eas/list?refTarget=pmis&cat=2102',
        title: '검토서'
      },
      {
        id: 'eas/list?refTarget=pmis&cat=2103',
        title: '제안도서'
      },
      {
        id: 'eas/list?refTarget=pmis&cat=2104',
        title: '자문도서'
      },
      {
        id: 'eas/list?refTarget=pmis&cat=2105',
        title: '입안도서'
      },
      {
        id: 'eas/list?refTarget=pmis&cat=2106',
        title: '심의도서'
      },
      {
        id: 'eas/list?refTarget=pmis&cat=2107',
        title: '승인도서'
      }
    ]
  }
];

export default menuItems;
