import React, { useState, useEffect, useRef } from 'react';
import { Table, Progress, Drawer } from 'antd';
import Util from '../../helpers/Util';
import VacationService from '../../services/VacationService';
import { VacationRecord } from '../../models/Vacation';
import { AuthStore } from '../../stores/authStore';
import { observer, inject } from 'mobx-react';
import LinkButton from '../../components/general/LinkButton';
import VacationLogs from './VacationLogs';

interface Props {
  authStore?: AuthStore;
}

const UserVacationRecords: React.FC<Props> = props => {
  const authStore = props.authStore!;
  const [items, setItems] = useState<VacationRecord[]>([]);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    const fetchItems = async () => {
      setLoading(true);
      try {
        const result = await VacationService.fetchRecords(100, 1, {
          keyword: String(authStore.user.id)
        });
        setItems(VacationRecord.fromJsonArray(result.items));
      } catch (error) {
        Util.showError(error);
      } finally {
        setLoading(false);
      }
    };
    fetchItems();
  }, [authStore.user.id]);

  const itemRef = useRef<VacationRecord>(new VacationRecord());
  const [detailVisible, setDetailVisible] = useState(false);
  const onClickItem = (item: VacationRecord) => {
    itemRef.current = item;
    setDetailVisible(true);
  };

  const columns = [
    {
      title: '년도',
      dataIndex: 'year',
      render: (text: string, record: VacationRecord) => (
        <LinkButton onClick={e => onClickItem(record)}>{text}</LinkButton>
      )
    },
    {
      title: '발생연차',
      dataIndex: 'given',
      align: 'right' as 'right'
    },
    {
      title: '사용연차',
      dataIndex: 'used',
      align: 'right' as 'right'
    },
    {
      title: '잔여연차',
      align: 'right' as 'right',
      render: (text: string, record: VacationRecord) => {
        return record.given - record.used;
      }
    },
    {
      title: '사용률',
      dataIndex: 'usedRate',
      width: '40%',
      render: (text: string, record: VacationRecord) => (
        <Progress percent={record.usedRate} style={{ width: '90%' }} />
      )
    }
  ];

  return (
    <>
      <h2>근태현황</h2>

      <Table
        columns={columns}
        dataSource={items}
        rowKey="year"
        pagination={false}
        bordered={true}
        loading={loading}
        size="middle"
      />

      <Drawer
        closable={false}
        placement="right"
        width="80%"
        visible={detailVisible}
        onClose={e => setDetailVisible(false)}
        destroyOnClose={true}
      >
        <VacationLogs
          searchOption={{
            year: itemRef.current.year,
            keyword: String(authStore.user.id)
          }}
        />
      </Drawer>
    </>
  );
};

export default inject('authStore')(observer(UserVacationRecords));
