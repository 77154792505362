import React, { useState, useEffect } from 'react';
import { Row, Col, Button, Icon, Table, Popconfirm } from 'antd';
import { EAFlowItem, EAFlowItemType } from '../../models/EAS';
import EAFlowTemplateService from '../../services/EAFlowTemplateService';
import EAFlowEditor from './EAFlowEditor';
import Util from '../../helpers/Util';
import { EAFlowTemplate } from '../../models/EAS';
import StringEditor from '../../components/general/StringEditor';
import LinkButton from '../../components/general/LinkButton';

interface Props {}

const EAFlowTemplateList: React.FC<Props> = props => {
  const [items, setItems] = useState<EAFlowTemplate[]>([]);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    const fetchItems = async () => {
      setLoading(true);
      try {
        const result = await EAFlowTemplateService.fetchItems();
        setItems(EAFlowTemplate.fromJsonArray(result.items));
      } catch (error) {
        Util.showError(error);
      } finally {
        setLoading(false);
      }
    };

    fetchItems();
  }, []);

  const columns = [
    {
      title: '이름',
      dataIndex: 'name',
      width: '250px'
    },
    {
      title: '결재선',
      dataIndex: 'flow_items',
      render: (text: string, record: EAFlowTemplate) => {
        const names = record.flow_items.map(item => item.user.name);
        return names.join(' → ');
      }
    },
    {
      title: '참조',
      dataIndex: 'cc_items',
      render: (text: string, record: EAFlowTemplate) => {
        const names = record.cc_users.map(user => user.name);
        return names.join(', ');
      }
    },
    {
      width: '200px',
      render: (text: string, record: EAFlowTemplate) => {
        return (
          <>
            <LinkButton onClick={e => onClickEditFlowButton(record)}>
              결재선지정
            </LinkButton>{' '}
            |{' '}
            <LinkButton onClick={e => onClickUpdateItemButton(record)}>
              수정
            </LinkButton>{' '}
            |{' '}
            <Popconfirm
              title="정말 삭제하시겠습니까?"
              onConfirm={e => onClickDeleteItemButton(record)}
            >
              <LinkButton>삭제</LinkButton>
            </Popconfirm>
          </>
        );
      }
    }
  ];

  const [nameEditorVisible, setNameEditorVisible] = useState(false);
  const [editingItem, setEditingItem] = useState<EAFlowTemplate | undefined>(
    undefined
  );
  const [submitting, setSubmitting] = useState(false);

  const onClickRegisterButton = () => {
    setNameEditorVisible(true);
  };

  const onClickUpdateItemButton = async (item: EAFlowTemplate) => {
    setEditingItem(item);
    setNameEditorVisible(true);
  };

  const onClickDeleteItemButton = async (item: EAFlowTemplate) => {
    try {
      await EAFlowTemplateService.delete(item.id);
      setItems(items.filter(eachItem => eachItem.id !== item.id));
    } catch (err) {
      Util.showError(err);
    }
  };

  const onCancelNameEditor = () => {
    setNameEditorVisible(false);
  };

  const onSubmitNameEditor = async (name: string) => {
    try {
      setSubmitting(true);
      const result = editingItem
        ? await EAFlowTemplateService.update(editingItem.id, { name })
        : await EAFlowTemplateService.create({ name });
      const eaflow = EAFlowTemplate.fromJson(result.eaflow);
      onSuccessNameEditor(eaflow);
    } catch (err) {
      Util.showError(err);
    } finally {
      setSubmitting(false);
    }
  };

  const onSuccessNameEditor = (item: EAFlowTemplate) => {
    if (editingItem === undefined) {
      setItems([item, ...items]);
    } else {
      setItems(
        items.map(eachItem => {
          return eachItem.id === item.id ? item : eachItem;
        })
      );
    }
    setEditingItem(undefined);
    setNameEditorVisible(false);
  };

  const [flowEditorVisible, setFlowEditorVisible] = useState(false);
  const [eaItems, setEAItems] = useState<EAFlowItem[]>([]);
  const [ccItems, setCCItems] = useState<EAFlowItem[]>([]);

  const showFlowEditor = (item: EAFlowTemplate) => {
    const cc_items = item.cc_users.map(
      user => new EAFlowItem(user, EAFlowItemType.refer)
    );

    setEditingItem(item);
    setEAItems(item.flow_items);
    setCCItems(cc_items);
    setFlowEditorVisible(true);
  };

  const hideFlowEditor = () => {
    setEditingItem(undefined);
    setFlowEditorVisible(false);
  };

  const onClickEditFlowButton = (item: EAFlowTemplate) => {
    showFlowEditor(item);
  };

  const onCancelEAFlowEditor = () => {
    hideFlowEditor();
  };

  const onOkEAFlowEditor = async (
    items1: EAFlowItem[],
    items2: EAFlowItem[]
  ) => {
    if (editingItem === undefined) {
      return;
    }

    const flow_items = items1.map(item => {
      return { user_id: item.user.id, type: item.type };
    });
    const cc_items = items2.map(item => item.user.id);
    const data = { flow_items, cc_items };

    try {
      const result = await EAFlowTemplateService.update(editingItem.id, data);
      const item = EAFlowTemplate.fromJson(result.eaflow);

      setItems(
        items.map(eachItem => {
          return eachItem.id === item.id ? item : eachItem;
        })
      );

      setFlowEditorVisible(false);
    } catch (err) {
      Util.showError(err);
    }
  };

  return (
    <>
      <h2>결재선 관리</h2>
      <Row type="flex" justify="space-between" style={{ marginBottom: 10 }}>
        <Col />
        <Col>
          <Button onClick={onClickRegisterButton}>
            <Icon type="plus" />
            결재선 등록
          </Button>
        </Col>
      </Row>

      <Table
        columns={columns}
        dataSource={items}
        rowKey="id"
        pagination={false}
        bordered={true}
        loading={loading}
        size="middle"
      />

      <EAFlowEditor
        initialCCItems={ccItems}
        initialEAItems={eaItems}
        visible={flowEditorVisible}
        onCancel={onCancelEAFlowEditor}
        onOk={onOkEAFlowEditor}
      />

      <StringEditor
        name="결재선"
        visible={nameEditorVisible}
        value={editingItem && editingItem.name}
        submitting={submitting}
        onSubmit={onSubmitNameEditor}
        onCancel={onCancelNameEditor}
      />
    </>
  );
};

export default EAFlowTemplateList;
