import React from 'react';
import { Table } from 'antd';

interface Props {}

const UnderConst: React.FC<Props> = props => {
  return (
    <>
      <h2>준비중</h2>
      <Table
        // columns={{}}
        dataSource={[]}
        pagination={false}
        rowKey="id"
        bordered={true}
        size="middle"
      />
    </>
  );
};
export default UnderConst;
