import React from 'react';
import { withRouter, RouteComponentProps } from 'react-router';
import { EADocListType } from '../../models/EAS';
import EADocListView from './EADocListView';

interface Props extends RouteComponentProps {}

const EADocListByType: React.FC<Props> = props => {
  const params = new URLSearchParams(props.location.search);
  const typeParam = params.get('type');
  const page = Number(params.get('page')) || 1;
  const keyword = params.get('keyword') || '';

  // type 파라미터로 넘어온 값이 valid한지 여부 확인
  let typeValid = false;
  for (let validType in EADocListType) {
    if (typeParam === validType) {
      typeValid = true;
      break;
    }
  }
  if (!typeValid) return <></>;

  // tyep이 all인 경우 필터링 (전체문서 조회시 EADocListAll 사용)
  const type = typeParam as EADocListType;
  if (type === EADocListType.all) return <></>;

  return <EADocListView type={type} keyword={keyword} page={page} />;
};

export default withRouter(EADocListByType);
