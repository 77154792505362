import React, { useState, useEffect } from 'react';
import Util from '../../../helpers/Util';
import { Order, Contract } from '../../../models/Order';
import Spinner from '../../../components/general/Spinner';
import OrderSummaryPanel from '../orders/OrderSummaryPanel';
import OrderService from '../../../services/OrderService';
import { Input } from 'antd';
import { FormComponentProps } from 'antd/lib/form';
import ContractEditor from '../contracts/ContractEditor';

interface Props extends FormComponentProps {
  orderId?: number;
  data?: any;
  fetchDelayed?: boolean;
}

const OrderInEditor: React.FC<Props> = props => {
  const { orderId, data, fetchDelayed } = props;
  const [loading, setLoading] = useState(true);
  const [order, setOrder] = useState<Order>(new Order());
  const [contract, setContract] = useState<Contract>(new Contract());
  const { getFieldDecorator } = props.form;

  console.count('OrderInEditor');

  useEffect(() => {
    const fetchDetail = async (orderId: number) => {
      setLoading(true);
      try {
        const result = await OrderService.fetchItem(orderId);
        const order = Order.fromJson(result.order);
        setOrder(order);

        const result2 = await OrderService.fetchContracts(orderId, false);
        if (result2.contracts && result2.contracts.length > 0) {
          setContract(Contract.fromJson(result2.contracts[0]));
        }
      } catch (error) {
        Util.showError(error);
      } finally {
        setLoading(false);
      }
    };

    const fetchDetailForData = async (data: any) => {
      setLoading(true);
      try {
        const result = await OrderService.fetchItem(data.order_id);
        setOrder(Order.fromJson(result.order));
        setContract(Contract.fromJson(data));
      } catch (error) {
        Util.showError(error);
      } finally {
        setLoading(false);
      }
    };

    const processFetch = () => {
      if (data) {
        fetchDetailForData(data);
      } else if (orderId) {
        fetchDetail(orderId);
      }
    };

    fetchDelayed ? setTimeout(processFetch, 400) : processFetch();
  }, [orderId, data, fetchDelayed]);

  if (loading) {
    return <Spinner />;
  }

  return (
    <>
      <h3>{order.name}</h3>
      <OrderSummaryPanel order={order} />

      <ContractEditor form={props.form} order={order} contract={contract} />

      {getFieldDecorator('id', { initialValue: contract.id })(
        <Input type="hidden" />
      )}
      {getFieldDecorator('order_id', { initialValue: order.id })(
        <Input type="hidden" />
      )}
      {getFieldDecorator('company_id', { initialValue: order.comp_id })(
        <Input type="hidden" />
      )}
      {getFieldDecorator('out', { initialValue: false })(
        <Input type="hidden" />
      )}
    </>
  );
};

export default OrderInEditor;
