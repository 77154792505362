import React, { useState, useEffect } from 'react';
import { observer, inject } from 'mobx-react';
import {
  Table,
  Button,
  Input,
  Row,
  Col,
  Icon,
  Modal,
  Form,
  Drawer,
  Checkbox,
} from 'antd';
import { User } from '../../models/User';
import { UserStore } from '../../stores/userStore';
import { RouteComponentProps } from 'react-router';
import Util from '../../helpers/Util';
import { FormComponentProps } from 'antd/lib/form';
import { Department } from '../../models/Org';
import { PaginationConfig } from 'antd/lib/table';
import dataStore from '../../stores/dataStore';
import UserCreateForm from '../../components/hr/UserCreateForm';
import UserDetail from '../../components/hr/UserDetail';
import LinkButton from '../../components/general/LinkButton';
import FCStringCodeSelect from '../../components/formcomps/FCStringCodeSelect';
import DeptTree from '../../components/general/DeptTree';

interface Props extends FormComponentProps, RouteComponentProps {
  userStore?: UserStore;
}

const UserAuthByMenus: React.FC<Props> = (props) => {
  const params = new URLSearchParams(props.location.search);
  const page = Number(params.get('page')) || 1;
  const dept_id = Number(params.get('dept_id')) || 0;
  const keyword = params.get('keyword') || '';
  const retired = params.get('retired') || '';

  const userStore = props.userStore!;
  const [items, setItems] = useState<User[]>([]);
  const { getFieldDecorator, setFieldsValue } = props.form;
  const [editorVisible, setEditorVisible] = useState(false);
  const [submitting, setSubmitting] = useState(false);

  const columns = [
    {
      title: '사번',
      dataIndex: 'id',
      align: 'right' as 'right', // https://github.com/Microsoft/TypeScript/issues/11465
    },
    {
      title: '이름',
      dataIndex: 'name',
      render: (text: string, record: User) => (
        <LinkButton
          onClick={(e) => handleItemClick(record)}
          style={record.retire_date ? { color: '#999' } : undefined}
        >
          {record.name}
        </LinkButton>
      ),
    },
    {
      title: '부서',
      dataIndex: 'dept_id',
      render: (text: string, record: User) => record.deptName,
    },
    {
      title: '직위',
      dataIndex: 'rank_id',
      render: (text: string, record: User) => record.rankName,
    },
    {
      title: '설계',
      width: '8%',
      align: 'center' as 'center',
      render: (text: string, record: User) =>
        renderCheckBox(record, 'acl_planning'),
    },
    {
      title: '사업',
      width: '8%',
      align: 'center' as 'center',
      render: (text: string, record: User) =>
        renderCheckBox(record, 'acl_business'),
    },
    {
      title: '개발',
      width: '8%',
      align: 'center' as 'center',
      render: (text: string, record: User) =>
        renderCheckBox(record, 'acl_development'),
    },
    {
      title: '마케팅',
      width: '8%',
      align: 'center' as 'center',
      render: (text: string, record: User) =>
        renderCheckBox(record, 'acl_marketing'),
    },
    {
      title: '해외법인',
      width: '8%',
      align: 'center' as 'center',
      render: (text: string, record: User) =>
        renderCheckBox(record, 'acl_oversea'),
    },
    {
      title: '경영',
      width: '8%',
      align: 'center' as 'center',
      render: (text: string, record: User) =>
        renderCheckBox(record, 'acl_management'),
    },
    {
      title: '경영보고',
      width: '8%',
      align: 'center' as 'center',
      render: (text: string, record: User) =>
        renderCheckBox(record, 'acl_report'),
    },
    {
      title: '프로젝트',
      width: '8%',
      align: 'center' as 'center',
      render: (text: string, record: User) =>
        renderCheckBox(record, 'acl_project'),
    },
  ];

  const renderCheckBox = (record: User, fieldName: string) => {
    return (
      <>
        {getFieldDecorator(`${fieldName}_${record.id}`, {
          valuePropName: 'checked',
          initialValue: !!record[fieldName],
        })(
          <Checkbox
            onChange={(e) => {
              let obj: any = {};
              obj[fieldName] = e.target.checked;
              onChangeCheckItem(record, obj);
            }}
          />
        )}
      </>
    );
  };

  const onChangeCheckItem = async (item: User, data: any) => {
    try {
      setSubmitting(true);
      await userStore.updateUser(item, data, false);
    } catch (err) {
      Util.showError(err);
    } finally {
      setSubmitting(false);
    }
  };

  const [currentItem, setCurrentItem] = useState<User>(new User());
  const [detailVisible, setDetailVisible] = useState(false);
  const onCloseDetail = () => {
    setDetailVisible(false);
    // if (paybookDirtyRef.current) {
    //   paybookDirtyRef.current = false;
    //   setRefreshTrigger(v => v + 1);
    // }
  };

  const handleItemClick = (item: User) => {
    // props.history.push('/management/support/users/' + item.id);
    setCurrentItem(item);
    setDetailVisible(true);
  };

  const handleAddButtonClick = () => {
    setEditorVisible(true);
  };

  const onSubmitSearchForm = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    props.form.validateFields((err, values) => {
      if (err) return;
      pushHistory(1, dept_id, values.keyword || '', values.retired || '');
    });
  };

  const onChangeTable = (pagination: PaginationConfig) => {
    pushHistory(pagination.current || 1, dept_id, keyword, retired);
  };

  const onSelectDept = (item: Department) => {
    pushHistory(1, item.id, keyword, retired);
  };

  const pushHistory = (
    page: number,
    dept_id: number,
    keyword: string,
    retired: string
  ) => {
    props.history.push(
      `${props.location.pathname}?dept_id=${dept_id}&page=${page}&keyword=${keyword}&retired=${retired}`
    );
  };

  // useEffect(() => {
  //   let users = searchOption.dept
  //     ? userStore.usersInDept(searchOption.dept).slice()
  //     : userStore.users.slice();

  //   if (searchOption.keyword !== undefined) {
  //     const keyword = searchOption.keyword;
  //     users = users.filter(
  //       e => e.name.indexOf(keyword) >= 0 || e.email.indexOf(keyword) >= 0
  //     );
  //   }

  //   if (searchOption.retired !== undefined) {
  //     const retired = searchOption.retired;
  //     users = users.filter(e => retired === e.retireState);
  //   }

  //   // setCurrentPage(1);
  //   setItems(users);
  // }, [searchOption, userStore.users]);

  useEffect(() => {
    let users: User[] = [];
    if (dept_id && dept_id > 0) {
      const dept = dataStore.findDepartment(dept_id);
      if (dept) {
        users = userStore.usersInDept(dept).slice(0);
      }
    } else {
      users = userStore.users.slice(0);
    }

    if (keyword !== '') {
      users = users.filter(
        (e) => e.name.indexOf(keyword) >= 0 || e.email.indexOf(keyword) >= 0
      );
    }

    if (retired !== '') {
      users = users.filter((e) => retired === e.retireState);
    }

    setFieldsValue({ keyword, retired });
    setItems(users);
  }, [userStore, keyword, retired, dept_id, setFieldsValue]);

  const pagination = {
    pageSize: 15,
    current: page,
    showTotal: (total: number, range: [number, number]) =>
      `전체 ${total}건 중 ${range[0]}-${range[1]}`,
  };

  return (
    <>
      <h2>메뉴별 권한설정</h2>

      <Form
        className="hl-form"
        layout="inline"
        style={{ marginBottom: 7 }}
        onSubmit={onSubmitSearchForm}
      >
        <Row type="flex" justify="space-between">
          <Col>
            <Form.Item>
              {getFieldDecorator('keyword')(
                <Input.Search
                  placeholder="검색어"
                  allowClear={true}
                  style={{ width: 200 }}
                />
              )}
            </Form.Item>
            <Form.Item>
              <FCStringCodeSelect
                form={props.form}
                name="retired"
                allowClear={true}
                placeholder="재직구분"
                items={['재직', '퇴사']}
                style={{ width: '120px' }}
              />
            </Form.Item>
            <Form.Item>
              <Button type="primary" htmlType="submit">
                검색
              </Button>
            </Form.Item>
          </Col>
          <Col>
            <Button onClick={handleAddButtonClick}>
              <Icon type="plus" />
              사용자 등록
            </Button>
          </Col>
        </Row>
      </Form>

      <Row gutter={10}>
        <Col span={5}>
          <DeptTree onSelectItem={onSelectDept} />
        </Col>
        <Col span={19}>
          <Table
            columns={columns}
            dataSource={items}
            rowKey="id"
            onChange={onChangeTable}
            pagination={pagination}
            loading={submitting}
            bordered={true}
            size="middle"
          />
        </Col>
      </Row>

      <Modal
        title="사용자 등록"
        centered
        visible={editorVisible}
        footer={null}
        onCancel={() => setEditorVisible(false)}
        destroyOnClose={true}
      >
        <UserCreateForm onSuccess={() => setEditorVisible(false)} />
      </Modal>

      <Drawer
        closable={false}
        placement="right"
        width={'85%'}
        visible={detailVisible}
        onClose={onCloseDetail}
        destroyOnClose={true}
      >
        <UserDetail id={currentItem.id} />
      </Drawer>
    </>
  );
};

const _UserAuthByMenus = Form.create<Props>()(UserAuthByMenus);
export default inject('userStore')(observer(_UserAuthByMenus));
