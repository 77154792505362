import React, { useState, useEffect } from 'react';
import { observer, inject } from 'mobx-react';
import { Select } from 'antd';
import { UserStore } from '../../stores/userStore';
import { User } from '../../models/User';
const { Option } = Select;

interface Props {
  values?: number[];
  userStore?: UserStore;
  onChange?: (values: number[]) => void;
}

const MultiUserSelect = (props: Props) => {
  const userStore = props.userStore!;

  const [values, setValues] = useState<number[]>([]);
  useEffect(() => {
    setValues(props.values || []);
  }, [props.values]);

  const [users, setUsers] = useState<User[]>([]);
  useEffect(() => {
    // NOTE: 특정 부서로 선택대상을 제한해야 할 경우 dept값 설정
    const dept = undefined;
    let users = dept
      ? userStore.usersInDept(dept).slice()
      : userStore.users.slice();

    users = users.sort((u1, u2) => {
      if (u1.name > u2.name) return 1;
      if (u1.name < u2.name) return -1;
      return 0;
    });

    setUsers(users);
  }, [userStore]);

  const children = users.map(user => {
    return (
      <Option key={user.id} value={user.id}>
        {user.name} {user.rankName}
      </Option>
    );
  });

  function handleChange(value: any) {
    let changedValues: number[] = [];
    if (String(value) !== '') {
      const selectedIds = String(value).split(',');
      changedValues = selectedIds.map((stringId: string) => Number(stringId));
    }

    setValues(changedValues);
    if (props.onChange) {
      props.onChange(changedValues);
    }
  }

  return (
    <>
      <Select
        mode="multiple"
        placeholder="참조자"
        value={values}
        onChange={handleChange}
        allowClear={true}
      >
        {children}
      </Select>
    </>
  );
};

export default inject('userStore')(observer(MultiUserSelect));
