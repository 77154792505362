import Service from './Index';

const AppService = {
  fetchNotifications: (limit: number, page: number, type?: number) => {
    const offset = (page - 1) * limit;
    return Service.get('/app/notifications', { limit, offset, type });
  },
  fetchPrimaryNotifications: (limit: number, page: number) => {
    const offset = (page - 1) * limit;
    return Service.get('/app/notifications_main', { limit, offset });
  },

  fetchStoreValues: (names: string[]) => {
    return Service.get('/app/store_values', { names: names.join(',') });
  },

  updateStoreValues: (name: string, value: any) => {
    return Service.put('/app/store_values', { name, value });
  },

  fetchDeptValues: (dept_id: number, names: string[]) => {
    return Service.get('/app/dept_values', { dept_id, names: names.join(',') });
  },

  updateDeptValues: (dept_id: number, name: string, value: any) => {
    return Service.put('/app/dept_values', { dept_id, name, value });
  }
};

export default AppService;
