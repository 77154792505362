import React, { useState, useEffect } from 'react';
import { Form, Input, Button, Table, Icon } from 'antd';
import { FormComponentProps } from 'antd/lib/form';
import { User } from '../../../models/User';
import UserService from '../../../services/UserService';
import Util from '../../../helpers/Util';
import moment from 'moment';
import LinkButton from '../../general/LinkButton';
import FIDatePicker from '../../formcomps/FIDatePicker';
import InlineItem from '../../formcomps/InlineItem';

interface Props extends FormComponentProps {
  user: User;
}

const _UserCareers = (props: Props) => {
  const { getFieldDecorator } = props.form;
  const { user } = props;

  const [items, setItems] = useState<any>([]);
  const [nextId, setNextId] = useState(1);

  useEffect(() => {
    //setLoading(true);
    UserService.fetchCardItems('careers', user.id)
      .then(result => {
        const results = result.results.map((result: any) => {
          return {
            ...result,
            begin_date: result.begin_date
              ? moment(result.begin_date)
              : undefined,
            end_date: result.end_date ? moment(result.end_date) : undefined,
            id: result.seq
          };
        });
        setNextId(results.length + 1);
        setItems(results);
        //setLoading(false);
      })
      .catch(error => Util.showError(error));
  }, [user]);

  const handleAddButtonClick = () => {
    let newItems = items.concat({ id: nextId });
    setNextId(nextId + 1);
    setItems(newItems);
  };

  const handleDeleteItem = (id: number) => {
    let newItems = items.filter((item: any) => item.id !== id);
    setItems(newItems);
  };

  const [submitting, setSubmitting] = useState(false);

  const handleSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    props.form.validateFields((err, values) => {
      if (err) return;

      let itemOrder = 1;
      const convertedValues = items.map((item: any) => {
        return {
          user_id: user.id,
          seq: itemOrder++,
          begin_date: values['begin_date' + item.id],
          end_date: values['end_date' + item.id],
          office: values['office' + item.id],
          dept: values['dept' + item.id],
          rank: values['rank' + item.id],
          job: values['job' + item.id]
        };
      });
      updateItem(convertedValues, user);
    });
  };

  const updateItem = async (values: any, item: User) => {
    try {
      setSubmitting(true);
      UserService.updateCardItems('careers', item.id, values);
      Util.showSuccess('업데이트 되었습니다.');
    } catch (err) {
      Util.showError(err);
    } finally {
      setSubmitting(false);
    }
  };

  const columns = [
    {
      title: '기간',
      dataIndex: 'begin_date',
      width: '40%',
      render: (text: string, record: any) => {
        return (
          <>
            <InlineItem>
              <FIDatePicker
                form={props.form}
                name={'begin_date' + record.id}
                initialValue={
                  record.begin_date && Util.formatDate(record.begin_date)
                }
              />
            </InlineItem>
            ~{' '}
            <InlineItem>
              <FIDatePicker
                form={props.form}
                name={'end_date' + record.id}
                initialValue={
                  record.end_date && Util.formatDate(record.end_date)
                }
              />
            </InlineItem>
          </>
        );
      }
    },
    {
      title: '근무처',
      dataIndex: 'office',
      render: (text: string, record: any) => {
        return (
          <Form.Item>
            {getFieldDecorator('office' + record.id, {
              initialValue: record.office
            })(<Input />)}
          </Form.Item>
        );
      }
    },
    {
      title: '부서명',
      dataIndex: 'dept',
      render: (text: string, record: any) => {
        return (
          <Form.Item>
            {getFieldDecorator('dept' + record.id, {
              initialValue: record.dept
            })(<Input />)}
          </Form.Item>
        );
      }
    },
    {
      title: '직급',
      dataIndex: 'rank',
      render: (text: string, record: any) => {
        return (
          <Form.Item>
            {getFieldDecorator('rank' + record.id, {
              initialValue: record.rank
            })(<Input />)}
          </Form.Item>
        );
      }
    },
    {
      title: '담당업무',
      dataIndex: 'job',
      render: (text: string, record: any) => {
        return (
          <Form.Item>
            {getFieldDecorator('job' + record.id, {
              initialValue: record.job
            })(<Input />)}
          </Form.Item>
        );
      }
    },
    {
      render: (text: string, record: any) => {
        return (
          <LinkButton onClick={e => handleDeleteItem(record.id)}>
            <Icon type="close" />
          </LinkButton>
        );
      }
    }
  ];

  return (
    <>
      <Form onSubmit={handleSubmit}>
        <Table
          bordered
          size="middle"
          columns={columns}
          dataSource={items}
          pagination={false}
          rowKey="id"
        />
        <Button onClick={handleAddButtonClick} style={{ marginTop: 10 }}>
          <Icon type="plus" />열 추가
        </Button>{' '}
        <Button type="primary" htmlType="submit" disabled={submitting}>
          저 장
        </Button>
      </Form>
    </>
  );
};

const UserCareers = Form.create<Props>()(_UserCareers);
export default UserCareers;
