import React, { useState, useEffect, useRef } from 'react';
import { RouteComponentProps, withRouter } from 'react-router';
import {
  Table,
  Button,
  Row,
  Col,
  Icon,
  Input,
  Form,
  InputNumber,
  Drawer,
  Popconfirm
} from 'antd';
import { Occasion } from '../../models/Occasion';
import LinkButton from '../../components/general/LinkButton';
import { FormComponentProps } from 'antd/lib/form';
import OccasionEditor from './OccasionEditor';
import OccasionService, {
  OccasionSearchOption
} from '../../services/OccasionService';
import Util from '../../helpers/Util';
import { PaginationConfig } from 'antd/lib/table';
import AButton from '../../components/general/AButton';

interface Props extends FormComponentProps, RouteComponentProps {}

const OccasionList: React.FC<Props> = props => {
  const [items, setItems] = useState<Occasion[]>([]);
  const [searchOption, setSearchOption] = useState<OccasionSearchOption>({});
  const [totalCount, setTotalCount] = useState(0);
  const [currentPage, setCurrentPage] = useState(1);
  const [loading, setLoading] = useState(false);
  const [refreshTrigger, setRefreshTrigger] = useState(1);
  const { getFieldDecorator } = props.form;

  const pagination = {
    pageSize: 15,
    current: currentPage,
    total: totalCount,
    showTotal: (total: number, range: [number, number]) =>
      `전체 ${total}건 중 ${range[0]}-${range[1]}`
  };

  useEffect(() => {
    const fetchItems = async () => {
      setLoading(true);
      try {
        const result = await OccasionService.fetchItems(
          pagination.pageSize,
          currentPage,
          searchOption
        );
        setTotalCount(result.total_count);
        setItems(Occasion.fromJsonArray(result.items));
      } catch (error) {
        Util.showError(error);
      } finally {
        setLoading(false);
      }
    };
    fetchItems();
  }, [currentPage, searchOption, pagination.pageSize, refreshTrigger]);

  const columns = [
    {
      title: 'No',
      dataIndex: 'id',
      width: '80px',
      align: 'right' as 'right'
    },
    {
      title: '행사명',
      dataIndex: 'title',
      ellipsis: true,
      render: (text: string, record: Occasion) => {
        return (
          <>
            <AButton onClick={e => onClickItem(record)}>{text}</AButton>
            {record.comment_count > 0 && ` (${record.comment_count})`}
          </>
        );
      }
    },
    {
      title: '일시',
      dataIndex: 'date',
      width: '150px',
      render: (text: string, record: Occasion) =>
        Util.formatDateTimeHHMM(record.date)
    },
    {
      title: '장소',
      dataIndex: 'place',
      ellipsis: true
    },
    // {
    //   title: '행사개요',
    //   dataIndex: 'summary',
    //   ellipsis: true
    // },
    {
      title: '참석대상',
      dataIndex: 'attendees',
      ellipsis: true
    },
    {
      width: '90px',
      render: (text: string, record: Occasion) => {
        return (
          <>
            <LinkButton onClick={e => onClickEditButton(record)}>
              수정
            </LinkButton>{' '}
            |{' '}
            <Popconfirm
              title="정말 삭제하시겠습니까?"
              onConfirm={e => onClickDeleteButton(record)}
            >
              <LinkButton>삭제</LinkButton>
            </Popconfirm>
          </>
        );
      }
    }
  ];

  const onChangeTable = (pagination: PaginationConfig) => {
    setCurrentPage(pagination.current || 1);
  };

  const onSubmitSearchForm = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    props.form.validateFields((err, values) => {
      if (err) return;
      setSearchOption(values);
      setCurrentPage(1);
    });
  };

  const [editorVisible, setEditorVisible] = useState(false);
  const itemIdRef = useRef<number>(0);

  const onClickItem = (item: Occasion) => {
    props.history.push(`./occasions/view?id=${item.id}`);
  };

  const onClickAddButton = () => {
    itemIdRef.current = 0;
    setEditorVisible(true);
  };

  const onClickEditButton = (item: Occasion) => {
    itemIdRef.current = item.id;
    setEditorVisible(true);
  };

  const onClickDeleteButton = async (item: Occasion) => {
    try {
      await OccasionService.delete(item.id);
      Util.showSuccess('삭제되었습니다.');
      setRefreshTrigger(v => v + 1);
    } catch (err) {
      Util.showError(err);
    }
  };

  const onCloseEditor = () => {
    setEditorVisible(false);
  };

  const onSuccessEditing = () => {
    setRefreshTrigger(v => v + 1);
    setEditorVisible(false);
  };

  return (
    <>
      <h2>전사 일정관리</h2>

      <Form
        className="hl-form"
        layout="inline"
        style={{ marginBottom: 7 }}
        onSubmit={onSubmitSearchForm}
      >
        <Row type="flex" justify="space-between">
          <Col>
            <Form.Item>
              {getFieldDecorator('keyword')(
                <Input.Search
                  placeholder="검색어"
                  allowClear={true}
                  style={{ width: 150 }}
                />
              )}
            </Form.Item>

            <Form.Item>
              {getFieldDecorator('year')(
                <InputNumber placeholder="년도" style={{ width: 100 }} />
              )}
            </Form.Item>

            <Form.Item>
              <Button type="primary" htmlType="submit">
                검색
              </Button>
            </Form.Item>
          </Col>

          <Col>
            <Button onClick={onClickAddButton}>
              <Icon type="plus" />
              행사 등록
            </Button>
          </Col>
        </Row>
      </Form>

      <Table
        columns={columns}
        dataSource={items}
        pagination={pagination}
        onChange={onChangeTable}
        rowKey="id"
        loading={loading}
        bordered={true}
        size="middle"
      />

      <Drawer
        closable={false}
        placement="right"
        width={1200}
        visible={editorVisible}
        onClose={onCloseEditor}
        destroyOnClose={true}
      >
        <OccasionEditor
          id={itemIdRef.current}
          onSuccessEditing={onSuccessEditing}
        />
      </Drawer>
    </>
  );
};

export default withRouter(Form.create<Props>()(OccasionList));
