import React, { useState } from 'react';
import { Button, Form } from 'antd';
import Util from '../../helpers/Util';
import { FormComponentProps } from 'antd/lib/form';
import { File } from '../../models/File';
import FCFileInput from '../formcomps/FCFileInput';
import FileService from '../../services/FileService';

interface Props extends FormComponentProps {
  file: File;
  onSuccess?: () => void;
}

const ArchiveFileRevUploader: React.FC<Props> = props => {
  const { file } = props;
  const [submitting, setSubmitting] = useState(false);
  const [uploading, setUploading] = useState(false);

  const onClickSubmitButton = () => {
    props.form.validateFields((err, values) => {
      if (err) return;
      if (values.file_ids.length !== 1) {
        Util.showError('버전 추가시 한 개 파일만 추가할수 있습니다.');
        return;
      }
      processItem({
        file_ids: values.file_ids,
        archive_id: file.archive_id,
        order_id: file.order_id,
        tags: file.tags
      });
    });
  };

  const processItem = async (values: any) => {
    try {
      setSubmitting(true);
      await FileService.createArchiveFile(values);
      await FileService.addFileRevision(file.group_id, Number(values.file_ids));
      Util.showSuccess('등록되었습니다.');
      if (props.onSuccess) props.onSuccess();
    } catch (err) {
      Util.showError(err);
    } finally {
      setSubmitting(false);
    }
  };

  const formItemLayout = {
    labelCol: { span: 4 },
    wrapperCol: { span: 16 }
  };

  return (
    <Form {...formItemLayout}>
      <Form.Item label="파일 첨부">
        <FCFileInput
          form={props.form}
          single
          onChangeUploading={setUploading}
        />
      </Form.Item>

      <Form.Item wrapperCol={{ span: 12, offset: 4 }}>
        <Button
          type="primary"
          disabled={uploading || submitting}
          onClick={onClickSubmitButton}
        >
          등 록
        </Button>
      </Form.Item>
    </Form>
  );
};

export default Form.create<Props>()(ArchiveFileRevUploader);
