import React from 'react';
import { Route, Switch, Redirect } from 'react-router-dom';
import UnderConst from '../../components/UnderConst';
import Dashboard from '../projects/common/Dashboard';
import OrderLedger from '../projects/common/OrderLedger';
import OrderDetail from '../projects/orders/OrderDetail';
import { OrderState } from '../../models/Order';
import DeptCodeList from '../../components/general/DeptCodeList';
import MyEducationList from '../works/educations/MyEducationList';
import EducationSchedules from '../works/educations/EducationSchedules';
import ArchiveGroupBrowser from '../../components/general/ArchiveGroupBrowser';
import CollectPlan from '../management/finance/CollectPlan';
import OrderEADocList from '../projects/orders/OrderEADocList';
import EADocDrafter from '../eas/EADocDrafter';
import OrderList from '../projects/common/OrderList';

interface Props {}

const Index: React.FC<Props> = props => {
  const menu = 'business';
  const submenu = 'cs';
  const deptId = 33;

  return (
    <Switch>
      <Route path="/business/cs/uc" component={UnderConst} />
      <Route path="/business/cs/archive" component={ArchiveGroupBrowser} />

      <Route path="/business/cs" exact>
        <Redirect to="/business/cs/dashboard" />
      </Route>

      <Route path="/business/cs/dashboard">
        <Dashboard menu={menu} deptId={deptId} hideDeptSelector />
      </Route>
      <Route path="/business/cs/ongoing">
        <OrderLedger
          title="진행 프로젝트"
          menu={menu}
          deptId={deptId}
          hideDeptSelector
          state={OrderState.ongoing}
        />
      </Route>

      <Route path="/business/cs/eadocs/ord">
        <OrderEADocList
          menu={menu}
          submenu={submenu}
          deptId={deptId}
          hideDeptSelector
        />
      </Route>
      <Route path="/business/cs/draft/ord">
        <EADocDrafter title="수주정보보고서" refTarget="ord" />
      </Route>
      <Route path="/business/cs/orders/view" component={OrderDetail} />
      <Route path="/business/cs/orders">
        <OrderList deptId={deptId} hideDeptSelector />
      </Route>
      <Route path="/business/cs/ordins">
        <OrderLedger
          title="수주견적"
          menu={menu}
          deptId={deptId}
          hideDeptSelector
          target="ordin"
        />
      </Route>
      <Route path="/business/cs/ctrins">
        <OrderLedger
          title="수주계약"
          menu={menu}
          deptId={deptId}
          hideDeptSelector
          target="ctrin"
        />
      </Route>
      <Route path="/business/cs/ordouts">
        <OrderLedger
          title="외주실행"
          menu={menu}
          deptId={deptId}
          hideDeptSelector
          target="ordout"
        />
      </Route>
      <Route path="/business/cs/ctrouts">
        <OrderLedger
          title="외주계약"
          menu={menu}
          deptId={deptId}
          hideDeptSelector
          target="ctrout"
        />
      </Route>

      <Route path="/business/cs/collect">
        <CollectPlan menu="business/cs" hideCorpSelect />
      </Route>

      <Route path="/business/cs/settings/stages">
        <DeptCodeList
          menu={menu}
          deptId={deptId}
          title="부서별 진행단계"
          fieldName="order_stages"
        />
      </Route>
      <Route path="/business/cs/settings/ctypes">
        <DeptCodeList
          menu={menu}
          deptId={deptId}
          title="부서별 수금구분"
          fieldName="cp_terms"
        />
      </Route>

      <Route path="/business/cs/myedu" component={MyEducationList} />
      <Route path="/business/cs/edu" component={EducationSchedules} />
    </Switch>
  );
};

export default Index;
