import React, { useState, useEffect } from 'react';
import {
  Form,
  Input,
  Button,
  Select,
  DatePicker,
  Radio,
  Icon,
  Col,
  Row,
  Table
} from 'antd';
import { FormComponentProps } from 'antd/lib/form';
import { TripAccount, Trip } from '../../../models/Trip';
import FCTextInput from '../../../components/formcomps/FCTextInput';
import FCUserInput from '../../../components/formcomps/FCUserInput';
import FREditor from '../../../components/general/FREditor';
import LinkButton from '../../../components/general/LinkButton';
import FCNumberInput from '../../../components/formcomps/FCNumberInput';
import _ from 'lodash';
import { SelectValue } from 'antd/lib/select';
import { inject, observer } from 'mobx-react';
import { AuthStore } from '../../../stores/authStore';

interface Props extends FormComponentProps {
  authStore?: AuthStore;
  id?: number;
  data?: any;
}

const TRIP_TYPES = ['일비', '교통비', '식비', '숙박비', '기타'];

const TripApplyEditor: React.FC<Props> = props => {
  const authStore = props.authStore!;
  const { getFieldDecorator, setFieldsValue } = props.form;
  const [item, setItem] = useState<Trip>(new Trip());
  const [items, setItems] = useState<TripAccount[]>([]);
  const [totalItem] = useState<TripAccount>(new TripAccount(0));
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const fetchDetail = async (id: number) => {
      // setLoading(true);
      // try {
      //   const data = await VacationService.fetchItem(id);
      //   configDetail(data.vacation);
      // } catch (error) {
      //   Util.showError(error);
      // } finally {
      //   setLoading(false);
      // }
    };

    const configDetail = (data: any) => {
      const trip = Trip.fromJson(data);
      setItem(trip);
      setItems(trip.items);
      setFieldsValue({
        trip_title: trip.trip_title,
        reason: trip.reason,
        place: trip.place,
        begin_date: trip.begin_date,
        end_date: trip.end_date
      });
    };

    if (props.data) {
      configDetail(props.data);
      setLoading(false);
    } else if (props.id) {
      fetchDetail(props.id);
    } else {
      // 불러오기가 아닐 경우
      // 신청인 정보 현재 유저로 기본 설정
      const trip = new Trip();
      trip.user = authStore.user;
      setItem(trip);

      // 기본 예산항목들 설정
      const items = TRIP_TYPES.map((e, index) => {
        let item = new TripAccount(index + 1);
        item.type = e;
        return item;
      });
      setItems(items);
      setLoading(false);
    }
  }, [props.data, props.id, setFieldsValue, authStore.user]);

  useEffect(() => {
    const sum = _.sumBy(items, 'request_price');
    setFieldsValue({ request_price_0: sum, items });
  }, [items, setFieldsValue]);

  let columns = [
    {
      dataIndex: 'index',
      render: (text: string, record: TripAccount, index: number) => {
        return (
          <div style={{ width: '20px', textAlign: 'right' }}>
            {record.id === 0 ? '' : index + 1 + '.'}
          </div>
        );
      }
    },
    {
      title: '구분',
      dataIndex: 'type',
      width: '120px',
      render: (text: string, record: TripAccount) => {
        if (record.id === 0) {
          return <div style={{ textAlign: 'center' }}>합 계</div>;
        }
        return (
          <Form.Item>
            {getFieldDecorator(`type_${record.id}`, {
              initialValue: record.type
            })(
              <Select
                style={{ width: '110px' }}
                onChange={value => onChangeItemType(record, value)}
              >
                {TRIP_TYPES.map(e => (
                  <Select.Option value={e} key={e}>
                    {e}
                  </Select.Option>
                ))}
              </Select>
            )}
          </Form.Item>
        );
      }
    },
    {
      title: '금액',
      dataIndex: 'request_price',
      width: 110,
      render: (text: string, record: TripAccount) => {
        return (
          <Form.Item>
            <FCNumberInput
              form={props.form}
              name={`request_price_${record.id}`}
              disabled={record.id === 0}
              options={{ initialValue: record.request_price }}
              onEnter={value => onEnterItemValue(record, value)}
            />
          </Form.Item>
        );
      }
    },
    {
      title: '예산 근거',
      dataIndex: 'request_base',
      width: '70%',
      render: (text: string, record: TripAccount) => {
        if (record.id === 0) {
          return <></>;
        }
        return (
          <Form.Item>
            <FCTextInput
              form={props.form}
              name={`request_base_${record.id}`}
              options={{ initialValue: record.request_base }}
              onEnter={value => onEnterItemBase(record, value)}
            />
          </Form.Item>
        );
      }
    },
    {
      width: 40,
      render: (text: string, record: TripAccount) => {
        if (record.id === 0) {
          return <></>;
        }
        return (
          <LinkButton onClick={e => onClickDeleteItem(record.id)}>
            <Icon type="close" />
          </LinkButton>
        );
      }
    }
  ];

  const onClickAddItem = () => {
    let newItems = items.concat(new TripAccount());
    setItems(newItems);
  };

  const onClickDeleteItem = (id: number) => {
    let newItems = items.filter((item: any) => item.id !== id);
    setItems(newItems);
  };

  const onChangeItemType = (item: TripAccount, value: SelectValue) => {
    const updatedItems = items.map(el => {
      return el.id === item.id
        ? TripAccount.fromJson({ ...item, type: value })
        : el;
    });

    setItems(updatedItems);
  };

  const onEnterItemValue = (
    item: TripAccount,
    value: number | string | undefined
  ) => {
    if (value === undefined) return;

    const updatedItems = items.map(el => {
      return el.id === item.id
        ? TripAccount.fromJson({ ...item, request_price: value })
        : el;
    });

    setItems(updatedItems);
  };

  const onEnterItemBase = (
    item: TripAccount,
    value: number | string | undefined
  ) => {
    if (value === undefined) return;

    const updatedItems = items.map(el => {
      return el.id === item.id
        ? TripAccount.fromJson({ ...item, request_base: value })
        : el;
    });

    setItems(updatedItems);
  };

  const formItemLayout = {
    labelCol: { span: 3 },
    wrapperCol: { span: 20 }
  };

  return (
    <>
      <Form {...formItemLayout}>
        <h3>개요</h3>
        <div className="hl-form" style={{ marginBottom: '10px' }}>
          {getFieldDecorator('user', {
            initialValue: { id: item.user.id, name: item.user.name }
          })(<Input type="hidden" />)}
          {getFieldDecorator('items', { initialValue: items })(
            <Input type="hidden" />
          )}
          <Form.Item label="구분">
            {getFieldDecorator('type', { initialValue: item.type })(
              <Radio.Group>
                <Radio value={1}>출장</Radio>
                <Radio value={2}>외근</Radio>
              </Radio.Group>
            )}
          </Form.Item>
          <Form.Item label="출장/외근명">
            {getFieldDecorator('trip_title', {
              rules: [
                {
                  required: true,
                  message: '출장/외근명을 입력해주세요.'
                },
                {
                  whitespace: true,
                  message: '출장/외근명을 입력해주세요.'
                }
              ]
            })(<Input autoFocus />)}
          </Form.Item>
          <Form.Item label="출장사유">
            {getFieldDecorator('reason')(<Input />)}
          </Form.Item>
          <Form.Item label="출장자">
            {!loading && (
              <FCUserInput
                form={props.form}
                name="user_ids"
                objName="users"
                items={item.users}
              />
            )}
          </Form.Item>
          <Form.Item label="출장지">
            {getFieldDecorator('place')(<Input />)}
          </Form.Item>
          <Form.Item label="출장일시">
            <Form.Item style={{ display: 'inline-block' }}>
              {getFieldDecorator('begin_date', {
                rules: [
                  { required: true, message: '출장 시작일시를 입력해주세요' },
                  { type: 'object' }
                ]
              })(
                <DatePicker
                  format="YYYY-MM-DD HH:mm"
                  showTime={{
                    format: 'HH:mm'
                  }}
                />
              )}
            </Form.Item>{' '}
            ~{' '}
            <Form.Item style={{ display: 'inline-block' }}>
              {getFieldDecorator('end_date', {
                rules: [
                  { required: true, message: '출장 종료일시를 입력해주세요' },
                  { type: 'object' }
                ]
              })(
                <DatePicker
                  format="YYYY-MM-DD HH:mm"
                  showTime={{
                    format: 'HH:mm'
                  }}
                />
              )}
            </Form.Item>
          </Form.Item>
        </div>

        <Row type="flex" justify="space-between">
          <Col>
            <h3>예산</h3>
          </Col>
          <Col>
            <Button onClick={onClickAddItem} style={{ marginBottom: 10 }}>
              <Icon type="plus" />열 추가
            </Button>
          </Col>
        </Row>
        <div className="hl-form" style={{ marginBottom: '10px' }}>
          <Table
            bordered={false}
            size="middle"
            columns={columns}
            dataSource={[...items, totalItem]}
            pagination={false}
            rowKey="id"
          />
        </div>

        {!loading && (
          <>
            <h3>상세일정</h3>
            <FREditor name="content" value={item.content} form={props.form} />
          </>
        )}
      </Form>
    </>
  );
};

export default inject('authStore')(observer(TripApplyEditor));
