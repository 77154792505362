import { MenuItem } from '../../../models/Common';

const menuItems: MenuItem[] = [
  { id: 'files', icon: 'desktop', title: '통합파일검색' },
  { id: '5001', icon: 'desktop', title: '조직관리' },
  { id: '5002', icon: 'desktop', title: '손익관리' },
  { id: '5003', icon: 'desktop', title: '분양관리' },
  { id: '5004', icon: 'desktop', title: '사전마케팅' },
  { id: '5005', icon: 'desktop', title: '본마케팅' },
  { id: '5006', icon: 'desktop', title: '사후마케팅' },
  { id: '5007', icon: 'desktop', title: '정산' },
  { id: '5009', icon: 'desktop', title: '문서,회의록 외' },
  { id: '101', icon: 'desktop', title: '포트폴리오' },
  {
    id: '기안/품의',
    title: '기안/품의',
    icon: 'desktop',
    children: [
      {
        id: 'eas/list?refTarget=pmis&cat=5004',
        title: '사전원가보고'
      },
      {
        id: 'eas/list?refTarget=pmis&cat=5005',
        title: '실행원가보고'
      },
      {
        id: 'eas/list?refTarget=pmis&cat=5007',
        title: '정산보고'
      }
    ]
  }
];

export default menuItems;
