import React, { useState, useEffect } from 'react';
import { DragSource, DropTarget } from 'react-dnd';
import { Table } from 'antd';
import update from 'immutability-helper';
import { TableProps } from 'antd/lib/table';

let dragingIndex = -1;

const BodyRow: React.FC<any> = props => {
  const {
    isOver,
    connectDragSource,
    connectDropTarget,
    moveRow,
    ...restProps
  } = props;
  const style = { ...restProps.style, cursor: 'move' };

  let { className } = restProps;
  if (isOver) {
    if (restProps.index > dragingIndex) {
      className += ' drop-over-downward';
    }
    if (restProps.index < dragingIndex) {
      className += ' drop-over-upward';
    }
  }
  return connectDragSource(
    connectDropTarget(<tr {...restProps} className={className} style={style} />)
  );
};

const rowSource = {
  beginDrag(props: any) {
    dragingIndex = props.index;
    return {
      index: props.index
    };
  }
};

const rowTarget = {
  drop(props: any, monitor: any) {
    const dragIndex = monitor.getItem().index;
    const hoverIndex = props.index;

    // Don't replace items with themselves
    if (dragIndex === hoverIndex) {
      return;
    }

    // Time to actually perform the action
    props.moveRow(dragIndex, hoverIndex);

    // Note: we're mutating the monitor item here!
    // Generally it's better to avoid mutations,
    // but it's good here for the sake of performance
    // to avoid expensive index searches.
    monitor.getItem().index = hoverIndex;
  }
};

const DragableBodyRow = DropTarget('row', rowTarget, (connect, monitor) => ({
  connectDropTarget: connect.dropTarget(),
  isOver: monitor.isOver()
}))(
  DragSource('row', rowSource, connect => ({
    connectDragSource: connect.dragSource()
  }))(BodyRow)
);

// interface Props extends TableProps<any> {
//   onChangeOrder?: (data: any) => void;
// }

/*
  NOTE:
  antd 3.24.0 업데이트이후 TableProps가 WithStore를 extends하여 WithStore의 속성들을 제거해줌
*/

interface Props
  extends Omit<
    TableProps<any>,
    'store' | 'checkboxPropsCache' | 'setCheckboxPropsCache'
  > {
  onChangeOrder?: (data: any) => void;
}

const DragableTable: React.FC<Props> = props => {
  const { columns, dataSource, onChangeOrder, ...restProps } = props;

  const [state, setState] = useState({
    data: dataSource
  });

  useEffect(() => {
    setState({ data: dataSource });
  }, [dataSource]);

  const components: any = {
    body: {
      row: DragableBodyRow
    }
  };

  const moveRow = (dragIndex: number, hoverIndex: number) => {
    const { data } = state;
    if (data === undefined) {
      return;
    }
    const dragRow = data[dragIndex];

    const newState = update(state, {
      data: {
        $splice: [
          [dragIndex, 1],
          [hoverIndex, 0, dragRow]
        ]
      }
    });

    setState(newState);

    if (onChangeOrder) {
      onChangeOrder(newState.data);
    }
  };

  return (
    <Table
      {...restProps}
      columns={columns}
      dataSource={state.data}
      components={components}
      onRow={(record, index) => ({
        index,
        moveRow
      })}
    />
  );
};

export default DragableTable;
