import React, { useState, useEffect } from 'react';
import { Form, Input, Button, Row, Col, DatePicker } from 'antd';
import { FormComponentProps } from 'antd/lib/form';
import { Occasion } from '../../models/Occasion';
import Util from '../../helpers/Util';
import OccasionService from '../../services/OccasionService';
import { AuthStore } from '../../stores/authStore';
import { observer, inject } from 'mobx-react';
import FREditor from '../../components/general/FREditor';
import InlineItem from '../../components/formcomps/InlineItem';
import InlineText from '../../components/formcomps/InlineText';
import FCFileInput from '../../components/formcomps/FCFileInput';

interface Props extends FormComponentProps {
  id?: number;
  authStore?: AuthStore;
  onSuccessEditing?: () => void;
}

const OccasionEditor: React.FC<Props> = props => {
  const itemId = props.id || 0;
  const authStore = props.authStore!;
  const [item, setItem] = useState<Occasion>(new Occasion());
  const [, setLoading] = useState(true);
  const { getFieldDecorator } = props.form;

  useEffect(() => {
    const fetchDetail = async () => {
      setLoading(true);
      try {
        const result = await OccasionService.fetchItem(itemId);
        setItem(Occasion.fromJson(result.occasion));
      } catch (error) {
        Util.showError(error);
      } finally {
        setLoading(false);
      }
    };

    if (itemId > 0) {
      fetchDetail();
    } else {
      setLoading(false);
    }
  }, [itemId]);

  const [submitting, setSubmitting] = useState(false);

  const onClickSubmitButton = () => {
    props.form.validateFields((err, values) => {
      if (err) return;
      processItem(values);
    });
  };

  const processItem = async (values: any) => {
    try {
      setSubmitting(true);
      itemId === 0
        ? await OccasionService.create(values)
        : await OccasionService.update(itemId, values);
      Util.showSuccess('저장되었습니다.');
      setSubmitting(false);
      if (props.onSuccessEditing) props.onSuccessEditing();
    } catch (err) {
      Util.showError(err);
      setSubmitting(false);
    }
  };

  const formItemLayout = {
    labelCol: { span: 4 },
    wrapperCol: { span: 19 }
  };

  console.count('OccasionEditor');

  const user = itemId === 0 ? authStore.user : item.owner;

  return (
    <>
      <h2>행사 {itemId > 0 ? '수정' : '등록'}</h2>
      <Row gutter={12}>
        <Col className="gutter-row" span={20}>
          <Form className="hl-form" {...formItemLayout}>
            <Form.Item label="작성자">
              <InlineItem width="40%">
                <Input value={user.nameWithDeptRank} readOnly />
              </InlineItem>
            </Form.Item>

            <Form.Item label="행사명">
              {getFieldDecorator('title', {
                initialValue: item.title,
                rules: [
                  {
                    required: true,
                    message: '행사명을 입력해주세요.'
                  },
                  {
                    whitespace: true,
                    message: '행사명을 입력해주세요.'
                  }
                ]
              })(<Input placeholder="행사명을 입력해주세요." autoFocus />)}
            </Form.Item>

            <Form.Item label="행사일시">
              <Form.Item style={{ display: 'inline-block' }}>
                {getFieldDecorator('date', {
                  initialValue: item.date,
                  rules: [
                    { required: true, message: '행사일시를 입력해주세요' },
                    { type: 'object' }
                  ]
                })(
                  <DatePicker
                    format="YYYY-MM-DD HH:mm"
                    showTime={{
                      format: 'HH:mm'
                    }}
                  />
                )}
              </Form.Item>
              <InlineText>행사장소: </InlineText>
              <Form.Item style={{ display: 'inline-block' }}>
                {getFieldDecorator('place', {
                  initialValue: item.place
                })(<Input />)}
              </Form.Item>
            </Form.Item>

            <Form.Item label="행사개요">
              {getFieldDecorator('summary', {
                initialValue: item.summary
              })(<Input />)}
            </Form.Item>

            <Form.Item label="참석대상">
              {getFieldDecorator('attendees', {
                initialValue: item.attendees
              })(<Input />)}
            </Form.Item>

            <Form.Item label="행사자료">
              <FCFileInput form={props.form} items={item.files} />
            </Form.Item>
          </Form>

          <div style={{ marginTop: '15px' }}>
            <FREditor name="content" value={item.content} form={props.form} />
          </div>
        </Col>
        <Col className="gutter-row" span={4}>
          <Row gutter={10}>
            <Col>
              <Button
                block
                type="primary"
                htmlType="submit"
                disabled={submitting}
                onClick={onClickSubmitButton}
              >
                {itemId > 0 ? '수 정' : '등 록'}
              </Button>
            </Col>
          </Row>
        </Col>
      </Row>
    </>
  );
};

const _OccasionEditor = Form.create<Props>()(OccasionEditor);
export default inject('authStore')(observer(_OccasionEditor));
