import Service from './Index';

export type OccasionSearchOption = {
  keyword?: string;
  year?: number;
  date?: string;
};

const OccasionService = {
  fetchItems: (limit: number, page: number, options?: OccasionSearchOption) => {
    const offset = (page - 1) * limit;
    return Service.get('/etc/occasions', {
      limit,
      offset,
      ...options
    });
  },
  fetchItem: (id: number) => {
    return Service.get(`/etc/occasions/${id}`);
  },
  fetchItemsForMonth: (year: number, month: number) => {
    return Service.get('/etc/occasions_for_month', {
      year,
      month
    });
  },
  fetchRecents: (date: string) => {
    return Service.get(`/etc/occasions/recents`, { date });
  },
  create: (data: any) => {
    return Service.post('/etc/occasions', data);
  },
  update: (id: number, data: any) => {
    return Service.put(`/etc/occasions/${id}`, data);
  },
  delete: (id: number) => {
    return Service.delete(`/etc/occasions/${id}`);
  },
  read: (id: number) => {
    return Service.put(`/etc/occasions/${id}/read`);
  }
};

export default OccasionService;
