import React, { useState, useEffect } from 'react';
import { Route, Switch } from 'react-router-dom';
import { Layout, PageHeader } from 'antd';
import Sidebar from '../../components/Sidebar';
import Header from '../../components/Header';
import { HLContent } from '../../components/layout/Index';
import { RouteComponentProps, withRouter, Redirect } from 'react-router';
import { MenuItem } from '../../models/Common';
import supportMenus from './menus/support';
import financeMenus from './menus/finance';
import SupportRouter from './SupportRouter';
import FinanceRouter from './FinanceRouter';
import { observer, inject } from 'mobx-react';
import { AuthStore } from '../../stores/authStore';

let defaultHeaderItems: MenuItem[] = [
  {
    id: 'support',
    title: '경영지원',
    icon: 'appstore',
    link: '/management/support',
  },
  {
    id: 'finance',
    title: '재무회계',
    icon: 'appstore',
    link: '/management/finance',
  },
];

let sideItemsInfo: { [key: string]: MenuItem[] } = {
  support: supportMenus,
  finance: financeMenus,
};

interface Props extends RouteComponentProps {
  authStore?: AuthStore;
}

const Index: React.FC<Props> = (props) => {
  const paths = props.location.pathname.split('/');
  const submenu = paths.length >= 3 ? paths[2] : undefined;

  // ------------------------
  // NOTE: 임시 권한 설정
  // 특정 유저에 한해 경영지원 > 교육관리, 홍보관리 메뉴 노출처리
  const authStore = props.authStore!;
  const userId = authStore.user.id;
  useEffect(() => {
    if (userId === 19027 || userId === 19026) {
      defaultHeaderItems = [
        {
          id: 'support',
          title: '경영지원',
          icon: 'appstore',
          link: '/management/support',
        },
      ];
      sideItemsInfo = {
        support: supportMenus.filter(
          (menuItem) => menuItem.id === '교육관리' || menuItem.id === '홍보관리'
        ),
      };
    }
  }, [userId]);
  // ------------------------

  let [sideItems, setSideItems] = useState<MenuItem[]>([]);
  useEffect(() => {
    if (submenu === undefined) return;
    setSideItems(sideItemsInfo[submenu]);
  }, [submenu]);

  // console.log(props.location.pathname);

  // let flatItems: MenuItem[] = [];
  // sideItems.forEach(sideItem => {
  //   flatItems.push(sideItem);
  //   if (sideItem.children) {
  //     flatItems = flatItems.concat(sideItem.children);
  //   }
  // });

  if (!authStore.user.acl_management) {
    return <></>;
  }

  return (
    <>
      <Sidebar menuItems={sideItems}>
        <div style={{ textAlign: 'left', margin: '30px 0px 0px 24px' }}>
          <img src="/images/hanlim-group.png" width="120" alt="한림그룹" />
        </div>
        <PageHeader
          title="경영"
          subTitle="한림그룹"
          style={{ paddingTop: 5, paddingBottom: 20 }}
        />
      </Sidebar>

      <Layout id="wrapper" style={{ marginLeft: 200 }}>
        <Layout.Content>
          <Header menuItems={defaultHeaderItems} />
          <HLContent>
            <Switch>
              <Route path="/management" exact>
                <Redirect to="/management/support/edu_summary" />
              </Route>
              <Route path="/management/support" component={SupportRouter} />
              <Route path="/management/finance" component={FinanceRouter} />
            </Switch>
          </HLContent>
        </Layout.Content>
      </Layout>
    </>
  );
};

// export default withRouter(Index);
export default inject('authStore')(observer(withRouter(Index)));
