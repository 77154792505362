import React from 'react';
import {
  HDescriptions,
  HDRow,
  HDItem
} from '../../../components/general/HDescriptions';
import { Contract } from '../../../models/Order';
import Util from '../../../helpers/Util';

interface Props {
  contract: Contract;
}

const ContractSummaryPanel: React.FC<Props> = props => {
  const { contract } = props;

  return (
    <HDescriptions>
      <HDRow>
        <HDItem label="계약금액" align="right" width="150px">
          {contract.f_price}
        </HDItem>
        <HDItem label="계약일" small>
          {Util.formatDate(contract.contract_date)}
        </HDItem>
      </HDRow>
      <HDRow>
        <HDItem label="과세공급가" align="right">
          {contract.f_tax_price}
        </HDItem>
        <HDItem label="과세율" small>
          {contract.f_tax_rate} %
        </HDItem>
      </HDRow>
      <HDRow>
        <HDItem label="면세공급가" align="right">
          {contract.f_notax_price}
        </HDItem>
        <HDItem label="비고" small rowSpan={3}>
          <div
            dangerouslySetInnerHTML={{
              __html: Util.stringToHTML(contract.note || '')
            }}
          />
        </HDItem>
      </HDRow>
      <HDRow>
        <HDItem label="부가세액" align="right">
          {contract.f_vat}
        </HDItem>
      </HDRow>
      <HDRow>
        <HDItem label="합계금액" align="right">
          {contract.f_total_price}
        </HDItem>
      </HDRow>
    </HDescriptions>
  );
};

export default ContractSummaryPanel;
