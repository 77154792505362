import React, { useState } from 'react';
import { observer, inject } from 'mobx-react';
import { DataStore } from '../../stores/dataStore';
import { Tree } from 'antd';
import { Department } from '../../models/Org';
const { TreeNode } = Tree;

interface Props {
  dataStore?: DataStore;
  onSelectItem?: (item: Department) => void;
}

const DeptTree: React.FC<Props> = props => {
  const [selectedKey, setSelectedKey] = useState('0');
  const dataStore = props.dataStore!;

  // TODO: 매번 트리 렌더링 하지 않도록
  const renderTreeNode = (node: Department) => {
    return (
      <TreeNode title={node.name} key={String(node.id)}>
        {node.children &&
          node.children.map(treeNode => renderTreeNode(treeNode))}
      </TreeNode>
    );
  };

  const handleTreeNodeSelect = (selectedKeys: string[]) => {
    // console.log('DeptTree > handleTreeNodeSelect: ', selectedKeys[0]);
    setSelectedKey(selectedKeys[0]);
    if (props.onSelectItem) {
      const id = Number(selectedKeys[0]);
      const dept = dataStore.findDepartment(id);
      if (dept) {
        props.onSelectItem(dept);
      }
    }
  };

  const tree = dataStore.dept.children
    ? [new Department(0, '전체보기'), ...dataStore.dept.children]
    : [];

  return (
    <div
      style={{
        height: '559px',
        overflow: 'auto',
        border: '1px solid #ccc',
        padding: '10px'
      }}
    >
      <Tree
        showLine
        defaultExpandAll={false}
        selectedKeys={[selectedKey]}
        onSelect={handleTreeNodeSelect}
      >
        {tree.map(treeNode => renderTreeNode(treeNode))}
      </Tree>
    </div>
  );
};

export default inject('dataStore')(observer(DeptTree));
