import Service from './Index';

export type EducationSearchOption = {
  category?: string;
  keyword?: string;
  year?: number;
};

const EducationService = {
  fetchItems: (
    limit: number,
    page: number,
    options?: EducationSearchOption
  ) => {
    const offset = (page - 1) * limit;
    return Service.get('/edus', {
      limit,
      offset,
      ...options
    });
  },
  fetchSummary: () => {
    return Service.get('/edus/summary');
  },
  fetchSchedules: (
    limit: number,
    page: number,
    options?: EducationSearchOption
  ) => {
    const offset = (page - 1) * limit;
    return Service.get('/edus/schedules', {
      limit,
      offset,
      ...options
    });
  },
  fetchMyItems: (
    limit: number,
    page: number,
    options?: EducationSearchOption
  ) => {
    const offset = (page - 1) * limit;
    return Service.get('/edus/my', {
      limit,
      offset,
      ...options
    });
  },
  fetchItem: (id: number) => {
    return Service.get(`/edus/${id}`);
  },
  create: (data: any) => {
    return Service.post('/edus', data);
  },
  update: (id: number, data: any) => {
    return Service.put(`/edus/${id}`, data);
  },
  delete: (id: number) => {
    return Service.delete(`/edus/${id}`);
  },

  fetchUsers: (id: number) => {
    return Service.get(`/edus/${id}/users`);
  },
  // 지정 신청자 등록
  addEnforcedUsers: (id: number, user_ids: number[]) => {
    return Service.post(`/edus/${id}/users`, { user_ids });
  },
  // 수강신청자 삭제
  deleteUser: (id: number, user_id: number) => {
    return Service.delete(`/edus/${id}/users/${user_id}`);
  },
  // 수강신청
  applyForEducation: (id: number) => {
    return Service.post(`/edus/${id}/apply`);
  },
  // 수강신청취소
  cancelApply: (id: number) => {
    return Service.delete(`/edus/${id}/apply`);
  },
  // 출결정보 일괄수정
  updateUsersState: (id: number, user_ids: number[], state: number) => {
    return Service.put(`/edus/${id}/users_state`, { user_ids, state });
  }
  // 수강신청정보 수정
  // updateUser: (id: number, user_id: number, data: any) => {
  //   return Service.put(`/edus/${id}/users/${user_id}`, data);
  // },
};

export default EducationService;
