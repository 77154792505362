import React, { useState, useEffect, useRef } from 'react';
import { RouteComponentProps, withRouter } from 'react-router';
import { Row, Col, Input, Icon, Table, Drawer, Popconfirm, Tag } from 'antd';
import Util from '../../helpers/Util';
import { PaginationConfig } from 'antd/lib/table';
import filesize from 'filesize';
import LinkButton from './LinkButton';
import FileService from '../../services/FileService';
import { File } from '../../models/File';
import ArchiveFileGroup from './ArchiveFileGroup';
import ArchiveFileRevUploader from './ArchiveFileRevUploader';
import AButton from './AButton';

interface Props extends RouteComponentProps {
  id?: number;
}

const OrderFileBrowser: React.FC<Props> = props => {
  const { location } = props;
  const params = new URLSearchParams(location.search);

  const paths = props.location.pathname.split('/');
  const param1 = paths.length >= 4 ? paths[3] : undefined;
  const orderId = Number(param1) || Number(params.get('id')) || 0; // undefined;

  const [loading, setLoading] = useState(false);
  const [items, setItems] = useState<File[]>([]);
  const [totalCount, setTotalCount] = useState(0);
  const [currentPage, setCurrentPage] = useState(1);
  const [keyword, setKeyword] = useState('');
  const [refreshTrigger, setRefreshTrigger] = useState(1);

  const pagination = {
    pageSize: 15,
    current: currentPage,
    total: totalCount,
    showTotal: (total: number, range: [number, number]) =>
      `전체 ${total}건 중 ${range[0]}-${range[1]}`
  };

  useEffect(() => {
    const fetchItems = async () => {
      setLoading(true);
      try {
        const result = await FileService.fetchArchiveItems(
          pagination.pageSize,
          currentPage,
          keyword,
          undefined,
          orderId
        );
        setTotalCount(result.total_count);
        setItems(File.fromJsonArray(result.items));
      } catch (error) {
        Util.showError(error);
      } finally {
        setLoading(false);
      }
    };

    fetchItems();
  }, [currentPage, pagination.pageSize, keyword, orderId, refreshTrigger]);

  const columns = [
    {
      title: '구분',
      dataIndex: 'archive_name',
      width: 200,
      ellipsis: true
    },
    {
      title: '이름',
      dataIndex: 'original_name',
      ellipsis: true,
      render: (text: string, record: File) => {
        return (
          <>
            <LinkButton onClick={e => onClickDownloadButton(record)}>
              <Icon type="download" />
            </LinkButton>{' '}
            {record.isPreviewable ? (
              <AButton onClick={e => FileService.previewFile(record)}>
                {text}
              </AButton>
            ) : (
              <>{text}</>
            )}
          </>
        );
      }
    },
    {
      title: '버전',
      dataIndex: 'revision',
      width: 45,
      align: 'right' as 'right'
    },
    {
      title: '크기',
      dataIndex: 'size',
      width: 90,
      align: 'right' as 'right',
      render: (text: string, record: File) => {
        return filesize(record.size, { round: 0 });
      }
    },
    {
      title: '태그',
      dataIndex: 'tags',
      width: 200,
      render: (text: string, record: File) => {
        return (
          <>
            {record.tags.map((tag, index) => (
              <Tag key={index}>{tag}</Tag>
            ))}
          </>
        );
      }
    },
    {
      title: '등록자',
      width: 100,
      dataIndex: 'owner.name'
    },
    {
      title: '등록일',
      dataIndex: 'created_at',
      width: 100,
      render: (text: string, record: File) => Util.formatDate(record.created_at)
    },
    {
      width: '45px',
      render: (text: string, record: File) => {
        return (
          <Popconfirm
            title="정말 삭제하시겠습니까?"
            onConfirm={e => onClickDeleteButton(record)}
          >
            <LinkButton>삭제</LinkButton>
          </Popconfirm>
        );
      }
    }
  ];

  const onChangeTable = (pagination: PaginationConfig) => {
    setCurrentPage(pagination.current || 1);
  };

  const [fileEditorVisible, setFileEditorVisible] = useState(false);
  const [fileVersionsVisible, setFileVersionsVisible] = useState(false);
  const currentFileRef = useRef<File>(new File());

  // const onClickEditButton = (item: File) => {
  //   currentFileRef.current = item;
  //   setFileEditorVisible(true);
  // };

  const onCloseEditor = () => {
    setFileEditorVisible(false);
  };

  const onSuccessEditing = () => {
    setRefreshTrigger(v => v + 1);
    setFileEditorVisible(false);
  };

  // const onClickVersionButton = (item: File) => {
  //   currentFileRef.current = item;
  //   setFileVersionsVisible(true);
  // };

  const onCloseVersion = () => {
    setFileVersionsVisible(false);
  };

  const onClickDeleteButton = async (item: File) => {
    try {
      await FileService.deleteItem(item.id);
      Util.showSuccess('삭제되었습니다.');
      setRefreshTrigger(v => v + 1);
    } catch (err) {
      Util.showError(err);
    }
  };

  const onClickDownloadButton = async (item: File) => {
    try {
      setLoading(true);
      await FileService.downloadFile(item.id, item.original_name);
    } catch (error) {
      Util.showError(error);
    } finally {
      setLoading(false);
    }
  };

  const onSearch = (keyword: string) => {
    setKeyword(keyword);
    setCurrentPage(1);
  };

  console.count('OrderFileBrowser');

  return (
    <div>
      <h2>통합파일검색</h2>

      <Row type="flex" justify="space-between" style={{ marginBottom: 10 }}>
        <Col>
          <Input.Search
            placeholder="검색어"
            allowClear={true}
            onSearch={onSearch}
            style={{ width: 200 }}
          />
        </Col>
        <Col></Col>
      </Row>

      <Table
        columns={columns}
        dataSource={items}
        rowKey="id"
        pagination={pagination}
        onChange={onChangeTable}
        loading={loading}
        size="middle"
        bordered={true}
      />

      <Drawer
        title="파일 버전 추가"
        width={800}
        visible={fileEditorVisible}
        onClose={onCloseEditor}
        destroyOnClose={true}
      >
        <ArchiveFileRevUploader
          file={currentFileRef.current}
          onSuccess={onSuccessEditing}
        />
      </Drawer>

      <Drawer
        title="파일 버전"
        width={800}
        visible={fileVersionsVisible}
        onClose={onCloseVersion}
        destroyOnClose={true}
      >
        <ArchiveFileGroup file={currentFileRef.current} />
      </Drawer>
    </div>
  );
};

export default withRouter(OrderFileBrowser);
