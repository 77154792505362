import Service from './Index';

export type VacationSearchOption = {
  keyword?: string;
  dept_id?: number;
  rank_id?: number;
  year?: number;
};

const VacationService = {
  fetchItems: (limit: number, page: number, options?: VacationSearchOption) => {
    const offset = (page - 1) * limit;
    return Service.get('/etc/vacations', {
      limit,
      offset,
      ...options
    });
  },
  fetchRecords: (
    limit: number,
    page: number,
    options?: VacationSearchOption
  ) => {
    const offset = (page - 1) * limit;
    return Service.get('/etc/vacations/records', {
      limit,
      offset,
      ...options
    });
  },
  batchUpdate: (content: string) => {
    return Service.post('/etc/vacations/batch_update', { content });
  },
  fetchRecordStats: (options: VacationSearchOption) => {
    return Service.get(`/etc/vacations/stats`, options);
  },
  fetchItem: (id: number) => {
    return Service.get(`/etc/vacations/${id}`);
  }
  // create: (eadata: any) => {
  //   return Service.post('/etc/vacations', { eadata });
  // },
  // update: (id: number, data: any) => {
  //   return Service.put(`/etc/vacations/${id}`, data);
  // }
};

export default VacationService;
