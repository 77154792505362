import React, { useState, useEffect, useRef } from 'react';
import { Table, Row, Col, Form, Button, InputNumber, Drawer, Tabs } from 'antd';
import { withRouter, RouteComponentProps } from 'react-router-dom';
import OrderService from '../../services/OrderService';
import { Order } from '../../models/Order';
import Util from '../../helpers/Util';
import { FormComponentProps } from 'antd/lib/form';
import ButtonGroup from 'antd/lib/button/button-group';
import FCTextInput from '../../components/formcomps/FCTextInput';
import _ from 'lodash';
import dataStore from '../../stores/dataStore';
import OrderExtraEdit from '../projects/orders/OrderExtraEdit';
import OrderView from '../projects/orders/OrderView';
import AButton from '../../components/general/AButton';
import ReactToPrint from 'react-to-print';
const { TabPane } = Tabs;

interface OrderVM extends Order {
  hq_id?: number;
  team_id?: number;
  extra_state: string;

  divider?: boolean;
  hq_count?: number;
  team_count?: number;
  extra_state_count?: number;
}

/*
[
  {
    dept_id: 1,
    teams: [
      { 
        team_id: 1,
        groups: [
          {
            state: '신규',
            orders: []
          },
          {
            state: '진행',
            orders: []
          }
        ]
      },
      {
        ...
      }
    ]
  }
]

*/

interface Props extends FormComponentProps, RouteComponentProps {}

const OrderReportHR: React.FC<Props> = props => {
  const params = new URLSearchParams(props.location.search);
  const hq = params.get('hq') || 'all';
  const dept_id = Number(params.get('dept_id')) || 0;
  const year =
    Number(params.get('year')) ||
    Number(localStorage.getItem('orderReportYear')) ||
    new Date().getFullYear();
  const keyword = params.get('keyword') || '';
  const now = params.get('now') || '';

  const { getFieldDecorator, setFieldsValue } = props.form;
  const [items, setItems] = useState<OrderVM[]>([]);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    const fetchItems = async () => {
      setLoading(true);
      try {
        const result = await OrderService.fetchItemsForReport({
          state: '진행',
          year,
          hq,
          dept_id,
          keyword
        });
        const items = Order.fromJsonArray(result.items);
        const vmItems = items.map(el => {
          let vmItem = el as OrderVM;
          vmItem.hq_id = el.dept_id;
          if (el.leaders.length > 0 && el.leaders[0].dept) {
            vmItem.team_id = el.leaders[0].dept.id;
          } else {
            vmItem.team_id = el.dept_id;
          }
          vmItem.extra_state = el.ctrin ? '진행' : '신규';
          return vmItem;
        });

        const orderedItems = _.orderBy(vmItems, [
          'hq_id',
          'team_id',
          'extra_state'
        ]);

        const groupedHQ = _.groupBy(orderedItems, 'hq_id');
        for (const key of Object.keys(groupedHQ)) {
          const firstItem = _.find(orderedItems, { hq_id: Number(key) });
          const groupedHQItems = groupedHQ[key];
          if (firstItem) {
            firstItem.hq_count = groupedHQItems.length;
            firstItem.divider = true;
          }

          const groupedTeam = _.groupBy(groupedHQItems, 'team_id');
          for (const key of Object.keys(groupedTeam)) {
            const firstItem = _.find(groupedHQItems, { team_id: Number(key) });
            const groupedTeamItems = groupedTeam[key];
            if (firstItem) {
              firstItem.team_count = groupedTeamItems.length;
              firstItem.divider = true;
            }

            const groupedState = _.groupBy(groupedTeamItems, 'extra_state');
            for (const key of Object.keys(groupedState)) {
              const firstItem = _.find(groupedTeamItems, { extra_state: key });
              const groupedStateItems = groupedState[key];
              if (firstItem) {
                firstItem.extra_state_count = groupedStateItems.length;
                firstItem.divider = true;
              }
            }
          }
        }

        setItems(orderedItems);
      } catch (error) {
        Util.showError(error);
      } finally {
        setLoading(false);
      }
    };

    fetchItems();

    setFieldsValue({
      year,
      keyword
    });
  }, [now, hq, dept_id, year, keyword, setFieldsValue]);

  const columns = [
    {
      title: '본부',
      dataIndex: 'dept_id',
      width: 80,
      render: (text: string, record: OrderVM) => {
        return {
          children: <>{record.dept && record.dept.name}</>,
          props: { rowSpan: record.hq_count || 0 }
        };
      }
    },
    {
      title: '부서',
      dataIndex: '부서',
      width: 80,
      className: 'hl-border-left',
      render: (text: string, record: OrderVM) => {
        const dept = dataStore.findDepartment(record.team_id);
        return {
          children: <>{(dept && dept.name) || ''}</>,
          props: { rowSpan: record.team_count || 0 }
        };
      }
    },
    {
      title: '구분',
      dataIndex: '구분',
      width: 60,
      className: 'hl-border-left',
      render: (text: string, record: OrderVM) => {
        return {
          children: <>{record.extra_state}</>,
          props: { rowSpan: record.extra_state_count || 0 }
        };
      }
    },
    {
      title: 'No',
      dataIndex: 'id',
      className: 'hl-border-left',
      align: 'right' as 'right',
      width: 60
    },
    {
      title: '프로젝트명',
      dataIndex: 'name',
      width: 280,
      render: (text: string, record: OrderVM) => (
        <AButton onClick={e => onClickItemName(record)}>
          <div className="truncate" style={{ width: '260px' }}>
            {text}
          </div>
        </AButton>
      )
    },
    {
      title: '팀장',
      dataIndex: '팀장',
      render: (text: string, record: OrderVM) =>
        record.leaders.map(e => e.name).join(', ')
    },
    {
      title: '담당',
      dataIndex: '담당',
      render: (text: string, record: OrderVM) =>
        record.managers.map(e => e.name).join(', ')
    },
    {
      title: '주요업무',
      dataIndex: 'task',
      width: 240
    },
    {
      title: '',
      width: 40,
      render: (text: string, record: Order) => (
        <AButton onClick={e => onClickExtra(record)}>수정</AButton>
      )
    }
  ];

  const [currentItem, setCurrentItem] = useState<Order>(new Order());
  const [detailVisible, setDetailVisible] = useState(false);
  const [extraVisible, setExtraVisible] = useState(false);

  const onClickItemName = (item: Order) => {
    setCurrentItem(item);
    setDetailVisible(true);
  };

  const onCloseDetail = () => {
    setDetailVisible(false);
  };

  const onChangeOrderInfo = (updatedOrder: Order) => {
    const foundVMItem = items.find(el => el.id === updatedOrder.id);
    if (foundVMItem) {
      const updatedVMItem = { ...foundVMItem, ...updatedOrder } as OrderVM;
      setItems(
        items.map(el => (el.id === updatedVMItem.id ? updatedVMItem : el))
      );
    }
  };

  const onClickExtra = (item: Order) => {
    setCurrentItem(item);
    setExtraVisible(true);
  };

  const onCloseExtra = () => {
    setExtraVisible(false);
  };

  const onChangeExtraInfo = (updatedOrder: Order) => {
    setExtraVisible(false);

    // 주요업무 (task) 필드값만 업데이트 처리
    setItems(
      items.map(el =>
        el.id === updatedOrder.id
          ? ({ ...el, task: updatedOrder.task } as OrderVM)
          : el
      )
    );
  };

  const onChangeTab = (key: string) => {
    pushHistory(key, 0, year, keyword);
  };

  const onSelectDept = (dept_id: number) => {
    pushHistory(hq, dept_id, year, keyword);
  };

  const onSubmitSearchForm = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    props.form.validateFields((err, values) => {
      if (err) return;

      const year = values.year || new Date().getFullYear();
      localStorage.setItem('orderReportYear', year);

      pushHistory(hq, dept_id, year, values.keyword || '');
    });
  };

  const pushHistory = (
    hq: string,
    dept_id: number,
    year: number,
    keyword: string
  ) => {
    props.history.push(
      `${
        props.location.pathname
      }?hq=${hq}&dept_id=${dept_id}&year=${year}&keyword=${keyword}&now=${Date.now()}`
    );
  };

  const printContentRef = useRef<HTMLDivElement>(null);

  console.count('OrderReport');

  return (
    <>
      <div ref={printContentRef}>
        <h2>인적자원 관리 리스트</h2>

        <Tabs type="card" onChange={onChangeTab} activeKey={hq}>
          <TabPane tab="전체" key="all" />
          <TabPane tab="설계부문" key="planning" />
          <TabPane tab="사업부문" key="business" />
          <TabPane tab="개발부문" key="development" />
          <TabPane tab="마케팅부문" key="marketing" />
          <TabPane tab="해외지사" key="oversea" />
        </Tabs>

        <Form
          className="hl-form"
          layout="inline"
          style={{ marginBottom: 7 }}
          onSubmit={onSubmitSearchForm}
        >
          <Row type="flex" justify="space-between">
            <Col>
              <Form.Item>
                <FCTextInput
                  form={props.form}
                  name="keyword"
                  allowClear
                  placeholder="검색어"
                  options={{ initialValue: keyword }}
                />
              </Form.Item>

              <Form.Item>
                {getFieldDecorator('year', { initialValue: year })(
                  <InputNumber placeholder="년도" style={{ width: 100 }} />
                )}
              </Form.Item>

              {hq === 'planning' && (
                <Form.Item>
                  <ButtonGroup>
                    <Button
                      onClick={e => onSelectDept(0)}
                      type={dept_id === 0 ? 'primary' : undefined}
                    >
                      전체
                    </Button>
                    <Button
                      onClick={e => onSelectDept(16)}
                      type={dept_id === 16 ? 'primary' : undefined}
                    >
                      설계1본부
                    </Button>
                    <Button
                      onClick={e => onSelectDept(19)}
                      type={dept_id === 19 ? 'primary' : undefined}
                    >
                      설계2본부
                    </Button>
                    <Button
                      onClick={e => onSelectDept(24)}
                      type={dept_id === 24 ? 'primary' : undefined}
                    >
                      설계3본부
                    </Button>
                    <Button
                      onClick={e => onSelectDept(26)}
                      type={dept_id === 26 ? 'primary' : undefined}
                    >
                      설계4본부
                    </Button>
                    <Button
                      onClick={e => onSelectDept(22)}
                      type={dept_id === 22 ? 'primary' : undefined}
                    >
                      도시본부
                    </Button>
                  </ButtonGroup>
                </Form.Item>
              )}
              {hq === 'business' && (
                <Form.Item>
                  <ButtonGroup>
                    <Button
                      onClick={e => onSelectDept(0)}
                      type={dept_id === 0 ? 'primary' : undefined}
                    >
                      전체
                    </Button>
                    <Button
                      onClick={e => onSelectDept(32)}
                      type={dept_id === 32 ? 'primary' : undefined}
                    >
                      본사CM본부
                    </Button>
                    <Button
                      onClick={e => onSelectDept(30)}
                      type={dept_id === 30 ? 'primary' : undefined}
                    >
                      금융1팀
                    </Button>
                    <Button
                      onClick={e => onSelectDept(31)}
                      type={dept_id === 31 ? 'primary' : undefined}
                    >
                      금융2팀
                    </Button>
                    <Button
                      onClick={e => onSelectDept(33)}
                      type={dept_id === 33 ? 'primary' : undefined}
                    >
                      CS
                    </Button>
                  </ButtonGroup>
                </Form.Item>
              )}

              <Form.Item>
                <Button type="primary" htmlType="submit">
                  검색
                </Button>
              </Form.Item>
            </Col>
            <Col>
              <ReactToPrint
                trigger={() => <Button>출력</Button>}
                content={() => printContentRef.current}
              />
            </Col>
          </Row>
        </Form>

        <Table
          columns={columns}
          dataSource={items}
          rowKey="id"
          rowClassName={record => {
            let className = 'hl-body-small';
            if (record.divider) className += ' hl-border-top';
            // if (record.summary) className += ' hl-bg-gray';
            return className;
          }}
          pagination={false}
          bordered={true}
          loading={loading}
          size="middle"
        />
      </div>

      <Drawer
        closable={false}
        placement="right"
        width={'85%'}
        visible={detailVisible}
        onClose={onCloseDetail}
        destroyOnClose={true}
      >
        {/* <OrderDetail id={currentItem.id} fetchDelayed /> */}
        <OrderView
          id={currentItem.id}
          title={currentItem.name}
          allowEditing
          onChangeOrderInfo={onChangeOrderInfo}
        />
      </Drawer>

      <Drawer
        closable={false}
        placement="right"
        width={1000}
        visible={extraVisible}
        onClose={onCloseExtra}
        destroyOnClose={true}
      >
        <OrderExtraEdit id={currentItem.id} onSuccess={onChangeExtraInfo} />
      </Drawer>
    </>
  );
};

export default withRouter(Form.create<Props>()(OrderReportHR));
