import React, { useState, useEffect } from 'react';
import { Form, Input, Button } from 'antd';
import { FormComponentProps } from 'antd/lib/form';
import { RouteComponentProps, withRouter } from 'react-router';
import { Order } from '../../../models/Order';
import OrderFormCompany from './OrderFormCompany';
import AppService from '../../../services/AppService';
import Util from '../../../helpers/Util';
import OrderService from '../../../services/OrderService';
import ProjectSelector from '../ProjectSelector';
import { Project } from '../../../models/Project';
import FCStringCodeSelect from '../../../components/formcomps/FCStringCodeSelect';
import FCTextInput from '../../../components/formcomps/FCTextInput';
import FCTextArea from '../../../components/formcomps/FCTextArea';
import InlineText from '../../../components/formcomps/InlineText';
import FCNumberInput from '../../../components/formcomps/FCNumberInput';
import InlineItem from '../../../components/formcomps/InlineItem';
import Spinner from '../../../components/general/Spinner';
import CompanyService from '../../../services/CompanyService';
import { Company } from '../../../models/Compnay';
import MultiDeptSelect from '../../../components/general/MultiDeptSelect';
import FCUserInput from '../../../components/formcomps/FCUserInput';
import FCCascader from '../../../components/formcomps/FCCascader';
import FIHQSelect from '../../../components/formcomps/FIHQSelect';
import FICorpSelect from '../../../components/formcomps/FICorpSelect';
import InlineTextRight from '../../../components/formcomps/InlineTextRight';
import FIDatePicker from '../../../components/formcomps/FIDatePicker';

interface Props extends FormComponentProps, RouteComponentProps {
  id?: number;
  data?: any;
  fetchDelayed?: boolean;
  restrictEditing?: boolean;
}

const OrderEditor: React.FC<Props> = props => {
  const { restrictEditing = false } = props;
  const [loading, setLoading] = useState(true);
  const { getFieldDecorator, setFieldsValue } = props.form;

  const [order, setOrder] = useState<Order>(new Order());
  const [kinds, setKinds] = useState<string[]>([]);
  const [uses, setUses] = useState<string[]>([]);
  const [regions, setRegions] = useState<string[]>([]);
  const [districts, setDistricts] = useState<string[]>([]);
  const [areas, setAreas] = useState<string[]>([]);

  useEffect(() => {
    const fetchDetail = async () => {
      setLoading(true);
      try {
        const result = await AppService.fetchStoreValues([
          'project_kinds',
          'project_uses',
          'regions',
          'districts',
          'areas'
        ]);
        setKinds(result['project_kinds']);
        setUses(result['project_uses']);
        setRegions(result['regions']);
        setDistricts(result['districts']);
        setAreas(result['areas']);

        if (props.data) {
          setOrder(Order.fromJson(props.data));
        } else if (props.id) {
          const result2 = await OrderService.fetchItem(props.id);
          console.log(result2);
          setOrder(Order.fromJson(result2.order));
        } else {
          setOrder(new Order());
        }
      } catch (error) {
        Util.showError(error);
      } finally {
        setLoading(false);
      }
    };

    if (props.fetchDelayed) {
      setTimeout(() => {
        fetchDetail();
      }, 400);
    } else {
      fetchDetail();
    }
  }, [props.id, props.data, props.fetchDelayed]);

  const [projectSelectorVisible, setProjectSelectorVisible] = useState(false);
  const onClickSearchProject = () => {
    setProjectSelectorVisible(true);
  };

  const onCancelProjectSelector = () => {
    setProjectSelectorVisible(false);
  };

  const onSelectProjects = async (items: Project[]) => {
    if (items.length > 0) {
      const project = items[0];

      let compInfo: any = {
        comp_id: project.comp_id,
        comp_name: project.comp_name
      };

      if (project.comp_id) {
        const result = await CompanyService.fetchItem(project.comp_id);
        const company = Company.fromJson(result.company);
        compInfo = {
          comp_id: company.id,
          comp_name: company.name,
          comp_owner: company.ceo,
          comp_rank: company.ceo !== '' ? '대표' : '',
          comp_phone: company.phone
        };
      }

      setFieldsValue({
        project_id: project.id,
        name: project.name,
        kind: project.kind,
        zipcode: project.zipcode,
        address: project.address,
        site_area: project.site_area,
        total_floor_area: project.total_floor_area,
        floor_level: project.floor_level,
        base_level: project.base_level,
        use1: project.use1,
        use2: project.use2,
        use3: project.use3,
        use: project.use,
        ...compInfo
      });

      setProjectSelectorVisible(false);
    }
  };

  const formItemLayout = {
    labelCol: { span: 4 },
    wrapperCol: { span: 19 }
  };

  console.count('OrderEditor');

  if (loading) {
    return <Spinner />;
  }

  return (
    <>
      <Form {...formItemLayout}>
        <h3>접수</h3>
        <div className="hl-form" style={{ marginBottom: '10px' }}>
          <Form.Item label="프로젝트 코드">
            <InlineItem width="24%">
              {getFieldDecorator('project_id', {
                initialValue: order.project.id || '',
                rules: [
                  {
                    required: true,
                    message: '프로젝트를 선택해주세요.'
                  }
                ]
              })(<Input disabled />)}
            </InlineItem>
          </Form.Item>

          <Form.Item label="계약건명">
            <InlineItem width="70%">
              {getFieldDecorator('name', {
                initialValue: order.name,
                rules: [
                  {
                    required: true,
                    message: '계약건명을 입력해주세요.'
                  }
                ]
              })(<Input />)}
            </InlineItem>{' '}
            <Button onClick={onClickSearchProject} disabled={restrictEditing}>
              프로젝트 검색...
            </Button>
          </Form.Item>

          <FICorpSelect
            form={props.form}
            label="법인"
            allowClear
            options={{
              initialValue: order.corp_id,
              rules: [{ required: true, message: '법인을 선택해주세요.' }]
            }}
            width="30%"
            disabled={restrictEditing}
          />

          <FIHQSelect
            form={props.form}
            label="부서"
            options={{
              initialValue: order.dept_id,
              rules: [{ required: true, message: '부서를 선택해주세요.' }]
            }}
            width="30%"
            disabled={restrictEditing}
          />

          <Form.Item label="접수날짜">
            <InlineItem>
              <FIDatePicker
                form={props.form}
                name="register_date"
                initialValue={
                  order.register_date && Util.formatDate(order.register_date)
                }
              />
            </InlineItem>

            <InlineText>종류:</InlineText>
            <InlineItem width="29%">
              <FCStringCodeSelect
                form={props.form}
                name="kind"
                items={kinds}
                allowClear={true}
                options={{ initialValue: order.kind }}
              />
            </InlineItem>
            <InlineText>등급:</InlineText>
            <InlineItem width="100px">
              <FCStringCodeSelect
                form={props.form}
                name="grade"
                items={['A', 'B', 'C']}
                allowClear={true}
                options={{ initialValue: order.grade }}
              />
            </InlineItem>
          </Form.Item>

          <Form.Item label="팀장">
            <FCUserInput
              form={props.form}
              name="leader_ids"
              objName="leaders"
              items={order.leaders}
            />
          </Form.Item>

          <Form.Item label="담당">
            <FCUserInput
              form={props.form}
              name="manager_ids"
              objName="managers"
              items={order.managers}
            />
          </Form.Item>

          <Form.Item label="협업">
            <InlineItem width="60%">
              <MultiDeptSelect
                form={props.form}
                name="collab_ids"
                values={order.collab_ids}
              />
            </InlineItem>
          </Form.Item>
        </div>

        <h3>대지정보</h3>
        <div className="hl-form" style={{ marginBottom: '10px' }}>
          <Form.Item label="우편번호">
            <InlineItem>
              {getFieldDecorator('zipcode', {
                initialValue: order.project.zipcode
              })(<Input style={{ width: '150px' }} disabled />)}
            </InlineItem>
          </Form.Item>

          <Form.Item label="위치">
            {getFieldDecorator('address', {
              initialValue: order.project.address
            })(<Input disabled />)}
          </Form.Item>

          <Form.Item label="대지면적">
            <FCNumberInput
              form={props.form}
              name="site_area"
              options={{ initialValue: order.project.site_area }}
              unit="㎡"
              disabled
            />
            <InlineTextRight>연면적:</InlineTextRight>
            <FCNumberInput
              form={props.form}
              name="total_floor_area"
              options={{ initialValue: order.project.total_floor_area }}
              unit="㎡"
              disabled
            />
          </Form.Item>

          <Form.Item label="층수 (지상)">
            <FCNumberInput
              form={props.form}
              name="floor_level"
              unit="층"
              options={{ initialValue: order.project.floor_level }}
              disabled
            />
            <InlineTextRight>(지하):</InlineTextRight>
            <FCNumberInput
              form={props.form}
              name="base_level"
              unit="층"
              options={{ initialValue: order.project.base_level }}
              disabled
            />
          </Form.Item>

          <Form.Item label="토지상태">
            <InlineItem width="180px">
              <FCStringCodeSelect
                form={props.form}
                name="site_state"
                items={['토지발굴', '토지매입', '토지소유']}
                allowClear={true}
                options={{ initialValue: order.site_state }}
              />
            </InlineItem>
            <InlineTextRight>토지가격:</InlineTextRight>
            <InlineItem>
              <FCNumberInput
                form={props.form}
                name="price"
                options={{ initialValue: order.price }}
              />
            </InlineItem>
          </Form.Item>

          <Form.Item label="지역">
            <FCStringCodeSelect
              form={props.form}
              name="region"
              mode="tags"
              allowClear
              items={regions}
              options={{ initialValue: order.region }}
            />
          </Form.Item>

          <Form.Item label="지구">
            <FCStringCodeSelect
              form={props.form}
              name="districts"
              mode="tags"
              allowClear
              items={districts}
              options={{ initialValue: order.districts }}
            />
          </Form.Item>

          <Form.Item label="구역">
            <FCStringCodeSelect
              form={props.form}
              name="areas"
              mode="tags"
              allowClear
              items={areas}
              options={{ initialValue: order.areas }}
            />
          </Form.Item>

          <Form.Item label="용도">
            <InlineItem width="240px">
              <FCCascader
                form={props.form}
                name="use1"
                items={uses}
                options={{ initialValue: order.use1 }}
              />
            </InlineItem>{' '}
            <InlineItem width="240px">
              <FCCascader
                form={props.form}
                name="use2"
                items={uses}
                options={{ initialValue: order.use2 }}
              />
            </InlineItem>{' '}
            <InlineItem width="240px">
              <FCCascader
                form={props.form}
                name="use3"
                items={uses}
                options={{ initialValue: order.use3 }}
              />
            </InlineItem>{' '}
            <InlineItem width="240px">
              <FCTextInput
                form={props.form}
                name="use"
                placeholder="용도 기타"
                options={{ initialValue: order.use }}
              />
            </InlineItem>
          </Form.Item>
        </div>

        <OrderFormCompany
          title="사업주"
          form={props.form}
          name="comp"
          required
          value={{
            id: order.comp_id,
            name: order.comp_name,
            owner: order.comp_owner,
            rank: order.comp_rank,
            phone: order.comp_phone,
            note: order.comp_note,
            nature: order.comp_nature,
            relation: order.comp_relation
          }}
        />

        <OrderFormCompany
          title="의뢰처"
          form={props.form}
          name="client"
          value={{
            id: order.client_id,
            name: order.client_name,
            owner: order.client_owner,
            rank: order.client_rank,
            phone: order.client_phone,
            note: order.client_note,
            nature: order.client_nature,
            relation: order.client_relation
          }}
        />

        <h3>사업구도</h3>
        <div className="hl-form" style={{ marginBottom: '10px' }}>
          <Form.Item label="종류">
            <InlineItem width="120px">
              <FCStringCodeSelect
                form={props.form}
                name="type"
                items={['수의계약', 'PF사업', '현상', '턴키']}
                allowClear={true}
                options={{ initialValue: order.type }}
              />
            </InlineItem>
            <InlineText>경과:</InlineText>
            <InlineItem width="120px">
              <FCStringCodeSelect
                form={props.form}
                name="progress"
                items={['계획검토', '인허가 준비', '인허가 中', '인허가 완료']}
                allowClear={true}
                options={{ initialValue: order.progress }}
              />
            </InlineItem>
          </Form.Item>

          <Form.Item label="시공사">
            <InlineItem width="40%">
              <FCTextInput
                form={props.form}
                name="constructor_name"
                options={{ initialValue: order.constructor_name }}
              />
            </InlineItem>
            <InlineTextRight>공사비(예정):</InlineTextRight>
            <InlineItem width="23%">
              <FCNumberInput
                form={props.form}
                name="est_cost"
                options={{ initialValue: order.est_cost }}
              />
            </InlineItem>
          </Form.Item>

          <Form.Item label="금융사">
            <InlineItem width="40%">
              <FCTextInput
                form={props.form}
                name="finance_comp"
                options={{ initialValue: order.finance_comp }}
              />
            </InlineItem>
            <InlineTextRight>금융관계(절차):</InlineTextRight>
            <InlineItem width="120px">
              <FCStringCodeSelect
                form={props.form}
                name="finance_state"
                items={['자금조달 中', '자금조달 完', '공사중']}
                allowClear={true}
                options={{ initialValue: order.finance_state }}
              />
            </InlineItem>
          </Form.Item>
        </div>

        <h3>기타</h3>
        <div className="hl-form" style={{ marginBottom: '10px' }}>
          <Form.Item label="계획안제출예정일">
            <InlineItem>
              <FIDatePicker
                form={props.form}
                name="plan_est_date"
                initialValue={
                  order.plan_est_date && Util.formatDate(order.plan_est_date)
                }
              />
            </InlineItem>
            <InlineText>계획안제출일:</InlineText>
            <InlineItem>
              <FIDatePicker
                form={props.form}
                name="plan_date"
                initialValue={
                  order.plan_date && Util.formatDate(order.plan_date)
                }
              />
            </InlineItem>
          </Form.Item>

          <Form.Item label="검토 및 요구사항">
            <FCTextArea
              form={props.form}
              name="note"
              options={{ initialValue: order.note }}
            />
          </Form.Item>
        </div>
      </Form>

      <ProjectSelector
        visible={projectSelectorVisible}
        onCancel={onCancelProjectSelector}
        onOk={onSelectProjects}
      />
    </>
  );
};

export default withRouter(OrderEditor);
