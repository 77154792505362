import React, { useState, useEffect, useRef } from 'react';
import { Button, Row, Col, Form, InputNumber, Drawer } from 'antd';
import { FormComponentProps } from 'antd/lib/form';
import FICorpSelect from '../../../components/formcomps/FICorpSelect';
import CollectSummaryTable from './CollectSummaryTable';
import FinanceService, {
  CollectFetchOption
} from '../../../services/FinanceService';
import { CollectPlanItem, CollectWeekGroup } from '../../../models/Finance';
import ContractPayBook from '../../projects/contracts/ContractPayBook';
import Util from '../../../helpers/Util';
import OrderDetail from '../../projects/orders/OrderDetail';

interface Props extends FormComponentProps {
  menu?: string;
  hideCorpSelect?: boolean;
}

const CollectPlan: React.FC<Props> = props => {
  const { hideCorpSelect = false } = props;
  const { getFieldDecorator } = props.form;
  const [groups, setGropus] = useState<CollectWeekGroup[]>([]);
  const [refreshTrigger, setRefreshTrigger] = useState(1);
  const [fetchOption, setFetchOption] = useState<CollectFetchOption>({
    year: new Date().getFullYear(),
    month: new Date().getMonth() + 1,
    menu: props.menu
  });

  const onSubmitSearchForm = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    props.form.validateFields((err, values) => {
      if (err) return;
      setFetchOption(values);
    });
  };

  useEffect(() => {
    const fetchItems = async () => {
      const result = await FinanceService.fetchCollects2(fetchOption);
      const plans = CollectPlanItem.fromJsonArray(result.plans);

      const allWeeks = Util.getAllWeeksInMonth(
        fetchOption.year,
        fetchOption.month
      );
      const groups: CollectWeekGroup[] = allWeeks.map(e => {
        const startDate = Util.formatDate(e.start);
        const endDate = Util.formatDate(e.end);
        const weekItems = plans.filter(i => {
          return i.f_est_date >= startDate && i.f_est_date <= endDate;
        });

        // console.log('weekItems: ', weekItems);

        // order_id, est_date가 동일한 항목들 하나로 묶음 처리
        // 예정일이 동일한 항목들은 실행일도 같거나 undefined 라는 가정
        // (실행시 실행일자로 예정일이 업데이트되기 때문)
        /*
        let items: CollectPlanItem[] = [];
        weekItems.forEach(e => {
          const foundItem = items.find(
            i =>
              i.order_id === e.order_id &&
              Util.formatDate(i.est_date) === Util.formatDate(e.est_date)
          );
          if (foundItem) {
            foundItem.total_price =
              Number(foundItem.total_price) + Number(e.total_price);
            foundItem.total_pay =
              Number(foundItem.total_pay) + Number(e.total_pay);
            if (e.pay_date) foundItem.pay_date = e.pay_date;
          } else {
            items.push(e);
          }
        });
        */

        // order_id, est_date, pay_date가 동일한 항목들 하나로 묶음 처리
        let items: CollectPlanItem[] = [];
        weekItems.forEach(e => {
          const foundItem = items.find(
            i =>
              i.order_id === e.order_id &&
              Util.formatDate(i.est_date) === Util.formatDate(e.est_date) &&
              Util.formatDate(i.pay_date) === Util.formatDate(e.pay_date)
          );
          if (foundItem) {
            foundItem.total_price =
              Number(foundItem.total_price) + Number(e.total_price);
            foundItem.total_pay =
              Number(foundItem.total_pay) + Number(e.total_pay);
          } else {
            items.push(e);
          }
        });

        return {
          startDate,
          endDate,
          items
        };
      });
      setGropus(groups);
      // console.log(groups);
    };

    fetchItems();
  }, [fetchOption, refreshTrigger]);

  const orderIdRef = useRef(0);
  const orderDetailDirtyRef = useRef(false);
  const paybookDirtyRef = useRef(false);
  const [detailVisible, setDetailVisible] = useState(false);
  const [collectEditVisible, setCollectEditVisible] = useState(false);

  const onSelectItem = (item: CollectPlanItem) => {
    orderIdRef.current = item.order_id;
    setDetailVisible(true);
  };

  const onChangeDetail = () => {
    orderDetailDirtyRef.current = true;
  };

  const onCloseDetail = () => {
    setDetailVisible(false);
    if (orderDetailDirtyRef.current) {
      orderDetailDirtyRef.current = false;
      setRefreshTrigger(v => v + 1);
    }
  };

  const onSelectPrice = (item: CollectPlanItem) => {
    orderIdRef.current = item.order_id;
    setCollectEditVisible(true);
  };

  const onCloseCollectEdit = () => {
    setCollectEditVisible(false);
    if (paybookDirtyRef.current) {
      paybookDirtyRef.current = false;
      setRefreshTrigger(refreshTrigger + 1);
    }
  };

  const onChangePayBook = () => {
    paybookDirtyRef.current = true;
  };

  return (
    <>
      <h2>수금계획</h2>

      <Form
        className="hl-form"
        layout="inline"
        style={{ marginBottom: 7 }}
        onSubmit={onSubmitSearchForm}
      >
        <Row type="flex" justify="space-between">
          <Col>
            <Form.Item>
              {getFieldDecorator('year', {
                initialValue: fetchOption.year
              })(
                <InputNumber
                  placeholder="년도"
                  style={{ width: 100 }}
                  formatter={value => `${value}년`}
                />
              )}
            </Form.Item>
            <Form.Item>
              {getFieldDecorator('month', {
                initialValue: fetchOption.month
              })(
                <InputNumber
                  placeholder="월"
                  style={{ width: 70 }}
                  min={1}
                  max={12}
                  formatter={value => `${value}월`}
                />
              )}
            </Form.Item>
            {!hideCorpSelect && (
              <FICorpSelect
                form={props.form}
                label="법인"
                width="170px"
                allowClear
              />
            )}
            <Form.Item>
              <Button type="primary" htmlType="submit">
                검색
              </Button>
            </Form.Item>
          </Col>
          <Col></Col>
        </Row>
      </Form>

      <CollectSummaryTable
        groups={groups}
        onSelectItem={onSelectItem}
        onSelectPrice={onSelectPrice}
      />

      <Drawer
        closable={false}
        placement="right"
        width={'85%'}
        visible={detailVisible}
        onClose={onCloseDetail}
        destroyOnClose={true}
      >
        <OrderDetail
          id={orderIdRef.current}
          fetchDelayed
          onChangeDetail={onChangeDetail}
        />
      </Drawer>

      <Drawer
        closable={false}
        placement="right"
        width={1200}
        visible={collectEditVisible}
        onClose={onCloseCollectEdit}
        destroyOnClose={true}
      >
        <ContractPayBook
          orderId={orderIdRef.current}
          out={false}
          onChange={onChangePayBook}
        />
      </Drawer>
    </>
  );
};

export default Form.create<Props>()(CollectPlan);
