import React, { useState, useEffect } from 'react';
import { Contract } from '../../../models/Order';
import { Row, Col } from 'antd';
import ContractTable from './ContractTable';

interface Props {
  data: any;
}

const ContractOutViewer: React.FC<Props> = props => {
  const { data } = props;
  const [terms, setTerms] = useState<string[]>([]);
  const [inContracts, setInContracts] = useState<Contract[]>([]);
  const [outContracts, setOutContracts] = useState<Contract[]>([]);

  console.count('ContractOutViewer');

  useEffect(() => {
    setTerms(data.terms);
    setInContracts(Contract.fromJsonArray(data.ctrins));
    setOutContracts(Contract.fromJsonArray(data.ctrouts));
  }, [data]);

  return (
    <>
      <Row type="flex" justify="space-between" style={{ marginBottom: 10 }}>
        <Col>
          <h3>수주계약</h3>
        </Col>
      </Row>
      <ContractTable out={false} contracts={inContracts} terms={terms} />

      <Row
        type="flex"
        justify="space-between"
        style={{ marginBottom: 10, marginTop: 20 }}
      >
        <Col>
          <h3>외주계약 (총 {outContracts.length}건)</h3>
        </Col>
      </Row>
      <ContractTable out={true} contracts={outContracts} terms={terms} />
    </>
  );
};

export default ContractOutViewer;
