import React from 'react';
import { Tabs } from 'antd';
import { RouteComponentProps, withRouter } from 'react-router';
import { UserStore } from '../../stores/userStore';
import { inject, observer } from 'mobx-react';
import UserDefaultForm from './form/UserDefault';
import UserPhysicalForm from './form/UserPhysical';
import UserEducations from './form/UserEducations';
import UserCareers from './form/UserCareers';
import UserFamilies from './form/UserFamilies';
import UserCerts from './form/UserCerts';
import UserContracts from './form/UserContracts';
import UserRNPs from './form/UserRNPs';
import UserTransfers from './form/UserTransfers';
import UserETCs from './form/UserETCs';
const { TabPane } = Tabs;

// interface MatchParams {
//   id: string;
// }

// interface Props extends RouteComponentProps<MatchParams> {
//   userStore?: UserStore;
// }

interface Props extends RouteComponentProps {
  userStore?: UserStore;
  id: number;
}

const UserDetail = (props: Props) => {
  const userStore = props.userStore!;
  // const { match } = props;

  // const user = userStore.findById(Number(match.params.id));
  const user = userStore.findById(Number(props.id));

  return (
    <>
      <h2>
        인적자원카드 - {user.name} (사원번호 {user.id})
      </h2>
      <Tabs type="card">
        <TabPane tab="기본정보" key="default">
          <UserDefaultForm user={user} />
        </TabPane>
        <TabPane tab="병사" key="physical">
          <UserPhysicalForm user={user} />
        </TabPane>
        <TabPane tab="근로계약" key="contracts">
          <UserContracts user={user} />
        </TabPane>
        <TabPane tab="상벌사항" key="rnps">
          <UserRNPs user={user} />
        </TabPane>
        <TabPane tab="인사명령사항" key="transfers">
          <UserTransfers user={user} />
        </TabPane>
        <TabPane tab="학 력" key="edus">
          <UserEducations user={user} />
        </TabPane>
        <TabPane tab="경 력" key="careers">
          <UserCareers user={user} />
        </TabPane>
        <TabPane tab="가족관계" key="families">
          <UserFamilies user={user} />
        </TabPane>
        <TabPane tab="자격사항" key="certs">
          <UserCerts user={user} />
        </TabPane>
        <TabPane tab="기타" key="etcs">
          <UserETCs user={user} />
        </TabPane>
      </Tabs>
    </>
  );
};

export default withRouter(inject('userStore')(observer(UserDetail)));
