import React, { useState, useEffect, useRef } from 'react';
import { Table, Button, Row, Col, Icon, Form, Drawer, Popconfirm } from 'antd';
import LinkButton from '../../components/general/LinkButton';
import { FormComponentProps } from 'antd/lib/form';
import Util from '../../helpers/Util';
import LawsuitService from '../../services/LawsuitService';
import { Lawsuit, LawsuitEvent } from '../../models/Lawsuit';
import AButton from '../../components/general/AButton';
import LawsuitEventEdit from './LawsuitEventEdit';
import { File } from '../../models/File';
import FileService from '../../services/FileService';

interface Props extends FormComponentProps {
  item: Lawsuit;
}

const LawsuitEventList: React.FC<Props> = props => {
  const { item } = props;
  const [loading, setLoading] = useState(false);
  const [refreshTrigger, setRefreshTrigger] = useState(1);
  const [items, setItems] = useState<LawsuitEvent[]>([]);

  useEffect(() => {
    const fetchItems = async () => {
      setLoading(true);
      try {
        const result = await LawsuitService.fetchEvents(item.id);
        setItems(LawsuitEvent.fromJsonArray(result.items));
      } catch (error) {
        Util.showError(error);
      } finally {
        setLoading(false);
      }
    };
    fetchItems();
  }, [item.id, refreshTrigger]);

  const columns = [
    {
      title: '날짜',
      dataIndex: 'date',
      width: '120px',
      render: (text: string, record: LawsuitEvent) => Util.formatDate(text)
    },
    {
      title: '경과내용',
      dataIndex: 'note',
      render: (text: string, record: LawsuitEvent) => {
        return (
          <>
            <div
              dangerouslySetInnerHTML={{ __html: Util.stringToHTML(text) }}
              style={{ marginTop: '10px', marginBottom: '10px' }}
            />
            <p>
              {record.files.map(file => (
                <span key={file.id} style={{ display: 'block' }}>
                  <LinkButton onClick={e => onClickFile(file)}>
                    <Icon type="download" />
                  </LinkButton>{' '}
                  {file.isPreviewable ? (
                    <AButton onClick={e => FileService.previewFile(file)}>
                      {file.original_name}
                    </AButton>
                  ) : (
                    <>{file.original_name}</>
                  )}
                </span>
              ))}
            </p>
          </>
        );
      }
    },
    {
      width: '80px',
      render: (text: string, record: LawsuitEvent) => {
        return (
          <>
            <LinkButton onClick={e => onClickUpdateButton(record)}>
              수정
            </LinkButton>{' '}
            |{' '}
            <Popconfirm
              title="정말 삭제하시겠습니까?"
              onConfirm={e => onClickDeleteButton(record)}
            >
              <LinkButton>삭제</LinkButton>
            </Popconfirm>
          </>
        );
      }
    }
  ];

  const onClickFile = async (item: File) => {
    try {
      setLoading(true);
      await FileService.downloadFile(item.id, item.original_name);
    } catch (error) {
      Util.showError(error);
    } finally {
      setLoading(false);
    }
  };

  const itemRef = useRef<LawsuitEvent | undefined>(undefined);
  const [editorVisible, setEditorVisible] = useState(false);

  const onClickAddButton = () => {
    itemRef.current = undefined;
    setEditorVisible(true);
  };

  const onClickUpdateButton = async (item: LawsuitEvent) => {
    itemRef.current = item;
    setEditorVisible(true);
  };

  const onCloseEditor = () => {
    setEditorVisible(false);
  };

  const onSuccessEditing = () => {
    setRefreshTrigger(v => v + 1);
    setEditorVisible(false);
  };

  const onClickDeleteButton = async (item: LawsuitEvent) => {
    try {
      await LawsuitService.deleteEvent(item.id);
      Util.showSuccess('삭제되었습니다.');
      setRefreshTrigger(v => v + 1);
    } catch (err) {
      Util.showError(err);
    }
  };

  return (
    <>
      <h2>{item.title} - 사건경과</h2>

      <Row type="flex" justify="space-between" style={{ marginBottom: '10px' }}>
        <Col>{item.project_name}</Col>
        <Col>
          <Button onClick={onClickAddButton}>
            <Icon type="plus" />
            경과내용 등록
          </Button>
        </Col>
      </Row>

      <Table
        columns={columns}
        dataSource={items}
        pagination={false}
        rowKey="id"
        loading={loading}
        bordered={true}
        size="middle"
        scroll={{ x: 'max-content' }}
      />

      <Drawer
        closable={false}
        placement="right"
        width={800}
        visible={editorVisible}
        onClose={onCloseEditor}
        destroyOnClose={true}
      >
        <LawsuitEventEdit
          lawsuit={item}
          item={itemRef.current}
          onSuccess={onSuccessEditing}
        />
      </Drawer>
    </>
  );
};

export default Form.create<Props>()(LawsuitEventList);
