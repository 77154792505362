import React, { useState, useEffect } from 'react';
import {
  Route,
  Switch,
  Redirect,
  RouteComponentProps,
  withRouter
} from 'react-router-dom';
import { Layout, PageHeader } from 'antd';
import Sidebar from '../../components/Sidebar';
import Header from '../../components/Header';
import { HLContent } from '../../components/layout/Index';
import { MenuItem } from '../../models/Common';
import easMenus from './menus/eas';
import boardMenus from './menus/board';
import EASRouter from './EASRouter';
import BoardRouter from './BoardRouter';

const defaultHeaderItems: MenuItem[] = [
  {
    id: 'eas',
    title: '전자결재',
    icon: 'appstore',
    link: '/works/eas'
  },
  {
    id: 'board',
    title: '게시판 / 업무일반',
    icon: 'appstore',
    link: '/works/board'
  }
];

const sideItemsInfo: { [key: string]: MenuItem[] } = {
  eas: easMenus,
  board: boardMenus
};

interface Props extends RouteComponentProps {}

const Index: React.FC<Props> = props => {
  const paths = props.location.pathname.split('/');
  const submenu = paths.length >= 3 ? paths[2] : undefined;

  let [sideItems, setSideItems] = useState<MenuItem[]>([]);
  useEffect(() => {
    if (submenu === undefined) return;
    setSideItems(sideItemsInfo[submenu]);
  }, [submenu]);

  return (
    <>
      <Sidebar
        menuItems={sideItems}
        defaultOpenKeys={['결재문서함', '공지사항', '회람', '문서함']}
      >
        <div style={{ textAlign: 'left', margin: '30px 0px 0px 24px' }}>
          <img src="/images/hanlim-group.png" width="120" alt="한림그룹" />
        </div>
        <PageHeader
          title="공통업무"
          style={{ paddingTop: 5, paddingBottom: 20 }}
        />
      </Sidebar>

      <Layout id="wrapper" style={{ marginLeft: 200 }}>
        <Layout.Content>
          <Header menuItems={defaultHeaderItems} />
          <HLContent>
            <Switch>
              <Route path="/works" exact>
                <Redirect to="/works/eas" />
              </Route>
              <Route path="/works/eas" component={EASRouter} />
              <Route path="/works/board" component={BoardRouter} />
            </Switch>
          </HLContent>
        </Layout.Content>
      </Layout>
    </>
  );
};

export default withRouter(Index);
