import React, { useState, useEffect } from 'react';
import { Form, DatePicker, Input } from 'antd';
import { FormComponentProps } from 'antd/lib/form';
import InlineItem from './InlineItem';
import moment, { Moment } from 'moment';

interface Props extends FormComponentProps {
  label?: string;
  name?: string;
  width?: string;
  initialValue?: string;
  required?: string;
  allowClear?: boolean;
  disabled?: boolean;
  onChange?: (date: Moment | undefined, dateString: string) => void;
}

const FIDatePicker: React.FC<Props> = props => {
  const { getFieldDecorator, setFieldsValue } = props.form;
  const {
    name = 'date',
    width = '150px',
    disabled = false,
    allowClear = false,
    initialValue,
    required
  } = props;
  const [momentDate, setMomentDate] = useState<Moment | undefined>(undefined);

  useEffect(() => {
    if (initialValue) {
      setFieldsValue({ [name]: initialValue });
      setMomentDate(moment(initialValue));
    }
  }, [initialValue, setFieldsValue, name]);

  const onChangeDate = (date: Moment | null, dateString: string) => {
    setFieldsValue({ [name]: dateString !== '' ? dateString : undefined });
    setMomentDate(date || undefined);
    props.onChange && props.onChange(date || undefined, dateString);
  };

  return (
    <Form.Item label={props.label}>
      <InlineItem width={width}>
        {getFieldDecorator(name, {
          initialValue,
          rules: [{ required: required !== undefined, message: required }]
        })(<Input type="hidden" />)}
        <DatePicker
          onChange={onChangeDate}
          value={momentDate}
          style={{ width }}
          disabled={disabled}
          allowClear={allowClear}
        />
      </InlineItem>
    </Form.Item>
  );
};

export default FIDatePicker;
