import React, { useState, useEffect } from 'react';
import Util from '../../../helpers/Util';
import Spinner from '../../../components/general/Spinner';
import { TripAccount, TripReport } from '../../../models/Trip';
import HTMLContentViewer from '../../../components/general/HTMLContentViewer';
import { Table } from 'antd';
import _ from 'lodash';
import AttachedFileList from '../../../components/general/AttachedFileList';
import {
  HDescriptions,
  HDRowItem
} from '../../../components/general/HDescriptions';

interface Props {
  id?: number;
  data?: any;
}

const TripReportViewer: React.FC<Props> = props => {
  const [loading, setLoading] = useState(false);
  const [item, setItem] = useState<TripReport>(new TripReport());
  const [total, setTotal] = useState<TripAccount>(new TripAccount(0));

  useEffect(() => {
    const fetchDetail = async (id: number) => {
      setLoading(true);
      try {
        // const data = await VacationService.fetchItem(id);
        // configDetail(data.vacation);
      } catch (error) {
        Util.showError(error);
      } finally {
        setLoading(false);
      }
    };

    const configDetail = (data: any) => {
      const item = TripReport.fromJson(data);
      setItem(item);

      const total = new TripAccount(0);
      total.type = '합계';
      total.request_price = _.sumBy(item.items, 'request_price');
      total.approve_price = _.sumBy(item.items, 'approve_price');
      setTotal(total);
    };

    if (props.data) {
      configDetail(props.data);
    } else if (props.id) {
      fetchDetail(props.id);
    }
  }, [props.id, props.data]);

  const columns = [
    {
      title: '구분',
      width: 100,
      dataIndex: 'type',
      render: (text: string, record: TripAccount) => {
        return record.id === 0 ? <b>{text}</b> : text;
      }
    },
    {
      title: '청구금액',
      dataIndex: 'f_request_price',
      width: 100,
      align: 'right' as 'right',
      render: (text: string, record: TripAccount) => {
        return record.id === 0 ? <b>{text}</b> : text;
      }
    },
    {
      title: '승인금액',
      dataIndex: 'f_approve_price',
      width: 100,
      align: 'right' as 'right',
      render: (text: string, record: TripAccount) => {
        return record.id === 0 ? <b>{text}</b> : text;
      }
    },
    {
      title: '산출근거',
      dataIndex: 'request_base',
      render: (text: string, record: TripAccount) => {
        if (record.id === 0) {
          return <></>;
        }

        return (
          <>
            <div style={{ marginBottom: '6px' }}>{record.request_base}</div>
            <AttachedFileList items={record.files} />
          </>
        );
      }
    }
  ];

  if (loading) {
    return <Spinner />;
  }

  return (
    <>
      <h3>출장비 정산</h3>
      <Table
        columns={columns}
        dataSource={[...item.items, total]}
        rowKey="id"
        rowClassName={record => {
          return record.id === 0 ? '' : 'hl-vertical-top';
        }}
        pagination={false}
        bordered={true}
        loading={loading}
        size="middle"
        style={{ marginBottom: '18px' }}
      />

      <h3>전표번호</h3>
      <HDescriptions>
        <HDRowItem label="전표번호">{item.ticket_no}</HDRowItem>
      </HDescriptions>

      <h3>결과보고</h3>
      <HTMLContentViewer content={item.report} />
    </>
  );
};

export default TripReportViewer;
