import React, { useState, useEffect } from 'react';
import { Link, withRouter, RouteComponentProps } from 'react-router-dom';
import { inject, observer } from 'mobx-react';
import { AuthStore } from '../stores/authStore';
import {
  Layout,
  Menu,
  Dropdown,
  Icon,
  Button,
  Badge,
  Drawer,
  Modal,
} from 'antd';
import NotificationSummary from './system/NotificationSummary';
import { MenuItem } from '../models/Common';
import UserPasswordReset from '../components/hr/UserPasswordReset';
const { Header } = Layout;

interface Props extends RouteComponentProps {
  authStore?: AuthStore;
}

const work: MenuItem = { id: '/works', title: '공통업무', link: '/works' };
const email: MenuItem = {
  id: '/email',
  title: '메일',
  link: 'https://mail.hanlimgroup.com/',
};
const project: MenuItem = {
  id: '/projects',
  title: '프로젝트',
  link: '/projects',
};
const management: MenuItem = {
  id: '/management',
  title: '경영',
  link: '/management',
};
const planning: MenuItem = {
  id: '/planning',
  title: '설계',
  link: '/planning',
};
const business: MenuItem = {
  id: '/business',
  title: '사업',
  link: '/business',
};
const development: MenuItem = {
  id: '/development',
  title: '개발',
  link: '/development',
};
const marketing: MenuItem = {
  id: '/marketing',
  title: '마케팅',
  link: '/marketing',
};
const oversea: MenuItem = {
  id: '/oversea',
  title: '해외법인',
  link: '/oversea',
};
const report: MenuItem = {
  id: '/report',
  title: '경영업무보고',
  link: '/reports/main',
};
const manual: MenuItem = {
  id: '/manual',
  title: '매뉴얼',
  link: '/works/board/archive?id=3',
};
const pmis: MenuItem = {
  id: '/pmis',
  title: 'PMIS',
  link: 'http://1.231.85.34:3001/',
};
const flex: MenuItem = {
  id: '/flex',
  title: '근태관리(플렉스)',
  link: 'https://flex.team/auth/login',
};
const legacy: MenuItem = {
  id: '/legacy',
  title: '기존 ERP',
  link: 'http://erp.hanlim-archi.co.kr/wmsgw.htm',
};

const AppHeader: React.FC<Props> = (props) => {
  const authStore = props.authStore!;
  const { location } = props;

  const [notePanelVisible, setNotePanelVisible] = useState(false);
  const [passwordResetVisible, setPasswordResetVisible] = useState(false);
  const [menuItems, setMenuItems] = useState<MenuItem[]>([]);

  useEffect(() => {
    let user = authStore.user;
    let menuItems: MenuItem[] = [work, email];

    if (user.acl_project) {
      menuItems.push(project);
    }
    if (user.acl_management) {
      menuItems.push(management);
    }
    if (user.acl_planning) {
      menuItems.push(planning);
    }
    if (user.acl_business) {
      menuItems.push(business);
    }
    if (user.acl_development) {
      menuItems.push(development);
    }
    if (user.acl_marketing) {
      menuItems.push(marketing);
    }
    if (user.acl_oversea) {
      menuItems.push(oversea);
    }
    if (user.acl_report) {
      menuItems.push(report);
    }
   
    menuItems.push(manual,pmis, flex,legacy);

    setMenuItems(menuItems);
  }, [authStore.user]);

  const handleNotePanelClose = () => {
    setNotePanelVisible(false);
  };

  const onClickPasswordReset = () => {
    setPasswordResetVisible(true);
  };

  const onClickLogout = () => {
    authStore.logout();
  };

  const onClickAppLogo = () => {
    props.history.push('/');
  };

  const userDropdownMenu = (
    <Menu>
      <Menu.Item onClick={onClickPasswordReset}>비밀번호 변경...</Menu.Item>
      <Menu.Divider />
      <Menu.Item onClick={onClickLogout}>로그아웃</Menu.Item>
    </Menu>
  );

  let currentMenuId = '';
  if (location.pathname !== '/') {
    const currentMenu = menuItems.find((menu) => {
      return location.pathname.startsWith(menu.id);
    });
    currentMenuId = currentMenu ? currentMenu.id : '';
  }

  const currentMenuIds = [currentMenuId];

  const renderMenuItem = (menu: MenuItem) => {
    if (menu.link && menu.link.startsWith('http')) {
      return (
        <Menu.Item key={menu.id}>
          <a href={menu.link} target="_blank" rel="noopener noreferrer">
            {menu.title}
          </a>
        </Menu.Item>
      );
    }

    return (
      <Menu.Item key={menu.id}>
        <Link to={menu.link || '/'}>{menu.title}</Link>
      </Menu.Item>
    );
  };

  return (
    <>
      <Header
        className="header"
        style={{
          position: 'fixed',
          zIndex: 1,
          width: '100%',
          // backgroundColor: 'white'
        }}
      >
        <div
          className="applogo"
          onClick={onClickAppLogo}
          style={{ cursor: 'pointer' }}
        />
        <div style={{ float: 'right' }}>
          {authStore.user.dept?.id === 1 && (
            <>
              <Badge dot={false}>
                <Icon
                  type="setting"
                  onClick={(e) => props.history.push('/settings')}
                  style={{
                    color: 'white',
                    cursor: 'pointer',
                    marginRight: '14px',
                  }}
                />
              </Badge>{' '}
            </>
          )}
          <Badge dot={false}>
            <Icon
              type="notification"
              onClick={(e) => setNotePanelVisible(true)}
              style={{ color: 'white', cursor: 'pointer' }}
            />
          </Badge>{' '}
          <Dropdown overlay={userDropdownMenu} trigger={['click']}>
            <Button
              type="link"
              className="ant-dropdown-link"
              style={{ color: '#bbb' }}
            >
              {/* <Avatar
                icon="user"
                src={
                  authStore.user.avatar &&
                  authStore.user.avatar.thumbnailFullPath
                }
              /> */}
              {authStore.user.deptName}
              <span style={{ color: 'white', margin: '0px 6px' }}>
                {authStore.user.name}
              </span>
              {authStore.user.rankName}님 <Icon type="down" />
            </Button>
          </Dropdown>
        </div>
        <Menu
          theme="dark"
          mode="horizontal"
          defaultSelectedKeys={currentMenuIds}
          selectedKeys={currentMenuIds}
          style={{ lineHeight: '64px' }}
        >
          {menuItems.map((menu) => renderMenuItem(menu))}
        </Menu>
      </Header>

      <Drawer
        title="주요 알림"
        placement="right"
        width={500}
        visible={notePanelVisible}
        onClose={handleNotePanelClose}
        destroyOnClose={true}
      >
        <NotificationSummary
          onSelectShowAll={() => setNotePanelVisible(false)}
        />
      </Drawer>

      <Modal
        title="비밀번호 변경"
        centered
        visible={passwordResetVisible}
        footer={null}
        onCancel={() => setPasswordResetVisible(false)}
        destroyOnClose={true}
      >
        <UserPasswordReset onSuccess={() => setPasswordResetVisible(false)} />
      </Modal>
    </>
  );
};

export default inject('authStore')(observer(withRouter(AppHeader)));
