import React, { useState, useEffect, useCallback } from 'react';
import Form, { FormComponentProps } from 'antd/lib/form';
import { Table, Button, Icon, Input, Checkbox } from 'antd';
import LinkButton from '../../../components/general/LinkButton';
import Util from '../../../helpers/Util';
import { Order, OrderOut } from '../../../models/Order';
import _ from 'lodash';
import FCNumberInput from '../../../components/formcomps/FCNumberInput';
import InlineText from '../../../components/formcomps/InlineText';
import InlineItem from '../../../components/formcomps/InlineItem';
import Spinner from '../../../components/general/Spinner';
import OrderSummaryPanel from './OrderSummaryPanel';
import OrderService from '../../../services/OrderService';
import { Company, CompanyType } from '../../../models/Compnay';
import CompanySelector from '../companies/CompanySelector';
import { CheckboxChangeEvent } from 'antd/lib/checkbox';
import FCTextInput from '../../../components/formcomps/FCTextInput';

interface Props extends FormComponentProps {
  orderId: number;
  data?: any;
  fetchDelayed?: boolean;
  allowTemporarySave?: boolean;
}

const OrderOutEditor: React.FC<Props> = props => {
  const { getFieldDecorator, getFieldValue, setFieldsValue } = props.form;
  const { orderId, data, fetchDelayed, allowTemporarySave = false } = props;

  console.count('OrderOutEditor');

  const [loading, setLoading] = useState(true);
  const [order, setOrder] = useState<Order>(new Order());
  const [items, setItems] = useState<OrderOut[]>([]);

  useEffect(() => {
    const fetchItems = async () => {
      setLoading(true);
      try {
        const result = await OrderService.fetchItem(orderId);
        const order = Order.fromJson(result.order);
        setOrder(order);

        data
          ? setItems(OrderOut.fromJsonArray(data.order_outs))
          : setItems(order.order_outs);
      } catch (error) {
        Util.showError(error);
      } finally {
        setLoading(false);
      }
    };

    if (fetchDelayed) {
      setTimeout(() => {
        fetchItems();
      }, 400);
    } else {
      fetchItems();
    }
  }, [orderId, data, fetchDelayed]);

  const [companySelectorVisible, setCompanySelectorVisible] = useState(false);

  const handleAddButtonClick = () => {
    setCompanySelectorVisible(true);
  };

  const onCancelCompanySelector = () => {
    setCompanySelectorVisible(false);
  };

  const onSelectCompanies = (comps: Company[]) => {
    if (comps.length > 0) {
      const newItems = comps.map(comp => {
        const item = new OrderOut();
        item.order_id = orderId;
        item.company_id = comp.id;
        item.company_name = comp.name;
        item.company_category = comp.business_category;
        return item;
      });

      let allItems = [...items, ...newItems];
      allItems = _.sortBy(allItems, ['company_category', 'company_name']);
      setItems(allItems);
      setFieldsValue({ item_count: allItems.length });
    }
    setCompanySelectorVisible(false);
  };

  const handleDeleteItem = (id: string) => {
    let newItems = items.filter((item: any) => item.id !== id);
    setItems(newItems);
    setFieldsValue({ item_count: newItems.length });
  };

  const handleSaveButtonClick = () => {
    props.form.validateFields((err, values) => {
      if (err) return;
      saveOrderOuts(values);
    });
  };

  const saveOrderOuts = async (values: any) => {
    try {
      const data = { order_outs: values.all_items };
      await OrderService.update(order.id, data);
      Util.showSuccess('저장되었습니다.');
    } catch (err) {
      Util.showError(err);
    }
  };

  const columns = [
    {
      title: '구분',
      dataIndex: 'company_category',
      // render: (text: string, record: any) => {
      //   return <div style={{ width: '80px' }}>{text}</div>;
      // }
      render: (text: string, record: any) => {
        return (
          <Form.Item style={{ width: '120px' }}>
            <FCTextInput
              form={props.form}
              name={`company_category_${record.id}`}
              options={{ initialValue: record.company_category }}
              onEnter={value => onEnterItemValue(value)}
            />
          </Form.Item>
        );
      }
    },
    {
      title: '선정',
      dataIndex: 'selected',
      render: (text: string, record: any) => {
        return (
          <Form.Item style={{ width: '40px' }}>
            {getFieldDecorator(`selected_${record.id}`, {
              initialValue: record.selected,
              valuePropName: 'checked'
            })(<Checkbox onChange={e => onChangeSelected(e, record)} />)}
          </Form.Item>
        );
      }
    },
    {
      title: '업체명',
      dataIndex: 'company_name',
      render: (text: string, record: any) => {
        return <div style={{ width: '170px' }}>{text}</div>;
      }
    },
    {
      title: '견적가',
      dataIndex: 'est_price',
      render: (text: string, record: any) => {
        return (
          <Form.Item>
            <FCNumberInput
              form={props.form}
              name={`est_price_${record.id}`}
              options={{ initialValue: record.est_price }}
              onEnter={value => onEnterItemValue(value)}
            />
          </Form.Item>
        );
      }
    },
    {
      title: '1차조정',
      dataIndex: 'c1_price',
      render: (text: string, record: any) => {
        return (
          <Form.Item>
            <FCNumberInput
              form={props.form}
              name={`c1_price_${record.id}`}
              options={{ initialValue: record.c1_price }}
              onEnter={value => onEnterItemValue(value)}
            />
          </Form.Item>
        );
      }
    },
    {
      title: '2차조정',
      dataIndex: 'c2_price',
      render: (text: string, record: any) => {
        return (
          <Form.Item>
            <FCNumberInput
              form={props.form}
              name={`c2_price_${record.id}`}
              options={{ initialValue: record.c2_price }}
              onEnter={value => onEnterItemValue(value)}
            />
          </Form.Item>
        );
      }
    },
    {
      title: '최종가',
      dataIndex: 'final_price',
      render: (text: string, record: any) => {
        return (
          <Form.Item>
            <FCNumberInput
              form={props.form}
              name={`final_price_${record.id}`}
              options={{ initialValue: record.final_price }}
              disabled
            />
          </Form.Item>
        );
      }
    },
    {
      render: (text: string, record: OrderOut) => {
        return (
          <LinkButton onClick={e => handleDeleteItem(record.id)}>
            <Icon type="close" />
          </LinkButton>
        );
      }
    }
  ];

  const onChangeSelected = (e: CheckboxChangeEvent, item: OrderOut) => {
    setFieldsValue({
      [`selected_${item.id}`]: e.target.checked
    });
    calcItems();
  };

  const onEnterItemValue = (
    value: number | string | undefined,
    field?: string
  ) => {
    if (value === undefined) return;
    calcItems();
    // if (field === 'rate') {
    //   // TODO: 다음 item rate 필드로 포커스처리
    // }
  };

  const [totalPrice, setTotalPrice] = useState(0);
  const [outRate, setOutRate] = useState(0);

  const calcItems = useCallback(() => {
    const updatedItems = items.map(item => {
      item.order_id = order.id;
      item.company_category = getFieldValue(`company_category_${item.id}`);
      item.selected = getFieldValue(`selected_${item.id}`);
      item.est_price = getFieldValue(`est_price_${item.id}`);
      item.c1_price = getFieldValue(`c1_price_${item.id}`);
      item.c2_price = getFieldValue(`c2_price_${item.id}`);
      item.final_price = item.selected ? item.getFinalPrice() : undefined;
      return item;
    });

    let outTotal = 0;
    for (const item of updatedItems) {
      setFieldsValue({
        [`final_price_${item.id}`]: item.final_price
      });
      if (item.selected && item.final_price) {
        outTotal += item.final_price;
      }
    }
    setTotalPrice(outTotal);

    setFieldsValue({
      all_items: JSON.stringify(updatedItems)
    });

    // 외주비율
    if (order.in_price) {
      const outRate = ((outTotal / order.in_price) * 100).toFixed(1);
      setOutRate(Number(outRate));
    }
  }, [items, order, setFieldsValue, getFieldValue]);

  useEffect(() => {
    if (!loading) calcItems();
  }, [loading, items.length, calcItems]);

  if (loading) {
    return <Spinner />;
  }

  const formItemLayout = {
    labelCol: { span: 4 },
    wrapperCol: { span: 19 }
  };

  return (
    <>
      <h3>{order.name}</h3>

      <OrderSummaryPanel order={order} />

      <Form {...formItemLayout}>
        <Button onClick={handleAddButtonClick} style={{ marginBottom: 10 }}>
          <Icon type="plus" />
          외주업체 선정...
        </Button>{' '}
        {allowTemporarySave && (
          <Button onClick={handleSaveButtonClick} style={{ marginBottom: 10 }}>
            <Icon type="cloud-upload" />
            견적 저장
          </Button>
        )}
        <Table
          bordered={false}
          size="middle"
          columns={columns}
          dataSource={items}
          pagination={false}
          // style={{ width: '1100px', marginBottom: '10px' }}
          style={{ marginBottom: '10px' }}
          rowKey="id"
        />
        {getFieldDecorator('order_id', { initialValue: order.id })(
          <Input type="hidden" />
        )}
        {getFieldDecorator('item_count', { initialValue: items.length })(
          <Input type="hidden" />
        )}
        {getFieldDecorator('all_items', {
          initialValue: JSON.stringify(items)
        })(<Input type="hidden" />)}
        <div className="hl-form" style={{ marginTop: '10px' }}>
          <InlineText>설계수주액:</InlineText>
          <InlineItem width="20%">{order.f_in_price}</InlineItem>
          <InlineText>외주비총액:</InlineText>
          <InlineItem width="20%">{Util.formatNumber(totalPrice)}</InlineItem>
          <InlineText>외주비율:</InlineText>
          <InlineItem width="20%">{Util.formatNumber(outRate)} %</InlineItem>
        </div>
      </Form>

      <CompanySelector
        type={CompanyType.partner}
        visible={companySelectorVisible}
        onCancel={onCancelCompanySelector}
        onOk={onSelectCompanies}
      />
    </>
  );
};

export default OrderOutEditor;
