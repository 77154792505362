import React, { useState } from 'react';
import { Form } from 'antd';
import { FormComponentProps } from 'antd/lib/form';
import { RouteComponentProps, withRouter } from 'react-router';
import Util from '../../helpers/Util';
import EAService from '../../services/EAService';
import EADocDraftContainer from './EADocDraftContainer';
import VacationApplyEditor from '../works/VacationApplyEditor';
import TripApplyEditor from '../works/trips/TripApplyEditor';
import TripReportEditor from '../works/trips/TripReportEditor';
import OrderEditor from '../projects/orders/OrderEditor';
import OrderInEditor from '../projects/orders/OrderInEditor';
import OrderOutEditor from '../projects/orders/OrderOutEditor';
import ContractOutEditor from '../projects/orders/ContractOutEditor';
import EADocContentEditor from './EADocContentEditor';
import _ from 'lodash';
import FREditor from '../../components/general/FREditor';
import { EADoc } from '../../models/EAS';

interface Props extends FormComponentProps, RouteComponentProps {
  title: string;
  docTitle?: string;
  refTarget?: string;
  refId?: number;
  ccDocs?: EADoc[];
  category?: number;
  privateHidden?: boolean;
  preventEditingTitle?: boolean;
  fetchDelayed?: boolean;
}

const EADocDrafter: React.FC<Props> = props => {
  const { refTarget, refId, category } = props;
  const [submitting, setSubmitting] = useState(false);

  const onSubmit = (eadata: any, submit: boolean) => {
    props.form.validateFields((err, values) => {
      if (err) return;

      eadata.ref_target = refTarget;
      eadata.ref_id = refId;
      eadata.category = category;

      // console.log(eadata);
      // console.log(values);
      // return;

      if (refTarget === undefined) {
        eadata.data = { content: values.content };
      } else {
        switch (refTarget) {
          case 'pmis':
            eadata.data = { content: values.content };
            break;
          case 'eduser':
            eadata.data = { content: values.content };
            break;
          case 'vac':
            values.begin_date =
              values.begin_date && Util.formatDate(values.begin_date);
            values.end_date =
              values.end_date && Util.formatDate(values.end_date);
            eadata.data = values;
            break;
          case 'trip':
            // NOTE: 출장자 숨은참조 처리
            eadata.bccuser_ids = values.users
              ? values.users.map((el: any) => el.id)
              : [];
            eadata.title = `${eadata.title} - ${values.trip_title}`;
            eadata.data = _.pick(values, [
              'type',
              'trip_title',
              'reason',
              'place',
              'user',
              'users',
              'begin_date',
              'end_date',
              'content',
              'items'
            ]);
            break;
          case 'trrpt':
            let reportData: any = {};
            reportData.report = values.report;
            reportData.request_total = values.request_price_0;
            reportData.approve_total = values.approve_price_0;
            reportData.items = values.items
              .filter((item: any) => item.request_price > 0)
              .map((item: any) => ({
                ...item,
                file_ids: values[`file_ids_${item.id}`]
              }));

            eadata.data = reportData;
            break;
          case 'ord':
            let ordData = _.omit(values, [
              'zipcode',
              'address',
              'site_area',
              'total_floor_area',
              'floor_level',
              'base_level'
            ]);
            ordData.use1 = values.use1.join('/');
            ordData.use2 = values.use2.join('/');
            ordData.use3 = values.use3.join('/');
            ordData.project = {
              id: values.project_id,
              zipcode: values.zipcode,
              address: values.address,
              site_area: values.site_area,
              total_floor_area: values.total_floor_area,
              floor_level: values.floor_level,
              base_level: values.base_level
            };
            eadata.data = ordData;
            break;
          case 'ordin':
            let ordinData: any = _.pick(values, [
              'order_id',
              'out',
              'company_id',
              'contract_date',
              'autocalc',
              'tax_rate',
              'price',
              'tax_price',
              'notax_price',
              'vat',
              'total_price',
              'note'
            ]);
            ordinData.items = JSON.parse(values.all_items);

            eadata.ref_id = values.order_id; // TODO: props.refId로 대체 여부 확인!!!!
            eadata.data = ordinData;
            break;
          case 'ctrin':
            let ctrinData: any = _.pick(values, [
              'id', // contract.id
              'order_id',
              'out',
              'company_id',
              'contract_date',
              'autocalc',
              'tax_rate',
              'price',
              'tax_price',
              'notax_price',
              'vat',
              'total_price',
              'note'
            ]);
            ctrinData.items = JSON.parse(values.all_items);

            eadata.ref_id = values.order_id; // TODO: props.refId로 대체 여부 확인!!!!
            eadata.data = ctrinData;
            break;
          case 'ordout':
            eadata.ref_id = values.order_id; // TODO: props.refId로 대체 여부 확인!!!!
            eadata.data = { order_outs: JSON.parse(values.all_items) };
            break;
          case 'ctrout':
            eadata.ref_id = values.order_id; // TODO: props.refId로 대체 여부 확인!!!!
            eadata.data = values;
            break;
        }
      }

      if (refTarget === 'ordout') {
        const selectedItems = eadata.data.order_outs.filter(
          (e: any) => e.selected
        );
        if (selectedItems.length === 0) {
          if (
            window.confirm(
              '선정된 업체가 없습니다. 이대로 진행하시겠습니까?'
            ) === false
          ) {
            return;
          }
        }
      }

      // console.log(eadata);
      // return;

      submit ? createItem(eadata) : saveDraft(eadata);
    });
  };

  console.count('EADocDrafter');

  const createItem = async (eadata: any) => {
    try {
      setSubmitting(true);
      await EAService.create(eadata);
      Util.showSuccess('결재상신 되었습니다.');
      props.history.push('/works/eas/list?type=sent'); // 기안후 발신문서로 이동
      // props.history.push(
      //   `./list?refTarget=${refTarget ? refTarget : ''}&cat=${category}`
      // );
    } catch (err) {
      setSubmitting(false);
      Util.showError(err);
    }
  };

  const saveDraft = async (eadata: any) => {
    try {
      setSubmitting(true);
      await EAService.saveDraft(eadata);
      Util.showSuccess('저장되었습니다.');
      props.history.push('/works/eas/list?type=draft'); // 임시저장문서로 이동
    } catch (err) {
      setSubmitting(false);
      Util.showError(err);
    }
  };

  const renderChildren = () => {
    if (refTarget === undefined)
      return <EADocContentEditor form={props.form} />;
    if (refTarget === 'pmis' || refTarget === 'eduser')
      return <FREditor name="content" form={props.form} />;
    if (refTarget === 'vac') return <VacationApplyEditor form={props.form} />;
    if (refTarget === 'trip') return <TripApplyEditor form={props.form} />;
    if (refTarget === 'trrpt') return <TripReportEditor form={props.form} />;
    if (refTarget === 'ord') return <OrderEditor form={props.form} />;
    if (refTarget === 'ordin' || refTarget === 'ctrin')
      return <OrderInEditor form={props.form} orderId={refId} fetchDelayed />;
    if (refTarget === 'ordout')
      return (
        <OrderOutEditor
          form={props.form}
          orderId={refId || 0}
          fetchDelayed={props.fetchDelayed}
          allowTemporarySave
        />
      );
    if (refTarget === 'ctrout')
      return (
        <ContractOutEditor
          form={props.form}
          orderId={refId || 0}
          fetchDelayed={props.fetchDelayed}
        />
      );
  };

  return (
    <EADocDraftContainer
      title={props.title}
      loading={submitting}
      onSubmit={onSubmit}
      docTitle={props.docTitle}
      privateHidden={props.privateHidden}
      preventEditingTitle={props.preventEditingTitle}
      refTarget={refTarget}
      ccDocs={props.ccDocs}
    >
      {renderChildren()}
    </EADocDraftContainer>
  );
};

const _EADocDrafter = Form.create<Props>()(EADocDrafter);
export default withRouter(_EADocDrafter);
