import React, { useState, useEffect } from 'react';
import { Form, Input, Button, Select, DatePicker, Card, Tag } from 'antd';
import { FormComponentProps } from 'antd/lib/form';
import { AuthStore } from '../../stores/authStore';
import { observer, inject } from 'mobx-react';
import Util from '../../helpers/Util';
import VacationService from '../../services/VacationService';
import UserPicker from '../../components/general/UserPicker';
import { User } from '../../models/User';
import * as moment from 'moment';
import { extendMoment } from 'moment-range';
import { Vacation } from '../../models/Vacation';
import FCTextInput from '../../components/formcomps/FCTextInput';
import InlineText from '../../components/formcomps/InlineText';
import InlineItem from '../../components/formcomps/InlineItem';
const rangeMoment = extendMoment(moment);
const { Option } = Select;

interface Props extends FormComponentProps {
  authStore?: AuthStore;
  id?: number;
  data?: any;
}

const TYPE_OPTIONS = [
  { key: '연차', value: '연차' },
  { key: '휴가', value: '휴가 (임원)' },
  { key: '조퇴', value: '조퇴' },
  { key: '지각', value: '지각' },
  { key: '외출', value: '외출' },
  { key: '경조', value: '경조' },
  { key: '교육', value: '교육' },
  { key: '훈련', value: '훈련' },
  { key: '산재', value: '산재' },
  { key: '병가', value: '병가' },
  { key: '기타', value: '기타' }
];

const VacationApplyEditor: React.FC<Props> = props => {
  const authStore = props.authStore!;
  const [userPickerVisible, setUserPickerVisible] = useState(false);
  const [targetUser, setTargetUser] = useState<User>(authStore.user);
  const [, setLoading] = useState(false);
  const [dateStrings, setDateStrings] = useState<string[]>([]);
  const [, setItem] = useState<Vacation>(new Vacation());
  const { getFieldDecorator, getFieldValue, setFieldsValue } = props.form;

  useEffect(() => {
    const fetchDetail = async (id: number) => {
      setLoading(true);
      try {
        const data = await VacationService.fetchItem(id);
        configDetail(data.vacation);
      } catch (error) {
        Util.showError(error);
      } finally {
        setLoading(false);
      }
    };

    const configDetail = (data: any) => {
      const vacation = Vacation.fromJson(data);
      setItem(vacation);
      setTargetUser(vacation.user);
      setDateStrings(vacation.dates);
      setFieldsValue({
        type: vacation.type,
        begin_date: vacation.beginDate,
        begin_type: vacation.beginType,
        end_date: vacation.endDate,
        end_type: vacation.endType,
        used: vacation.used,
        given: vacation.given,
        note: vacation.note
      });
    };

    if (props.data) {
      configDetail(props.data);
    } else if (props.id) {
      fetchDetail(props.id);
    }
  }, [props.data, props.id, setFieldsValue]);

  const onClickChangeUser = () => {
    setUserPickerVisible(true);
  };

  const onCancelUserPicker = () => {
    setUserPickerVisible(false);
  };

  const onOkUserPicker = (users: User[]) => {
    setUserPickerVisible(false);
    if (users.length > 0) {
      setTargetUser(users[0]);
    }
  };

  const onChangeBeginDate = (
    date: moment.Moment | null,
    dateString: string
  ) => {
    if (date === null) {
      setFieldsValue({ end_date: undefined });
      setDateStrings([]);
      return;
    }

    const endDate = getFieldValue('end_date');
    if (endDate) {
      applyRanges(date, endDate);
    } else {
      setDateStrings([dateString]);
    }
  };

  const onChangeEndDate = (date: moment.Moment | null, dateString: string) => {
    const beginDate = getFieldValue('begin_date');
    if (beginDate) {
      applyRanges(beginDate, date || beginDate);
    }
  };

  const onCloseDateTag = (dateString: string) => {
    setDateStrings(dateStrings.filter(item => item !== dateString));
  };

  // 선택한 휴가기간에 따라 실 사용일 배열 추출 (토,일 제외)
  const applyRanges = (beginDate: moment.Moment, endDate?: moment.Moment) => {
    const range = rangeMoment.range(beginDate, endDate || beginDate);
    const days = Array.from(range.by('day'));
    const filtered = days.filter(day => day.weekday() > 0 && day.weekday() < 6);
    const results = filtered.map(day => Util.formatDate(day));
    setDateStrings(results);
  };

  const [dateTypeChanged, setDateTypeChanged] = useState(0);
  const onChangeBeginType = () => {
    setDateTypeChanged(Date.now());
  };

  const onChangeEndType = () => {
    setDateTypeChanged(Date.now());
  };

  // 가능일수 업데이트
  useEffect(() => {
    const fetchGivenDays = async () => {
      if (dateStrings.length === 0) {
        setFieldsValue({ given: 0 });
        return;
      }

      try {
        setLoading(true);
        const year = rangeMoment(dateStrings[0]).year();
        const data = await VacationService.fetchRecords(1, 1, {
          keyword: String(targetUser.id),
          year
        });
        const records = data.items;
        const given = records.length > 0 ? records[0].given : 0; // 발생연차
        const used = records.length > 0 ? records[0].used : 0; // 사용연차
        setFieldsValue({ given: given - used }); // 가능일수(잔여연차)
      } catch (error) {
        Util.showError(error);
      } finally {
        setLoading(false);
      }
    };

    fetchGivenDays();

    setFieldsValue({
      user: {
        id: targetUser.id,
        name: targetUser.name
      }
    });
  }, [targetUser, dateStrings, setFieldsValue]);

  // 사용일수 업데이트
  useEffect(() => {
    const beginType = getFieldValue('begin_type');
    const endType = getFieldValue('end_type');
    let used = 0;
    if (dateStrings.length > 0) {
      const adjust1 = beginType !== 1 ? -0.5 : 0;
      const adjust2 = endType !== 1 ? -0.5 : 0;
      used = dateStrings.length + adjust1 + adjust2;
    }
    setFieldsValue({ used: used, dates: dateStrings.join(',') });
  }, [dateStrings, dateTypeChanged, getFieldValue, setFieldsValue]);

  const formItemLayout = {
    labelCol: { span: 3 },
    wrapperCol: { span: 20 }
  };

  return (
    <>
      <Form className="hl-form" {...formItemLayout}>
        {getFieldDecorator('user', {
          initialValue: { id: targetUser.id, name: targetUser.name }
        })(<Input type="hidden" />)}
        {getFieldDecorator('dates', { initialValue: dateStrings.join(',') })(
          <Input type="hidden" />
        )}
        <Form.Item label="대상자">
          <InlineItem width="40%">
            <Input value={targetUser.nameWithDeptRank} />
          </InlineItem>{' '}
          <Button onClick={onClickChangeUser}>대상자 변경...</Button>
        </Form.Item>

        <Form.Item label="구분">
          {getFieldDecorator('type', { initialValue: '연차' })(
            <Select style={{ width: '20%' }}>
              {TYPE_OPTIONS.map(el => (
                <Option key={el.key} value={el.key}>
                  {el.value}
                </Option>
              ))}
            </Select>
          )}
        </Form.Item>

        <Form.Item label="신청기간">
          <Form.Item style={{ display: 'inline-block' }}>
            {getFieldDecorator('begin_date', {
              rules: [
                {
                  required: true,
                  message: '필수 입력'
                },
                { type: 'object' }
              ]
            })(
              <DatePicker
                onChange={onChangeBeginDate}
                disabledDate={currentDate => {
                  const endDate = getFieldValue('end_date');
                  if (currentDate === undefined || currentDate === null) {
                    return true;
                  }
                  return !(
                    currentDate.weekday() > 0 &&
                    currentDate.weekday() < 6 &&
                    (endDate === undefined
                      ? true
                      : currentDate.isBefore(endDate))
                  );
                }}
              />
            )}
          </Form.Item>{' '}
          <Form.Item style={{ display: 'inline-block', width: '15%' }}>
            {getFieldDecorator('begin_type', {
              initialValue: 1
            })(
              <Select onChange={onChangeBeginType}>
                <Option value={1}>종일</Option>
                <Option value={2}>오전반차</Option>
                <Option value={3}>오후반차</Option>
              </Select>
            )}
          </Form.Item>
          ~{' '}
          <Form.Item style={{ display: 'inline-block' }}>
            {getFieldDecorator('end_date', {
              rules: [{ type: 'object' }]
            })(
              <DatePicker
                onChange={onChangeEndDate}
                disabledDate={currentDate => {
                  const beginDate = getFieldValue('begin_date');
                  if (
                    currentDate === undefined ||
                    currentDate === null ||
                    beginDate === undefined
                  ) {
                    return true;
                  }
                  return !(
                    currentDate.weekday() > 0 &&
                    currentDate.weekday() < 6 &&
                    currentDate.isAfter(beginDate, 'day')
                  );
                }}
              />
            )}
          </Form.Item>{' '}
          <Form.Item style={{ display: 'inline-block', width: '15%' }}>
            {getFieldDecorator('end_type', {
              initialValue: 1
            })(
              <Select onChange={onChangeEndType}>
                <Option value={1}>종일</Option>
                <Option value={2}>오전반차</Option>
                <Option value={3}>오후반차</Option>
              </Select>
            )}
          </Form.Item>
        </Form.Item>

        {dateStrings.length > 0 && (
          <Form.Item label="사용일">
            {dateStrings.map((dateString, index) => (
              <Tag
                key={dateString}
                closable={index > 0 && index < dateStrings.length - 1}
                onClose={() => onCloseDateTag(dateString)}
              >
                {dateString}
              </Tag>
            ))}
          </Form.Item>
        )}

        <Form.Item label="사용일수">
          <Form.Item style={{ display: 'inline-block' }}>
            <InlineItem>
              {getFieldDecorator('used', {
                rules: [
                  {
                    validator: (rule, value, cb) => {
                      const given = getFieldValue('given');
                      if (given <= 0) {
                        cb('가능일수가 없습니다.');
                      } else if (value > given) {
                        cb('가능일수 초과');
                      } else {
                        cb();
                      }
                    }
                  }
                ]
              })(
                <Select
                  open={false}
                  showArrow={false}
                  disabled
                  style={{ width: '80px' }}
                />
              )}
            </InlineItem>
          </Form.Item>
          <InlineText>가능일수: </InlineText>
          <Form.Item style={{ display: 'inline-block' }}>
            <InlineItem>
              {getFieldDecorator('given', { initialValue: 0 })(
                <Select
                  open={false}
                  showArrow={false}
                  disabled
                  style={{ width: '80px' }}
                />
              )}
            </InlineItem>
          </Form.Item>
          <InlineText>
            '연차' 또는 '휴가'일 경우에만 사용일수가 차감됩니다.
          </InlineText>
        </Form.Item>

        <Form.Item label="비고">
          <FCTextInput form={props.form} name="note" />
        </Form.Item>
      </Form>

      <Card title="안내문" bordered={true} style={{ marginTop: 15 }}>
        <b>1. 휴가사용</b>
        <ul>
          <li>
            휴가의 사용은 연속하여 4일까지 가능하며, 부득이한 경우는
            경영지원팀에 문의부탁드립니다.
          </li>
          <li>
            반차의 경우 기준시간은 14:00입니다. 단, 공사현장의 사정에 따라
            출퇴근시간이 상이할 경우 소정근무시간을 1/2을 한 시간이 기준시간
          </li>
        </ul>

        <b>2. 증빙제출 서류</b>
        <ul>
          <li>
            교육 : 교육전 - 교육신청서 사본, 교육후 - 교육참가필증 사본 또는
            교육수료증 사본
          </li>
          <li>훈련 : 훈련전 - 훈련통지서 사본, 훈련후 - 훈련참가필증 사본 </li>
          <li>
            경조 :
            <ul>
              <li>
                결혼 : 청첩장 사본 및 관계증명서류(주민등록등본 또는
                가족관계증명원)
              </li>
              <li>고희 : 관계증명서류(주민등록등본 또는 가족관계증명원)</li>
              <li>
                상례 : 사망진단서 사본(생략가능), 관계증명서류(주민등록등본 또는
                가족관계증명원)
              </li>
            </ul>
          </li>
          <li>
            병가 : 병가전 - 진단서 사본, 병가후 - 입원 또는 치료확인서 사본
          </li>
          <li>기타 첨부서류는 경영지원팀에 문의바랍니다.</li>
        </ul>
      </Card>

      <UserPicker
        visible={userPickerVisible}
        onCancel={onCancelUserPicker}
        onOk={onOkUserPicker}
      />
    </>
  );
};

export default inject('authStore')(observer(VacationApplyEditor));
