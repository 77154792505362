import React, { useState } from 'react';
import { Input, Button, Form } from 'antd';
import { FormComponentProps } from 'antd/lib/form';
import Util from '../../../helpers/Util';
import _ from 'lodash';
import FIDatePicker from '../../../components/formcomps/FIDatePicker';
import AssetService from '../../../services/AssetService';
import { AssetUser } from '../../../models/Asset';
import FCUserInput from '../../../components/formcomps/FCUserInput';

interface Props extends FormComponentProps {
  assetId: number;
  item?: AssetUser;
  onSuccess?: () => void;
}

const AssetUserEdit: React.FC<Props> = props => {
  const { assetId, item } = props;
  const { getFieldDecorator } = props.form;
  const [submitting, setSubmitting] = useState(false);

  const onSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    props.form.validateFields((err, values) => {
      if (err) return;

      if (!values.user_ids || values.user_ids.length === 0) {
        Util.showError('사용자를 선택해주세요.');
        return;
      }

      values.user_id = values.user_ids[0];
      values.begin_date = values.begin_date || null;
      values.end_date = values.end_date || null;

      processItem(_.omit(values, ['user_ids', 'users']));
    });
  };

  const processItem = async (values: any) => {
    try {
      setSubmitting(true);

      if (item) {
        await AssetService.updateAssetUser(item.id, values);
      } else {
        values.asset_id = assetId;
        await AssetService.createAssetUser(values);
      }

      Util.showSuccess('저장되었습니다.');

      if (props.onSuccess) props.onSuccess();
    } catch (err) {
      Util.showError(err);
      setSubmitting(false);
    }
  };

  return (
    <Form
      className="hl-form"
      labelCol={{ span: 4 }}
      wrapperCol={{ span: 18 }}
      onSubmit={onSubmit}
    >
      <Form.Item label="사용자">
        <FCUserInput
          form={props.form}
          name="user_ids"
          objName="users"
          items={(item && [item.user]) || []}
          preventMultiSelects
        />
      </Form.Item>

      <Form.Item label="시작일">
        <FIDatePicker
          form={props.form}
          name="begin_date"
          initialValue={item && Util.formatDate(item.begin_date)}
          allowClear
        />
      </Form.Item>

      <Form.Item label="종료일">
        <FIDatePicker
          form={props.form}
          name="end_date"
          initialValue={item && Util.formatDate(item.end_date)}
          allowClear
        />
      </Form.Item>

      <Form.Item label="비고">
        {getFieldDecorator('note', {
          initialValue: item && item.note
        })(<Input />)}
      </Form.Item>

      <Form.Item wrapperCol={{ span: 12, offset: 4 }}>
        <Button type="primary" htmlType="submit" disabled={submitting}>
          저 장
        </Button>
      </Form.Item>
    </Form>
  );
};

export default Form.create<Props>()(AssetUserEdit);
