import React, { useState, useEffect } from 'react';
import { withRouter, RouteComponentProps } from 'react-router';
import { Table, Row, Col, Input, Button, Icon, Tag } from 'antd';
import { Post } from '../../models/Post';
import { PaginationConfig } from 'antd/lib/table';
import Util from '../../helpers/Util';
import PostService from '../../services/PostService';
import LinkButton from '../../components/general/LinkButton';

interface Props extends RouteComponentProps {
  type?: number;
  refId?: number;
}

const PostList: React.FC<Props> = props => {
  const { location } = props;
  const params = new URLSearchParams(location.search);
  const type = props.type || Number(params.get('type')) || 0;
  const refId = props.refId || Number(params.get('refId')) || undefined;

  const [items, setItems] = useState<Post[]>([]);
  const [keyword, setKeyword] = useState<string>('');
  const [totalCount, setTotalCount] = useState(0);
  const [currentPage, setCurrentPage] = useState(1);
  const [loading, setLoading] = useState(false);

  const pagination = {
    pageSize: 15,
    current: currentPage,
    total: totalCount,
    showTotal: (total: number, range: [number, number]) =>
      `전체 ${total}건 중 ${range[0]}-${range[1]}`
  };

  useEffect(() => {
    const fetchItems = async () => {
      setLoading(true);
      try {
        const result = await PostService.fetchItems(
          pagination.pageSize,
          currentPage,
          type,
          refId,
          keyword
        );
        setTotalCount(result.total_count);
        setItems(Post.fromJsonArray(result.items));
      } catch (error) {
        Util.showError(error);
      } finally {
        setLoading(false);
      }
    };
    fetchItems();
  }, [currentPage, keyword, pagination.pageSize, type, refId]);

  const columns = [
    {
      title: 'No',
      dataIndex: 'id',
      align: 'right' as 'right',
      width: 80,
      render: (text: string, record: Post) => {
        if (record.pinned) {
          return <Tag color="blue">공지</Tag>;
        } else {
          return text;
        }
      }
    },
    {
      title: '제목',
      dataIndex: 'title',
      render: (text: string, record: Post) => (
        <>
          <LinkButton onClick={e => onClickItem(record)}>{text}</LinkButton>
          {record.comment_count > 0 && ` (${record.comment_count})`}
        </>
      )
    },
    {
      title: '게시자',
      dataIndex: 'name',
      width: 120
    },
    {
      title: '조회',
      dataIndex: 'read_count',
      align: 'right' as 'right',
      width: 70
    },
    {
      title: '등록일',
      dataIndex: 'created_at',
      width: 120,
      render: (text: string, record: Post) => Util.formatDate(text)
    }
  ];

  const onClickItem = (item: Post) => {
    props.history.push(`./view?type=${type}&id=${item.id}`);
  };

  const onClickAddButton = () => {
    props.history.push(`./edit?type=${type}&refId=${refId || ''}`);
  };

  const onSearch = (keyword: string) => {
    setKeyword(keyword);
    setCurrentPage(1);
  };

  const onChangeTable = (pagination: PaginationConfig) => {
    setCurrentPage(pagination.current || 1);
  };

  return (
    <>
      <h2>{Post.getTypeName(type, refId)}</h2>

      <Row type="flex" justify="space-between" style={{ marginBottom: 10 }}>
        <Col>
          <Input.Search
            placeholder="검색어"
            allowClear={true}
            onSearch={onSearch}
            style={{ width: 200 }}
          />
        </Col>
        <Col>
          <Button onClick={onClickAddButton}>
            <Icon type="plus" />
            게시물 등록
          </Button>
        </Col>
      </Row>

      <Table
        columns={columns}
        dataSource={items}
        rowKey="id"
        pagination={pagination}
        onChange={onChangeTable}
        bordered={true}
        loading={loading}
        size="middle"
      />
    </>
  );
};

export default withRouter(PostList);
