export class Corp {
  id: number;
  code: string;
  name: string;
  credit = 0;

  constructor(id: number, code: string, name: string, credit: number = 0) {
    this.id = id;
    this.code = code;
    this.name = name;
    this.credit = credit;
  }

  static fromJsonArray(sources: any[]) {
    return sources.map(source => Corp.fromJson(source));
  }

  static fromJson(source: any) {
    return new Corp(source.id, source.code, source.name, source.credit);
  }
}

export class Department {
  id: number;
  name: string;
  children?: Department[];
  parent?: Department;

  constructor(id: number = 0, name: string = '') {
    this.id = id;
    this.name = name;
  }

  static fromJsonArray(sources: any[]) {
    return sources.map(source => Department.fromJson(source));
  }

  static fromJson(source: any) {
    const item = new Department();
    item.id = source.id;
    item.name = source.name;
    if (source.children) {
      item.children = Department.fromJsonArray(source.children);
      item.children.forEach(e => (e.parent = item));
    }
    return item;
  }

  removeParent() {
    this.parent = undefined;
    if (this.children) {
      this.children.forEach(c => c.removeParent());
    }
  }

  get isExecutive(): boolean {
    return this.id === 1;
  }

  // get users(): User[] {
  //   return userStore.usersInDept(this);
  // }

  // TODO: 매번 찾지 말고 저장?
  get rootDept(): Department {
    let found: Department = this;

    let next = this.parent;
    while (next) {
      found = next;
      next = next.parent;
    }

    return found;
  }

  // dept의 하위부서들을 1차원 배열로 리턴
  static findAllChildren(dept: Department | undefined): Department[] {
    let results: Department[] = [];
    if (dept && dept.children) {
      results = dept.children;
      dept.children.forEach(e => {
        const children = Department.findAllChildren(e);
        results = [...results, ...children];
      });
    }
    return results;
  }

  // dept의 상위부서들을 1차원 배열로 리턴
  static findAllParents(dept: Department | undefined): Department[] {
    let results: Department[] = [];
    if (dept && dept.parent) {
      results = [dept.parent];
      const parents = Department.findAllParents(dept.parent);
      results = [...parents, ...results];
    }
    return results;
  }
}
