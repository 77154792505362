import React, { useState, useEffect } from 'react';
import { withRouter, RouteComponentProps } from 'react-router';
import { EADoc } from '../../../models/EAS';
import EADocListPanel from '../../eas/EADocListPanel';
import { Row, Col, Input, Icon, Form, Button } from 'antd';
import EAService, { EADocSearchOption } from '../../../services/EAService';
import Util from '../../../helpers/Util';
import FIHQSelect from '../../../components/formcomps/FIHQSelect';
import { FormComponentProps } from 'antd/lib/form';

interface Props extends RouteComponentProps, FormComponentProps {
  title?: string;
  deptId?: number;
  userId?: number;
  hideDeptSelector?: boolean;
  hideDraftButton?: boolean;
}

// TODO: 리팩토링 - OrderEADocList 코드 중복?
const TripEADocList: React.FC<Props> = props => {
  const {
    title = '출장명령품의',
    deptId,
    userId,
    hideDraftButton = false
  } = props;
  const pageSize = 15;
  const [loading, setLoading] = useState(false);
  const [items, setItems] = useState<EADoc[]>([]);
  const [totalCount, setTotalCount] = useState(0);
  const [currentPage, setCurrentPage] = useState(1);
  const [searchOption, setSearchOption] = useState<EADocSearchOption>({
    data_dept_id: deptId
  });
  const { getFieldDecorator } = props.form;

  useEffect(() => {
    const fetchItems = async () => {
      setLoading(true);
      try {
        // const result = await EAService.fetchItems(pageSize, currentPage, {
        //   ...searchOption,
        //   ref_target: 'trip'
        // });
        const result = await EAService.fetchTripItems(
          pageSize,
          currentPage,
          userId,
          searchOption.keyword
        );
        setTotalCount(result.total_count);
        setItems(EADoc.fromJsonArray(result.items));
      } catch (error) {
        Util.showError(error);
      } finally {
        setLoading(false);
      }
    };

    fetchItems();
  }, [currentPage, searchOption, pageSize, userId]);

  const onClickAddButton = () => {
    props.history.push('/works/eas/trips/apply');
  };

  const onSubmitSearchForm = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    props.form.validateFields((err, values) => {
      if (err) return;
      setSearchOption(values);
      setCurrentPage(1);
    });
  };

  const pagination = {
    pageSize,
    current: currentPage,
    total: totalCount,
    showTotal: (total: number, range: [number, number]) =>
      `전체 ${total}건 중 ${range[0]}-${range[1]}`
  };

  return (
    <>
      <h2>{title}</h2>

      <Form
        className="hl-form"
        layout="inline"
        style={{ marginBottom: 7 }}
        onSubmit={onSubmitSearchForm}
      >
        <Row type="flex" justify="space-between">
          <Col>
            <Form.Item>
              {getFieldDecorator('keyword')(
                <Input.Search
                  placeholder="제목 또는 기안자명"
                  allowClear={true}
                  style={{ width: 150 }}
                />
              )}
            </Form.Item>

            <FIHQSelect
              form={props.form}
              name="data_dept_id"
              options={{ initialValue: deptId }}
              width="180px"
              allowClear
              hide={props.hideDeptSelector}
            />

            <Form.Item>
              <Button type="primary" htmlType="submit">
                검색
              </Button>
            </Form.Item>
          </Col>
          <Col>
            {!hideDraftButton && (
              <Button onClick={onClickAddButton}>
                <Icon type="plus" />
                기안하기
              </Button>
            )}
          </Col>
        </Row>
      </Form>

      <EADocListPanel
        items={items}
        loading={loading}
        pagination={pagination}
        onChangeCurrentPage={page => setCurrentPage(page)}
      />
    </>
  );
};

const _TripEADocList = Form.create<Props>()(TripEADocList);
export default withRouter(_TripEADocList);
