import React, { useState, useRef } from 'react';
import {
  Table,
  Button,
  Row,
  Col,
  Icon,
  Input,
  Form,
  Drawer,
  Popconfirm
} from 'antd';
import LinkButton from '../../components/general/LinkButton';
import { FormComponentProps } from 'antd/lib/form';
import EducationEdit from './EducationEdit';
import { PaginationConfig } from 'antd/lib/table';
import { Education } from '../../models/Education';
import EducationService, {
  EducationSearchOption
} from '../../services/EducationService';
import Util from '../../helpers/Util';

interface Props extends FormComponentProps {}

const PollList: React.FC<Props> = props => {
  const [loading] = useState(false);
  const [totalCount] = useState(0);
  const [currentPage, setCurrentPage] = useState(1);
  const [, setRefreshTrigger] = useState(1);
  const [, setSearchOption] = useState<EducationSearchOption>({});
  const [items] = useState<Education[]>([]);
  const { getFieldDecorator } = props.form;

  const pagination = {
    pageSize: 15,
    current: currentPage,
    total: totalCount,
    showTotal: (total: number, range: [number, number]) =>
      `전체 ${total}건 중 ${range[0]}-${range[1]}`
  };

  //   useEffect(() => {
  //     const fetchItems = async () => {
  //       setLoading(true);
  //       try {
  //         const result = await EducationService.fetchItems(
  //           pagination.pageSize,
  //           currentPage,
  //           searchOption
  //         );
  //         setTotalCount(result.total_count);
  //         setItems(Education.fromJsonArray(result.items));
  //       } catch (error) {
  //         Util.showError(error);
  //       } finally {
  //         setLoading(false);
  //       }
  //     };
  //     fetchItems();
  //   }, [currentPage, pagination.pageSize, searchOption, refreshTrigger]);

  const columns = [
    {
      title: '번호',
      dataIndex: 'id',
      align: 'right' as 'right',
      width: '100px'
    },
    {
      title: '설문조사명',
      dataIndex: 'title'
    },
    {
      title: '문항수 (객관식/주관식)',
      dataIndex: 'date'
    },
    {
      width: '90px',
      render: (text: string, record: Education) => {
        return (
          <>
            <LinkButton onClick={e => onClickUpdateButton(record)}>
              수정
            </LinkButton>{' '}
            |{' '}
            <Popconfirm
              title="정말 삭제하시겠습니까?"
              onConfirm={e => onClickDeleteButton(record)}
            >
              <LinkButton>삭제</LinkButton>
            </Popconfirm>
          </>
        );
      }
    }
  ];

  const onChangeTable = (pagination: PaginationConfig) => {
    setCurrentPage(pagination.current || 1);
  };

  const onSubmitSearchForm = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    props.form.validateFields((err, values) => {
      if (err) return;
      setSearchOption(values);
    });
  };

  const itemRef = useRef<Education | undefined>(undefined);
  const [editorVisible, setEditorVisible] = useState(false);
  // const [detailVisible, setDetailVisible] = useState(false);

  const onClickAddButton = () => {
    itemRef.current = undefined;
    setEditorVisible(true);
  };

  const onClickUpdateButton = async (item: Education) => {
    itemRef.current = item;
    setEditorVisible(true);
  };

  const onCloseEditor = () => {
    setEditorVisible(false);
  };

  const onSuccessEditing = () => {
    setRefreshTrigger(v => v + 1);
    setEditorVisible(false);
  };

  const onClickDeleteButton = async (item: Education) => {
    try {
      await EducationService.delete(item.id);
      Util.showSuccess('삭제되었습니다.');
      setRefreshTrigger(v => v + 1);
    } catch (err) {
      Util.showError(err);
    }
  };

  // const onClickTitle = (item: Education) => {
  //   itemRef.current = item;
  //   setDetailVisible(true);
  // };

  // const onCloseDetail = () => {
  //   setDetailVisible(false);
  // };

  return (
    <>
      <h2>설문조사관리</h2>

      <Form
        className="hl-form"
        layout="inline"
        style={{ marginBottom: 7 }}
        onSubmit={onSubmitSearchForm}
      >
        <Row type="flex" justify="space-between">
          <Col>
            <Form.Item>
              {getFieldDecorator('keyword')(
                <Input.Search
                  placeholder="검색어"
                  allowClear={true}
                  style={{ width: 150 }}
                />
              )}
            </Form.Item>

            <Form.Item>
              <Button type="primary" htmlType="submit">
                검색
              </Button>
            </Form.Item>
          </Col>

          <Col>
            <Button onClick={onClickAddButton} disabled>
              <Icon type="plus" />
              설문조사 등록
            </Button>
          </Col>
        </Row>
      </Form>

      <Table
        columns={columns}
        dataSource={items}
        pagination={pagination}
        onChange={onChangeTable}
        rowKey="id"
        loading={loading}
        bordered={true}
        size="middle"
      />

      <Drawer
        closable={false}
        placement="right"
        width={1000}
        visible={editorVisible}
        onClose={onCloseEditor}
        destroyOnClose={true}
      >
        <EducationEdit
          id={itemRef.current ? itemRef.current.id : undefined}
          onSuccess={onSuccessEditing}
        />
      </Drawer>
    </>
  );
};

export default Form.create<Props>()(PollList);
