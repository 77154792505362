import React, { useState, useEffect } from 'react';
import { Input, Button, Form } from 'antd';
import { FormComponentProps } from 'antd/lib/form';
import Util from '../../../helpers/Util';
import FCNumberInput from '../../../components/formcomps/FCNumberInput';
import InlineItem from '../../../components/formcomps/InlineItem';
import FIDatePicker from '../../../components/formcomps/FIDatePicker';
import AssetService from '../../../services/AssetService';
import { Asset } from '../../../models/Asset';

interface Props extends FormComponentProps {
  id?: number;
  onSuccess?: () => void;
}

const AssetEdit: React.FC<Props> = props => {
  const itemId = props.id || 0;
  const [, setLoading] = useState(true);
  const [item, setItem] = useState<Asset>(new Asset());
  const { getFieldDecorator } = props.form;

  useEffect(() => {
    const fetchItem = async (id: number) => {
      setLoading(true);
      try {
        const result = await AssetService.fetchItem(id);
        const item = Asset.fromJson(result.asset);
        setItem(item);
      } catch (error) {
        Util.showError(error);
      } finally {
        setLoading(false);
      }
    };

    if (itemId > 0) {
      fetchItem(itemId);
    }
  }, [itemId]);

  const [submitting, setSubmitting] = useState(false);

  const onSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    props.form.validateFields((err, values) => {
      if (err) return;
      processItem(values);
    });
  };

  const processItem = async (values: any) => {
    values.purchase_date = values.purchase_date || null;
    values.dispose_date = values.dispose_date || null;
    // console.log(values);

    try {
      setSubmitting(true);

      itemId === 0
        ? await AssetService.create(values)
        : await AssetService.update(itemId, values);
      Util.showSuccess('저장되었습니다.');

      if (props.onSuccess) props.onSuccess();
    } catch (err) {
      Util.showError(err);
      setSubmitting(false);
    }
  };

  return (
    <>
      <h2>자산 {itemId > 0 ? '수정' : '등록'}</h2>

      <Form
        className="hl-form"
        labelCol={{ span: 4 }}
        wrapperCol={{ span: 18 }}
        onSubmit={onSubmit}
      >
        <Form.Item label="자산번호">
          <InlineItem width="30%">
            {getFieldDecorator('code', {
              initialValue: item.code,
              rules: [
                {
                  required: true,
                  message: '자산번호를 입력해주세요.'
                }
              ]
            })(<Input />)}
          </InlineItem>
        </Form.Item>

        <Form.Item label="자산명">
          {getFieldDecorator('name', {
            initialValue: item.name,
            rules: [
              {
                required: true,
                message: '자산명을 입력해주세요.'
              }
            ]
          })(<Input />)}
        </Form.Item>

        <Form.Item label="대분류">
          <InlineItem width="30%">
            {getFieldDecorator('category1', {
              initialValue: item.category1
            })(<Input />)}
          </InlineItem>
        </Form.Item>

        <Form.Item label="중분류">
          <InlineItem width="30%">
            {getFieldDecorator('category2', {
              initialValue: item.category2
            })(<Input />)}
          </InlineItem>
        </Form.Item>

        <Form.Item label="제조사">
          <InlineItem width="60%">
            {getFieldDecorator('manufacturer', {
              initialValue: item.manufacturer
            })(<Input />)}
          </InlineItem>
        </Form.Item>

        <Form.Item label="모델명">
          <InlineItem width="60%">
            {getFieldDecorator('model', {
              initialValue: item.model
            })(<Input />)}
          </InlineItem>
        </Form.Item>

        <Form.Item label="구입비용">
          <FCNumberInput
            form={props.form}
            name="price"
            options={{ initialValue: item.price }}
          />
        </Form.Item>

        <Form.Item label="구매일자">
          <FIDatePicker
            form={props.form}
            name="purchase_date"
            initialValue={Util.formatDate(item.purchase_date)}
            allowClear
          />
        </Form.Item>

        <Form.Item label="폐기일자">
          <FIDatePicker
            form={props.form}
            name="dispose_date"
            initialValue={Util.formatDate(item.dispose_date)}
            allowClear
          />
        </Form.Item>

        <Form.Item wrapperCol={{ span: 12, offset: 4 }}>
          <Button type="primary" htmlType="submit" disabled={submitting}>
            저 장
          </Button>
        </Form.Item>
      </Form>
    </>
  );
};

export default Form.create<Props>()(AssetEdit);
