import { MenuItem } from '../../../models/Common';

const menuItems: MenuItem[] = [
  { id: 'files', icon: 'desktop', title: '통합파일검색' },
  { id: '2001', icon: 'desktop', title: '기획설계' },
  { id: '2002', icon: 'desktop', title: '계획설계' },
  { id: '2003', icon: 'desktop', title: '기본설계' },
  { id: '2004', icon: 'desktop', title: '실시설계' },
  { id: '2005', icon: 'desktop', title: '현장관리,사용승인' },
  { id: '2007', icon: 'desktop', title: '문서,회의록 외' },
  { id: '101', icon: 'desktop', title: '포트폴리오' },
  {
    id: '기안/품의',
    icon: 'desktop',
    title: '기안/품의',
    children: [
      {
        id: 'eas/list?refTarget=pmis&cat=2001',
        title: '계획도면/검토서'
      },
      {
        id: 'eas/list?refTarget=pmis&cat=2002',
        title: '심의도서'
      },
      {
        id: 'eas/list?refTarget=pmis&cat=2003',
        title: '허가도서'
      },
      {
        id: 'eas/list?refTarget=pmis&cat=2004',
        title: '실시도서'
      }
    ]
  }
];

export default menuItems;
