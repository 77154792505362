import React, { useState } from 'react';
import { List, Icon, Drawer } from 'antd';
import AButton from './AButton';
import { EADoc } from '../../models/EAS';
import EADocDetail from '../../pages/eas/EADocDetail';

interface Props {
  items: EADoc[];
  onClickRemove?: (item: EADoc) => void;
}

const AttachedEADocList: React.FC<Props> = props => {
  const { items } = props;

  const [detailVisible, setDetailVisible] = useState(false);
  const [detailItemId, setDetailItemId] = useState(0);

  const onClickItem = (item: EADoc) => {
    setDetailItemId(item.id);
    setDetailVisible(true);
  };

  const onCloseDetail = () => {
    setDetailVisible(false);
  };

  const onClickRemove = (item: EADoc, e: React.MouseEvent) => {
    // e.stopPropagation(); // 삭제시 handleItemClick 이벤트까지 실행되는 것 방지
    if (props.onClickRemove) props.onClickRemove(item);
  };

  if (items.length === 0) {
    return <></>;
  }

  return (
    <>
      <List
        size="small"
        bordered={false}
        split={false}
        dataSource={items}
        renderItem={(item: EADoc) => (
          <List.Item
            actions={
              props.onClickRemove
                ? [<Icon type="delete" onClick={e => onClickRemove(item, e)} />]
                : []
            }
          >
            <Icon type="paper-clip" />{' '}
            <AButton onClick={e => onClickItem(item)}>{item.title}</AButton>{' '}
          </List.Item>
        )}
      />

      <Drawer
        closable={false}
        placement="right"
        width={'85%'}
        visible={detailVisible}
        onClose={onCloseDetail}
        destroyOnClose={true}
      >
        <EADocDetail id={detailItemId} />
      </Drawer>
    </>
  );
};

export default AttachedEADocList;
