import React from 'react';
import { withRouter, RouteComponentProps } from 'react-router';
import { Order } from '../../../models/Order';
import OrderLedger from '../common/OrderLedger';

interface Props extends RouteComponentProps {
  deptId?: number;
}

const OrderLedgerForState: React.FC<Props> = props => {
  const { location } = props;
  const params = new URLSearchParams(location.search);
  const state = Order.stateFromString(params.get('state') || '');

  return (
    <OrderLedger
      title={`${Order.stateName(state)} 프로젝트`}
      state={state}
      deptId={props.deptId}
    />
  );
};

export default withRouter(OrderLedgerForState);
