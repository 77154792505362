import React from 'react';
import { Button, Table } from 'antd';
import { User } from '../../models/User';
import { EAFlowItem } from '../../models/EAS';
import DragableTable from './DragableTable';

interface Props {
  title: string;
  items: EAFlowItem[];
  dragable?: boolean;
  onDeleteRequest?: (id: number) => void;
  onChangeOrder?: (items: any) => void;
}

const UserListPanel: React.FC<Props> = props => {
  const { title, items, dragable, onDeleteRequest, onChangeOrder } = props;

  const handleDeleteItem = (item: User) => {
    if (onDeleteRequest) {
      onDeleteRequest(item.id);
    }
  };

  const columns = [
    {
      title: '이름',
      dataIndex: 'user',
      render: (text: string, record: EAFlowItem) => {
        const typeName = record.type === 2 ? `(${record.typeName})` : '';
        return `${record.user.nameWithRank} ${typeName}`;
      }
    },
    {
      width: '40px',
      render: (text: string, record: EAFlowItem) => {
        return (
          <Button
            type="link"
            style={{ margin: 0, padding: 0, height: '22px' }}
            onClick={e => handleDeleteItem(record.user)}
          >
            X
          </Button>
        );
      }
    }
  ];

  const renderTable = () => {
    if (!!dragable) {
      return (
        <DragableTable
          columns={columns}
          dataSource={items}
          rowKey="id"
          showHeader={false}
          bordered={false}
          pagination={false}
          size="small"
          scroll={{ y: 240 }}
          style={{ height: '245px', border: '1px solid #ccc' }}
          onChangeOrder={onChangeOrder}
        />
      );
    } else {
      return (
        <Table
          columns={columns}
          showHeader={false}
          dataSource={items}
          rowKey="id"
          bordered={false}
          pagination={false}
          size="small"
          scroll={{ y: 240 }}
          style={{ height: '245px', border: '1px solid #ccc' }}
        />
      );
    }
  };

  return (
    <div className="hl-no-border">
      <h4>{title}</h4>
      {renderTable()}
    </div>
  );
};

export default UserListPanel;
