import React, { useState } from 'react';
import { Button, Form, Select, Input } from 'antd';
import { FormComponentProps } from 'antd/lib/form';
import Util from '../../../helpers/Util';
import FCFileInput from '../../../components/formcomps/FCFileInput';
import FIDatePicker from '../../../components/formcomps/FIDatePicker';
import { UserContract, User } from '../../../models/User';
import FCNumberInput from '../../formcomps/FCNumberInput';
import UserService from '../../../services/UserService';

interface Props extends FormComponentProps {
  user: User;
  item?: UserContract;
  onSuccess?: () => void;
}

const UserContractEdit: React.FC<Props> = props => {
  const { user, item } = props;
  const [submitting, setSubmitting] = useState(false);
  const { getFieldDecorator } = props.form;

  const onSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    props.form.validateFields((err, values) => {
      if (err) return;
      processItem(values);
    });
  };

  const processItem = async (values: any) => {
    try {
      setSubmitting(true);

      values.begin_date =
        (values.begin_date && Util.formatDate(values.begin_date)) || null;
      values.end_date =
        (values.end_date && Util.formatDate(values.end_date)) || null;
      values.type = values.type || null;

      if (item) {
        await UserService.updateContract(item.user_id, item.seq, values);
      } else {
        await UserService.createContract(user.id, values);
      }

      Util.showSuccess('저장되었습니다.');
      if (props.onSuccess) props.onSuccess();
    } catch (err) {
      Util.showError(err);
      setSubmitting(false);
    }
  };

  return (
    <>
      <h2>근로계약 {item ? '수정' : '등록'}</h2>

      <Form
        className="hl-form"
        labelCol={{ span: 4 }}
        wrapperCol={{ span: 18 }}
        onSubmit={onSubmit}
      >
        <FIDatePicker
          form={props.form}
          name="begin_date"
          label="계약 시작일"
          allowClear
          initialValue={item && Util.formatDate(item.begin_date)}
        />

        <FIDatePicker
          form={props.form}
          name="end_date"
          label="계약 종료일"
          allowClear
          initialValue={item && Util.formatDate(item.end_date)}
        />

        <Form.Item label="직계구분">
          {getFieldDecorator('type', {
            initialValue: item && item.type
          })(
            <Select allowClear style={{ width: '150px' }}>
              <Select.Option value={1}>임원</Select.Option>
              <Select.Option value={2}>정규직</Select.Option>
              <Select.Option value={3}>프로젝트 계약직</Select.Option>
              <Select.Option value={4}>일반 계약직</Select.Option>
              <Select.Option value={5}>파견직</Select.Option>
            </Select>
          )}
        </Form.Item>

        <Form.Item label="연봉">
          <FCNumberInput
            form={props.form}
            name="salary"
            options={{ initialValue: (item && item.salary) || 0 }}
          />
        </Form.Item>

        <Form.Item label="비고">
          {getFieldDecorator('note', {
            initialValue: item && item.note
          })(<Input />)}
        </Form.Item>

        <Form.Item label="근로계약서">
          <FCFileInput form={props.form} items={item && item.files} />
        </Form.Item>

        <Form.Item wrapperCol={{ span: 12, offset: 4 }}>
          <Button type="primary" htmlType="submit" disabled={submitting}>
            저 장
          </Button>
        </Form.Item>
      </Form>
    </>
  );
};

export default Form.create<Props>()(UserContractEdit);
