import moment from 'moment';
import Util from '../helpers/Util';
import { File } from './File';

export class Lawsuit {
  id = 0;
  owner_id = 0;
  project_id = 0;
  project_name = '';
  ongoing = true;
  comp_name = '';
  title = '';
  code = '';
  court = '';
  accuser = '';
  accused = '';
  director = '';
  lawyer = '';
  lawsuit_pay = 0;
  settle_pay = 0;
  deposit = 0;
  confirm_fee = 0;
  total_pay = 0;
  note = '';

  // derived
  [key: string]: any; // for string index (subscript)

  static fromJsonArray(sources: any[]) {
    return sources.map(source => Lawsuit.fromJson(source));
  }

  static fromJson(source: any) {
    let item = new Lawsuit();
    for (const key of Object.keys(source)) {
      if (key in item) {
        item[key] = source[key];
      }
    }

    return item;
  }

  get f_lawsuit_pay(): string {
    return Util.formatNumber(this.lawsuit_pay);
  }

  get f_settle_pay(): string {
    return Util.formatNumber(this.settle_pay);
  }
}

export class LawsuitEvent {
  id = 0;
  lawsuit_id = 0;
  owner_id = 0;
  date?: moment.Moment = moment();
  note = '';
  files: File[] = [];

  // derived
  [key: string]: any; // for string index (subscript)

  static fromJsonArray(sources: any[]) {
    return sources.map(source => LawsuitEvent.fromJson(source));
  }

  static fromJson(source: any) {
    let item = new LawsuitEvent();
    for (const key of Object.keys(source)) {
      if (key in item) {
        item[key] = source[key];
      }
    }

    item.date = Util.stringToMoment(source.date);
    item.files = File.fromJsonArray(source.files);

    return item;
  }
}
