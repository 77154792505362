import React, { useState, useEffect } from 'react';
import { Row, Col } from 'antd';
import TreeView from '../../../components/general/TreeView';
import { MenuItem } from '../../../models/Common';
import PageService from '../../../services/PageService';
import Util from '../../../helpers/Util';
import { ContentPage } from '../../../models/Page';
import { RouteComponentProps } from 'react-router-dom';
import PageViewer from '../../board/PageViewer';

const menuItems: MenuItem[] = [
  { id: '12', title: '직무,직책' },
  { id: '13', title: '휴가,연차' },
  { id: '14', title: '징계' },
  { id: '15', title: '임금피크제' },
  { id: '16', title: '퇴직금' }
];

interface Props extends RouteComponentProps {}

const HBPages: React.FC<Props> = props => {
  const params = new URLSearchParams(props.location.search);
  const pageId = Number(params.get('id')) || 12;

  const [, setLoading] = useState(false);
  const [item, setItem] = useState<ContentPage | undefined>(undefined);

  useEffect(() => {
    const fetchDetail = async () => {
      setLoading(true);
      try {
        const result = await PageService.fetchItem(pageId);
        const contentPage = ContentPage.fromJson(result.page);
        setItem(contentPage);
      } catch (error) {
        Util.showError(error);
      } finally {
        setLoading(false);
      }
    };

    fetchDetail();
  }, [pageId]);

  const onSelectItem = async (id: string) => {
    if (Number(id)) {
      props.history.push(`${props.location.pathname}?id=${id}`);
    }
  };

  return (
    <Row gutter={15}>
      <Col xl={5} xxl={4}>
        <h2>인사실무</h2>
        <TreeView items={menuItems} onSelectItem={onSelectItem} />
      </Col>
      <Col xl={19} xxl={20}>
        {item && <PageViewer id={item.id} />}
      </Col>
    </Row>
  );
};

export default HBPages;
