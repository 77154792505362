import React, { useState, useEffect } from 'react';
import { Select, Input, Button, Row, Col } from 'antd';
import { User } from '../../models/User';
import { FormComponentProps } from 'antd/lib/form';
import UserPicker from '../general/UserPicker';
import _ from 'lodash';
const { Option } = Select;

interface Props extends FormComponentProps {
  name?: string;
  objName?: string;
  items?: User[];
  preventMultiSelects?: boolean;
  onChange?: (values: User[]) => void;
}

const FCUserInput = (props: Props) => {
  const { getFieldDecorator, setFieldsValue } = props.form;
  const { name = 'user_ids', objName } = props;
  const [items, setItems] = useState<User[]>(props.items || []);

  useEffect(() => {
    setFieldsValue({
      [name]: items.map(e => e.id)
    });

    if (objName) {
      setFieldsValue({
        [objName]: items.map(e => ({ id: e.id, name: e.name }))
      });
    }
  }, [items, name, objName, setFieldsValue]);

  const [selectorVisible, setSelectorVisible] = useState(false);

  const onClickSelect = () => {
    setSelectorVisible(true);
  };

  const onCancelUserPicker = () => {
    setSelectorVisible(false);
  };

  const onOkUserPicker = (selectedItems: User[]) => {
    setSelectorVisible(false);

    let updatedItems: User[] = [];
    if (props.preventMultiSelects) {
      updatedItems = selectedItems.length > 0 ? [selectedItems[0]] : [];
    } else {
      updatedItems = _.unionBy(items, selectedItems, 'id');
    }

    setItems(updatedItems);
    props.onChange && props.onChange(updatedItems);
  };

  function handleChange(value: any) {
    const ids = String(value).split(',');
    const updatedItems =
      ids.length === 0 ? [] : items.filter(e => _.includes(ids, String(e.id)));
    setItems(updatedItems);
    props.onChange && props.onChange(updatedItems);
  }

  return (
    <>
      {getFieldDecorator(name)(<Input type="hidden" />)}
      {objName && getFieldDecorator(objName)(<Input type="hidden" />)}
      <Row gutter={5}>
        <Col span={20}>
          <Select
            mode="tags"
            open={false}
            value={items.map(e => e.id)}
            onChange={handleChange}
            allowClear={true}
          >
            {items.map(user => (
              <Option key={user.id} value={user.id}>
                {user.name}
              </Option>
            ))}
          </Select>
        </Col>
        <Col span={4}>
          <Button onClick={onClickSelect}>선택...</Button>
        </Col>
      </Row>

      <UserPicker
        visible={selectorVisible}
        onCancel={onCancelUserPicker}
        onOk={onOkUserPicker}
      />
    </>
  );
};

export default FCUserInput;
