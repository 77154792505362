import React, { useState, useEffect } from 'react';
import { Comment as AntComment, Avatar, Tooltip } from 'antd';
import { Comment } from '../../models/Comment';
import CommentEditor from './CommentEditor';
import Util from '../../helpers/Util';

interface Props {
  refTarget: number;
  refId: number;
  comment: Comment;
  depth: number;
  onCreateComment?: () => void;
}

const CommentView: React.FC<Props> = props => {
  const { refTarget, refId, comment, depth } = props;
  const [editorVisible, setEditorVisible] = useState(false);
  const [items, setItems] = useState<Comment[]>(comment.children);

  useEffect(() => {
    setItems(comment.children);
  }, [comment.children]);

  const onClickReplyButton = () => {
    setEditorVisible(!editorVisible);
  };

  const onCreateComment = (comment: Comment) => {
    // comment.children.push(comment);
    setItems([...items, comment]);
    setEditorVisible(false);
    if (props.onCreateComment) {
      props.onCreateComment();
    }
  };

  const onCreateChildComment = () => {
    if (props.onCreateComment) {
      props.onCreateComment();
    }
  };

  const onCancelCommentEditor = () => {
    setEditorVisible(false);
  };

  const actions =
    depth >= 3 ? [] : [<span onClick={onClickReplyButton}>답글달기</span>];

  return (
    <AntComment
      actions={actions}
      author={comment.owner.nameWithRank}
      avatar={
        <Avatar
          icon="user"
          src={comment.owner.avatar && comment.owner.avatar.thumbnailFullPath}
        />
      }
      content={<p>{comment.content}</p>}
      datetime={
        <Tooltip title={Util.formatDateTime(comment.created_at)}>
          <span>{Util.formatDateRelatively(comment.created_at)}</span>
        </Tooltip>
      }
    >
      {items.map(item => (
        <CommentView
          key={item.id}
          comment={item}
          refTarget={refTarget}
          refId={refId}
          depth={depth + 1}
          onCreateComment={onCreateChildComment}
        />
      ))}

      {editorVisible && (
        <CommentEditor
          refTarget={refTarget}
          refId={refId}
          parentId={comment.id}
          onCreateComment={onCreateComment}
          onCancel={onCancelCommentEditor}
        />
      )}
    </AntComment>
  );
};

export default CommentView;
