import React, { useState, useEffect } from 'react';
import { Button, Form } from 'antd';
import { FormComponentProps } from 'antd/lib/form';
import Util from '../../../helpers/Util';
import { Order, OrderState } from '../../../models/Order';
import OrderService from '../../../services/OrderService';
import FCStringCodeSelect from '../../../components/formcomps/FCStringCodeSelect';

interface Props extends FormComponentProps {
  item: Order;
  stages: string[];
  onChange?: (data: any) => void;
}

const OrderStateForm: React.FC<Props> = props => {
  const { item, stages } = props;
  const { setFieldsValue } = props.form;

  useEffect(() => {
    setFieldsValue({ state: item.state, stage: item.stage });
  }, [item, setFieldsValue]);

  const [submitting, setSubmitting] = useState(false);

  const onSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    props.form.validateFields((err, values) => {
      if (err) return;
      processItem(values);
    });
  };

  const processItem = async (values: any) => {
    try {
      setSubmitting(true);
      await OrderService.update(item.id, values);
      Util.showSuccess('저장되었습니다.');
      setSubmitting(false);
      if (props.onChange) props.onChange(values);
    } catch (err) {
      Util.showError(err);
      setSubmitting(false);
    }
  };

  return (
    <Form
      className="hl-form"
      layout="inline"
      onSubmit={onSubmit}
      style={{ marginBottom: 7 }}
    >
      <Form.Item label="프로젝트상태">
        <FCStringCodeSelect
          form={props.form}
          name="state"
          items={Object.values(OrderState)}
          style={{ width: '150px' }}
        />
      </Form.Item>
      <Form.Item label="진행단계">
        <FCStringCodeSelect
          form={props.form}
          name="stage"
          items={stages}
          style={{ width: '180px' }}
        />
      </Form.Item>
      <Form.Item>
        <Button type="primary" htmlType="submit" disabled={submitting}>
          저 장
        </Button>
      </Form.Item>
    </Form>
  );
};

export default Form.create<Props>()(OrderStateForm);
