import React, { useState, useEffect } from 'react';
import { Row, Col, Card, Button, Icon, Tabs, Modal, Popconfirm } from 'antd';
import { EADoc, EADocState } from '../../models/EAS';
import { RouteComponentProps, withRouter } from 'react-router';
import Util from '../../helpers/Util';
import EAService from '../../services/EAService';
import EAFlowView from './EAFlowView';
import EADocActivityList from './EADocActivityList';
import Spinner from '../../components/general/Spinner';
import CommentEditor from '../../components/general/CommentEditor';
import CommentSection from '../../components/general/CommentSection';
import {
  HDescriptions,
  HDRow,
  HDRowItem,
  HDItem
} from '../../components/general/HDescriptions';
import AttachedFileList from '../../components/general/AttachedFileList';
import AttachedEADocList from '../../components/general/AttachedEADocList';
import authStore from '../../stores/authStore';
const { TabPane } = Tabs;

interface Props extends RouteComponentProps {
  title: string;
  id: number; // id 필수 (다른 하위탭으로 전달하기 위해)
  doc?: EADoc; // doc이 넘어올경우 내부에서 별도로 fetch 처리 안함
}

const EADocViewer: React.FC<Props> = props => {
  const docId = props.id;
  const doc = props.doc;

  const [item, setItem] = useState<EADoc>(new EADoc());
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    const fetchItem = async () => {
      setLoading(true);
      try {
        const result = await EAService.fetchItem(docId);
        const doc = EADoc.fromJson(result.eadoc);
        setItem(doc);
      } catch (error) {
        Util.showError(error);
      } finally {
        setLoading(false);
      }
    };

    if (doc) {
      setItem(doc);
    } else if (docId) {
      fetchItem();
    }
  }, [docId, doc]);

  // console.log('item.ccDepts: ', item.ccDepts);

  const ccUserNames = item.ccUsers.map(user => user.nameWithRank);
  const ccDeptNames = item.ccDepts.map(dept => dept.name);

  const [submitting, setSubmitting] = useState(false);

  const onClickApprove = async () => {
    try {
      setSubmitting(true);
      const result = await EAService.approveItem(item.id);
      Util.showSuccess('승인처리 되었습니다.');
      if (result && result.next_eadoc_id) {
        props.history.push(`/works/eas/view?id=${result.next_eadoc_id}`);
      } else {
        props.history.push('/works/eas/list?type=waiting');
      }
    } catch (err) {
      setSubmitting(false);
      Util.showError(err);
    }
  };

  const [commentModalVisible, setCommentModalVisible] = useState(false);

  const onClickReturn = () => {
    setCommentModalVisible(true);
  };

  const onCreateComment = async () => {
    setCommentModalVisible(false);
    try {
      setSubmitting(true);
      const result = await EAService.returnItem(item.id);
      Util.showSuccess('반려처리 되었습니다.');
      if (result && result.next_eadoc_id) {
        props.history.push(`/works/eas/view?id=${result.next_eadoc_id}`);
      } else {
        props.history.push('/works/eas/list?type=waiting');
      }
    } catch (err) {
      setSubmitting(false);
      Util.showError(err);
    }
  };

  const onClickCancelApprove = async () => {
    try {
      setSubmitting(true);
      await EAService.cancelApproveItem(item.id);
      Util.showSuccess('승인취소 되었습니다.');
      props.history.push('/works/eas/list?type=waiting');
    } catch (err) {
      setSubmitting(false);
      Util.showError(err);
    }
  };

  const onClickEdit = () => {
    props.history.push(`/works/eas/edit?id=${docId}`);
  };

  const onClickPend = async () => {
    try {
      setSubmitting(true);
      const result = await EAService.pendItem(item.id);
      if (result && result.next_eadoc_id) {
        props.history.push(`/works/eas/view?id=${result.next_eadoc_id}`);
      } else {
        props.history.push('/works/eas/list?type=waiting');
      }
    } catch (err) {
      setSubmitting(false);
      Util.showError(err);
    }
  };

  const onClickDelete = async () => {
    try {
      setSubmitting(true);
      await EAService.delete(item.id);
      Util.showSuccess('삭제 되었습니다.');
      item.state === EADocState.draft
        ? props.history.push('/works/eas/list?type=draft')
        : props.history.push('/works/eas/list?type=sent');
    } catch (err) {
      setSubmitting(false);
      Util.showError(err);
    }
  };

  const onClickSubmit = async () => {
    try {
      setSubmitting(true);
      await EAService.submitItem(item.id);
      Util.showSuccess('결재상신 되었습니다.');
      props.history.push('/works/eas/list?type=sent');
    } catch (err) {
      setSubmitting(false);
      Util.showError(err);
    }
  };

  if (loading) {
    return <Spinner />;
  }

  return (
    <>
      <h2>{props.title}</h2>
      <Row gutter={12}>
        <Col className="gutter-row" span={18}>
          <Tabs type="card">
            <TabPane tab="결재문서정보" key="1">
              <HDescriptions>
                <HDRow>
                  <HDItem label="문서코드">{item.code}</HDItem>
                  <HDItem label="문서ID">{item.id}</HDItem>
                  <HDItem label="작성일">
                    {Util.formatDateTime(item.created_at)}
                  </HDItem>
                </HDRow>
                <HDRowItem label="기안자" colSpan={5}>
                  {item.owner.nameWithDeptRank}
                </HDRowItem>
                {ccUserNames.length > 0 && (
                  <HDRowItem label="참조" colSpan={5}>
                    {ccUserNames.join(', ')}
                  </HDRowItem>
                )}
                {ccDeptNames.length > 0 && (
                  <HDRowItem label="수신부서" colSpan={5}>
                    {ccDeptNames.join(', ')}
                  </HDRowItem>
                )}
                {item.ccDocs.length > 0 && (
                  <HDRowItem label="첨부 문서" colSpan={5}>
                    <AttachedEADocList items={item.ccDocs} />
                  </HDRowItem>
                )}
                {item.files.length > 0 && (
                  <HDRowItem label="첨부 파일" colSpan={5}>
                    <AttachedFileList items={item.files} />
                  </HDRowItem>
                )}
              </HDescriptions>

              <div style={{ marginTop: '15px' }}>{props.children}</div>

              {item.state !== EADocState.draft && (
                <CommentSection refTarget={1} refId={docId} />
              )}
            </TabPane>
            <TabPane tab="결재진행내역" key="2">
              <EADocActivityList docId={docId} />
            </TabPane>
          </Tabs>
        </Col>
        <Col className="gutter-row" span={6}>
          <Card size="small" title="결재선">
            <EAFlowView
              items={item.eaFlow.items}
              currentUserId={item.assignedTo && item.assignedTo.id}
            />
          </Card>

          {(authStore.user.isAdmin || item.hasRightToEdit) && (
            <Row style={{ marginTop: '10px' }}>
              <Col>
                <Button
                  block
                  onClick={e => onClickEdit()}
                  disabled={submitting}
                >
                  <Icon type="edit" />수 정
                </Button>
              </Col>
            </Row>
          )}

          {item.hasRightToDelete && (
            <Row style={{ marginTop: '10px' }}>
              <Col>
                <Popconfirm
                  title={
                    item.state === EADocState.draft
                      ? '정말 삭제하시겠습니까?'
                      : '정말 취소하시겠습니까?'
                  }
                  onConfirm={e => onClickDelete()}
                >
                  <Button block disabled={submitting}>
                    <Icon type="delete" />
                    {item.state === EADocState.draft ? '삭 제' : '상신취소'}
                  </Button>
                </Popconfirm>
              </Col>
            </Row>
          )}

          {item.hasRightToSubmit && (
            <Row style={{ marginTop: '10px' }}>
              <Col>
                <Button
                  block
                  type="primary"
                  onClick={e => onClickSubmit()}
                  disabled={submitting}
                >
                  기안하기
                </Button>
              </Col>
            </Row>
          )}

          {item.hasRightToApprove && (
            <>
              <Row gutter={10} style={{ marginTop: '10px' }}>
                <Col span={12}>
                  <Button
                    block
                    onClick={e => onClickPend()}
                    disabled={submitting}
                  >
                    <Icon type="clock-circle" />보 류
                  </Button>
                </Col>
                <Col span={12}>
                  <Button
                    block
                    type="danger"
                    onClick={e => onClickReturn()}
                    disabled={submitting}
                  >
                    <Icon type="reload" />반 려
                  </Button>
                </Col>
              </Row>
              <Row style={{ marginTop: '10px' }}>
                <Col>
                  <Button
                    block
                    type="primary"
                    onClick={e => onClickApprove()}
                    disabled={submitting}
                  >
                    <Icon type="check" />승 인
                  </Button>
                </Col>
              </Row>
            </>
          )}

          {item.hasRightToCancelApprove && (
            <Row style={{ marginTop: '10px' }}>
              <Col>
                <Button
                  block
                  type="danger"
                  onClick={e => onClickCancelApprove()}
                  disabled={submitting}
                >
                  <Icon type="reload" />
                  승인 취소
                </Button>
              </Col>
            </Row>
          )}
        </Col>
      </Row>

      <Modal
        title="반려 사유"
        centered
        width={700}
        visible={commentModalVisible}
        footer={null}
        onCancel={() => setCommentModalVisible(false)}
        destroyOnClose={true}
      >
        <CommentEditor
          refTarget={1}
          refId={docId}
          onCreateComment={onCreateComment}
        />
      </Modal>
    </>
  );
};

export default withRouter(EADocViewer);
