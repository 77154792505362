import React, { useState, useEffect } from 'react';
import { Post } from '../../models/Post';
import Spinner from '../../components/general/Spinner';
import PostService from '../../services/PostService';
import Util from '../../helpers/Util';
import { Row, Col } from 'antd';
import HTMLContentViewer from '../../components/general/HTMLContentViewer';
import { RELEASE_NOTE_ID } from '../../config';

const ReleaseNotes = () => {
  const [loading, setLoading] = useState(true);
  const [item, setItem] = useState<Post>(new Post());

  useEffect(() => {
    const fetchDetail = async () => {
      setLoading(true);
      try {
        const result = await PostService.fetchItem(RELEASE_NOTE_ID);
        // await PostService.read(postId);
        setItem(Post.fromJson(result.post));
      } catch (error) {
        Util.showError(error);
      } finally {
        setLoading(false);
      }
    };

    fetchDetail();
  }, []);

  return (
    <>
      <h2>시스템 업데이트내역</h2>
      <Row>
        <Col className="gutter-row" span={20}>
          {loading ? <Spinner /> : <HTMLContentViewer content={item.content} />}
        </Col>
      </Row>
    </>
  );
};

export default ReleaseNotes;
