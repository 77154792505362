import { MenuItem } from '../../../models/Common';

const menuItems: MenuItem[] = [
  {
    id: '업무지원',
    title: '업무지원',
    icon: 'desktop',
    children: [
      { id: '4101', title: '설계지원' },
      { id: '4102', title: '도시지원' },
      { id: '4103', title: '사업지원' },
      { id: '4104', title: '마케팅지원' }
    ]
  },
  { id: '4105', title: '업무매뉴얼', icon: 'desktop' }
];

export default menuItems;
