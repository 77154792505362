import 'reflect-metadata'; // order is important
import React from 'react';
import ReactDOM from 'react-dom';
import App from './App';
import * as serviceWorker from './serviceWorker';

import { ConfigProvider } from 'antd';
import ko_KR from 'antd/lib/locale-provider/ko_KR';
import 'moment/locale/ko';

import { DndProvider } from 'react-dnd';
import HTML5Backend from 'react-dnd-html5-backend';

import { Provider } from 'mobx-react';
import authStore from './stores/authStore';
import dataStore from './stores/dataStore';
import userStore from './stores/userStore';

const stores = { authStore, dataStore, userStore };

ReactDOM.render(
  <Provider {...stores}>
    <ConfigProvider locale={ko_KR}>
      <DndProvider backend={HTML5Backend}>
        <App />
      </DndProvider>
    </ConfigProvider>
  </Provider>,
  document.getElementById('root')
);
// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
