import React, { useState, useEffect, useRef } from 'react';
import {
  Table,
  Button,
  Row,
  Col,
  Input,
  Form,
  Popconfirm,
  InputNumber,
  Modal
} from 'antd';
import LinkButton from '../../../components/general/LinkButton';
import { FormComponentProps } from 'antd/lib/form';
import { PaginationConfig } from 'antd/lib/table';
import { Education } from '../../../models/Education';
import EducationService, {
  EducationSearchOption
} from '../../../services/EducationService';
import Util from '../../../helpers/Util';
import AButton from '../../../components/general/AButton';
import EducationDetail from './EducationDetail';

interface Props extends FormComponentProps {}

const AvailableEducations: React.FC<Props> = props => {
  const [loading, setLoading] = useState(false);
  const [totalCount, setTotalCount] = useState(0);
  const [currentPage, setCurrentPage] = useState(1);
  const [refreshTrigger, setRefreshTrigger] = useState(1);
  const [searchOption, setSearchOption] = useState<EducationSearchOption>({});
  const [items, setItems] = useState<Education[]>([]);
  const { getFieldDecorator } = props.form;

  const pagination = {
    pageSize: 15,
    current: currentPage,
    total: totalCount,
    showTotal: (total: number, range: [number, number]) =>
      `전체 ${total}건 중 ${range[0]}-${range[1]}`
  };

  useEffect(() => {
    const fetchItems = async () => {
      setLoading(true);
      try {
        const result = await EducationService.fetchSchedules(
          pagination.pageSize,
          currentPage,
          searchOption
        );
        setTotalCount(result.total_count);
        setItems(Education.fromJsonArray(result.items));
      } catch (error) {
        Util.showError(error);
      } finally {
        setLoading(false);
      }
    };
    fetchItems();
  }, [currentPage, pagination.pageSize, searchOption, refreshTrigger]);

  const columns = [
    {
      title: '번호',
      dataIndex: 'id',
      align: 'right' as 'right',
      width: '100px'
    },
    {
      title: '교육명',
      dataIndex: 'title',
      render: (text: string, record: Education) => (
        <AButton onClick={e => onClickItem(record)}>{record.title}</AButton>
      )
    },
    {
      title: '교육일자',
      dataIndex: 'date',
      width: 150,
      render: (text: string, record: Education) => Util.formatDateTimeHHMM(text)
    },
    {
      title: '신청인원',
      align: 'right' as 'right',
      width: 100,
      render: (text: string, record: Education) => {
        return `${record.apply_count} / ${record.seat_count}`;
      }
    },
    {
      width: '90px',
      render: (text: string, record: Education) => {
        if (record.applied) {
          return (
            <Popconfirm
              title="정말 취소하시겠습니까?"
              onConfirm={e => onClickCancelApplyButton(record)}
            >
              <LinkButton>수강신청취소</LinkButton>
            </Popconfirm>
          );
        } else {
          return (
            <LinkButton onClick={e => onClickApplyButton(record)}>
              수강신청
            </LinkButton>
          );
        }
      }
    }
  ];

  const onChangeTable = (pagination: PaginationConfig) => {
    setCurrentPage(pagination.current || 1);
  };

  const onSubmitSearchForm = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    props.form.validateFields((err, values) => {
      if (err) return;
      setSearchOption(values);
      setCurrentPage(1);
    });
  };

  const onClickApplyButton = async (item: Education) => {
    try {
      await EducationService.applyForEducation(item.id);
      Util.showSuccess('신청하였습니다.');
      setRefreshTrigger(v => v + 1);
    } catch (err) {
      Util.showError(err);
    }
  };

  const onClickCancelApplyButton = async (item: Education) => {
    try {
      await EducationService.cancelApply(item.id);
      Util.showSuccess('취소되었습니다.');
      setRefreshTrigger(v => v + 1);
    } catch (err) {
      Util.showError(err);
    }
  };

  const itemRef = useRef<Education | undefined>(undefined);
  const [detailVisible, setDetailVisible] = useState(false);

  const onClickItem = (item: Education) => {
    itemRef.current = item;
    setDetailVisible(true);
  };

  const onCloseDetail = () => {
    setDetailVisible(false);
  };

  return (
    <>
      <h2>교육일정</h2>

      <Form
        className="hl-form"
        layout="inline"
        style={{ marginBottom: 7 }}
        onSubmit={onSubmitSearchForm}
      >
        <Row type="flex" justify="space-between">
          <Col>
            <Form.Item>
              {getFieldDecorator('keyword')(
                <Input.Search
                  placeholder="검색어"
                  allowClear={true}
                  style={{ width: 150 }}
                />
              )}
            </Form.Item>

            <Form.Item>
              {getFieldDecorator('year')(
                <InputNumber placeholder="년도" style={{ width: 100 }} />
              )}
            </Form.Item>

            <Form.Item>
              <Button type="primary" htmlType="submit">
                검색
              </Button>
            </Form.Item>
          </Col>
          <Col></Col>
        </Row>
      </Form>

      <Table
        columns={columns}
        dataSource={items}
        pagination={pagination}
        onChange={onChangeTable}
        rowKey="id"
        loading={loading}
        bordered={true}
        size="middle"
      />

      <Modal
        centered
        width={800}
        visible={detailVisible}
        footer={null}
        onCancel={() => onCloseDetail()}
        destroyOnClose={true}
      >
        <EducationDetail id={(itemRef.current && itemRef.current.id) || 0} />
      </Modal>
    </>
  );
};

export default Form.create<Props>()(AvailableEducations);
