import { Modal } from 'antd';
import moment, { Moment } from 'moment';

import * as momentAll from 'moment';
import { extendMoment, DateRange } from 'moment-range';
const rangeMoment = extendMoment(momentAll);

let errorShown = false;

const Util = {
  // TODO: arg is T? return boolean 아닌가?
  //   function isDefined<T>(arg: T | undefined): arg is T {
  //     return typeof arg !== 'undefined' && arg !== null;
  //   },

  nullifyUndifined: (data: any) => {
    for (const key of Object.keys(data)) {
      if (key in data) {
        data[key] = data[key] === undefined ? null : data[key];
      }
    }
  },

  formatDate: (
    value: Moment | string | undefined | null,
    defaultValue: string = ''
  ) => {
    if (value === undefined || value === null) return defaultValue;
    if (typeof value === 'string') {
      return moment(value).format('YYYY-MM-DD');
    } else {
      return value.format('YYYY-MM-DD');
    }
  },

  formatYear: (
    value: Moment | string | undefined | null,
    defaultValue: string = ''
  ) => {
    if (value === undefined || value === null) return defaultValue;
    if (typeof value === 'string') {
      return moment(value).format('YYYY');
    } else {
      return value.format('YYYY');
    }
  },

  formatDateTime: (
    value: Moment | string | undefined | null,
    defaultValue: string = ''
  ) => {
    if (value === undefined || value === null) return defaultValue;
    if (typeof value === 'string') {
      return moment(value).format('YYYY-MM-DD HH:mm:ss');
    } else {
      return value.format('YYYY-MM-DD HH:mm:ss');
    }
  },

  formatDateTimeHHMM: (
    value: Moment | string | undefined | null,
    defaultValue: string = ''
  ) => {
    if (value === undefined || value === null) return defaultValue;
    if (typeof value === 'string') {
      return moment(value).format('YYYY-MM-DD HH:mm');
    } else {
      return value.format('YYYY-MM-DD HH:mm');
    }
  },

  formatDateRelatively: (
    value: Moment | string | undefined | null,
    defaultValue: string = ''
  ) => {
    if (value === undefined || value === null) return defaultValue;
    if (typeof value === 'string') {
      return moment(value).fromNow();
    } else {
      return value.fromNow();
    }
  },

  formatNumber: (
    value?: string | number,
    defaultValue: string | number = 0
  ) => {
    if (value !== undefined && value !== null) {
      return `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',');
    } else {
      return `${defaultValue}`;
    }
  },

  parseNumbers(
    target: any,
    source: any,
    fields: string[],
    defaultValue?: number
  ) {
    for (const field of fields) {
      target[field] = source[field] ? Number(source[field]) : defaultValue;
    }
    return target;
  },

  compareMoments(a: Moment | undefined, b: Moment | undefined) {
    if (a && b) {
      return a > b ? 1 : -1;
    } else if (a) {
      return 1;
    } else if (b) {
      return -1;
    } else {
      return 0;
    }
  },

  isDefined: <T>(arg: T | undefined): arg is T => {
    return typeof arg !== 'undefined' && arg !== null;
  },

  stringToMoment(value: string | null | undefined): moment.Moment | undefined {
    return value ? moment(value) : undefined;
  },

  stringToHTML(value: string) {
    let html = value.replace(/(?:\r\n|\r|\n)/g, '<br />');
    return html.replace(/(https?:\/\/[^\s]+)/g, function (url: string) {
      return '<a href="' + url + '" target="_blank">' + url + '</a>';
    });
  },

  showError: (err: any) => {
    if (errorShown) return;
    errorShown = true;

    let content = '';
    if (err.response && err.response.data && err.response.data.errors) {
      const { errors } = err.response.data;
      content = errors[0].msg;
    } else if (err.message) {
      content = err.message;
    } else {
      content = err;
    }

    // message.error(content);
    Modal.error({
      content,
      centered: true,
      onOk: () => {
        errorShown = false;
      },
      onCancel: () => {
        errorShown = false;
      },
    });
  },

  showSuccess: (content: string) => {
    // message.success(content);
    Modal.success({ content, centered: true });
  },

  // getAllWeeksInMonth: (year: number, month: number) => {
  //   const startDate = moment([year, month - 1]);
  //   const firstDay = moment(startDate).startOf('month');
  //   const endDay = moment(startDate).endOf('month');
  //   const monthRange = rangeMoment.range(firstDay, endDay);

  //   let weeks: number[] = [];
  //   for (const day of monthRange.by('days')) {
  //     const week = moment(day).week();
  //     if (_.includes(weeks, week) === false) weeks.push(week);
  //   }

  //   console.log(Util.formatDate(firstDay), Util.formatDate(endDay), weeks);

  //   let results: DateRange[] = [];
  //   for (const week of weeks) {
  //     const firstWeekDay = moment()
  //       .week(week)
  //       .day(1);
  //     const lastWeekDay = moment()
  //       .week(week)
  //       .day(7);

  //     const weekRange = rangeMoment.range(firstWeekDay, lastWeekDay);
  //     results.push(weekRange);
  //   }
  //   return results;
  // }

  getAllWeeksInMonth: (year: number, month: number) => {
    const startDate = moment([year, month - 1]);
    const firstDay = moment(startDate).startOf('month');
    const endDay = moment(startDate).endOf('month');
    const monthRange = rangeMoment.range(firstDay, endDay);

    // console.log(Util.formatDate(firstDay), Util.formatDate(endDay));

    let results: DateRange[] = [];
    let firstWeekday = firstDay;
    for (const day of monthRange.by('days')) {
      const thisDay = moment(day);
      if (thisDay.day() === 0) {
        firstWeekday = day;
      }
      if (thisDay.day() === 6 || thisDay.isSame(endDay, 'd')) {
        const weekRange = rangeMoment.range(firstWeekday, day);
        results.push(weekRange);
      }
    }

    return results;
  },
};

export default Util;
