import React, { ReactNode } from 'react';
import { Button } from 'antd';

interface Props {
  children: ReactNode;
  disabled?: boolean;
  style?: React.CSSProperties | undefined;
  onClick?: (event: React.MouseEvent<HTMLElement, MouseEvent>) => void;
}

const LinkButton: React.FC<Props> = (props: Props) => {
  return (
    <Button
      type="link"
      className="hl-no-padding"
      disabled={props.disabled}
      onClick={props.onClick}
      style={props.style}
    >
      {props.children}
    </Button>
  );
};

export default LinkButton;
