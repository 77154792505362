import React, { useState, useEffect } from 'react';
import { Form, Input, Button, Table, Icon, Switch } from 'antd';
import { FormComponentProps } from 'antd/lib/form';
import { User } from '../../../models/User';
import UserService from '../../../services/UserService';
import Util from '../../../helpers/Util';
import LinkButton from '../../general/LinkButton';
import FIDatePicker from '../../formcomps/FIDatePicker';

interface Props extends FormComponentProps {
  user: User;
}

const _UserFamilies = (props: Props) => {
  const { getFieldDecorator } = props.form;
  const { user } = props;

  const [items, setItems] = useState<any>([]);
  const [nextId, setNextId] = useState(1);

  useEffect(() => {
    //setLoading(true);
    UserService.fetchCardItems('families', user.id)
      .then(result => {
        const results = result.results.map((result: any) => {
          return {
            ...result,
            id: result.seq
          };
        });
        setNextId(results.length + 1);
        setItems(results);
        //setLoading(false);
      })
      .catch(error => Util.showError(error));
  }, [user]);

  const handleAddButtonClick = () => {
    let newItems = items.concat({
      id: nextId,
      cohabit: true,
      health_care: true
    });
    setNextId(nextId + 1);
    setItems(newItems);
  };

  const handleDeleteItem = (id: number) => {
    let newItems = items.filter((item: any) => item.id !== id);
    setItems(newItems);
  };

  const [submitting, setSubmitting] = useState(false);

  const handleSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    props.form.validateFields((err, values) => {
      if (err) return;

      let itemOrder = 1;
      const convertedValues = items.map((item: any) => {
        return {
          user_id: user.id,
          seq: itemOrder++,
          relation: values['relation' + item.id],
          name: values['name' + item.id],
          cohabit: values['cohabit' + item.id],
          birth_date: values['birth_date' + item.id],
          health_care: values['health_care' + item.id],
          job: values['job' + item.id],
          job_title: values['job_title' + item.id]
        };
      });
      updateItem(convertedValues, user);
    });
  };

  const updateItem = async (values: any, item: User) => {
    try {
      setSubmitting(true);
      UserService.updateCardItems('families', item.id, values);
      Util.showSuccess('업데이트 되었습니다.');
    } catch (err) {
      Util.showError(err);
    } finally {
      setSubmitting(false);
    }
  };

  const columns = [
    {
      title: '관계',
      dataIndex: 'relation',
      render: (text: string, record: any) => {
        return (
          <Form.Item>
            {getFieldDecorator('relation' + record.id, {
              initialValue: record.relation
            })(<Input />)}
          </Form.Item>
        );
      }
    },
    {
      title: '성명',
      dataIndex: 'name',
      render: (text: string, record: any) => {
        return (
          <Form.Item>
            {getFieldDecorator('name' + record.id, {
              initialValue: record.name
            })(<Input />)}
          </Form.Item>
        );
      }
    },
    {
      title: '생일',
      dataIndex: 'birth_date',
      render: (text: string, record: any) => {
        return (
          <FIDatePicker
            form={props.form}
            name={'birth_date' + record.id}
            initialValue={
              record.birth_date && Util.formatDate(record.birth_date)
            }
          />
        );
      }
    },
    {
      title: '동거',
      dataIndex: 'cohabit',
      render: (text: string, record: any) => {
        return (
          <Form.Item>
            {getFieldDecorator('cohabit' + record.id, {
              valuePropName: 'checked',
              initialValue: record.cohabit
            })(<Switch />)}
          </Form.Item>
        );
      }
    },
    {
      title: '건강보험 피부양자',
      dataIndex: 'health_care',
      render: (text: string, record: any) => {
        return (
          <Form.Item>
            {getFieldDecorator('health_care' + record.id, {
              valuePropName: 'checked',
              initialValue: record.health_care
            })(<Switch />)}
          </Form.Item>
        );
      }
    },
    {
      title: '작업',
      dataIndex: 'job',
      render: (text: string, record: any) => {
        return (
          <Form.Item>
            {getFieldDecorator('job' + record.id, {
              initialValue: record.job
            })(<Input />)}
          </Form.Item>
        );
      }
    },
    {
      title: '작장(학교)명',
      dataIndex: 'job_title',
      render: (text: string, record: any) => {
        return (
          <Form.Item>
            {getFieldDecorator('job_title' + record.id, {
              initialValue: record.job_title
            })(<Input />)}
          </Form.Item>
        );
      }
    },
    {
      render: (text: string, record: any) => {
        return (
          <LinkButton onClick={e => handleDeleteItem(record.id)}>
            <Icon type="close" />
          </LinkButton>
        );
      }
    }
  ];

  return (
    <>
      <Form onSubmit={handleSubmit}>
        <Table
          bordered
          size="middle"
          columns={columns}
          dataSource={items}
          pagination={false}
          rowKey="id"
        />
        <Button onClick={handleAddButtonClick} style={{ marginTop: 10 }}>
          <Icon type="plus" />열 추가
        </Button>{' '}
        <Button type="primary" htmlType="submit" disabled={submitting}>
          저 장
        </Button>
      </Form>
    </>
  );
};

const UserFamilies = Form.create<Props>()(_UserFamilies);
export default UserFamilies;
