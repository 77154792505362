import Service from './Index';

const UserService = {
  fetchDetail: (id: number) => {
    return Service.get(`/users/${id}`);
  },
  fetchCardItems: (target: string, id: number) => {
    return Service.get(`/users/${id}/${target}`);
  },
  updateCardItems: (target: string, id: number, data: any) => {
    return Service.put(`/users/${id}/${target}`, data);
  },
  resetMyPassword: (data: any) => {
    return Service.put('/users/me/password', data);
  },
  resetPassword: (user_id: number, data: any) => {
    return Service.put(`/users/${user_id}/password`, data);
  },
  fetchAllContracts: (
    limit: number,
    page: number,
    type?: number,
    keyword?: string
  ) => {
    const offset = (page - 1) * limit;
    return Service.get('/users/contracts', {
      limit,
      offset,
      type,
      keyword
    });
  },
  createContract: (user_id: number, data: any) => {
    return Service.post(`/users/${user_id}/contracts`, data);
  },
  updateContract: (user_id: number, seq: number, data: any) => {
    return Service.put(`/users/${user_id}/contracts/${seq}`, data);
  },
  deleteContract: (user_id: number, seq: number) => {
    return Service.delete(`/users/${user_id}/contracts/${seq}`);
  },
  createRNP: (user_id: number, data: any) => {
    return Service.post(`/users/${user_id}/rnps`, data);
  },
  updateRNP: (user_id: number, seq: number, data: any) => {
    return Service.put(`/users/${user_id}/rnps/${seq}`, data);
  },
  deleteRNP: (user_id: number, seq: number) => {
    return Service.delete(`/users/${user_id}/rnps/${seq}`);
  },
  createTransfer: (user_id: number, data: any) => {
    return Service.post(`/users/${user_id}/transfers`, data);
  },
  updateTransfer: (user_id: number, seq: number, data: any) => {
    return Service.put(`/users/${user_id}/transfers/${seq}`, data);
  },
  deleteTransfer: (user_id: number, seq: number) => {
    return Service.delete(`/users/${user_id}/transfers/${seq}`);
  },
  createETC: (user_id: number, data: any) => {
    return Service.post(`/users/${user_id}/etcs`, data);
  },
  updateETC: (user_id: number, seq: number, data: any) => {
    return Service.put(`/users/${user_id}/etcs/${seq}`, data);
  },
  deleteETC: (user_id: number, seq: number) => {
    return Service.delete(`/users/${user_id}/etcs/${seq}`);
  }
};

export default UserService;
