import React, { useState, useEffect } from 'react';
import { withRouter, RouteComponentProps } from 'react-router';
import { EADoc } from '../../../models/EAS';
import EADocListPanel from '../../eas/EADocListPanel';
import { Row, Col, Input, Icon, Form, Button } from 'antd';
import EAService, { EADocSearchOption } from '../../../services/EAService';
import Util from '../../../helpers/Util';
import FIHQSelect from '../../../components/formcomps/FIHQSelect';
import { FormComponentProps } from 'antd/lib/form';

interface Props extends RouteComponentProps, FormComponentProps {
  menu?: string;
  submenu?: string;
  deptId?: number;
  deptIds?: number[];
  hideDeptSelector?: boolean;
}

const OrderEADocList: React.FC<Props> = props => {
  const { menu = 'projects', submenu = 'main', deptId } = props;
  const pageSize = 15;
  const [loading, setLoading] = useState(false);
  const [items, setItems] = useState<EADoc[]>([]);
  const [totalCount, setTotalCount] = useState(0);
  const [currentPage, setCurrentPage] = useState(1);
  const [searchOption, setSearchOption] = useState<EADocSearchOption>({
    data_dept_id: deptId
  });
  const { getFieldDecorator } = props.form;

  useEffect(() => {
    const fetchItems = async () => {
      setLoading(true);
      try {
        const result = await EAService.fetchItems(pageSize, currentPage, {
          ...searchOption,
          ref_target: 'ord',
          menu
        });
        setTotalCount(result.total_count);
        setItems(EADoc.fromJsonArray(result.items));
      } catch (error) {
        Util.showError(error);
      } finally {
        setLoading(false);
      }
    };

    fetchItems();
  }, [currentPage, searchOption, pageSize, menu]);

  const onClickAddButton = () => {
    props.history.push(`/${menu}/${submenu}/draft/ord`);
  };

  const onSubmitSearchForm = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    props.form.validateFields((err, values) => {
      if (err) return;
      setSearchOption(values);
      setCurrentPage(1);
    });
  };

  const pagination = {
    pageSize,
    current: currentPage,
    total: totalCount,
    showTotal: (total: number, range: [number, number]) =>
      `전체 ${total}건 중 ${range[0]}-${range[1]}`
  };

  return (
    <>
      <h2>수주정보보고</h2>

      <Form
        className="hl-form"
        layout="inline"
        style={{ marginBottom: 7 }}
        onSubmit={onSubmitSearchForm}
      >
        <Row type="flex" justify="space-between">
          <Col>
            <Form.Item>
              {getFieldDecorator('keyword')(
                <Input.Search
                  placeholder="제목 또는 기안자명"
                  allowClear={true}
                  style={{ width: 150 }}
                />
              )}
            </Form.Item>

            <FIHQSelect
              form={props.form}
              name="data_dept_id"
              options={{ initialValue: deptId }}
              width="180px"
              allowClear
              hide={props.hideDeptSelector}
              deptIds={props.deptIds}
              onChange={value => {
                localStorage.setItem(
                  `${menu}DefaultDeptId`,
                  value ? String(value) : ''
                );
              }}
            />

            <Form.Item>
              <Button type="primary" htmlType="submit">
                검색
              </Button>
            </Form.Item>
          </Col>
          <Col>
            <Button onClick={onClickAddButton}>
              <Icon type="plus" />
              신규수주정보 보고
            </Button>
          </Col>
        </Row>
      </Form>

      <EADocListPanel
        items={items}
        loading={loading}
        pagination={pagination}
        onChangeCurrentPage={page => setCurrentPage(page)}
      />
    </>
  );
};

const _OrderEADocList = Form.create<Props>()(OrderEADocList);
export default withRouter(_OrderEADocList);
