import React, { useState, useEffect, useRef } from 'react';
import {
  Table,
  Button,
  Row,
  Col,
  Input,
  Form,
  InputNumber,
  Modal,
  Drawer,
  Tag
} from 'antd';
import LinkButton from '../../../components/general/LinkButton';
import { FormComponentProps } from 'antd/lib/form';
import { PaginationConfig } from 'antd/lib/table';
import { EducationUser } from '../../../models/Education';
import EducationService, {
  EducationSearchOption
} from '../../../services/EducationService';
import Util from '../../../helpers/Util';
import EducationDetail from './EducationDetail';
import EADocDrafter from '../../eas/EADocDrafter';
import EADocDetail from '../../eas/EADocDetail';

interface Props extends FormComponentProps {}

const MyEducationList: React.FC<Props> = props => {
  const [loading, setLoading] = useState(false);
  const [totalCount, setTotalCount] = useState(0);
  const [currentPage, setCurrentPage] = useState(1);
  const [searchOption, setSearchOption] = useState<EducationSearchOption>({});
  const [items, setItems] = useState<EducationUser[]>([]);
  const { getFieldDecorator } = props.form;

  const pagination = {
    pageSize: 15,
    current: currentPage,
    total: totalCount,
    showTotal: (total: number, range: [number, number]) =>
      `전체 ${total}건 중 ${range[0]}-${range[1]}`
  };

  useEffect(() => {
    const fetchItems = async () => {
      setLoading(true);
      try {
        const result = await EducationService.fetchMyItems(
          pagination.pageSize,
          currentPage,
          searchOption
        );
        setTotalCount(result.total_count);
        setItems(EducationUser.fromJsonArray(result.items));
      } catch (error) {
        Util.showError(error);
      } finally {
        setLoading(false);
      }
    };
    fetchItems();
  }, [currentPage, pagination.pageSize, searchOption]);

  const columns = [
    {
      title: '번호',
      dataIndex: 'education_id',
      align: 'right' as 'right',
      width: '100px'
    },
    {
      title: '교육명',
      dataIndex: 'education.title',
      render: (text: string, record: EducationUser) => (
        <LinkButton onClick={e => onClickItem(record)}>
          {record.title}
        </LinkButton>
      )
    },
    {
      title: '교육일자',
      dataIndex: 'education.date',
      width: 150,
      render: (text: string, record: EducationUser) =>
        Util.formatDateTimeHHMM(text)
    },
    {
      title: '출결상황',
      dataIndex: 'stateName',
      width: 80,
      render: (text: string, record: EducationUser) => {
        if (record.state === 3) {
          return <Tag color="red">{text}</Tag>;
        }
        return text;
      }
    },
    {
      title: '설문조사',
      dataIndex: 'poll',
      width: 100,
      render: (text: string, record: EducationUser) => {
        return 'N/A';
      }
    },
    {
      width: 100,
      render: (text: string, record: EducationUser) => {
        if (record.state === 3) {
          return (
            <LinkButton onClick={e => onClickReport(record)}>
              불출석사유서
            </LinkButton>
          );
        }
      }
    }
  ];

  const onChangeTable = (pagination: PaginationConfig) => {
    setCurrentPage(pagination.current || 1);
  };

  const onSubmitSearchForm = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    props.form.validateFields((err, values) => {
      if (err) return;
      setSearchOption(values);
      setCurrentPage(1);
    });
  };

  const itemRef = useRef<EducationUser>(new EducationUser());
  const [detailVisible, setDetailVisible] = useState(false);

  const onClickItem = (item: EducationUser) => {
    itemRef.current = item;
    setDetailVisible(true);
  };

  const onCloseDetail = () => {
    setDetailVisible(false);
  };

  const [detailInfo, setDetailInfo] = useState({
    show: false,
    ref_target: 'eduser',
    draft: false
  });

  const onClickReport = (item: EducationUser) => {
    itemRef.current = item;
    setDetailInfo({
      show: true,
      ref_target: 'eduser',
      draft: !item.eaid
    });
  };

  const onCloseDetailInfo = () => {
    setDetailInfo({ ...detailInfo, show: false });
  };

  const drafterTitle = '불출석사유서';
  const docTitle = `${drafterTitle} - ${itemRef.current.title}`;

  return (
    <>
      <h2>교육수강내역</h2>

      <Form
        className="hl-form"
        layout="inline"
        style={{ marginBottom: 7 }}
        onSubmit={onSubmitSearchForm}
      >
        <Row type="flex" justify="space-between">
          <Col>
            <Form.Item>
              {getFieldDecorator('keyword')(
                <Input.Search
                  placeholder="검색어"
                  allowClear={true}
                  style={{ width: 150 }}
                />
              )}
            </Form.Item>

            <Form.Item>
              {getFieldDecorator('year')(
                <InputNumber placeholder="년도" style={{ width: 100 }} />
              )}
            </Form.Item>

            <Form.Item>
              <Button type="primary" htmlType="submit">
                검색
              </Button>
            </Form.Item>
          </Col>
          <Col></Col>
        </Row>
      </Form>

      <Table
        columns={columns}
        dataSource={items}
        pagination={pagination}
        onChange={onChangeTable}
        rowKey="education_id"
        loading={loading}
        bordered={true}
        size="middle"
      />

      <Modal
        centered
        width={800}
        visible={detailVisible}
        footer={null}
        onCancel={() => onCloseDetail()}
        destroyOnClose={true}
      >
        <EducationDetail id={itemRef.current.education_id} />
      </Modal>

      <Drawer
        closable={false}
        placement="right"
        width="85%"
        visible={detailInfo.show}
        onClose={onCloseDetailInfo}
        destroyOnClose={true}
      >
        {detailInfo.draft ? (
          <EADocDrafter
            title={drafterTitle}
            docTitle={docTitle}
            refTarget={detailInfo.ref_target}
            refId={itemRef.current.id}
            fetchDelayed={true}
          />
        ) : (
          <EADocDetail
            refTarget={detailInfo.ref_target}
            refId={itemRef.current.id}
            fetchDelayed={true}
          />
        )}
      </Drawer>
    </>
  );
};

export default Form.create<Props>()(MyEducationList);
