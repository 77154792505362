import React, { useState, useEffect } from 'react';
import { Button, Form, InputNumber } from 'antd';
import { FormComponentProps } from 'antd/lib/form';
import Util from '../../../helpers/Util';
import FCNumberInput from '../../../components/formcomps/FCNumberInput';
import FCTextInput from '../../../components/formcomps/FCTextInput';
import OrderService from '../../../services/OrderService';
import { Order } from '../../../models/Order';

interface Props extends FormComponentProps {
  id: number;
  onSuccess?: (order: Order) => void;
}

const OrderExtraEdit: React.FC<Props> = props => {
  const [, setLoading] = useState(true);
  const [order, setOrder] = useState<Order>(new Order());

  useEffect(() => {
    const fetchItem = async (id: number) => {
      setLoading(true);
      try {
        const result = await OrderService.fetchItem(id);
        const order = Order.fromJson(result.order);
        setOrder(order);
      } catch (error) {
        Util.showError(error);
      } finally {
        setLoading(false);
      }
    };

    fetchItem(props.id);
  }, [props.id]);

  const [submitting, setSubmitting] = useState(false);

  const onSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    props.form.validateFields((err, values) => {
      if (err) return;
      processItem(values);
    });
  };

  const processItem = async (values: any) => {
    try {
      setSubmitting(true);
      const result = await OrderService.updateAndFetch(props.id, values);
      const updatedOrder = Order.fromJson(result.order);
      props.onSuccess && props.onSuccess(updatedOrder);
      Util.showSuccess('저장되었습니다.');
    } catch (err) {
      Util.showError(err);
      setSubmitting(false);
    }
  };

  return (
    <>
      <h2>{order.name}</h2>

      <Form
        className="hl-form"
        labelCol={{ span: 4 }}
        wrapperCol={{ span: 18 }}
        onSubmit={onSubmit}
      >
        <Form.Item label="수주금액">
          <InputNumber
            value={order.in_total_price}
            formatter={value => Util.formatNumber(value)}
            style={{ width: 150 }}
            disabled
          />
        </Form.Item>

        <Form.Item label="핵심수주예상금액">
          <FCNumberInput
            form={props.form}
            name="core_est_price"
            options={{ initialValue: order.core_est_price }}
          />
        </Form.Item>

        <Form.Item label="진성수주계약">
          <FCNumberInput
            form={props.form}
            name="key_order_price"
            options={{ initialValue: order.key_order_price }}
          />
        </Form.Item>

        <Form.Item label="진행사항">
          <FCTextInput
            form={props.form}
            name="status_note"
            options={{ initialValue: order.status_note }}
          />
        </Form.Item>

        <Form.Item label="이슈">
          <FCTextInput
            form={props.form}
            name="issue"
            options={{ initialValue: order.issue }}
          />
        </Form.Item>

        <Form.Item label="주요업무">
          <FCTextInput
            form={props.form}
            name="task"
            options={{ initialValue: order.task }}
          />
        </Form.Item>

        <Form.Item label="비고">
          <FCTextInput
            form={props.form}
            name="remark"
            options={{ initialValue: order.remark }}
          />
        </Form.Item>

        <Form.Item wrapperCol={{ span: 12, offset: 4 }}>
          <Button type="primary" htmlType="submit" disabled={submitting}>
            저 장
          </Button>
        </Form.Item>
      </Form>
    </>
  );
};

export default Form.create<Props>()(OrderExtraEdit);
