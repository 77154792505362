import React, { useState } from 'react';
import { Button, Form, Input } from 'antd';
import { FormComponentProps } from 'antd/lib/form';
import Util from '../../helpers/Util';
import FCFileInput from '../../components/formcomps/FCFileInput';
import FIDatePicker from '../../components/formcomps/FIDatePicker';
import { PageLog } from '../../models/Page';
import moment from 'moment';
import PageService from '../../services/PageService';
import _ from 'lodash';

interface Props extends FormComponentProps {
  pageId: number;
  item?: PageLog;
  onSuccess?: () => void;
}

const PageLogEdit: React.FC<Props> = props => {
  const { pageId, item } = props;
  const [submitting, setSubmitting] = useState(false);
  const { getFieldDecorator } = props.form;

  const onSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    props.form.validateFields((err, values) => {
      if (err) return;
      processItem(values);
    });
  };

  const processItem = async (values: any) => {
    try {
      //   console.log(values);

      values.date = values.date && Util.formatDate(values.date);
      if (values.file_ids && values.file_ids.length > 0) {
        values.file_id = values.file_ids[0];
      } else {
        values.file_id = null;
      }
      const data = _.omit(values, 'file_ids');

      setSubmitting(true);

      if (item) {
        data.page_id = item.page_id;
        await PageService.updatePageLog(item.id, data);
      } else {
        await PageService.createPageLog(pageId, data);
      }

      Util.showSuccess('저장되었습니다.');
      if (props.onSuccess) props.onSuccess();
    } catch (err) {
      Util.showError(err);
      setSubmitting(false);
    }
  };

  return (
    <>
      <h2>변경내역 {item ? '수정' : '등록'}</h2>

      <Form
        className="hl-form"
        labelCol={{ span: 4 }}
        wrapperCol={{ span: 18 }}
        onSubmit={onSubmit}
      >
        <FIDatePicker
          form={props.form}
          name="date"
          label="일자"
          initialValue={
            (item && Util.formatDate(item.date)) || Util.formatDate(moment())
          }
        />

        <Form.Item label="변경내용">
          {getFieldDecorator('content', {
            initialValue: item && item.content
          })(<Input />)}
        </Form.Item>

        <Form.Item label="변경사유">
          {getFieldDecorator('note', {
            initialValue: item && item.note
          })(<Input />)}
        </Form.Item>

        <Form.Item label="관련자료">
          <FCFileInput form={props.form} items={item && item.files} single />
        </Form.Item>

        <Form.Item wrapperCol={{ span: 12, offset: 4 }}>
          <Button type="primary" htmlType="submit" disabled={submitting}>
            저 장
          </Button>
        </Form.Item>
      </Form>
    </>
  );
};

export default Form.create<Props>()(PageLogEdit);
