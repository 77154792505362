import React, { useState, useEffect } from 'react';
import { Card, Button, Dropdown, Menu } from 'antd';
import EAFlowView from './EAFlowView';
import EAFlowEditor from './EAFlowEditor';
import { EAFlowItem, EAFlowTemplate, EAFlowItemType } from '../../models/EAS';
import Util from '../../helpers/Util';
import EAFlowTemplateService from '../../services/EAFlowTemplateService';
import { ClickParam } from 'antd/lib/menu';
import { User } from '../../models/User';
import AppService from '../../services/AppService';
import _ from 'lodash';

interface Props {
  eaItems: EAFlowItem[];
  ccItems: EAFlowItem[];
  refTarget?: string;
  hideOpenButton?: boolean;
  onChange: (eaItems: EAFlowItem[], ccItems: EAFlowItem[]) => void;
}

const EADocEditorFlowPanel: React.FC<Props> = (props) => {
  const { hideOpenButton = false } = props;
  // const [eaItems, setEAItems] = useState<EAFlowItem[]>(props.eaItems);
  // const [ccItems, setCCItems] = useState<EAFlowItem[]>(props.ccItems);
  const [eaItemsApproved, setEAItemsApproved] = useState<EAFlowItem[]>([]);
  const [eaItemsWaiting, setEAItemsWaiting] = useState<EAFlowItem[]>([]);
  const [editorVisible, setEditorVisible] = useState(false);

  // 결재선 편집시 대기중 항목들만 편집가능토록 처리하기 위해 승인항목과 대기중항목 분리
  useEffect(() => {
    // setEAItems(props.eaItems);
    const apprvoedItems = props.eaItems.filter((e) => e.action !== null);
    const waitingItems = props.eaItems.filter((e) => e.action === null);
    setEAItemsApproved(apprvoedItems);
    setEAItemsWaiting(waitingItems);
  }, [props.eaItems]);

  // useEffect(() => {
  //   setCCItems(props.ccItems);
  // }, [props.ccItems]);

  const [extraItems, setExtraItems] = useState<EAFlowItem[]>([]);
  useEffect(() => {
    const fetchExtraForVac = async () => {
      try {
        const result = await AppService.fetchStoreValues(['vac_manager']);
        if (result && result.vac_manager) {
          const manager = User.fromJson(result.vac_manager);
          const flowItem = new EAFlowItem(manager, EAFlowItemType.approve);
          setExtraItems([flowItem]);
        }
      } catch (error) {
        Util.showError(error);
      }
    };

    const fetchExtraForTrip = async () => {
      try {
        const result = await AppService.fetchStoreValues(['trip_manager']);
        if (result && result.trip_manager) {
          const manager = User.fromJson(result.trip_manager);
          const flowItem = new EAFlowItem(manager, EAFlowItemType.approve);
          setExtraItems([flowItem]);
        }
      } catch (error) {
        Util.showError(error);
      }
    };

    if (props.refTarget) {
      if (props.refTarget === 'vac') {
        fetchExtraForVac();
      } else if (props.refTarget === 'trip' || props.refTarget === 'trrpt') {
        fetchExtraForTrip();
      }
    }
  }, [props.refTarget]);

  const [items, setItems] = useState<EAFlowTemplate[]>([]);
  useEffect(() => {
    const fetchItems = async () => {
      try {
        const result = await EAFlowTemplateService.fetchItems();
        setItems(EAFlowTemplate.fromJsonArray(result.items));
      } catch (error) {
        Util.showError(error);
      }
    };

    fetchItems();
  }, []);

  const onClickEditButton = () => {
    setEditorVisible(true);
  };

  const onCancelEAFlowEditor = () => {
    setEditorVisible(false);
  };

  const onOkEAFlowEditor = (items1: EAFlowItem[], items2: EAFlowItem[]) => {
    setEditorVisible(false);
    // applyFlowItems(items1, items2);
    applyFlowItems([...eaItemsApproved, ...items1], items2);
  };

  const onClickTemplateMenu = (e: ClickParam) => {
    const template = items.find((item) => item.id === Number(e.key));
    if (template) {
      const ccItems = template.cc_users.map(
        (user) => new EAFlowItem(user, EAFlowItemType.refer)
      );
      applyFlowItems(template.flow_items, ccItems);
    }
  };

  const applyFlowItems = (items1: EAFlowItem[], items2: EAFlowItem[]) => {
    // NOTE: items1에 이미 extraItems 항목이 포함된 경우 제거
    // extraItems를 항상 마지막에 추가하기 위함
    const duplicatesRemoved = _.reject(items1, (e) => {
      return _.findIndex(extraItems, (item) => item.user.id === e.user.id) >= 0;
    });
    props.onChange([...duplicatesRemoved, ...extraItems], items2);
  };

  const EAFlowTemplateMenu =
    items.length === 0 ? (
      <Menu>
        <Menu.Item key="0">결재선이 없습니다.</Menu.Item>
      </Menu>
    ) : (
      <Menu onClick={onClickTemplateMenu}>
        {items.map((item) => (
          <Menu.Item key={item.id}>{item.name}</Menu.Item>
        ))}
      </Menu>
    );

  return (
    <>
      <Card
        size="small"
        title="결재선"
        extra={
          <>
            {!hideOpenButton && (
              <>
                <Dropdown overlay={EAFlowTemplateMenu} trigger={['click']}>
                  <Button icon="folder-open" />
                </Dropdown>{' '}
              </>
            )}
            <Button icon="edit" onClick={onClickEditButton} />
          </>
        }
      >
        <EAFlowView items={props.eaItems} />
      </Card>

      <EAFlowEditor
        initialCCItems={props.ccItems}
        initialEAItems={eaItemsWaiting}
        visible={editorVisible}
        onCancel={onCancelEAFlowEditor}
        onOk={onOkEAFlowEditor}
      />
    </>
  );
};

export default EADocEditorFlowPanel;
