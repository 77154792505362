import React, { useState, useEffect } from 'react';
import { Button, Input } from 'antd';
import { EADoc } from '../../models/EAS';
import EADocSelectorDrawer from '../../pages/eas/EADocSelectorDrawer';
import { FormComponentProps } from 'antd/lib/form';
import AttachedEADocList from '../general/AttachedEADocList';
import _ from 'lodash';

interface Props extends FormComponentProps {
  items?: EADoc[];
  single?: boolean;
}

const FCEADocInput: React.FC<Props> = props => {
  const { single = false } = props;
  const { getFieldDecorator, setFieldsValue } = props.form;
  const [items, setItems] = useState<EADoc[]>([]);

  useEffect(() => {
    setItems(props.items || []);
  }, [props.items]);

  useEffect(() => {
    setFieldsValue({
      eadoc_ids: items.map(e => e.id)
    });
  }, [items, setFieldsValue]);

  const [selectorVisible, setSelectorVisible] = useState(false);

  const handleSelectDoc = () => {
    setSelectorVisible(true);
  };

  const handleSelectorCancel = () => {
    setSelectorVisible(false);
  };

  const handleSelectorOk = (selectedItems: EADoc[]) => {
    setSelectorVisible(false);
    if (selectedItems.length === 0) return;

    if (single) {
      setItems([selectedItems[0]]);
    } else {
      setItems(_.unionBy(items, selectedItems, 'id'));
    }
  };

  const onClickRemove = (itemToDelete: EADoc) => {
    const updatedItems = items.filter(item => item.id !== itemToDelete.id);
    setItems(updatedItems);
  };

  return (
    <>
      {getFieldDecorator('eadoc_ids')(<Input type="hidden" />)}
      <Button disabled={single && items.length >= 1} onClick={handleSelectDoc}>
        문서 선택...
      </Button>
      <EADocSelectorDrawer
        visible={selectorVisible}
        onCancel={handleSelectorCancel}
        onOk={handleSelectorOk}
      />

      <AttachedEADocList items={items} onClickRemove={onClickRemove} />
    </>
  );
};

export default FCEADocInput;
