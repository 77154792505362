import React, { useState, useEffect } from 'react';
import {
  Table,
  Button,
  Input,
  Row,
  Col,
  Form,
  Drawer,
  Icon,
  Select
} from 'antd';
import { User, UserContract } from '../../models/User';
import { RouteComponentProps } from 'react-router';
import LinkButton from '../general/LinkButton';
import Util from '../../helpers/Util';
import { FormComponentProps } from 'antd/lib/form';
import { File } from '../../models/File';
import { PaginationConfig } from 'antd/lib/table';
import UserDetail from './UserDetail';
import UserService from '../../services/UserService';
import AButton from '../general/AButton';
import FileService from '../../services/FileService';

const CONTRACT_TYPES = [
  '임원',
  '정규직',
  '프로젝트 계약직',
  '일반 계약직',
  '파견직'
];

interface Props extends FormComponentProps, RouteComponentProps {}

const UserContractList: React.FC<Props> = props => {
  const params = new URLSearchParams(props.location.search);
  const page = Number(params.get('page')) || 1;
  const keyword = params.get('keyword') || undefined;
  const type = Number(params.get('type')) || undefined;
  const { getFieldDecorator } = props.form;

  const [items, setItems] = useState<UserContract[]>([]);
  const [totalCount, setTotalCount] = useState(0);
  const [loading, setLoading] = useState(false);

  const pagination = {
    pageSize: 15,
    current: page,
    total: totalCount,
    showTotal: (total: number, range: [number, number]) =>
      `전체 ${total}건 중 ${range[0]}-${range[1]}`
  };

  useEffect(() => {
    const fetchItems = async () => {
      setLoading(true);
      try {
        const result = await UserService.fetchAllContracts(
          pagination.pageSize,
          page,
          type,
          keyword
        );
        setTotalCount(result.total_count);
        setItems(UserContract.fromJsonArray(result.items));
      } catch (error) {
        Util.showError(error);
      } finally {
        setLoading(false);
      }
    };
    fetchItems();
  }, [page, keyword, pagination.pageSize, type]);

  const columns = [
    {
      title: '사번',
      dataIndex: 'user_id',
      width: 60,
      align: 'right' as 'right'
    },
    {
      title: '이름',
      dataIndex: 'user.name',
      width: 70,
      render: (text: string, record: UserContract) => (
        <LinkButton onClick={e => handleItemClick(record.user)}>
          {record.user.name}
        </LinkButton>
      )
    },
    {
      title: '소속회사',
      dataIndex: 'corp_id',
      width: 130,
      render: (text: string, record: UserContract) => record.user.compName
    },
    {
      title: '부서',
      dataIndex: 'dept_id',
      width: 80,
      render: (text: string, record: UserContract) => record.user.deptName
    },
    {
      title: '직위',
      dataIndex: 'rank_id',
      width: 70,
      render: (text: string, record: UserContract) => record.user.rankName
    },
    {
      title: '직계구분',
      width: 100,
      dataIndex: 'typeName'
    },
    {
      title: '계약시작일',
      dataIndex: 'begin_date',
      width: 90,
      render: (text: string, record: UserContract) => Util.formatDate(text)
    },
    {
      title: '계약종료일',
      dataIndex: 'end_date',
      width: 90,
      render: (text: string, record: UserContract) => Util.formatDate(text)
    },
    {
      title: '근로계약서',
      dataIndex: 'files',
      render: (text: string, record: UserContract) => {
        return (
          <>
            {record.files.map(file => (
              <span key={file.id} style={{ display: 'block' }}>
                <LinkButton onClick={e => onClickFile(file)}>
                  <Icon type="download" />
                </LinkButton>{' '}
                {file.isPreviewable ? (
                  <AButton onClick={e => FileService.previewFile(file)}>
                    {file.original_name}
                  </AButton>
                ) : (
                  <>{file.original_name}</>
                )}
              </span>
            ))}
          </>
        );
      }
    },
    {
      title: '비고',
      dataIndex: 'note',
      width: 100
    }
  ];

  const onClickFile = async (item: File) => {
    try {
      setLoading(true);
      await FileService.downloadFile(item.id, item.original_name);
    } catch (error) {
      Util.showError(error);
    } finally {
      setLoading(false);
    }
  };

  const [currentItem, setCurrentItem] = useState<User>(new User());
  const [detailVisible, setDetailVisible] = useState(false);
  const onCloseDetail = () => {
    setDetailVisible(false);
    // if (paybookDirtyRef.current) {
    //   paybookDirtyRef.current = false;
    //   setRefreshTrigger(v => v + 1);
    // }
  };

  const handleItemClick = (item: User) => {
    setCurrentItem(item);
    setDetailVisible(true);
  };

  const onSubmitSearchForm = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    props.form.validateFields((err, values) => {
      if (err) return;
      pushHistory(1, values.keyword || '', values.type || '');
    });
  };

  const onChangeTable = (pagination: PaginationConfig) => {
    pushHistory(pagination.current || 1, keyword || '', type || '');
  };

  const pushHistory = (
    page: number,
    keyword: string,
    type: number | string
  ) => {
    props.history.push(
      `${props.location.pathname}?page=${page}&keyword=${keyword}&type=${type}`
    );
  };

  return (
    <>
      <h2>근로계약기간 관리</h2>

      <Form
        className="hl-form"
        layout="inline"
        style={{ marginBottom: 7 }}
        onSubmit={onSubmitSearchForm}
      >
        <Row type="flex" justify="space-between">
          <Col>
            <Form.Item>
              {getFieldDecorator('keyword', { initialValue: keyword })(
                <Input.Search
                  placeholder="검색어"
                  allowClear={true}
                  style={{ width: 200 }}
                />
              )}
            </Form.Item>

            <Form.Item>
              {getFieldDecorator(`type`, { initialValue: type })(
                <Select
                  placeholder="직계구분"
                  allowClear
                  style={{ width: '150px' }}
                >
                  {CONTRACT_TYPES.map((e, index) => (
                    <Select.Option value={index + 1} key={e}>
                      {e}
                    </Select.Option>
                  ))}
                </Select>
              )}
            </Form.Item>

            <Form.Item>
              <Button type="primary" htmlType="submit">
                검색
              </Button>
            </Form.Item>
          </Col>
          <Col></Col>
        </Row>
      </Form>

      <Table
        columns={columns}
        dataSource={items}
        rowKey="id"
        onChange={onChangeTable}
        pagination={pagination}
        loading={loading}
        bordered={true}
        size="middle"
      />

      <Drawer
        closable={false}
        placement="right"
        width={'85%'}
        visible={detailVisible}
        onClose={onCloseDetail}
        destroyOnClose={true}
      >
        <UserDetail id={currentItem.id} />
      </Drawer>
    </>
  );
};

export default Form.create<Props>()(UserContractList);
