import Service from './Index';

export type OrderSearchOption = {
  keyword?: string;
  year?: number;
  hq?: string;
  dept_id?: number;
  kind?: string;
  use?: string;
  state?: string;
  target?: string;
  is_new?: boolean;
  is_urgent?: boolean;
  has_issue?: boolean;
  sort_field?: string;
  sort_order?: string;
};

const OrderService = {
  fetchItems: (limit: number, page: number, options?: OrderSearchOption) => {
    const offset = (page - 1) * limit;
    return Service.get('/orders', {
      limit,
      offset,
      ...options
    });
  },

  fetchItemsForReport: (options?: OrderSearchOption) => {
    return Service.get('/orders/reports', options);
  },

  fetchItem: (id: number, columns?: string) => {
    return Service.get(`/orders/${id}`, { columns });
  },
  update: (id: number, data: any) => {
    return Service.put(`/orders/${id}`, data);
  },
  updateAndFetch: (id: number, data: any) => {
    return Service.put(`/orders/${id}`, { ...data, request_updated: true });
  },
  updateStages: (id: number, data: any) => {
    return Service.put(`/orders/${id}/stages`, { data });
  },
  fetchContract: (id: number, company_id: number, out: boolean) => {
    return Service.get(`/orders/${id}/contract`, { company_id, out });
  },
  fetchContractById: (id: number) => {
    return Service.get(`/orders/contracts/${id}`);
  },
  fetchContracts: (order_id: number, out: boolean) => {
    return Service.get(`/orders/${order_id}/contracts`, { out });
  },
  updateContract: (contract_id: number, data: any) => {
    return Service.put(`/orders/contracts/${contract_id}`, { data });
  },

  fetchContractItems: (contract_id: number) => {
    return Service.get(`/orders/contracts/${contract_id}/items`);
  },
  updateContractItem: (contract_id: number, seq: number, data: any) => {
    return Service.put(`/orders/contracts/${contract_id}/items/${seq}`, {
      data
    });
  },

  // contract_pays
  fetchContractPays: (order_id: number, out: boolean) => {
    return Service.get(`/orders/${order_id}/contract_pays`, { out });
  },
  createContractPay: (data: any) => {
    return Service.post(`/orders/contract_pays`, { data });
  },
  updateContractPay: (id: number, data: any) => {
    return Service.put(`/orders/contract_pays/${id}`, { data });
  },
  deleteContractPay: (id: number) => {
    return Service.delete(`/orders/contract_pays/${id}`);
  }
};

export default OrderService;
