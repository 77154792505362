import React, { useState, useEffect } from 'react';
import {
  Table,
  Button,
  Row,
  Col,
  Input,
  Form,
  InputNumber,
  Drawer
} from 'antd';
import LinkButton from '../../../components/general/LinkButton';
import { FormComponentProps } from 'antd/lib/form';
import { PaginationConfig } from 'antd/lib/table';
import Util from '../../../helpers/Util';
import EADocDrafter from '../../eas/EADocDrafter';
import EADocDetail from '../../eas/EADocDetail';
import TripService, { TripSearchOption } from '../../../services/TripService';
import { TripReport } from '../../../models/Trip';
import { EADocState } from '../../../models/EAS';
import { User } from '../../../models/User';
import AppService from '../../../services/AppService';
import UserPicker from '../../../components/general/UserPicker';

interface Props extends FormComponentProps {}

// TODO: 리팩토링 필요 - MyTripList와 중복
const AllTripList: React.FC<Props> = props => {
  const [loading, setLoading] = useState(false);
  const [totalCount, setTotalCount] = useState(0);
  const [currentPage, setCurrentPage] = useState(1);
  // const [searchOption, setSearchOption] = useState<TripSearchOption>({
  //   user_id: authStore.user.id
  // });
  const [searchOption, setSearchOption] = useState<TripSearchOption>({});
  const [items, setItems] = useState<TripReport[]>([]);
  const { getFieldDecorator } = props.form;

  const pagination = {
    pageSize: 15,
    current: currentPage,
    total: totalCount,
    showTotal: (total: number, range: [number, number]) =>
      `전체 ${total}건 중 ${range[0]}-${range[1]}`
  };

  useEffect(() => {
    const fetchItems = async () => {
      setLoading(true);
      try {
        const result = await TripService.fetchTripReports(
          pagination.pageSize,
          currentPage,
          searchOption
        );
        setTotalCount(result.total_count);
        setItems(TripReport.fromJsonArray(result.items));
      } catch (error) {
        Util.showError(error);
      } finally {
        setLoading(false);
      }
    };
    fetchItems();
  }, [currentPage, pagination.pageSize, searchOption]);

  const columns = [
    {
      title: '번호',
      dataIndex: 'id',
      align: 'right' as 'right',
      width: 70
    },
    {
      title: '구분',
      dataIndex: 'type',
      width: 50,
      render: (text: string, record: TripReport) => {
        return record.trip.type === 1 ? '출장' : '외근';
      }
    },
    {
      title: '출장/외근명',
      dataIndex: 'trip.trip_title',
      render: (text: string, record: TripReport) => (
        <LinkButton onClick={e => onClickItem(record)}>{text}</LinkButton>
      )
    },
    {
      title: '출장/외근 기간',
      dataIndex: 'trip.durationSummary',
      width: 250
    },
    {
      title: '신청인',
      dataIndex: 'trip.user.name',
      width: 100
    },
    {
      title: '출장자',
      dataIndex: 'user.name',
      width: 100
    },
    {
      title: '상태',
      dataIndex: 'eaid',
      width: 100,
      render: (text: string, record: TripReport) => {
        if (record.eaid) {
          return (
            <LinkButton onClick={e => onClickViewReport(record)}>
              {record.ea_state === EADocState.done ? '결재완료' : '결재중'}
            </LinkButton>
          );
        } else {
          return '보고서 미상신';
        }
      }
    },
    {
      title: '청구금액',
      dataIndex: 'request_total',
      align: 'right' as 'right',
      width: 120,
      render: (text: string, record: TripReport) => {
        return record.eaid ? record.f_request_total : 'N/A';
      }
    },
    {
      title: '승인금액',
      dataIndex: 'approve_total',
      align: 'right' as 'right',
      width: 120,
      render: (text: string, record: TripReport) => {
        return record.eaid && record.ea_state === EADocState.done
          ? record.f_approve_total
          : 'N/A';
      }
    }
  ];

  const onChangeTable = (pagination: PaginationConfig) => {
    setCurrentPage(pagination.current || 1);
  };

  const onSubmitSearchForm = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    props.form.validateFields((err, values) => {
      if (err) return;
      setSearchOption({ ...values });
      setCurrentPage(1);
    });
  };

  const [detailInfo, setDetailInfo] = useState<any>({
    show: false,
    draft: false,
    ref_target: '',
    ref_id: undefined,
    id: undefined,
    doc_title: ''
  });

  const onClickItem = (item: TripReport) => {
    setDetailInfo({
      show: true,
      draft: false,
      ref_target: 'trip',
      ref_id: item.trip.id,
      id: undefined
    });
  };

  const onClickViewReport = (item: TripReport) => {
    setDetailInfo({
      show: true,
      draft: false,
      // ref_target: 'trrpt',
      // ref_id: report.id,
      // id: undefined
      ref_target: undefined,
      ref_id: undefined,
      id: item.eaid
    });
  };

  const onCloseDetailInfo = () => {
    setDetailInfo({ ...detailInfo, show: false });
  };

  // const [submitting, setSubmitting] = useState(false);
  const [tripManager, setTripManager] = useState<User | undefined>(undefined);
  useEffect(() => {
    const fetchInfo = async () => {
      setLoading(true);
      try {
        const result = await AppService.fetchStoreValues(['trip_manager']);
        if (result && result.trip_manager) {
          setTripManager(User.fromJson(result.trip_manager));
        }
      } catch (error) {
        Util.showError(error);
      } finally {
        setLoading(false);
      }
    };

    fetchInfo();
  }, []);

  const [userPickerVisible, setUserPickerVisible] = useState(false);

  const onClickTripManager = () => {
    setUserPickerVisible(true);
  };

  const onCancelUserPicker = () => {
    setUserPickerVisible(false);
  };

  const onOkUserPicker = async (selectedItems: User[]) => {
    setUserPickerVisible(false);
    if (selectedItems.length <= 0) return;
    const user = selectedItems[0];
    try {
      await AppService.updateStoreValues('trip_manager', {
        id: user.id,
        name: user.name
      });
      setTripManager(user);
      Util.showSuccess('처리되었습니다.');
    } catch (error) {
      Util.showError(error);
    }
  };

  return (
    <>
      <h2>출장비 정산</h2>

      <Form
        className="hl-form"
        layout="inline"
        style={{ marginBottom: 7 }}
        onSubmit={onSubmitSearchForm}
      >
        <Row type="flex" justify="space-between">
          <Col>
            <Form.Item>
              {getFieldDecorator('keyword')(
                <Input.Search
                  placeholder="검색어"
                  allowClear={true}
                  style={{ width: 150 }}
                />
              )}
            </Form.Item>

            <Form.Item>
              {getFieldDecorator('year')(
                <InputNumber placeholder="년도" style={{ width: 100 }} />
              )}
            </Form.Item>

            <Form.Item>
              <Button type="primary" htmlType="submit">
                검색
              </Button>
            </Form.Item>

            <Form.Item>
              <Button onClick={onClickTripManager}>
                인사관리 담당 {(tripManager && ` : ${tripManager.name}`) || ''}
              </Button>{' '}
            </Form.Item>
          </Col>
          <Col></Col>
        </Row>
      </Form>

      <Table
        columns={columns}
        dataSource={items}
        pagination={pagination}
        onChange={onChangeTable}
        rowKey="id"
        loading={loading}
        bordered={true}
        size="middle"
      />

      <Drawer
        closable={false}
        placement="right"
        width="85%"
        visible={detailInfo.show}
        onClose={onCloseDetailInfo}
        destroyOnClose={true}
      >
        {detailInfo.draft ? (
          <EADocDrafter
            title="출장결과보고"
            docTitle={detailInfo.doc_title}
            refTarget={detailInfo.ref_target}
            refId={detailInfo.ref_id}
            fetchDelayed={true}
          />
        ) : (
          <EADocDetail
            id={detailInfo.id}
            refTarget={detailInfo.ref_target}
            refId={detailInfo.ref_id}
            fetchDelayed={true}
          />
        )}
      </Drawer>

      <UserPicker
        title="인사관리 담당 지정"
        visible={userPickerVisible}
        onCancel={onCancelUserPicker}
        onOk={onOkUserPicker}
      />
    </>
  );
};

export default Form.create<Props>()(AllTripList);
