import React, { useState } from 'react';
import { Form, Input, Button, Row, Col } from 'antd';
import { FormComponentProps } from 'antd/lib/form';
import Util from '../../helpers/Util';
import PlanService from '../../services/PlanService';
import { AuthStore } from '../../stores/authStore';
import { observer, inject } from 'mobx-react';
import InlineItem from '../../components/formcomps/InlineItem';
import TextArea from 'antd/lib/input/TextArea';
import { Plan } from '../../models/Plan';
import FIDatePicker from '../../components/formcomps/FIDatePicker';

interface Props extends FormComponentProps {
  item?: Plan;
  authStore?: AuthStore;
  onSuccessEditing?: () => void;
}

const PlanEditor: React.FC<Props> = props => {
  const { item } = props;
  const authStore = props.authStore!;
  const { getFieldDecorator } = props.form;

  const [submitting, setSubmitting] = useState(false);

  const onClickSubmitButton = () => {
    props.form.validateFields((err, values) => {
      if (err) return;
      processItem(values);
    });
  };

  const processItem = async (values: any) => {
    try {
      setSubmitting(true);
      item
        ? await PlanService.update(item.id, values)
        : await PlanService.create(values);
      Util.showSuccess('저장되었습니다.');
      setSubmitting(false);
      if (props.onSuccessEditing) props.onSuccessEditing();
    } catch (err) {
      Util.showError(err);
      setSubmitting(false);
    }
  };

  const formItemLayout = {
    labelCol: { span: 4 },
    wrapperCol: { span: 19 }
  };

  console.count('PlanEditor');

  const user = item ? item.owner : authStore.user;

  return (
    <Row gutter={12}>
      <Col className="gutter-row" span={20}>
        <Form className="hl-form" {...formItemLayout}>
          <Form.Item label="작성자">
            <InlineItem width="40%">
              <Input value={user.nameWithDeptRank} readOnly />
            </InlineItem>
          </Form.Item>

          <FIDatePicker
            form={props.form}
            label="날짜"
            initialValue={item && item.date && Util.formatDate(item.date)}
            required="날짜를 입력해주세요"
          />

          <Form.Item label="내용">
            {getFieldDecorator('content', {
              initialValue: item && item.content,
              rules: [
                {
                  required: true,
                  message: '내용을 입력해주세요'
                },
                {
                  whitespace: true,
                  message: '내용을 입력해주세요.'
                }
              ]
            })(<TextArea rows={10} />)}
          </Form.Item>
        </Form>
      </Col>
      <Col className="gutter-row" span={4}>
        <Row gutter={10}>
          <Col>
            <Button
              block
              type="primary"
              htmlType="submit"
              disabled={submitting}
              onClick={onClickSubmitButton}
            >
              {item ? '수 정' : '등 록'}
            </Button>
          </Col>
        </Row>
      </Col>
    </Row>
  );
};

const _PlanEditor = Form.create<Props>()(PlanEditor);
export default inject('authStore')(observer(_PlanEditor));
