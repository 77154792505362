import React from 'react';
import { inject, observer } from 'mobx-react';
import { Route, Switch, Redirect } from 'react-router-dom';
import UnderConst from '../../components/UnderConst';
import { AuthStore } from '../../stores/authStore';
import PostList from '../board/PostList';
import PostEditor from '../board/PostEditor';
import PostViewer from '../board/PostViewer';
import OccasionViewer from '../management/OccasionViewer';
import OccasionList from '../management/OccasionList';
import PlanList from '../plans/PlanList';
import ArchiveGroupBrowser from '../../components/general/ArchiveGroupBrowser';
import ReleaseNotes from './ReleaseNotes';
import NotificationList from './NotificationList';

interface Props {
  authStore?: AuthStore;
}

const Index: React.FC<Props> = props => {
  const authStore = props.authStore!;

  return (
    <Switch>
      <Route path="/works/board/uc" component={UnderConst} />

      <Route path="/works/board" exact>
        <Redirect to="/works/board/notifications" />
      </Route>

      <Route path="/works/board/notifications" component={NotificationList} />

      <Route path="/works/board/posts/notice-all">
        <PostList type={1} />
      </Route>
      <Route path="/works/board/posts/notice-dept">
        <PostList
          type={1}
          refId={authStore.user.rootDept && authStore.user.rootDept.id}
        />
      </Route>
      <Route path="/works/board/posts/circular-all">
        <PostList type={2} />
      </Route>
      <Route path="/works/board/posts/circular-dept">
        <PostList
          type={2}
          refId={authStore.user.rootDept && authStore.user.rootDept.id}
        />
      </Route>
      <Route path="/works/board/posts/edit" component={PostEditor} />
      <Route path="/works/board/posts/view" component={PostViewer} />
      <Route path="/works/board/posts/" component={PostList} />

      <Route path="/works/board/occasions/view" component={OccasionViewer} />
      <Route path="/works/board/occasions" component={OccasionList} />

      <Route path="/works/board/plans" component={PlanList} />

      <Route path="/works/board/archive" component={ArchiveGroupBrowser} />

      <Route path="/works/board/release-notes" component={ReleaseNotes} />
    </Switch>
  );
};

export default inject('authStore')(observer(Index));
