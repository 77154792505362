import React, { useState, useEffect } from 'react';
import { inject, observer } from 'mobx-react';
import {
  Route,
  Switch,
  Redirect,
  withRouter,
  RouteComponentProps,
} from 'react-router-dom';
import { Layout, PageHeader } from 'antd';
import Header from '../../components/Header';
import Sidebar from '../../components/Sidebar';
import { HLContent } from '../../components/layout/Index';
import { MenuItem } from '../../models/Common';
import authStore, { AuthStore } from '../../stores/authStore';
import { Order } from '../../models/Order';
import OrderService from '../../services/OrderService';
import MainRouter from './MainRouter';
import UrbanRouter from './UrbanRouter';
import OrderRouter from './OrderRouter';
import mainMenus from './menus/main';
import urbanMenus from './menus/urban';
import mainProjectMenus from './menus/mainProject';
import urbanProjectMenus from './menus/urbanProject';

let defaultHeaderItems: MenuItem[] = [
  {
    id: 'main',
    title: '설계본부',
    icon: 'appstore',
    link: '/planning/main',
  },
  {
    id: 'urban',
    title: '도시계획본부',
    icon: 'appstore',
    link: '/planning/urban',
  },
];

const sideItemsInfo: { [key: string]: MenuItem[] } = {
  main: mainMenus,
  urban: urbanMenus,
};

interface Props extends RouteComponentProps {
  authStore?: AuthStore;
}

const Index: React.FC<Props> = (props) => {
  const paths = props.location.pathname.split('/');
  const menu = paths[1];
  const submenu = paths.length >= 3 ? paths[2] : undefined;
  const param1 = paths.length >= 4 ? paths[3] : undefined;

  // console.count('Planning.Index');

  const [headerItems, setHeaderItems] = useState<MenuItem[]>(
    defaultHeaderItems
  );
  const [sideItems, setSideItems] = useState<MenuItem[]>([]);
  const [order, setOrder] = useState<Order>(new Order());
  useEffect(() => {
    const fetchOrderInfo = async (id: number) => {
      const result = await OrderService.fetchItem(id, 'name, dept_id');
      if (result && result.order) {
        const order = Order.fromJson(result.order);
        setHeaderItems([
          ...defaultHeaderItems,
          {
            id: 'orders',
            title: `${order.name}`,
            icon: 'appstore',
            link: `/${menu}/orders/${id}`,
          },
        ]);

        setOrder(order);

        if (order.dept_id && order.dept_id === 22) {
          setSideItems(urbanProjectMenus);
        } else {
          setSideItems(mainProjectMenus);
        }
      }
    };

    if (submenu === undefined) return;

    if (submenu === 'orders' && param1 !== undefined) {
      console.log('Planning.Index param1 changed: ', param1);
      fetchOrderInfo(Number(param1));
    } else {
      console.log('Planning.Index submenu changed: ', submenu);
      setSideItems(sideItemsInfo[submenu]);
    }
  }, [menu, submenu, param1]);

  // console.log('headerItems: ', headerItems);

  const onSelectHeaderMenu = (key: string) => {
    localStorage.setItem('planningHeaderMenuKey', key);
  };

  const defaultHeaderMenuKey =
    localStorage.getItem('planningHeaderMenuKey') || 'main';

  if (!authStore.user.acl_planning) {
    return <></>;
  }

  return (
    <>
      <Sidebar menuItems={sideItems} defaultOpenKeys={['업무프로세스']}>
        <div style={{ textAlign: 'left', margin: '30px 0px 0px 24px' }}>
          <img src="/images/hanlim-arch.png" width="120" alt="한림건축" />
        </div>
        <PageHeader
          title="설계"
          subTitle="한림건축"
          style={{ paddingTop: 5, paddingBottom: 20 }}
        />
      </Sidebar>

      <Layout id="wrapper" style={{ marginLeft: 200 }}>
        <Layout.Content>
          <Header menuItems={headerItems} onSelectMenu={onSelectHeaderMenu} />
          <HLContent>
            <Switch>
              <Route path="/planning" exact>
                <Redirect to={`/planning/${defaultHeaderMenuKey}/dashboard`} />
              </Route>
              <Route path="/planning/main" component={MainRouter} />
              <Route path="/planning/urban" component={UrbanRouter} />
              <Route path="/planning/orders">
                <OrderRouter order={order} />
              </Route>
            </Switch>
          </HLContent>
        </Layout.Content>
      </Layout>
    </>
  );
};

export default inject('authStore')(observer(withRouter(Index)));
