import { MenuItem } from '../../../models/Common';

const menuItems: MenuItem[] = [
  {
    id: '자금관리',
    title: '자금관리',
    icon: 'desktop',
    children: [
      {
        id: '자금1',
        title: '일일 자금보고',
        link: '/management/finance/daily'
      },
      {
        id: '자금2',
        title: '수금계획',
        link: '/management/finance/collect'
      },
      {
        id: '자금3',
        title: '월별 수금현황',
        link: '/management/finance/cstatus'
      },
      {
        id: '자금4',
        title: '자금계획표',
        link: '/management/finance/account'
      }
    ]
  },
  {
    id: '업체관리',
    title: '업체관리',
    icon: 'desktop',
    children: [
      {
        id: '업체1',
        title: '발주처 관리',
        link: '/management/finance/companies/list?type=1'
      },
      {
        id: '업체2',
        title: '협력업체 관리',
        link: '/management/finance/companies/list?type=2'
      },
      {
        id: '1102',
        title: '협력업체 평가표'
      }
    ]
  },
  {
    id: '프로젝트관리',
    title: '프로젝트관리',
    icon: 'desktop',
    link: '/management/finance/ledger?state=ongoing'
  },
  {
    id: '1104',
    title: '해외법인관리',
    icon: 'desktop'
  },
  {
    id: '1105',
    title: '경영참고자료',
    icon: 'desktop'
  },
  {
    id: '기안/품의',
    title: '기안/품의',
    icon: 'desktop',
    children: [
      {
        id: '기안1',
        title: '지출결의',
        link: '/management/finance/eas/list?cat=1101'
      },
      {
        id: '기안2',
        title: '기타업무품의',
        link: '/management/finance/eas/list?cat=1100'
      }
    ]
  },
  {
    id: '1106',
    title: '업무매뉴얼',
    icon: 'desktop'
  },
  {
    id: '그룹2',
    title: '업무보고',
    icon: 'desktop',
    children: [
      {
        id: '그룹21',
        title: '외주비 현황보고',
        link: '/management/finance/eas/list?cat=1102'
      },
      {
        id: '그룹22',
        title: '매출,수금 현황보고',
        link: '/management/finance/eas/list?cat=1103'
      },
      {
        id: '그룹23',
        title: '손익 현황보고',
        link: '/management/finance/eas/list?cat=1104'
      },
      {
        id: '그룹24',
        title: '자산부채 현황보고',
        link: '/management/finance/eas/list?cat=1105'
      }
    ]
  },
  {
    id: '1101',
    title: '업무보고 (자료실)',
    icon: 'desktop'
  }
];

export default menuItems;
