import React from 'react';
import { Route, Switch, Redirect } from 'react-router-dom';
import { Layout, PageHeader } from 'antd';
import Sidebar from '../../components/Sidebar';
import { HLBody } from '../../components/layout/Index';
import UnderConst from '../../components/UnderConst';
import { MenuItem } from '../../models/Common';
import ArchiveGroupBrowser from '../../components/general/ArchiveGroupBrowser';
import EADocListByCat from '../eas/EADocListByCat';
import EADocDrafterWrapper from '../eas/EADocDrafterWrapper';
import { AuthStore } from '../../stores/authStore';
import { inject, observer } from 'mobx-react';

const mainItems: MenuItem[] = [
  {
    id: '6002',
    title: '법인관리',
    icon: 'desktop',
  },
  {
    id: '해외',
    title: '업무보고',
    icon: 'desktop',
    link: '/oversea/main/eas/list?cat=6001',
  },
  {
    id: '6001',
    title: '업무보고 (자료실)',
    icon: 'desktop',
  },
];

interface Props {
  authStore?: AuthStore;
}

const Index: React.FC<Props> = (props) => {
  const authStore = props.authStore!;
  if (!authStore.user.acl_oversea) {
    return <></>;
  }

  return (
    <>
      <Sidebar menuItems={mainItems} defaultOpenKeys={['법인관리']}>
        <PageHeader title="해외법인" />
      </Sidebar>
      <Layout id="wrapper" style={{ marginLeft: 200 }}>
        <Layout.Content>
          <HLBody>
            <Switch>
              <Route path="/oversea" exact>
                <Redirect to="/oversea/main/archive?id=6002" />
              </Route>

              <Route path="/oversea/uc" component={UnderConst} />
              <Route
                path="/oversea/main/archive"
                component={ArchiveGroupBrowser}
              />

              <Route path="/oversea/main/eas/list" component={EADocListByCat} />
              <Route
                path="/oversea/main/eas/draft"
                component={EADocDrafterWrapper}
              />
            </Switch>
          </HLBody>
        </Layout.Content>
      </Layout>
    </>
  );
};

export default inject('authStore')(observer(Index));
