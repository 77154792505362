import React from 'react';
import { Form, Input, Button } from 'antd';
import { Corp } from '../../models/Org';
import { FormComponentProps } from 'antd/lib/form';
import { observer, inject } from 'mobx-react';
import { DataStore } from '../../stores/dataStore';
import Util from '../../helpers/Util';
import FCNumberInput from '../../components/formcomps/FCNumberInput';

interface Props extends FormComponentProps {
  dataStore?: DataStore;
  item?: Corp;
  preventEditingCode?: boolean;
  preventEditingName?: boolean;
  onSuccess: () => void;
}

const _CorpEditor = (props: Props) => {
  const dataStore = props.dataStore!;
  const {
    item,
    preventEditingCode = false,
    preventEditingName = false
  } = props;
  const { getFieldDecorator } = props.form;

  const handleSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    props.form.validateFields((err, values) => {
      if (err) return;
      item ? updateItem(values, item) : createItem(values);
    });
  };

  const createItem = async (values: any) => {
    try {
      dataStore.updatingStore = true;
      await dataStore.createCorp(values.code, values.name, values.credit);
      props.onSuccess();
    } catch (err) {
      Util.showError(err);
    } finally {
      dataStore.updatingStore = false;
    }
  };

  const updateItem = async (values: any, item: Corp) => {
    try {
      dataStore.updatingStore = true;
      await dataStore.updateCorp(item.id, {
        code: values.code,
        name: values.name,
        credit: values.credit
      });
      props.onSuccess();
    } catch (err) {
      Util.showError(err);
    } finally {
      dataStore.updatingStore = false;
    }
  };

  console.count('CompEditor');

  return (
    <Form
      labelCol={{ span: 5 }}
      wrapperCol={{ span: 18 }}
      onSubmit={handleSubmit}
    >
      <Form.Item label="코드">
        {getFieldDecorator('code', {
          rules: [
            {
              required: true,
              message: '코드를 입력해주세요.'
            },
            {
              len: 3,
              message: '코드는 3글자로 입력해주세요.'
            }
          ],
          initialValue: item && item.code
        })(<Input autoFocus disabled={preventEditingCode} />)}
      </Form.Item>
      <Form.Item label="이름">
        {getFieldDecorator('name', {
          rules: [
            {
              required: true,
              message: '이름을 입력해주세요.'
            }
          ],
          initialValue: item && item.name
        })(<Input disabled={preventEditingName} />)}
      </Form.Item>

      <Form.Item label="마이너스 한도">
        <FCNumberInput
          form={props.form}
          name="credit"
          options={{ initialValue: (item && item.credit) || 0 }}
        />
      </Form.Item>

      <Form.Item wrapperCol={{ span: 12, offset: 5 }}>
        <Button type="primary" htmlType="submit">
          {item ? '수정' : '등록'}
        </Button>
      </Form.Item>
    </Form>
  );
};

const CorpEditor = Form.create<Props>()(_CorpEditor);
export default inject('dataStore')(observer(CorpEditor));
