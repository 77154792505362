import React, { useState, useEffect } from 'react';
import { Table, Form } from 'antd';
import { FormComponentProps } from 'antd/lib/form';
import { CollectPlanItem, CollectWeekGroup } from '../../../models/Finance';
import AButton from '../../../components/general/AButton';
import _ from 'lodash';
import Util from '../../../helpers/Util';

interface Props extends FormComponentProps {
  groups: CollectWeekGroup[];
  onSelectItem?: (item: CollectPlanItem) => void;
  onSelectPrice?: (item: CollectPlanItem) => void;
}

const CollectSummaryTable: React.FC<Props> = props => {
  const [loading] = useState(false);
  const [items, setItems] = useState<CollectPlanItem[]>([]);

  useEffect(() => {
    let items: CollectPlanItem[] = [];
    props.groups.forEach((group, index) => {
      if (group.items.length > 0) {
        const summaryItem = new CollectPlanItem();
        summaryItem.id = index;
        summaryItem.contract_id = 0;
        summaryItem.name = `${index + 1}주차: ${group.startDate} ~ ${
          group.endDate
        }`;
        summaryItem.total_price = _.sumBy(group.items, i =>
          Number(i.total_price)
        );
        summaryItem.total_pay = _.sumBy(group.items, i => Number(i.total_pay));

        items.push(...group.items);
        items.push(summaryItem);
      }
    });
    setItems(items);
  }, [props.groups]);

  const onClickItem = (item: CollectPlanItem) => {
    props.onSelectItem && props.onSelectItem(item);
  };

  const onClickPrice = (item: CollectPlanItem) => {
    props.onSelectPrice && props.onSelectPrice(item);
  };

  const columns = [
    {
      title: '법인',
      dataIndex: 'corp.name',
      width: '100px',
      align: 'center' as 'center',
      render: (text: string, record: CollectPlanItem) => {
        if (record.contract_id === 0) {
          return {
            children: (
              <div style={{ marginBottom: '10px' }}>
                <b>{record.name}</b>
              </div>
            ),
            props: { colSpan: 4 }
          };
        }
        return text;
      }
    },
    {
      title: '본부',
      dataIndex: 'dept.name',
      render: (text: string, record: CollectPlanItem) => {
        if (record.contract_id === 0) return { props: { colSpan: 0 } };
        return text;
      }
    },
    {
      title: '계약건명',
      dataIndex: 'name',
      render: (text: string, record: CollectPlanItem) => {
        if (record.contract_id === 0) return { props: { colSpan: 0 } };
        return <AButton onClick={e => onClickItem(record)}>{text}</AButton>;
      }
    },
    {
      title: '종류',
      dataIndex: 'kind',
      render: (text: string, record: CollectPlanItem) => {
        if (record.contract_id === 0) return { props: { colSpan: 0 } };
        return text;
      }
    },
    {
      title: '계획',
      children: [
        {
          title: '일자',
          dataIndex: 'est_date',
          align: 'right' as 'right',
          render: (text: string, record: CollectPlanItem) =>
            Util.formatDate(record.est_date)
        },
        {
          title: '금액',
          dataIndex: 'f_total_price',
          align: 'right' as 'right',
          render: (text: string, record: CollectPlanItem) => {
            if (record.contract_id === 0) {
              return (
                <div style={{ marginBottom: '10px' }}>
                  <b>{text}</b>
                </div>
              );
            }
            return (
              <AButton onClick={e => onClickPrice(record)}>{text}</AButton>
            );
          }
        }
      ]
    },
    {
      title: '실행',
      children: [
        {
          title: '일자',
          dataIndex: 'pay_date',
          align: 'right' as 'right',
          render: (text: string, record: CollectPlanItem) =>
            Util.formatDate(record.pay_date)
        },
        {
          title: '금액',
          dataIndex: 'f_total_pay',
          align: 'right' as 'right',
          render: (text: string, record: CollectPlanItem) => {
            if (record.contract_id === 0) {
              return (
                <div style={{ marginBottom: '10px' }}>
                  <b>{text}</b>
                </div>
              );
            }
            return (
              <AButton onClick={e => onClickPrice(record)}>{text}</AButton>
            );
          }
        }
      ]
    }
  ];

  return (
    <Table
      columns={columns}
      dataSource={items}
      pagination={false}
      rowKey="id"
      rowClassName={record => {
        let className = '';
        if (record.contract_id === 0) className += ' hl-bg-gray';
        return className;
      }}
      loading={loading}
      bordered={true}
      size="middle"
    />
  );
};

export default Form.create<Props>()(CollectSummaryTable);
