import { MenuItem } from '../../../models/Common';

const menuItems: MenuItem[] = [
  {
    id: '프로젝트총괄',
    title: '프로젝트 총괄',
    icon: 'desktop',
    link: '/development/main/dashboard'
  },
  {
    id: '진행 프로젝트',
    title: '진행 프로젝트',
    icon: 'desktop',
    link: '/development/main/ongoing'
  },
  {
    id: '업무프로세스',
    title: '업무프로세스',
    icon: 'desktop',
    children: [
      {
        id: 'ord',
        title: '수주정보보고',
        link: '/development/main/eadocs/ord'
      },
      {
        id: 'orders',
        title: '수주심의',
        link: '/development/main/orders'
      },
      {
        id: 'ordins',
        title: '수주견적',
        link: '/development/main/ordins'
      },
      {
        id: 'ctrins',
        title: '수주계약',
        link: '/development/main/ctrins'
      },
      {
        id: 'ordouts',
        title: '외주실행',
        link: '/development/main/ordouts'
      },
      {
        id: 'ctrouts',
        title: '외주계약',
        link: '/development/main/ctrouts'
      }
      // {
      //   id: 'comp',
      //   title: '협력업체등록신청',
      //   link: '/development/main/uc'
      // }
    ]
  },

  // {
  //   id: '수금관리',
  //   title: '수금관리',
  //   icon: 'desktop',
  //   children: [
  //     {
  //       id: '수금계획표',
  //       title: '수금계획표',
  //       link: '/development/main/collect'
  //     },
  //     {
  //       id: '기성청구',
  //       title: '기성청구',
  //       link: '/development/main/uc'
  //     }
  //   ]
  // },
  {
    id: '수금계획표',
    title: '수금계획표',
    icon: 'desktop',
    link: '/development/main/collect'
  },
  {
    id: '개발',
    title: '업무보고',
    icon: 'desktop',
    link: '/development/main/eas/list?cat=4007'
  },
  { id: '4106', title: '업무보고 (자료실)', icon: 'desktop' },
  {
    id: '기안/품의',
    title: '기안/품의',
    icon: 'desktop',
    children: [
      {
        id: '기안1',
        title: '사업계획서',
        link: '/development/main/eas/list?refTarget=pmis&cat=4002'
      },
      {
        id: '기안2',
        title: '사업수지(정산)',
        link: '/development/main/eas/list?refTarget=pmis&cat=4001'
      },
      {
        id: '기안3',
        title: '분양계획',
        link: '/development/main/eas/list?refTarget=pmis&cat=4005'
      },
      {
        id: '기안4',
        title: '광고계획',
        link: '/development/main/eas/list?refTarget=pmis&cat=4006'
      },
      {
        id: '기안5',
        title: '기타업무품의',
        link: '/development/main/eas/list?cat=4000'
      }
    ]
  },
  {
    id: '설정',
    title: '설정',
    icon: 'desktop',
    children: [
      {
        id: 'stages',
        title: '부서별 진행단계',
        link: '/development/main/settings/stages'
      },
      {
        id: 'ctypes',
        title: '부서별 수금구분',
        link: '/development/main/settings/ctypes'
      }
    ]
  }
];

export default menuItems;
