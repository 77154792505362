import Service from './Index';

const EAFlowTemplateService = {
  fetchItems: () => {
    return Service.get(`/eas/flow_templates`);
  },
  create: (data: any) => {
    return Service.post(`/eas/flow_templates`, data);
  },
  update: (id: number, data: any) => {
    return Service.put(`/eas/flow_templates/${id}`, data);
  },
  delete: (id: number) => {
    return Service.delete(`/eas/flow_templates/${id}`);
  }
};

export default EAFlowTemplateService;
