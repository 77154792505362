import React, { useState, useEffect } from 'react';
import { Table, Row, Col, Input, Button, InputNumber, Drawer } from 'antd';
import { RouteComponentProps, withRouter } from 'react-router';
import { Order } from '../../models/Order';
import OrderService, { OrderSearchOption } from '../../services/OrderService';
import Form, { FormComponentProps } from 'antd/lib/form';
import Util from '../../helpers/Util';
import { PaginationConfig } from 'antd/lib/table';
import AppService from '../../services/AppService';
import EADocDetail from '../eas/EADocDetail';
import FCStringCodeSelect from '../../components/formcomps/FCStringCodeSelect';
import AButton from '../../components/general/AButton';
import LinkButton from '../../components/general/LinkButton';
import ArchiveGroupBrowser from '../../components/general/ArchiveGroupBrowser';
import FIHQSelect from '../../components/formcomps/FIHQSelect';

interface Props extends RouteComponentProps, FormComponentProps {
  deptId?: number;
}

const OrderListForPortfolio: React.FC<Props> = (props) => {
  const { deptId } = props;
  const [items, setItems] = useState<Order[]>([]);
  const [searchOption, setSearchOption] = useState<OrderSearchOption>({
    dept_id: deptId,
  });
  const [totalCount, setTotalCount] = useState(0);
  const [currentPage, setCurrentPage] = useState(1);
  const [loading, setLoading] = useState(false);
  const [kinds, setKinds] = useState<string[]>([]);
  const { getFieldDecorator } = props.form;

  useEffect(() => {
    const fetchStringCodes = async () => {
      setLoading(true);
      try {
        const result = await AppService.fetchStoreValues(['project_kinds']);
        setKinds(result['project_kinds']);
      } catch (error) {
        Util.showError(error);
      } finally {
        setLoading(false);
      }
    };

    fetchStringCodes();
  }, []);

  const pagination = {
    pageSize: 15,
    current: currentPage,
    total: totalCount,
    showTotal: (total: number, range: [number, number]) =>
      `전체 ${total}건 중 ${range[0]}-${range[1]}`,
  };

  useEffect(() => {
    const fetchItems = async () => {
      setLoading(true);
      try {
        const result = await OrderService.fetchItems(
          pagination.pageSize,
          currentPage,
          searchOption
        );
        setTotalCount(result.total_count);
        setItems(Order.fromJsonArray(result.items));
      } catch (error) {
        Util.showError(error);
      } finally {
        setLoading(false);
      }
    };

    fetchItems();
  }, [currentPage, searchOption, pagination.pageSize]);

  const onChangeTable = (pagination: PaginationConfig) => {
    setCurrentPage(pagination.current || 1);
  };

  const onSubmitSearchForm = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    props.form.validateFields((err, values) => {
      if (err) return;
      setSearchOption(values);
      setCurrentPage(1);
    });
  };

  const [detailVisible, setDetailVisible] = useState(false);
  const [archiveVisible, setArchiveVisible] = useState(false);
  const [refId, setRefId] = useState(0);

  const onClickItem = (item: Order) => {
    setRefId(item.id);
    setDetailVisible(true);
  };

  const onCloseDetail = () => {
    setDetailVisible(false);
  };

  const onClickArchive = (item: Order) => {
    setRefId(item.id);
    setArchiveVisible(true);
  };

  const onCloseArchive = () => {
    setArchiveVisible(false);
  };

  const columns = [
    {
      title: '부서',
      dataIndex: 'dept_id',
      fixed: 'left' as 'left',
      width: 90,
      render: (text: string, record: Order) => {
        return record.dept ? record.dept.name : '';
      },
    },
    {
      title: '계약건명',
      dataIndex: 'name',
      fixed: 'left' as 'left',
      width: 300,
      render: (text: string, record: Order) => (
        <AButton onClick={(e) => onClickItem(record)}>
          <div className="truncate" style={{ width: '300px' }}>
            {text}
          </div>
        </AButton>
      ),
    },
    {
      title: '대지면적',
      dataIndex: 'site_area',
      align: 'right' as 'right',
      render: (text: string, record: Order) => {
        return `${Util.formatNumber(record.site_area)} ㎡`;
      },
    },
    {
      title: '연면적',
      dataIndex: 'total_floor_area',
      align: 'right' as 'right',
      render: (text: string, record: Order) => {
        return `${Util.formatNumber(record.project.total_floor_area)} ㎡`;
      },
    },
    {
      title: '용도',
      dataIndex: 'f_use1',
    },
    {
      title: '규모',
      dataIndex: 'project.level_info',
    },
    {
      title: '발주처',
      dataIndex: 'comp_name',
    },
    {
      title: '금융사',
      dataIndex: 'finance_comp',
    },
    {
      title: '시공사',
      dataIndex: 'constructor_name',
    },
    {
      title: '수행년도',
      dataIndex: 'year',
      align: 'right' as 'right',
    },
    {
      title: '준공년도',
      dataIndex: 'project.end_date',
      align: 'right' as 'right',
      render: (text: string, record: Order) => {
        return Util.formatYear(record.project.end_date);
      },
    },
    {
      title: '포트폴리오',
      fixed: 'right' as 'right',
      width: 70,
      render: (text: string, record: Order) => {
        return (
          <LinkButton onClick={(e) => onClickArchive(record)}>
            포트폴리오
          </LinkButton>
        );
      },
    },
    {
      title: '비고',
      dataIndex: 'state',
      fixed: 'right' as 'right',
      width: 70,
    },
  ];

  return (
    <>
      <h2>포트폴리오 관리</h2>

      <Form
        className="hl-form"
        layout="inline"
        style={{ marginBottom: 7 }}
        onSubmit={onSubmitSearchForm}
      >
        <Row type="flex" justify="space-between">
          <Col>
            <Form.Item>
              {getFieldDecorator('keyword')(
                <Input.Search
                  placeholder="검색어"
                  allowClear={true}
                  style={{ width: 150 }}
                />
              )}
            </Form.Item>

            <Form.Item>
              {getFieldDecorator('year')(
                <InputNumber placeholder="년도" style={{ width: 100 }} />
              )}
            </Form.Item>

            <FIHQSelect
              form={props.form}
              options={{ initialValue: deptId }}
              width="180px"
            />

            <Form.Item>
              <FCStringCodeSelect
                form={props.form}
                name="kind"
                placeholder="종류"
                items={kinds}
                allowClear={true}
                style={{ width: '120px' }}
              />
            </Form.Item>

            <Form.Item>
              <Button type="primary" htmlType="submit">
                검색
              </Button>
            </Form.Item>
          </Col>
        </Row>
      </Form>

      <Table
        columns={columns}
        dataSource={items}
        rowKey="id"
        pagination={pagination}
        onChange={onChangeTable}
        bordered={true}
        loading={loading}
        size="middle"
        scroll={{ x: 'max-content' }}
      />

      <Drawer
        closable={false}
        placement="right"
        width={'85%'}
        visible={detailVisible}
        onClose={onCloseDetail}
        destroyOnClose={true}
      >
        <EADocDetail refTarget="ord" refId={refId} fetchDelayed={true} />
      </Drawer>

      <Drawer
        closable={false}
        placement="right"
        width={1100}
        visible={archiveVisible}
        onClose={onCloseArchive}
        destroyOnClose={true}
      >
        <ArchiveGroupBrowser id={101} orderId={refId} />
      </Drawer>
    </>
  );
};

const _OrderListForPortfolio = Form.create<Props>()(OrderListForPortfolio);
export default withRouter(_OrderListForPortfolio);
