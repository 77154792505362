import Service from './Index';
import { File } from '../models/File';

const FileService = {
  fetchArchives: (group_id: number) => {
    return Service.get(`/files/ags/${group_id}/archives`);
  },

  // fetchItems: (
  //   limit: number,
  //   page: number,
  //   keyword?: string,
  //   archive_ids?: string
  // ) => {
  //   const offset = (page - 1) * limit;
  //   return Service.get('/files', {
  //     limit,
  //     offset,
  //     keyword,
  //     archive_ids
  //   });
  // },

  fetchArchiveItems: (
    limit: number,
    page: number,
    keyword?: string,
    archive_ids?: string,
    order_id?: number
  ) => {
    const offset = (page - 1) * limit;
    return Service.get('/files/archives', {
      limit,
      offset,
      keyword,
      archive_ids,
      order_id
    });
  },

  createArchiveFile: (data: any) => {
    return Service.post(`/files/archives`, data);
  },

  fetchGroupItems: (group_id: number) => {
    return Service.get(`/files/groups/${group_id}`);
  },

  updateFileGroup: (
    group_id: number,
    current_archive_id: number,
    order_id: number = 0,
    archive_id: number,
    tags: string[]
  ) => {
    return Service.put(`/files/groups/${group_id}`, {
      current_archive_id,
      order_id,
      archive_id,
      tags
    });
  },

  // updateItem: (id: number, data: any) => {
  //   return Service.put(`/files/${id}`, data);
  // },

  addFileRevision: (group_id: number, file_id: number) => {
    return Service.post(`/files/${group_id}/revisions`, { file_id });
  },

  deleteItem: (id: number) => {
    return Service.delete(`/files/${id}`);
  },

  downloadFile: (file_id: number, file_name: string) => {
    return Service.download(`/files/download/${file_id}`, file_name);
  },

  previewFile: (item: File) => {
    const link = document.createElement('a');
    link.href = item.originalFullPath;
    link.setAttribute('target', '_blank');
    document.body.appendChild(link);
    link.click();
  }
};

export default FileService;
