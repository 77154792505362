import React from 'react';
import { RouteProps } from 'react-router';
import { Route, Redirect } from 'react-router-dom';
import { AuthStore } from '../stores/authStore';
import { inject, observer } from 'mobx-react';

interface Props {
  authStore?: AuthStore;
}

const Index: React.FC<Props & RouteProps> = props => {
  const { authStore, ...rest } = props;

  if (authStore!.userLoggedIn) {
    return <Route {...rest} />;
  }
  return (
    <Redirect
      to={{
        pathname: '/login',
        state: { from: props.location }
      }}
    />
  );
};

export default inject('authStore')(observer(Index));
