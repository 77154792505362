import React, { useEffect } from 'react';
import { Form, Input, Button } from 'antd';
import { FormComponentProps } from 'antd/lib/form';
import InlineItem from './InlineItem';

interface Props extends FormComponentProps {
  required?: boolean;
  zipcode?: string;
  address?: string;
  useMap?: boolean;
}

const FCAddressInput = (props: Props) => {
  const { getFieldDecorator, setFieldsValue } = props.form;
  const { useMap = false } = props;
  const requireRule: any = props.required
    ? {
        required: true,
        message: '위치를 입력해주세요.'
      }
    : {};

  const onClickSearchButton = () => {
    // new daum.Postcode({
    //     oncomplete: function(data) {
    //         // 팝업에서 검색결과 항목을 클릭했을때 실행할 코드를 작성하는 부분입니다.
    //         // 예제를 참고하여 다양한 활용법을 확인해 보세요.
    //     }
    // }).open();

    new (window as any).daum.Postcode({
      oncomplete: function(data: any) {
        console.log(data);

        // 각 주소의 노출 규칙에 따라 주소를 조합한다.
        // 내려오는 변수가 값이 없는 경우엔 공백('')값을 가지므로, 이를 참고하여 분기 한다.
        var fullAddr = ''; // 최종 주소 변수
        var extraAddr = ''; // 조합형 주소 변수

        // 사용자가 선택한 주소 타입에 따라 해당 주소 값을 가져온다.
        fullAddr =
          data.userSelectedType === 'R' ? data.roadAddress : data.jibunAddress;

        // 사용자가 선택한 주소가 도로명 타입일때 조합한다.
        if (data.userSelectedType === 'R') {
          //법정동명이 있을 경우 추가한다.
          if (data.bname !== '') {
            extraAddr += data.bname;
          }
          // 건물명이 있을 경우 추가한다.
          if (data.buildingName !== '') {
            extraAddr +=
              extraAddr !== '' ? ', ' + data.buildingName : data.buildingName;
          }
          // 조합형주소의 유무에 따라 양쪽에 괄호를 추가하여 최종 주소를 만든다.
          fullAddr += extraAddr !== '' ? ' (' + extraAddr + ')' : '';
        }

        // 우편번호와 주소 정보를 해당 필드에 넣는다.
        //   _this.setData({
        //     zipcode: data.zonecode,
        //     address: fullAddr,
        //     addressType: (data.userSelectedType === 'R') ? 1 : 2
        //   });

        setFieldsValue({ zipcode: data.zonecode, address: fullAddr });

        // 지도를 담을 영역의 DOM 레퍼런스
        if (useMap) {
          var container: any = document.getElementById('map');
          container.style.display = '';
          var options = {
            // 지도를 생성할 때 필요한 기본 옵션
            center: new (window as any).kakao.maps.LatLng(
              33.450701,
              126.570667
            ), // 지도의 중심좌표.
            level: 3 // 지도의 레벨(확대, 축소 정도)
          };
          var map = new (window as any).kakao.maps.Map(container, options); // 지도 생성 및 객체 리턴

          // 주소-좌표 변환 객체를 생성합니다
          var geocoder = new (window as any).kakao.maps.services.Geocoder();

          // 주소로 좌표를 검색합니다
          geocoder.addressSearch(fullAddr, function(result: any, status: any) {
            // 정상적으로 검색이 완료됐으면
            if (status === (window as any).kakao.maps.services.Status.OK) {
              var coords = new (window as any).kakao.maps.LatLng(
                result[0].y,
                result[0].x
              );

              // 결과값으로 받은 위치를 마커로 표시합니다
              new (window as any).kakao.maps.Marker({
                map: map,
                position: coords
              });

              // 지도의 중심을 결과값으로 받은 위치로 이동시킵니다
              map.setCenter(coords);
            }
          });
        }
      }
    }).open();
  };

  useEffect(() => {
    if (useMap && props.address) {
      // 지도를 담을 영역의 DOM 레퍼런스
      var container: any = document.getElementById('map');
      container.style.display = '';
      var options = {
        // 지도를 생성할 때 필요한 기본 옵션
        center: new (window as any).kakao.maps.LatLng(33.450701, 126.570667), // 지도의 중심좌표.
        level: 3 // 지도의 레벨(확대, 축소 정도)
      };
      var map = new (window as any).kakao.maps.Map(container, options); // 지도 생성 및 객체 리턴

      // 주소-좌표 변환 객체를 생성합니다
      var geocoder = new (window as any).kakao.maps.services.Geocoder();

      // 주소로 좌표를 검색합니다
      geocoder.addressSearch(props.address, function(result: any, status: any) {
        // 정상적으로 검색이 완료됐으면
        if (status === (window as any).kakao.maps.services.Status.OK) {
          var coords = new (window as any).kakao.maps.LatLng(
            result[0].y,
            result[0].x
          );

          // 결과값으로 받은 위치를 마커로 표시합니다
          new (window as any).kakao.maps.Marker({
            map: map,
            position: coords
          });

          // 지도의 중심을 결과값으로 받은 위치로 이동시킵니다
          map.setCenter(coords);
        }
      });
    }
  }, [useMap, props.address]);

  return (
    <>
      <Form.Item label="우편번호">
        <InlineItem>
          {getFieldDecorator('zipcode', { initialValue: props.zipcode })(
            <Input style={{ width: '150px' }} />
          )}
        </InlineItem>{' '}
        <Button onClick={onClickSearchButton}>주소 검색...</Button>
      </Form.Item>

      <Form.Item label="위치">
        {getFieldDecorator('address', {
          initialValue: props.address,
          rules: [requireRule]
        })(<Input />)}
        <div
          id="map"
          style={{ width: '100%', height: '300px', display: 'none' }}
        ></div>
      </Form.Item>
    </>
  );
};

export default FCAddressInput;
