import React, { useState, useEffect } from 'react';
import { Button, Row, Col, Icon, Form } from 'antd';
import DragableTable from '../../../components/general/DragableTable';
import Util from '../../../helpers/Util';
import OrderService from '../../../services/OrderService';
import { Order } from '../../../models/Order';
import _ from 'lodash';
import { FormComponentProps } from 'antd/lib/form';

interface Props extends FormComponentProps {
  order: Order;
  onSubmit: () => void;
}

type TermItem = {
  id: number;
  term: string;
};

const ContractTermsEditor: React.FC<Props> = props => {
  const { order } = props;
  const [items, setItems] = useState<TermItem[]>([]);

  useEffect(() => {
    const items = order.terms.map((term, index) => ({ id: index, term }));
    setItems(items);
  }, [order]);

  const columns = [
    {
      title: '구분',
      dataIndex: 'term',
      render: (text: string, record: TermItem) => {
        return <div style={{ padding: '4px 8px' }}>{text}</div>;
      }
    }
  ];

  const onChangeOrder = (items: TermItem[]) => {
    setItems(items);
  };

  const onClickSave = async () => {
    try {
      const terms = _.uniq(items.map(el => el.term));
      await OrderService.update(order.id, { terms: JSON.stringify(terms) });
      Util.showSuccess('저장되었습니다.');
      props.onSubmit();
    } catch (error) {
      Util.showError(error);
    }
  };

  return (
    <>
      <Row type="flex" justify="space-between" style={{ marginBottom: 10 }}>
        <Col></Col>
        <Col>
          <Button onClick={onClickSave}>
            <Icon type="save" />
            변경사항 저장
          </Button>{' '}
        </Col>
      </Row>

      <DragableTable
        columns={columns}
        dataSource={items}
        rowKey="id"
        bordered={true}
        pagination={false}
        size="middle"
        onChangeOrder={onChangeOrder}
      />
    </>
  );
};

export default Form.create<Props>()(ContractTermsEditor);
