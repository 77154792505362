import { MenuItem } from '../../../models/Common';

const menuItems: MenuItem[] = [
  {
    id: '프로젝트총괄',
    title: '프로젝트 총괄',
    icon: 'desktop',
    link: '/planning/main/dashboard'
  },
  {
    id: '진행 프로젝트',
    title: '진행 프로젝트',
    icon: 'desktop',
    link: '/planning/main/ongoing'
  },
  {
    id: '업무프로세스',
    title: '업무프로세스',
    icon: 'desktop',
    children: [
      {
        id: 'ord',
        title: '수주정보보고',
        link: '/planning/main/eadocs/ord'
      },
      {
        id: 'orders',
        title: '수주심의',
        link: '/planning/main/orders'
      },
      {
        id: 'ordins',
        title: '수주견적',
        link: '/planning/main/ordins'
      },
      {
        id: 'ctrins',
        title: '수주계약',
        link: '/planning/main/ctrins'
      },
      {
        id: 'ordouts',
        title: '외주실행',
        link: '/planning/main/ordouts'
      },
      {
        id: 'ctrouts',
        title: '외주계약',
        link: '/planning/main/ctrouts'
      }
      // {
      //   id: 'comp',
      //   title: '협력업체등록신청',
      //   link: '/planning/main/uc'
      // }
    ]
  },
  {
    id: '수금계획표',
    title: '수금계획표',
    icon: 'desktop',
    link: '/planning/main/collect'
  },
  {
    id: '수주정보',
    title: '수주정보',
    icon: 'desktop',
    children: [
      {
        id: '업무보고1',
        title: '전체 수주 진행 목록',
        link: '/planning/main/reports_all'
      },
      {
        id: '업무보고2',
        title: '핵심 프로젝트',
        link: '/planning/main/reports_urgent'
      },
      {
        id: '업무보고3',
        title: '이슈 프로젝트',
        link: '/planning/main/reports_issue'
      },
      {
        id: '업무보고4',
        title: '신규 프로젝트',
        link: '/planning/main/reports_new'
      },
      // {
      //   id: '업무보고3',
      //   title: '핵심수주 일일 보고',
      //   link: '/planning/main/reports3'
      // },
      {
        id: '업무보고5',
        title: '인적자원 관리 리스트',
        link: '/planning/main/reports_hr'
      }
    ]
  },
  {
    id: '업무보고',
    title: '업무보고', // 진행프로젝트 보고
    icon: 'desktop',
    link: '/planning/main/eas/list?cat=2005'
  },
  {
    id: '교육',
    title: '교육',
    icon: 'desktop',
    children: [
      {
        id: '교육1',
        title: '교육수강내역',
        link: '/planning/main/myedu'
      },
      {
        id: '교육2',
        title: '교육일정',
        link: '/planning/main/edu'
      }
    ]
  },
  {
    id: '2203',
    title: '업무매뉴얼',
    icon: 'desktop'
  },
  {
    id: '2202',
    title: '기타자료',
    icon: 'desktop'
  },
  {
    id: '기안/품의',
    title: '기안/품의',
    icon: 'desktop',
    children: [
      {
        id: 'report1',
        title: '계획도면/검토서',
        link: '/planning/main/eas/list?refTarget=pmis&cat=2001'
      },
      {
        id: 'report2',
        title: '심의도서',
        link: '/planning/main/eas/list?refTarget=pmis&cat=2002'
      },
      {
        id: 'report3',
        title: '허가도서',
        link: '/planning/main/eas/list?refTarget=pmis&cat=2003'
      },
      {
        id: 'report4',
        title: '실시도서',
        link: '/planning/main/eas/list?refTarget=pmis&cat=2004'
      },
      {
        id: 'report5',
        title: '정기경영보고',
        link: '/planning/main/eas/list?cat=2000'
      }
    ]
  },
  {
    id: '설정',
    title: '설정',
    icon: 'desktop',
    children: [
      {
        id: 'stages',
        title: '부서별 진행단계',
        link: '/planning/main/settings/stages'
      },
      {
        id: 'ctypes',
        title: '부서별 수금구분',
        link: '/planning/main/settings/ctypes'
      }
    ]
  }
];

export default menuItems;
