import { observable, action } from 'mobx';
import { Corp, Department } from '../models/Org';
import _ from 'lodash';
import { API_ROOT } from '../config';
import axios from 'axios';
import userStore from './userStore';

interface ErrorMessage {
  msg: string;
}

interface ServerErrorType {
  errors: ErrorMessage[];
}

export class DataStore {
  @observable corps: Corp[] = [];
  @observable dept: Department = new Department(0, 'root');
  @observable ranks: string[] = [];
  @observable updatingStore = false;
  hq_ids: number[] = [];

  private lastCorpId: number = 0; // TODO: id > Date.now() 사용?
  private lastDeptId: number = 0; // TODO: id > Date.now() 사용?

  @action
  async fetchStore() {
    const { data: result } = await axios(API_ROOT + '/app');
    let rawCorps = result.data.corps;
    let rawDepts = result.data.depts;
    let rawRanks = result.data.ranks;
    this.corps = Corp.fromJsonArray(rawCorps);
    this.dept.children = Department.fromJsonArray(rawDepts);
    this.ranks = rawRanks;
    this.hq_ids = result.data.hq_ids || [];

    // set lastCompId
    rawCorps = _.orderBy(rawCorps, ['id', 'asc']);
    this.lastCorpId = rawCorps[rawCorps.length - 1].id;

    // set lastDeptId
    let maxId = 0;
    const findMaxId = (items: any) => {
      _.forEach(items, item => {
        if (item.id > maxId) maxId = item.id;
        if (item.children) {
          findMaxId(item.children);
        }
      });
    };
    findMaxId(rawDepts);
    this.lastDeptId = maxId;
  }

  private async saveCorps(corps: Corp[]) {
    const config = { headers: { 'Content-Type': 'application/json' } };
    const body = JSON.stringify({ corps });
    const { data: result } = await axios.post(
      API_ROOT + '/app/corps',
      body,
      config
    );
    return result.corps;
  }

  private async saveRanks(ranks: string[]) {
    const config = { headers: { 'Content-Type': 'application/json' } };
    const body = JSON.stringify({ ranks });
    const { data: result } = await axios.post(
      API_ROOT + '/app/ranks',
      body,
      config
    );
    return result.ranks;
  }

  private async saveDepts(depts: Department[]) {
    const config = { headers: { 'Content-Type': 'application/json' } };
    const body = JSON.stringify({ depts });
    const { data: result } = await axios.post(
      API_ROOT + '/app/depts',
      body,
      config
    );
    return result.depts;
  }

  @action
  async createCorp(code: string, name: string, credit: number) {
    const clones = _.cloneDeep(this.corps.slice());
    const index = _.findIndex(clones, { code });
    if (index >= 0) {
      throw new Error('입력하신 코드의 회사가 이미 존재합니다.');
    }

    clones.push(new Corp(this.lastCorpId + 1, code, name, credit));

    const itemsReturned = await this.saveCorps(clones);
    this.corps = Corp.fromJsonArray(itemsReturned);
    this.lastCorpId++;
  }

  @action
  async deleteCorp(id: number) {
    const clones = _.cloneDeep(this.corps.slice());
    const index = _.findIndex(clones, { id });
    clones.splice(index, 1);

    const itemsReturned = await this.saveCorps(clones);
    this.corps = Corp.fromJsonArray(itemsReturned);
    await axios.delete(API_ROOT + `/app/corps/${id}`);

    // 해당 compCode 값을 가지고 있는 user의 compCode 값 제거
    userStore.users.forEach(user => {
      if (user.corp && user.corp.id === id) {
        user.corp = undefined;
      }
    });
  }

  @action
  async updateCorp(
    id: number,
    data: { code: string; name: string; credit: number }
  ) {
    const clones = _.cloneDeep(this.corps.slice());
    const corp = _.find(clones, { id });
    if (!corp) return;

    const code = data.code;
    const corpWithSameCode = _.find(clones, { code });
    if (corpWithSameCode && corpWithSameCode.id !== id) {
      throw new Error('입력하신 코드의 회사가 이미 존재합니다.');
    }

    corp.code = data.code;
    corp.name = data.name;
    corp.credit = data.credit;

    const itemsReturned = await this.saveCorps(clones);
    this.corps = Corp.fromJsonArray(itemsReturned);
  }

  findCorp(id?: number): Corp | undefined {
    if (id === undefined) return undefined;
    return this.corps.find(eachItem => eachItem.id === id);
  }

  @action
  async createRank(name: string) {
    const ranks = this.ranks.slice();
    if (ranks.find(e => e === name)) {
      throw new Error('이미 존재하는 값입니다.');
    }
    this.ranks = await this.saveRanks(ranks.concat(name));
  }

  @action
  async deleteRank(name: string) {
    let ranks = this.ranks.slice();
    const index = _.findIndex(ranks, name);
    ranks.splice(index, 1);
    this.ranks = await this.saveRanks(ranks);

    // await AppService.deleteRank(name);
    await axios.delete(API_ROOT + `/app/ranks/${name}`);

    userStore.users.forEach(user => {
      if (user.rank && user.rank === name) {
        user.rank = undefined;
      }
    });
  }

  @action
  async updateRank(oldName: string, newName: string) {
    let ranks = this.ranks.slice();
    if (ranks.find(e => e === newName)) {
      throw new Error('이미 존재하는 값입니다.');
    }
    ranks = ranks.map(e => (e === oldName ? newName : e));
    this.ranks = await this.saveRanks(ranks);

    userStore.users.forEach(user => {
      if (user.rank && user.rank === oldName) {
        user.rank = newName;
      }
    });
  }

  @action
  async moveRankUp(name: string) {
    // let clones = _.cloneDeep(this.ranks.slice());
    // const index = _.findIndex(clones, { id });
    // if (index <= 0) return;
    // const items = clones.splice(index, 1);
    // clones.splice(index - 1, 0, items[0]);
    // const itemsReturned = await this.saveRanks(clones);
    // this.ranks = Rank.fromJsonArray(itemsReturned);
  }

  @action
  async moveRankDown(name: string) {
    // let clones = _.cloneDeep(this.ranks.slice());
    // const index = _.findIndex(clones, { id });
    // if (index >= clones.length - 1) return;
    // const items = clones.splice(index, 1);
    // clones.splice(index + 1, 0, items[0]);
    // const itemsReturned = await this.saveRanks(clones);
    // this.ranks = Rank.fromJsonArray(itemsReturned);
  }

  findHQ(dept: Department): Department | undefined {
    const parents = Department.findAllParents(dept);
    const children = Department.findAllChildren(dept);
    const allDepts = [...parents, dept, ...children];
    return allDepts.find(dept => _.includes(this.hq_ids, dept.id));
  }

  findDepartment(id?: number, dept?: Department): Department | undefined {
    // console.log('dataStore > findDepartment : ', dept, id);
    if (id === undefined) {
      return undefined;
    }

    if (dept === undefined) {
      dept = this.dept;
    }

    if (dept.id === id) {
      return dept;
    }

    if (dept.children) {
      let found: Department | undefined = undefined;
      for (let i = 0; i < dept.children.length; i++) {
        found = this.findDepartment(id, dept.children[i]);
        if (found) {
          break;
        }
      }
      return found;
    }

    return undefined;
  }

  @action
  async createDept(name: string) {
    await this.createChildDept(name, this.dept);
  }

  @action
  async createChildDept(name: string, parent: Department) {
    this.dept.removeParent();
    const clone = _.cloneDeep(this.dept);
    const newDept = new Department(this.lastDeptId + 1, name);

    const parentClone = this.findDepartment(parent.id, clone);
    if (!parentClone) return;

    if (parentClone.children) {
      parentClone.children.push(newDept);
    } else {
      parentClone.children = [newDept];
    }

    const itemsReturned = await this.saveDepts(clone.children!);
    this.dept.children = Department.fromJsonArray(itemsReturned);
    this.lastDeptId++;
  }

  @action
  async deleteDept(id: number) {
    // const clone = _.cloneDeep(this.dept);
    // const parent = this.findParentDepartment(clone, id);
    // if (!parent) return;
    // const index = _.findIndex(parent.children!, { id });
    // parent.children!.splice(index, 1);
    // // children이 empty array일 경우 해당 필드 제거
    // // antd 트리 테이블 렌더링시 children 필드가 있으면 무조건 fold/unfold 버튼이 표시되기 때문
    // if (parent.children!.length === 0) {
    //   delete parent.children;
    // }
    // const itemsReturned = await this.saveDepts(clone.children!);
    // this.dept.children = Department.fromJsonArray(itemsReturned);
    // await axios.delete(API_ROOT + `/app/depts/${id}`);
    // // 해당 deptId 값을 가지고 있는 user의 deptId 값 제거
    // userStore.users.forEach(user => {
    //   if (user.dept && user.dept.id === id) {
    //     user.dept = undefined;
    //   }
    // });
  }

  @action
  async updateDept(id: number, data: { name?: string }) {
    this.dept.removeParent();
    const clone = _.cloneDeep(this.dept);
    const dept = this.findDepartment(id, clone);
    if (!dept) return;

    if (data.name) {
      dept.name = data.name;
    }

    const itemsReturned = await this.saveDepts(clone.children!);
    this.dept.children = Department.fromJsonArray(itemsReturned);
  }

  @action
  async moveDeptUp(id: number) {
    // const clone = _.cloneDeep(this.dept);
    // const parent = this.findParentDepartment(clone, id);
    // if (!parent) return;
    // const index = _.findIndex(parent.children!, { id });
    // if (index <= 0) return;
    // const items = parent.children!.splice(index, 1);
    // parent.children!.splice(index - 1, 0, items[0]);
    // const itemsReturned = await this.saveDepts(clone.children!);
    // this.dept.children = Department.fromJsonArray(itemsReturned);
  }

  @action
  async moveDeptDown(id: number) {
    // const clone = _.cloneDeep(this.dept);
    // const parent = this.findParentDepartment(clone, id);
    // if (!parent) return;
    // const index = _.findIndex(parent.children!, { id });
    // if (index >= parent.children!.length - 1) return;
    // const items = parent.children!.splice(index, 1);
    // parent.children!.splice(index + 1, 0, items[0]);
    // const itemsReturned = await this.saveDepts(clone.children!);
    // this.dept.children = Department.fromJsonArray(itemsReturned);
  }
}

export default new DataStore();
