import moment from 'moment';
import Util from '../helpers/Util';
import { File } from './File';
import { User } from './User';

export class Education {
  id = 0;
  owner_id = 0;
  title = '';
  category = '전사';
  instructor = '';
  date?: moment.Moment;
  duration = 0;
  summary = '';
  seat_count = 0; // 모집
  apply_count = 0; // 신청
  attend_count = 0; // 참석
  files: File[] = [];
  finished = false;
  applied = false;

  // derived
  [key: string]: any; // for string index (subscript)

  static fromJsonArray(sources: any[]) {
    return sources.map(source => Education.fromJson(source));
  }

  static fromJson(source: any) {
    let item = new Education();
    for (const key of Object.keys(source)) {
      if (key in item) {
        item[key] = source[key];
      }
    }

    item.date = Util.stringToMoment(source.date);
    item.files = source.files ? File.fromJsonArray(source.files) : [];

    return item;
  }

  get available_count() {
    return this.seat_count - this.apply_count;
  }
}

// 수강신청정보
export class EducationUser {
  id = 0;
  education_id = 0;
  user_id = 0;
  title = '';
  state = 0;
  enforced = false;
  eaid?: number;
  created_at = '';

  // derived
  [key: string]: any; // for string index (subscript)
  user: User = new User();
  education: Education = new Education();

  static fromJsonArray(sources: any[]) {
    return sources.map(source => EducationUser.fromJson(source));
  }

  static fromJson(source: any) {
    let item = new EducationUser();
    for (const key of Object.keys(source)) {
      if (key in item) {
        item[key] = source[key];
      }
    }

    item.education = Education.fromJson({
      id: source.education_id,
      title: source.title,
      date: source.date,
      finished: source.finished
    });

    item.user = User.fromJson({
      id: source.user_id,
      name: source.name,
      dept_id: source.dept_id,
      rank: source.rank
    });

    return item;
  }

  get stateName() {
    switch (this.state) {
      case 1:
        return '출석';
      case 2:
        return '지각';
      case 3:
        return '결석';
      default:
        return 'N/A';
    }
  }
}
