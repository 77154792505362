import Service from './Index';

export type AssetSearchOption = {
  keyword?: string;
  category1?: string;
  category2?: string;
  include_disposal?: boolean;
};

const AssetService = {
  fetchItems: (limit: number, page: number, options?: AssetSearchOption) => {
    const offset = (page - 1) * limit;
    return Service.get('/assets', {
      limit,
      offset,
      ...options
    });
  },
  fetchItem: (id: number) => {
    return Service.get(`/assets/${id}`);
  },
  create: (data: any) => {
    return Service.post('/assets', data);
  },
  update: (id: number, data: any) => {
    return Service.put(`/assets/${id}`, data);
  },
  delete: (id: number) => {
    return Service.delete(`/assets/${id}`);
  },

  fetchAssetUsers: (asset_id: number) => {
    return Service.get(`/assets/${asset_id}/users`);
  },
  createAssetUser: (data: any) => {
    return Service.post('/assets/asset_users', data);
  },
  updateAssetUser: (id: number, data: any) => {
    return Service.put(`/assets/asset_users/${id}`, data);
  },
  deleteAssetUser: (id: number) => {
    return Service.delete(`/assets/asset_users/${id}`);
  },
  assignAssetUser: (asset_id: number, user_id: number | null) => {
    return Service.post('/assets/assign_user', { asset_id, user_id });
  },

  fetchAsseEADocs: (asset_id: number) => {
    return Service.get(`/assets/${asset_id}/eadocs`);
  },
  createAssetEADoc: (data: any) => {
    return Service.post('/assets/asset_eadocs', data);
  },
  updateAssetEADoc: (id: number, data: any) => {
    return Service.put(`/assets/asset_eadocs/${id}`, data);
  },
  deleteAssetEADoc: (id: number) => {
    return Service.delete(`/assets/asset_eadocs/${id}`);
  },

  fetchReports: (options: AssetSearchOption) => {
    return Service.get('/assets/reports', {
      include_disposal: options.include_disposal
    });
  }
};

export default AssetService;
