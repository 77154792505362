import React, { useState, useEffect } from 'react';
import { observer, inject } from 'mobx-react';
import { UserStore } from '../../stores/userStore';
import { Table, Input } from 'antd';
import { Department } from '../../models/Org';
import { User } from '../../models/User';
import _ from 'lodash';

interface Props {
  userStore?: UserStore;
  dept?: Department;
  selectedUsers?: User[];
  onChange?: (items: User[]) => void;
}

const UserSelector: React.FC<Props> = props => {
  const userStore = props.userStore!;
  const { dept, selectedUsers, onChange } = props;
  const [items, setItems] = useState<User[]>([]);
  const [keyword, setKeyword] = useState('');

  useEffect(() => {
    let users = dept
      ? userStore.usersInDept(dept).slice()
      : userStore.users.slice();

    if (keyword !== '') {
      users = _.filter(users, el => el.name.includes(keyword));
    }

    users = _.sortBy(users, ['name']);

    setItems(users);
  }, [dept, keyword, userStore]);

  const onSearch = (keyword: string) => {
    setKeyword(keyword.trim());
  };

  // rowSelection object indicates the need for row selection
  const selectedRowKeys = selectedUsers
    ? selectedUsers.map(user => user.id)
    : [];
  const rowSelection = {
    selectedRowKeys,
    onChange: (selectedRowKeys: any, selectedRows: User[]) => {
      onChange && onChange(selectedRows);
    }
    // getCheckboxProps: (record: any) => ({
    //   disabled: record.name === 'Disabled User', // Column configuration not to be checked
    //   name: record.name
    // })
  };

  const columns = [
    {
      title: `전체 선택 (${items.length})`,
      dataIndex: 'name',
      render: (text: string, record: User) => {
        return `${record.name} ${record.rankName}`;
      }
    }
  ];

  return (
    <div className="hl-no-border">
      <Input.Search
        placeholder="구성원 검색"
        allowClear={true}
        onSearch={onSearch}
      />
      <Table
        columns={columns}
        dataSource={items}
        rowKey="id"
        bordered={false}
        size="small"
        scroll={{ y: 478 }}
        style={{ height: '520px', border: '1px solid #ccc', marginTop: '7px' }}
        pagination={false}
        rowSelection={rowSelection}
      />
    </div>
  );
};

export default inject('userStore')(observer(UserSelector));
