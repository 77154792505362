import React, { useState } from 'react';
import { Button, Form, Input } from 'antd';
import { FormComponentProps } from 'antd/lib/form';
import Util from '../../../helpers/Util';
import FCFileInput from '../../../components/formcomps/FCFileInput';
import { User, UserETC } from '../../../models/User';
import UserService from '../../../services/UserService';

interface Props extends FormComponentProps {
  user: User;
  item?: UserETC;
  onSuccess?: () => void;
}

const UserETCEdit: React.FC<Props> = props => {
  const { user, item } = props;
  const [submitting, setSubmitting] = useState(false);
  const { getFieldDecorator } = props.form;

  const onSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    props.form.validateFields((err, values) => {
      if (err) return;
      processItem(values);
    });
  };

  const processItem = async (values: any) => {
    try {
      setSubmitting(true);

      if (item) {
        await UserService.updateETC(item.user_id, item.seq, values);
      } else {
        await UserService.createETC(user.id, values);
      }

      Util.showSuccess('저장되었습니다.');
      if (props.onSuccess) props.onSuccess();
    } catch (err) {
      Util.showError(err);
      setSubmitting(false);
    }
  };

  return (
    <>
      <h2>기타사항 {item ? '수정' : '등록'}</h2>

      <Form
        className="hl-form"
        labelCol={{ span: 4 }}
        wrapperCol={{ span: 18 }}
        onSubmit={onSubmit}
      >
        <Form.Item label="제목">
          {getFieldDecorator('content', {
            initialValue: item && item.content
          })(<Input />)}
        </Form.Item>

        <Form.Item label="관련자료">
          <FCFileInput form={props.form} items={item && item.files} />
        </Form.Item>

        <Form.Item wrapperCol={{ span: 12, offset: 4 }}>
          <Button type="primary" htmlType="submit" disabled={submitting}>
            저 장
          </Button>
        </Form.Item>
      </Form>
    </>
  );
};

export default Form.create<Props>()(UserETCEdit);
