import { MenuItem } from '../../../models/Common';

const menuItems: MenuItem[] = [
  {
    id: '교육관리',
    title: '교육관리',
    icon: 'desktop',
    children: [
      {
        id: '교육1',
        title: '교육일정',
        link: '/management/support/edu_summary',
      },
      {
        id: '교육2',
        title: '교육관리',
        link: '/management/support/educations',
      },
      {
        id: '교육3',
        title: '교육자료실',
        link: '/management/support/edu_docs',
      },
      // {
      //   id: '교육4',
      //   title: '설문조사관리',
      //   link: '/management/support/polls'
      // }
    ],
  },
  {
    id: '자산관리',
    title: '자산관리',
    icon: 'desktop',
    children: [
      {
        id: '자산1',
        title: '자산관리',
        link: '/management/support/assets',
      },
      {
        id: '자산2',
        title: '자산보고서',
        link: '/management/support/assets_report',
      },
    ],
  },
  {
    id: '법인관리',
    title: '법인관리',
    icon: 'desktop',
    children: [
      {
        id: '법인1',
        title: '전체요약',
        link: '/management/support/corp_summary',
      },
      {
        id: '법인2',
        title: '법인별 자격관리',
        link: '/management/support/corp_pages',
      },
    ],
  },
  {
    id: '인사관리',
    title: '인사관리',
    icon: 'desktop',
    allow_user_ids: [
      1, // admin
      15001, // 이강진
      13005, // 인중호
      22040, // 박경태
      11004, // 공병돈
    ],
    children: [
      {
        id: '인사1',
        title: '조직도',
        link: '/management/support/orgchart',
      },
      {
        id: '인사2',
        title: '인사실무',
        link: '/management/support/hb',
      },
      {
        id: '인사3',
        title: '인사정보',
        link: '/management/support/users',
      },
      {
        id: '인사4',
        title: '근로계약기간 관리',
        link: '/management/support/user_contracts',
      },
    ],
  },
  {
    id: '계약관리',
    title: '계약관리',
    icon: 'desktop',
    link: '/management/support/posts/?type=4',
  },
  {
    id: '1002',
    title: '계약서',
    icon: 'desktop',
  },
  {
    id: '홍보관리',
    title: '홍보관리',
    icon: 'desktop',
    children: [
      {
        id: '홍보1',
        title: '포트폴리오 관리',
        link: '/management/support/portfolio',
      },
      {
        id: '홍보2',
        title: '지명원 자료실',
        link: '/management/support/posts/?type=5',
      },
      {
        id: '홍보3',
        title: '전사 일정관리',
        link: '/management/support/occasions',
      },
      {
        id: '홍보4',
        title: '개별 일정관리',
        link: '/management/support/plans',
      },
    ],
  },
  {
    id: '비용관리',
    title: '비용관리',
    icon: 'desktop',
    children: [
      {
        id: '비용1',
        title: '업무경비',
        link: '/management/support/posts/?type=6',
      },
      {
        id: '비용2',
        title: '전도금',
        link: '/management/support/posts/?type=7',
      },
      {
        id: '비용3',
        title: '기타 일반관리비',
        link: '/management/support/posts/?type=8',
      },
    ],
  },
  {
    id: '근태관리',
    title: '근태관리',
    icon: 'desktop',
    children: [
      {
        id: '근태1',
        title: '부서별 연차사용 현황',
        link: '/management/support/vacations/stats',
      },
      {
        id: '근태2',
        title: '휴가관리',
        link: '/management/support/vacations/records',
      },
    ],
  },
  {
    id: '출장외근관리',
    title: '출장/외근관리',
    icon: 'desktop',
    children: [
      {
        id: '출장1',
        title: '실적관리',
        link: '/management/support/trips/eadocs',
      },
      {
        id: '출장2',
        title: '출장비 정산',
        link: '/management/support/trips/reports',
      },
    ],
    allow_user_ids: [
      1, // admin
      3, // 홍길동
      22040, // 박경태
      11004, // 공병돈
    ],
  },
  {
    id: '규정외 관리',
    title: '규정외 관리',
    icon: 'desktop',
    children: [
      {
        id: '규정1',
        title: '사규관리',
        link: '/management/support/posts/?type=9',
      },
      {
        id: '규정2',
        title: '문서관리',
        link: '/management/support/posts/?type=10',
      },
    ],
  },
  {
    id: '법무내역',
    title: '법무내역',
    icon: 'desktop',
    link: '/management/support/lawsuits',
  },
  {
    id: '그룹1',
    title: '업무보고',
    icon: 'desktop',
    children: [
      {
        id: '그룹11',
        title: '법무 진행현황 리스트',
        link: '/management/support/eas/list?cat=1000',
      },
      {
        id: '그룹12',
        title: '인원현황 리스트',
        link: '/management/support/eas/list?cat=1001',
      },
      {
        id: '그룹13',
        title: '경영,교육,행사',
        link: '/management/support/eas/list?cat=1002',
      },
    ],
  },
  {
    id: '1001',
    title: '업무보고 (자료실)',
    icon: 'desktop',
  },
  {
    id: '기타',
    title: '기타',
    icon: 'desktop',
    children: [
      {
        id: '기타0',
        title: '결재문서 템플릿',
        link: '/management/support/eas-templates/',
      },
      {
        id: '기타1',
        title: '회사 관리',
        link: '/management/support/corps',
      },
      {
        id: '기타2',
        title: '직위 관리',
        link: '/management/support/ranks',
      },
      {
        id: '기타3',
        title: '부서 관리',
        link: '/management/support/depts',
      },
    ],
  },
];

export default menuItems;
