import React, { useState, useEffect, useRef } from 'react';
import { Button, Row, Col, Form, Drawer, Modal } from 'antd';
import { FormComponentProps } from 'antd/lib/form';
import FICorpSelect from '../../../components/formcomps/FICorpSelect';
import DailyReportTable from './DailyReportTable';
import FinanceService, {
  CollectFetchOption
} from '../../../services/FinanceService';
import {
  CollectPlanItem,
  AccountItem,
  AccountVMItem,
  DailyReportItem
} from '../../../models/Finance';
import ContractPayBook from '../../projects/contracts/ContractPayBook';
import AccountItemEditor from './AccountItemEditor';
import DailyDepositTable from './DailyDepositTable';
import DailyDepositEditor from './DailyDepositEditor';
import FIDatePicker from '../../../components/formcomps/FIDatePicker';
import DailyReportSummary from './DailyReportSummary';
import CorpEditor from '../CorpEditor';
import { Corp } from '../../../models/Org';
import dataStore from '../../../stores/dataStore';
import { Moment } from 'moment';
import Util from '../../../helpers/Util';
import ReactToPrint from 'react-to-print';

interface Props extends FormComponentProps {
  preventEditing?: boolean;
}

const DailyReport: React.FC<Props> = props => {
  const { preventEditing = false } = props;
  const needsFetchLatest = useRef(true);
  const [refreshTrigger, setRefreshTrigger] = useState(1);
  const [loading, setLoading] = useState(false);
  const [fetchOption, setFetchOption] = useState<CollectFetchOption>({
    year: new Date().getFullYear(),
    month: new Date().getMonth() + 1,
    day: new Date().getDate(),
    corp_id: 1
  });
  const [inItems, setInItems] = useState<AccountItem[]>([]);
  const [outItems, setOutItems] = useState<AccountItem[]>([]);
  const [allReportItems, setAllReportItems] = useState<DailyReportItem[]>([]);
  const [dailyReportItem, setDailyReportItem] = useState<
    DailyReportItem | undefined
  >(undefined);

  const fetchOptionDateString = () => {
    return `${fetchOption.year}-${fetchOption.month}-${fetchOption.day}`;
  };

  const onChangeCorp = (corp_id: number) => {
    setFetchOption({ ...fetchOption, corp_id });
  };

  const onChangeTargetDate = (date: Moment | undefined, dateString: string) => {
    if (date) {
      setFetchOption({
        ...fetchOption,
        year: date.year(),
        month: date.month() + 1,
        day: date.date()
      });
    }
  };

  useEffect(() => {
    const fetchItems = async () => {
      try {
        setLoading(true);
        // 수주관련 수금지급 실행건
        const result = await FinanceService.fetchOrderPayActions(fetchOption);
        const plans = CollectPlanItem.fromJsonArray(result.actions);

        // 수금지급 실행건
        const result2 = await FinanceService.fetchAccountPaidItems(fetchOption);
        const accountItems = AccountItem.fromJsonArray(result2.items);

        // 잔액현황
        const result3 = await FinanceService.fetchDailyReports(fetchOption);
        const reportItems = DailyReportItem.fromJsonArray(result3.items);
        const corpReportItem = reportItems.find(
          e => e.corp_id === fetchOption.corp_id
        );
        setAllReportItems(reportItems);
        setDailyReportItem(corpReportItem);

        const plansInItems = plans.filter(e => e.out === false);
        const plansOutItems = plans.filter(e => e.out === true);

        // console.log('plans: ', plans);
        // console.log('plansInItems: ', plansInItems);
        // console.log('plansOutItems: ', plansOutItems);

        // order_id가 동일한 항목들 하나로 묶음 처리 (daily이기 때문에 est_date는 모두 동일)
        // 예정일이 동일한 항목들은 실행일도 같거나 undefined 라는 가정
        // (실행시 실행일자로 예정일이 업데이트되기 때문)
        let inItems: CollectPlanItem[] = [];
        plansInItems.forEach(e => {
          const foundItem = inItems.find(i => i.order_id === e.order_id);
          if (foundItem) {
            foundItem.total_price =
              Number(foundItem.total_price) + Number(e.total_price);
            foundItem.total_pay =
              Number(foundItem.total_pay) + Number(e.total_pay);
            if (e.pay_date) foundItem.pay_date = e.pay_date; // TODO: 필요?
          } else {
            inItems.push(e);
          }
        });

        let outItems: CollectPlanItem[] = [];
        plansOutItems.forEach(e => {
          const foundItem = outItems.find(i => i.order_id === e.order_id);
          if (foundItem) {
            foundItem.total_price =
              Number(foundItem.total_price) + Number(e.total_price);
            foundItem.total_pay =
              Number(foundItem.total_pay) + Number(e.total_pay);
            if (e.pay_date) foundItem.pay_date = e.pay_date; // TODO: 필요?
          } else {
            outItems.push(e);
          }
        });

        // console.log('inItems: ', inItems);
        // console.log('outItems: ', outItems);
        // console.log('accountItems: ', accountItems);

        const allItems = [
          ...AccountItem.fromCollectPlanItems(inItems),
          ...AccountItem.fromCollectPlanItems(outItems),
          ...accountItems
        ];

        let allInItems = allItems.filter(e => e.out === false);
        let allOutItems = allItems.filter(e => e.out === true);

        // console.log('allInItems: ', allInItems);
        // console.log('allOutItems: ', allOutItems);

        setInItems(allInItems);
        setOutItems(allOutItems);
      } catch (e) {
        console.log(e);
      } finally {
        setLoading(false);
      }
    };

    // 가장 최근 리포트의 날짜를 찾아서fetchOption으로 설정
    const fetchLatestReport = async () => {
      try {
        setLoading(true);
        const result = await FinanceService.fetchLatestDailyReportDate(1);
        const date = Util.stringToMoment(result.target_date);
        if (date) {
          setFetchOption({
            ...fetchOption,
            year: date.year(),
            month: date.month() + 1,
            day: date.date()
          });
        }
      } catch (e) {
        console.log(e);
      } finally {
        setLoading(false);
      }
    };

    if (needsFetchLatest.current) {
      // 최초로딩시 fetchLatestReport 호출
      needsFetchLatest.current = false;
      fetchLatestReport();
    } else {
      // 이후엔 fetchItems 호출
      fetchItems();
    }
  }, [fetchOption, refreshTrigger, needsFetchLatest]);

  const orderIdRef = useRef(0);
  const orderOutRef = useRef(false);
  const paybookDirtyRef = useRef(false);
  const [collectEditVisible, setCollectEditVisible] = useState(false);

  const accountItemRef = useRef<AccountItem | undefined>(undefined);
  const [itemEditorVisible, setItemEditorVisible] = useState(false);

  const onSuccessEditingAccount = () => {
    setRefreshTrigger(v => v + 1);
    setItemEditorVisible(false);
  };

  const onSelectItem = (item: AccountVMItem, out: boolean) => {
    orderOutRef.current = out;

    if (item.source1) {
      accountItemRef.current = item.source1;
      orderIdRef.current = item.order_id1;
    } else {
      accountItemRef.current = item.source2;
      orderIdRef.current = item.order_id2;
    }

    orderIdRef.current === 0
      ? setItemEditorVisible(true)
      : setCollectEditVisible(true);
  };

  const onChangePayBook = () => {
    paybookDirtyRef.current = true;
  };

  const onCloseCollectEdit = () => {
    setCollectEditVisible(false);
    if (paybookDirtyRef.current) {
      paybookDirtyRef.current = false;
      setRefreshTrigger(refreshTrigger + 1);
    }
  };

  const reportItemRef = useRef<DailyReportItem | undefined>(undefined);
  const [depositEditorVisible, setDepositEditorVisible] = useState(false);

  const onClickAdd = () => {
    reportItemRef.current = undefined;
    setDepositEditorVisible(true);
  };

  const onSuccessEditingDeposit = () => {
    setDepositEditorVisible(false);
    setRefreshTrigger(refreshTrigger + 1);
  };

  const onClickUpdate = () => {
    reportItemRef.current = dailyReportItem;
    setDepositEditorVisible(true);
  };

  const [creditEditorVisible, setCreditEditorVisible] = useState(false);

  const corpItemRef = useRef<Corp | undefined>(undefined);
  const onClickUpdateCorpCredit = () => {
    corpItemRef.current = dataStore.findCorp(fetchOption.corp_id);
    setCreditEditorVisible(true);
  };

  const onSuccessCreditEditing = () => {
    setCreditEditorVisible(false);
    setRefreshTrigger(v => v + 1);
  };

  const printContentRef = useRef<HTMLDivElement>(null);
  const corp = dataStore.findCorp(fetchOption.corp_id);

  return (
    <>
      <h2>일일 자금보고</h2>

      <Form className="hl-form" layout="inline" style={{ marginBottom: 15 }}>
        <Row type="flex" justify="space-between">
          <Col>
            <FICorpSelect
              form={props.form}
              label="법인"
              width="170px"
              options={{ initialValue: fetchOption.corp_id || 1 }}
              onChange={onChangeCorp}
            />

            <Form.Item>
              <FIDatePicker
                form={props.form}
                name="target_date"
                initialValue={fetchOptionDateString()}
                onChange={onChangeTargetDate}
              />
            </Form.Item>

            {!preventEditing && (
              <Form.Item>
                <Button onClick={onClickUpdateCorpCredit}>
                  마이너스 한도액 설정
                </Button>
              </Form.Item>
            )}
          </Col>
          <Col>
            <ReactToPrint
              trigger={() => <Button>출력</Button>}
              content={() => printContentRef.current}
            />{' '}
            {!preventEditing && (
              <>
                <Button
                  onClick={onClickUpdate}
                  disabled={dailyReportItem === undefined}
                >
                  현 잔액현황 수정하기
                </Button>{' '}
                <Button onClick={onClickAdd}>신규 등록하기</Button>
              </>
            )}
          </Col>
        </Row>
      </Form>

      <div ref={printContentRef}>
        <h2 className="only-print">
          일일 자금보고 / {corp && corp.name} / {fetchOptionDateString()}
        </h2>

        {fetchOption.corp_id && fetchOption.corp_id === 1 && (
          <>
            <h3>법인별 자금현황</h3>
            <DailyReportSummary items={allReportItems} />
            <br />
            <br />
          </>
        )}

        <h3>잔액현황</h3>
        <DailyDepositTable item={dailyReportItem} loading={loading} />
        <br />
        <br />

        <h3>수입</h3>
        <DailyReportTable
          items={inItems}
          title1="현금수입"
          title2="통장수입"
          loading={loading}
          preventEditing={preventEditing}
          onSelectItem={item => onSelectItem(item, false)}
        />
        <br />
        <br />

        <h3>지출</h3>
        <DailyReportTable
          items={outItems}
          title1="현금지출"
          title2="통장지출"
          loading={loading}
          preventEditing={preventEditing}
          onSelectItem={item => onSelectItem(item, true)}
        />
      </div>

      <Drawer
        closable={false}
        placement="right"
        width={1200}
        visible={collectEditVisible}
        onClose={onCloseCollectEdit}
        destroyOnClose={true}
      >
        <ContractPayBook
          orderId={orderIdRef.current}
          out={orderOutRef.current}
          onChange={onChangePayBook}
        />
      </Drawer>

      <Modal
        title={orderOutRef.current ? '지급등록' : '수금등록'}
        width={800}
        centered
        visible={itemEditorVisible}
        footer={null}
        onCancel={() => setItemEditorVisible(false)}
        destroyOnClose={true}
      >
        <AccountItemEditor
          out={orderOutRef.current}
          item={accountItemRef.current}
          onSuccess={onSuccessEditingAccount}
        />
      </Modal>

      <Drawer
        closable={false}
        placement="right"
        width={1300}
        visible={depositEditorVisible}
        onClose={() => setDepositEditorVisible(false)}
        destroyOnClose={true}
      >
        <DailyDepositEditor
          item={reportItemRef.current}
          corpId={fetchOption.corp_id}
          onSuccess={onSuccessEditingDeposit}
        />
      </Drawer>

      <Modal
        title="법인별 마이너스 한도액 설정"
        centered
        visible={creditEditorVisible}
        footer={null}
        onCancel={() => setCreditEditorVisible(false)}
        destroyOnClose={true}
      >
        <CorpEditor
          onSuccess={onSuccessCreditEditing}
          item={corpItemRef.current}
          preventEditingCode
          preventEditingName
        />
      </Modal>
    </>
  );
};

export default Form.create<Props>()(DailyReport);
