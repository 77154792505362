import { User } from './User';
import userStore from '../stores/userStore';

export class Comment {
  id = 0;
  ref_target = 1;
  ref_id = 0;
  owner_id = 0;
  parent_id?: number;
  content = '';
  created_at = '';

  // derived
  [key: string]: any; // for string index (subscript)
  owner: User = new User();
  children: Comment[] = [];

  static fromJsonArray(sources: any[]) {
    let results: Comment[] = [];
    let flatComments = sources.map(source => Comment.fromJson(source));
    flatComments.forEach(comment => {
      if (comment.parent_id) {
        const parent = flatComments.find(el => el.id === comment.parent_id);
        if (parent) {
          parent.children.push(comment);
        }
      } else {
        results.push(comment);
      }
    });
    return results;
  }

  static fromJson(source: any) {
    let item = new Comment();
    for (const key of Object.keys(source)) {
      if (key in item) {
        item[key] = source[key];
      }
    }

    item.owner = userStore.findById(source.owner_id);

    return item;
  }
}
