import React, { useState, useEffect } from 'react';
import { Cascader } from 'antd';
import { GetFieldDecoratorOptions } from 'antd/lib/form/Form';
import { FormComponentProps } from 'antd/lib/form';
import { CascaderOptionType } from 'antd/lib/cascader';

interface Props extends FormComponentProps {
  name: string;
  items: string[];
  placeholder?: string;
  options?: GetFieldDecoratorOptions;
}

const FCCascader = (props: Props) => {
  const { getFieldDecorator } = props.form;
  const [items, setItems] = useState<CascaderOptionType[]>([]);

  useEffect(() => {
    // const cascaderItems = [
    //   {
    //     value: 'Value1',
    //     label: 'Label1',
    //     children: [
    //       {
    //         value: 'Value11',
    //         label: 'Label11'
    //       }
    //     ]
    //   },
    //   {
    //     value: 'Value2',
    //     label: 'Label2'
    //   }
    // ];

    let cascaderItems: CascaderOptionType[] = [];
    let cascaderObject: { [key: number]: CascaderOptionType } = {
      0: { value: 'root', children: cascaderItems }
    };

    const buildItems = (item: string) => {
      item.split('/').forEach((e, index) => {
        let current = cascaderObject[index] || undefined;
        if (current) {
          if (current.children === undefined) current.children = [];
          let foundItem = current.children.find(c => c.value === e);
          if (foundItem === undefined) {
            foundItem = { value: e, label: e };
            current.children.push(foundItem);
          }
          cascaderObject[index + 1] = foundItem;
        }
      });
    };

    props.items.forEach(item => buildItems(item));

    setItems(cascaderItems);
  }, [props.items]);

  return (
    <>
      {getFieldDecorator(
        props.name,
        props.options
      )(<Cascader options={items} placeholder={props.placeholder || ''} />)}
    </>
  );
};

export default FCCascader;
