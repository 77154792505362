import React from 'react';
import { withRouter, RouteComponentProps } from 'react-router';
import EADocDrafter from './EADocDrafter';
import { EADoc } from '../../models/EAS';
import { Order } from '../../models/Order';

interface Props extends RouteComponentProps {
  order?: Order;
}

const EADocDrafterWrapper: React.FC<Props> = props => {
  const { order, location } = props;
  const params = new URLSearchParams(location.search);
  const category = Number(params.get('cat')) || 0;
  const refTarget = params.get('refTarget') || undefined;
  const refId: number = (props.match.params as any).oid;

  const title = EADoc.categoryName(category) || '기안하기';
  const docTitle = order ? `${title} - ${order.name}` : '';

  return (
    <EADocDrafter
      title={title}
      docTitle={docTitle}
      refTarget={refTarget}
      refId={refId}
      category={category}
    />
  );
};

export default withRouter(EADocDrafterWrapper);
