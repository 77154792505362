import React, { useState, useEffect } from 'react';
import { Row, Col } from 'antd';
import { RouteComponentProps, withRouter } from 'react-router';
import Util from '../../helpers/Util';
import CommentSection from '../../components/general/CommentSection';
import HTMLContentViewer from '../../components/general/HTMLContentViewer';
import Spinner from '../../components/general/Spinner';
import { Occasion } from '../../models/Occasion';
import OccasionService from '../../services/OccasionService';
import {
  HDescriptions,
  HDRow,
  HDItem,
  HDRowItem
} from '../../components/general/HDescriptions';
import AttachedFileList from '../../components/general/AttachedFileList';

interface Props extends RouteComponentProps {}

const OccasionViewer: React.FC<Props> = props => {
  const { location } = props;
  const params = new URLSearchParams(location.search);
  const itemId = Number(params.get('id')) || 0;

  const [loading, setLoading] = useState(true);
  const [item, setItem] = useState<Occasion>(new Occasion());

  useEffect(() => {
    const fetchDetail = async () => {
      setLoading(true);
      try {
        const result = await OccasionService.fetchItem(itemId);
        await OccasionService.read(itemId);
        setItem(Occasion.fromJson(result.occasion));
      } catch (error) {
        Util.showError(error);
      } finally {
        setLoading(false);
      }
    };

    if (itemId > 0) {
      fetchDetail();
    }
  }, [itemId]);

  if (loading) {
    return <Spinner />;
  }

  return (
    <>
      <h2>{item.title}</h2>
      <Row>
        <Col className="gutter-row" span={20}>
          <HDescriptions>
            <HDRow>
              <HDItem label="작성자">{item.owner.nameWithDeptRank}</HDItem>
              <HDItem label="작성일">
                {Util.formatDateTime(item.created_at)}
              </HDItem>
              <HDItem label="조회">{item.read_count + 1}</HDItem>
            </HDRow>
            <HDRow>
              <HDItem label="행사일시">
                {Util.formatDateTimeHHMM(item.date)}
              </HDItem>
              <HDItem label="행사장소">{item.place}</HDItem>
            </HDRow>
            <HDRowItem label="행사개요" colSpan={5}>
              {item.summary}
            </HDRowItem>
            <HDRowItem label="참석대상" colSpan={5}>
              {item.attendees}
            </HDRowItem>
            {item.files.length > 0 && (
              <HDRowItem label="첨부 파일" colSpan={5}>
                <AttachedFileList items={item.files} />
              </HDRowItem>
            )}
          </HDescriptions>
          <HTMLContentViewer content={item.content} />
        </Col>
      </Row>

      <Row>
        <Col className="gutter-row" span={20}>
          <CommentSection refTarget={3} refId={itemId} />
        </Col>
      </Row>
    </>
  );
};

export default withRouter(OccasionViewer);
