import React from 'react';

interface Props {
  content: string;
  style?: React.CSSProperties;
}

const HTMLContentViewer: React.FC<Props> = props => {
  const defaultStyle: React.CSSProperties = {
    margin: '15px 0px',
    padding: '20px',
    border: '1px solid #ddd'
  };

  const propStyle: React.CSSProperties = props.style ? props.style : {};

  return (
    <div
      dangerouslySetInnerHTML={{
        __html: props.content
      }}
      style={{ ...defaultStyle, ...propStyle }}
    />
  );
};

export default HTMLContentViewer;
